import axios from 'axios';
import {API_URL} from '../../types/datastructures';

export function API_CALL_submitNewJob(jobId: string) { return axios.post(`${API_URL}/job/new`, {jobId}); }

export function API_CALL_addRecurringJob(jobId: string, teamId: string) { return axios.post(`${API_URL}/job/recurring/add`, {jobId, teamId}); }

export function API_CALL_deleteCatalogJob(jobId: string) { return axios.post(`${API_URL}/job/delete`, {jobId}); }

export function API_CALL_submitAccuracyJob(jobId: string) { return axios.get(`${API_URL}/accuracyV2?jobId=${jobId}&request_id=latest`); }

export const API_CALL_LoadJobsData = (teamId: string) => {
    return axios.post(`${API_URL}/job/data`, {teamId});
}


