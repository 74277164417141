import React from 'react';
import { useController, ControllerProps, Control } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { defaultVal } from '../../../libraries/types/datastructures';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as EmailValidator from 'email-validator';

interface RHInputProps<T extends object> extends Omit<ControllerProps<T>, 'render'> {
    keyName?: string;
    control: Control<T>,
    type?: 'number' | 'text' | 'date' | 'textarea'
    label?: string;
    placeholder?: string;
    id?: string;
    className?: string;
    onChange?: (...event: any[]) => void;
    defaultValue?: any;
    disabled?: boolean;
    validateEmail?: boolean;
    gridItem?: boolean;
}

export const RHInput: React.FC<RHInputProps<any>> = (props) => {
    const extraControllerOptions = {};
    if (props.validateEmail) {
        extraControllerOptions['rules'] = {
            validate: EmailValidator.validate
        }
    }
    const { field, fieldState } = useController({
        ...props,
        ...extraControllerOptions,
        rules: {
            ...(props.rules ? props.rules : {}),
            ...(extraControllerOptions['rules'] ? extraControllerOptions['rules'] : {}),
        }
    });
    const type = props.type ? props.type : 'text';

    const fieldProps = {...field}
    
    if(type === 'number') {
        fieldProps.onChange =  (e) => {
            const output = parseInt(e.target.value, 10);
            field.onChange(isNaN(output) ? 0 : output);
            if (props.onChange) {
                props.onChange(e);
            }
        }
        fieldProps.value = isNaN(field.value) || field.value === 0 ? "" : field.value.toString()
    } else {
        if (props.onChange) {
            fieldProps.onChange = props.onChange;
        }
    }

    const tfProps = {
        key: defaultVal(props.keyName, props.name),
        label: defaultVal(props.label, props.name),
        placeholder: defaultVal(props.placeholder, props.name),
        id: defaultVal(props.id, undefined),
        className: defaultVal(props.className, undefined),
        defaultValue: defaultVal(props.defaultValue, undefined),
        disabled: defaultVal(props.disabled, undefined)
    }

    const ele = type === 'textarea'
    ? <TextareaAutosize
        {...fieldProps} 
        //variant="outlined"
        //size={'small'}
        style={{width: '100%'}}
        {...tfProps}
    />
    : <TextField 
        type={type} 
        {...fieldProps} 
        variant="outlined"
        size={'small'}
        style={{width: '100%'}}
        {...tfProps}
    />

    const isGridItem = defaultVal(props.gridItem, true);

    return isGridItem ? <Grid item xs={4}>
        {ele}
        {null && <><p>{fieldState.isTouched && "Touched"}</p>
        <p>{fieldState.isDirty && "Dirty"}</p>
        <p>{fieldState.invalid ? "invalid" : "valid"}</p></>}
    </Grid> : ele
}