import * as Sentry from '@sentry/browser';
import config from '../../config';
import { ActiveUserAccount } from '../../types/datastructures';

class SentryServiceClass {

    public Sentry: typeof Sentry;
    private initialized: boolean;
    private userInfo: { name: string, email: string; }

    constructor() {
        this.Sentry = Sentry;
        this.initialized = false;
        this.userInfo = { name: '', email: '' };
    }

    public init = () => {
        const DEPLOY_ENV = !!config.customEnv.DEPLOY_ENV ? config.customEnv.DEPLOY_ENV : 'dev'
        const DEV_ID = !!config.customEnv.REACT_APP_DEV_ID ? config.customEnv.REACT_APP_DEV_ID : ""
        if (!this.initialized) {
            const initObj = {};
            initObj['dsn'] = "https://8fde8a2dc9ef4b5e8466bd747cde5579@sentry.io/2702336";
            if (process.env.NODE_ENV === 'production') {
                switch (DEPLOY_ENV) {
                    case 'prod':
                        initObj['environment'] = 'frontend-production';
                        break;
                    case 'staging':
                        initObj['environment'] = 'frontend-staging';
                        break;
                    case 'dev':
                        initObj['environment'] = 'frontend-staging-dev';
                        break;
                }
            } else {
                initObj['environment'] = 'frontend-local'
            }
            this.Sentry.init(initObj);
            this.Sentry.configureScope(scope => {
                scope.setTag("DEPLOY_ENV", DEPLOY_ENV);
                if (DEV_ID) {
                    scope.setTag("DEV_ID", DEV_ID);
                }
            });
            this.initialized = true;
        }
    }

    getSentry = () => {
        if (!this.initialized) {
            this.init();
        }
        return this.Sentry;
    }

    setUser = (activeUser: ActiveUserAccount) => {
        if (!this.initialized) {
            this.init();
        }

        const coreUser = activeUser?.user?.core;
        this.userInfo = {
            email: coreUser?.email,
            name: activeUser.user.displayName//`l: ${coreUser.lastName}, f: ${coreUser.firstName}`
        };

        this.Sentry.configureScope(scope => {
            scope.setUser({
                ...this.userInfo,
                id: coreUser?.userId
            });
        });
    }

    showReportDialog: typeof Sentry.showReportDialog = (options) => {
        const opts = { 
            ...this.userInfo,
            ...(options ? options : {})
        }
        console.log(opts)
        this.getSentry().showReportDialog(opts);
    }
}

export const SentryService = new SentryServiceClass();