import * as React from 'react';
import Card from '@material-ui/core/Card';
import './style.scss';


type eleType = typeof Card //React.FC<CSUI_Segment>

export const CSUISegment: eleType = (props) => {
    
    const {
        className,
        ...restProps
    } = props;
    
    const classes = ['csui_segment'];

    if (className) {
        classes.push(className);
    }
    
    const customProps = {
        className: classes.join(" ")
    };

    return <Card
        {...restProps}
        {...customProps}
    />
}
