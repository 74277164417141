import axios from 'axios';
import {API_URL} from '../../types/datastructures';
import { WorkFlowStagesList } from '../../DataService';

export function API_CALL_LoadSkuKeywords(teamId: string){
    return axios.post(`${API_URL}/sku/keywords`, { teamId })
}

const API_URL_skuStage = `${API_URL}/sku/stage`;
export const API_CALL_skuStagePut = (teamId: string, workflowStageData: WorkFlowStagesList) => {
    return axios.post<any>(`${API_URL_skuStage}/update`, {
        teamId,
        workflowStageData
    });
}

const API_URL_deleteSnapshotService = `${API_URL}/sku/delete-snapshot`;
export const API_CALL_deleteSnapshotService = (snapshots: any[]) => {
    return axios.post<any>(API_URL_deleteSnapshotService, {snapshots: snapshots});
}

const API_URL_DeleteSku = `${API_URL}/sku/delete`;
export const API_CALL_DeleteSku = (teamId: string, skus: { skuId: string; retailer: string }[]) => {
    return axios.post<any>(API_URL_DeleteSku, {skus, teamId});
}

const API_URL_confirmEmailService = `${API_URL}/auth/confirm-email`;
export const API_CALL_confirm_email = (emailConfirmId: string) => {
    return axios.post<any>(API_URL_confirmEmailService, {
        emailConfirmId: emailConfirmId,
    })
}

const API_URL_downloadsListService = `${API_URL}/exports/list`
export const API_CALL_downloadsListService = (teamId: string) => {
    return axios.get(`${API_URL_downloadsListService}?teamId=${teamId}`)
}

const API_URL_downloadsDeleteService = `${API_URL}/exports/delete`
export const API_CALL_downloadsDeleteService = (teamId: string, exportId: string) => {
    return axios.delete(`${API_URL_downloadsDeleteService}?teamId=${teamId}&exportId=${exportId}`)
}

const API_URL_quickLookupImage = `${API_URL}/qlImage`;
export const API_CALL_quickLookupImage = (imageUrl: string, htmlOutput: boolean = false) => {
    return axios.get(`${API_URL_quickLookupImage}?imageUrl=${imageUrl}&htmlOutput=${htmlOutput}`);
}

export function API_CALL_SendFCMToken(token: string){
    return axios.post(`${API_URL}/fcm/set-token`, { token })
}

export async function API_CALL_IndexTeamDataElastic(teamId: string){
    const res = await axios.post(`${API_URL}/misc/indexTeamDataElastic`, { teamId });
    //console.log(res.data)
    return res
}