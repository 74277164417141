import { CustomViewItem, CustomViewList } from './datastructures';
import { transform } from 'lodash';
import { Objectentries } from '../../libraries/types/datastructures';

// This function is for type completion help...
const columnArray = (array: CustomViewItem[]) => array;

const customViewItemArrayToList = (array: CustomViewItem[]) => Object.assign(
    {},
    ...array.map(item => ({ [item.id]: item }))
) as CustomViewList['list'];

const allRecordIdColumns = columnArray([
    {
        id: 'dataSource',
        displayName: 'Data Source',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Source',
        columnHeaderSubtitle: 'Data',
        position: 1,
    },
    {
        id: 'jobName',
        displayName: 'Job Name',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Job',
        columnHeaderSubtitle: 'Name',
        position: 4,
    },
    {
        id: 'recordDate',
        displayName: 'Record Date',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Record',
        columnHeaderSubtitle: 'Date',
        position: 5,
    },
    {
        id: 'recordType',
        displayName: 'Record Type',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Record',
        columnHeaderSubtitle: 'Version',
        position: 6,
    },
    {
        id: 'redirectUrl',
        displayName: 'Redirect URL',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Url',
        columnHeaderSubtitle: 'Redirect',
        position: 7,
    },
]);

const editedColumns = columnArray([
    {
        id: 'titleEdited',
        displayName: 'Title - Edited',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Title',
        columnHeaderSubtitle: 'Edited',
        position: 1,
    },
    {
        id: 'shortDescriptionEdited',
        displayName: 'Description - Edited',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Description',
        columnHeaderSubtitle: 'Edited',
        position: 2,
    },
]);

const allCorporateIdColumns = columnArray([
    {
        id: 'upc',
        displayName: 'UPC',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'UPC',
        columnHeaderSubtitle: 'GTIN 12',
        tableColumnPosition: 3,
        position: 1,
    },
    {
        id: 'retailerId',
        displayName: 'Retailer URL ID',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Retailer',
        columnHeaderSubtitle: 'URL ID',
        tableColumnPosition: 4,
        position: 2,
    },
    {
        id: 'modelName',
        displayName: 'Model Name',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Model',
        columnHeaderSubtitle: 'Name',
        position: 3,
    },
    {
        id: 'model',
        displayName: 'Model #',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Model',
        columnHeaderSubtitle: 'Number',
        tableColumnPosition: 8,
        position: 4,
    },
    {
        id: 'manufacturer',
        displayName: 'Mfg. Part Number',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Manufacturer',
        columnHeaderSubtitle: 'Part Number',
        position: 5,
    },
    {
        id: 'productNumber',
        displayName: 'Product #',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Product',
        columnHeaderSubtitle: 'Number',
        position: 6,
    },
    {
        id: 'variants',
        displayName: 'Variant Product IDs',
        columnHeaderTitle: 'Variant',
        columnHeaderSubtitle: 'Product ID\'s',
        tableColumnPosition: 22,
        type: 'item',
        checked: false,
        position: 7,
    },
    {
        id: 'scrapedAsins',
        displayName: 'Scraped ASIN',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'ASIN',
        columnHeaderSubtitle: 'Scraped',
        position: 8,
    },
]);

const allBrandIdColumns = columnArray([
    {
        id: 'brand',
        displayName: 'Brand',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Brand',
        columnHeaderSubtitle: 'Name',
        tableColumnPosition: 1,
        position: 1,
    },
    {
        id: 'manufacturerName',
        displayName: 'Manufacturer',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Manufacturer',
        columnHeaderSubtitle: 'Name',
        tableColumnPosition: 2,
        position: 2,
    }
]);

const allSystemTagColumns = columnArray([
    {
        id: 'keyItem',
        displayName: 'Key Item',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Key Item',
        columnHeaderSubtitle: 'System Tag',
        position: 1,
    },
    {
        id: 'newLaunch',
        displayName: 'New Launch',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'New Launch',
        columnHeaderSubtitle: 'System Tag',
        position: 2,
    },
    {
        id: 'promoted',
        displayName: 'Promoted',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Promoted',
        columnHeaderSubtitle: 'System Tag',
        position: 3,
    },
    {
        id: 'inactive',
        displayName: 'Inactive',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Inactive',
        columnHeaderSubtitle: 'System Tag',
        position: 4,
    },
    {
        id: 'topSeller',
        displayName: 'Top Seller',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Top Seller',
        columnHeaderSubtitle: 'System Tag',
        position: 5,
    },
    {
        id: 'lowSeller',
        displayName: 'Low Seller',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Low Seller',
        columnHeaderSubtitle: 'System Tag',
        position: 6,
    },
    {
        id: 'highMargin',
        displayName: 'High Margin',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'High Margin',
        columnHeaderSubtitle: 'System Tag',
        position: 7,
    },
    {
        id: 'lowMargin',
        displayName: 'Low Margin',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Low Margin',
        columnHeaderSubtitle: 'System Tag',
        position: 8,
    },
    {
        id: 'highReturns',
        displayName: 'High Returns',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'High Returns',
        columnHeaderSubtitle: 'System Tag',
        position: 9,
    },
    {
        id: 'competitorSku',
        displayName: 'Competitor SKU',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Competitor SKU',
        columnHeaderSubtitle: 'System Tag',
        position: 10,
    },
    {
        id: 'delete',
        displayName: 'Delete',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Delete',
        columnHeaderSubtitle: 'System Tag',
        position: 11,
    }
]);

const allHierarchies = columnArray([
    {
        id: 'mainCategory',
        displayName: 'Main',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Main',
        columnHeaderSubtitle: 'Category',
        tableColumnPosition: 5,
        position: 1,
    },
    {
        id: 'subcategory1',
        displayName: 'Sub-category 1',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Sub 1',
        columnHeaderSubtitle: 'Category',
        position: 2,
    },
    {
        id: 'subcategory2',
        displayName: 'Sub-category 2',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Sub 2',
        columnHeaderSubtitle: 'Category',
        position: 3,
    },
    {
        id: 'subcategory3',
        displayName: 'Sub-category 3',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Sub 3',
        columnHeaderSubtitle: 'Category',
        position: 4,
    }
]);

const allAssets = columnArray([
    {
        id: 'image',
        displayName: 'Main Image',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Main',
        columnHeaderSubtitle: 'Image',
        tableColumnPosition: 2,
        position: 1,
    },
    {
        id: 'image1',
        displayName: 'Alt 1 Image',
        columnHeaderTitle: 'Alt 1',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 3,
    },
    {
        id: 'image2',
        displayName: 'Alt 2 Image',
        columnHeaderTitle: 'Alt 2',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 4,
    },
    {
        id: 'image3',
        displayName: 'Alt 3 Image',
        type: 'item',
        columnHeaderTitle: 'Alt 3',
        columnHeaderSubtitle: 'Image',
        checked: false,
        position: 5,
    },
    {
        id: 'image4',
        displayName: 'Alt 4 Image',
        columnHeaderTitle: 'Alt 4',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 6,
    },
    {
        id: 'image5',
        displayName: 'Alt 5 Image',
        columnHeaderTitle: 'Alt 5',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 7,
    },
    {
        id: 'image6',
        displayName: 'Alt 6 Image',
        columnHeaderTitle: 'Alt 6',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 8,
    },
    {
        id: 'image7',
        displayName: 'Alt 7 Image',
        columnHeaderTitle: 'Alt 7',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 9,
    },
    {
        id: 'image8',
        displayName: 'Alt 8 Image',
        columnHeaderTitle: 'Alt 8',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 10,
    },
    {
        id: 'image9',
        displayName: 'Alt 9 Image',
        columnHeaderTitle: 'Alt 9',
        columnHeaderSubtitle: 'Image',
        type: 'item',
        checked: false,
        position: 11,
    },
    /* {
        id: 'imageResolutionFailCount',
        displayName: 'Res. Fail',
        columnHeaderTitle: 'Res. Fail',
        columnHeaderSubtitle: 'Count',
        type: 'item',
        checked: false,
        position: 12,
    }, */
]);

const allSentiment = columnArray([
    {
        id: 'ratingsCount',
        displayName: 'Ratings (count)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Ratings',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 13,
        position: 1,
    },
    {
        id: 'reviewsCount',
        displayName: 'Review (count)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Reviews',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 14,
        position: 2,
    }
]);

const allMarketing = columnArray([
    {
        id: 'title',
        displayName: 'Title',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Title',
        columnHeaderSubtitle: 'Product',
        tableColumnPosition: 7,
        position: 1,
    },
    {
        id: 'description',
        displayName: 'Short Description',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Short Description',
        columnHeaderSubtitle: 'Product',
        position: 2,
    },
    {
        id: 'bullet1',
        displayName: 'Bullet 01',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 01',
        columnHeaderSubtitle: 'Bullets',
        position: 4,
    },
    {
        id: 'bullet2',
        displayName: 'Bullet 02',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 02',
        columnHeaderSubtitle: 'Bullets',
        position: 5,
    },
    {
        id: 'bullet3',
        displayName: 'Bullet 03',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 03',
        columnHeaderSubtitle: 'Bullets',
        position: 6,
    },
    {
        id: 'bullet4',
        displayName: 'Bullet 04',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 04',
        columnHeaderSubtitle: 'Bullets',
        position: 7,
    },
    {
        id: 'bullet5',
        displayName: 'Bullet 05',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 05',
        columnHeaderSubtitle: 'Bullets',
        position: 8,
    },
    {
        id: 'bullet6',
        displayName: 'Bullet 06',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 06',
        columnHeaderSubtitle: 'Bullets',
        position: 9,
    },
    {
        id: 'bullet7',
        displayName: 'Bullet 07',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 07',
        columnHeaderSubtitle: 'Bullets',
        position: 10,
    },
    {
        id: 'bullet8',
        displayName: 'Bullet 08',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 08',
        columnHeaderSubtitle: 'Bullets',
        position: 11,
    },
    {
        id: 'bullet9',
        displayName: 'Bullet 09',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 09',
        columnHeaderSubtitle: 'Bullets',
        position: 12,
    },
    {
        id: 'bullet10',
        displayName: 'Bullet 10',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 10',
        columnHeaderSubtitle: 'Bullets',
        position: 13,
    },
    {
        id: 'bullet11',
        displayName: 'Bullet 11',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 11',
        columnHeaderSubtitle: 'Bullets',
        position: 14,
    },
    {
        id: 'bullet12',
        displayName: 'Bullet 12',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet 12',
        columnHeaderSubtitle: 'Bullets',
        position: 15,
    }
]);

const allRichContent = columnArray([
    {
        id: 'videosCount',
        displayName: 'Videos (#)',
        columnHeaderTitle: 'Videos',
        columnHeaderSubtitle: 'Count',
        type: 'item',
        checked: false,
        position: 1,
    },
    {
        id: 'documentsCount',
        displayName: 'PDF\'s (#)',
        columnHeaderTitle: 'PDF\'s',
        columnHeaderSubtitle: 'Count',
        type: 'item',
        checked: false,
        position: 2,
    },
    {
        id: 'view360',
        displayName: '360 Spins',
        columnHeaderTitle: '360',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 17,
        type: 'item',
        checked: false,
        position: 3,
    },
    {
        id: 'enhancedContent',
        displayName: 'Enhanced Content',
        columnHeaderTitle: 'Enhanced',
        columnHeaderSubtitle: 'Content',
        tableColumnPosition: 18,
        type: 'item',
        checked: false,
        position: 4,
    },
    {
        id: 'aModulesType',
        displayName: 'Amazon A+ Modules Type',
        columnHeaderTitle: 'A+',
        columnHeaderSubtitle: 'Type',
        tableColumnPosition: 27,
        type: 'item',
        checked: false,
        position: 33,
    },
]);

const allDimensions = columnArray([
    /* {
        id: 'dimensionLength',
        displayName: 'Length',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Length',
        columnHeaderSubtitle: 'Product',
        position: 1,
    },
    {
        id: 'dimensionWidth',
        displayName: 'Width',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Width',
        columnHeaderSubtitle: 'Product',
        position: 2,
    },
    {
        id: 'dimensionHeight',
        displayName: 'Height',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Height',
        columnHeaderSubtitle: 'Product',
        position: 3,
    },
    {
        id: 'dimension',
        displayName: 'L x W x H',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'L x W x H',
        columnHeaderSubtitle: 'Product',
        position: 4,
    },
    {
        id: 'dimensionSize',
        displayName: 'Size',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Size',
        columnHeaderSubtitle: 'Product',
        position: 5,
    },
    {
        id: 'dimensionWeight',
        displayName: 'Weight',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Weight',
        columnHeaderSubtitle: 'Product',
        position: 6,
    }, */
    {
        id: 'color',
        displayName: 'Color',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Color',
        columnHeaderSubtitle: 'Product',
        position: 1,
    },
    {
        id: 'variantDimensions',
        displayName: 'Variant Dimensions',
        columnHeaderTitle: 'Variant',
        columnHeaderSubtitle: 'Dimensions',
        tableColumnPosition: 28,
        type: 'item',
        checked: false,
        position: 2,
    }
]);

const allSalesData = columnArray([
    {
        id: 'msrp',
        displayName: 'MSRP',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'MSRP',
        columnHeaderSubtitle: 'Price',
        position: 1,
    },
    {
        id: 'price',
        displayName: 'Sale Price',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Sale Price',
        columnHeaderSubtitle: 'Price',
        position: 2,
    },
    {
        id: 'availability',
        displayName: 'Availability',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Availability',
        columnHeaderSubtitle: 'Product',
        tableColumnPosition: 1,
        position: 3,
    },
    {
        id: 'buyBox',
        displayName: 'Buy Box',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Buy Box',
        columnHeaderSubtitle: 'Seller',
        position: 4,
    },
    {
        id: 'shippedBy',
        displayName: 'Shipped By',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Shipped By',
        position: 5,
    },
    {
        id: 'fulfillmentType',
        displayName: 'Seller Type',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Seller',
        columnHeaderSubtitle: 'Type',
        position: 6,
    },
    {
        id: 'productBadge',
        displayName: 'Product Badge',
        columnHeaderTitle: 'Product',
        columnHeaderSubtitle: 'Badge',
        tableColumnPosition: 29,
        type: 'item',
        checked: false,
        position: 7
    },
    {
        id: 'isPrime',
        displayName: 'Is Prime',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Is Prime',
        position: 8
    },
    {
        id: 'bestSellerInfo',
        displayName: 'Best Seller Rank (Amazon)',
        columnHeaderTitle: 'Best Seller',
        columnHeaderSubtitle: 'Rank',
        tableColumnPosition: 21,
        type: 'item',
        checked: false,
        position: 9
    },
    {
        id: 'amazonPromotion',
        displayName: 'Promotion (Amazon)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Promotion',
        columnHeaderSubtitle: '(Amazon)',
        position: 10
    },
    {
        id: 'amazonSubscribeSave',
        displayName: 'Subscribe & Save (Amazon)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Subscribe & Save',
        columnHeaderSubtitle: '(Amazon)',
        position: 11
    },
]);

const live = columnArray([
    {
        id: 'status',
        displayName: 'Status',
        type: 'item',
        checked: true,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Live',
        columnHeaderSubtitle: 'Status',
        position: 1,
    },
    {
        id: 'liveDate',
        displayName: 'First Audit',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Audit',
        columnHeaderSubtitle: 'First Date',
        position: 2,
    }
]);

const accuracy = columnArray([
    {
        id: 'accuracyVsPrevious',
        displayName: 'Accuracy vs Previous',
        type: 'item',
        checked: true,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Accuracy',
        columnHeaderSubtitle: 'vs Previous',
        position: 1,
    }
]);

const specsAmazon = columnArray([
    {
        id: 'country',
        displayName: 'Origin Country',
        type: 'item',
        checked: true,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Origin',
        columnHeaderSubtitle: 'Country',
        position: 1,
    },
    {
        id: 'productStyle',
        displayName: 'Product Style',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Style',
        columnHeaderSubtitle: '(product)',
        position: 2,
    },
    {
        id: 'color',
        displayName: 'Product Color',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Color',
        columnHeaderSubtitle: '(product)',
        position: 3,
    },
    {
        id: 'brandStore',
        displayName: 'Brand Store',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Brand Store',
        columnHeaderSubtitle: 'Name',
        position: 4,
    },
    {
        id: 'brandStoreUrl',
        displayName: 'Brand Store URL',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Brand Store',
        columnHeaderSubtitle: 'URL',
        position: 5,
    },
    {
        id: 'variantDimensions',
        displayName: 'Variant Dimensions',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Variant',
        columnHeaderSubtitle: 'Dimensions',
        position: 6,
    },
    {
        id: 'energyLabel',
        displayName: 'Energy Label',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Energy Label',
        columnHeaderSubtitle: 'Yes/No',
        position: 7,
    },
    {
        id: 'energyLabelUrl',
        displayName: 'Energy Label URL',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Energy Label',
        columnHeaderSubtitle: 'URL',
        position: 8,
    },
]);

const specifications = columnArray([
    {
        id: 'specifications',
        displayName: 'Specifications',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Specifications',
        columnHeaderSubtitle: '',
        position: 1,
    },
    {
        id: 'metaTags',
        displayName: 'Meta Tags',
        type: 'item',
        checked: false,
        tableColumnPosition: 0,
        columnHeaderTitle: 'Meta Tags',
        columnHeaderSubtitle: '',
        position: 2
    }
])

const health = columnArray([
    {
        id: 'overallScore',
        displayName: 'Overall Health Score',
        type: 'item',
        checked: true,
        bold: true,
        columnHeaderTitle: 'Health',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 9,
        position: 1,
    },
    {
        id: 'marketingScore',
        displayName: 'Overall Marketing Score',
        type: 'item',
        checked: true,
        bold: true,
        columnHeaderTitle: 'Marketing',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 10,
        position: 2,
    },
    {
        id: 'titleScore',
        displayName: 'Title Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Title',
        columnHeaderSubtitle: 'Score',
        position: 3,
    },
    {
        id: 'shortDescriptionScore',
        displayName: 'Short Description Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Short Desc.',
        columnHeaderSubtitle: 'Score',
        position: 4,
    },
    {
        id: 'longDescriptionScore',
        displayName: 'Long Description Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Long Desc.',
        columnHeaderSubtitle: 'Score',
        position: 5,
    },
    {
        id: 'bulletScore',
        displayName: 'Bullet Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet',
        columnHeaderSubtitle: 'Score',
        position: 6,
    },
    {
        id: 'seoScore',
        displayName: 'Overall SEO Keyword Score',
        type: 'item',
        checked: true,
        bold: true,
        columnHeaderTitle: 'Keyword',
        columnHeaderSubtitle: 'SEO Score',
        tableColumnPosition: 13,
        position: 7,
    },
    {
        id: 'titleSeoScore',
        displayName: 'Title SEO Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Title',
        columnHeaderSubtitle: 'SEO Score',
        position: 8,
    },
    {
        id: 'shortDescriptionSeoScore',
        displayName: 'Short Description SEO Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Short Desc.',
        columnHeaderSubtitle: 'SEO Score',
        position: 9,
    },
    {
        id: 'longDescriptionSeoScore',
        displayName: 'Long Description SEO Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Long Desc.',
        columnHeaderSubtitle: 'SEO Score',
        position: 10,
    },
    {
        id: 'bulletSeoScore',
        displayName: 'Bullet SEO Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullet',
        columnHeaderSubtitle: 'SEO Score',
        position: 11,
    },
    {
        id: 'assetScore',
        displayName: 'Overall Asset Score',
        type: 'item',
        checked: true,
        bold: true,
        columnHeaderTitle: 'Asset',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 10,
        position: 12,
    },
    {
        id: 'imageScore',
        displayName: 'Image Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Image',
        columnHeaderSubtitle: 'Score',
        position: 13,
    },
    /* {
        id: 'imageResolutionScore',
        displayName: 'Image Res. Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Image Res.',
        columnHeaderSubtitle: 'Score',
        position: 14,
    }, */
    {
        id: 'sentimentScore',
        displayName: 'Overall Sentiment Score',
        type: 'item',
        checked: true,
        bold: true,
        columnHeaderTitle: 'Sentiment',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 11,
        position: 15,
    },
    {
        id: 'ratingScore',
        displayName: 'Rating Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Rating',
        columnHeaderSubtitle: 'Score',
        position: 16,
    },
    {
        id: 'reviewScore',
        displayName: 'Reviews Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Reviews',
        columnHeaderSubtitle: 'Score',
        position: 17,
    },
    {
        id: 'richContentScore',
        displayName: 'Overall Rich Content Score',
        type: 'item',
        checked: false,
        bold: true,
        columnHeaderTitle: 'Rich Content',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 12,
        position: 18,
    },
    {
        id: 'videoScore',
        displayName: 'Video Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Video',
        columnHeaderSubtitle: 'Score',
        position: 19,
    },
    {
        id: 'documentScore',
        displayName: 'Document Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Document',
        columnHeaderSubtitle: 'Score',
        position: 20,
    },
    {
        id: 'view360Score',
        displayName: '360 View Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: '360 View',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 13,
        position: 21,
    },
    {
        id: 'enhancedContentScore',
        displayName: 'Enhanced Content Score',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Enhanced',
        columnHeaderSubtitle: 'Score',
        tableColumnPosition: 14,
        position: 22,
    },
]);

const workflows = columnArray([
    {
        id: 'workflowStage',
        displayName: 'Stage',
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Workflow',
        columnHeaderSubtitle: 'Stage',
        position: 1,
    },
    {
        id: 'workflowFolder',
        displayName: 'Folder',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Workflow',
        columnHeaderSubtitle: 'Folder',
        position: 2,
    }
]);

const keywords = columnArray([
    {
        id: 'primaryKeywords',
        displayName: 'Primary Keywords',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Keywords',
        columnHeaderSubtitle: 'Primary',
        position: 1,
    },
    {
        id: 'secondaryKeywords',
        displayName: 'Secondary Keywords',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Keywords',
        columnHeaderSubtitle: 'Secondary',
        position: 2,
    },
]);

const flagCounts = columnArray([
    {
        id: 'totalFlags',
        displayName: 'All Flags (count)',
        type: 'item',
        checked: true,
        tableColumnPosition: 15,
        columnHeaderTitle: 'Flag',
        columnHeaderSubtitle: 'Total',
        position: 1,
    },
    {
        id: 'criticalFlags',
        displayName: 'Critical Flags (count)',
        tableColumnPosition: 16,
        type: 'item',
        checked: true,
        columnHeaderTitle: 'Critical',
        columnHeaderSubtitle: 'Flags',
        position: 2,
    },
    {
        id: 'warningFlags',
        displayName: 'Warning Flags (count)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Warning',
        columnHeaderSubtitle: 'Flags',
        position: 3,
    },
    {
        id: 'indicatorFlags',
        displayName: 'Indicator Flags (count)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Indicator',
        columnHeaderSubtitle: 'Flags',
        position: 4,
    },
]);

const allCounts = columnArray([
    {
        id: 'titleCharacterCount',
        displayName: 'Title (char)',
        columnHeaderTitle: 'Title',
        columnHeaderSubtitle: 'Avg. Count',
        tableColumnPosition: 25,
        type: 'item',
        checked: false,
        position: 1,
    },
    {
        id: 'descriptionCharCount',
        displayName: 'Description (char)',
        columnHeaderTitle: 'Description',
        columnHeaderSubtitle: 'Avg. Count',
        tableColumnPosition: 19,
        type: 'item',
        checked: false,
        position: 2,
    },
    {
        id: 'descriptionSectionsCharacterCount',
        displayName: 'Description (char by section)',
        columnHeaderTitle: 'Desc. Section',
        columnHeaderSubtitle: 'Char Count',
        tableColumnPosition: 26,
        type: 'item',
        checked: false,
        position: 3,
    },
    {
        id: 'bullets',
        displayName: 'Bullets (#)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Bullets',
        columnHeaderSubtitle: 'Count',
        position: 4,
    },
    {
        id: 'bulletsCharCount',
        displayName: 'Bullets (char)',
        columnHeaderTitle: 'Bullets',
        columnHeaderSubtitle: 'Char Count',
        tableColumnPosition: 20,
        type: 'item',
        checked: false,
        position: 5,
    },
    {
        id: 'imageCount',
        displayName: 'Image (#)',
        type: 'item',
        columnHeaderTitle: 'Image',
        columnHeaderSubtitle: 'Count',
        checked: false,
        position: 6,
    },
    {
        id: 'videosCount',
        displayName: 'Videos (#)',
        columnHeaderTitle: 'Videos',
        columnHeaderSubtitle: 'Count',
        type: 'item',
        checked: false,
        position: 7,
    },
    {
        id: 'documentsCount',
        displayName: 'PDF\'s (#)',
        columnHeaderTitle: 'PDF\'s',
        columnHeaderSubtitle: 'Count',
        type: 'item',
        checked: false,
        position: 8,
    },
    {
        id: 'specsCount',
        displayName: 'Specs (#)',
        columnHeaderTitle: 'Specs',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 23,
        type: 'item',
        checked: false,
        position: 9,
    },
    {
        id: 'aModulesCount',
        displayName: 'Amazon A+ Modules (#)',
        columnHeaderTitle: 'A+',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 24,
        type: 'item',
        checked: false,
        position: 10,
    },
    {
        id: "aModulesTemplateCount",
        displayName: 'Amazon A+ Modules Template (#)',
        columnHeaderTitle: 'A+ Template',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 25,
        type: 'item',
        checked: false,
        position: 11,
    },
    {
        id: 'ratingsCount',
        displayName: 'Ratings (#)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Ratings',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 13,
        position: 12,
    },
    {
        id: 'reviewsCount',
        displayName: 'Reviews (#)',
        type: 'item',
        checked: false,
        columnHeaderTitle: 'Reviews',
        columnHeaderSubtitle: 'Count',
        tableColumnPosition: 14,
        position: 13,
    }
]);

const columnMapByArray = {
    allRecordIdColumns,
    allCorporateIdColumns,
    allSystemTagColumns,
    allHierarchies,
    allAssets,
    allSentiment,
    allMarketing,
    allDimensions,
    allSalesData,
    live,
    editedColumns,
    health,
    workflows,
    allRichContent,
    keywords,
    flagCounts,
    allCounts,
    specsAmazon,
    allBrandIdColumns,
    accuracy,
    specifications
};

export const columnMapByList = transform(
    columnMapByArray,
    (result, value, key) => {
        result[key] = customViewItemArrayToList(value);
    },
    {} as { [sectionName in keyof typeof columnMapByArray]: ReturnType<typeof customViewItemArrayToList> }
);

export const masterColumnMap = Object.assign(
    {},
    ...Objectentries(columnMapByList)
) as ReturnType<typeof customViewItemArrayToList>;

export const defaultColumnOrder = [
    'dataSource',
    'status',
    'image',
    'retailerId',
    'brand',
    'model',
    'title',
    'availability',
    'msrp',
    'price',
    'buyBox',
    'totalFlags',
    'criticalFlags',
    'overallScore',
    'marketingScore',
    'assetScore',
    'sentimentScore',
    'seoScore',
    'ratingScore',
    'ratingsCount',
    'workflowStage',
];
