import styled from 'styled-components';

export const StyledCell: React.FC<React.CSSProperties> = styled.div<React.CSSProperties>`
    margin: 1px;
    padding: 12px;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.43;
    padding-left: 6px;
    padding-right: 8px;
    white-space: nowrap;
    font-size: 14px;
    display: table-cell;
    vertical-align: inherit;
    text-overflow: ellipsis;
    text-align: center;
    letter-spacing: 0.01071em;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
`

export const StyledHeader: React.FC<React.CSSProperties> = styled.div<React.CSSProperties>`
    margin: 1px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.43;
    white-space: normal;
    font-size: 14px;
    vertical-align: inherit;
    text-overflow: ellipsis;
    text-align: center;
    letter-spacing: 0.01071em;
`

export const StyledRow: React.FC<React.CSSProperties> = styled.div<React.CSSProperties>`
    margin: 1px;
    padding: 4px 6px;
    text-align: left;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.43;
    white-space: nowrap;
    font-size: 14px;
    display: table-cell;
    vertical-align: inherit;
    text-overflow: ellipsis;
    text-align: center;
    letter-spacing: 0.01071em;
`
