import * as React from 'react';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { CSUIButton } from '../CSUI';

type buttonColor = typeof CSUIButton.defaultProps.color

interface CSDropzoneProps {
    onFileSelect: DropFilesEventHandler;
    activeStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    onlyShowBrowseButton?: boolean;
    children?: JSX.Element;
    buttonColor?: buttonColor
}

const CSDropzone: React.FC<CSDropzoneProps> = (props) => {
    const color = props.buttonColor === undefined
    ? 'green'
    : props.buttonColor;
    const browseButton = (onClick: () => void) => <CSUIButton 
        children={'Upload'}
        // className="newjob_button"
        slim
        color={color} 
        onClick={onClick}
    />;
    
    const allowDragNDrop = (props.onlyShowBrowseButton !== undefined) ? !props.onlyShowBrowseButton : true;
    const outerStyle = (allowDragNDrop) 
    ? {
        margin: '0 auto',
        border: '2px dashed #50FF50',
        borderRadius: 5,
        width: 200,
        height: 200
    }
    : {} /* {
        margin: 'intial',
        border: 'intial',
        borderRadius: 'intial',
        width: 'intial',
        height: 'intial',
    } */
    return (
        <Dropzone 
            onClick={evt => evt.preventDefault()}
            // disableClick={true} // (props.disableClick !== undefined) ? props.disableClick : true} 
            // ref={props.dropzoneRef} 
            onDrop={props.onFileSelect}
        >
            {innerProps => {
                // tslint:disable-next-line:no-console
                // console.log(innerProps);
                return <div 
                    {...innerProps.getRootProps()}
                    style={Object.assign(
                        {},
                        outerStyle,
                        props.style
                    )}
                > 
                    <input {...innerProps.getInputProps()} />
                    {(props.children) 
                    ? props.children
                    : (allowDragNDrop)
                    ? <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}
                    >
                        <div>
                            <span>Drop csv here</span> <br />
                            <span>or</span>
                        </div>
                        {browseButton(innerProps.open)}
                    </div>
                    : <div>{browseButton(innerProps.open)}</div>}
                </div>
            }}
        </Dropzone>
    );
};

export default CSDropzone;