import React from 'react';
import moment from 'moment';
import { CSUIIcon } from '../../../../components/CSUI';
import { ColorScheme } from '../../../../pages/Dashboard/datastructures';
import DashboardProgressBar from '../../../../pages/Dashboard/components/DashboardProgressSection/DashboardProgressBar';
import { ProductProgress } from '../../datastructures';
import './style.scss';
import Grid from '@material-ui/core/Grid/Grid';

interface ProgressCardProps extends ProductProgress {
    icon?: string;
    title: string;
    subTitle: string;
    scheme?: ColorScheme;
    newLineSubTitle?: boolean;
    value1?: number;
    scheme1?: ColorScheme;
    isInt?: boolean;
    isMonitorTabCard?: boolean;
    valuePrefix?: string;
}

const renderVariation = (variation: number | string, variationType: "comparison" | "change" | "none" | string, variationValueType: "number" | "percent" | "currency", variationPeriod: number = 0, variationStartDate: string = "", variationText?: string): JSX.Element => {
    if (variationType === "none") {
        return null
    }
    const variationPrefix = variationValueType === "currency" ? "$" : "";
    const variationSuffix = variationValueType === "percent" ? "%" : "";
    const variationClass = `variation ${variation < 0 ? 'negative' : (variation > 0 ? undefined : "neutral-value")}`;

    if (typeof variation === "undefined") {
        return <div className="variation neutral-value">{variationText ? variationText : 'No comparison'}</div>;
    } else if (variationType === "comparison") {

        const variationText = variationStartDate ? `than ${moment(variationStartDate, "MM/DD/YYYY").subtract(variationPeriod, "days").format("MM/DD/YYYY")}` : "";
        return <div className={variationClass}>
            <span>{variationPrefix}{((variationValueType !== "number" || !isNaN(variation as number)) && variation) || "--"}{variationSuffix}</span> {variation < 0 ? "lower" : "higher"} {variationText}
        </div>;
    } else {

        const variationText = variationStartDate ? `since ${moment(variationStartDate, "MM/DD/YYYY").subtract(variationPeriod, "days").format("MM/DD/YYYY")}` : "";
        return <div className={variationClass}>
            <span>{variationPrefix}{((variationValueType !== "number" || !isNaN(variation as number)) && variation) || "--"}{variationSuffix}</span> change {variationText}
        </div>;
    }
}
const ProgressCard: React.FC<ProgressCardProps> = (props) => {

    const firstOptionArray = [
        'Flags',
        'Accuracy',
        'Keywords',
        'Page',
        'Health',
        'Health Score',
        'Count',
        '% Change',
        'Characters'
    ];
if(props.isMonitorTabCard || firstOptionArray.includes(props.title)) {
return <div className="cs-progress-card">
        {/* const variationTextClass = variationText === 'Very good' ? '' : "neutral-value" */}
    <Grid container>
        <div className={'grid-card-layout'}>
            {/* size 3 */}
            {/* size={props.icon ? "9" : "12"} */}
            <div className="text-right">
                {props.title !== 'Flags' ?
                    <>
                        <h1>
                            {props.title}<span className={props.newLineSubTitle ? "new-line" : undefined}>{props.subTitle}</span>
                        </h1>
                        <h2 style={{ color: (props.scheme && props.scheme.primary) || "indigo" }}>
                            {props.valuePrefix}{props.type === "currency" && "$"}{props.type === "number" || props.type === "percent"
                                ? (props.value >= 1000
                                    ? props.value.toLocaleString()
                                    : !props.isInt ? (props.value as number).toFixed(1) : Math.floor(props.value as number))
                                : props.value
                            }{props.type === "percent" && "%"}
                        </h2>
                        {props.icon? null : <DashboardProgressBar progress={props.progress} color={(props.scheme && props.scheme.primary) || "indigo"} /> }
                        {props.type === ("label" as any)
                            ? <div className="variation neutral-value">{props.footNote}</div>
                            : renderVariation(props.variation, props.variationType, props.variationValueType, props.variationPeriod, props.variationStartDate, props.variationText)
                        }
                    </>
                    : <>
                        <h1>
                            {props.title}<span className={props.newLineSubTitle ? "new-line" : undefined}>{props.subTitle}</span>
                        </h1>
                        {/* [WIP]: COUNT CRITICALS */}
                        <div className={'flagged-items'}>
                            <div>
                                <h2 style={{ color: (props.scheme && props.scheme.primary) || "indigo" }}>
                                    {props.valuePrefix}{props.type === "currency" && "$"}{props.type === "number"
                                        ? (props.value >= 1000
                                            ? props.value.toLocaleString()
                                            : (props.value as number).toFixed(2))
                                        : props.value
                                    }{props.type === "percent" && "%"}
                                </h2>
                                {props.type === ("label" as any)
                                ? <div className="variation neutral-value">{props.footNote}</div>
                                : renderVariation(props.variation, props.variationType, props.variationValueType, props.variationPeriod, props.variationStartDate, 'critical')
                                }
                            </div>
                                <div>
                                <h2 style={{ color: (props.scheme && props.scheme1.primary) || "indigo" }}>
                                    {props.type === "currency" && "$"}{props.type === "number"
                                            ? (props.value1 >= 1000
                                                ? props.value1.toLocaleString()
                                                : (props.value1 as number).toFixed(2))
                                            : props.value1
                                        }{props.type === "percent" && "%"}
                                </h2>
                                {props.type === ("label" as any)
                                    ? <div className="variation neutral-value">{props.footNote}</div>
                                    : renderVariation(props.variation, props.variationType, props.variationValueType, props.variationPeriod, props.variationStartDate, 'warnings')
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    </Grid>

    <div className="bottomNote">
        {props.footNote || ""}
    </div>
</div>;
} else {
    return <div className="cs-progress-card flexible">
    <Grid container>
        {props.icon && <Grid item xs={3}>
            <CSUIIcon icon={props.icon as any} />
        </Grid>}
        <Grid item xs={props.icon ? 9 : 12}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <h1>
                {props.title}<span className={props.newLineSubTitle ? "new-line" : undefined}>{props.subTitle}</span>
            </h1>
            <h2 style={{ color: (props.scheme && props.scheme.primary) || "indigo" }}>
                {props.type === "currency" && "$"}{props.type === "number"
                    ? (props.value >= 1000
                        ? props.value.toLocaleString()
                        : (props.value as number).toFixed(2))
                    : props.value
                }{props.type === "percent" && "%"}
            </h2>
            <div style={{margin: '10px 0'}}>
                {props.type === ("label" as any)
                    ? <div className="variation neutral-value">{props.footNote}</div>
                    : renderVariation(props.variation, props.variationType, props.variationValueType, props.variationPeriod, props.variationStartDate)
                }
            </div>
            </div>
        </Grid>
    </Grid>
    <DashboardProgressBar progress={props.progress} color={(props.scheme && props.scheme.primary) || "indigo"} />
    <div className="bottomNote">
        {props.footNote || ""}
    </div>
</div>;
}
}
export default ProgressCard;
