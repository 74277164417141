import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2, 3)
        },
        title: {
            textAlign: 'center'
        },
        buttonContainer: {
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'center'
        }
    })
);

export default useStyles;
