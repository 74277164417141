import React from 'react';
import { Route, RouteComponentProps } from "react-router-dom";

export type RouteObject = {
    path: string;
    exact: boolean;
    children?: RouteObject[];
} & ({
    element: JSX.Element;
    render?: (router: RouteComponentProps) => JSX.Element;
    component?: React.ComponentType | React.ReactNode;
} | {
    element?: JSX.Element;
    render: (router: RouteComponentProps) => JSX.Element
    component?: React.ComponentType | React.ReactNode;
} | {
    element?: JSX.Element;
    render?: (router: RouteComponentProps) => JSX.Element;
    component: React.ComponentType | React.ReactNode;
});

const routeObjectToRoute = (obj: RouteObject, pathPrefix = '') => {
    const { children, render, element, component, path, ...reqProps } = obj;
    const fullPath = pathPrefix + path

    const renderProps: any = component
    ? {component}
    : render
        ? { render }
        : { render: () => element }

    const outRoutes = [
        <Route key={fullPath} path={fullPath} {...reqProps} {...renderProps} />,
    ]

    if (obj.children) {
        obj.children.forEach(child => routeObjectToRoute(child, fullPath).forEach(x => outRoutes.push(x)));
    }

    return outRoutes;
}

export const generateRoutes = (routeObjs: RouteObject[]) => routeObjs.map(routeObj => routeObjectToRoute(routeObj));
