import React from 'react';
import TextField from '@material-ui/core/TextField';
import { CSUIButton } from '../../../components/CSUI/CSUIButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '../../../components/ConfirmModal';
import MaterialModal from '@material-ui/core/Modal';
import { Tag, SKUsList, defaultTags } from './datastructures';
import { set, cloneDeep } from 'lodash';
import { GlobalState } from '../../../libraries/types/ReportUp';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { ContentStatusDataService } from '../../../libraries/DataService';
import { CSUISwitch } from '../../../components/CSUI';
import "./style.scss"
import { TrackingService } from '../../../libraries/Tracking';

const getDefaultTags = () => cloneDeep(defaultTags);

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      fontSize: '1.1rem',
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
    list: {
      marginLeft: theme.spacing(2),
    },
    cardBody: {
      padding: theme.spacing(2),
    },
    saveButton: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
    modalBody: {
      textAlign: 'center',
    },
    controlLabel: {
      margin: 'unset',
    },
    content: {
      marginTop: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(0.5, 1),
      borderRadius: '3px',
      fontWeight: 600,
      fontSize: '.9rem',
    },
    newCustomTag: {
      marginTop: theme.spacing(1.5),
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  });

interface Props extends WithStyles<typeof styles> {
  setTags: (data: {skus?: SKUsList, tags?: Tag[]}) => void;
  skus: SKUsList;
  checked: { [rowId: string]: boolean };
  defaultTags?: Tag[];
  customTags?: Tag[];
  globalState: GlobalState;
  onCustomTagsUpdated?: (customTags: Tag[]) => void;
}
interface State {
  defaultTags: Tag[];
  customTags: Tag[];
  newTag: string;
  confirmation: boolean;
  tagToDelete?: any;
  completed: boolean;
  checkedTags: { [key: string]: boolean };
  partialTags: { [key: string]: boolean };
  overwrite: boolean;
}
class SKUTaggingView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      defaultTags: props.defaultTags || defaultTags,
      customTags: props.customTags || [],
      newTag: '',
      confirmation: false,
      tagToDelete: null,
      checkedTags: {},
      partialTags: {},
      completed: false,
      overwrite: false
    };
  }

  onTagSelectedGenerator = (tagType: 'default' | 'custom', tagId: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked },
    } = e;
    const { checkedTags } = this.state;
    checkedTags[tagId] = !checkedTags[tagId];

    if (tagType === 'default') {
      this.setState({
        defaultTags: this.state.defaultTags.map((tag: Tag) => {
            if (tag.id === tagId) {
              tag.selected = checked;
            }
            return tag;
          }),
        checkedTags,
      });
    } else if (tagType === 'custom') {
      this.setState({
        checkedTags,
        customTags: this.state.customTags.map((tag: Tag) => {
            if (tag.id === tagId) {
              tag.selected = checked;
            }
            return tag;
          })
      });
    }
  };

  getTeamId(): string {
    const teamId: string =
      this.props.globalState.activeUser.isSignedIn &&
      this.props.globalState.activeUser.user
        ? !this.props.globalState.activeUser.user.core ||
          !this.props.globalState.activeUser.user.core.teamId
          ? ''
          : this.props.globalState.activeUser.user.core.teamId
        : '';
    return teamId;
  }

  async componentDidMount() { 
    TrackingService.onEvent("viewTags");
    const customTags = this.props.customTags;

    const partialTags = {};
    const checkedTags = {};
    const tagCount = {};
    for (const skuIds in this.props.checked) {
      for (const tag in this.props.skus[skuIds]) {
        const tagData = this.props.skus[skuIds][tag];
        const value = tagData.selected ? 1 : 0;
        tagCount[tagData.id] = tagCount[tagData.id]
          ? tagCount[tagData.id] + value
          : value;
      }
    }
    let itemCount = 0;
    for (const val in Object.values(this.props.checked)) {
      if (!val) {
        continue;
      }
      itemCount++;
    }
    const { defaultTags } = this.state;

    for (const tag in tagCount) {
      checkedTags[tag] = tagCount[tag] === itemCount;
      partialTags[tag] = tagCount[tag] > 0;
      customTags.map((item) => {
        if (item.id === tag) {
          item.selected = checkedTags[tag];
        }
        return item;
      });

      defaultTags.map((item) => {
        if (item.id === tag) {
          item.selected = checkedTags[tag];
        }
        return item;
      });
    }

    this.setState({
      customTags,
      checkedTags,
      defaultTags,
      partialTags
    });
  }

  removeCustomTag = async (tagId: string): Promise<void> => {
    const teamId = this.getTeamId();
    await ContentStatusDataService.skuTags({teamId}).deleteTag(tagId);
    this.setState({
      customTags: this.state.customTags.filter(
          (tag: Tag) => tag.id !== tagId
        )
    },
    () => {
          if (this.props.onCustomTagsUpdated) {
            this.props.onCustomTagsUpdated(this.state.customTags);
          }
        });
  };

  handleNewTagChange = (e): void => {
    const { value } = e.target as HTMLInputElement;
    this.setState({ newTag: value });
  };

  addTag = async () => {
    if (!this.state.newTag) {
      return;
    } else if (
      this.state.customTags.find(
        (tag: Tag) =>
          tag.displayName.toLowerCase() === this.state.newTag.toLowerCase()
      )
    ) {
      this.setState({ newTag: '' });
    } else {
      const newCustomTagId =
        'tags_customs_tags_' +
        this.state.newTag
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9\s]/g, '')
          .replace(/\s/g, '_');

      const teamId = this.getTeamId();
      await ContentStatusDataService.skuTags({teamId}).createTag({ id: newCustomTagId, displayName: this.state.newTag, selected: false });

      this.setState(
        { newTag: '' },
        () => {
          if (this.props.onCustomTagsUpdated) {
            this.props.onCustomTagsUpdated(this.state.customTags);
          }
        }
      );
    }
  };

  saveChanges = () => {
    const { overwrite } = this.state
    const result: SKUsList = {};
    const output: SKUsList = { tags: {} };
    [...this.state.defaultTags, ...this.state.customTags]
    .filter(tag => overwrite ? true : tag.selected)
    .forEach((tag: Tag, position: number) => {
      set(output, `tags[${tag.displayName}]`, cloneDeep(tag));
    });
    
    Object.keys(this.props.checked).forEach((skuId: string, index: number) => {
      const tagsToSet = {
        ...(this.props.skus[skuId] || {}),
        ...output.tags
      }
      set(result, `[${skuId}]`, tagsToSet);
    });

    this.setState({ defaultTags: getDefaultTags(), completed: true }, async () => {
          const teamId = this.props.globalState.teamJobData.teamId;
          await ContentStatusDataService.skuTags({
            teamId,
          }).set(result);
        
          TrackingService.onEvent("saveTags");
        }
    );
  };

  renderSuccessDialog = () => {
    return (
        <MaterialModal open={this.state.completed} onClose={() => this.props.setTags({tags: this.state.customTags})}>
          <div className={this.props.classes.paper}>
            <h1>Success!</h1>
            <p>You may now close this window.</p>
            <CSUIButton onClick={() => this.props.setTags({tags: this.state.customTags})} color='green'>
              Close
            </CSUIButton>
          </div>
        </MaterialModal>
    );
  };

  displayDefaultTags = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <h1 className={classes.header}>System Tags</h1>
        <Grid container>
          {this.state.defaultTags.map((tag: Tag, index: number) => {
            const shouldBeChecked = this.state.checkedTags[tag.id] || tag.selected || false
            const indeterminate = this.state.overwrite ? false : !shouldBeChecked && !!this.state.partialTags[tag.id]
            return (
              <Grid className={'tagRow'} item xs={12}>
                <FormControlLabel
                  className={classes.controlLabel}
                  key={tag.id}
                  control={
                    <Checkbox

                      color='primary'
                      onChange={this.onTagSelectedGenerator('default', tag.id)}
                      key={`tag-${tag.id}`}
                      id={`tag-${tag.id}`}
                      checked={shouldBeChecked}
                      indeterminate={indeterminate}
                    />
                  }
                  label={tag.displayName}
                />
              </Grid>
            )
          })}
        </Grid>
      </React.Fragment>
    );
  };

  displayCustomTags = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <h1 className={classes.header}>Custom Tags</h1>
        <Grid container>
          {(this.state.customTags || []).map((tag: Tag) => {
            const shouldBeChecked = this.state.checkedTags[tag.id] || tag.selected || false
            const indeterminate = this.state.overwrite ? false : !shouldBeChecked && !!this.state.partialTags[tag.id]
            return (
              <Grid className={'tagRow'} container item xs={12} key={tag.id} alignItems='center'>
                <Grid item xs={11}>
                  <FormControlLabel
                    className={classes.controlLabel}
                    control={
                      <Checkbox
                        color='primary'
                        onChange={this.onTagSelectedGenerator('custom', tag.id)}
                        key={`tag-${tag.id}`}
                        id={`tag-${tag.id}`}
                        checked={shouldBeChecked}
                        indeterminate={indeterminate}
                      />
                    }
                    label={tag.displayName}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Close
                    className={classes.closeButton}
                    onClick={() => {
                      this.setState({
                        confirmation: true,
                        tagToDelete: tag.id,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </React.Fragment>
    );
  };

  displaySaveChangesAction = () => {
    const { classes, checked } = this.props;

    return (
      <div className={classes.saveButton}>
        <CSUISwitch 
          labelLeft="Update Tags" 
          labelRight="Overwrite Tags" 
          checked={this.state.overwrite}

          onChange={() => this.setState(prev => ({overwrite: !!!prev.overwrite}))}
        /><br />
        <CSUIButton disabled={!Object.keys(checked).length} color='green' onClick={this.saveChanges}>
          Save Changes
        </CSUIButton>
        <h4 className='purple-text'>IMPORTANT</h4>
        <p>To view your Tags, add them from your View tab.</p>
      </div>
    );
  };

  displayAddTagForm = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <h1 className={classes.header}>Add a New Custom Tag</h1>
        <Grid
          container
          alignItems='center'
          className={classes.newCustomTag}
          spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              type='text'
              label='Enter tag name'
              value={this.state.newTag}
              onChange={this.handleNewTagChange}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.keyCode === 13 && this.state.newTag) {
                  this.addTag();
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CSUIButton
              disabled={!this.state.newTag}
              onClick={this.addTag}
              color='green'>
              Add
            </CSUIButton>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render = () => {
    const { classes, checked } = this.props;
    const selectedSKUs = checked && Object.keys(checked).length;

    return (
      <Grid className='catalog-filters catalog-tags-dropdown' container spacing={2}>
        {this.renderSuccessDialog()}
        <Modal
          isOpen={this.state.confirmation}
          body={
            <h3 className={classes.modalBody}>
              {'Are use sure you want to delete this Tag?'}
            </h3>
          }
          onCancel={() => {
            this.setState({
              tagToDelete: null,
              confirmation: false,
            });
          }}
          onSubmit={() => {
            if (this.state.tagToDelete) {
              this.removeCustomTag(this.state.tagToDelete);
            }
            this.setState({
              confirmation: false,
              tagToDelete: null,
            });
          }}
        />
        <Grid container item xs={8}>
          <Grid className={classes.cardBody} item xs={12}>
            <Grid>
              <h1 className='h1-simple'>{`You've Selected ${selectedSKUs} SKUS for Tagging`}</h1>
              <p>
                Select only the Tags you want associated with these specific
                SKUS.
              </p>
            </Grid>
            <Grid container item spacing={2} className={classes.content}>
              <Grid item xs={4}>
                {this.displayDefaultTags()}
              </Grid>
              <Grid item xs={4}>
                {this.displayCustomTags()}
              </Grid>
              <Grid item xs={4}>
                {this.displayAddTagForm()}
                {this.displaySaveChangesAction()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <h2 className='purple-text' style={{fontWeight: 900}}>Helpful Tip</h2>
              <h4 className='purple-text'>
                What's a tag and how should I use them?
              </h4>
              <p className=''>
                Adding tags to your SKUS allow you to easily view insights
                specific to the tagged groups that you select.
              </p>
              <p className='small'>
                <b>Example use cases</b>
              </p>
              <ol className={classes.list}>
                <li>
                  Tagging SKUS that have a high return rate so you can review
                  their Health Status and Accuracy Status.
                </li>
                <li>
                  Tagging SKUS that are new launches so you can review their
                  Live Status.
                </li>
                <li>
                  Tagging competitive SKUS to compare how their content grades
                  out vs your own.
                </li>
              </ol>
              <p className='small'>
                <b>Update vs Overwrite</b>
              </p>
              <ul className={classes.list}>
                <li>
                  Update Tags: Only the checked tags will be saved to each SKU that has been selected for tagging. (This is the default behavior.)
                </li>
                <li>
                  Overwrite Tags: All tags will be saved to each SKU that has been selected for tagging. (This means that the tags which are not checked will be deleted from each SKU.)
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };
}

export default withStyles(styles, { withTheme: true })(SKUTaggingView);
