import * as React from 'react';
import { GlobalState } from '../../libraries/types/ReportUp';
import { MDBIcon, MDBCard, MDBCardBody, MDBContainer, MDBBtn } from "mdbreact";
import './style.scss';
import { Notification, sampleNotifications } from './datastructures';
import { CSUISegment } from '../CSUI';
import GlobalStateProvider from '../GlobalStateProvider';

interface NotificationsProps {
    globalState: GlobalState;
}

interface NotificationsState {
    notifications: Notification[]
}

class Notifications extends React.Component<NotificationsProps, NotificationsState> {

    constructor(props: NotificationsProps) {

        super(props);
        this.state = {
            notifications: sampleNotifications
        }

    }

    _dismiss = (notification: Notification) => {
        this.setState({ notifications: this.state.notifications.filter((n: Notification) => n.id !== notification.id) });
        if (notification.onAction) {
            notification.onAction(notification);
        } else if (notification.actionText && notification.link) {
            window.open(notification.link);
        }
    }

    _displayNotification = (notification: Notification) => <MDBContainer key={`notification-${notification.id}`} className="notification-container">
        <MDBCard style={{ borderColor: notification.color || "#6f42c1" }}>
            <MDBCardBody>
                <div className="d-flex flex-row notification">
                    <span className="date">{notification.date}</span>
                    <div className="icon" style={{ color: notification.color || "#6f42c1" }}>
                        <MDBIcon icon={notification.icon || "bell"} />
                    </div>
                    <div className="content">
                        {notification.link && <a href={notification.link} target="_blank" rel="noopener noreferrer" style={{ color: notification.color || "#6f42c1" }}>{notification.title}</a>}
                        {!notification.link && <h1 style={{ color: notification.color || "#6f42c1" }}>{notification.title}</h1>}
                        <p>{notification.text}</p>
                    </div>
                    <div className="cta">
                        <MDBBtn gradient="purple" onClick={() => this._dismiss(notification)}>{notification.actionText || "Dismiss"}</MDBBtn>
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>
    </MDBContainer>;

    render() {

        return (
            <div className="notifications-container">
                <h1 className="cs-purple notifications-header">Notifications</h1>
                <CSUISegment>
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                        <div>
                            {(this.state.notifications.length === 0)
                                ? <div className="d-flex flex-column align-items-center width-padding">
                                    <MDBIcon far icon="bell-slash" size="5x" className="width-padding" />
                                    <p className="width-padding">
                                        You do not have any notifications at this time, check back later.
                                    </p>
                                </div>
                            : null}
                            <div className="notifications-holder d-flex flex-column align-items-center width-padding">
                                {this.state.notifications.map((n: Notification) => this._displayNotification(n))}
                            </div>
                        </div>
                    </div>
                </CSUISegment>
            </div>
        );
    }
}

export default GlobalStateProvider(Notifications);