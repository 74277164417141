import * as qs from 'query-string';
import {flatten, transform} from 'lodash';
import { createBrowserHistory } from "history";

export function querystringparser(str: string, options?: qs.ParseOptions): { [x: string]: string[] | undefined} {
    return transform(qs.parse(str, options), (result, value, key) => {result[key] = (value) ? flatten([value]) : undefined});
}

const browserHistory = createBrowserHistory();
const basename = "car"

/*
Account switchin strategies:

1: "Google Approach"/path based

Use dynamic prefixes: "/u/{integer}"
Use newPush fn below & set basename on Router

2: Query param based

Detect ttuid on load, store in newly created history service, remove from url bar
Inject this ttuid into all app links

*/

/* const oldPush = browserHistory.push;

const newPush = (arg1, arg2) => {
  const prefix = "/"+basename
  let newArg1 = arg1;
  const getNewPathName = (oldPathname) => {
    return (oldPathname.startsWith(prefix) ? "" : prefix) + oldPathname
  }
  if (typeof arg1 === 'string') {
    newArg1 = getNewPathName(arg1);
  } else if (typeof arg1 === 'object' && !!arg1.pathname) {
    newArg1 = {
      ...arg1,
      pathname: getNewPathName(arg1.pathname)
    };
  }
  return oldPush(newArg1, arg2);
}

browserHistory.push = newPush; */

export const history = browserHistory;