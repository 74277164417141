import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1% 3%',
    width: '100%',
  },
  contentContainer: {},
}));

interface BasePageLayoutProps {
  className?: string;
}

const BasePageLayout: React.FunctionComponent<BasePageLayoutProps> = (
  props
) => {
  const classes = useStyles();

  const className = props.className || '';

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.contentContainer, "layoutContent")}>{props.children}</div>
    </div>
  );
};

export default BasePageLayout;
