import * as React from 'react';
import { MDBRow, MDBCol, MDBCard } from 'mdbreact';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import { GlobalState } from '../../../../libraries/types/ReportUp';
import { SKUWithKeywords } from '../../../../pages/Catalog/SKUKeywords/datastructures';
import { countKeywordInstances } from '../../data';

interface KeywordUsageProps {
    sku: SkuRecord;
    globalState: GlobalState;
    style?: React.CSSProperties;
    skuKeywords: SKUWithKeywords;
}

interface KeywordUsageState {
}

class KeywordUsageColumn extends React.Component<KeywordUsageProps, KeywordUsageState> {

    constructor(props: KeywordUsageProps) {
        super(props);

        this.state = {}
    }

    countInstances = (keyword: string, location: "title" | "description" | "bullets") => {
        return countKeywordInstances(this.props.sku, keyword, location);
    }

    renderKeywords = (type: "primary" | "secondary", sku: SKUWithKeywords) => {
        return <div style={{ minHeight: 88 }}>
            {(!sku || !sku[`${type}Keywords`] || sku[`${type}Keywords`].length === 0) && <MDBRow className="padded">
                <MDBCol size="12">
                    None designated
                </MDBCol>
            </MDBRow>}
            {sku[`${type}Keywords`].map((keyword: string, index: number) => <MDBRow key={`${type}-keyword-${index}`} className="padded">
                <MDBCol size="6">
                    {keyword}
                </MDBCol>
                <MDBCol size="2" className="text-center">
                    {this.countInstances(keyword, "title")}
                </MDBCol>
                <MDBCol size="2" className="text-center">
                    {this.countInstances(keyword, "description")}
                </MDBCol>
                <MDBCol size="2" className="text-center">
                    {this.countInstances(keyword, "bullets")}
                </MDBCol>
            </MDBRow>)}
        </div>
    }

    render() {
        const { style, skuKeywords } = this.props;

        return (
            <React.Fragment>
                {
                    skuKeywords ? <MDBCard className="card-body dark-container" {...{ style }}>
                    <MDBRow className="titleRow">
                        <MDBCol size="6">
                            <div>
                                <div>
                                    <h1 className="dual">Keyword<span>Usage</span></h1>
                                </div>
                                <div>
                                    on the EDITED version
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <div className="restBody" style={{ }}>
                        <MDBCard className="card-body white-container">
                            <div className="keywords-table">
                                <MDBRow className="table-head padded">
                                    <MDBCol className="little-header" size="6">
                                        Primary
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Title
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Desc.
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Bullets
                                    </MDBCol>
                                </MDBRow>
                                {this.renderKeywords("primary", skuKeywords)}
                                <MDBRow className="table-head padded">
                                    <MDBCol className="little-header" size="6">
                                        Secondary
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Title
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Desc.
                                    </MDBCol>
                                    <MDBCol size="2" className="text-center little-header no-padding">
                                        Bullets
                                    </MDBCol>
                                </MDBRow>
                                {this.renderKeywords("secondary", skuKeywords)}
                            </div>
                            <div className="boxed-content text-center">
                                <b>Want to add/edit keywords?</b>
                                <div>Click the Keyword button in the gray tool bar above.</div>
                            </div>
                        </MDBCard>
                    </div>
                </MDBCard> : null
                }
            </React.Fragment>
        )
    }
}

export default KeywordUsageColumn;