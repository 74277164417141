import React from 'react'
import styles from './styles'

const RecomendedPlan: React.FunctionComponent = () => {
  const classes = styles();
  return (
    <div className={classes.greenLabel}>
      RECOMMENDED
    </div>
  )
}

export default RecomendedPlan