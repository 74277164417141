import * as React from "react";
import "./style.scss";
import { GlobalState } from "../../../libraries/types/ReportUp";
import { ContentStatusDataService, TeamBalance } from "../../../libraries/DataService";
import { CSUIButton, CSUISelect } from "../../../components/CSUI";
import SuccessDialog from "../../../components/Dialogs/successDialog";
import { API_CALL_teamBalanceSet, API_CALL_customPlanSubscription } from "../../../libraries/Util/apiClient";
import { SinglePlan } from "../../../libraries/types/chargebeeStructure";
import { ChargebeeAppClass, ChargebeeService } from "../../../libraries/ScriptServices/Chargebee";
import { firestoreDb } from "../../../libraries/Util";

interface AdminUITeamProps {
    globalState: GlobalState;
    teamId: string;
}

interface State {
    teamBalance?: TeamBalance;
    dataLoading: boolean;
    completed?: string;
    amount?: number;
    planSelections: string[];
    newPlanSelections: string[];
    plans?: {[plan_id: string]: SinglePlan};
    email: string;
    planType?: string;
    freeTrialLimit: number | null;
}

class AdminUITeam extends React.Component<AdminUITeamProps, State> {

    private chargebeeApp: ChargebeeAppClass;

    constructor(props: AdminUITeamProps) {
        super(props);

        this.state = {
            dataLoading: true,
            completed: "",
            planSelections: [],
            newPlanSelections: [],
            plans: {},
            email: '',
            freeTrialLimit: null
        };
    }

    async componentDidMount() {
        await this.setTeamBalance();

        const {teamId} = this.state.teamBalance;
        this.chargebeeApp = ChargebeeService.get({teamId, chargebeeCustomerId: teamId});

        if (this.chargebeeApp) {
            const plans: State['plans'] = {};
            const plansArray = await this.chargebeeApp.getPlans();
            plansArray.forEach(plan => { plans[plan.plan.id] = plan});

            this.setState({plans});
        }

        
        let planType: string = undefined;
        let freeTrialLimit = null;
        const teamAccountDoc = await firestoreDb.collection("TeamAccount").doc(teamId).get();
        if (teamAccountDoc.exists) {
            const teamAccountData = teamAccountDoc.data();
            if (teamAccountData?.adminOverride?.planType) {
                planType = teamAccountData?.adminOverride?.planType;
            }
            if (typeof teamAccountData.freeTrialLimit === 'number') {
                freeTrialLimit = teamAccountData.freeTrialLimit;
            }
        }
        this.setState({planType, newPlanSelections:[planType], freeTrialLimit});
    }

    setTeamBalance = async () => {
        const teamBalance = await ContentStatusDataService
            .teamBalance({ teamId: this.props.teamId })
            .get({ forceRefresh: true });

        this.setState({ teamBalance, dataLoading: false, amount: teamBalance.balance });
    }

    componentWillUnmount() {
    }

    balanceInfo = () => {
        if (this.state.teamBalance) {

            const fmtNum = (num: any) => typeof num === 'number'
                ? num.toLocaleString()
                : "--"

            const label = <span className="label">Balance:</span>

            const { balance } = this.state.teamBalance

            return <div className="jobTableBalanceInfo">
                <div>{label} {fmtNum(balance)} </div>
            </div>
        } else {
            return null
        }
    };

    renderSuccessDialog = () => {
        return <SuccessDialog title="Success!" onClose={this.closeSuccessDialog} open={!!this.state.completed}>
            <p>{this.state.completed}</p>
        </SuccessDialog>;
    }

    closeSuccessDialog = () => {
        this.setState({
            completed: "",
        })
    }

    setAmount = (e: React.FormEvent<HTMLInputElement>): void => {
        const { value } = e.target as HTMLInputElement;
        const amount = parseInt(value, 10);
        this.setState({ amount });
    }

    setCreditInput = () => {
        const value = this.state.amount !== undefined && !isNaN(this.state.amount)
        ? this.state.amount
        : '';

        return <React.Fragment>
            <div className="row-container">
                <div className="column-container">
                    <header><span>New Balance</span></header>
                </div>
            </div>
            <div className="row-container">
                <div className="column-container">

                    <input value={value} className="plain-cs-input" type='number'
                        name="amount"
                        onChange={this.setAmount}
                    />
                </div>
            </div>

        </React.Fragment>
    }

    setCreditAmount = async () => {
        const {teamId} = this.props;

        const {amount} = this.state;

        await API_CALL_teamBalanceSet(teamId, amount);
        await this.setTeamBalance();

        this.setState({completed: "The credit amount has been successfully set!"});
    }

    setCreditButton = () => {
        const amount = this.state.amount || 0;
        const disabled = amount < 0 || amount === this.state.teamBalance?.balance;

        return <CSUIButton onClick={this.setCreditAmount} disabled={disabled} color="green">Set Credit Balance</CSUIButton>
    }

    plansDropdown = () => {
        const {planSelections, plans} = this.state;

        const options = Object.keys(plans)
        .filter(plan_id => plans[plan_id].plan.cf_one_time_payment !== "True")
        .map(plan_id => {
            return {
                value: plan_id,
                text: plans[plan_id].plan.name
            }
        });

        const onChange = (planSelections) => {
            if (planSelections[0] !== this.state.planSelections[0]) {
                this.setState({planSelections})
            }
        }
        
        return <CSUISelect options={options} values={planSelections} onChange={onChange} />
    }

    emailInput = () => {
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement;

            if (value !== this.state.email) {
                this.setState({email: value})
            }
        }

        return <input 
            value={this.state.email} 
            className="" 
            type='email' 
            placeholder='Email Address'
            name="email"
            onChange={onChange}
        />
    }

    sendEmailButton = () => {
        const { planSelections: plans, email } = this.state;
        const { teamId } = this.props;

        const plan_id = plans[0];

        const disabled = !(email && teamId && plan_id);

        const onClick = () => {
            API_CALL_customPlanSubscription(teamId, plan_id, email);
        }

        return <CSUIButton onClick={onClick} disabled={disabled} color="green">Send Email</CSUIButton>
    }

    newPlans = [
        {value: 'daily', text: 'Genius (daily)'},
        {value: 'weekly', text: 'Brilliant (weekly)'},
        {value: 'monthly', text: 'Smart (monthly)'},
    ]

    planTypeInfo = () => {
        if (this.state.planType) {

            const planObj = this.newPlans.find(plan => plan.value === this.state.planType);

            const label = <span className="label">Plan Type:</span>

            return <div className="jobTableBalanceInfo">
                <div>{label} {planObj.text} </div>
            </div>
        } else {
            return null
        }
    };

    newPlansDropdown = () => {
        const {newPlanSelections} = this.state;

        const options = this.newPlans;

        const onChange = (newPlanSelections) => {
            if (newPlanSelections[0] !== this.state.newPlanSelections[0]) {
                this.setState({newPlanSelections})
            }
        }
        
        return <CSUISelect options={options} values={newPlanSelections} onChange={onChange} />
    }

    setPlanTypeButton = () => {
        return <CSUIButton onClick={async () => {
            if (this.state.newPlanSelections[0]) {
                await firestoreDb.collection("TeamAccount")
                .doc("8515b5ef-8e55-483c-97c4-a1debfa8042c")
                .update({"adminOverride.planType": this.state.newPlanSelections[0]});
    
                this.setState(prevState => ({completed: "The plan type has been successfully set!", planType: prevState.newPlanSelections[0]}));
            }
        }} disabled={this.state.planType === this.state.newPlanSelections[0]} color="green">Set Plan Type</CSUIButton>
    }

    setLimitInput = () => {
        const value = this.state.freeTrialLimit !== undefined && !isNaN(this.state.freeTrialLimit)
        ? this.state.freeTrialLimit
        : '';

        return <React.Fragment>
            <div className="row-container">
                <div className="column-container">
                    <header><span>New Free Trial Limit</span></header>
                </div>
            </div>
            <div className="row-container">
                <div className="column-container">

                    <input value={value} className="plain-cs-input" type='number'
                        name="amount"
                        onChange={this.setLimitInputHandler}
                    />
                </div>
            </div>

        </React.Fragment>
    }

    setLimitInputHandler = (e: React.FormEvent<HTMLInputElement>): void => {
        const { value } = e.target as HTMLInputElement;
        const freeTrialLimit = parseInt(value, 10);
        this.setState({ freeTrialLimit });
    }

    setLimitAmount = async () => {
        const {teamId} = this.state.teamBalance;

        const {freeTrialLimit} = this.state;

        const teamAccountDoc = await firestoreDb.collection("TeamAccount").doc(teamId);
        await teamAccountDoc.update({freeTrialLimit: isNaN(freeTrialLimit) ? null : freeTrialLimit});
        this.setState({completed: "The free trial limit has been successfully set!"});
    }

    setLimitButton = () => {
        return <CSUIButton onClick={this.setLimitAmount} color="green">Set Free Trial Limit</CSUIButton>
    }

    render() {
        return <div className="teamMemberTable cs-inputs-container team-adminUi">
            {this.renderSuccessDialog()}
            <div className="tableTitle">Manage Free Trial Limit</div>
            <div className="addUsersTable" style={{marginBottom: 40}}>
                <div style={{ width: "71%" }} className="rt-copy-container no-border">
                    <div className="table-columns-container setCreditInputContainer">
                        {this.setLimitInput()}
                    </div>
                    <div className="action-button-container">
                        {this.setLimitButton()}
                    </div>
                </div>
            </div>
            <div className="tableTitle">Manage Account Plan Type</div>
            {this.planTypeInfo()}
            <div className="addUsersTable" style={{marginBottom: 40}}>
                <div style={{ width: "71%" }} className="rt-copy-container no-border">
                    <div className="table-columns-container selectPlanDropdownDiv">
                        {this.newPlansDropdown()}
                    </div>
                    <div className="action-button-container">
                        {this.setPlanTypeButton()}
                    </div>
                </div>
            </div>
            <div className="tableTitle">Manage Credit Balance</div>
            {this.balanceInfo()}
            <div className="addUsersTable" style={{marginBottom: 40}}>
                <div style={{ width: "71%" }} className="rt-copy-container no-border">
                    <div className="table-columns-container setCreditInputContainer">
                        {this.setCreditInput()}
                    </div>
                    <div className="action-button-container">
                        {this.setCreditButton()}
                    </div>
                </div>
            </div>
            <div className="tableTitle">Send Custom Plan</div>
            <div className="addUsersTable">
                <div style={{ width: "71%" }} className="rt-copy-container no-border planSelectDiv">
                    <div className="table-columns-container selectPlanDropdownDiv">
                        Plan: {this.plansDropdown()} <br />
                        Email: {this.emailInput()}
                    </div>
                    <div className="">
                        {this.sendEmailButton()}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default AdminUITeam;
