import { Objectkeys } from "../types/datastructures";
import Papa from "papaparse";

const cleanUploadedCsv = (results: Papa.ParseResult) => {
    const badHeaderSet = {};
    results.meta.fields = results.meta.fields.filter(rowHeader => {
        if (rowHeader) {
            return true;
        } else {
            badHeaderSet[rowHeader] = void 0;
            return false;
        }
    });
    results.data.forEach(rowObject => {
        Objectkeys(badHeaderSet).forEach(rowHeader => {
            delete rowObject[rowHeader];
        })
    });
    return results;
}

async function parseUploadedCsv(file: File) {
    return new Promise<Papa.ParseResult>((resolve, reject) => {
        Papa.parse(file, {
            skipEmptyLines: true,
            header: true,
            complete: results => resolve(cleanUploadedCsv(results)),
            error: (error, file) => reject({error, file}),
            ...({ transform: ((val: string) => val.trim()) } as any)
        });
    });
}

export const CSVUtils = {
    parse: parseUploadedCsv
}