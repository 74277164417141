import React from 'react';
import { GlobalState, GlobalStateAppContextConsumer } from '../../libraries/types/ReportUp';

// after implementing a redux store remove
const GlobalStateAppProvider = <T extends {globalState?: GlobalState}>(
  WrappedComponent: React.ComponentType<T>
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithGlobalState = (props: Omit<T, "globalState">) => {
    // Fetch the props you want to inject. This could be done with context.
    // props comes afterwards so the can override the default ones.
    return <GlobalStateAppContextConsumer>
      {(globalState) => <WrappedComponent globalState={globalState} {...props as T} />}
    </GlobalStateAppContextConsumer>;
  };

  ComponentWithGlobalState.displayName = `withGlobalState(${displayName})`;
  
  return ComponentWithGlobalState as React.ComponentType<Omit<T, "globalState">>
}

export default GlobalStateAppProvider;
