import { makeAutoObservable } from "mobx";
import * as CSApiClient from "../libraries/Util/apiClient";
import { WorkflowFolder } from "../pages/Workflow/types";
import { Objectentries } from "../libraries/types/datastructures";


const genEmptyTeamData = () => ({
    workflowFolders: undefined,
    workflowFolderList: null,
}) as any;

export class WorkflowStore {
    workflowFolders: any;
    activeTeamId: string;
    teamData: any;
    loadedTeamIds: any;
    dataSubscribers: any;
    workflows: any;
    stages: any;
    workflowInfo: any;

    constructor() {
        makeAutoObservable(this);

        this.stages = null
        this.workflowInfo = null
        this.workflows = [];
        this.teamData = {};
        this.activeTeamId = null;
        this.loadedTeamIds = [];
        this.dataSubscribers = {};
    }

    getStagesInfo = async (teamId?: string) => {
        const { data }: any = await CSApiClient["API_CALL_getWorkflowInfo"](teamId)
        //const { name, author, skuAmount, tags, stages } = data[Object.keys(data)[0]]
        this.workflowInfo = data;
        //this.stages = { ...stages }
    }

    getWorkflowFolderList = async (teamId?: string) => {
        this.initTeam(teamId, false);
        const data = await this.workflowFolderListFn({ teamId: teamId });
        const workflows = Objectentries(data).map((workflow: WorkflowFolder) => {
            return {
                id: workflow.id,
                name: workflow.name
            }
        })
        this.workflows = workflows
    }


    initTeam = (teamId: string, setAsActiveTeamId: boolean = true) => {
        if (!!teamId && typeof teamId === 'string') {
            this.teamData[teamId] = genEmptyTeamData();
            this.loadedTeamIds.push(teamId);
            this.dataSubscribers[teamId] = {};
            if (setAsActiveTeamId || !!!this.activeTeamId) {
                this.activeTeamId = teamId;
            }
        } else {
            throw new Error(`teamId parameter value is invalid: ${teamId}`);
        }
    }


    private workflowFolderListFn = async (options: { teamId: string }) => {
        if (this.teamData !== undefined) {
            if (this.teamData.hasOwnProperty(options.teamId) && !this.teamData[options.teamId].workflowFolderList) {
                await this.workflowFolderListLoad(options);
            }
            return this.teamData[options.teamId].workflowFolderList;
        }
    }


    private workflowFolderListLoad = async (options: { teamId: string }) => {
        const workflowFolders = await CSApiClient["API_CALL_LoadWorkflowFolderList"](options.teamId);
        this.teamData[options.teamId].workflowFolderList = workflowFolders.data.data;
    }
}

export const workflowStore = new WorkflowStore()