import React from 'react';
import { JobProduct, KeyWordStats } from '../../datastructures';
import ProgressCard from '../ProgressCard';
import { CSUIIcon } from '../../../../components/CSUI';
import { SKUWithKeywords } from '../../../../pages/Catalog/SKUKeywords/datastructures';
import Grid from '@material-ui/core/Grid/Grid';
import './style.scss';

interface KeywordSnapshotProps {
    product: JobProduct,
    sku: SKUWithKeywords
}

class KeywordSnapshot extends React.Component<KeywordSnapshotProps> {
    getProgressCardSettings = (card: "total" | "primary" | "secondary", product: JobProduct) => {

        switch (card) {
            case "total":
                return {
                    title: "Total",
                    subTitle: "Usage",
                    ...product.keywordSnapshot.total
                };
            case "primary":
                return {
                    title: "Primary",
                    subTitle: "Usage",
                    ...product.keywordSnapshot.primary
                };
            case "secondary":
                return {
                    title: "Secondary",
                    subTitle: "Usage",
                    ...product.keywordSnapshot.secondary
                };
        }
    }

    renderKeywordsTable = (table: "primary" | "secondary") => {
        return <div className="keywords-table">
            <Grid container className="table-head padded">
                <Grid item xs={6}>
                    {table === "secondary" ? "Secondary" : "Primary"} Keyword
                </Grid>
                <Grid item xs={2} className="text-center">
                    Title
                </Grid>
                <Grid item xs={2} className="text-center">
                    Desc.
                </Grid>
                <Grid item xs={2} className="text-center">
                    Bullets
                </Grid>
            </Grid>
            {this.props.product.keywordSnapshot.stats[table].length === 0 && <Grid container><Grid item xs={12} style={{ paddingLeft: "30px" }}>None designated</Grid></Grid>}
            {this.props.product.keywordSnapshot.stats[table].map((stat: KeyWordStats, index: number) => {

                return <Grid container key={`${table}-keyword-stats-${index}`} className="padded">
                    <Grid item xs={6} className={stat.warning ? "warning" : undefined}>
                        {stat.keyword}
                    </Grid>
                    <Grid item xs={2} className={`text-center ${stat.title >= 3 ? 'bolden' : ''}`}>
                        {stat.title}
                    </Grid>
                    <Grid item xs={2} className={`text-center ${stat.description >= 3 ? 'boldened with-warning' : ''}`}>
                        {stat.description}
                    </Grid>
                    <Grid item xs={2} className={`text-center ${stat.bullets >= 3 ? 'bolden with-warning ' : ''}`}>
                        {stat.bullets}
                    </Grid>
                </Grid>
            })}
        </div>;
    }

    render() {
        return <Grid className="card-body dark-container">
            <CSUIIcon className="header-icon" icon="dash statuskeyword" />
            <Grid container>
                <Grid xs={8}>
                    <h1 className="dual">Keyword<span>Usage</span></h1>
                </Grid>
            </Grid>
            <div className="cards-line tight top">
                <ProgressCard {...this.getProgressCardSettings("total", this.props.product)} variationType={"none"} />
                <ProgressCard {...this.getProgressCardSettings("primary", this.props.product)} variationType={"none"} />
                <ProgressCard {...this.getProgressCardSettings("secondary", this.props.product)} variationType={"none"} />
            </div>
            <Grid className="card-body white-container">
                {this.renderKeywordsTable("primary")}
                {this.renderKeywordsTable("secondary")}
            </Grid>
        </Grid>
    }
}

export default KeywordSnapshot;