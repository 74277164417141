import React from 'react';
import { MDBCard, MDBRow, MDBCol } from 'mdbreact';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface BlankMasterColumnProps {
    sku: SkuRecord
}

const BlankMasterColumn = (props: BlankMasterColumnProps) => {
    return <MDBCard className="card-body dark-container standard-size">
        <MDBRow className="titleRow">
            <MDBCol size="6">
                <div>
                    <div>
                        <h1 className="dual">Master<span>Version</span></h1>
                    </div>
                    <div className="date-row">
                        <div className="date-container">{moment(props.sku.timestamp).format("dddd MM/DD/YYYY")}</div>
                    </div>
                </div>
            </MDBCol>
            <MDBCol size="6" className="text-right">
                <div className="underlined-link standard-link">master_dataset</div>
                <div className="plain-link standard-link block smaller-link">change</div>
            </MDBCol>
        </MDBRow>
        <div className="restBody">
            <div className="whiteBox white-container-bg p-like text-center">
                <p>Ooops! It looks like you haven't mapped this job SKU to an external data sheet for accuracy comparisson.</p>
                <p><Link to="#" className="underlined-link standard-link">Click here to setup</Link> or choose a different content type.</p>
            </div>

        </div>
    </MDBCard>
}
export default BlankMasterColumn;