import React from 'react';
import { JobProduct } from '../../datastructures';
import ScoreDisplay from '../../Common/ScoreDisplay';
import { ColorScheme } from '../../../../pages/Dashboard/datastructures';
import StarRating from '../StarRating';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import { ImagePopup, VideoPopup } from '../../Common/AssetPopup';
import { SKUWithKeywords } from '../../../../pages/Catalog/SKUKeywords/datastructures';
import { getSkuKeywordsStats, defaultKeywordsStats } from '../../data';
import ImageBox from '../../Common/ImageBox';
import HighlightedText from '../../Common/HighlightedText';
import Popup from "reactjs-popup";
import { getLatestRubric } from '../../../../libraries/Util/utils';
import { Link } from 'react-router-dom';
import { retailerNameRender } from '../../../../libraries/types/datastructures';
import Grid from '@material-ui/core/Grid/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import './style.scss';
import AmazonPrimeLogo from '../../../../media/CatalogProductIcons/Amazon_Prime_Logo.png';
import { PDPSpecsTable } from '../../Common/Misc';

interface ContentSnapshotProps {
    product: JobProduct;
    skuRecord: SkuRecord;
    scheme?: ColorScheme,
    sku: SKUWithKeywords
}

interface ContentSnapshotState {
    currentSnapshot: number
}

export default class ContentSnapshot extends React.Component<ContentSnapshotProps, ContentSnapshotState> {

    constructor(props: ContentSnapshotProps) {
        super(props);

        this.state = {
            currentSnapshot: 0
        }
    }

    selectSnapshot = (options: string[]) => {
        if (options.length > 0 && parseInt(options[0]) !== this.state.currentSnapshot) {
            this.setState({ currentSnapshot: parseInt(options[0]) })
        }
    }

    renderNonGradedSpecs = () => {
        const { otherSpecs } = this.props.product.contentSnapshot[this.state.currentSnapshot].nonGradedSpecs;
        const filteredOtherSpecs = {...otherSpecs};
        [
            "360 View",
            "A+ Modules Count",
            "Enhanced Content"
        ].forEach(key => {
            delete filteredOtherSpecs[key];
        })

        return <div className="non-graded-specs">
            <div>
                <label className="field solid multi-line smaller">
                    Other Specifications
                </label>
                <PDPSpecsTable specs={filteredOtherSpecs} />
            </div>
        </div>
    }

    renderRichMedia = () => {
        const other = this.props.skuRecord.skuData.Other || {};
        const specs = this.props.skuRecord.skuData.Specifications.Other;
        const rubric = getLatestRubric(this.props.skuRecord);
        const grades = this.props.skuRecord.status.health.rubric[rubric].scores?.richContent;

        const yesOrNo = (val: any) =>  {
            const res = typeof val === 'string' ? JSON.parse(val) : val;
            return <h3>{res ? 'Yes' : 'No'}</h3>
        }

        return <Grid container className="with-border-bottom">
            <Grid item xs={2}>
                <ScoreDisplay title="360 View" score={grades?.view360} />
            </Grid>
            <Grid item xs={4}>
                <label className="field solid multi-line smaller">
                    Has 360 View?
                </label>
                {yesOrNo(other['360 View'] || specs['360 View'])}
            </Grid>
            <Grid item xs={2}>
                <ScoreDisplay title="Enhanced Content" score={grades?.enhancedContent} />
            </Grid>
            <Grid item xs={4}>
                <label className="field solid multi-line smaller">
                    Has Enhanced Content?
                </label>
                {yesOrNo(other['Enhanced Content'] || specs['Enhanced Content'])}
            </Grid>
        </Grid>
    }

    renderRating = () => {
        const rubric = getLatestRubric(this.props.skuRecord);

        const grades = this.props.skuRecord.status.health.rubric[rubric].scores?.sentiment;
        return <Grid container className="with-border-bottom">
            <Grid item xs={2}>
                <ScoreDisplay title="Ratings" score={grades?.ratings.average} />
            </Grid>
            <Grid item xs={4}>
                <StarRating rating={this.props.skuRecord.skuData.Ratings["Ratings Average"]} title="Average" />
            </Grid>
            <Grid item xs={3}>
                <ScoreDisplay title="Reviews" score={grades.reviews} />
            </Grid>
            <Grid item xs={3} className="text-center">
                <label className="field with-margin-top">
                    {this.props.skuRecord.skuData.Ratings["Ratings Count"]} Reviews
            </label>
            </Grid>
        </Grid>
    }

    renderVideoAndPdf = () => {
        const { skuRecord } = this.props;
        const rubric = getLatestRubric(skuRecord);


        const videoGrades = skuRecord.status.health.rubric[rubric].scores?.richContent.videos;
        const docGrades = skuRecord.status.health.rubric[rubric].scores?.richContent.documents;

        const newTabLink = { target: "_blank", rel: "noreferrer noopener" };

        const skuVideos = skuRecord.skuData.Assets.Videos
        const videosArray: any[] = (skuVideos.labels.length > 0)
        ? skuVideos.labels.map(label => ({value: skuVideos.values[label]}))
        : [];

        let documentsArray: {
            label: string;
            href: string;
        }[] = [];
        const skuDocuments = skuRecord.skuData.Assets.Documents;
        if (skuDocuments && skuDocuments.labels.length > 0) {
            documentsArray = skuDocuments.labels.map(labelName => {
                const doc = skuDocuments.values[labelName];
                const label = doc.meta.title
                ? doc.meta.title
                : labelName;

                return { label, href: doc.url, ...newTabLink };
            })//.slice(0,1);
        }

        return <React.Fragment>
            <Grid container className="with-border-bottom">
            <Grid item xs={2}>
                <ScoreDisplay title="Video" score={videoGrades} />
            </Grid>
            <Grid item xs={4}>
                {videosArray.map((video, index) => <div key={index} className="thumbnail-image-wrapper">
                    <VideoPopup
                        {...video}
                        key={`product-video-${index}`}
                    >
                    </VideoPopup>
                </div>)}
            </Grid>
        </Grid>
        <Grid container className="with-border-bottom">
            <Grid item xs={2}>
                <ScoreDisplay title="Document" score={docGrades} />
            </Grid>
            <Grid item xs={4}>
                {documentsArray.map((document, idx) => <Popup
                    key={`${idx}_${document.label}`}
                    trigger={<div className="thumbnail-image-wrapper">
                        <a
                            className="thumbnail-image"
                            {...documentsArray[idx]}
                        >
                            <FileCopyIcon />
                        </a>
                    </div>}
                    position="top center"
                    on="hover"
                    children={<div className="text-center">{document.label}</div>}
                />)}
            </Grid>
        </Grid>
        </React.Fragment>
    }

    renderImages = () => {
        const { skuRecord } = this.props;
        const { Images } = skuRecord.skuData.Assets;
        const images = Images.labels.map((label, idx) => {
            const popup = <ImageBox
                fileTypeChecks={{ minimum_resolution: true, height: 1000, width: 1000 } as any}
                value={Images.values[label]}
            />

            return <div key={`product-image-${idx}`}>
                {popup}
            </div>
        })
        const rubric = getLatestRubric(skuRecord);

        const grades = skuRecord.status.health.rubric[rubric].scores?.assets.images;
        return [
            <Grid container className="with-border-bottom image-scores">
                <Grid item xs={3}>
                    <ScoreDisplay title="Image" score={grades} />
                    {/* <ScoreDisplay title="Image Resolution" score={grades} /> */}
                </Grid>
                <Grid item xs={9}>
                    <div className="product-image-audit-row">
                        {images}
                    </div>
                </Grid>
            </Grid>
        ]
    }

    renderKeywords = () => {
        const { skuRecord } = this.props;

        /* const dummy = {
            score: this.props.product.contentSnapshot[this.state.currentSnapshot].scores?.keyword
        } as any; */

        const rubric = getLatestRubric(skuRecord);
        const grades = skuRecord.status.health.rubric[rubric].scores.seo.overall;

        const stats = this.props.sku ? getSkuKeywordsStats(this.props.sku) : defaultKeywordsStats;

        return <Grid container className="with-border-bottom">
            <Grid item xs={3}>
                <ScoreDisplay title="Keyword" score={grades} />
            </Grid>
            <Grid item xs={4}>
                <label className="field">
                    Keywords Used/Total
                </label>
                <label className="field">
                    Primary Used/Total
                </label>
                <label className="field">
                    Secondary Used/Total
                </label>
            </Grid>
            <Grid item xs={5}>

                <label className="field">
                    {stats.total.used}/{stats.total.count}
                </label>
                <label className="field">
                    {stats.primary.used}/{stats.primary.count}
                </label>
                <label className="field">
                    {stats.secondary.used}/{stats.secondary.count}
                </label>
            </Grid>
        </Grid>
    }

    renderBullets = () => {
        const { skuRecord } = this.props;
        const rubric = getLatestRubric(skuRecord);


        const grades = skuRecord.status.health.rubric[rubric].scores?.marketing.bullets;

        const bullets = skuRecord.skuData.Copy.Bullets;
        const bulletsArray = bullets.labels.map(label => bullets.values[label]);

        return <Grid container className="with-border-bottom">
            <Grid item xs={3}>
                <ScoreDisplay title="Bullets" score={grades} />
            </Grid>
            <Grid item xs={9}>
                <label className="field solid">Main Bullets</label>
                {bulletsArray.length > 0 ? <ul className="bullets-list">
                    {bulletsArray.map((bullet: string, index: number) => <li key={`main-bullet-${index}`}>
                        <HighlightedText text={bullet} highlight={this.props.sku ? [...this.props.sku.primaryKeywords, ...this.props.sku.secondaryKeywords] : []} />
                    </li>)}
                </ul> : "--"}
            </Grid>
        </Grid>
    }

    // TODO - fix this dangerouslySetInnerHTML usage
    renderDescription = () => {
        const { skuRecord } = this.props;

        const rubric = getLatestRubric(skuRecord);

        const grades = skuRecord.status.health.rubric[rubric].scores?.marketing.shortDescription;

        const shortDescFieldToDisplay = skuRecord.skuData.Copy["Short Description - Raw"] || skuRecord.skuData.Copy["Short Description"]

        return <Grid container className="with-border-bottom">
            <Grid item xs={3}>
                <ScoreDisplay title="Description" score={grades} />
            </Grid>
            <Grid item xs={9}>
                <label className="field solid">Short Description</label>
                <div className="field-content">
                    <div>
                        <HighlightedText text={shortDescFieldToDisplay} highlight={this.props.sku ? [...this.props.sku.primaryKeywords, ...this.props.sku.secondaryKeywords] : []} />
                    </div>
                </div>
                {/* <div className="field-content" dangerouslySetInnerHTML={{ __html: this.props.product.contentSnapshot[this.state.currentSnapshot].description }} /> */}
            </Grid>
        </Grid>
    }

    retailerNameRender = (retailer: string) => {
        return retailerNameRender(retailer)
    }

    renderTitle = () => {
        const { skuRecord } = this.props;

        //const retailerName = this.retailerNameRender(skuRecord.retailer);

        const { Images } = skuRecord.skuData.Assets;
        const mainImagePopup = <ImagePopup value={Images.values[Images.labels[0]]} />

        const rubric = getLatestRubric(skuRecord);

        const grades = skuRecord.status.health.rubric[rubric].scores?.marketing.title;

        const {BuyBox} = skuRecord.skuData;
        
        const currencySymbol = BuyBox['Price Currency Symbol'] || '$';
        const priceFmt = (price: number) => !isNaN(price) ? `${currencySymbol}${price.toFixed(2)}` : null;

        const formatPrice = (field: 'Price' | 'MSRP') => {
            const priceVal = BuyBox[field];
    
            let formatted = '--';
            if (priceVal) {
                formatted = priceFmt(priceVal) || '--';
            } else if (BuyBox.Ranges && BuyBox.Ranges[field]) {
                const range = BuyBox.Ranges[field];
                const min = priceFmt(range.min);
                const max = priceFmt(range.max);
                formatted = min && max ? `${min} - ${max}` : '--'
            }
            return formatted;
        }
        
        const priceObj = {
            full: formatPrice("Price"),
            msrp: formatPrice("MSRP"),
            soldBy: BuyBox.Seller ? BuyBox.Seller : '--',
            shippedBy: BuyBox.ShipBy ? BuyBox.ShipBy : '--'
        }

        return <Grid container className="with-border-bottom">
            <Grid item xs={3}>
                <ScoreDisplay title="Title" score={grades} />
            </Grid>
            <Grid item xs={9}>
                <div className="product-image small pull-left">
                    {/* <img src={this.props.product.mainImageUrl} alt={this.props.product.productName} /> */}
                    {mainImagePopup}
                </div>
                <div className="column max-75 pull-left">
                    <a className="product-link no-margin" href={this.props.product.link} target="_blank" rel="noopener noreferrer">
                        <HighlightedText text={this.props.product.productName} highlight={this.props.sku ? [...this.props.sku.primaryKeywords, ...this.props.sku.secondaryKeywords] : []} />
                    </a>
                    <div className="label-line" style={{marginBottom: 10}}>By {this.props.product.manufacturer}</div>
                    <Grid container direction="column" className="BuyBoxInfo">
                        <Grid item container direction="row">
                            <div className="column">
                                <label className="labelTitle">
                                    Sale Price
                                </label>
                                <label className="infoPrice">

                                    {priceObj.full}
                                </label>
                            </div>
                            <div className="column" style={{minWidth: 10}}>
                                <label className="labelTitle">
                                    Sold by
                                </label>
                                <label className="infoText">
                                    {priceObj.soldBy}
                                </label>
                            </div>
                            <div className="column" style={{minWidth: 10}}>
                                <label className="labelTitle">
                                    Shipped by
                                </label>
                                <label className="infoText">
                                    {priceObj.shippedBy}
                                </label>
                            </div>
                        </Grid>
                        <Grid item container direction="row">
                            <div className="column">
                                <label className="labelTitle">
                                    MSRP
                                </label>
                                <label className="infoPrice">
                                    {priceObj.msrp}
                                </label>
                            </div>
                            <div className="column" style={{minWidth: 10}}>
                                <label className="labelTitle">
                                    Availability
                                </label>
                                <label className="infoText" >
                                    {this.props.skuRecord.skuData.BuyBox.Availability}
                                </label>
                            </div>
                            <div className="column" style={{minWidth: 10}}>{skuRecord.skuData.Other?.IsPrime && (<React.Fragment>
                                <label className="labelTitle">
                                    Shipping Type
                                </label>
                                <div className="column logo-container">
                                    {skuRecord.skuData.Other?.IsPrime && (
                                        <img src={AmazonPrimeLogo} alt="Amazon Prime Logo" className="prime-logo" />
                                    )}
                                </div>
                            </React.Fragment>)}</div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    }

    render() {
        return <Card className="card-body dark-container content-snapshot">
            <CardContent className='top-card'>
                <Grid container>
                    <Grid item xs={6}>
                        <h1 className="dual">Content<span>Snapshot</span></h1>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <Link to="/scoring" style={{paddingRight: 5}}>View Scoring Rubrics</Link>
                    </Grid>
                </Grid>
            </CardContent>
            <CardContent className="card-body white-container">
                {this.renderTitle()}
                {this.renderDescription()}
                {this.renderBullets()}
                {this.renderKeywords()}
                {this.renderImages()}
                {this.renderVideoAndPdf()}
                {this.renderRating()}
                {this.renderRichMedia()}
                {this.renderNonGradedSpecs()}
            </CardContent>
        </Card>
    }
}
