import { ScoreRange, Score } from './types/score';

class ScoreCardService {
    public calculateScore(steps: ScoreRange[], value: number, focus?: string) {
        const matchedRange = steps.find(range =>
            value >= range.min && value < range.max
        );
        let scoreVal = 0;

        if (matchedRange) {
            scoreVal = !matchedRange.score ? 0 : matchedRange.score;
        }

        return {
            score: {
                score: scoreVal,
                potential: 5,
                steps: steps.map(step => step.min).map(String),
                wheretofocus: focus,
            },
            qty: value,
        };
    }

    public calculateScoreStepsRange(scoreItem) {
        const correctOrder = ['0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5'];
        const steps: ScoreRange[] = [];

        for (const key of correctOrder) {
            const item = scoreItem[key];
            if (item === '-' || item === '') {
                continue;
            }

            if (steps.length > 0) {
                steps[steps.length - 1].max = item;
            }

            steps.push({
                min: item,
                score: parseFloat(key),
            });
        }

        const lastMax = scoreItem.hardCap && scoreItem.hardCap !== '-' ? scoreItem.hardCap : 99999999;

        if (steps.length === 0) {
            steps.push({
                min: lastMax,
                score: 0,
            });
        }

        steps[steps.length - 1].max = lastMax;

        return steps;
    }

    public calculateAverageScore(score: Score, weight: number, accelerator: number): number {
        if (!score) {
            return accelerator;
        }

        const weightedScore = (score.score * weight) / 100;
        accelerator = accelerator ? accelerator + weightedScore : weightedScore;

        return accelerator;
    }

    public calculateGroupScore(scoreWeightPairs: Array<[Score, number]>) {
        const potential = 5;
        // Filter out scores that should not count
        const filtered = scoreWeightPairs.filter(pair => pair[0] && pair[0].score !== null && pair[0].score !== undefined);
        // Weight the scores
        const weightedScores = filtered.map(pair => pair[0].score*pair[1]);
        // Sum weighted scores to get numerator
        const numerator = weightedScores.reduce((a,b) => a+b, 0);
        // Multiply each weight by potential and then sum them, to create denominator
        const denominator = filtered.map(pair => pair[1]*potential).reduce((a,b) => a+b, 0)
        // Divide numerator by denominator and "scale" it to the potential to get group score
        const groupScore = denominator > 0 ? (numerator / denominator) * potential : null

        return groupScore !== null ? {
            score: groupScore,
            potential,
            steps: [],
            wheretofocus: '',
        } as Score : null;
    }
}

export const scoreCardService = new ScoreCardService();
