/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-fallthrough */
import * as React from 'react';
import { Tab, Menu, Header, Dimmer, Message, List, Step, Loader } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import {
    RetailerAdminRecord, MappedBrandFieldDefinition,
    CsvData, CoreJob, BrandJobStatusChecks, Objectkeys, assignDotted, assignDottedArray,
    Objectentries, RandomId, getCoreFromUserAccount, ObjectKeyValuePairs, JobData
} from '../../libraries/types/datastructures';
import { ReportUpOptions_NewJob, GlobalState } from '../../libraries/types/ReportUp';
import { DropFilesEventHandler } from 'react-dropzone';
import * as Papa from 'papaparse';
import PreviewTable from '../DataMapping/PreviewTable';
import { RouteComponentProps } from 'react-router-dom';
import VisualFieldMapperBrand from '../DataMapping/VisualFieldMapperBrand';
import CSDropzone from '../DataMapping/CSDropzone';
import { querystringparser, coreJobDataSet, firestoreDb } from '../../libraries/Util';
import { API_CALL_submitAccuracyJob } from '../../libraries/Util/apiClient';
import { mapValues } from 'lodash'
import { CSVUtils } from '../../libraries/Util/misc';
import { CSUISegment, CSUIButtonCloseX, CSUITabs, CSUIButton } from '../CSUI';
import './style.scss';
import BasePageLayout from '../../layouts/BasePageLayout';
import GlobalStateProvider from '../GlobalStateProvider';
import { RetailerRecordList } from '../../libraries/Util/constants';


// tslint:disable-next-line:no-any
interface NewAccuracyJobProps extends RouteComponentProps {
    globalState: GlobalState;
    // coreJob: CoreJob;
}

// tslint:disable-next-line:variable-name
const default_parsedCsv = () => ({
    data: [],
    meta: {
        fields: []
    }
}) as CoreJob['jobData']['masterCsv'];

// tslint:disable-next-line:variable-name
const default_jobData = () => ({
    datasetId: '',
    fileName: '',
    masterCsv: default_parsedCsv(),
    skuIdMap: {}
}) as CoreJob['jobData'];

// tslint:disable-next-line:variable-name
const default_job = (props: NewAccuracyJobProps): CoreJob => {
    const jobId = RandomId(); // TODO: consider uuidv5 for namespaces
    return {
        jobId,
        jobNum: parseInt(jobId.slice(-4), 16).toString(),
        jobData: default_jobData(),
        jobName: '',
        owner: (props.globalState.activeUser.isSignedIn) ? props.globalState.activeUser.user.displayName! : '',
        statusChecks: ['Live', 'Health'],
        statusReportMap: {},
        dateOpened: (new Date()).valueOf(),
        stage: 'Incomplete Job',
        retailerInfo: {
            retailerId: '3f483a05-e743-41f3-93da-0f9ab8414b45',
            retailerFieldMap: {},
            retailerName: ''
        },
        schedule: {
            start: {
                when: 'now'
            },
            liveStatus: {
                frequency: {
                    how: 'once'
                }
            },
            recur: {
                how: 'once'
            }
        },
        meta: {
            sku: {
                checkType: {
                    new: false,
                    edit: false
                },
                submitDate: {

                }
            }
        }
    };
};

type reportChooser = {
    [x in BrandJobStatusChecks]?: boolean;
}


interface Job {
    retailerRecords: { [x: string]: RetailerAdminRecord };
    selectedRetailer?: RetailerAdminRecord;
    coreJob: CoreJob;
    reportTypes: reportChooser;
    paste: {
        textarea: string[];
        field: 'upc' | 'toolId' | 'url'
    };
    loadMethod?: 'csv' | 'paste';
}

interface State {
    submitted?: boolean;
    view: {
        loadingData: boolean;
        newInterface: boolean;
        activeIndex: number;
        jobNameClick: boolean;
        legal: {
            credits: boolean;
            accuracy: boolean;
        }
        errors: {
            message: boolean;
            saveButton: boolean;
            uidErrors?: {
                [idFieldName: string]: {
                    tests: {
                        notLengthTwelve?: string[];
                        repeated: {
                            key: string | number;
                            value: number;
                        }[];
                        notNumeric: string[];
                    };
                    idFieldName: string;
                    fieldName: string;
                }
            }
        }
        notification: {
            show: boolean;
            animate: boolean;
            msg: {
                header: string;
                notes: string[];
            }
        }
        team: {
            submittable: boolean;
        }
        seen: { [stepNum: number]: boolean }
        savedJobData: string;
    };
    job: Job;
}

const getStateTemplate = (inst: NewAccuracyJob, state: Partial<State> = {}): State => {
    return Object.assign(
        {
            view: {
                loadingData: false,
                newInterface: true,
                activeIndex: 0,
                jobNameClick: false,
                legal: {
                    credits: false,
                    accuracy: false
                },
                errors: {
                    message: false,
                    saveButton: false
                },
                notification: {
                    show: false,
                    animate: false,
                    msg: {
                        header: '',
                        notes: []
                    }
                },
                team: {
                    submittable: true
                },
                seen: { 1: true },
                savedJobData: ''
            },
            job: {
                retailerRecords: {},
                coreJob: default_job(inst.props),
                reportTypes: {
                    Live: true,
                    Health: true
                },
                paste: {
                    textarea: [],
                    field: 'upc'
                },
                loadMethod: undefined
            } as Job
        } as State,
        state
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const coreJobToStateJob = (coreJobRaw: CoreJob, state: State): Job => {
    const coreJob = (coreJobRaw.jobData.datasetId)
        ? coreJobRaw
        : { ...coreJobRaw, jobData: default_jobData() }
    return {
        retailerRecords: {},
        coreJob,
        reportTypes: Object.assign({}, ...coreJob.statusChecks.map(check => ({ [check]: true }))),
        paste: {
            textarea: [],
            field: 'upc'
        },
        loadMethod: (coreJob.jobData.fileName)
            ? (coreJob.jobData.masterCsv.meta.delimiter)
                ? 'csv'
                : 'paste'
            : undefined,
        ...(coreJob.retailerInfo.retailerId
            ? { selectedRetailer: state.job.retailerRecords[coreJob.retailerInfo.retailerId] }
            : {})
    };
}

class NewAccuracyJob extends React.Component<NewAccuracyJobProps, State> {

    private formSubmit: React.RefObject<HTMLDivElement>;

    constructor(props: NewAccuracyJobProps) {
        super(props);

        this.formSubmit = React.createRef<HTMLDivElement>();

        const parsedLocationSearch = props.location ? querystringparser(props.location.search) : undefined;

        const locationIndex = (parsedLocationSearch && parsedLocationSearch['idx'] && parsedLocationSearch['idx']![0]) ? parseInt(parsedLocationSearch['idx']![0], 10)
            : undefined;

        const state = getStateTemplate(this);

        if (locationIndex) { state.view = { ...state.view, activeIndex: locationIndex - 1, seen: { [locationIndex]: true } }; }

        this.state = {
            ...state,
            /* job: {
                ...coreJobToStateJob({...props.coreJob, jobData: default_jobData()}, state),
                retailerRecords: state.job.retailerRecords
            } */
        };
    }

    stateSet = this.setState /* ((...args) => {
        if (this && this._isMounted) {
            this && (this.setState as any)(...args);
        }
    })  as this['setState'];*/

    componentDidMount() {
        this.setupRetailerRecords();
    }

    setupRetailerRecords = () => {

        const records = { ...this.state.job.retailerRecords, ...RetailerRecordList };

        const retailerRecords = mapValues(records, rec => ({ ...rec, fieldDefinitions: {} })) as any;

        this.stateSet(prevState => ({ job: { ...prevState.job, retailerRecords } }));

        /* firestoreDb.collection('retailerRecords').get()
            .then(retailersSnapsot => {
                const records = { ...this.state.job.retailerRecords };

                retailersSnapsot.docs.forEach(retailerSnapsot => {
                    const retailerRecord = retailerSnapsot.data() as RetailerAdminRecord;
                    // retailerRecord.fieldDefinitions = walmartRecord.fieldDefinitions;
                    records[retailerRecord.retailerId] = retailerRecord;
                });

                const walmartRecord = records['3f483a05-e743-41f3-93da-0f9ab8414b45'];

                const retailerRecords = mapValues(records, rec => ({ ...rec, fieldDefinitions: walmartRecord.fieldDefinitions }))

                this.stateSet(prevState => ({ job: { ...prevState.job, retailerRecords } }));
            }) */


    }

    reportHandler = (options: ReportUpOptions_NewJob) => {
        switch (options.method) {
            case 'selectedRetailerRecord':
                // this.generateBrandJobData(this.state.retailerRecords[options.selectedRecordId]);
                this.stateSet(prevState => ({
                    job: {
                        ...prevState.job,
                        coreJob: {
                            ...prevState.job.coreJob,
                            retailerInfo: {
                                ...prevState.job.coreJob.retailerInfo,
                                retailerId: options.selectedRecordId
                            }
                        }
                    }
                }));
                break;
            default:
        }
    }

    componentWillUnmount() {
        // this.firestoreUnsubscribe();
    }

    saveJobDataIfNew = (teamId: string) => coreJobDataSet({
        ...this.state.job.coreJob.jobData,
        retailerInfo: {
            [this.state.job.coreJob.retailerInfo.retailerId]: this.state.job.coreJob.retailerInfo
        }
    } as JobData, teamId);

    cancelJob = async () => {
        this.props.history.push("/jobs");
    }

    submitJob = async () => {
        if (true) {
            this.cancelJob();
        }

        const { coreJob } = this.state.job;

        // const draftJob = serializeStateDraft(this.state);
        const inst = this;
        // this.props.firestoreDb.collection('liveStatusJobDrafts').doc(coreJob.jobId).set(draftJob)
        // .then(() => {
        const coreUser = getCoreFromUserAccount(this.props.globalState.activeUser);
        if (coreUser) {
            await this.showNotification("Submitting AccuracySTATUS job...");

            await Promise.all([
                firestoreDb.collection('brandJobs').doc(coreJob.jobId).update({
                    "meta.accuracy.datasetId": coreJob.jobData.datasetId
                }),
                this.saveJobDataIfNew(coreUser.teamId)
            ]);

            await API_CALL_submitAccuracyJob(coreJob.jobId)
                .catch(function (error) {
                    console.log(error);
                });

            await this.showNotification("Job submitted!")
            this.hideNotification(1000)

            inst.setState({ submitted: true });
        }
    }

    cacheMappedFieldsForRetailer = (mappedFields: { [x: string]: MappedBrandFieldDefinition }) => {
        this.stateSet(prevState => ({
            job: {
                ...prevState.job,
                coreJob: {
                    ...prevState.job.coreJob,
                    retailerInfo: {
                        ...prevState.job.coreJob.retailerInfo,
                        retailerFieldMap: mappedFields
                    }
                }
            }
        }));
    }

    submitBrand = () => {
        this.handleSubmitButton();
    };
    // tslint:disable-next-line:no-any
    // handleItemClick = (e: any, elem: any ) => this.stateSet({ activeItem: elem.name });


    goToTab = (tabNumber: number) => this.stateSet(prevState => ({
        view: assignDottedArray({ ...prevState.view }, [
            ['activeIndex', tabNumber],
            ['seen', { ...prevState.view.seen, [tabNumber + 1]: true }]
        ])
    }));

    handleNextTab = (tabNumber: number) => this.goToTab(tabNumber);
    // tslint:disable-next-line:no-any
    handleTabChange = (e: any, { activeIndex }: any) => this.goToTab(activeIndex);

    handleSubmitButton = () => {
        (this.formSubmit.current!.firstChild!.firstChild! as HTMLButtonElement).click();
    }

    clearLoadedData = () => {
        this.stateSet(prevState => ({
            job: assignDottedArray({ ...prevState.job }, [
                ['coreJob.jobData', default_jobData()],
                ['loadMethod', undefined],
                ['coreJob.retailerInfo.retailerFieldMap', {}],
                ['paste.textarea', []],
            ]),
            view: assignDottedArray({ ...prevState.view }, [
                ['errors.uidErrors', undefined]
            ])
        }));
    }

    goBackButton = (message: string, tab: number) => <CSUIButton
        onClick={() => this.goToTab(tab - 1)}
        children={message}
        slim
        // icon='arrow left' 
        // labelPosition='left' 
        color="green"
        className="newjob_button"
    />

    step3Content = () => {
        const { masterCsv } = this.state.job.coreJob.jobData;
        // const skusPasted = this.state.job.loadMethod === 'paste';

        const fileNotUploaded = !masterCsv.data.length || !masterCsv.meta.fields || !masterCsv.meta.fields.length
        const numOfUidErrors = this.areThereUidErrors();
        return <div className="stepContent">
            <div>
                <Header as="h2"><b>STEP 1: Import your SKUs</b></Header>
                <p>Please be sure to read all the steps so that we have all the right information to compare your SKUs and display the results.</p>
            </div>
            <div>
                <Step.Group widths={4}>
                    <Step>
                        <Step.Content style={{ width: '100%', height: '100%' }}>
                            <Step.Title>IMPORTANT</Step.Title>
                            <Step.Description>
                                <ul style={{ paddingLeft: 10 }}>
                                    <li>Upload a CSV file only</li>
                                    <li>{(this.isWalmartSelected()) ? 'UPC or Tool ID' : 'Product Page URL'} must be in a column</li>
                                    <li>FIELD NAMES should be on row 1</li>
                                    <li>DATA should start on row 2</li>
                                </ul>
                                <div style={{ paddingLeft: 10 }}>
                                    <CSDropzone
                                        onlyShowBrowseButton={true}
                                        onFileSelect={this.onDrop}
                                        style={{ margin: '0', display: 'inline' }}
                                    />
                                </div>
                            </Step.Description>
                        </Step.Content>
                    </Step>
                    <Step disabled={fileNotUploaded}>
                        <Step.Content style={{ width: '100%', height: '100%' }}>
                            <Step.Title>SUMMARY</Step.Title>
                            <Step.Description>
                                <ul style={{ paddingLeft: 10 }}>
                                    <li>File <span style={{ fontWeight: 700 }}>{this.state.job.coreJob.jobData.fileName}</span></li>
                                    <li>SKUs {this.greenSpan(masterCsv.data.length)}</li>
                                    <li>Fields {this.greenSpan((masterCsv.meta.fields) ? masterCsv.meta.fields.length : 0)}</li>
                                    <li>Errors {this.greenSpan((numOfUidErrors) ? numOfUidErrors : 0, {}, true)}</li>
                                </ul>
                            </Step.Description>
                        </Step.Content>
                    </Step>
                    <Step disabled={fileNotUploaded}>
                        <Step.Content style={{ width: '100%', height: '100%' }}>
                            <Step.Title>DATA REVIEW (first 5 rows)</Step.Title>
                            <Step.Description>
                                <ul style={{ paddingLeft: 10 }}>
                                    <li>Address listed errors</li>
                                    <li>Are field names showing in the top row?</li>
                                    <li>Are all your columns showing up?1</li>
                                    <li>Does the first SKU match your sheet?</li>
                                </ul>
                            </Step.Description>
                        </Step.Content>
                    </Step>
                    <Step disabled={fileNotUploaded}>
                        <Step.Content style={{}}>
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    If it looks good, then
                                </div>
                                <div>
                                    <CSUIButton slim className="newjob_button"
                                        color={"green"}
                                        disabled={!this.isStepComplete(1)}
                                        children={"Next"}
                                        onClick={() => this.newJobFlowOnClick(1)}
                                        style={{ width: 116 }}
                                    />
                                </div>
                                <div style={{ margin: '10px 0' }}>
                                    If not, fix the errors, and
                                </div>
                                <div>
                                    <CSUIButton slim children={'Reload'} className="newjob_buttonClear" color="grey" style={{ width: 116 }} onClick={this.clearLoadedData} />
                                </div>
                            </div>
                        </Step.Content>
                    </Step>
                </Step.Group>
            </div>
            <div id="newJobStep3Item4">
                {(!numOfUidErrors) ? null : <div style={{ marginBottom: 10 }}>
                    <Header as="h5" style={{ fontWeight: 'bold', color: 'red' }}>
                        Data Errors
                    </Header>
                    <span>Please re-upload the data with all of the following errors corrected:</span>
                    <div>
                        {Objectentries(this.state.view.errors.uidErrors).map(fieldErrorObj => this.uidErrorDisplay(fieldErrorObj))}
                    </div>
                </div>}
                <div style={{ marginLeft: 0 }}>
                    <PreviewTable
                        previewData={{ papa: this.state.job.coreJob.jobData.masterCsv, fileName: this.state.job.coreJob.jobData.fileName } as CsvData}
                        pastedData={this.state.job.loadMethod === 'paste'}
                        hideHeader={true}
                        hideFilename={true}
                        doNotDisplay={!masterCsv.data.length || !masterCsv.meta.fields || !masterCsv.meta.fields.length}
                    />
                </div>
            </div>

        </div>;
    }

    uidErrorDisplay = (uidErrorObj: State['view']['errors']['uidErrors']['any']) => {
        const columnType = (uidErrorObj.idFieldName === 'upc')
            ? "UPC (GTIN-12)"
            : (uidErrorObj.idFieldName === 'toolId')
                ? "Tool ID (Item ID)"
                : "URL";
        const testDisplayConfig = {
            notUrlFormat: {
                label: 'Mal-formed URLs',
                desc: 'These URLs do not start with "http(s)://":',
                render: (item) => item
            },
            notNumeric: {
                label: 'Non-Digit Characters',
                desc: 'These contain non-digit characters:',
                render: (item) => item
            },
            notLengthTwelve: {
                label: 'UPC not 12 digits long',
                desc: 'These UPCs do not contain 12 digits:',
                render: (item) => item
            },
            repeated: {
                label: 'Repeated UIDs/URLs',
                desc: 'Each SKU should have an unique ID or URL. These are repeated:',
                render: (item) => `${item.key} (${item.value} times)`
            },
        };
        const testRenderer = (testName: keyof typeof testDisplayConfig, testItems: any[]) => {
            const displayConfig = testDisplayConfig[testName];
            return (testItems.length) ? <div key={testName}>
                <span style={{ textDecoration: 'underline red' }}>{displayConfig.label}</span>:
                <span style={{ fontStyle: 'italic' }}>{" " + displayConfig.desc}</span>
                <div>
                    <List horizontal divided items={testItems.map(displayConfig.render)} />
                </div>
            </div> : null
        }
        return <div key={uidErrorObj.idFieldName}>
            <span>--</span><br />
            <span style={{ fontWeight: 'bold' }}>UID Column Type</span>: {columnType}<br />
            <span style={{ fontWeight: 'bold' }}>Uploaded Column Name</span>: {uidErrorObj.fieldName}<br />
            {ObjectKeyValuePairs(uidErrorObj.tests).map(testPair => testRenderer(testPair.key, testPair.value)).filter(x => x)}
        </div>
    }

    areThereUidErrors = () => ((this.state.view.errors.uidErrors)
        ? Objectentries(this.state.view.errors.uidErrors).map(fieldErrorObj =>
            Objectentries(fieldErrorObj.tests).map(x => x.length).filter(x => x).length
        ).reduce((a, b) => a + b, 0)
        : false
    )

    checkUidErrors = (idFieldName: string, fieldName: string) => {
        const uidErrors = (() => {
            const { data } = this.state.job.coreJob.jobData.masterCsv;
            const items = data.map(sku => sku[fieldName].trim());

            const wordCount = items.reduce((collector, sku) => ({
                ...collector,
                [sku]: ((collector[sku]) ? collector[sku] : 0) + 1
            }), {} as { [sku: string]: number });
            const deDuped = Object.keys(wordCount).filter(x => x);

            const multipleIdenticalTest = () => ObjectKeyValuePairs(wordCount).filter(pair => pair.value > 1);
            const allNumericTest = () => deDuped.filter(sku => !/^\d+$/g.test(sku));
            const allCorrectUrlFormatTest = () => deDuped.filter(sku => !/^(ftp|http|https):\/\/.+$/g.test(sku));

            const errorDetails = {
                idFieldName,
                fieldName,
                tests: {
                    repeated: multipleIdenticalTest(),
                }
            }

            switch (idFieldName) {
                case 'upc':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notNumeric: allNumericTest(),
                            notLengthTwelve: deDuped.filter(sku => sku.length !== 12)
                        }
                    };
                case 'toolId':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notNumeric: allNumericTest(),
                        }
                    };
                case 'url':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notUrlFormat: allCorrectUrlFormatTest(),
                        }
                    };
                default:
                    return errorDetails;
            }
        })();

        return new Promise<typeof uidErrors>((resolve) => {
            this.stateSet(
                prevState => ({
                    view: assignDottedArray({ ...prevState.view }, [
                        [`errors.uidErrors.${idFieldName}`, uidErrors]
                    ])
                }),
                () => resolve(uidErrors)
            );
        });
    }

    greenSpan = (num: string | number, style: React.CSSProperties = {}, reverse: boolean = false) => <span style={{ color: (!!(reverse ? !num : num)) ? '#4FDA03' : 'darkred', fontWeight: 'bold', ...style }}>{num}</span>

    isWalmartSelected = () => {
        const { selectedRetailer } = this.state.job;
        return (selectedRetailer)
            ? selectedRetailer.retailerName === 'Walmart.com'
            : true
    }

    skuIdMapSetUnset = (idFieldName: string) => ({
        set: (fieldName: string) => {
            this.checkUidErrors(idFieldName, fieldName);
            this.stateSet(prevState => ({
                job: assignDottedArray({ ...prevState.job }, [
                    ['coreJob.jobData.skuIdMap', Object.assign(
                        { ...prevState.job.coreJob.jobData.skuIdMap },
                        { [idFieldName]: fieldName }
                    )]
                ])
            }))
        },
        unset: () => this.stateSet(prevState => {
            const { skuIdMap } = prevState.job.coreJob.jobData;
            delete skuIdMap[idFieldName];
            return {
                job: assignDottedArray({ ...prevState.job }, [
                    ['coreJob.jobData.skuIdMap', Object.assign(
                        { ...skuIdMap }
                    )]
                ])
            };
        })
    })

    reservedWalmart = () => {
        return {
            "Tool ID (Item ID)": this.skuIdMapSetUnset('toolId'),
            "UPC (GTIN-12)": this.skuIdMapSetUnset('upc'),
            // "URL": this.skuIdMapSetUnset('url') 
        };
    }

    reservedLowes = () => {
        return {
            "URL": this.skuIdMapSetUnset('url')
        };
    }

    reservedRetailerFields = () => {
        return (this.isWalmartSelected()) ? this.reservedWalmart() : this.reservedLowes();
    }

    reservedText = () => {
        return "Please map at least one UID field.";/*(this.isWalmartSelected()) 
        ? 'Please map either the UPC or Walmart 124 Tool ID column (or both)'
        : 'Please map the URL column'*/
    }

    step4Content = () => {
        const retailerId = this.state.job.coreJob.retailerInfo.retailerId
        const retailerRecords = Object.values(this.state.job.retailerRecords)
        const retailerRecord = retailerRecords.find(record => record.retailerId === retailerId)
        const mapper = (this.state.job.loadMethod !== undefined && this.state.job.coreJob.retailerInfo.retailerId)
            ? <Dimmer.Dimmable as="div" dimmed={this.state.job.loadMethod === 'paste'}>
                <Dimmer className="mapperDimmer" active={this.state.job.loadMethod === 'paste'} inverted={true}>
                    {undefined && '(SKUs were pasted and automatically mapped)'}
                </Dimmer>
                <VisualFieldMapperBrand
                    reportMappedFields={this.cacheMappedFieldsForRetailer}
                    csvData={this.state.job.coreJob.jobData.masterCsv as Papa.ParseResult}
                    mappedFields={this.state.job.coreJob.retailerInfo.retailerFieldMap}
                    retailer={this.state.job.retailerRecords[this.state.job.coreJob.retailerInfo.retailerId]}
                    retailerFields={retailerRecord.fieldDefinitions}
                    reservedFields={this.reservedRetailerFields()}
                    reservedText={this.reservedText()}
                    idOnly={false}
                />
            </Dimmer.Dimmable>
            : (this.state.job.coreJob.retailerInfo.retailerId)
                ? this.goBackButton('Please load SKUs first', 1)
                : this.goBackButton('Please select a Retailer Site first', 1)
            ;
        const retailerSite = this.retailerNameRenderer(true);
        return <div className="stepContent">
            <div style={{ display: 'flex' }}>
                <div style={{ width: '70%' }}>
                    <div>
                        <Header as="h2"><b>STEP 2: Map Your Fields</b></Header>
                        <p>To help us evalutate the accuracy of your SKUs on {retailerSite}, please match <b>your fields listed on the left</b> to {retailerSite}’s <b>fields on the right.</b>  Don’t worry, we’ll save your mappings for future use!</p>
                    </div>
                </div>
                <div style={{ width: '100%', padding: '0 20px' }}>
                    {mapper}
                </div>
            </div>
        </div>;
    }

    showErrorMessage = () => this.stateSet(prevState => ({
        view: assignDotted({ ...prevState.view },
            'errors.message', true
        )
    }))

    toggleErrorShowing = () => this.stateSet(prevState => ({
        view: assignDotted({ ...prevState.view },
            'errors.message', !prevState.view.errors.message
        )
    }));

    newJobFlowOnClick = (stepNum: number) => {
        const isStepComplete = this.isStepComplete(stepNum);
        const { message } = this.state.view.errors;
        if (isStepComplete) {
            if (message) {
                this.toggleErrorShowing();
            }
            if (stepNum === 2) {
                this.submitJob();
            } else {
                this.handleNextTab(
                    (stepNum === 3 && this.state.job.loadMethod === 'paste') ? stepNum + 1 : stepNum
                );
            }
        } else {
            if (!message) {
                this.toggleErrorShowing();
            }
        }
    }

    // tslint:disable-next-line:member-ordering
    newJobErrors = () => ({
        1: [
            { step: 1, msg: 'Upload some SKUs', test: (!!this.state.job.coreJob.jobData.masterCsv.data.length) },
            {
                step: 1, msg: 'Fix errors with uploaded data', test: ((this.state.view.errors.uidErrors)
                    ? Objectentries(this.state.view.errors.uidErrors).map(fieldErrorObj =>
                        Objectentries(fieldErrorObj.tests).map(x => x.length).filter(x => x).length
                    ).filter(x => x).length === 0
                    : true
                )
            },

        ],
        2: [
            { step: 2, msg: 'Please map an unique ID or URL for the SKUs', test: (!!Object.keys(this.state.job.coreJob.jobData.skuIdMap).length) }
        ],
    })

    stepCompletionCriteria = (num: keyof ReturnType<NewAccuracyJob['newJobErrors']>) => this.newJobErrors()[num].reduceRight((prev, curr) => prev && curr.test, true)

    isStepComplete = (stepNum: number) => {
        let bit = true;
        switch (stepNum) {
            // @ts-ignore
            case 2:
                bit = bit && this.stepCompletionCriteria(2);
            case 1:
                bit = bit && this.stepCompletionCriteria(1);
                break;
            default:
                bit = false;
        }
        return bit;
    }

    stepShowCheck = (stepNum: number): boolean => this.stepCompletionCriteria(stepNum as any) && (this.state.view.seen[stepNum] || (this.isStepComplete(stepNum + 1) && this.stepShowCheck(stepNum + 1)));

    errorMessage = (stepNum: number) => {
        const newJobErrors = this.newJobErrors();
        const errors = Objectkeys(newJobErrors)
            .filter(e => e <= stepNum)
            .reverse()
            .reduceRight(
                (prev, curr) => prev.concat(newJobErrors[curr].filter(e => !e.test)),
                [] as typeof newJobErrors[1]
            );
        // Objectkeys(newJobErrors).filter(e => e <= stepNum && !this.stepCompletionCriteria(e.step));
        const message = <Message error={true} style={{ maxWidth: 550, textAlign: 'center' }}>
            <Message.Header>Please complete the following before proceeding:</Message.Header>
            <Message.List>
                {errors.map((error, idx) => <Message.Item key={idx}>
                    <a onClick={() => this.goToTab(error.step - 1)}
                        style={{ cursor: 'pointer' }}
                    >
                        {error.msg}
                    </a>
                </Message.Item>)}
            </Message.List>
        </Message>;
        return (
            (
                (stepNum === 6 && (!!!Objectentries(this.state.job.reportTypes).filter(x => x).length))
                || this.state.view.errors.message
            )
            && (errors.length > 0)
        ) ? message : undefined;
    }

    newJobStepDetail = (stepNum: number) => {
        const button = <CSUIButton slim className="newjob_button"
            disabled={!this.isStepComplete(stepNum)}
            color={"green"}
            children={(stepNum === 2) ? "Submit" : "Next"}
            onClick={() => this.newJobFlowOnClick(stepNum)}
        />;
        const message = this.errorMessage(stepNum);
        let jsx;
        switch (stepNum) {
            case 1:
                jsx = this.step3Content();
                break;
            case 2:
                jsx = this.step4Content();
                break;
            case 7:
            default:
                jsx = <div>{String(stepNum)}</div>;
                break;
        }
        return <div
            style={{
                display: 'flex',
                padding: '25px 5px 15px'
            }}
        >
            <div style={{ overflowX: 'hidden', width: '100%' }}>
                {(stepNum !== 4) && message}
                {React.cloneElement(jsx, { id: `stepContent_${stepNum}` })}
            </div>
            {(stepNum === 2) && <div className="nextButton">{button}</div>}
        </div>;
    }

    newJobStepTitle = (stepNum: number) => {
        let title: string;
        const complete = this.stepShowCheck(stepNum);
        switch (stepNum) {
            case 1:
                title = 'Upload SKUs';
                break;
            case 2:
                title = 'Map Fields';
                break;
            default:
                title = '';
                break;
        }
        return <Menu.Item key={title}>
            {(complete) ? <Icon name='check' /* color="green" */ style={{ color: '#4FDA03' }} size='big' /> : undefined}
            {`${(false && complete) ? '' : `${stepNum}. `}${title}`}
        </Menu.Item>;
    }

    retailerNameRenderer = (capitalize?: boolean) => (this.state.job.selectedRetailer) ? this.state.job.selectedRetailer.retailerName : (capitalize) ? 'Retailer Site' : 'retailer site';

    onDrop: DropFilesEventHandler = async (accepted) => {
        const acceptedFile = accepted[0];
        if (acceptedFile) {
            const masterCsv = await CSVUtils.parse(acceptedFile);
            this.setUploadedCsv(acceptedFile.name, masterCsv);
        }
    }

    setUploadedCsv = (fileName: string, masterCsv: Papa.ParseResult) => {
        this.setState(prevState => ({
            job: {
                ...prevState.job,
                coreJob: {
                    ...prevState.job.coreJob,
                    jobData: {
                        datasetId: RandomId(),
                        fileName,
                        masterCsv,
                        skuIdMap: {}
                    },
                    retailerInfo: {
                        ...prevState.job.coreJob.retailerInfo,
                        retailerFieldMap: {}
                    }
                },
                loadMethod: 'csv'
            },
            view: {
                ...prevState.view,
                errors: {
                    ...prevState.view.errors,
                    uidErrors: undefined
                }
            }
        }));
    }

    oldPanes = () => {
        return [1, 2].map(step => ({
            menuItem: this.newJobStepTitle(step),
            render: () => <Tab.Pane>{this.newJobStepDetail(step)}</Tab.Pane>
        }));
    }

    panes = () => {
        return [1, 2].map(step => ({
            key: step,
            header: this.newJobStepTitle(step),
            content: this.newJobStepDetail(step)
        }));
    }

    jobNameClickHandler = () => {
        this.goToTab(0);
        this.stateSet(prevState => ({ view: assignDotted({ ...prevState.view }, `jobNameClick`, true) }));
    }

    getNotificationPanel = () => {
        const { notification } = this.state.view;
        return <Dimmer active={notification.show} inverted>
            <Loader inverted>
                <Header as="h1">{notification.msg.header}</Header>
                {notification.msg.notes.map((note, idx) => <p key={idx} style={{ fontSize: '1.3rem' }}>{note}</p>)}
            </Loader>
        </Dimmer>
    }

    showNotification = (headerMsg: string, ...notes: string[]) => {
        return new Promise<void>(resolve => {
            this.stateSet(
                prevState => ({
                    view: assignDottedArray({ ...prevState.view }, [
                        ['notification.show', true],
                        ['notification.msg.header', headerMsg],
                        ['notification.msg.notes', notes]
                    ])
                }),
                () => resolve()
            );
        });
    }

    hideNotification = (delay: number = 0) => {
        return new Promise<void>(resolve =>
            setTimeout(() => {
                this.stateSet(
                    prevState => ({
                        view: assignDottedArray({ ...prevState.view }, [
                            ['notification.show', false],
                            ['notification.msg.header', ''],
                            ['notification.msg.notes', []]
                        ])
                    }),
                    () => resolve()
                );
            }, delay)
        );
    }

    render() {
        // const oldPanes = this.oldPanes();
        const panes = this.panes();
        return <BasePageLayout>
            <CSUISegment className="accuracyNewJob">
                <CSUIButtonCloseX topRight onClick={() => this.cancelJob()} />
                <div className="newAccuracyJob">
                    {this.getNotificationPanel()}
                    {/* <Tab 
                        className=""
                        panes={oldPanes} 
                        activeIndex={this.state.view.activeIndex}
                        onTabChange={this.handleTabChange}
                    /> */}
                    <CSUITabs
                        panes={panes}
                        activeTabIndex={this.state.view.activeIndex}
                        onTabIndexChange={this.goToTab}
                    />
                </div>
            </CSUISegment>
        </BasePageLayout>
    }
}

export default GlobalStateProvider(NewAccuracyJob);