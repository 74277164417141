import * as React from 'react';
import './style.scss';

interface StarRatingProps {
    rating: number,
    title: string
}

const StarRating = (props: StarRatingProps) => {
    const stars: JSX.Element[] = [];
    let ratingSplit = [];

    const rating = Math.floor(props.rating);

    if (rating) {
        ratingSplit = props.rating.toString().split('.');
    }

    const halved = (ratingSplit.length === 2) && (parseInt(ratingSplit[1]) >= 5);

    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<div key={i} className="star full"></div>)
        } else if (i === (rating + 1) && halved) {
            stars.push(<div key={i} className="star half"></div>)
        } else {
            stars.push(<div key={i} className="star empty"></div>)
        }
    }
    return <div className="star-rating">{stars}{props.rating} {props.title}</div>
}

export default StarRating;
