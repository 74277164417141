import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    numb: {
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        marginLeft: theme.spacing(1),
        lineHeight: '27px',
    },
}));