import {
    CustomViewData, Filters, UserSettingsData,
    ColumnObj, UserSearch, defaultCustomViewValues
} from "./datastructures";
import { chain } from 'lodash';
import {  Tag } from "./SKUTaggingView/datastructures";
import { SkuRecordCatalogData } from './catalog-datamodel';
import 'firebase/firestore';
import { ContentStatusDataService } from '../../libraries/DataService';
import { RetailerRecordList } from "../../libraries/Util/constants";
import {cloneDeep} from "lodash"
import config from "../../libraries/config"

const genLocalStoragePersister = <T extends any>(key: string) => ({
    get: () => JSON.parse(localStorage.getItem(key)) as T,
    set: (settings: T) => localStorage.setItem(key, JSON.stringify(settings)),
    clear: () => localStorage.removeItem(key)
})

const LocalStorageUserSettings = genLocalStoragePersister<UserSettingsData>("CS_Catalog_userSettings");
const blankUserSettings: UserSettingsData = {
    filters: null,
    viewData: null,
    customTags: null,
    categories: null,
    search: {
    }
}

class LocalStorageService {
    private userSettings: UserSettingsData;

    constructor(namespace: string) {

        this.configureNamespace(namespace);
        const lsUserSettings = LocalStorageUserSettings.get();

        this.userSettings = lsUserSettings
        ? lsUserSettings
        : blankUserSettings
    }

    public configureNamespace(namespace: string) {
        const ls_namespace = localStorage.getItem("CS_LS_namespace");
        if (ls_namespace !== namespace) {
            LocalStorageUserSettings.clear();
            localStorage.setItem("CS_LS_namespace", namespace);
        }
    }

    public setUserSettings(setting: "filters" | "categories" | "customTags" | "viewData" | "search" | "all", value: UserSettingsData | Filters | Tag[] | CustomViewData | ColumnObj[] | UserSearch): void {
        switch (setting) {
            case "filters":
                this.userSettings.filters = value as Filters;
                break;
            case "categories":
                this.userSettings.categories = value as ColumnObj[];
                break;
            case "customTags":
                this.userSettings.customTags = value as Tag[];
                break;
            case "viewData":
                this.userSettings.viewData = value as CustomViewData;
                break;
            case "search":
                this.userSettings.search = value as UserSearch;
                break;
            case "all":
                this.userSettings = cloneDeep(value) as UserSettingsData;
                break;
        }

        LocalStorageUserSettings.set(this.userSettings);
    }

    public getUserSettings(setting?: "filters" | "categories" | "customTags" | "viewData" | "searchTerm"): Filters | Tag[] | CustomViewData | ColumnObj[] | UserSettingsData | UserSearch {
        return setting ? this.userSettings[setting] : this.userSettings;
    }
}

let localStorageService;

export const initLocalStorageService = (userId: string, teamId: string) => {
    const namespace = `${config.customEnv.DEPLOY_ENV}_${teamId.split("-")[0] || ''}_${userId.split("-")[0] || ''}`;
    localStorageService = new LocalStorageService(namespace);
}

const transformCatalogDataToCustomViewData = () => {
        return cloneDeep(defaultCustomViewValues);
}

const stringSet = (seed: string[] = []) => {
    const set = new Set<string>();

    seed.forEach(set.add);

    return set;
};

const addIfNotUndefined = (set: Set<string>, maincategory: string, subcategories: string[], index: 0 | 1) => {
    if (subcategories[index]) { set.add([maincategory, ...subcategories.slice(0, index + 1)].join("|")) }
}

const getDefaultFilters = () => {
    const defaultFilters = {
        dataSource: {
            items: Object.values(RetailerRecordList).map(retObj => ({
                id: retObj.retailerKey,
                displayName: retObj.retailerName,
                checked: false,
                type: "selectable" as "selectable"
            })),
            searchable: false,
            label: "Data Source",
            id: "dataSource"
        },
        jobs: {
            id: "jobs",
            displayName: "Job Name",
            type: "jobs",
            value: []
        },
        date: {
            id: "date",
            displayName: "Date",
            type: "date-options",
            from: null,
            to: null,
            value: "current"
        },
        brands: {
            items: [],
            searchable: true,
            label: "Brands",
            id: "brands"
        },
        category: {
            items: [],
            searchable: true,
            label: "Category",
            id: "category"
        },
        subCategory1: {
            items: [],
            searchable: true,
            label: "Sub-category 1",
            id: "subCategory1"
        },
        subCategory2: {
            items: [],
            searchable: true,
            label: "Sub-category 2",
            id: "subCategory2"
        },
        versions: {
            items: [{
                id: "live",
                displayName: "Live or Current",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "archived",
                displayName: "Archived",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "edited",
                displayName: "Edited",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "master",
                displayName: "Master",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }],
            searchable: false,
            label: "Versions",
            id: "versions"
        },
        live: {
            items: [{
                id: "live",
                displayName: "Live",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "not-found",
                displayName: "Not found",
                checked: false,
                type: "selectable" as "selectable"
            }],
            searchable: false,
            label: "Live",
            id: "live"
        },
        accuracy: {
            items: [/* {
                id: "accuracy-less-than",
                displayName: "Accuracy is less than",
                valueType: "percent" as "percent",
                value: 100,
                type: "value" as "value",
                disabled: true,
            }, */
            {
                id: "total-flag-is",
                displayName: "Total flagged items is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 99,
                selectValue: '<',
                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            },
            {
                id: "warning-flag-is",
                displayName: "Warning flags is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 20,
                selectValue: '<',
                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            },
            {
                id: "critical-flag-is",
                displayName: "Critical flags is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 20,
                selectValue: '<',
                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            },
            {
                id: "indicator-flag-is",
                displayName: "Indicator flags is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 20,
                selectValue: '<',
                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            },
            ],
            searchable: false,
            label: "Flags",
            id: "accuracy"
        },
        health: {
            items: [
                {
                    id: "health-score-is",
                    displayName: "Health score is ",
                    valueType: "numeric" as "numeric",
                    value: 5,
                    minValue: 0,
                    maxValue: 5,
                    selectValue: '<',
                    checked: false,
                    type: "selectable-value" as "selectable-value",
                    className: "bolden",
                    keepOrder: true
                },
                {
                id: "health_scores_marketing",
                displayName: "Marketing score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "bolden",
                keepOrder: true
            }, {
                id: "title-score-less-than",
                displayName: "Title score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "description-score-less-than",
                displayName: "Description score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "bullets-score-less-than",
                displayName: "Bullets score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',
                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "asset-score-less-than",
                displayName: "Asset score is ",
                checked: false,
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                type: "selectable-value" as "selectable-value",
                className: "bolden",
                keepOrder: true
            }, {
                id: "images-score-less-than",
                displayName: "Images score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, /* {
                id: "images-res-score-less-than",
                displayName: "Images res score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, */ {
                id: "videos-score-less-than",
                displayName: "Videos score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "pdf-score-less-than",
                displayName: "Document score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "sentiment-score-less-than",
                displayName: "Sentiment score is ",
                checked: false,
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                type: "selectable-value" as "selectable-value",
                className: "bolden",
                keepOrder: true
            }, {
                id: "rating-score-less-than",
                displayName: "Rating score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "review-score-less-than",
                displayName: "Review score is ",
                valueType: "numeric" as "numeric",
                value: 5,
                minValue: 0,
                maxValue: 5,
                selectValue: '<',

                checked: false,
                type: "selectable-value" as "selectable-value",
                className: "indented",
                keepOrder: true
            }, {
                id: "keyword-score-less-than",
                displayName: "Keyword score is ",
                checked: false,
                valueType: "numeric" as "numeric",
                minValue: 0,
                maxValue: 5,
                value: 5,
                selectValue: '<',

                type: "selectable-value" as "selectable-value",
                className: "bolden",
                keepOrder: true
            }],
            searchable: false,
            label: "Health Scores",
            id: "health"
        },
        systemTags: {
            items: [{
                id: "tags_all_system_tags_key_item",
                displayName: "Key Item",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_new_launch",
                displayName: "New Launch",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_promoted",
                displayName: "Promoted",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_inactive",
                displayName: "Inactive",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_top_seller",
                displayName: "Top Seller",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_low_seller",
                displayName: "Low Seller",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_high_margin",
                displayName: "High Margin",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_low_margin",
                displayName: "Low Margin",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_high_returns",
                displayName: "High Returns",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_competitor_sku",
                displayName: "Competitor SKU",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "tags_all_system_tags_delete",
                displayName: "Delete",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }],
            searchable: false,
            label: "System Tags",
            id: "systemTags"
        },
        keywords: {
            items: [{
                id: "has-primary-keywords",
                displayName: "Has Primary Keywords",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "has-secondary-keywords",
                displayName: "Has Secondary Keywords",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "no-primary-keywords",
                displayName: "No Primary Keywords",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "no-secondary-keywords",
                displayName: "No Secondary Keywords",
                checked: false,
                type: "selectable" as "selectable"
            }],
            searchable: false,
            label: "Keywords",
            id: "keywords"
        },
        content: {
            items: [{
                id: "has-360-spins",
                displayName: "Has 360 Spins",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "has-enhanced-content",
                displayName: "Has Enhanced Content",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "no-360-spins",
                displayName: "No 360 Spins",
                checked: false,
                type: "selectable" as "selectable"
            }, {
                id: "no-enhanced-content",
                displayName: "No Enhanced Content",
                checked: false,
                type: "selectable" as "selectable"
            }],
            searchable: false,
            label: "Additional content",
            id: "content"
        },
        customTags: {
            id: "customTags",
            displayName: "Custom Tags",
            type: "tags",
            value: []
        },
        stages: {
            items: [{
                id: "not-reviewed",
                displayName: "Not Reviewed",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "in-progress",
                displayName: "In Progress",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "review",
                displayName: "Review",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "approved",
                displayName: "Approved",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "re-submitted",
                displayName: "Re Submitted",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }, {
                id: "delete",
                displayName: "Delete",
                checked: false,
                type: "selectable" as "selectable",
                keepOrder: true
            }],
            searchable: false,
            label: "Workflow Stages",
            id: "stages"
        },
        folders: {
            items: [],
            searchable: false,
            label: "Workflow Folders",
            id: "folders"
        },
        fulfillmentType: {
            items: [
              {
                  id: "1P",
                  displayName: "1P",
                  checked: false,
                  type: "selectable" as "selectable"
              },
              {
                  id: "2P",
                  displayName: "2P",
                  checked: false,
                  type: "selectable" as "selectable"
              },
              {
                  id: "3P",
                  displayName: "3P",
                  checked: false,
                  type: "selectable" as "selectable"
              },
            ],
            searchable: false,
            label: "Fulfillment Type",
            id: "fulfillmentType"
        },
        isPrime: {
            items: [{
                    id: "isPrime",
                    displayName: "true",
                    checked: false,
                    type: "selectable" as "selectable",
                    keepOrder: true
                }, {
                    id: "isNotPrime",
                    displayName: "false",
                    checked: false,
                    type: "selectable" as "selectable",
                    keepOrder: true
                }],
            searchable: false,
            label: "Is Prime",
            id: "isPrime"
        }
    } as Filters;

    return defaultFilters;
}

const generateFiltersFromCatalogData = (data: SkuRecordCatalogData, shouldUpdate = true) => {
    const newFilters = updateFiltersWithCatalogData(cloneDeep(getDefaultFilters()), data, shouldUpdate);

    return newFilters
}

const updateFiltersWithCatalogData = (filters: Filters, data: SkuRecordCatalogData, shouldUpdate = true) => {
    const newFiltersData = chain(data)
        .reduce((prev, row) => {
            prev.brands.add(row.brand);

            const mainCategory = row.mainCategory;
            prev.cat.add(mainCategory);

            const subcategoriesArray = [row.subcategory1, row.subcategory2, row.subcategory3];
            addIfNotUndefined(prev.subCat1, mainCategory, subcategoriesArray, 0);
            addIfNotUndefined(prev.subCat2, mainCategory, subcategoriesArray, 1);

            return prev;
        }, { brands: stringSet(), cat: stringSet(), subCat1: stringSet(), subCat2: stringSet() })
        .mapValues(set => Array.from(set).filter(x => x).map(item => ({
            id: item.toLocaleLowerCase(),
            displayName: item,
            checked: false,
            type: "selectable" as "selectable"
        })))
        .value();

    const newFilters = {
        ...(filters || cloneDeep(getDefaultFilters())),
        brands: {
            items: newFiltersData.brands,
            searchable: true,
            label: "Brands",
            id: "brands"
        },
        category: {
            items: newFiltersData.cat,
            searchable: true,
            label: "Category",
            id: "category"
        },
        subCategory1: {
            items: newFiltersData.subCat1,
            searchable: true,
            label: "Sub-category 1",
            id: "subCategory1"
        },
        subCategory2: {
            items: newFiltersData.subCat2,
            searchable: true,
            label: "Sub-category 2",
            id: "subCategory2"
        }
    } as Filters;

    if (shouldUpdate) {
        ContentStatusDataService.filtersData().set(newFilters);
    }

    return newFilters
}

/* Default order of the categories displayed in the catalog table */
const defaultCategoriesOrder: string[] = ["image", "brand", "model", "upc", "maincategory", "title", "status", "score", "marketing", "assets", "sentiment", "accuracy"]

export { localStorageService, transformCatalogDataToCustomViewData, generateFiltersFromCatalogData, updateFiltersWithCatalogData, getDefaultFilters, defaultCategoriesOrder }
