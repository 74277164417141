import React from 'react';
import { useStyles } from './styles';

interface Props {
  numberText: string | number;
  label: string;
}

const Stepper = ({ numberText, label }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.numb}>{numberText}</div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default Stepper;
