import React from 'react';
import Popup from "reactjs-popup";
import ImageBox from '../../Common/ImageBox';
import { VideoPopup } from '../../Common/AssetPopup';
import { MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { JobProduct, stripHtml } from '../../datastructures';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import './style.scss';

interface EditPreviewColumnProps {
    skuRecord: SkuRecord,
    product: JobProduct,
    contentSnapshotIndex?: number
}

const EditPreviewColumn = (props: EditPreviewColumnProps) => {
    const { skuRecord } = props;
    const { Images } = skuRecord.skuData.Assets;
    const images = Images.labels.map((label, idx) => {
        const popup = <ImageBox
            fileTypeChecks={{ minimum_resolution: true, height: 1000, width: 1000 } as any}
            value={Images.values[label]}
            showResolution={false}
        />

        return <div key={`product-image-${idx}`}>
            {popup}
        </div>
    })

    const newTabLink = { target: "_blank", rel: "noreferrer noopener" };

    const skuVideos = skuRecord.skuData.Assets.Videos
    const videosArray: any[] = (skuVideos.labels.length > 0)
        ? skuVideos.labels.map(label => ({ value: skuVideos.values[label] }))
        : [];

    let documentsArray: {
        label: string;
        href: string;
    }[] = [];
    const skuDocuments = skuRecord.skuData.Assets.Documents;
    if (skuDocuments && skuDocuments.labels.length > 0) {
        documentsArray = skuDocuments.labels.map(labelName => {
            const doc = skuDocuments.values[labelName];
            const label = doc.meta.title
                ? doc.meta.title
                : labelName;

            return { label, href: doc.url, ...newTabLink };
        })
    }

    return <MDBCard className="card-body dark-container edit-preview-container">
        <MDBRow className="titleRow">
            <MDBCol size="6">
                <div>
                    <div>
                        <h1 className="dual">Edited<span>Preview</span></h1>
                    </div>
                </div>
            </MDBCol>

        </MDBRow>
        <div className="restBody">
            <div className="product-title">
                <div className="img-container"><img src={props.skuRecord.skuData.Assets.Images.values['Main Image'].url} alt={props.skuRecord.skuData.UID["Product Name"]} /></div>
                <div>
                    <span>{props.skuRecord.skuData.UID["Product Name"]}</span>
                    <div className="manufacturer">
                        {props.skuRecord.skuData.UID["Brand Name"]}
                    </div>
                </div>
            </div>
            <div className="content-section">
                <h1>Description</h1>
                <p>{stripHtml(props.skuRecord.skuData.Copy["Short Description"])}</p>
            </div>
            {props.skuRecord.skuData.Copy.Bullets.labels.length > 0 && <div className="content-section">
                <h1>Main Bullets</h1>
                <ul className="bullets-list">
                    {props.skuRecord.skuData.Copy.Bullets.labels.map((bullet: string, index: number) => <li key={`main-bullet-${index}`}>
                        {props.skuRecord.skuData.Copy.Bullets.values[bullet]}
                    </li>)}
                </ul>
            </div>}
            <div className="content-section">
                <h1>Images</h1>

                <div className="product-image-audit-row">
                    {images}
                </div>
            </div>
            <div className="content-section">
                <h1>Videos</h1>

                {videosArray.map((video, index) => <div key={index} className="thumbnail-image-wrapper">
                    <VideoPopup
                        {...video}
                        key={`product-video-${index}`}
                    >
                    </VideoPopup>
                </div>)}
            </div>
            <div className="content-section">
                <h1>Documents</h1>

                {documentsArray.map((document, idx) => <Popup
                    key={`${idx}_${document.label}`}
                    trigger={<div className="thumbnail-image-wrapper">
                        <a
                            className="thumbnail-image"
                            {...documentsArray[0]}
                        >
                            <MDBIcon far icon="file-pdf" />
                        </a>
                    </div>}
                    position="top center"
                    on="hover"
                    children={<div className="text-center">{document.label}</div>}
                />)}
            </div>
        </div>
    </MDBCard>
}
export default EditPreviewColumn;