import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

const component = (props) => (
  <div className='spinner-container' {...props}>
    <CircularProgress />
  </div>
);

export default component