import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

type DataSection = 'UID' | 'Taxonomy' | 'Copy' | 'Assets'
type DataField = {
    fieldName?: string;
    section: DataSection;
    isDynamic?: boolean;
    isSpecification?: boolean;
}
export type DataMapping = {
    sourceField: string;
    dataField: DataField;
    dynamicFieldName?: string;
}

const ContentStatusFields: { [fieldName: string]: DataField } = {
    "URL": { section: "UID", fieldName: "URL" },
    "UPC (GTIN-12)": { section: "UID", fieldName: "UPC (GTIN-12)" },
    "Retailer SKU ID": { section: "UID", fieldName: "Retailer SKU ID" },
    "Brand Name": { section: "UID", fieldName: "Brand Name" },
    "Product Name": { section: "UID", fieldName: "Product Name" },
    "Model": { section: "UID", fieldName: "Model" },
    "Manufacturer Part Number": { section: "UID", fieldName: "Manufacturer Part Number" },
    "Category": { section: "Taxonomy", isDynamic: true, fieldName: "Category" },
    "Image": { section: "Assets", isDynamic: true, fieldName: "Image" },
    "Video": { section: "Assets", isDynamic: true, fieldName: "Video" },
    "Document": { section: "Assets", isDynamic: true, fieldName: "Document" },
    "Short Description": { section: "Copy", fieldName: "Short Description" },
    "Bullet": { section: "Copy", isDynamic: true, fieldName: "Bullet" }
}

export const contentStatusFieldsToDisplay = (mappedFields: { [csFieldName: string]: DataMapping }): {[csFieldName: string]: DataField} => {
    const displayFields = {};
    const mappedFieldsArray = Object.entries(mappedFields);

    Object.keys(ContentStatusFields).forEach(csFieldName => {
        const dataField = ContentStatusFields[csFieldName];

        if (dataField.isDynamic) {
            const allMappedDynamic = mappedFieldsArray
            .sort((a, b) => a[1].dynamicFieldName.localeCompare(b[1].dynamicFieldName))
            .filter(entry => entry[1].dataField.fieldName === dataField.fieldName);

            let tempHolder: {[csFieldName: string]: DataField} = {};
            let dynamicLength = 0;
            allMappedDynamic.forEach((entry, idx) => {
                if (entry[1].sourceField === '') {
                    tempHolder[entry[1].dynamicFieldName] = entry[1].dataField;
                } else {
                    const tempEntries = Object.entries(tempHolder);
                    tempEntries.forEach(entryI => {
                        displayFields[entryI[0]] = entryI[1];
                    });
                    displayFields[entry[1].dynamicFieldName] = entry[1].dataField;
                    dynamicLength += tempEntries.length + 1;
                    tempHolder = {};
                }
            });
            
            const length = dynamicLength+1;
            const nextDynamicFieldName = `${dataField.fieldName} (${length < 10 ? 0 : ''}${length})`;
            displayFields[nextDynamicFieldName] = dataField;
        } else {
            displayFields[csFieldName] = dataField;
        }
    });

    return displayFields;
}

interface Props {
  inputLabel: string;
  classes: { [key: string]: string };
  mappedFields: { [csFieldName: string]: DataMapping }
  selectOptions: string[];
  onChangeSelect: (e: { [csFieldName: string]: DataMapping }) => void;
}

export const CSUIDataMapper: React.FunctionComponent<Props> = ({
  inputLabel,
  classes,
  selectOptions,
  mappedFields,
  onChangeSelect,
}: Props) => {

  const contentStatusFields = contentStatusFieldsToDisplay(mappedFields);

  const handleChange = (csFieldName: string, sourceField: string) => {
    const dataField = contentStatusFields[csFieldName];
    
    const newMappedFields = { ...mappedFields };
    newMappedFields[csFieldName] = {
      sourceField,
      dataField,
      ...(dataField.isDynamic ? {dynamicFieldName: csFieldName} : {})
    }
    
    onChangeSelect(newMappedFields);
  };

  const handleChangeSelect = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    handleChange(event.target.name, event.target.value);
  };

  const onClearSelect = (csFieldName: string) => () => {
    handleChange(csFieldName, "");
  };

  const filteredSelectOptions = selectOptions.filter(
    (title) => !Object.values(mappedFields).find((val) => val.sourceField === title)
  );

  return (
    <ul className={classes.mappedList}>
      <li className={classes.mappedListItem}>
        <div className={classes.mappedListTitle}>Content Status List</div>
        <div className={classes.mappedListTitle}>Your Fields</div>
      </li>
      {Object.keys(contentStatusFields).map((csFieldName, i) => {
        const value = mappedFields[csFieldName] ? mappedFields[csFieldName] : undefined;
        return (
          <li className={classes.mappedListItem} key={csFieldName + i}>
            <div>{csFieldName}</div>
            <div>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel id={csFieldName}>{inputLabel}</InputLabel>
                <Select
                  id={csFieldName}
                  name={csFieldName}
                  value={value ? value.sourceField : ''}
                  onChange={handleChangeSelect}>
                  {filteredSelectOptions.map((title) => (
                    <MenuItem key={title} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                  {(value && value.sourceField) ? (
                    <MenuItem key={value.sourceField} value={value.sourceField}>
                      {value.sourceField}
                    </MenuItem>
                  ) : null}
                </Select>
                {(value && value.sourceField) ? (
                  <span
                    className={classes.removeButton}
                    onClick={onClearSelect(csFieldName)}>
                    remove
                  </span>
                ) : null}
              </FormControl>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
