export interface Tag {
  id: string;
  displayName: string;
  selected: boolean;
}

export interface SKUsList {
  [skuId: string]: {
    [tag: string]: Tag;
  };
}

export const defaultTags: Tag[] = [
  {
    id: 'tags_all_system_tags_key_item',
    displayName: 'Key Item',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_new_launch',
    displayName: 'New Launch',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_promoted',
    displayName: 'Promoted',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_top_seller',
    displayName: 'Top Seller',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_low_seller',
    displayName: 'Low Seller',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_high_margin',
    displayName: 'High Margin',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_low_margin',
    displayName: 'Low Margin',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_high_returns',
    displayName: 'High Returns',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_competitor_sku',
    displayName: 'Competitor SKU',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_delete',
    displayName: 'Delete',
    selected: false,
  },
  {
    id: 'tags_all_system_tags_inactive',
    displayName: 'Inactive',
    selected: false,
  },
];
