import { escapedRegex, htmlStringToReact } from '../../../../libraries/Util/string';

interface HighlightedTextProps {
    text: string;
    highlight: string[];
}

const HighlightedText = (props: HighlightedTextProps): JSX.Element => {
    const content = props.text;
    let data = props.text.replace(/-/g, ' ');
    props.highlight.forEach((word: string) => {
        data = data.replace(escapedRegex(`${word.replace(/-/g, ' ')}`, 'gi'), `[keyword]${word}[keyword]`)
    });
    const contents = data.split("[keyword]");
    const iKeywords = props.highlight.map((word: string) => word.replace(/-/g, ' ').toLowerCase())

    const htmlStr = contents.filter((text: string) => text !== "").map((word: string, index: number) => {
        const wordIndex = contents.filter((text: string) => text !== "").slice(0, index).join("").length;
        const originalWord = content.slice(wordIndex, wordIndex + word.length);
        if (iKeywords.includes(word.replace(/-/g, ' ').toLowerCase())) {
            return `<span class="highlighted-word">${originalWord}</span>`;
        } else {
            return `${originalWord}${index !== (contents.length - 1) ? ' ' : ""}`;
        }
    }).join('');

    return htmlStringToReact(htmlStr);
}

export default HighlightedText;
