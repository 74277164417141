import axios from 'axios';
import { API_URL } from '../../types/datastructures';
import { Email } from '../../../pages/Catalog/ShareEmail/datastructures';

type exportSvcQueryParams = {
    teamId: string;
    skus: Array<{ skuId: string; recordId: string; retailer: string }>;
    columns: string[];
    gcStorage: boolean;
    spreadsheet: boolean;
    imagePdf: boolean;
    userId: string;
};
interface ShareParams {
    teamId: string;
    email: Email;
    userId: string;
    skus: Array<{ skuId: string; recordId: string; retailer: string }>;
    columns: string[];
    gcStorage?: boolean;
}

const API_URL_shareService = `${API_URL}/share-service`;
export const API_CALL_exportService = (data: exportSvcQueryParams) => axios.post(
    `${API_URL}/export-service`,
    data
)
export const API_CALL_shareService = (shareParams: ShareParams) => {
    return axios.post(API_URL_shareService, shareParams);
}