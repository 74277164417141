import axios from 'axios';
import {API_URL} from '../../types/datastructures';
import { WorkflowFolder } from '../../../pages/Workflow/types';

export function API_CALL_LoadSkuWorkFlowFolders(teamId: string){
    return axios.post(`${API_URL}/sku/workflow-folders`, { teamId })
}

export function API_CALL_LoadSkuWorkFlowStages(teamId: string){
    return axios.post(`${API_URL}/sku/stage/get`, { teamId })
}

export function API_CALL_LoadWorkflowFolderList(teamId: string){
    return axios.post(`${API_URL}/workflow-folder/get`, { teamId })
}

export function API_CALL_SetWorkflowFolder(teamId: string, workflows: WorkflowFolder[], deletedFolders: WorkflowFolder[]){
    return axios.post(`${API_URL}/workflow-folder/set`, { teamId, workflows, deletedFolders })
}

export function API_CALL_getWorkflowInfo(teamId: string){
    return axios.get(`${API_URL}/workflow-folder?teamId=${teamId}`)
}