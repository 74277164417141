import axios from 'axios';
import {API_URL} from '../../types/datastructures';
import {
    Filters,
    KeywordCollection,
} from '../../../pages/Catalog/datastructures';
import { SKUsList } from '../../../pages/Catalog/SKUTaggingView/datastructures';

// Base Data

export function API_CALL_CatalogDataLoad(teamId: string, search = ''){
    return axios.post(`${API_URL}/catalog/get-data`, { teamId, search })
}

export function API_CALL_ReportDataLoad(teamId: string, fieldPaths: Array<string>) {
    return axios.post(`${API_URL}/report/get`, { teamId, fieldPaths })
}

export async function API_CALL_CatalogDataLoadNew(teamId: string, filters: Filters, search: string){
    const res = await axios.post(`${API_URL}/catalog/get`, { teamId, filters, search: search || "" })
    //console.log(res.data)
    return res
}

export async function API_CALL_CatalogDataLoadElastic(options: {teamId: string, filters: Filters, search: string}) {
    const chunkedUrl = `${API_URL}/catalog/getElastic-chunks`;
    const opts = {...options, chunkedUrl};
    return await API_CALL_ChunkedCatalogClient(opts);
}

export async function API_CALL_CatalogDataLoadChunked(options: {teamId: string, filters: Filters, search: string, partialDataReturn?: (chunk: string[]) => void}) {
    const chunkedUrl = `${API_URL}/catalog/get-chunks`;
    const opts = {...options, chunkedUrl};
    return await API_CALL_ChunkedCatalogClient(opts);
}

async function API_CALL_ChunkedCatalogClient(options: {chunkedUrl: string, teamId: string, filters: Filters, search: string, partialDataReturn?: (chunk: string[]) => void}) {
    const {chunkedUrl, teamId, filters, search, partialDataReturn} = options;

    const partialFn = partialDataReturn ? partialDataReturn : (x: any) => void 0

    const processChunkedResponse = async (response: Response) => {
        const chunks = [];
        //let text = '';
        const reader = response.body.getReader()
        const decoder = new TextDecoder();

        const readChunk = async (): Promise<string[]> => {
            const newVal = await reader.read();
            return await appendChunks(newVal) as string[];
        }

        const appendChunks = async (result: any) => {
            const value = decoder.decode(result.value || new Uint8Array(), {stream: !result.done});
            //console.log('got chunk of', value.length, 'bytes')
            //console.log("delimiter?", text.indexOf("^_^"));
            chunks.push(value);
            /* text += value;
            if (text.indexOf("^_^") > -1) {
                const splits = text.split("^_^");
                //console.log(splits)
                const fullChunks = splits.slice(0, splits.length-1);
                text = splits.slice(-1).filter(x=>x)[0] || "";
                chunks.push(fullChunks);
                //console.log('text so far is', text.length, 'bytes\n');
                partialFn(fullChunks);
            } */
            //console.log("text", text);
            if (result.done) {
                if (chunks.length === 0) {
                    partialFn(undefined);
                }
                return chunks;
            } else {
                //console.log('recursing', value);
                return await readChunk();
            }
        }

        return readChunk();
    }

    

    const execFn = async () => {
        try {
            const response = await fetch(
                chunkedUrl,
                {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ teamId, filters, search: search || "", noCacheLoad: !!options['noCacheLoad'] })
                }
            );

            const chunks = await processChunkedResponse(response);
            const data = {
                data: JSON.parse(chunks.join(""))
            }
            return {data};
            //return Object.assign({}, ...chunks.filter(x=>x).map(x => JSON.parse(x)));
        } catch (err) {
            console.error(err);
            return undefined;
        }
    }

    let result: {
        data: {
            data: any;
        };
    };

    for (let attempts = 2; attempts > 0; attempts--) {
        result = await execFn();
        if (result) {
            break;
        }
    }

    return result;
}

export async function API_CALL_CatalogDataLoadNewChunks(teamId: string, filters: Filters, partialDataReturn: (chunk: string[]) => void) {
    var chunkedUrl = `${API_URL}/catalog/get-chunks`;

    const processChunkedResponse = (response: Response) => {
        const chunks = [];
        let text = '';
        const reader = response.body.getReader()
        const decoder = new TextDecoder();

        const readChunk = async (): Promise<string[]> => {
            const newVal = await reader.read();
            return appendChunks(newVal) as string[];
        }

        const appendChunks = (result: any) => {
            const value = decoder.decode(result.value || new Uint8Array(), {stream: !result.done});
            //console.log('got chunk of', value.length, 'bytes')
            //console.log("delimiter?", text.indexOf("^_^"));
            text += value;
            if (text.indexOf("^_^") > -1) {
                const splits = text.split("^_^");
                //console.log(splits)
                const fullChunks = splits.slice(0, splits.length-1);
                text = splits.slice(-1).filter(x=>x)[0] || "";
                chunks.push(fullChunks);
                //console.log('text so far is', text.length, 'bytes\n');
                partialDataReturn(fullChunks);
            }
            //console.log("text", text);
            if (result.done) {
                if (chunks.length === 0) {
                    partialDataReturn(undefined);
                }
                return chunks;
            } else {
                //console.log('recursing');
                return readChunk();
            }
        }

        return readChunk();
    }


    try {
        const response = await fetch(
            chunkedUrl,
            {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ teamId, filters })
            }
        );

        await processChunkedResponse(response);

        //return Object.assign({}, ...chunks.filter(x=>x).map(x => JSON.parse(x)));
    } catch (err) {
        console.error(err)
    }
}

// Tags

export function API_CALL_LoadSkuTags(teamId: string){
    return axios.post(`${API_URL}/sku/tags`, { teamId })
}

export function API_CALL_SetSkuTags(teamId: string, skuTags: SKUsList){
    return axios.post(`${API_URL}/sku/tags-set`, { teamId, skuTags })
}

export function API_CALL_SetSkuKeywords(teamId: string, skuKeywords: KeywordCollection){
    return axios.post(`${API_URL}/sku/keywords-set`, { teamId, skuKeywords })
}

export function API_CALL_LoadSkuTagList(teamId: string){
    return axios.get(`${API_URL}/tag/`, {params: { teamId }})
}

export function API_CALL_CreateTag(tag, teamId: string){
    return axios.post(`${API_URL}/tag/create`, { tag, teamId })
}

export function API_CALL_DeleteTag(tagId: string, teamId: string){
    return axios.post(`${API_URL}/tag/delete`, { tagId, teamId })
}

// SKU Edits

export function API_CALL_LoadSkuEdits(teamId: string, retailer: string, skuId: string){
    return axios.post(`${API_URL}/sku/edits/get`, { teamId, retailer, skuId })
}

export function API_CALL_SetSkuEdits(teamId: string, retailer: string, skuId: string, newEdits){
    return axios.post(`${API_URL}/sku/edits/update`, { teamId, retailer, skuId, newEdits })
}

// Saved Views

const API_URL_saveViewService = `${API_URL}/catalog/views`;
export const API_CALL_saveViewService = (userId: string, viewData: object) => {
    return axios.post<any>(API_URL_saveViewService, {
        userId: userId,
        viewData: viewData,
    });
}

export const API_CALL_deleteViewService = (userId: string, viewId: string) => {
    return axios.delete(`${API_URL_saveViewService}?userId=${userId}&viewId=${viewId}`);
}

export function API_CALL_GetSkuRecordByRecordId(recordId: string, teamId: string){
    return axios.post(`${API_URL}/sku/get-recordId`, { recordId, teamId })
}

export function API_CALL_GetSkuRecordBySnapshotId(teamId: string, skuId:string, snapshotId: string, retailer: string){
    return axios.post(`${API_URL}/sku/get-snapshot`, { teamId, skuId, snapshotId, retailer })
}

