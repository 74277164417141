import React from 'react';
import { defaultVal } from '../../libraries/types/datastructures';

// import CSLogo from './../../media/Header/ContentStatus_Basic Logo.png'
import CSRoundLogo from './../../media/Header/icon-512x512.png'
import IDEALogo from './../../media/Header/partners/IDEA.png'

type LogoProps = {
    partnerLogo?: 'idea4industry';
    primaryColor?: React.CSSProperties['color'];
    secondaryColor?: React.CSSProperties['color'];
    showLogo?: boolean;
    className?: string;
    imageClassName?: string;
    outerStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties; 
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const OurLogo: React.FunctionComponent<LogoProps> = React.memo((props) => {
    const showLogo = defaultVal(props.showLogo, true);
    const primaryColor = defaultVal(props.primaryColor, 'black');
    const secondaryColor = defaultVal(props.secondaryColor, 'black');
    const outerStyle = defaultVal(props.outerStyle, {});
    const imageStyle = defaultVal(props.style, outerStyle);
    const outerClassName =  defaultVal(props.className, "");
    const imageClassName = defaultVal(props.imageClassName, outerClassName);
    const textStyle = defaultVal(props.textStyle, {});

    return <div className={outerClassName} style={{display: 'flex', alignItems: 'center', cursor: 'default', ...outerStyle}}>
        {showLogo ? <img
            alt="Logo"
            src={CSRoundLogo}
            {...props}
            style={imageStyle}
            className={imageClassName}
        /> : null}
        <div style={{fontSize: '2rem', marginLeft: 10, ...textStyle}}>
            <span style={{fontWeight: 900, color: primaryColor}}>
                CONTENT
            </span>
            <span style={{color: secondaryColor}}>
                STATUS
            </span>
        </div>
    </div>
});

const PartnerLogoMap ={
    'idea4industry': IDEALogo
}

const Logo: React.FunctionComponent<LogoProps> = React.memo((props) => {
    if (props.partnerLogo) {
        const showLogo = defaultVal(props.showLogo, true);
        const primaryColor = defaultVal(props.primaryColor, 'black');
        const secondaryColor = defaultVal(props.secondaryColor, 'black');
        const outerStyle = defaultVal(props.outerStyle, {});
        const imageStyle = defaultVal(props.style, outerStyle);
        const outerClassName =  defaultVal(props.className, "");
        const imageClassName = defaultVal(props.imageClassName, outerClassName);
    
        return <div className={outerClassName} style={{display: 'flex', alignItems: 'center', cursor: 'default', ...outerStyle}}>
            {showLogo ? <img
                alt="Logo"
                src={PartnerLogoMap[props.partnerLogo]}
                {...props}
                style={imageStyle}
                className={imageClassName}
            /> : null}
            <div style={{
                fontSize: '.9rem', 
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
                //alignSelf: 'end'
            }}>
                <span style={{
                    fontWeight: 700,
                    color: 'black',
                    marginRight: 10
                }}>Powered By</span>
                <OurLogo {...props} outerStyle={{maxHeight: 25}} className="" textStyle={{fontSize: '1.2rem'}} />
            </div>
        </div>
    } else {
        return <OurLogo {...props} />;
    }
});

export default Logo;
