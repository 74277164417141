import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  body?: JSX.Element;
  cancelLabel?: string;
  submitLabel?: string;
  onCancel?: () => void;
  onSubmit: () => void;
  isOneButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
    },
  })
);

const ConfirmModal = ({
  isOpen,
  body,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  onCancel,
  onSubmit,
    isOneButton = false,
}: Props) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <div className={classes.paper}>
        {body && <Grid>{body}</Grid>}
        <Grid
          container
          spacing={4}
          className={classes.buttonContainer}
          justify='center'>
          { !isOneButton ?
            <Grid item>
              <Button color='default' variant='contained' onClick={onCancel}>
                {cancelLabel}
              </Button>
            </Grid>
            : null
          }
          <Grid item>
            <Button color='primary' variant='contained' onClick={onSubmit}>
              {submitLabel}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
