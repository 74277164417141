import React, { Component } from 'react';
import { SentryService } from '../../libraries/ScriptServices/Reporting';

import CSLogo from '../../media/Header/ContentStatus_Basic Logo.png';
import { CSUIButton } from '../CSUI';


class CSSentryBoundary extends Component {
  state: { eventId?: string, hasError?: boolean };

    constructor(props) {
        super(props);
        this.state = { 
          hasError: false
        };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      SentryService.getSentry().withScope((scope) => {
          console.log(errorInfo)
          scope.setExtras(errorInfo);
          const eventId = SentryService.Sentry.captureException(error);
          this.setState({eventId});
      });
    }

    reportFeedbackButton = (label: string = "Report feedback") => {
      return <CSUIButton slim label={label} onClick={() => SentryService.showReportDialog({ eventId: this.state.eventId })} />
    }

    CSErrorUI = () => {
      return <div className="error-view">
        <div className="error-body" style={{textAlign: 'center'}}>
          {// eslint-disable-next-line jsx-a11y/alt-text
          }<img src={CSLogo} className="error-logo" />
          <p className="error-message-text">
            An error has occurred. The issue has been reported to our internal team.
          </p>
          <p className="error-message-text">
            Please consider providing us with additional details or feedback regarding this issue: <br /> <br /> 
            {this.reportFeedbackButton("Send Feedback")}
          </p>
          <p className="error-message-text">
            <CSUIButton color="green" slim label="Refresh the Page" onClick={() => {
              window.location.reload()
            }} />
          </p>
          {/* <p>or go <a onClick={this.handleBack}>back</a>.</p> */}
        </div>
      </div>
    }

    render() {
      // if(this.state.hasError) {
      //   console.log(this.state.eventId)
      //   return <><Snackbar title='sadsd' />{this.props.children}</>;

      // }

        if (this.state.hasError) {
            //render fallback UI
            return this.CSErrorUI()
        }

        //when there's not an error, render children untouched

        return this.props.children
    }
}

export default CSSentryBoundary;