import React from 'react';
import { RouteComponentProps, NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { GlobalState } from '../../libraries/types/ReportUp';
import { CSUISegment, CSUITabs, CSUIBoldHeader } from '../CSUI';
import { firestoreDb } from '../../libraries/Util';
import TeamMemberTable from '../../pages/MyTeam/TeamMemberTable';
import { ActiveUserAccount, CoreUserAccount } from '../../libraries/types/datastructures';
import { AdminJobs } from '../../pages/MyJobs';
import TopLevelPageLayout from '../../layouts/TopLevelPageLayout';
import GlobalStateProvider from '../GlobalStateProvider';
import OperationsList from './operations';
import './style.scss';
import { ADMIN_IDS } from '../../constants';
import { ContentStatusDataService } from '../../libraries/DataService';
import { AuthClientService } from '../../libraries/Auth';
import config from '../../libraries/config';
import AdminUITeam from '../../pages/MyTeam/AdminUITeam';

interface Props extends RouteComponentProps {
  globalState: GlobalState;
}

interface State {
  team: {
    [userId: string]: CoreUserAccount;
  };
  dataLoading: boolean;
}

class SiteAdminSectionShell extends React.Component<Props, State> {
  private firestoreUnsubscribe: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      team: {},
      dataLoading: true,
    };
  }

  async componentDidMount() {
    Object.assign(window, {ContentStatusDataService, AuthClientService, config, firestoreDb});
    
    this.firestoreUnsubscribe = firestoreDb
      .collection('UserAccount')
      //.where("teamId", "==", this.state.teamId)
      .onSnapshot((colSnapshot) => {
        this.setState((prevState) => {
          const team = { ...prevState.team };
          colSnapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case 'removed':
                delete team[change.doc.data().userId as string];
                break;
              case 'added':
              case 'modified':
              default:
                team[
                  change.doc.data().userId as string
                ] = change.doc.data() as CoreUserAccount;
                break;
            }
          });
          return { team, dataLoading: false };
        });
      });
  }

  componentWillUnmount() {
    this.firestoreUnsubscribe();
  }

  userList = () => (
    <div>
      <TeamMemberTable {...this.state} type={'team'} siteAdminTable={true} />
    </div>
  );

  experimentalUIs = () => {
    return (
      <div>
        {/* <div>
                <a target="_blank" href="/admin/homedepot-ql">Go To Home Depot QL</a>
            </div>
            <br /> */}
        <div>
          <NavLink to='/digital-shelf'>Go To Digital Shelf</NavLink>
        </div>
        <br />
        <div>
          <NavLink to='/demo/reports/'>Go To Dashboard Demo</NavLink>
        </div>
        <br />
        <div>
          <NavLink to='/old-reports/'>Go To Old (WebDataRocks) Reports</NavLink>
        </div>
        <br />
        <div>
          <NavLink to='/demo/escatalog/'>Go To ElasticSearch Catalog Demo</NavLink>
        </div>
        <br />
        <div>
          <NavLink to='/demo/escatalog/reindex'>Go To ElasticSearch Catalog Reindex Page</NavLink>
        </div>
      </div>
    );
  };

  jobList = () => {
    return <AdminJobs globalState={this.props.globalState} />;
  };

  operations = () => {
    return <OperationsList />;
  };

  teamSettings = () => {
    return <AdminUITeam
      globalState={this.props.globalState}
      teamId={this.props.globalState.teamJobData.teamId}
    />
  }

  render() {
    const title = (
      <CSUIBoldHeader className='adminHeader' bold='Admin' plain='Section' />
    );
    return (
      <TopLevelPageLayout title={title}>
        <CSUISegment className='adminSegment'>
          <CSUITabs
            className='adminTabs'
            panes={[
              {
                key: 'branding',
                header: 'Experimental UIs',
                content: this.experimentalUIs(),
              },
              {
                key: 'team',
                header: 'Admin Team Settings',
                content: this.teamSettings(),
              },
              {
                key: 'userList',
                header: 'User List',
                content: this.userList(),
              },
              {
                key: 'adminJobList',
                header: 'Admin Job List',
                content: this.jobList(),
              },
              {
                key: 'operationList',
                header: 'Operations',
                content: this.operations(),
              },
            ]}
          />
        </CSUISegment>
      </TopLevelPageLayout>
    );
  }
}

type SiteAdminSectionProps = {
  globalState: GlobalState;
} & RouteComponentProps;

const SiteAdminSection = (props: SiteAdminSectionProps) => {

  return (
    <Switch>
      <Route
        exact={true}
        path='/admin'
        render={() => ADMIN_IDS.includes((props.globalState.activeUser as ActiveUserAccount).user.core.userId)
          ? <SiteAdminSectionShell {...props} />
          : <Redirect to="/dashboard" />
        }
      />
    </Switch>
  );
};

export default GlobalStateProvider(SiteAdminSection);
