import * as React from 'react';
import './style.scss'; 

type CSUI_BoldHeaderProps = {
    bold: string
    plain: string
    className?: string
}

//type eleType = typeof MDBCard //React.FC<CSUI_Segment>

export const CSUIBoldHeader: React.FunctionComponent<CSUI_BoldHeaderProps> = (props) => {
    
    const {
        className,
        bold,
        plain
        //...restProps
    } = props;
    
    const classes = ['csui_boldheader'];

    if (className) {
        classes.push(className);
    }
    
    const customProps = {
        className: classes.join(" ")
    };

    const span = <span 
        //{...restProps}

        {...customProps}
    >
        <span className="boldPart">{`${bold}`}</span><span className="plainPart">{`${plain}`}</span>
    </span>;

    return span; 
}