import moment from 'moment-timezone';
import { makeAutoObservable } from 'mobx';
import * as CSApiClient from '../libraries/Util/apiClient';
import { actionItemsLinks } from '../libraries/Util/enums';
import { DashboardInfo, dashboardDemoState, DashboardState } from '../pages/Dashboard/datastructures';

export class DashboardStore {
    dataLoading: boolean;
    dashboardInfo: DashboardState;
    dataLoadingStatus: 'loading' | 'success' | 'err';

    constructor() {

        makeAutoObservable(this);

        this.dataLoading = true
        this.dashboardInfo = { ...dashboardDemoState }
    }

    getDashboard = async (teamId?: string) => {
        try {
            const dashboardState: DashboardState = dashboardDemoState;
            const { data }: any = await CSApiClient["API_CALL_getDashboardInfo"](teamId)
            const dashboardInfo = data
            const updateCards = this.updateCards(dashboardInfo, dashboardState);
            const formatedDashboardData = this.formatDashboardData(dashboardInfo, updateCards);

            this.dashboardInfo = { ...formatedDashboardData }
            this.dataLoading = false
        } catch (err) {
            this.dataLoading = false
        }
    }
    
    setCurrentView = (view) => {
        this.dashboardInfo.currentView = view
    }

    formatDashboardData = (dashboardInfo, dashboardState) => {
        const tz = moment.tz.guess();
        dashboardState.lastUpdate = moment.unix(dashboardInfo.timestamp / 1000).tz(tz).format('MMMM Do YYYY, h:mm a zz');
        dashboardState.tables[0].rows = [];

        dashboardInfo.recurring_jobs.forEach((reccuringJob) => {
            const healthStatus = !isNaN(reccuringJob.health_status) ? reccuringJob.health_status : 0;
            const liveStatus = !isNaN(reccuringJob.live_status) ? reccuringJob.live_status : 0;
            const numSkus = !isNaN(reccuringJob.numSkus) ? reccuringJob.numSkus : 0;

            let formattedTime = '';
            if (!isNaN(reccuringJob.lastTimestamp)) {
                const timestamp = reccuringJob.lastTimestamp;
                const date = new Date(timestamp * 1000);
                const year = date.getFullYear();
                const month = date.getMonth();
                const day = date.getDay();

                formattedTime = `${month}/${day}/${year}`;
            }

            dashboardState.tables[0].rows.push([
                {
                    value: reccuringJob.jobName,
                },
                {
                    value: numSkus
                },
                {
                    value: formattedTime
                },
                {
                    value: liveStatus * 100,
                    underlined: true
                },
                {
                    value: parseFloat(healthStatus.toFixed(2)),
                    underlined: true
                }
            ])
        });
        dashboardState.stats[0].stats = [{
            display: 'title',
            label: 'Open Workflows',
            value: dashboardInfo.workflow_folders.open_workflows
        }, {
            display: 'subtitle',
            label: 'SKUs in stage'
        }, {
            display: 'item',
            label: 'Not reviewed',
            value: dashboardInfo.workflow_folders.skus_in_stage.not_reviewed,
            highlighted: true
        }, {
            display: 'item',
            label: 'In Progress',
            value: dashboardInfo.workflow_folders.skus_in_stage.in_progress
        }, {
            display: 'item',
            label: 'Review',
            value: dashboardInfo.workflow_folders.skus_in_stage.review
        }, {
            display: 'item',
            label: 'Approved',
            value: dashboardInfo.workflow_folders.skus_in_stage.approved
        }, {
            display: 'item',
            label: 'Re-Submitted',
            value: dashboardInfo.workflow_folders.skus_in_stage.re_submitted
        }];

        dashboardState.stats[1].stats = [{
            display: 'item',
            label: 'Alerts',
            value: dashboardInfo.action_items.alerts
        }, {
            display: 'item',
            label: 'SKUs not found',
            highlighted: true,
            value: dashboardInfo.action_items.skus_not_found
        }, {
            display: 'item',
            label: 'Missing SKUS',
            value: dashboardInfo.action_items.missing_skus
        }, {
            display: 'item',
            label: 'Score <2',
            value: dashboardInfo.action_items.score_under_2,
            highlighted: true,
            link: actionItemsLinks.SCORE_LESS_THEN_2,
        }, {
            display: 'item',
            label: 'Key Items <3',
            value: dashboardInfo.action_items.key_items_under_3,
            link: actionItemsLinks.KEY_ITEMS_LESS_THEN_3,
        }, {
            display: 'item',
            label: 'New Launches <3',
            value: dashboardInfo.action_items.new_launches_under_3,
            link: actionItemsLinks.NEW_LAUNCHES_LESS_THEN_3,
        }, {
            display: 'item',
            label: 'Top Sellers <3',
            value: dashboardInfo.action_items.top_sellers_under_3,
            link: actionItemsLinks.TOP_SELLERS_LESS_THEN_3,
        }, {
            display: 'item',
            label: 'High Returns <3',
            value: dashboardInfo.action_items.high_returns_under_3,
            highlighted: true,
            link: actionItemsLinks.HIGH_RETURNS_LESS_THEN_3,
        }];

        return dashboardState
    }

    updateCards = (dashboardInfo: DashboardInfo, dashboardState: DashboardState) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const accuracyStatusProgress = dashboardInfo.status_checks.accuracy_status.today / 5 * 100;
        // const healthStatusProgress = dashboardInfo.status_checks.health_status.today / 5 * 100;
        const liveStatusProgress = dashboardInfo.status_checks.live_status.today * 100;

        dashboardState.statusChecks.forEach((elem) => {
            switch (elem.title) {
                case 'Live':
                    elem.value = liveStatusProgress;
                    elem.type = 'percent';
                    break;
                case 'Health':
                    elem.value = dashboardInfo.status_checks.health_status.today;
                    elem.type = 'number';
                    break;
                case 'Accuracy':
                    elem.type = 'string';
                    elem.value = '--'; // = elem.type === 'percent' ? accuracyStatusProgress : dashboardInfo.status_checks.accuracy_status.today;
                    elem.progress = 0; //accuracyStatusProgress;
                    break;
                case 'Critical':
                    elem.type = 'string';
                    elem.value = dashboardInfo.status_checks.critical_flags;
                    elem.progress = 0//dashboardInfo.status_checks.critical_flags / Object.keys(skusData).length *100;
                    break;
            }

            if (!elem.progress || isNaN(elem.progress)) {
                elem.progress = 0;
            }
            if (elem.title !== 'Accuracy') {
                if (!elem.value || isNaN(elem.value)) {
                    elem.value = 0;
                }

                elem.value = parseFloat(elem.value.toFixed(2));
            }
        });

        const assetScoreProgress = dashboardInfo.scorecard_summary.asset_score.today / 5 * 100;
        // const keywordScoreProgress = dashboardInfo.scorecard_summary.keyword_score.today / 5 * 100;
        // const marketingScoreProgress = dashboardInfo.scorecard_summary.marketing_score.today / 5 * 100;
        // const sentimentScoreProgress = dashboardInfo.scorecard_summary.sentiment_score.today / 5 * 100;

        dashboardState.scoreCards.forEach((elem) => {
            switch (elem.title) {
                case 'Marketing':
                    elem.value = dashboardInfo.scorecard_summary.marketing_score.today;
                    elem.type = 'number';
                    break;
                case 'Keyword':
                    elem.progress = 0; //dashboardInfo.scorecard_summary.keyword_score.progress;
                    elem.value = '--'; // dashboardInfo.scorecard_summary.keyword_score.today;
                    elem.type = 'number';
                    break;
                case 'Asset':
                    elem.value = elem.type === 'percent' ? assetScoreProgress : dashboardInfo.scorecard_summary.asset_score.today;
                    //elem.value = dashboardInfo.status_checks.health_status.today;
                    elem.type = 'number';
                    break;
                case 'Sentiment':
                    elem.value = dashboardInfo.scorecard_summary.sentiment_score.today;
                    elem.type = 'number';
                    break;
            }

            if (!elem.value || isNaN(elem.value)) {
                elem.value = 0;
            }

            if (!elem.progress || isNaN(elem.progress)) {
                elem.progress = 0;
            }
            elem.value = parseFloat(elem.value.toFixed(2));
        });

        return dashboardState
    };
}

export const dashboardStore = new DashboardStore()