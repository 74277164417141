import React from "react";
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import './style.scss';

interface CSUITabPaneRenderProps {
  setActiveTab: (activeTabIndex: number) => void
}

interface CSUITabPane {
  key: string | number;
  header: React.ReactNode;
  content?: React.ReactNode;
  render?: (props: CSUITabPaneRenderProps) => React.ReactNode;
  headerClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
}

interface CSUITabsProps {
  panes: CSUITabPane[]
  className?: string;

  /** The initial activeTabIndex. Use this prop if Tabs component will be uncontrolled*/
  defaultActiveTabIndex?: number

  /**
   * Called on tab change.
   * Use this prop (and activeTabIndex) if Tabs component will be controlled
   *
   * @param {number} activeIndex - The new proposed activeIndex.
   */
  onTabIndexChange?: (activeIndex: number) => void
  /** Index of the currently active tab. Use this prop (and onTabIndexChange) if Tabs component will be controlled */
  activeTabIndex?: number
  onTabActions?: {
    [tabIdx: number]: (props: CSUITabPaneRenderProps) => void
  }
}

interface State {
  activeTabIndex: number
  propsActiveTabIndex?: number
}

export class CSUITabs extends React.Component<CSUITabsProps, State> {

  static getDerivedStateFromProps(nextProps: CSUITabsProps, prevState: State) {
    if (nextProps.activeTabIndex !== prevState.propsActiveTabIndex) {
      const { activeTabIndex } = nextProps;
      return { ...prevState, activeTabIndex, propsActiveTabIndex: activeTabIndex };
    } else {
      return null;
    }
  }

  constructor(props: CSUITabsProps) {
    super(props);

    const { activeTabIndex, defaultActiveTabIndex } = props;

    this.state = {
      activeTabIndex: (activeTabIndex !== undefined)
        ? activeTabIndex
        : (defaultActiveTabIndex !== undefined)
          ? defaultActiveTabIndex
          : 0,
      propsActiveTabIndex: activeTabIndex
    };
  }

  setActiveTab = (activeTabIndex: number) => {
    this.setState({
      activeTabIndex
    },
    () => {
      if (this.props.onTabActions && this.props.onTabActions[activeTabIndex]) {
        this.props.onTabActions[activeTabIndex]({setActiveTab: this.setActiveTab});
      }
    });
  };

  setTabActive = activeTabIndex => _ => {
    if (this.state.activeTabIndex !== activeTabIndex) {
      this.setActiveTab(activeTabIndex);
    }
    if (this.props.onTabIndexChange && this.props.activeTabIndex !== activeTabIndex) {
      this.props.onTabIndexChange(activeTabIndex)
    }
  };

  tabNav = () => {
    const {pathname, search, hash} = window.location;
    const currentUrl = pathname + search + hash;
    return <MDBNav className="nav-tabs mt-5">{
      this.props.panes.map((pane, idx) => {
        const isActive = this.state.activeTabIndex === idx;

        return <MDBNavItem
          key={`paneHeader.${idx}.${pane.key}`}
          className={`${pane.headerClassName}${isActive ? '-active' : ''}`}
        >
          <MDBNavLink
            className={`CSUITabsHeader${isActive ? " CSUITabsHeader-Active" : ''}`}
            to={currentUrl}
            active={isActive}
            onClick={this.setTabActive(idx)}
            role="tab"
          >
            {pane.header}
          </MDBNavLink>
        </MDBNavItem>
      })
    }</MDBNav>
  }

  tabContent = () => {
    return <MDBTabContent
      className={`CSUITabsContent-Active ${this.props.panes[0].contentClassName === 'white-bg'? 'white-bg' : null}`}
      activeItem={`${this.state.activeTabIndex}`}
    >{
        this.props.panes.map((pane, idx) => <MDBTabPane
          key={`paneContent.${idx}.${pane.key}`}
          tabId={`${idx}`}
          role="tabpanel"
          className={pane.contentClassName}
        >
          {pane.render 
          ? pane.render({setActiveTab: this.setActiveTab})
          : pane.content || ''}
        </MDBTabPane>)
      }</MDBTabContent>
  }

  render() {
    const classes = ['CSUITabs'];
    if(this.props.className) {
      classes.push(this.props.className);
    }
    const className = classes.join(" ");
    
    return (
      <div {...{className}}>
        {this.tabNav()}
        {this.tabContent()}
      </div>
    );
  }
}