import { MappedRowSectionCell, MappedRowSection, RetailerAdminRecord, 
    MappedBrandFieldDefinition, CsvData, NewJobContact, MappedRow, UserAccountObj } from './datastructures';
import React, { ComponentClass } from 'react';
import { TeamJobData } from '../Util/jobService';


const contextExpander = <T extends object>(ctxt: React.Context<T>): [
    React.ProviderExoticComponent<React.ProviderProps<T>>,
    React.ExoticComponent<React.ConsumerProps<T>>
] => [ctxt.Provider, ctxt.Consumer];

const createContext = <T extends object>() => contextExpander(React.createContext<T | null>(null))

export interface SyncedDivs {
    groups: {[groupId: string]: {
        expanded: boolean;
        divs: {[divId: string]: {
            height: number
        }}
    }};
    setExpand: (groupId: string, expanded: boolean) => void;
    toggleExpand: (groupId: string) => void;
    reportDivData: (groupId: string, divId: string, height: number) => void;
    groupClassname: (groupId: string) => string;
}

export const [
    SyncedDivsAppContextProvider,
    SyncedDivsAppContextConsumer
] = createContext<SyncedDivs>();

export const withSyncedDivsHOC = <T extends {syncedDivs: SyncedDivs;}>(Classer: ComponentClass<T, any>) => {
    type omitSyncedDivs = Omit<T, 'syncedDivs'>;
    return ((props: omitSyncedDivs) => {
        return <SyncedDivsAppContextConsumer>
            {(syncedDivs: SyncedDivs) => React.createElement(
                Classer, 
                {...props, syncedDivs} as T
            )}
        </SyncedDivsAppContextConsumer>
    }) as React.FC<omitSyncedDivs>;
}

export interface DetailBar {
    register: (children: {
        node: React.ReactNode,
        registerDomNode: (x) => void,
      }) => () => void
}

export const [
    DetailBarAppContextProvider,
    DetailBarAppContextConsumer
] = createContext<DetailBar>();

export interface GlobalState {
    partnerLogo?: 'idea4industry';
    teamJobData: TeamJobData;
    activeUser: UserAccountObj;
    credits: { teamId: string; balance: number; asofTs: number; };
    signOut: () => void;
}

export const GlobalStateAppContext = React.createContext<GlobalState | null>(null);

export const [
    GlobalStateAppContextProvider,
    GlobalStateAppContextConsumer
] = contextExpander(GlobalStateAppContext);

export interface AccuracyStatusHeightsAppContextInterface {
    itemCellTop: number,
    itemCellBottom: number,
    itemCellTopValue: number,
    itemCellBottomValue: number
}

export const [
    AccuracyStatusHeightsAppContextProvider,
    AccuracyStatusHeightsAppContextConsumer
] = createContext<AccuracyStatusHeightsAppContextInterface>();

export interface HideNonErrorAppContextInterface {
    hideNonError: boolean
}

export const [
    HideNonErrorAppContextProvider,
    HideNonErrorAppContextConsumer
] = createContext<HideNonErrorAppContextInterface>();

export type ReportUpOptions_Data = {
    method: 'error',
    op: number
} | {
    method: 'caution',
    op: number
} | {
    method: 'size',
    className: string,
    dimensions: {
        height: number;
        width: number;
        // tslint:disable-next-line:no-any
        [x: string]: any;
    }
} | {
    method: 'cellUpdate'
    cellData: MappedRowSectionCell
} | {
    method: 'rowUpdate',
    rowData: MappedRow
} | {
    method: 'rowSummaryUpdate',
    field: 'tag';
    tag: 'Not Reviewed' | 'In Progress' | 'Complete' | 'Approved' | 'Removed' | 'Delete';
} | {
    method: 'rowSummaryUpdate',
    field: 'stage';
    stage: 'Not Reviewed' | 'In Progress' | 'Complete' | 'Approved' | 'Removed' | 'Delete';
} | {
    method: 'brandReorder',
    sectionTitle: MappedRowSection['sectionTitle'],
    brandTitles: MappedRowSection['brandTitles']
};

export type ReportUpOptions_NewJob = {
    method: 'selectedRetailerRecord',
    selectedRecordId: RetailerAdminRecord['retailerId']
} | {
    method: 'importRetailerSKUs',
    csvData: CsvData;
} | {
    method: 'cacheMappedFields',
    mappedFields: { [x: string]: { [x: string]: MappedBrandFieldDefinition}};
} | {
    method: 'registerBrandCsv',
    csvData: CsvData;
} | {
    method: 'deleteBrandCsv'
} | {
    method: 'deleteRetailerSKUs'
} | {
    method: 'editNewJobContact',
    section: string,
    contact: NewJobContact   
} | {
    method: 'deleteNewJobContact',
    section: string,
    contactId: string   
};

export interface ReportUpOptions_Admin {
    method: 'newRetailer' | 'editRetailer',
    record: RetailerAdminRecord
};

export type ReportUpFn<T> = (options: T) => void;
export type ReportUpFn_Data = ReportUpFn<ReportUpOptions_Data>;
export type ReportUpFn_Admin = ReportUpFn<ReportUpOptions_Admin>;
export type ReportUpFn_NewJob = ReportUpFn<ReportUpOptions_NewJob>;
