export enum ProductAccuracyState {
    MATCH = 'match',
    CONVERSION_MATCH = 'conversion-match',
    USER_MATCH = 'user-match',
    PARTIAL = 'partial/similar',
    MISSING = 'missing',
}

export enum ProductAccuracySection {
    UID = 'uid',
    RICH_CONTENT = 'richContent',
    MARKETING = 'marketing',
    ASSETS = 'assets',
    SPECS = 'specifications'
}
