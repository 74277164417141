import * as React from 'react';
// import * as Papa from 'papaparse';
import CSUITable from '../../components/CSUI/CSUITable';
import { CsvData } from '../../libraries/types/datastructures';

// tslint:disable-next-line:no-any
interface PreviewTableProps {
    previewData?: CsvData;
    hideHeader?: boolean;
    pastedData?: boolean;
    doNotDisplay?: boolean;
    hideFilename?: boolean;
}

interface State {
}

class PreviewTable extends React.Component<PreviewTableProps, State> {
    constructor(props: PreviewTableProps) {
        super(props);
        this.state = {
        };
    }
    // tslint:disable-next-line:no-any

    render() {
        const rows = this.props.previewData.papa.data.slice(0, 5)
        const columns = this.props.previewData.papa.meta.fields.map((headerName, idx) => (
            {
                Header: <div style={{
                    borderBottom: '1px solid rgb(123,48,167)',
                    padding: '5px 0'
                }}>{headerName}</div>,
                headerStyle: {
                    //backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
                    fontWeight: 'bold'
                },
                id: `id=${idx}-${headerName}`,
                accessor: (d: object) => d[headerName],
                Cell: (props) => props.original[headerName]
            }
        ))
        return (this.props.previewData)
            ? (
                <div style={(this.props.doNotDisplay) ? {display: 'none'} : undefined}>
                    {(this.props.hideHeader) ? undefined : <br /> }
                    {(this.props.hideHeader) ? undefined : <h3>Data Preview</h3>}
                    {(!!!this.props.hideFilename) ? 
                        <div>Loaded {(this.props.pastedData) ? 'data' : 'file'}: <span style={{ fontWeight: 700 }}>{' ' + this.props.previewData.fileName}</span><br /></div> 
                    : null}
                    <CSUITable
                        columnConfig={columns}
                        rows={rows}
                        getRowId={row => row}
                        rowConfig={{}}
                        maxWidth={1800}
                    />
                    <br />
                    <br />
                </div>
        ) : null;
    }
}

export default PreviewTable;