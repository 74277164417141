import React from "react";
import './style.scss';
import { SyncedDivs, withSyncedDivsHOC } from "../../../../libraries/types/ReportUp";

interface SyncedDivTestProps {
    syncedDivs: SyncedDivs;
    groupId: string;
    divId: string;
    className?: string;
    children?: React.ReactNode
    title: {
        main: string;
        detail: string;
        icons?: JSX.Element;
    }
}

interface State {
    contents: string
}

class SyncedDivTest extends React.Component<SyncedDivTestProps, State> {

    private divRef: React.RefObject<HTMLDivElement>;

    constructor(props: SyncedDivTestProps) {
        super(props);

        this.divRef = React.createRef<HTMLDivElement>();

        this.state = {
            contents: ''
        };
    }

    componentDidMount() {

        // this.onChangeHandler()


        const { syncedDivs, groupId, divId } = this.props;

        syncedDivs.reportDivData(groupId, divId, 10);
        // console.log("hi", groupId, divId )
    }

    onChangeHandler = () => {
        /* const {syncedDivs, groupId, divId} = this.props;

        const domRect = this.divRef.current.getBoundingClientRect(); */

        // syncedDivs.reportDivData(groupId, divId, domRect.height);
    }

    isExpanded = () => {
        const { syncedDivs, groupId } = this.props;
        return (syncedDivs.groups[groupId] && syncedDivs.groups[groupId].expanded);
    }

    title = () => {
        const { syncedDivs, groupId, title } = this.props;

        const onClick = (syncedDivs.groups[groupId])
            ? () => syncedDivs.toggleExpand(groupId)
            : () => void 0;

        return <div className="cardTitle minimizedExpander" {...{ onClick }}>
            <div className="text">
                <span>{title.main}</span> {title.detail}
            </div>
            {title.icons ? <div className="icons">
                {title.icons}
            </div> : null}
        </div>
    }

    expandedContent = () => {
        const { children } = this.props;

        return <div className="cardExpandedContent">
            {children ? children : null}
        </div>
    }

    render() {
        const { syncedDivs, groupId } = this.props;

        const classes = ['syncedDivTest'];
        classes.push(syncedDivs.groupClassname(groupId));
        if (this.props.className) {
            classes.push(this.props.className);
        }
        const className = classes.join(" ");

        return (
            <div ref={this.divRef} {...{ className }}>
                <div /* ref={this.divRef} */>
                    {this.title()}
                    {this.isExpanded() ?
                        this.expandedContent()
                        : null}
                </div>
            </div>
        );
    }
}

export default withSyncedDivsHOC(SyncedDivTest)//SyncedDivTest;