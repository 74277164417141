import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { roleOptions, companyTypeOptions, industryOptions, hdyhasOptions } from './../data';
import { GlobalState } from '../../../libraries/types/ReportUp';
import {
  getCoreFromUserAccount,
  CoreUserAccount,
  ObjectKeyValuePairs,
  Objectentries,
  UserAccountObj,
  RandomId,
} from '../../../libraries/types/datastructures';
import './style.scss';
import { CSUIButton } from '../../../components/CSUI';
import { querystringparser } from '../../../libraries/Util';
import {
  API_CALL_registerNewAccount,
  API_CALL_saveUserAccount,
  API_CALL_updateUserAccount,
} from '../../../libraries/Util/apiClient';
import {AuthClientService} from '../../../libraries/Auth';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ADMIN_IDS } from '../../../constants';

interface UserAccountSettingsProps extends RouteComponentProps {
  globalState: GlobalState;
  queryParams?: {
    teamId?: string;
    inviteId?: string;
    refId?: string;
  }
  registration?: boolean;
  reportCoreUser?: (coreUser: CoreUserAccount) => void;
  reportCompletion?: (stats: {
    num: number;
    denom: number;
    amt: number;
    pct: string;
  }) => void;
  registerSaveFn?: (saveFn: () => Promise<void>) => void;
}

interface State {
  coreUser?: CoreUserAccount;
  registration: {
    teamId?: string;
  };
}

const coreUserDataTemplate = (
  userAccount: UserAccountObj,
  teamId: string = RandomId()
) => {
  const coreUser = userAccount.isSignedIn
    ? userAccount.user.core && userAccount.user.core.userId
      ? {...userAccount.user.core, hdyhas: ''}
      : {
          userId: userAccount.profile.sub,
          firstName: userAccount.profile.given_name!,
          lastName: userAccount.profile.family_name!,
          title: '',
          role: '',
          company: '',
          companyType: '',
          industry: '',
          email: userAccount.profile.email!,
          teamId,
          teamIds: [teamId],
          isEmailConfirmed: true,
          hdyhas: ''
        }
    : {
        userId: '',
        firstName: '',
        lastName: '',
        title: '',
        role: '',
        company: '',
        companyType: '',
        industry: '',
        email: '',
        teamId: '',
        teamIds: [''],
        isEmailConfirmed: true,
        hdyhas: ''
      };

  return coreUser;
};

const deleteProperties = <T, K extends keyof T>(
  o: T,
  ...props: K[]
): Omit<T, K> => {
  const newO = { ...o };

  props.forEach((prop) => {
    delete newO[prop];
  });

  return newO;
};

const completionProgressStats = (coreUser: CoreUserAccount) => {
  const displayUser = deleteProperties(
    coreUser,
    'userId',
    'chargebeeCustomerId',
    'teamId',
    'isEmailConfirmed'
  );

  const pct = (num: number) => (num * 100).toFixed(1) + '%';

  const progressStats = (() => {
    const num = Objectentries(displayUser).filter((x) => x).length;
    const denom = Object.keys(displayUser).length;
    const amt = denom > 0 ? num / denom : 0;
    return { num, denom, amt, pct: pct(amt) };
  })();

  return progressStats;
};

class UserAccountSettings extends React.Component<
  UserAccountSettingsProps,
  State
> {
  constructor(props: UserAccountSettingsProps) {
    super(props);

    this.state = {
      registration: { },
    };
  }

  componentDidMount() {
    const teamId = this.determinePresetTeamId();
    const coreUser = this.initCoreUser(teamId);

    this.setState({ coreUser, registration: {teamId} }, this.sendReports);

    if (this.props.registerSaveFn) {
      //console.log("reported it")
      this.props.registerSaveFn(this.saveAccountDetails);
    }
  }

  determinePresetTeamId = () => {
    let teamId = this.props.queryParams?.teamId;

    if(!teamId) {

      teamId = this.state.registration.teamId;

      if (!teamId) {
        const parsedLocationSearch = querystringparser(this.props.location.search);
        const teamIdParam = parsedLocationSearch['teamId'];
    
        teamId = teamIdParam && teamIdParam[0] ? teamIdParam[0] : undefined;
      }
    }

    return teamId;
  }

  initCoreUser = (teamId?: string) => {
    const coreUser = this.props.registration
      ? coreUserDataTemplate(
          this.props.globalState.activeUser,
          teamId
        )
      : getCoreFromUserAccount(this.props.globalState.activeUser);

    return coreUser;
  };

  completionProgressStats = () => {
    const { coreUser } = this.state;

    return completionProgressStats(coreUser);
  };

  coreUser = () => {
    const teamId = this.determinePresetTeamId();
    const { coreUser } = this.state;
    return teamId ? { ...coreUser, teamId } : coreUser;
  };

  saveAccountDetails = async () => {
    const coreUser = this.coreUser();

    if (coreUser.teamIds.indexOf(coreUser.teamId) < 0) {
      coreUser.teamIds.push(coreUser.teamId);
    }

    const relevantAccountSettingValues = ObjectKeyValuePairs({hdyhas: '', ...coreUser})
      .filter(
        (pair) => pair.key !== 'teamIds' && pair.key !== 'isEmailConfirmed'
      )
      .map((pair) => pair.value);

    if (
      coreUser.userId &&
      relevantAccountSettingValues.filter((x) => !!!x).length === 0
    ) {
      // isEmailConfirmed = false
      const saveFn = () => {
        return API_CALL_saveUserAccount(coreUser);;
        /* return firestoreDb
          .collection('UserAccount')
          .doc(coreUser.userId)
          .set(coreUser); */
      };

      await saveFn();

      if (this.props.registration) {
        const profile = await AuthClientService.refreshAuth0UserProfile();
        
        const {inviteId, refId} = (this.props.queryParams || {});

        await API_CALL_registerNewAccount({
          userId: coreUser.userId,
          inviteId,
          refId,
          confirmed: profile.email_verified
        });
        /* await new Promise((resolve) => {
          this.props.history.push('/dashboard');
          setTimeout(resolve, 200);
        }); */
      } else {
        await API_CALL_updateUserAccount(coreUser);
      }

      window.location.href = '/dashboard';
    }
  };

  sendReports = () => {
    const { reportCompletion, reportCoreUser } = this.props;

    if (reportCompletion) {
      reportCompletion(this.completionProgressStats());
    }
    if (reportCoreUser) {
      reportCoreUser(this.coreUser());
    }
  };

  setCoreUserValue = (field: keyof CoreUserAccount, value: string) => {
    if (value !== undefined && value !== this.state.coreUser[field]) {
      this.setState(
        { coreUser: { ...this.state.coreUser, [field]: value } },
        this.sendReports
      );
    }
  };

  onAccountSettingChange = (e): void => {
    const { name } = e.target as HTMLInputElement;

    this.handleChange(name)(e);
  };

  handleChange = (property) => (e) => {
    this.setCoreUserValue(property, (e.target as HTMLInputElement).value);
  };

  generateSelectionChange = (field: keyof CoreUserAccount) => (
    values: string[]
  ) => {
    const value = values[0];

    this.setCoreUserValue(field, value);
  };

  renderInputs = () => {
    const { coreUser } = this.state;

    return (
      <React.Fragment>
        {coreUser && (
          <Grid className='UserAccountSettings'>
            <Grid container spacing={2} className='row'>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='firstName'
                  label='First name'
                  type='text'
                  value={coreUser.firstName}
                  onChange={this.onAccountSettingChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='lastName'
                  label='Last name'
                  type='text'
                  value={coreUser.lastName}
                  onChange={this.onAccountSettingChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='row'>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='email'
                  label='Email'
                  type='email'
                  disabled
                  value={coreUser.email}
                  onChange={this.onAccountSettingChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='company'
                  label='Your Company Name'
                  type='text'
                  value={coreUser.company}
                  onChange={this.onAccountSettingChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='title'
                  label='Your Title'
                  type='text'
                  value={coreUser.title}
                  onChange={this.onAccountSettingChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='row'>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='role'
                  label='Your Role'
                  type='text'
                  value={coreUser.role}
                  onChange={this.handleChange('role')}
                >
                  {roleOptions.map((role) => (
                      <MenuItem key={role} value={role.toLowerCase()}>
                        {role}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='industry'
                  label='Your Industry'
                  type='text'
                  value={coreUser.industry.trim()}
                  onChange={this.handleChange('industry')}
                >
                  {industryOptions.map((industry) => (
                    <MenuItem key={industry} value={industry.toLowerCase()}>
                      {industry}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='companyType'
                  label='Your Company Type'
                  type='text'
                  value={coreUser.companyType}
                  onChange={this.handleChange('companyType')}
                >
                  {companyTypeOptions.map((type) => (
                    <MenuItem key={type} value={type.toLowerCase()}>{type}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {this.props.registration || ADMIN_IDS.includes(this.state.coreUser.userId) ? <Grid container spacing={2} className='row'>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  name='hdyhas'
                  label='How Did You Hear Abour Us?'
                  type='text'
                  value={coreUser['hdyhas'] || ''}
                  onChange={this.handleChange('hdyhas')}
                >
                  {hdyhasOptions.map((val) => (
                      <MenuItem key={val} value={val.toLowerCase()}>
                        {val}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid> : null}
          </Grid>
        )}
      </React.Fragment>
    );
  };

  renderAccountEdit = (): React.ReactNode => {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} className='text-right'>
            <CSUIButton color='green' onClick={this.saveAccountDetails}>
              Save
            </CSUIButton>
          </Grid>
        </Grid>
        {this.renderInputs()}
      </div>
    );
  };

  render() {
    return this.props.registration
      ? this.renderInputs()
      : this.renderAccountEdit();
  }
}

export default UserAccountSettings;
