import React from 'react';
import {
    CoreUserAccount, Objectentries, ObjectKeyValuePairs, SITE_URL
} from '../../../libraries/types/datastructures';
import { CSUISwitch, CSUIButton } from '../../../components/CSUI';
import CSUITable, { RT_CellInfo, RT_Column } from '../../../components/CSUI/CSUITable';
import CustomDropdown from '../../../components/DataMapping/CustomDropDown';
import { API_CALL_DeleteTeamInvite, API_CALL_DeleteUser, API_CALL_GetTeamCredits } from '../../../libraries/Util/apiClient';
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import moment from 'moment';
import { Route } from 'react-router-dom';
import '../../../assets/scss/common.scss';
import './style.scss';

interface TeamMemberTableProps {
    type: 'admin' | 'team'
    team: {
        [userId: string]: CoreUserAccount;
    }
    dataLoading: boolean;
    siteAdminTable?: boolean;
    onMemberRoleUpdate?: (userId: string, role: "admin" | "team") => void;
    adminMode?: boolean;
    currentUserId?: string;
    adminIds?: string[];
    isAdminRootUser?: boolean;
}

interface State {
    status: {
        [userId: string]: boolean
    },
    deletingUser?: CoreUserAccount | undefined,
    teamCredits?: { [teamId: string]: { credits: number; teamId: string } }
}

const accountHeaderMap = {
    teamId: {
        header: 'Team ID'
    },
    credits: {
        header: 'Credits'
    },
    numUsers: {
        header: '# of Users'
    },
    dateCreated: {
        header: 'Date Created'
    },
    dateLastAccessed: {
        header: 'Last User Login'
    },

}

type accountColumnHeaders = keyof typeof accountHeaderMap;

const headerMap = {
    userType: {
        header: 'User Type'
    },
    firstName: {
        header: 'First Name'
    },
    lastName: {
        header: 'Last Name'
    },
    email: {
        header: 'Email'
    },
    company: {
        header: 'Company'
    },
    dateJoined: {
        header: 'Date Joined'
    },
    loginAs: {
        header: 'Login As'
    },
    teamId: {
        header: 'Team ID'
    }
};

type columnHeaders = keyof typeof headerMap;

class TeamMemberTable extends React.Component<TeamMemberTableProps, State> {

    constructor(props: TeamMemberTableProps) {
        super(props);
        this.state = {
            status: {},
        };
    }

    async componentDidMount() {
        if (this.props.siteAdminTable) {
            const { data: teamCredits } = await API_CALL_GetTeamCredits()

            this.setState({ teamCredits });
        }
    }

    isAdminId = (id: string) => !!(this.props.adminIds
        ? this.props.adminIds.find(innerId => innerId === id)
        : false)

    renderStatus = (user: CoreUserAccount) => {

        if ((user as any).status === 'pending') {
            return " - "
        }

        const stateStatus = this.state.status[user.userId];

        const checked = (stateStatus === true || stateStatus === undefined);

        return <div>
            <CSUISwitch
                disabled={!this.props.adminMode || this.props.currentUserId === user.userId}
                className="csui_switch_green"
                labelLeft=""
                labelRight=""
                checked={checked}
                onChange={() => this.setState(prevState => ({
                    status: {
                        ...prevState.status,
                        [user.userId]: !checked
                    }
                }))}
            />
        </div>;
    }

    renderDateJoined = (user: CoreUserAccount) => {
        if (user && user.dateJoined) {
            return <div className='pendingStatusLabel'>{moment(user.dateJoined).format("MM/DD/YYYY")}</div>
        } else if ((user as any).status === 'pending') {
            return <div className="pendingStatusLabel">Pending</div>
        } else {
            return <div className="pendingStatusLabel">-</div>
        }
    }

    routerLink = (label: string, path: string) => <Route path="/*" render={props =>
        <span className="teamIdLink" children={label} onClick={() => props.history.push(path)} />
    } />

    renderAccountColumn = (
        team: {
            key: React.ReactText;
            value: CoreUserAccount[];
        },
        column: accountColumnHeaders
    ) => {
        const notHaveTest = (str: string) => (str || "").toLocaleLowerCase().indexOf("test") === -1
        switch (column) {
            case 'teamId':
                return team.key.toString() !== "undefined"
                    ? this.routerLink(team.key.toString(), `/team?teamId=${team.key}`)
                    : team.key;
            case 'numUsers':
                const length = team.value.length;

                const names = team.value
                    .filter(user => notHaveTest(user.firstName) && notHaveTest(user.lastName))
                    .map((user, idx) => <div key={idx}>{user.firstName + " " + user.lastName}</div>)
                return <div className="accountUserNames"><div><b>({length})</b></div>{names}</div>
            // @ts-ignore
            case 'credits':
                const teamCredit = (this.state.teamCredits || {})[team.key];
                if (teamCredit) {
                    return teamCredit.credits.toLocaleString();
                }
            // eslint-disable-next-line no-fallthrough
            case 'dateCreated':
            case 'dateLastAccessed':
                return " - "
            default:
                return <b>ON</b>
        }
    }

    renderLoginAs = (user: CoreUserAccount) => {
        const url = `${SITE_URL}/admin/user-view?userId=${user.userId}`;

        // eslint-disable-next-line react/jsx-no-target-blank
        return <a href={url} target='_blank'>Login</a>;
    }

    renderColumn = (user: CoreUserAccount, column: columnHeaders) => {
        switch (column) {
            case 'firstName':
            case 'lastName':
            case 'company':
            case 'email':
                return user[column];
            case 'userType':
                return (this.props.adminMode && user.userId !== this.props.currentUserId)
                    ? <CustomDropdown
                        className="material"
                        onChange={(e) => this.props.onMemberRoleUpdate(user.userId, e.target.value as "admin" | "team")}
                        value={/* this.isAdminId(user.userId) ? "admin" : */ "team"}
                    >
                        {/* <option value="admin">Admin</option> */}
                        <option value="team">Team</option>
                    </CustomDropdown>
                    : (/* this.props.type === "admin" ? "Admin" :  */"Team");
            // case 'status':
            //     return this.renderStatus(user);//"Active";
            /* case 'jobAccess':
                return <b>All</b>;
            case 'dateJoined':
            case 'dateLastAccessed':
                return " - " */
            case 'dateJoined':
                return this.renderDateJoined(user);
            case 'loginAs':
                return this.renderLoginAs(user);
            case 'teamId':
                return user.teamIds ? user.teamIds.join(", ") : user.teamId;
            default:
                return <b>ON</b>
        }
    }

    renderColumnWidths = (column: columnHeaders) => {

        switch (column) {
            case 'company':
                return 130;
            /* case 'inviteRemoveUsers':
                return 120; */
            // case 'status':
            /* case 'createDeleteJobs': */
            // return 115;
            /* case 'notifications': */
            case 'firstName':
            case 'lastName':
            case 'userType':
            case 'dateJoined':
            case 'loginAs':
                return 120;
            /*  case 'deleteSkus':
                 return 65;
             case 'exportContent':
                 return 75;
             case 'jobAccess':
                 return 95;
             case 'purchaseCredits':
                 return 100; */
            case 'email':
                return 300;
            default:
                return undefined;
        }
    }

    renderAccountHeader = (column: accountColumnHeaders) => {
        let inside;
        switch (column) {
            default:
                inside = <div><br />{accountHeaderMap[column].header}</div>;
                break;
            /* default:
                inside = headerMap[column].header; */
        }

        return <div style={{
            borderBottom: '1px solid indigo',
            padding: '5px 0'
        }} >
            {inside}
        </div>
    }

    renderHeader = (column: columnHeaders) => {
        let inside;
        switch (column) {
            case 'userType':
            case 'firstName':
            case 'lastName':
            case 'company':
            case 'email':
            // case 'status':
            /* case 'jobAccess':
            case 'notifications': */
            // eslint-disable-next-line no-fallthrough
            case 'dateJoined':
            case 'loginAs':
            case 'teamId':
                /* case 'dateLastAccessed': */
                inside = <div><br />{headerMap[column].header}</div>;
                break;
            /* default:
                inside = headerMap[column].header; */
        }

        return <div style={{
            borderBottom: '1px solid indigo',
            padding: '5px 0'
        }} >
            {inside}
        </div>
    }

    deleteUserFn = () => {
        if (!this.state.deletingUser) {
            return;
        }

        if (this.state.deletingUser.status === "pending") {
            API_CALL_DeleteTeamInvite(this.state.deletingUser.inviteId);
        } else {
            API_CALL_DeleteUser(this.state.deletingUser.userId);
        }

        this.setState({ deletingUser: undefined })
    }

    renderConfirmationDialog = () => {
        const username = `${this.state.deletingUser.firstName} ${this.state.deletingUser.lastName}`;
        const confirmStr = `Are you sure you want to delete user [${username}]?`;

        return <ConfirmationDialog title={confirmStr} cancel="Cancel" confirm="Delete" onConfirm={this.deleteUserFn}
            onCancel={() => this.setState({ deletingUser: undefined })}>
            <p>{''}</p>
        </ConfirmationDialog>
    }

    deleteColumn = () => {

        return {
            // Header: this.renderHeader(key),
            /* headerStyle: {
                // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
                fontWeight: 'bold',
                fontSize: '10pt',
                textAlign: 'left'
            }, */
            style: { fontSize: '10pt', textAlign: 'right' },
            id: 'closeCol',
            width: this.props.siteAdminTable ? 120 : 0,
            //accessor: key
            Cell: (cellInfo: RT_CellInfo) => {
                const regular = <div></div>;
                const onDeleteUser = () => {
                    this.setState({ deletingUser: user })
                }

                const user: CoreUserAccount = cellInfo.original;

                const admin = <div onClick={onDeleteUser} className="teamTableCloseX">
                    x
                </div>;
                const siteAdmin = <div>
                    <CSUIButton color="red" label="Delete" onClick={onDeleteUser} />
                </div>;

                return (this.props.siteAdminTable)
                    ? siteAdmin
                    : ((this.props.adminMode && user.userId !== this.props.currentUserId) ? admin : regular)
            }
        } as RT_Column
    }

    columns = () => {

        const { siteAdminTable } = this.props;

        const deleteColumn = this.deleteColumn();

        const dynamicColumns = Object.keys(headerMap)
            .filter((key: columnHeaders) => {
                return this.props.adminMode
                ? true
                : key !== 'teamId'
            })
            .filter((key: columnHeaders) => siteAdminTable ? key : key !== "loginAs")
            .map((key: columnHeaders) => (
                {
                    Header: this.renderHeader(key),
                    headerStyle: {
                        // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
                        fontWeight: 'bold',
                        fontSize: '10pt',
                        textAlign: 'left'
                    },
                    width: this.renderColumnWidths(key),
                    style: { fontSize: '1rem', textAlign: 'left' },
                    id: key,
                    //accessor: key
                    Cell: (cellInfo: RT_CellInfo) => this.renderColumn(cellInfo.original, key)
                } as RT_Column
            ));

        return [deleteColumn, ...dynamicColumns];
    }

    tableData = () => {

        const { team } = this.props;
        const data = Objectentries(team);
        return data;
    }

    accountColumns = () => {

        const dynamicColumns = Object.keys(accountHeaderMap).map((key: accountColumnHeaders) => (
            {
                Header: this.renderAccountHeader(key),
                headerStyle: {
                    // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
                    fontWeight: 'bold',
                    fontSize: '10pt',
                    textAlign: 'left'
                },
                width: this.renderColumnWidths(key as any),
                style: { fontSize: '1rem', textAlign: 'left' },
                id: key,
                //accessor: key
                Cell: (cellInfo: RT_CellInfo) => this.renderAccountColumn(cellInfo.original, key)
            } as RT_Column
        ));

        return dynamicColumns;
    }

    accountTableData = () => {
        const tableData = this.tableData();

        const accountMap: {
            [teamId: string]: CoreUserAccount[]
        } = {};

        tableData.forEach(coreUser => {
            const teamIds = coreUser.teamIds || [];

            teamIds.forEach(teamId => {
                const teamObj = accountMap[teamId];
                if (teamObj) {
                    teamObj.push(coreUser);
                } else {
                    accountMap[teamId] = [coreUser];
                }
            })
        });

        const data = ObjectKeyValuePairs(accountMap);

        return data;
    }

    render() {
        const data = this.tableData();
        const defaultPageSize = 25//Math.min(data.length, 25);

        const columns = this.columns();

        const tableTitle = (this.props.siteAdminTable)
            ? 'Users'
            : (this.props.type === 'admin')
                ? 'Admin(s)'
                : 'Team'

        const titleProps = {};
        if (this.props.isAdminRootUser && this.props.type === 'team') {
            titleProps['onClick'] = () => {
                console.log("Team ID:", Object.values(this.props.team)[0]?.teamId || "(still loading...)");
            }
            titleProps['style'] = {cursor: 'pointer'};
        }
        const normalTable = <div className="teamMemberTable">
            {this.state.deletingUser && this.renderConfirmationDialog()}
            <div className="tableTitle" {...titleProps}>{tableTitle}</div>
            <CSUITable
                columnConfig={columns}
                rows={data}
                //getRowId={row => row}
                rowConfig={{}}
                maxWidth={1800}
            />
        </div>

        let accountTable: JSX.Element | undefined = undefined;

        if (this.props.siteAdminTable) {
            const accountData = this.accountTableData();
            const accountColumns = this.accountColumns();

            const accountTableTitle = "Accounts"

            accountTable = <div className="teamMemberTable accountListTable no-border">
                <div className="tableTitle">{accountTableTitle}</div>
                <CSUITable
                    columnConfig={accountColumns}
                    rows={accountData}
                    //getRowId={row => row}
                    rowConfig={{}}
                    maxWidth={1800}
                    pagination={true}
                    paginationSize={defaultPageSize}
                />
            </div>
        }

        return (this.props.siteAdminTable)
            ? <div>
                {accountTable}
                {normalTable}
            </div>
            : normalTable
    }
}

export default TeamMemberTable;
