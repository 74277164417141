import * as React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { SkuRecord } from '../../../pages/Catalog/catalog-datamodel';
import DynamicColumn from './DynamicColumn';
import { SyncedDivs, SyncedDivsAppContextProvider } from '../../../libraries/types/ReportUp';
import { GlobalState } from '../../../libraries/types/ReportUp';
import { SKUWithKeywords } from '../../../pages/Catalog/SKUKeywords/datastructures';
import { JobProduct } from '../datastructures';
import { ContentStatusDataService } from '../../../libraries/DataService';
import { ScoreCardDefaultMappings, ScoreCardSettings } from '../../ScoreCards/types';

interface ProductImproveProps {
    skuRecord: SkuRecord
    globalState: GlobalState
    sku: SKUWithKeywords
    product: JobProduct
    editedSkuRecord: SkuRecord
    updateEditedSkuRecord: (skuRecord: SkuRecord) => void
}

interface State {
    syncedDivs: SyncedDivs;
    displayKeywords: boolean;
    column1: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview';
    column2: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview';
    column3: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview';
    scoreCardConfig?: {
        scoreCards: ScoreCardSettings[];
        scoreCardMappings: ScoreCardDefaultMappings;
    }
}

class ProductImprove extends React.Component<ProductImproveProps, State> {

    constructor(props: ProductImproveProps) {
        super(props);

        const { toggleExpand, reportDivData, groupClassname, setExpand } = this;

        this.state = {
            syncedDivs: {
                groups: {},
                setExpand,
                toggleExpand,
                reportDivData,
                groupClassname
            },
            displayKeywords: false,
            column1: 'Archived',
            column2: 'Edited',
            column3: 'Keywords'
        }
    }

    componentDidMount = async () => {
        const teamId = this.props.globalState.teamJobData.teamId;

        const [scoreCards, scoreCardMappings, systemCards] = await Promise.all([
            ContentStatusDataService.scoreCard({teamId}).get(),
            ContentStatusDataService.scoreCard({teamId}).getDefaults(),
            ContentStatusDataService.scoreCard({teamId}).getSystemCards()
        ]);

        const scoreCardConfig = {scoreCards: [...scoreCards, ...systemCards], scoreCardMappings};

        this.setState({scoreCardConfig})
    }

    groupClassname = (groupId: string) => `groupClass_${groupId}`

    setExpand = (groupId: string, expanded: boolean) => {
        this.setState(prevState => ({
            syncedDivs: {
                ...prevState.syncedDivs,
                groups: {
                    ...prevState.syncedDivs.groups,
                    [groupId]: {
                        ...(prevState.syncedDivs.groups[groupId] ? prevState.syncedDivs.groups[groupId] : { divs: {} }),
                        expanded
                    }
                }
            }
        }))
    }

    toggleExpand = (groupId: string) => {
        this.setExpand(groupId, !!!this.state.syncedDivs.groups[groupId].expanded);
    }

    reportDivData = (groupId: string, divId: string, height: number) => {
        // console.log(groupId, divId, height)
        this.setState(prevState => ({
            syncedDivs: {
                ...prevState.syncedDivs,
                groups: {
                    ...prevState.syncedDivs.groups,
                    [groupId]: {
                        ...(prevState.syncedDivs.groups[groupId] ? prevState.syncedDivs.groups[groupId] : { expanded: false }),
                        divs: {
                            ...(prevState.syncedDivs.groups[groupId] ? prevState.syncedDivs.groups[groupId].divs : {}),
                            [divId]: { height }
                        }
                    }
                }
            }
        }))
    }

    dynamicColumn = (
        columnId: string,
        columnType: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview',
        sku: any,
        includeKeywords: boolean = false
    ) => {

        const { globalState, updateEditedSkuRecord, editedSkuRecord } = this.props;
        const { scoreCardConfig } = this.state;
        const skuRecord = sku ? JSON.parse(JSON.stringify(sku)) : undefined;
        
        return <DynamicColumn product={this.props.product} skuWithKeywords={this.props.sku}
            onColumnTypeChanged={(type) => {
                if (columnId === '3') {
                    this.setState(prevState => ({
                        ...prevState,
                        displayKeywords: type === "Keywords",
                        [`column${columnId}`]: type,
                    }));
                } else {
                    this.setState(
                        prevState => ({
                            ...prevState,
                            [`column${columnId}`]: type,
                        }), 
                        // () => console.log(this.state[`column${columnId}`])
                    );
                }
            }}
            highlight={(this.state.displayKeywords && this.props.sku)
                ? [...this.props.sku.primaryKeywords, ...this.props.sku.secondaryKeywords]
                : []
            }
            {...{ columnId, columnType, globalState, skuRecord, includeKeywords, updateEditedSkuRecord, editedSkuRecord, scoreCardConfig }}
        />
    }

    renderCSS = () => {
        const { groups } = this.state.syncedDivs;

        return <style type="text/css">
            {
                Object.keys(groups).map((groupId, index) => {
                    const minHeight = !groups[groupId].expanded
                        ? 30
                        : Math.max(
                            10,
                            ...Object.keys(groups[groupId].divs)
                                .map(divId => groups[groupId].divs[divId].height)
                        )
                    return `
                .${this.groupClassname(groupId)} {
                    min-height: ${minHeight}px !important;
                }
                `
                }).join('')
            }
        </style>

    }

    render() {
        

        return <SyncedDivsAppContextProvider value={this.state.syncedDivs}>
            <React.Fragment>
                {this.renderCSS()}
                <MDBRow>
                    <MDBCol key={1} size="4">
                        {this.dynamicColumn('1', this.state.column1, this.state.column1 === 'Edited' ? this.props.editedSkuRecord : this.props.skuRecord)}
                    </MDBCol>
                    <MDBCol key={2} size="4">
                        {this.dynamicColumn('2', this.state.column2, this.state.column2 === 'Edited' ? this.props.editedSkuRecord : this.props.skuRecord)}
                    </MDBCol>
                    <MDBCol key={3} size="4">
                        {this.dynamicColumn('3', this.state.column3, this.state.column3 === 'Edited' ? this.props.editedSkuRecord : this.props.skuRecord , true)}
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        </SyncedDivsAppContextProvider>
    }
}

export default ProductImprove;
