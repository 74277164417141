import React from 'react';
import {
  GlobalState,
} from '../../libraries/types/ReportUp';
import NewJobsTable from './NewJobsTable';
import { CSUIButtonLink } from '../../components/CSUI';

import './style.scss';
import {
  TeamBalance,
  ContentStatusDataService,
} from '../../libraries/DataService';
import TopLevelPageLayout from '../../layouts/TopLevelPageLayout';
import GlobalStateProvider from '../../components/GlobalStateProvider';

interface MyJobsProps {
  globalState: GlobalState;
  showStatusConfig?: boolean;
}

interface State {
  teamBalance?: TeamBalance;
}

class MyJobs extends React.Component<MyJobsProps, State> {
  constructor(props: MyJobsProps) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { teamId } = this.props.globalState.teamJobData;
    const teamBalance = await ContentStatusDataService.teamBalance({
      teamId,
    }).get({ forceRefresh: true });
    this.setState({ teamBalance });

    /* this.state.chargebeeCustomerId
        ? null
        : API_CALL_generateChargebeeCustomer(this.state.coreUser.userId).then(() => window.location.reload()) */
  }

  newLayout = () => {
    const { globalState, showStatusConfig } = this.props;
    const { teamBalance } = this.state;

    const tableProps = { globalState, showStatusConfig, teamBalance };

    const title = 'My Audits';
    const subTitle = null;

    const buttons = [
      <CSUIButtonLink
        label='New Audit'
        to='/jobs/new'
        color='green'
      />,
    ];

    return (
      <TopLevelPageLayout title={title} details={subTitle} buttons={buttons}>
        <NewJobsTable type={'draft'} {...tableProps} />
        <NewJobsTable type={'recurring'} {...tableProps} />
        <NewJobsTable type={'one-time'} {...tableProps} />
      </TopLevelPageLayout>
    );
  };

  render() {

    return this.newLayout();
  }
}

export const AdminJobs: React.FunctionComponent<MyJobsProps> = (
  globalState
) => <MyJobs {...{ ...globalState, showStatusConfig: true }} />;

export default GlobalStateProvider(MyJobs);