import axios, { AxiosResponse } from 'axios';
import { OperationConfig } from '../../../components/SiteAdminSection/operations';
import {API_URL} from '../../types/datastructures';

export function API_CALL_teamOperations(options: {
    teamId: string;
    tsUpTo?: number;
    limit?: number;
}) { 
    return axios.post<typeof options, AxiosResponse<{
        teamId: string;
        operations: {
            [opId: string]: OperationConfig
        }
    }>>(`${API_URL}/operations/teamOperations`, options); 
}