import React from 'react';
import './style.scss';
import CSLogo from '../../media/Header/ContentStatus_Basic Logo.png';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingScreenProps {
    //router: RouteComponentProps<any>;
}

interface State {

}

class LoadingScreen extends React.Component<LoadingScreenProps, State> {
    constructor(props: LoadingScreenProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="loading-screen-wrapper">
                <div className="loading-screen-form">
                    {// eslint-disable-next-line jsx-a11y/alt-text
                    }<img src={CSLogo} className="logo" />
                    <h1>
                        <CircularProgress />
                    </h1>
                </div>
            </div>
        )
    }

}

export default LoadingScreen;
