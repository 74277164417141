import React from "react";
import styles from "../styles";
import { Divider, Grid } from "@material-ui/core";
import ProgressCard from "../../ProductAudit/ProgressCard";
import { CSUIIcon } from "../../../../components/CSUI";
import { isEmpty } from "lodash";

interface Props {
    title: string;
    isMatch?: boolean;
    differencePct?: number;
    item?: any;
    differenceCount?: number;
    contentClass?: any;
    isMatchSectionHidden?: boolean;
    secondProgressCardTitle?: string;
}

const ComparisonItem: React.FC<Props> = (props) => {
    const {
        title,
        differencePct,
        isMatch,
        item,
        differenceCount,
        contentClass,
        isMatchSectionHidden,
        secondProgressCardTitle
    } = props;

    const classes = styles();

    let differenceCountSign = '';
    if (Number(differenceCount) > 0) {
        differenceCountSign = "+";
    } else if (Number(differenceCount) < 0) {
        differenceCountSign = "-";
    }
    
    return (
        <Grid container className={classes.itemWrapper}>
            <Grid item xs={12} className={classes.itemWrapperChild}>
                <Grid container className={classes.itemHeaderSection}>
                    <Grid item xs={3} className={classes.itemTitleSection}>
                        <h2 className={classes.itemTitle}>{title}</h2>
                        <h3 className={classes.itemSubTitle}>Comparison</h3>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container className={classes.progressCardsContainer}>
                            {/* {isScore &&
                            <Grid item xs={4}>
                                {(score || score === 0)
                                    ?
                                    <ProgressCard isMonitorTabCard={true} subTitle="" title="Health Score"
                                                  type="number" value={score}
                                                  icon="score"
                                                  variationType="none"
                                                  scheme={{ primary: score >= 4 ? '#4FDA03' : score >= 3 ? '#FFC000' : 'red', }}
                                    />
                                    :
                                    <ProgressCard isMonitorTabCard={true} subTitle="" title="Health Score"
                                                  type="text" value="--" icon="score"
                                                  variationType="none" />
                                }
                            </Grid>
                            } */}
                            {(differenceCount || differenceCount === 0) &&
                            <Grid item xs={4}>
                                <ProgressCard isMonitorTabCard={true} subTitle="" title={secondProgressCardTitle ? `${secondProgressCardTitle} +/-` : "Count +/-"} type="number"
                                              value={differenceCount} icon="count" valuePrefix={differenceCountSign}
                                              isInt={true}
                                              scheme={{ primary: differenceCount !== 0 ? 'red' : '#4FDA03', }}
                                              variationType="none" />
                            </Grid>
                            }
                            {(differencePct || differencePct === 0) &&
                            <Grid item xs={4}>
                                <ProgressCard isMonitorTabCard={true} subTitle="" title="% Change"
                                              type="percent" value={differencePct * 100}
                                              scheme={{ primary: differencePct !== 0 ? 'red' : '#4FDA03', }}
                                              icon="difference" variationType="none" />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{marginTop: 10}} />
                {item &&
                    <div className={isEmpty(item) ? `${classes.content}` : `${classes.content} ${contentClass}`}>
                        {isEmpty(item) ? 'No data' : item}
                    </div>
                }
                { isMatchSectionHidden ? <div style={{height: 101}}/> : isMatch !== undefined ?
                    <div className={classes.warningSection}>
                        {!isMatch ?
                            (<><CSUIIcon icon="product warning"
                                         className="icon" /> Partial match to
                                current</>)
                            :
                            <> <div className={classes.approved}/>Full match to current</>
                        }
                    </div>
                    : <div className={classes.warningPlaceHolder}></div>
                }
            </Grid>
        </Grid>
    );
};

export default ComparisonItem;
