import {
    RandomId, Objectentries,
    UserAccountObj,
    CoreUserAccount, getCoreFromUserAccount
} from "../types/datastructures";
import { firestoreDb } from "./firestore";
import { PlatformCatalogJob } from "../../components/NewJob/data";
import { ContentStatusDataService } from '../DataService';
import { API_CALL_TeamBillingPlanType } from "./apiClient";

type JobServiceProps = {
    activeUser: UserAccountObj;
} | {
    teamId: string;
}

export interface TeamJobData {
    teamId: string;
    teamJobs: {[x: string]: PlatformCatalogJob};
    dataLoading: boolean;
    planType?: string;
}

interface Listener {
    handler: (state: TeamJobData) => void
    error?: (error: any) => void
}

const speak = (listener: Listener, state: TeamJobData) => {
    try {
        listener.handler(state);
    } catch (e) {
        if (listener.error) {
            listener.error(e);
        }
    }
}

export class JobService {
    private props: JobServiceProps;


    private teamJobsListeners: {
        [listenerId: string]: Listener
    }

    private cancelTeamJobsSubscriptions: { [id: string]: () => void };

    private state: TeamJobData;

    private coreUser: CoreUserAccount;

    constructor(props: JobServiceProps) {
        this.props = props;

        const anyProps: any = this.props;

        if (anyProps.activeUser) {
            this.coreUser = getCoreFromUserAccount(anyProps.activeUser);
        }

        const teamId = (this.coreUser)
        ? this.coreUser.teamId
        : anyProps.teamId;

        this.teamJobsListeners = {};

        this.cancelTeamJobsSubscriptions = {};

        this.state = {
            teamJobs: {},
            dataLoading: true,
            teamId
        };

        this.subscribeToTeamJobs();
        this.loadTeamPlanType();
    }

    private setState = (
        stateSetter: (prevState: Readonly<TeamJobData>, props: Readonly<JobServiceProps>) => Partial<TeamJobData>,
        callback: () => any = () => void 0
    ): void => {
        this.state = { ...this.state, ...stateSetter(this.state, this.props)};
        this.shout(this.state);
        callback();
    }

    private shout = (state: TeamJobData) => Objectentries(this.teamJobsListeners).forEach(listener => {
        speak(listener, state);
    });

    private loadTeamPlanType = async () => {
        const response = await API_CALL_TeamBillingPlanType(this.state.teamId);
        const {planType} = response.data;
        this.setState(prevState => ({planType}));
    }

    private subscribeToTeamJobs = () => {
        ContentStatusDataService.initTeam(this.state.teamId);
        this.cancelTeamJobsSubscriptions['TeamAccount.jobs'] = firestoreDb.collection('TeamAccount').doc(this.state.teamId)
        .collection('catalogjobs').onSnapshot(colSnapshot => {
                this.setState(
                    prevState => {
                        const teamJobs = { ...prevState.teamJobs };
                        const docChanges = colSnapshot.docChanges();
                        const dataLoading = (docChanges.length === 0) ? {dataLoading: false} : {} as {};
                        docChanges.forEach(change => {
                            switch (change.type) {
                                case 'removed':
                                    if(this.cancelTeamJobsSubscriptions[change.doc.id]) {
                                        this.cancelTeamJobsSubscriptions[change.doc.id]();
                                        delete this.cancelTeamJobsSubscriptions[change.doc.id];
                                    }
                                    delete teamJobs[change.doc.id];
                                    break;
                                case 'added':
                                case 'modified':
                                default:
                                    if(!this.cancelTeamJobsSubscriptions[change.doc.id]) {
                                        this.cancelTeamJobsSubscriptions[change.doc.id] = firestoreDb.collection('CatalogJobs'/* 'brandJobs' */).doc(change.doc.id)
                                        .onSnapshot(docSnapshot => {
                                            this.setState(prevState1 => ({
                                                teamJobs: {...prevState1.teamJobs, [docSnapshot.id]: docSnapshot.data() as PlatformCatalogJob},
                                                dataLoading: false
                                            }))
                                        })
                                    }
                                    break;
                            }
                        });
                        return { teamJobs, ...dataLoading };
                    },
                );
        });
    }

    registerTeamJobsListener = (handler: Listener['handler'], error: Listener['error']) => {
        const listener = {handler, error};
        const listenerId = RandomId();
        this.teamJobsListeners[listenerId] = listener;
        speak(listener, this.state);
        return () => this.removeTeamJobsListener(listenerId);
    }

    private removeTeamJobsListener = (listenerId: string) => {
        delete this.teamJobsListeners[listenerId];
    }
}
