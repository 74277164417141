import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import paginate from "paginate-array";
import * as RT from '../../../@types/react-table-classic';
import { XGrid, XGridProps } from '@material-ui/x-grid';
import './style.scss';
import clsx from 'clsx';

export type RT_CellInfo = RT.CellInfo;
export type RT_Column = RT.Column;

const columnArray = (propsColumns: RT_Column[]) => {

    const columnWidths: { [columnId: string]: { columnId: string; width: number } } = {};

    const onSort = (...args) => console.log(args);

    const columns = propsColumns.map((column, idx) => {

        if (!column.columns) {
            column.columns = [column]
        }

        if (!column.columns[0].id) {
            column.columns[0].id = idx.toString()
        }

        const dxColumnBase = {
            name: column.columns[0].id,
            title: column.columns[0].id,
            getCellValue: (row: any) => (column.columns[0].Cell as any)({ original: row }) // (columnName)
        }

        const colWidth = column.columns[0].width;
        const colMinWidth = column.columns[0].minWidth;

        const width = (colWidth && typeof colWidth === 'number')
            ? colWidth
            : (colMinWidth && typeof colMinWidth === 'number')
                ? colMinWidth
                : 70;

        columnWidths[column.columns[0].id] = { columnId: column.columns[0].id, width };

        const dxColumn = {
            ...dxColumnBase,
            topHeader: column.Header,
            bottomHeader: column.columns[0].Header,
            cell: column.columns[0].Cell,
            onSort,
            style: column.columns[0].style
        }

        return dxColumn;
    });

    return {
        columns,
        columnWidths
    }
}

//type CustomColumn = ReturnType<typeof columnArray>['columns']

interface CSUITableProps {
    columnConfig: RT_Column[];
    rowConfig?: {
        [rowId: string]: {
            className?: string
            style?: React.CSSProperties
        }
    }
    // cellRenderer: (columnId: string) => React.ReactNode;
    rows: any[];
    getRowId?: (row: any) => string;
    sort?: {
        sorting: any;
        fn: (sortOps: Array<{ columnName: string, direction: string }>) => void
    };
    maxWidth?: number;
    pagination?: boolean;
    paginationSize?: number;
}

const CSUITable_defaultProps: Partial<CSUITableProps> = {
    pagination: false,
    paginationSize: 10
}

const CSUITable: React.FC<CSUITableProps> = (propsWithoutDefaults) => {
    const props = {...CSUITable_defaultProps, ...propsWithoutDefaults};

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const pageSize = props.paginationSize
    React.useEffect(() => {
        setRowsPerPage(pageSize)
    }, [pageSize])
    const classes = makeStyles({
        root: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            maxWidth: props.maxWidth ? props.maxWidth : 1000,
        },
        table: {
            // tableLayout: "auto"
        },
    })();

    const { rows, getRowId } = props;

    const getRowKey = (row, idx) => getRowId
        ? getRowId(row)
        : `${idx}`

    const rowStyle = (row, idx) => {
        const config = (props.rowConfig || {})[getRowKey(row, idx)];

        return config && config.style
            ? config.style
            : {}
    }

    const rowClassName = (row, idx) => {
        const config = (props.rowConfig || {})[getRowKey(row, idx)];

        return config && config.className
            ? config.className
            : ''
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const {
        columns,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        columnWidths
    } = columnArray(props.columnConfig);

    const paginateCollection = paginate(rows, page+1, rowsPerPage);
    const renderRows = props.pagination ? paginateCollection.data : rows;

    return (
        <>
            <TableContainer component={Paper} className={classes.root + " csui_table"}>
                <Table className={classes.root + " " + classes.table} aria-label="dense table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => {
                                const value = column.topHeader;
                                return <TableCell
                                    className={classes.root}
                                    style={{ ...(column.style || {}), width: columnWidths[column.name].width }}
                                    key={'header.' + column.name}
                                    children={value}
                                />
                            })}
                            {/* <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderRows.map((row, idx) => (
                            <TableRow
                                key={getRowKey(row, idx)}
                                className={rowClassName(row, idx)}
                                style={rowStyle(row, idx)}
                            >
                                {columns.map(column => {
                                    const value = column.getCellValue(row);

                                    return <TableCell
                                        className={classes.root}
                                        style={{ ...(column.style || {}), width: columnWidths[column.name].width }}
                                        key={`row.${idx}.${column.name}`}
                                        children={value}
                                    />
                                })}
                                {/* <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.calories}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.pagination && (
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    page={page}
                    count={paginateCollection.total}
                    rowsPerPage={paginateCollection.perPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </>
    );
}

export default CSUITable;

interface CSUIMUIXGridProps extends XGridProps {
    noCSUIDefaultStyle?: boolean
}

type customXGridType = React.MemoExoticComponent<React.ForwardRefExoticComponent<CSUIMUIXGridProps>>

export const CSUIMUIXGrid = ((props) => {
    
    React.useEffect(() => {
        const xpathExpr = "//div[contains(text(), 'Material-UI X Unlicensed product')]";
        const xpathResult = document.evaluate(xpathExpr, document, null, XPathResult.ANY_TYPE, null );
        
        const watermarkEle = [] as Node[];
        let thisNode = xpathResult.iterateNext();

        try {
            while (thisNode) {
                watermarkEle.push(thisNode);
                thisNode = xpathResult.iterateNext();
            }
        } catch (e) {
            console.error(e)
        }
        watermarkEle.forEach(ele => {
            ele.parentElement.removeChild(ele);
        });
    }, [props.loading]);

    const className = clsx("csui_mui_xgrid", props.className || '', props.noCSUIDefaultStyle ? "" : "csui_default_style")
    return <XGrid autoHeight={true} pagination {...props} className={className} />;
}) as customXGridType