import React from "react";
import { SkuKeywords } from './datastructures';
import { CSUIButton } from "../../../components/CSUI";
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { KeywordCollection } from '../datastructures';
import { Objectentries } from "../../../libraries/types/datastructures";
import clsx from 'clsx';
import '../../../assets/scss/common.scss';
import './style.scss';
import { ContentStatusDataService } from '../../../libraries/DataService';
import { CircularProgress } from "@material-ui/core";
import { TrackingService } from "../../../libraries/Tracking";

interface SKUKeywordsProps {
    skus: {
        [skuId: string]: SkuKeywords;
    },
    onSKUsKeywordsUpdated?: (skus: SkuKeywords[]) => void,
    checked: { [rowId: string]: boolean };
    teamId: string;
}
interface ExpandableSKUWithKeywords extends SkuKeywords {
    expanded: boolean,
    skuPrimaryKeywords: string,
    skuSecondaryKeywords: string,
    skuPrimaryKeywordsError: string,
    skuSecondaryKeywordsError: string
}
interface SKUKeywordsState {
    currentlySaving?: boolean;
    skus: ExpandableSKUWithKeywords[],
    primaryKeywords: string,
    secondaryKeywords: string,
    bulkExpanded: boolean,
    skusExpanded: boolean,
    sharedPrimaryKeywordsError: string,
    sharedSecondaryKeywordsError: string
}

const expandableSKUsToKeywordCollection = (skus: ExpandableSKUWithKeywords[]): KeywordCollection => {
    const result = {};
    skus.forEach((sku: ExpandableSKUWithKeywords) => {
        result[sku.skuRecord.skuId] = {
            skuId: sku.skuRecord.skuId,
            keywords: {}
        };
        sku.primaryKeywords.forEach((keyword: string) => {
            const id = keyword.replace(/[^a-zA-Z0-9]/g, "");
            result[sku.skuRecord.skuId].keywords[id] = {
                displayName: keyword,
                id,
                applied: true,
                type: "primary"
            }
        });
        sku.secondaryKeywords.forEach((keyword: string) => {
            const id = keyword.replace(/[^a-zA-Z0-9]/g, "");
            result[sku.skuRecord.skuId].keywords[id] = {
                displayName: keyword,
                id,
                applied: true,
                type: "secondary"
            }
        });
    })
    return result;
}

const KeywordInList = (props: { keyword: string, onDelete: (keyword: string) => void, color?: string }) => <div className="editable-list-item" style={{ color: props.color || "default" }}>
    {props.keyword}
    <Close onClick={() => props.onDelete(props.keyword)} style={{ color: "red" }} />
</div>;

class SKUKeywords extends React.PureComponent<
    SKUKeywordsProps,
    SKUKeywordsState
    > {

    constructor(props: SKUKeywordsProps) {
        super(props);
        this.state = {
            skus: Objectentries(props.skus).filter((sku: SkuKeywords) => props.checked[sku.skuRecord.skuId]).map((sku: SkuKeywords) => {
                return {
                    ...sku,
                    expanded: false,
                    skuPrimaryKeywords: "",
                    skuSecondaryKeywords: "",
                    skuPrimaryKeywordsError: "",
                    skuSecondaryKeywordsError: ""
                }
            }),
            primaryKeywords: "",
            secondaryKeywords: "",
            bulkExpanded: false,
            skusExpanded: false,
            sharedPrimaryKeywordsError: "",
            sharedSecondaryKeywordsError: ""

        }
    }

    componentDidMount() {
        TrackingService.onEvent("viewKeywords");
        this.setState({
            skus: Object.values(this.props.skus)
                .filter((sku: SkuKeywords) =>
                    this.props.checked[sku.skuRecord.skuId]
                )
                .map((sku: SkuKeywords) => {
                    return {
                        ...sku,
                        expanded: false,
                        skuPrimaryKeywords: "",
                        skuSecondaryKeywords: "",
                        skuPrimaryKeywordsError: "",
                        skuSecondaryKeywordsError: "",
                    };
                }),
        })
    }

    saveChanges = async () => {
        await new Promise(resolve => this.setState(
            {currentlySaving: true},
            async () => {
                this.reportChanges();
                await this.persistSKUsKeywords();
                TrackingService.onEvent("saveKeywords");
                this.setState(
                    {currentlySaving: false},
                    resolve as any
                );
            }
        ));
    }

    deleteSharedKeyword = (keyword: string, type: "primaryKeywords" | "secondaryKeywords") => {
        this.setState({
            skus: this.state.skus.map((sku: ExpandableSKUWithKeywords) => {
                sku[type] = sku[type].filter((existingKeyword: string) => existingKeyword !== keyword)
                return sku;
            })
        })
    }

    generateSKUInputChange = (textareaName: "skuPrimaryKeywords" | "skuSecondaryKeywords", skuId: string) => (e: React.FormEvent<HTMLTextAreaElement>): void => {
        const { value } = e.target as HTMLTextAreaElement;
        this.updateSKUProperty(skuId, textareaName, value);
    }

    generateInputChange = (textareaName: "primaryKeywords" | "secondaryKeywords") => (e: React.FormEvent<HTMLTextAreaElement>): void => {
        const { value } = e.target as HTMLTextAreaElement;
        this.setState(prevState => {
            return {
                ...prevState,
                [textareaName]: value
            };
        });
    }

    generateAddSharedKeywords = (type: "primaryKeywords" | "secondaryKeywords", errorContainer: "sharedPrimaryKeywordsError" | "sharedSecondaryKeywordsError") => () => {
        const userKeywords = this.state[type].split("\n");
        const keywords = userKeywords.filter((keyword: string, index: number) => keyword.length > 0 && /[a-zA-Z0-9]+/.test(keyword) && userKeywords.indexOf(keyword) === index);

        if (keywords.length === 0) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [errorContainer]: "You must enter at least one Keyword."
                }

            });
        } else if (Object.keys(this.props.checked).length === 0) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [errorContainer]: "You have not selected any SKUs."
                }

            });
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [type]: "",
                    [errorContainer]: "",
                    skus: this.state.skus.map((sku: ExpandableSKUWithKeywords) => {
                        const newSkuKeywords = sku[type].concat(keywords);
                        sku[type] = newSkuKeywords.filter((keyword: string, index: number) => newSkuKeywords.indexOf(keyword) === index);
                        return sku;
                    })
                }
            });
        }
    }

    reportChanges = () => {
        if (this.props.onSKUsKeywordsUpdated) {
            this.props.onSKUsKeywordsUpdated(this.state.skus as SkuKeywords[]);
        }
    }

    generateAddSKUKeywords = (type: "skuPrimaryKeywords" | "skuSecondaryKeywords", errorContainer: "skuPrimaryKeywordsError" | "skuSecondaryKeywordsError", skuId: string) => () => {
        const userKeywords = this.state.skus.find((sku: ExpandableSKUWithKeywords) => sku.skuRecord.skuId === skuId)[type].split("\n");
        const keywords = userKeywords.filter((keyword: string, index: number) => keyword.length > 0 && /[a-zA-Z0-9]+/.test(keyword) && userKeywords.indexOf(keyword) === index);

        if (keywords.length === 0) {
            this.updateSKUProperty(skuId, errorContainer, "You must enter at least one Keyword.");
        } else {
            this.setState(prevState => {
                const skuProperty = type === "skuPrimaryKeywords" ? "primaryKeywords" : "secondaryKeywords";
                return {
                    ...prevState,
                    skus: this.state.skus.map((sku: ExpandableSKUWithKeywords) => {
                        if (sku.skuRecord.skuId === skuId) {
                            const newSkuKeywords = sku[skuProperty].concat(keywords);
                            sku[skuProperty] = newSkuKeywords.filter((keyword: string, index: number) => newSkuKeywords.indexOf(keyword) === index);
                            sku[type] = "";
                            sku[errorContainer] = "";

                        }
                        return sku;
                    })
                }
            });
        }
    }

    persistSKUsKeywords = async (skuId?: string) => {
        const skus = skuId
        ? [this.state.skus.find((sku: ExpandableSKUWithKeywords) => sku.skuRecord.skuId === skuId)]
        : this.state.skus;
        const keywordCollection = expandableSKUsToKeywordCollection(skus);
        await ContentStatusDataService.skuKeywords({teamId: this.props.teamId}).set(keywordCollection);
    }

    updateSKUProperty = (skuId: string, property: string, value: string | boolean) => {
        this.setState(prevState => {
            return {
                ...prevState,
                skus: prevState.skus.map((sku: ExpandableSKUWithKeywords) => {
                    if (sku.skuRecord.skuId === skuId) {
                        sku[property] = value;
                    }
                    return sku;
                })
            }
        })
    }
    getSharedKeywords = (type: "primaryKeywords" | "secondaryKeywords"): string[] => {
        let sharedKeywords: string[] = [];
        this.state.skus.forEach((sku: ExpandableSKUWithKeywords) => {
            sharedKeywords = sharedKeywords.concat(sku[type].filter((keyword: string) => !sharedKeywords.includes(keyword) && this.state.skus.find((differentSku: ExpandableSKUWithKeywords) => differentSku.skuRecord.skuId !== sku.skuRecord.skuId && differentSku[type].includes(keyword))));
        })
        return sharedKeywords;
    }

    renderSKUKeywordsInput = (type: "skuPrimaryKeywords" | "skuSecondaryKeywords", errorContainer: "skuPrimaryKeywordsError" | "skuSecondaryKeywordsError", skuId: string) => {
        const sku = this.state.skus.find((sku: ExpandableSKUWithKeywords) => sku.skuRecord.skuId === skuId);
        return <div className="width20pct align-left">
            <div className="list-header indented">
                {type === "skuPrimaryKeywords" ? "Primary" : "Secondary"} Keywords

            </div>
            <textarea
                name={type}
                value={sku[type]}
                onChange={this.generateSKUInputChange(type, skuId)} className="with-round-container-border standard-column-list-container" placeholder="Type or paste keywords or phrases with each unique keyword or phrase in its own line">
            </textarea>
            {sku[errorContainer] && <div className="error-text">{sku[errorContainer]}</div>}
            <div className="text-right action-button-container">
                <CSUIButton onClick={this.generateAddSKUKeywords(type, errorContainer, skuId)} color="green" flat>Add</CSUIButton>
            </div>
        </div>
    }

    renderSKUKeywordsOptions = (skuId: string) => {
        return <div className="flex-row-container with-round-container-border with-padding" style={{ paddingLeft: "25px", paddingRight: "30px" }}>
            {this.renderSKUKeywordsInput("skuPrimaryKeywords", "skuPrimaryKeywordsError", skuId)}
            {this.renderSKUKeywords("primaryKeywords", skuId)}
            <div className="width10pct" />
            {this.renderSKUKeywordsInput("skuSecondaryKeywords", "skuSecondaryKeywordsError", skuId)}
            {this.renderSKUKeywords("secondaryKeywords", skuId)}
        </div>
    }
    renderPrimaryKeywords = () => {
        return <div className="width20pct align-left">
            <div className="list-header indented">
                Primary Keywords
            </div>
            <textarea
                name="pimaryKeywords"
                value={this.state.primaryKeywords}
                onChange={this.generateInputChange("primaryKeywords")} className="with-round-container-border standard-column-list-container" placeholder="Type or paste keywords or phrases with each unique keyword or phrase in its own line">
            </textarea>
            {this.state.sharedPrimaryKeywordsError && <div className="error-text">{this.state.sharedPrimaryKeywordsError}</div>}
            <div className="text-right action-button-container">
                <CSUIButton onClick={this.generateAddSharedKeywords("primaryKeywords", "sharedPrimaryKeywordsError")} color="green" flat>Add</CSUIButton>
            </div>
        </div>
    }
    renderSharedPrimaryKeywords = () => {
        const sharedPrimaryKeywords = this.getSharedKeywords("primaryKeywords");
        return <div className="width20pct align-left">
            <div className="list-header indented">
                Shared Primary Keywords
                    </div>
            <div className="standard-column-list-container without-padding">
                {sharedPrimaryKeywords.length === 0 && <div className="no-items-notice">
                    There are no shared primary keywords.
            </div>}
                {sharedPrimaryKeywords.map((keyword: string, index: number) => <KeywordInList key={`shared-primary-keyword-${index}`} onDelete={(keyword: string) => this.deleteSharedKeyword(keyword, "primaryKeywords")} keyword={keyword} />)}
            </div>
        </div>
    }
    renderSecondaryKeywords = () => {
        return <div className="width20pct align-right">
            <div className="list-header indented">
                Secondary Keywords
            </div>
            <textarea
                name="secondaryKeywords"
                value={this.state.secondaryKeywords}
                onChange={this.generateInputChange("secondaryKeywords")} className="with-round-container-border standard-column-list-container" placeholder="Type or paste keywords or phrases with each unique keyword or phrase in its own line">
            </textarea>
            {this.state.sharedSecondaryKeywordsError && <div className="error-text">{this.state.sharedSecondaryKeywordsError}</div>}
            <div className="text-right action-button-container">
                <CSUIButton onClick={this.generateAddSharedKeywords("secondaryKeywords", "sharedSecondaryKeywordsError")} color="green" flat>Add</CSUIButton>
            </div>
        </div>
    }
    renderSharedSecondaryKeywords = () => {
        const sharedSecondaryKeywords = this.getSharedKeywords("secondaryKeywords");
        return <div className="width20pct align-right">
            <div className="list-header indented">
                Shared Secondary Keywords
                    </div>
            <div className="standard-column-list-container without-padding">
                {sharedSecondaryKeywords.length === 0 && <div className="no-items-notice">
                    There are no shared secondary keywords.
            </div>}
                {sharedSecondaryKeywords.map((keyword: string, index: number) => <KeywordInList key={`shared-secondary-keyword-${index}`} onDelete={(keyword: string) => this.deleteSharedKeyword(keyword, "secondaryKeywords")} keyword={keyword} />)}
            </div>
        </div>
    }

    deleteSKUKeyword = (keyword: string, type: "primaryKeywords" | "secondaryKeywords", skuId: string) => {
        this.setState({
            skus: this.state.skus.map((sku: ExpandableSKUWithKeywords) => {
                if (sku.skuRecord.skuId === skuId) {
                    sku[type] = sku[type].filter((existingKeyword: string) => existingKeyword !== keyword)
                }
                return sku;
            })
        })
    }

    renderSKUKeywords = (type: "primaryKeywords" | "secondaryKeywords", skuId: string) => {
        const sku = this.state.skus.find((sku: ExpandableSKUWithKeywords) => sku.skuRecord.skuId === skuId);
        const keywordType = type === "primaryKeywords" ? "Primary" : "Secondary";
        return <div className="width20pct align-right">
            <div className="list-header indented">
                {keywordType} Keywords
                        <span className="right-label">
                    <span className={sku[type].length > 10 ? "bolder" : "bold"}>
                        {sku[type].length}
                    </span>
                    <span className={sku[type].length > 10 ? "bolder" : undefined}> / 10</span>
                </span>
            </div>
            <div className="standard-column-list-container without-padding">
                {sku[type].length === 0 && <div className="no-items-notice">
                    This SKU does not have any {keywordType} Keywords
            </div>}
                {sku[type].map((keyword: string, index: number) => <KeywordInList color={index > 9 && "red"} key={`shared-secondary-keyword-${index}`} onDelete={(keyword: string) => this.deleteSKUKeyword(keyword, type, skuId)} keyword={keyword} />)}
            </div>
        </div>
    }

    toggleSku = (skuId: string) => {
        this.setState(prevState => {
            return {
                skus: prevState.skus.map((sku: ExpandableSKUWithKeywords) => {
                    if (sku.skuRecord.skuId === skuId) {
                        sku.expanded = !sku.expanded;
                    }
                    return sku;
                }
                )
            }
        });
    }

    renderSKUsList = () => {
        const isZoomed = ((window.outerWidth - 10) / window.innerWidth) > 1.2
        const zoomClass = isZoomed && window.innerWidth < 900 ? 'table-list-zoomed' : ''
        return <ul className={`table-list ${zoomClass}`}>
            {this.state.skus.map((sku: ExpandableSKUWithKeywords, index: number) => <li key={`sku-${index}`}>
                <div className="tr-columns" onClick={() => this.toggleSku(sku.skuRecord.skuId)}>
                    <div className="width3pct" style={{ paddingTop: "3px" }}>
                        <button className="options-toggler" />
                    </div>
                    <div className="width5pct text-center">
                        {sku.skuRecord.mainImage && <img src={sku.skuRecord.mainImage} alt={sku.skuRecord.title} />}
                    </div>
                    <div className="width10pct vertically-aligned">
                        {sku.skuRecord.brand}
                    </div>
                    <div className="width5pct vertically-aligned">
                        {sku.skuRecord.model}
                    </div>
                    <div className="width10pct vertically-aligned">
                        {sku.skuRecord.upc}
                    </div>
                    <div className="width40pct vertically-aligned">
                        {sku.skuRecord.title}
                    </div>
                    <div className="width25pct vertically-aligned">
                        <div className="bold">
                            Total Keywords: <span className={(sku.primaryKeywords.length + sku.secondaryKeywords.length) > 10 ? "bolder" : undefined}>{sku.primaryKeywords.length + sku.secondaryKeywords.length}</span>
                        </div>
                        <div className="bold">
                            Primary: <span className={sku.primaryKeywords.length > 10 ? "bolder" : undefined}>{sku.primaryKeywords.length}</span>
                        </div>
                        <div className="bold">
                            Secondary: <span className={sku.secondaryKeywords.length > 10 ? "bolder" : undefined}>{sku.secondaryKeywords.length}</span>
                        </div>
                    </div>
                </div>
                {sku.expanded && this.renderSKUKeywordsOptions(sku.skuRecord.skuId)}
            </li>)}
        </ul>
    }

    renderNoSkusNotice=()=>{
        return <Grid container xs={12} justify='center' style={{marginTop:"300px"}}>
            <Grid item xs={5} style={{fontSize:"14px",border:"solid 1px #000",borderRadius:"5px",padding:"30px",textAlign:"center",height:"auto",fontWeight:500}}>
                Please select at least one SKU
            </Grid>
        </Grid>
    }

    renderKeywordsOptions=()=>{
        return  <div>
        <Grid className="card-body" >

            <div className="flex-row-container" style={{ marginTop: "20px" }}>
                <button className={`bulk-keywords-toggler ${this.state.bulkExpanded && "expanded"}`} onClick={() => this.setState({ bulkExpanded: !this.state.bulkExpanded })} />
                <div>
                    <div className='sku-title-container'>
                        <h1 className={clsx('sku-title', 'standard-subtitle')}>Add In Bulk</h1>
                        <CSUIButton className='sku-title-control' onClick={this.saveChanges}>Save</CSUIButton>
                    </div>
                    <p className={clsx('standard-subtext', 'sku-title-desc')}>
                        Adding keywords in bulk will add them to all the selected SKUs, which is great for adding the brand name or common features. Don't worry, it won't duplicate if a SKU already has one of the keywords. However, plurals will be considered unique keywords. TIP: If your selected SKUs share a lot of the same keywords, but not all - it might be faster to paste them all in bulk anyway and simply scroll down to remove those that don't fit.
                    </p>
                </div>
            </div>
            {this.state.bulkExpanded && <Grid container className="with-round-container-border with-padding with-margin-top with-margin-bottom">
                <Grid item xs={12} style={{ paddingLeft: "5px", paddingRight: "10px" }}>

                    <div className="flex-row-container">
                        {this.renderPrimaryKeywords()}
                        {this.renderSharedPrimaryKeywords()}
                        <div className="width10pct" />
                        {this.renderSecondaryKeywords()}
                        {this.renderSharedSecondaryKeywords()}
                    </div>
                </Grid>

            </Grid>}

            <div className="flex-row-container" style={{ marginTop: "20px" }}>
                <div className="flex-row-container" style={{ width: "35%" }}><button style={{ marginRight: "15px", marginLeft: "-5px" }} className={`bulk-keywords-toggler ${this.state.skusExpanded && "expanded"}`} onClick={() => this.setState(
                    {
                        skusExpanded: !this.state.skusExpanded,
                        skus: this.state.skus.map((sku: ExpandableSKUWithKeywords) => {
                            sku.expanded = !this.state.skusExpanded;
                            return sku;
                        })
                    })} />

                    <h1 style={{ marginLeft: "30px" }} className="standard-subtitle">SKU by SKU</h1>
                </div>
                {this.state.skus.find((sku: SkuKeywords) => sku.primaryKeywords.length > 10 || sku.secondaryKeywords.length > 10) && <div style={{ width: "65%", marginRight: "20px" }} className="error-message">

                    <b>Error:</b> Some SKUS have surpassed the maximum of 10 Primary or Secondary keywords that will be audited. Extras will be saved but not audited.

            </div>}
            </div>
        </Grid>
        {this.renderSKUsList()}
    </div>
    }

    render() {
        const { currentlySaving, skus } = this.state;
        const hasSkus = Object.keys(this.props.checked).length > 0;

        return <Grid container className="catalog-filters sku-keywords-container">
                <Grid item xs={12}>
                    <h3 className="standard-header-title">
                        You've Selected {
                            Object.keys(skus).length
                        } SKUS for Adding Keywords
                    </h3>

                    <p className="standard-header-subtext">
                        By utilizing the most current keywords within your titles, descriptions and bullets helps to ensure your products show up in search results.
                </p>
                {currentlySaving ? (
                    <div className='loader-container'>
                        <CircularProgress />
                    </div>
                    ) : (
                    <React.Fragment>
                        {hasSkus
                            ? this.renderKeywordsOptions()
                            : this.renderNoSkusNotice()}
                    </React.Fragment>
                )}
                </Grid>
        </Grid>;
    }
}

export default SKUKeywords;
