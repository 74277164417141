import React from 'react';
import {MDBDatePicker} from 'mdbreact';
import { CSUIIcon } from '../../../../../components/CSUI';
import './style.scss';

interface IconDatePickerProps {
    value:Date,
    onChange:(date:Date)=>void
}
interface IconDatePickerState {
    pickerId:string;
}

export default class IconDatePicker extends React.Component<IconDatePickerProps,IconDatePickerState> {
    constructor(props:IconDatePickerProps) {
        super(props);
        this.state={
            pickerId:`DatePicker${Date.now()}`
        }
    }
    
    toggleDatePicker=(e:React.MouseEvent)=>{
        e.stopPropagation();
        if(!document.querySelector(".MuiDialog-root")) {
            document.getElementById(`${this.state.pickerId}`).click();
        }
        
    }
    
    render() {
        return <button onClick={this.toggleDatePicker} className="icon-button date-selector">
        <CSUIIcon icon="product calendar" className="icon" />
        <div className="hidden-datepicker">
        <MDBDatePicker valueDefault={this.props.value} getValue={this.props.onChange} id={this.state.pickerId} />
        </div>
        
        </button>
    }
}