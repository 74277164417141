import React, { useState } from 'react';
//import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TopBar from '../MainLayout/TopBar';
import { DetailBarAppContextProvider } from '../../libraries/types/ReportUp';
import DemoNavbar from '../../pages/DemoNavbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    //paddingTop: 52,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    paddingTop: 12,
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const defaultDetailBar = (detailBar: any) =>
  detailBar ? (
    <div
      style={{
        position: 'relative',
        //top: '53px',
        left: '0px',
        right: '0px',
        zIndex: 100,
        boxShadow:
          '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',
      }}
      ref={(ref) => {
        if (detailBar && detailBar.registerDomNode) {
          detailBar.registerDomNode(ref);
        }
      }}
    />
  ) : null;

type detailBar = {
  node: React.ReactNode;
  registerDomNode: (x) => void;
};

const registerDetailBar = (setDetailBar: any) => (children: detailBar) => {
  setDetailBar(children);
  return () => setDetailBar(undefined);
};

export const NewMainLayout: React.ComponentType = (props) => {
  const classes = useStyles();
  const [detailBar, setDetailBar] = useState([]);

  return (
    <div className={classes.root}>
      <TopBar /><div style={{minHeight:52}}>&nbsp;</div>
      <DemoNavbar />
      {defaultDetailBar(detailBar)}
      <DetailBarAppContextProvider
        value={{ register: registerDetailBar(setDetailBar) }}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{props.children}</div>
          </div>
        </div>
      </DetailBarAppContextProvider>
    </div>
  );
};

export default NewMainLayout;
