import makeStyles from '@material-ui/core/styles/makeStyles';


const styles = makeStyles((theme) => ({
    root: {
        height: '54px',
        // remove after removing a padding between header and page content
        //marginTop: '-12px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',

    },
    link: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
        padding: theme.spacing(1, 1.5),
        cursor: 'pointer',
        color: '#ffffff',
        '&:hover': {
            color: 'unset'
        }
    },
    labelContainer: {
        marginLeft: theme.spacing(1),
    },
    nextArrow: {
        marginLeft: theme.spacing(1),
        color: '#BA55D3',
        fontSize: '3.2rem',
    },
    active: {
        background: '#6b19b7',
        fontWeight: 500
    },
    title: {
        fontSize: '.8rem'
    },
    subTitle: {
        lineHeight: '10px',
        fontSize: '1rem'
    },
    icon: {
        fontSize: '2.7rem',
    }
}));

export default styles;
