import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GlobalState } from '../../../libraries/types/ReportUp';
import { CSUIButton, CSUIButtonCloseX, CSUISegment } from '../../../components/CSUI';
import { firestoreDb } from '../../../libraries/Util';
import Grid from '@material-ui/core/Grid';
import GlobalStateProvider from '../../../components/GlobalStateProvider';
import Popper from '@material-ui/core/Popper';
import { RHInput } from '../../../components/CSUI/RHInput';

interface TeamSettings {
    teamName?: string;
    metrics: {
        msrp?: number;
        estimated_daily_unit_sales?: number;
        estimated_daily_unit_sales_online?: number;
        average_conversion_rate?: number;
        gross_margin?: number;
        average_return_rate?: number;
        average_cost_per_return?: number;
    };
    branding?: {
        partnerLogo?: string;
    }
}

const defaultSettings: TeamSettings = {
    teamName: '',
    metrics: {
        msrp: 0,
        estimated_daily_unit_sales: 0,
        estimated_daily_unit_sales_online: 0,
        average_conversion_rate: 0,
        gross_margin: 0,
        average_return_rate: 0,
        average_cost_per_return: 0
    },
    branding: {

    }
}

export const TeamSettingsDataService = {
    get: async (teamId: string) => {
        const teamDoc = await firestoreDb.collection("TeamAccount").doc(teamId).get();
        let settings = defaultSettings;
        
        if(teamDoc.exists) {
            const docSettings = teamDoc.data().settings
            if (docSettings) {
                settings = docSettings;
            }
        }

        return settings;
    },
    set: async (teamId: string, settings: TeamSettings) => {
        const teamDoc = await firestoreDb.collection("TeamAccount").doc(teamId).get();
        if (teamDoc.exists) {
            await firestoreDb.collection("TeamAccount").doc(teamId).update({settings});
        } else {
            await firestoreDb.collection("TeamAccount").doc(teamId).set({...defaultSettings, settings});
        }
    },
    defaultSettings
}

interface TeamSettingsFormProps {
    globalState: GlobalState;
    modalView?: boolean;
}

export const TeamSettingsForm = GlobalStateProvider<TeamSettingsFormProps>((props) => {
    
    const { control, handleSubmit, reset } = useForm<TeamSettings>({
        defaultValues: defaultSettings,
        mode: "onChange"
    });
    
    const { isDirty } = useFormState({
        control
    });
    
    useEffect(() => {
        const componentDidMount = async () => {
            const settings = await TeamSettingsDataService.get(props.globalState.teamJobData.teamId);
            reset(settings);
        }
        componentDidMount();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // <-- Empty dependency array makes this a "componentDidMount hook"

    const onSubmit = (data: TeamSettings) => {
        TeamSettingsDataService.set(props.globalState.teamJobData.teamId, data);
        reset(data);
    };
  
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div style={{display: 'flex', flexDirection: 'column',}}>
            <div style={{display: 'flex'}}>
                <CSUIButton type="submit" label="Save" color="green" style={{marginLeft: 'auto'}} disabled={!isDirty}/>
            </div>
            <Grid container spacing={2} className='row' style={{marginTop: 20}}>
                {props.modalView ? null : <RHInput control={control} type='text' name="teamName" label="Team Name" rules={{ required: true }} />}
                <RHInput control={control} type='number' name="metrics.msrp" label="Average MSRP" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.estimated_daily_unit_sales" label="Average Daily Unit Sales" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.estimated_daily_unit_sales_online" label="Average Daily Unit Sales Online" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.average_conversion_rate" label="Average Conversion Rate" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.gross_margin" label="Average Gross Margin" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.average_return_rate" label="Average Return Rate" rules={{ required: true }} />
                <RHInput control={control} type='number' name="metrics.average_cost_per_return" label="Average Cost Per Return" rules={{ required: true }} />
            </Grid>
        </div>
    </form>);
});

interface TeamSettingsModalProps {
    render: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => JSX.Element
}

export const TeamSettingsModal: React.FC<TeamSettingsModalProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const closeModal = () => setAnchorEl(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        anchorEl ? closeModal() : setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "team-settings-popper" : undefined;

    const triggerEle = props.render(handleClick) || null;

    return (<>
        {triggerEle}
        <Popper id={id} open={open} anchorEl={anchorEl}>
            <CSUISegment
                style={{
                    backgroundColor: "white",
                    width: 640,
                    padding: 20,
                }}
            >
                <CSUIButtonCloseX topRight={true} onClick={closeModal} />
                <TeamSettingsForm modalView={true} />
            </CSUISegment>
        </Popper>
    </>);
};