import React from 'react';
import { GlobalState } from '../../libraries/types/ReportUp';
import './style.scss';
import { set } from 'lodash';
import '../../assets/scss/common.scss';
import { CSUIIcon, CSUIButtonCloseX, CSUISegment } from '../../components/CSUI';
import {
  JobProduct,
  convertSkuToJobProduct,
  NavButtonProps,
} from './datastructures';
import ProductAudit from './ProductAudit';
import {
  SKUsList,
  Tag,
} from '../../pages/Catalog/SKUTaggingView/datastructures';
import { SkuRecord } from '../../pages/Catalog/catalog-datamodel';
import { getKeywordsSnapshot } from './data';
import { querystringparser, history } from '../../libraries/Util';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ArchivedVersion from './Common/ArchivedVersion';
import ProductImprove from './ProductImprove';
import {
  SKUWithKeywords,
  addKeywordsToSKURecord,
  SkuKeywords,
  SKUWithKeywordsToSkuKeywords,
} from '../../pages/Catalog/SKUKeywords/datastructures';
import { ContentStatusDataService } from '../../libraries/DataService';
import SKUKeywords from '../../pages/Catalog/SKUKeywords';
import SKUTaggingView from '../../pages/Catalog/SKUTaggingView';
import GlobalStateProvider from '../../components/GlobalStateProvider';
import Grid from '@material-ui/core/Grid/Grid';
import { WorkFlowStage } from './datastructures';
import ProductMonitorTab from './ProductMonitorTab';

interface Props extends RouteComponentProps {
  globalState: GlobalState;
  customTags?: Tag[];
}

interface State {
  recordId?: string;
  section: 'audit' | 'improve' | 'monitor';
  selectedSections: string[];
  product: JobProduct;
  skuRecord?: SkuRecord;
  comparedSkuRecord?: SkuRecord;
  skusTags: SKUsList;
  customTags?: Tag[];
  editedSkuRecord?: SkuRecord;
  sku: SKUWithKeywords;
  workflowStage:
    | 'not-reviewed'
    | 'in-progress'
    | 'review'
    | 'approved'
    | 're-submitted';
  view: {
    detailBarView?: string;
  };
}

export const NavButton = (props: NavButtonProps) => {
  const classes = [];
  if (props.active) {
    classes.push('active');
  }
  if (props.larger) {
    classes.push('larger');
  } else if (props.large) {
    classes.push('large');
  }

  return (
    <button
      title={props.title}
      className={classes.length ? classes.join(' ') : undefined}
      onClick={props.onClick}>
      <CSUIIcon className='icon' icon={props.icon as any} />
      <span>{props.text}</span>
    </button>
  );
};

class CatalogProduct extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const parsedLocationSearch = querystringparser(props.location.search);
    const recordIdParam = parsedLocationSearch['recordId'];
    const recordId = recordIdParam && recordIdParam[0];

    this.state = {
      recordId,
      section: 'audit',
      product: undefined, // demoProduct,
      selectedSections: [],
      sku: null,
      skusTags: null,
      customTags: [],
      workflowStage: 'not-reviewed',
      view: {},
    };
  }

  loadEditedSkuRecord = async (
    teamId: string,
    retailer: string,
    skuId: string
  ) => {
    let editsData;
    try {
      editsData = await ContentStatusDataService.skuEdits({
        teamId,
        retailer,
        skuId,
      }).get();
    } catch (e) {
      console.log('e', e);
    }

    return editsData;
  };

  async loadSkuRecord() {
    const {
      globalState: {
        teamJobData: { teamId },
      },
    } = this.props;

    const skuRecord = await ContentStatusDataService.catalogData({
      teamId: teamId,
    }).getSkuByRecordId(this.state.recordId);

    if (skuRecord) {

      const { retailer, skuId } = skuRecord;

      const [
        editedSkuRecord,
        skusTags,
        customTags,
        skuWithKeywords,
        stageSKUs,
        comparedSkuRecord,
      ] = await Promise.all([
        ContentStatusDataService.skuEdits({
          teamId,
          retailer,
          skuId,
        }).get(),
        ContentStatusDataService.skuTags({
          teamId,
        }).get(),
        ContentStatusDataService.skuTags({
          teamId: teamId,
        }).getList(),
        ContentStatusDataService.skuKeywords({
          teamId,
        }).getBySkuId(skuRecord.skuId),
        ContentStatusDataService.skuWorkflowStages({
          teamId,
        }).get(),
        ContentStatusDataService.catalogData({
          teamId,
        }).getSkuBySnapshotId(skuId, retailer, skuRecord.status.accuracy?.previous?.metadata.snapshotId || ''),
      ]);

      const stageInfo = skuRecord
        ? stageSKUs[skuRecord.skuId]?.workflow_stage
        : undefined;
      const sku = skuWithKeywords
        ? addKeywordsToSKURecord(skuRecord, skuWithKeywords)
        : addKeywordsToSKURecord(skuRecord);
      const product = {
        ...convertSkuToJobProduct(skuRecord),
        keywordSnapshot: getKeywordsSnapshot(sku),
      };

      this.setState({
        sku,
        workflowStage: stageInfo,
        skuRecord,
        product,
        skusTags,
        customTags,
        editedSkuRecord,
        comparedSkuRecord
      });
    } else {
      //console.log("No SkuRecord for", this.state.recordId)
    }
  }

  async componentDidMount() {
    this.loadSkuRecord();
  }

  updateEditedSkuRecord = async (editedSkuRecord: SkuRecord) => {
    this.setState({ editedSkuRecord });

    const { retailer, skuId, teamId } = editedSkuRecord;

    await ContentStatusDataService.skuEdits({
      teamId,
      retailer,
      skuId,
    }).set(editedSkuRecord);
  };

  setHash = (hashId: string, windowLocationSearch: string = '') => {
    const { location } = this.props;
    const config = {
      pathname: location.pathname,
      search: windowLocationSearch,
      hash: hashId ? '#' + hashId : '',
    };
    history.replace(config);
  };

  showSection = (section: 'audit' | 'improve' | 'monitor') => () => {
    this.loadSkuRecord();
    const windowLocationSearch = window.location.search;
    this.setState({ section }, () =>
      this.setHash(section, windowLocationSearch)
    );
  };

  selectTab = (
    tab: 'tags' | 'keywords' | 'live' | 'edited' | 'master' | 'keywords-column'
  ) => {
    if (!this.state.selectedSections.includes(tab)) {
      this.setState({
        selectedSections: [...this.state.selectedSections, tab],
      });
    } else {
      this.setState({
        selectedSections: this.state.selectedSections.filter(
          (item: string) => item !== tab
        ),
      });
    }
  };

  renderNavColumns = () => {
    return (
      <React.Fragment>
        <h1>Columns</h1>
        <NavButton
          icon='product live'
          active={this.state.selectedSections.includes('live')}
          onClick={() => this.selectTab('live')}
          text='Live'
        />
        <NavButton
          icon='product pencil'
          active={this.state.selectedSections.includes('edited')}
          onClick={() => this.selectTab('edited')}
          text='Edited'
        />
        <NavButton
          icon='product master'
          active={this.state.selectedSections.includes('master')}
          onClick={() => this.selectTab('master')}
          text='Master'
        />
        <NavButton
          icon='product keywords'
          active={this.state.selectedSections.includes('keywords-column')}
          onClick={() => {}}
          title='Coming Soon'
          text='Keywords'
        />
      </React.Fragment>
    );
  };

  renderCurrentSection = () => {
    const { skuRecord, editedSkuRecord, comparedSkuRecord } = this.state;

    switch (this.state.section) {
      case 'audit':
        return (
          <ProductAudit
            sku={this.state.sku}
            product={this.state.product}
            customTags={this.props.customTags}
            skuRecord={skuRecord}
            setTags={this.setSKUsTags}
            skuTags={
              this.state.skusTags
                ? this.state.skusTags[this.state.skuRecord?.skuId]
                : {}
            }
          />
        );
      case 'improve':
        return (
          <ProductImprove
            product={this.state.product}
            globalState={this.props.globalState}
            sku={this.state.sku}
            skuRecord={skuRecord}
            editedSkuRecord={editedSkuRecord ? editedSkuRecord : skuRecord ? JSON.parse(JSON.stringify(skuRecord)) : undefined}
            updateEditedSkuRecord={this.updateEditedSkuRecord}
          />
        );
      case 'monitor':
        return (<ProductMonitorTab
            skuRecord={skuRecord}
            comparedSkuRecord={comparedSkuRecord}
            globalState={this.props.globalState}
        />)
    }
  };

  closePage = () => {
    const { recordId } = this.state;

    const link = `/catalog${recordId ? '?recordId=' + recordId : ''}`;
    this.setState({
      editedSkuRecord: undefined,
    });

    this.props.history.push(link);
  };

  renderProductData = () => {
    const loadedDiv = (
      <div>
        <ArchivedVersion
          product={this.state.product}
          skuRecord={this.state.skuRecord}
          skuStage={this.state.workflowStage}
          setWorkflowStage={this.setWorkflowStage}
        />

        {this.renderCurrentSection()}
      </div>
    );

    const loadingDiv = (
      <div className='dataLoader'>
        <div className='loaderHeader'>Data Loading...</div>
      </div>
    );

    return this.state.product ? loadedDiv : loadingDiv;
  };

  closeModal = () => {
    this.setState(
      (prevState) => ({
        view: {
          ...prevState.view,
          detailBarView: undefined,
        },
      }),
      () => this.setHash('', window.location.search)
    );
  };

  genToggleModal = (detailBarView: string) => () => {
    this.loadSkuRecord();
    if (this.state.view.detailBarView === detailBarView) {
      this.closeModal();
    } else {
      this.setState(
        (prevState) =>
          set({ ...prevState }, 'view.detailBarView', detailBarView),
        () => this.setHash(detailBarView, window.location.search)
      );
    }
  };

  setSKUsTags = async (data: {skus?: SKUsList, tags?: Tag[]}, alreadySet = true) => {
    if (!alreadySet) {
      await ContentStatusDataService.skuTags({
        teamId: this.props.globalState.teamJobData.teamId,
      }).set(data.skus);
    }
    this.closeModal();
  };

  detailBarViews = () => {
    const { skuRecord, sku } = this.state;
    const { detailBarView } = this.state.view;

    if (detailBarView) {
      const detailBarViews = {
        skuKeywords: skuRecord ? (
          <div>
            <SKUKeywords
              skus={{ [skuRecord.skuId]: SKUWithKeywordsToSkuKeywords(sku) }}
              teamId={this.props.globalState.teamJobData.teamId}
              onSKUsKeywordsUpdated={(skuWithKeywords: SkuKeywords[]) => {
                const updatedSku = skuWithKeywords[0];

                this.setState({
                  sku: { ...updatedSku, skuRecord },
                });
              }}
              checked={{ [skuRecord.skuId]: true }}
            />
          </div>
        ) : <div />,
        skusTaggingView: skuRecord ? (
          <div>
            <SKUTaggingView
              skus={this.state.skusTags}
              customTags={this.state.customTags}
              globalState={this.props.globalState}
              setTags={this.setSKUsTags}
              checked={{ [skuRecord.skuId]: true }}
            />
          </div>
        ) : <div />,
      };

      const onClick = this.genToggleModal(detailBarView);

      const animationProps = {
        reveal: true,
        duration: 290,
        style: {
          backgroundColor: 'white', //"rgba(255,255,255,.6)",
          //position: "absolute",
          top: -16,
          left: -8,
          right: -8,
          // width: "100%",
          zIndex: 504,
          border: '1px solid darkgray',
          padding: 20,
        } as React.CSSProperties,
        children: (
          <React.Fragment>
            {detailBarViews[detailBarView]
              ? React.cloneElement(detailBarViews[detailBarView], {
                  key: detailBarView,
                })
              : null}
            <div
              style={{
                position: 'fixed',
                top: 5,
                right: 12,
                width: 30,
                height: 30,
              }}>
              <CSUIButtonCloseX
                onClick={onClick}
                style={{ width: 30, padding: '7.7px 0' }} />
            </div>
          </React.Fragment>
        ),
      };

      const slideDown = false;

      const eleNoAnimate = (
        <div
          className={`detailBarViewHolder ${detailBarView}`}
          style={{
            ...animationProps.style,
            transform: 'translateX(0)',
            boxShadow: 'rgba(136, 136, 136, 0.56) 0px 10px 18px',
          }}
          children={animationProps.children}
        />
      );

      const output = (
        <React.Fragment>
          {eleNoAnimate}
          {slideDown ? (
            <div
              className='exitArea'
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                bottom: 0,
                left: 0,
              }}
              onClick={onClick}
            />
          ) : null}
        </React.Fragment>
      );

      return output; // ReactDOM.createPortal(ele, portal);
    } else {
      return null;
    }
  };

  setWorkflowStage = async (workflowStages: WorkFlowStage[]) => {
    const workflow_stage = workflowStages[0];

    if (workflow_stage) {
      const { skuRecord: { teamId, skuId } } = this.state;
      await ContentStatusDataService.skuWorkflowStages({
        teamId,
      }).set({
        [skuId]: {
          skuId,
          workflow_stage,
        },
      });
      this.loadSkuRecord();
    }
  }

  render() {
    const closeWordButton = null;
    const hideBeneathStyle = this.state.view.detailBarView
      ? { style: { display: 'none' } as React.CSSProperties }
      : {};

    return (
      <div className='catalog-product-container'>
        {
          <CSUISegment
            className='card-body white-container'
            {...hideBeneathStyle}>
            <CSUIButtonCloseX topRight onClick={this.closePage} />
            <Grid>
              <Grid item xs={3}>
                <h1 className='dual margin-left'>
                  Product<span>View</span>
                  {closeWordButton}
                </h1>
              </Grid>
              <Grid xs={9} className='text-right'></Grid>
            </Grid>
            <nav className='updated-toolbar round' id='_page-toolbar'>
              <h1>Views</h1>
              <NavButton
                icon='product search'
                active={this.state.section === 'audit'}
                onClick={this.showSection('audit')}
                text='Audit'
              />
              <NavButton
                icon='product pencil'
                active={this.state.section === 'improve'}
                onClick={this.showSection('improve')}
                text='Improve'
              />

              <NavButton
                icon='product search'
                active={this.state.section === 'monitor'}
                onClick={this.showSection('monitor')}
                text='Monitor'
              />
              <h1>Edit</h1>
              <NavButton
                icon='product tag'
                active={this.state.selectedSections.includes('tags')}
                onClick={this.genToggleModal('skusTaggingView')}
                text='Tags'
              />
              <NavButton
                onClick={this.genToggleModal('skuKeywords')}
                icon='product keywords'
                active={this.state.selectedSections.includes('keywords')}
                text='Keywords'
                title='Coming Soon'
              />
            </nav>

            {this.renderProductData()}
          </CSUISegment>
        }
        {this.detailBarViews()}
      </div>
    );
  }
}

export default GlobalStateProvider(withRouter(CatalogProduct));
