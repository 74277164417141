import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Header from './Header';
import styles from './styles';
import TabPanel from './Content/TabPanel';
import { PlanSize, FormulaState, IPlan } from './types';
import PlanSizes from './Content/PlanSizes';
import RetailersFormula from './Content/RetailersFormula';
import { PLAN_SIZES } from './plans';
import { GlobalState } from '../../libraries/types/ReportUp';
import './style.scss';
import { getCoreFromUserAccount } from '../../libraries/types/datastructures';
import GlobalStateProvider from '../../components/GlobalStateProvider';
import { SinglePlan } from '../../libraries/types/chargebeeStructure';
import { ChargebeeAppClass, ChargebeeService } from '../../libraries/ScriptServices/Chargebee';
import { CSUIButton, CSUISegment } from '../../components/CSUI';
import BillingPageLayout from '../../layouts/BillingPageLayout';
import Spinner from '../../components/Spinner';
import Divider from '@material-ui/core/Divider/Divider';

interface BillingSettingsProps {
  globalState?: GlobalState;
  publicVersion?: boolean;
  embedded?: boolean;
  //directCheckout?: boolean;
}

interface State {
  activePlan: string | null;
  planSizes: PlanSize[];
  currentTab: string;
  retailersFormula: FormulaState;
  plans: IPlan[];
  chargebeePlans: {[planId: string]: SinglePlan};
  isSamsUser?: boolean;
  dataLoading: boolean;
}

const planOptions = {
  flex: {
    subTitle: 'Provides a one-time snapshot.',
    period: '1-Time Only',
    activeColor: '#787878',
    gradient: 'linear-gradient(90deg,#808080, #A9A9A9, #B0B0B0, #B8B8B8)',
  },
  smart: {
    subTitle: 'Starting point for your auditing stratgey.',
    period: 'Monthly',
    periodChildren: "1st Monday of the Month",
    activeColor: '#6C0DC4',
    gradient: 'linear-gradient(to bottom, #8A2BE2 0%, #7B1CD3 100%)',
  },
  brilliant: {
    subTitle: 'Easy, consistent, <br /> auditing.',
    period: 'Weekly',
    periodChildren: "Every Monday",
    activeColor: '#3333e8',
    gradient: 'linear-gradient(90deg, #4d4dff, #4f4ff7)',
  },
  genius: {
    subTitle: 'Perfect for watching pricing & availability.',
    period: 'Daily',
    periodChildren: "Monday - Friday",
    activeColor: '#ff7400',
    gradient: 'linear-gradient(90deg, #ffc100, #ff9a00)',
  }
}

const tabConverter = (currentTab: string) => {
  return currentTab === 'Monthly' ? 'quarterly' : 'annual'
}

const planNameConverter = (planName: string) => {
  return ['Flex', 'Smart', 'Brilliant', 'Genius'].find(name => planName.includes(name.toLocaleLowerCase())) || planName;
}

const Billing: React.FunctionComponent<BillingSettingsProps> = (props) => {
  const classes = styles();

  let chargebeeApp: ChargebeeAppClass;
  //let plans = [];

  const getChargebeeApp = async () => {
    if (!chargebeeApp) {
      let teamId = '';
      let chargebeeCustomerId = '';

      if (!props.publicVersion) {
        const coreUser = getCoreFromUserAccount(props.globalState.activeUser);
        teamId = coreUser.teamId;
        chargebeeCustomerId = coreUser.chargebeeCustomerId || '';
      }
      chargebeeApp = ChargebeeService.get({ teamId, chargebeeCustomerId });
      
    }
    return chargebeeApp;
  };

  const [state, setState] = useState<State>({
    activePlan: null,
    planSizes: [...PLAN_SIZES],
    currentTab: 'Monthly',
    retailersFormula: {
      retailers: '0',
      skusPerRetailer: '0',
      total: '0',
    },
    plans: [],
    chargebeePlans: {},
    dataLoading: true
  });

  useEffect(() => {
    //console.log('mounted')
    const fn = async () => {
      const chargebeeApp = await getChargebeeApp();
      const chargebeePlans = {};
      const plans: IPlan[] = (await chargebeeApp.getPlans())
      .map(plan => {
        chargebeePlans[plan.plan.id] = plan;
        return plan.plan
      })
      .filter(plan => plan['cf_plan_enabled'] === 'True')
      .map(plan => ({
        planKey: plan.id,
        planName: plan.id,
        name: planNameConverter(plan.id),
        subTitle: '',
        period: '',
        plans: (plan.tiers || [])
        .map(tier => {
          const newTier = {...tier};
          const numAudits = newTier.starting_unit === 1 ? 50 : newTier.starting_unit;
          newTier['numAudits'] = numAudits;
          const key = newTier['numAudits']+"";
          newTier['key'] = key;
          if(plan.id.includes("flex")) {
            newTier['feePer'] = newTier.price;
            newTier.price = newTier.price*numAudits;
          } else {
            if (plan.meta_data?.overage) {
              newTier['overageFee'] = plan.meta_data?.overage[key];
            }
          }
          return newTier
        })
        .sort((a, b) => b.price - a.price),
        ...(planOptions[Object.keys(planOptions).find(key => plan.id.startsWith(key)) || 0] || {})
      }));
      console.log(plans);
      const planSizes: PlanSize[] = plans
      .filter(plan => plan.planKey.includes("flex"))[0]?.plans
      .map(planTier => ({
        key: planTier.numAudits+"" as any,
        price: planTier.numAudits,
        description: 'Unique Product Pages to be Audited',
        isActive: false,
      }));
      const userObj = getCoreFromUserAccount(props.globalState.activeUser);
      //props.globalState.activeUser
      const isSamsUser = userObj['hdyhas'] === "partner - sam's club";
      setState({ ...state, plans, planSizes, chargebeePlans, activePlan: "1500", dataLoading: false, isSamsUser })
    }

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openChargeBeePortal = async () => {
    (await getChargebeeApp()).openCustomerPortal();
  };

  const directCheckout = async (plan_id: string, quantity?: number) => {
    const {chargebeePlans} = state;

    const {attached_addons} = chargebeePlans[plan_id].plan as any;
    const addonIds = (attached_addons || []).map(addon => addon.id) as string[];

    //if (teamBilling && teamBilling.authorizedIds.includes(coreUser.userId)) {
    (await getChargebeeApp()).openCheckout(plan_id, quantity, addonIds);
    /* } else {
        alert("Not authorized!");
    } */
  }

  const onSelectPlan = (plan) => {
    directCheckout(plan, parseInt(state.activePlan, 10));
  };

  const onSelectPlanSizes = (activePlan: string) => {
    setState({
      ...state,
      activePlan,
      planSizes: state.planSizes.map((plan) => ({
        ...plan,
        isActive: plan.key === activePlan,
      })),
    });
  };

  const handleChange = (currentTab) => setState({ ...state, currentTab });

  const filteredPlans = state.plans.filter(plan => plan.planKey.indexOf(tabConverter(state.currentTab)) > -1);
  const sortedPlans = ['smart', 'brilliant', 'genius'].map(x => filteredPlans.find(v => v.planKey.includes(x)));

  const TABS = [
    {
      title: 'Monthly',
      Component: (
        <PlanSizes
          activePlan={state.activePlan}
          planSizes={state.planSizes}
          onSelectPlan={onSelectPlan}
          onSelectPlanSize={onSelectPlanSizes}
          flexPlan={state.plans.filter(plan => plan.planKey.startsWith("flex"))[0]}
          plans={sortedPlans}
          planType={tabConverter(state.currentTab)}
          publicVersion={props.publicVersion}
          onTermChange={handleChange}
          termSize={state.currentTab}
        />
      ),
    },
    {
      title: 'Annual',
      Component: <PlanSizes
        activePlan={state.activePlan}
        planSizes={state.planSizes}
        onSelectPlan={onSelectPlan}
        onSelectPlanSize={onSelectPlanSizes}
        flexPlan={state.plans.filter(plan => plan.planKey.startsWith("flex"))[0]}
        plans={sortedPlans}
        planType={tabConverter(state.currentTab)}
        publicVersion={props.publicVersion}
        onTermChange={handleChange}
        termSize={state.currentTab}
      />,
    },
  ];
  //const tabsTitles = TABS.map((tab) => tab.title);

  const onChangeFormula = (retailersFormula: FormulaState) => {
    const total = retailersFormula.total ? retailersFormula.total : 600
    if (retailersFormula.total !== "--") {
      let selectedPlan = false;
      let activePlan;

      const plans = state.planSizes
        .sort((a, b) => +a.price - +b.price)
        .map((plan) => {
          if (plan.price >= +total && !selectedPlan) {
            plan.recommended = true;
            plan.isActive = true;
            selectedPlan = true;
            activePlan = plan.key;
          } else {
            plan.recommended = false;
            plan.isActive = false;
          }
          return plan;
        });
      setState({
        ...state,
        retailersFormula,
        planSizes: plans.reverse(),
        activePlan: activePlan === undefined ? "50000" : activePlan,
      });
    } else {
      setState({ ...state, retailersFormula });
    }
  };

  const renderNewLayout = (children: any) => {
    //const { globalState } = props;
    const { dataLoading } = state;

    const buttons = [
      <CSUIButton
        onClick={openChargeBeePortal}
        color='green'>
        Billing Portal
      </CSUIButton>,
    ];
    return (
      <BillingPageLayout
        title='Billing'
        details={
          <React.Fragment>
            Manage your subscription and purchase audits.
          </React.Fragment>
        }
        buttons={buttons}>
        <CSUISegment>
          {dataLoading ? (
            <Spinner />
          ) : (
            children
          )}
        </CSUISegment>
      </BillingPageLayout>
    );
  };

  const SamsPlan = () => {
    return state.isSamsUser ? <Grid container item xs={12} direction="row" className={"SamsDiv"}>
      <div>Click for the discounted <span>Sam's Club $99</span> Supplier Plan</div>
      <div><CSUIButton label="Select Plan" slim color="green" onClick={() => directCheckout("samsclub_brilliant_plan_weekly")}/></div>
    </Grid> : undefined
  }

  const mainContent = (
    <div className="mainContentDiv">
      {/* <div className="buttonHolder">
        <CSUIButton
          onClick={() => openChargeBeePortal()}
          color='green'>
          Billing Portal
        </CSUIButton>
      </div> */}
      <Grid container className={classes.container}>
        {/* <CSUISegment> */}
        {SamsPlan()}
        <Grid container item xs={12} direction="row" className={classes.header}>
          <Header />
          <RetailersFormula
            state={state.retailersFormula}
            onChangeFormula={onChangeFormula}
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <div className={classes.root}>
            {/* <div style={{padding: '0 50px'}}>
              <div>
                <PlanTab
                  tabs={tabsTitles}
                  onSelect={handleChange}
                  active={state.currentTab}
                />
                <h5 className={classes.subTitle}>Save 20% with annual pre-pay</h5>
              </div>
            </div> */}
            {state.plans.length ? TABS.map(({ title, Component }) => (
              <TabPanel
                key={title}
                index={title}
                currentValue={state.currentTab}>
                {Component}
              </TabPanel>
            )) : "Data loading error..."}
          </div>
        </Grid>
      </Grid>
    </div>
  )

  return props.publicVersion || props.embedded ? mainContent : renderNewLayout(mainContent);
};

export default GlobalStateProvider(Billing);
