import { chunk, cloneDeep, fromPairs, toPairs } from "lodash";

import {
    CatalogData,
    SkuRecord,
    SkuRecordCatalogData,
    SkuRecordFlatten,
} from '../../pages/Catalog/catalog-datamodel';
import {
    SKUsList, Tag,
} from '../../pages/Catalog/SKUTaggingView/datastructures';
import {
    ColumnObj,
    CustomViewData,
    Filters,
    KeywordCollection,
} from '../../pages/Catalog/datastructures';
import { WorkflowFolder } from "../../pages/Workflow/types";
import * as DEFAULT_ApiClient from "../Util/apiClient";
import { firestoreDb } from "../Util";
import { Objectentries, ObjectKeyValuePairs, RandomId } from "../types/datastructures";
import _ from 'lodash';
import {
    toRecordId
} from '../../pages/Catalog/catalog-dataloader';
import { WorkFlowStage } from '../../pages/CatalogProduct/datastructures';
import { PlatformCatalogJob } from "../../components/NewJob/data";
import {
    AuditRule,
    EditRulePayload,
    RuleId,
    RuleBasicPayload,
    RuleStatusPayload,
    DefaultRuleBasicPayload,
    TestRulePayload,
    RuleData,
} from '../../components/AuditRules/datastructures';
import { ScoreCardDefaultMappings, ScoreCardSettings } from '../../pages/ScoreCards/types';
import { Notification } from "../../components/Notifications/datastructures";
import { API_CALL_CreateTag, API_CALL_DeleteTag } from '../Util/apiClient';
import { OperationConfig } from "../../components/SiteAdminSection/operations";
import { localStorageService } from "../../pages/Catalog/data";

type ApiClient = typeof DEFAULT_ApiClient;

export interface WorkflowFolderList {
    [skuId: string]: {
        [workflowId: string]: WorkflowFolder
    }
};

export interface WorkFlowStagesList {
    [skuId: string]: {
        skuId: string,
        workflow_stage: WorkFlowStage
    }
};

export interface SkuEditsList {
    [skuId: string]: {
        productName?: string,
        shortDescription?: string
    }
};

export interface TeamBalance {
    teamId: string;
    balance: number;
    asofTs: number;
}

export interface CreditEvent {
    version: 'v1';
    type: 'chargebee' | 'job' | 'platform';
    id: string;
    timestamp: number;
    creditShift: number;
    rejected?: boolean;
    skipCredit?: boolean;

    chargebee?: {
      [x:string]: any
    };
    job?: {
      snapshotId: string;
      jobId: string;
    };
    platform?: {
      type: 'credit_expire' | 'credit_add' | 'credit_earn';
      expireId?: string;
    };
  }

export interface TeamData {
    catalogData?: { [skuId: string]: SkuRecord };
    reportData?: {keyMap: {[key: string]: string}, data: SkuRecordCatalogData},
    skuTags?: SKUsList;
    keywords?: KeywordCollection;
    workflowFolders?: WorkflowFolderList;
    stageSKUs?: WorkFlowStagesList;
    skuEdits?: SkuRecord;
    teamBalance?: TeamBalance;
    creditEvents?: Array<CreditEvent>;
    jobsData?: {[jobId: string]: PlatformCatalogJob};
    catalogLastUpdate?: number;
    catalogDataNew?: CatalogData;
    rules: RuleData[];
    tags: Tag[];
    workflowFolderList: {[id: string]: WorkflowFolder};
    scoreCards: {scoreCardId: ScoreCardSettings};
    scoreCardDefaults: ScoreCardDefaultMappings;
    notifications: {
        lastAck: number;
        list: { [id: string]: Notification}
    };
    operations: { [opId: string]: OperationConfig; };
}

const genEmptyTeamData = () => ({
    catalogData: undefined,
    reportData: undefined,
    skuTags: undefined,
    keywords: undefined,
    workflowFolders: undefined,
    stageSKUs: undefined,
    skuEdits: undefined,
    catalogLastUpdate: Date.now(),
    rules: undefined,
    workflowFolderList: null,
    scoreCards: null,
    notifications: {
        list: undefined,
        lastAck: undefined
    }
}) as TeamData;

class ContentStatusDataServiceClass {

    private loadedTeamIds: string[];
    private activeTeamId: string;
    private teamData: {
        [teamId: string]: TeamData
    }
    private filters: Filters;
    private apiCallUpdateEdits: Function;
    private apiCallSetSkuWorkFlowStages: Function;
    private apiCallUpdateDefaultScoreCard: Function;
    private apiCallSetDefaultScoreCard: Function;

    private CSApiClient: ApiClient;

    private dataSubscribers: {
        [teamId: string]: {
            [dataType: string]: { [subId: string]: Function }
        }
    }

    constructor () {
        this.teamData = {};
        this.loadedTeamIds = [];
        this.dataSubscribers = {};
        this.CSApiClient = DEFAULT_ApiClient;
        this.apiCallUpdateEdits = _.debounce(this.CSApiClient["API_CALL_SetSkuEdits"], 3000)
        this.apiCallSetSkuWorkFlowStages = _.debounce(this.CSApiClient["API_CALL_skuStagePut"], 3000)
        this.apiCallUpdateDefaultScoreCard = _.debounce(this.CSApiClient["API_CALL_EditDefaultScoreCard"], 5000);
        this.apiCallSetDefaultScoreCard = _.debounce(this.CSApiClient["API_CALL_ScoreCardSetDefault"], 1000);
    }

    initTeam = (teamId: string, setAsActiveTeamId: boolean = true) => {
        if (!!teamId && typeof teamId === 'string') {
            this.teamData[teamId] = genEmptyTeamData();
            this.loadedTeamIds.push(teamId);
            this.dataSubscribers[teamId] = {};

            if (setAsActiveTeamId || !!!this.activeTeamId) {
                this.activeTeamId = teamId;
            }
        } else {
            throw new Error(`teamId parameter value is invalid: ${teamId}`);
        }
    }

    updateSubscribers = (teamId: string, dataType: string, data: any) => {
        Object.entries(this.dataSubscribers[teamId][dataType]).forEach(subCallback => {
            subCallback[1](data);
        });
    }

    setApiClient = (apiClient: ApiClient) => {
        this.CSApiClient = apiClient;
    }

    getTeamIdToUse = (teamId: string) => {
        if (!!!teamId) {
            if (!!this.activeTeamId) {
                return this.activeTeamId;
            } else {
                throw new Error(`No teamId has been initialized. ContentStatusDataService.initTeam(<teamId>) must be called at least once.`);
            }
        } else if (typeof teamId !== 'string') {
            throw new Error(`teamId parameter value is invalid: ${teamId}`);
        } else if (this.loadedTeamIds.indexOf(teamId) === -1) {
            this.initTeam(teamId, false);
            //throw new Error(`teamId has not been initialized. ContentStatusDataService.initTeam(<teamId>) must be called first: ${teamId}`);
        }

        return teamId;
    }

    private notificationsLoad = async (options: {teamId: string}) => {
        //const notificationsRes = await this.CSApiClient["API_CALL_LoadSkuTags"](options.teamId);
        this.teamData[options.teamId].notifications = {
            lastAck: 0,
            list: {}
        };
    }

    private notificationsGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].notifications.list === undefined) {
            await this.notificationsLoad(options);
        }

        return this.teamData[options.teamId].notifications;
    }

    private notificationsPush = async (options: {teamId: string, notification: Notification}) => {
        this.teamData[options.teamId].notifications.list = {...this.teamData[options.teamId].notifications.list, [options.notification.id]: options.notification};
        this.updateSubscribers(options.teamId, 'notifications', this.teamData[options.teamId].notifications);
        //await this.CSApiClient["API_CALL_SetSkuTags"](options.teamId, options.notifications);
    }

    private notificationsAck = async (options: {teamId: string}) => {
        this.teamData[options.teamId].notifications.lastAck = Date.now();
        // call API with Date.now();
        this.updateSubscribers(options.teamId, 'notifications', this.teamData[options.teamId].notifications);
    }

    private notificationsRegisterSubscriber = async (options: {teamId: string, callback: (notifications: TeamData['notifications']) => void}) => {
        const subId = RandomId();
        if (!this.dataSubscribers[options.teamId]['notifications']) {
            this.dataSubscribers[options.teamId]['notifications'] = {};
        }
        this.dataSubscribers[options.teamId]['notifications'][subId] = options.callback;
        options.callback(await this.notificationsGet(options));
        return () => { delete this.dataSubscribers[options.teamId]['notifications'][subId] }
    }

    notifications = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.notificationsGet({teamId, ...options}),
            refresh: async () => await this.notificationsLoad({teamId}),
            push: async (notification: Notification) => await this.notificationsPush({teamId, notification}),
            reportAck: async () => await this.notificationsAck({teamId}),
            subscribe: async (callback: (notifications: TeamData['notifications']) => void) => this.notificationsRegisterSubscriber({teamId, callback})
        }
    }

    private reportDataLoad = async (options: {teamId: string, fieldPaths: Array<string>}) => {
        const reportData = await this.CSApiClient["API_CALL_ReportDataLoad"](options.teamId, options.fieldPaths);
        this.teamData[options.teamId].reportData = reportData.data;

        return this.teamData[options.teamId].reportData;
    }

    private catalogDataLoad = async (options: {teamId: string}) => {
        const catalogData = await this.CSApiClient["API_CALL_CatalogDataLoad"](options.teamId);
        this.teamData[options.teamId].catalogData = catalogData.data.data;
        this.teamData[options.teamId].catalogLastUpdate = Date.now();

        await this.catalogDataLoadNew(options);
    }

    private catalogDataGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].catalogData === undefined) {
            await this.catalogDataLoad(options);
        }

        return this.teamData[options.teamId].catalogData;
    }

    private catalogDataLoadNew = async (options: {teamId: string, filters?: Filters, offset?: number, search?: string, elastic?: boolean}) => {
        const catalogData = await this.CSApiClient[
            options.elastic
            ? "API_CALL_CatalogDataLoadElastic"
            : "API_CALL_CatalogDataLoadChunked"//"API_CALL_CatalogDataLoadNew"
        ](options as any);
        if (catalogData?.data?.data) {
            this.teamData[options.teamId].catalogDataNew = catalogData.data.data;
            this.teamData[options.teamId].catalogLastUpdate = Date.now();
        }

        return this.teamData[options.teamId].catalogDataNew;
    }

    private catalogDataLoadChunks = async (options: {
        teamId: string,
        filters?: Filters,
        partialReturn?: (data: SkuRecordCatalogData) => void,
        forceRefresh?: boolean
    }) => {
        if (options.forceRefresh || this.teamData[options.teamId].catalogDataNew === undefined) {
            await this.CSApiClient["API_CALL_CatalogDataLoadNewChunks"](
                options.teamId,
                options.filters,
                //options.partialReturn
                (chunk) => {
                    if (this.teamData[options.teamId].catalogDataNew === undefined) {
                        this.teamData[options.teamId].catalogDataNew = {
                            skus: {},
                            headersData: {},
                            amount: 0,
                        }
                    }

                    if (chunk) {
                        //console.log("chunk", chunk)
                        //const parsed = JSON.parse(chunk);
                        Object.assign(this.teamData[options.teamId].catalogDataNew, ...chunk.filter(x=>x).map(s => JSON.parse(s)));

                    }
                    if (options.partialReturn) {
                        options.partialReturn(this.teamData[options.teamId].catalogDataNew.skus);
                    }
                }
            );
            /* this.teamData[options.teamId].catalogDataNew = catalogData.data; */
            this.teamData[options.teamId].catalogLastUpdate = Date.now();
        } else {
            if (options.partialReturn) {
                options.partialReturn(this.teamData[options.teamId].catalogDataNew.skus);
            }
        }

        return this.teamData[options.teamId].catalogDataNew;
    }

    private catalogDataSet = async (options: {teamId: string, flattenSkuRecord: SkuRecordFlatten, skuRecord: SkuRecord}) => {
        const {teamId, flattenSkuRecord, skuRecord} = options
        if (!this.teamData[teamId].catalogDataNew) {
            this.teamData[teamId].catalogDataNew = {
                skus: {},
                headersData: {},
                amount: 0,
            };
        }
        this.teamData[teamId].catalogDataNew[flattenSkuRecord.skuId] = flattenSkuRecord;
        if (!this.teamData[teamId].catalogData) {
            this.teamData[teamId].catalogData = {};
        }
        this.teamData[teamId].catalogData[skuRecord.skuId] = skuRecord;
    }

    private catalogDataGetNew = async (options: {teamId: string, filters?: Filters, forceRefresh?: boolean, offset?: number, search?: string, elastic?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].catalogDataNew === undefined) {
            await this.catalogDataLoadNew(options);
        }

        return this.teamData[options.teamId].catalogDataNew;
    }

    private catalogDataDeleteSkus = async (options: {teamId: string, skus: { skuId: string; retailer: string }[]}) => {
        const promise = this.CSApiClient["API_CALL_DeleteSku"](options.teamId, options.skus);
        for (const skuId in options.skus) {
            delete (this.teamData[options.teamId].catalogDataNew || {})[skuId];
            delete (this.teamData[options.teamId].catalogData || {})[skuId];
        }
        //this.teamData[options.teamId].catalogDataNew = options.catalogData;
        await promise;
    }

    private catalogDataDeleteSkusSnapshots = async (options: {teamId: string, snapshots: {skuId: string}[]}) => {
        const promise = await this.CSApiClient["API_CALL_deleteSnapshotService"](options.snapshots);
        for (const skuId in options.snapshots) {
            delete (this.teamData[options.teamId].catalogDataNew || {})[skuId];
            delete (this.teamData[options.teamId].catalogData || {})[skuId];
        }
        //this.teamData[options.teamId].catalogDataNew = options.catalogData;
        await promise;
    }

    private catalogDataGetSkuByRecordId = async (options: {teamId: string, recordId: string}) => {
        if (!this.teamData[options.teamId].catalogData) {
            const skuData = await this.CSApiClient["API_CALL_GetSkuRecordByRecordId"](options.recordId, options.teamId);

            if (skuData) {
                return skuData.data.data as SkuRecord;
            }

            await this.catalogDataLoad({teamId: options.teamId})
        }

        const catalogDataValues = Object.values(this.teamData[options.teamId].catalogData);

        for (const value of catalogDataValues) {
            const recordId = toRecordId({skuId: value.skuId, timestamp: value.timestamp, retailer: value.retailer});

            if (recordId === options.recordId) {
                return value;
            }
        }
        
        const skuData = await this.CSApiClient["API_CALL_GetSkuRecordByRecordId"](options.recordId, options.teamId);

        if (skuData) {
            return skuData.data.data as SkuRecord;
        }

        return catalogDataValues[0];
    }

    private catalogDataGetValues = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].catalogData === undefined) {
            await this.catalogDataLoad(options);
        }

        return Object.values(this.teamData[options.teamId].catalogData);
    }

    private catalogDataGetSkuBySnapshotId = async (options: {teamId: string, skuId:string, retailer: string, snapshotId: string}) => {
        const skuData = await this.CSApiClient["API_CALL_GetSkuRecordBySnapshotId"](options.teamId, options.skuId, options.snapshotId, options.retailer);
        return skuData.data.data;
    }

    catalogData = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            getNew: async (options?: {filters?: Filters, forceRefresh?: boolean, offset?: number, search?: string }) => await this.catalogDataGetNew({teamId, ...options}),
            getElastic: async (options?: {filters?: Filters, forceRefresh?: boolean, offset?: number, search?: string }) => await this.catalogDataGetNew({teamId, ...options, elastic: true}),
            refreshCatalogData: async (options?: {filters?: Filters, offset?: number}) => await this.catalogDataLoadNew({teamId, ...options}),
            getChunks: async (options?: {filters?: Filters, partialReturn?: (data: SkuRecordCatalogData) => void, forceRefresh?: boolean}) => await this.catalogDataLoadChunks({teamId, ...options}),
            get: async (options?: {forceRefresh?: boolean}) => await this.catalogDataGet({teamId, ...options}),
            set: (flattenSkuRecord: SkuRecordFlatten, skuRecord: SkuRecord) => this.catalogDataSet({teamId, flattenSkuRecord, skuRecord}),
            refresh: async () => await this.catalogDataLoad({teamId}),
            getSkuByRecordId: async (recordId: string) => await this.catalogDataGetSkuByRecordId({teamId, recordId}),
            getSkuBySnapshotId: async (skuId:string, retailer: string, snapshotId: string) => await this.catalogDataGetSkuBySnapshotId({teamId, skuId, retailer, snapshotId}),
            getValues: async (options?: {forceRefresh?: boolean}) => await this.catalogDataGetValues({teamId, ...options}),
            deleteSkus: async (options?: {skus: { skuId: string; retailer: string }[]}) => await this.catalogDataDeleteSkus({teamId, ...options}),
            deleteSkusSnapshots: async (options?: { snapshots: {skuId: string}[],}) => await this.catalogDataDeleteSkusSnapshots({teamId,...options}),
        }
    }

    reportData = (options: {teamId: string, fieldPaths: Array<string>}) => {
        const teamId = this.getTeamIdToUse(options.teamId);
        return {
            get: async () => await this.reportDataLoad({teamId, fieldPaths: options.fieldPaths}),
        }
    }

    getFilters = () => {
        return this.filters;
    }

    setFilters = (options: {filters: Filters}) => {
        this.filters = options.filters;
    }

    filtersData = () => {
        return {
            get: () => this.getFilters(),
            set: (filters: Filters) => this.setFilters({filters}),
        }
    }

    private tagListLoad = async  (options: {teamId: string}) => {
        const skuTags = await this.CSApiClient["API_CALL_LoadSkuTagList"](options.teamId);
        this.teamData[options.teamId].tags = skuTags.data || [];
    }

    private tagListGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].tags === undefined) {
            await this.tagListLoad(options);
        }

        return this.teamData[options.teamId].tags;
    }

    private deleteTag = async (options: {teamId: string, tagId: string}) => {
        await API_CALL_DeleteTag(options.tagId, options.teamId);
        const viewData = localStorageService.getUserSettings('viewData') as CustomViewData;
        if (viewData) {
            viewData.tags['section']['custom_tags'].selection = viewData.tags['section']['custom_tags'].selection.filter(
                (tag: string) => tag !== options.tagId
            );
            localStorageService.setUserSettings('viewData', viewData);
        }

        let categories = localStorageService.getUserSettings('categories') as ColumnObj[];
        if (categories) {
            categories = categories.filter(
                (category: ColumnObj) => category.id !== options.tagId
            );
            localStorageService.setUserSettings('categories', categories);
        }
        if (this.teamData[options.teamId].tags) {
            this.teamData[options.teamId].tags = this.teamData[options.teamId].tags.filter(
                (tag: Tag) => tag.id !== options.tagId
            );
        }
    }

    private createTag = async (options: {teamId: string, tag: Tag}) => {
        await API_CALL_CreateTag(options.tag, options.teamId);
        const newTags = this.teamData[options.teamId].tags || []
        newTags.push(options.tag);
        this.teamData[options.teamId].tags = newTags;
    }

    private skuTagsLoad = async (options: {teamId: string}) => {
        const skuTags = await this.CSApiClient["API_CALL_LoadSkuTags"](options.teamId);
        this.teamData[options.teamId].skuTags = skuTags.data.data;
    }

    private skuTagsGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].skuTags === undefined) {
            await this.skuTagsLoad(options);
        }
        const data = cloneDeep(this.teamData[options.teamId].skuTags);
        return data;
    }

    private skuTagsSet = async (options: {teamId: string, newTags: SKUsList}) => {
        this.teamData[options.teamId].skuTags  = {...this.teamData[options.teamId].skuTags, ...options.newTags};
        const promises = chunk(toPairs(options.newTags), 3000)
        .map(chunkedTagsArray => {
            const chunkedTags = fromPairs(chunkedTagsArray);
            return this.CSApiClient["API_CALL_SetSkuTags"](options.teamId, chunkedTags);
        });
        await Promise.all(promises);
        //this.CSApiClient["API_CALL_SetSkuTags"](options.teamId, options.newTags);
    }

    skuTags = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            getList: async () => await this.tagListGet({teamId}),
            get: async (options?: {forceRefresh?: boolean}) => await this.skuTagsGet({teamId, ...options}),
            refresh: async () => await this.skuTagsLoad({teamId}),
            set: async (newTags: SKUsList) => await this.skuTagsSet({teamId, newTags}),
            deleteTag: async (tagId: string) => await this.deleteTag({teamId, tagId}),
            createTag: async (tag: Tag) => await this.createTag({teamId, tag}),
        }
    }

    private workflowFolderListLoad = async  (options: {teamId: string}) => {
        const workflowFolders = await this.CSApiClient["API_CALL_LoadWorkflowFolderList"](options.teamId);
        this.teamData[options.teamId].workflowFolderList = workflowFolders.data.data;
    }

    private workflowFolderList = async (options: {teamId: string}) => {
        if (!this.teamData[options.teamId].workflowFolderList) {
            await this.workflowFolderListLoad(options);
        }

        return this.teamData[options.teamId].workflowFolderList;
    }

    private workflowFolderSet = async  (options: {teamId: string, workflows: WorkflowFolder[], deletedFolders: WorkflowFolder[]}) => {
        await this.CSApiClient["API_CALL_SetWorkflowFolder"](options.teamId, options.workflows, options.deletedFolders);
        const workflowFolderList = {};
        options.workflows.map(workflow => workflowFolderList[workflow.id] = workflow)
        this.teamData[options.teamId].workflowFolderList = workflowFolderList;
    }

    workflowFolder = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            refresh: async () => await this.workflowFolderListLoad({teamId}),
            getList: async () => await this.workflowFolderList({teamId}),
            set: async (workflows: WorkflowFolder[], deletedFolders: WorkflowFolder[]) => await this.workflowFolderSet({teamId, workflows, deletedFolders})
        }
    }

    private skuKeywordsLoad = async (options: {teamId: string}) => {
        const skuKeywords = await this.CSApiClient["API_CALL_LoadSkuKeywords"](options.teamId);
        this.teamData[options.teamId].keywords = skuKeywords.data.data;
    }

    private skuKeywordsGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].keywords === undefined) {
            await this.skuKeywordsLoad(options);
        }

        return this.teamData[options.teamId].keywords;
    }

    private skuKeywordsGetById = async (options: {skuId: string, teamId: string, forceRefresh?: boolean}) => {
        if (!this.teamData[options.teamId].keywords) {
            await this.skuKeywordsLoad(options);
        }

        return this.teamData[options.teamId].keywords[options.skuId]
    }

    private skuKeywordsSet = async (options: {teamId: string, newKeywords: KeywordCollection}) => {
        this.teamData[options.teamId].keywords = {...this.teamData[options.teamId].keywords, ...options.newKeywords };
        await this.CSApiClient["API_CALL_SetSkuKeywords"](options.teamId, options.newKeywords);
    }

    skuKeywords = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.skuKeywordsGet({teamId, ...options}),
            getBySkuId: async (skuId: string) => await this.skuKeywordsGetById({skuId, teamId, ...options}),
            set: async (newKeywords: KeywordCollection) => await this.skuKeywordsSet({teamId, newKeywords}),
            refresh: async () => await this.skuKeywordsLoad({teamId}),
        }
    }

    private skuWorkflowFoldersLoad = async (options: {teamId: string}) => {
        const skuWorkFlowFolders = await this.CSApiClient["API_CALL_LoadSkuWorkFlowFolders"](options.teamId);
        this.teamData[options.teamId].workflowFolders = skuWorkFlowFolders.data.data;
    }

    private skuWorkflowFoldersGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].workflowFolders === undefined) {
            await this.skuWorkflowFoldersLoad(options);
        }

        return this.teamData[options.teamId].workflowFolders;
    }

    private skuWorkflowFoldersSet = async (options: {teamId: string, newWorkflowFolders: WorkflowFolderList}) => {
        this.teamData[options.teamId].workflowFolders  = options.newWorkflowFolders;

        const promises = chunk(ObjectKeyValuePairs(options.newWorkflowFolders), 200)
            .map(kvArray => {
                const batch = firestoreDb.batch();
                kvArray.forEach(kv => {
                    const ref = firestoreDb.collection('TeamAccount')
                        .doc(options.teamId)
                        .collection('workflow_folders')
                        .doc(String(kv.key));
                    batch.set(ref, { ...kv.value });
                })
                return batch.commit();
            });
        return Promise.all(promises).then(() => void 0 as void)
    }

    skuWorkflowFolders = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.skuWorkflowFoldersGet({teamId, ...options}),
            set: async (newWorkflowFolders: WorkflowFolderList) => await this.skuWorkflowFoldersSet({teamId, newWorkflowFolders}),
            refresh: async () => await this.skuWorkflowFoldersLoad({teamId}),
        }
    }

    private skuWorkflowStagesLoad = async (options: {teamId: string}) => {
        const skuWorkFlowStages = await this.CSApiClient["API_CALL_LoadSkuWorkFlowStages"](options.teamId);
        this.teamData[options.teamId].stageSKUs = skuWorkFlowStages.data.data;
    }

    private skuWorkflowStagesGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].stageSKUs === undefined) {
            await this.skuWorkflowStagesLoad(options);
        }

        return this.teamData[options.teamId].stageSKUs;
    }

    private skuWorkflowStagesSet = async (options: {teamId: string, newWorkflowStages: WorkFlowStagesList}) => {
        this.teamData[options.teamId].stageSKUs  = { ...this.teamData[options.teamId].stageSKUs, ...options.newWorkflowStages };
        this.apiCallSetSkuWorkFlowStages(options.teamId, options.newWorkflowStages);
    }

    skuWorkflowStages = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.skuWorkflowStagesGet({teamId, ...options}),
            set: async (newWorkflowStages: WorkFlowStagesList) => await this.skuWorkflowStagesSet({teamId, newWorkflowStages}),
            refresh: async () => await this.skuWorkflowStagesLoad({teamId}),
        }
    }

    private skuEditsLoad = async (options: {teamId: string, retailer: string, skuId: string}) => {
        const skuEdits = await this.CSApiClient["API_CALL_LoadSkuEdits"](options.teamId, options.retailer, options.skuId);
        this.teamData[options.teamId].skuEdits = skuEdits.data.data[options.skuId];
    }

    private skuEditsGet = async (options: {teamId: string, retailer: string, skuId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].skuEdits === undefined || (this.teamData[options.teamId].skuEdits.skuId !== options.skuId)) {
            await this.skuEditsLoad(options);
        }

        return this.teamData[options.teamId].skuEdits;
    }

    private skuEditsSet = async (options: {teamId: string, retailer: string, skuId: string, newSkuRecordEdited: SkuRecord}) => {
        this.teamData[options.teamId].skuEdits  = options.newSkuRecordEdited;
        await this.apiCallUpdateEdits(options.teamId, options.retailer, options.skuId, options.newSkuRecordEdited);
    }

    skuEdits = (options: {teamId?: string, retailer?: string, skuId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);
        const retailer = options.retailer;
        const skuId = options.skuId;

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.skuEditsGet({teamId, retailer, skuId}),
            set: async (newSkuRecordEdited: SkuRecord) => await this.skuEditsSet({teamId, retailer, skuId, newSkuRecordEdited: newSkuRecordEdited}),
            refresh: async () => await this.skuEditsLoad({teamId, retailer, skuId}),
        }
    }

    private teamBalanceLoad = async (options: {teamId: string}) => {
        this.teamData[options.teamId].teamBalance = (await this.CSApiClient.API_CALL_teamBalance(options.teamId)).data
    }

    private teamBalanceGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].teamBalance === undefined) {
            await this.teamBalanceLoad(options);
        }

        return this.teamData[options.teamId].teamBalance;
    }

    teamBalance = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.teamBalanceGet({teamId: teamId}),
            refresh: async () => await this.teamBalanceLoad({teamId: teamId}),
        }
    }

    private creditEventsLoad = async (options: {teamId: string}) => {
        this.teamData[options.teamId].creditEvents = (await this.CSApiClient.API_CALL_teamCreditEvents(options.teamId)).data
    }

    private creditEventsGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].creditEvents === undefined) {
            await this.creditEventsLoad(options);
        }

        return this.teamData[options.teamId].creditEvents;
    }

    creditEvents = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.creditEventsGet({teamId: teamId}),
            refresh: async () => await this.creditEventsLoad({teamId: teamId}),
        }
    }

    private jobsDataLoad = async (options: {teamId: string}) => {
        const jobsData = await this.CSApiClient["API_CALL_LoadJobsData"](options.teamId);
        this.teamData[options.teamId].jobsData = jobsData.data.jobData;
    }

    private jobsDataGet = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].jobsData === undefined) {
            await this.jobsDataLoad(options);
        }

        return this.teamData[options.teamId].jobsData;
    }

    jobsData = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.jobsDataGet({teamId}),
            refresh: async () => await this.jobsDataLoad({teamId}),
        }
    }

    private loadRules = async (options: {teamId: string}) => {
        const rules = await this.CSApiClient["API_CALL_GetTeamRules"](options.teamId);
        this.teamData[options.teamId].rules = rules.data.data;
    }

    private getRules = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || this.teamData[options.teamId].rules === undefined) {
            await this.loadRules(options);
        }

        return this.teamData[options.teamId].rules;
    }

    private deleteRule = async (options: {data: RuleBasicPayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.delete(options.data);
    }

    private deleteDefaultRule = async (options: { data: RuleId }) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.delete(options.data);
    }

    private changeRuleStatus = async (options: {data: RuleStatusPayload, isSystemRule: boolean}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.changeStatus(options.data, options.isSystemRule);
    }

    private changeDefaultRuleStatus = async (options: {data: RuleStatusPayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.changeStatus(options.data);
    }

    private createRule = async (options: {data: { auditRules: AuditRule}}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.create(options.data);
    }

    private createDefaultRule = async (options: {data: { auditRules: AuditRule}}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.create(options.data);
    }

    private editRule = async (options: {data: EditRulePayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.edit(options.data);
    }

    private editDefaultRule = async (options: {data: EditRulePayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.edit(options.data);
    }

    private runRule = async (options: DefaultRuleBasicPayload) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.runRule(options);
    }

    private runDefaultRule = async (options: {data: DefaultRuleBasicPayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.runRule(options.data);
    }

    private duplicateRule = async (options: {data: RuleBasicPayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.duplicate(options.data);
    }

    private duplicateDefaultRule = async (options: {data: DefaultRuleBasicPayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.duplicate(options.data);
    }

    private testRule = async (options: {data: RuleBasicPayload & TestRulePayload}) => {
        const res = await this.CSApiClient.API_CALL_RuleBuilder.TeamRules.test(options.data);
        return res;
    }

    private testDefaultRule = async (options: {data: DefaultRuleBasicPayload & TestRulePayload}) => {
        await this.CSApiClient.API_CALL_RuleBuilder.CSDefaultRules.test(options.data);
    }

    ruleBuilder = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            getRules: async (options?: {forceRefresh?: boolean}) => await this.getRules({teamId, ...options}),
            refresh: async () => await this.loadRules({teamId}),
            delete: async (options: {data: RuleBasicPayload}) => await this.deleteRule(options),
            deleteDefault: async (options: {data: RuleId}) => await this.deleteDefaultRule(options),
            changeStatus: async (options: { data: RuleStatusPayload, isSystemRule: boolean}) => await this.changeRuleStatus(options),
            changeStatusDefault: async (options: { data: RuleStatusPayload}) => await this.changeDefaultRuleStatus(options),
            create: async (options: { data: { auditRules: AuditRule}}) => await this.createRule(options),
            createDefault: async (options: { data: { auditRules: AuditRule}}) => await this.createDefaultRule(options),
            edit: async (options: { data: EditRulePayload}) => await this.editRule(options),
            editDefault: async (options: { data: EditRulePayload}) => await this.editDefaultRule(options),
            run: async (options: { data: RuleId}) => await this.runRule({teamId, ...options.data}),
            runDefault: async (options: { data: DefaultRuleBasicPayload}) => await this.runDefaultRule(options),
            duplicate: async (options: { data: RuleBasicPayload}) => await this.duplicateRule(options),
            duplicateDefault: async (options: { data: DefaultRuleBasicPayload}) => await this.duplicateDefaultRule(options),
            test: async (options: { data: RuleBasicPayload & TestRulePayload}) => await this.testRule(options),
            testDefault: async (options: { data: DefaultRuleBasicPayload & TestRulePayload}) => await this.testDefaultRule(options),
        };
    }

    private loadScoreCards = async (options: {teamId: string}) => {
        const scoreCardsArray = await this.CSApiClient["API_CALL_GetScoreCardList"](options.teamId);
        const scoreCards = {} as any;
        scoreCardsArray.data.forEach((scoreCard: ScoreCardSettings) => {
            scoreCards[scoreCard.scoreCardId] = scoreCard;
        })
        this.teamData[options.teamId].scoreCards = scoreCards;
    }

    private getScoreCards = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || !this.teamData[options.teamId].scoreCards) {
            await this.loadScoreCards(options);
        }

        return Objectentries(this.teamData[options.teamId].scoreCards);
    }

    private getDefaultScorecards = async (options: {teamId: string}) => {
        const scoreCards = await this.CSApiClient["API_CALL_GetDefaultScoreCard"]();
        return scoreCards;
    }

    private getSystemScorecards = async (options: {teamId: string}) => {
        const scoreCards = await this.CSApiClient["API_CALL_GetSystemScoreCards"]();
        return scoreCards;
    }

    private deleteScoreCard = async (options: {id: string, teamId: string}) => {
        delete this.teamData[options.teamId].scoreCards[options.id];
        await this.CSApiClient.API_CALL_DeleteScoreCard(options.id, options.teamId);
    }

    private createScoreCard = async (options: {data: ScoreCardSettings, teamId: string}) => {
        this.teamData[options.teamId].scoreCards[options.data.scoreCardId] = options.data;
        await this.CSApiClient.API_CALL_CreateScoreCard(options.data, options.teamId);
    }

    private publishChangesScoreCard = async (options: {id: string, teamId: string}) => {
        await this.CSApiClient.API_CALL_ScorecarsdPublishChanges(options.teamId, options.id);
    }

    private resetDefaultScoreCard = async (options: {teamId: string}) => {
        const data = await this.CSApiClient.API_CALL_ResetDefaultScoreCard();
        return data;
    }

    private editScoreCard = async (options: {teamId: string, data: ScoreCardSettings}) => {
        this.teamData[options.teamId].scoreCards[options.data.scoreCardId] = options.data;
        await this.CSApiClient["API_CALL_EditScoreCard"](options.data, options.teamId);
    }

    private editDefaultScoreCard = async (options: {data: ScoreCardSettings}) => {
        await this.apiCallUpdateDefaultScoreCard(options.data);
    }

    private setDefaultScoreCard = async (options: { scoreCardId: string, teamId: string, status:boolean}) => {
        this.apiCallSetDefaultScoreCard(options.scoreCardId, options.teamId, options.status);
    }

    private loadScoreCardDefaults = async (options: {teamId: string}) => {
        const scoreCardDefaultsRes = await this.CSApiClient["API_CALL_GetTeamDefaultScoreCards"](options.teamId);
        const scoreCardDefaults = scoreCardDefaultsRes.data;
        this.teamData[options.teamId].scoreCardDefaults = scoreCardDefaults;
    }

    private getScoreCardDefaults = async (options: {teamId: string, forceRefresh?: boolean}) => {
        if (options.forceRefresh || !this.teamData[options.teamId].scoreCardDefaults) {
            await this.loadScoreCardDefaults(options);
        }

        return this.teamData[options.teamId].scoreCardDefaults;
    }

    private setScoreCardDefaults = async (options: {teamId: string, data: ScoreCardDefaultMappings}) => {
        this.teamData[options.teamId].scoreCardDefaults = options.data;
        await this.CSApiClient["API_CALL_SetTeamDefaultScoreCards"](options.teamId, options.data);
    }

    scoreCard = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {forceRefresh?: boolean}) => await this.getScoreCards({teamId, ...options}),
            refresh: async () => await this.loadScoreCards({teamId}),
            delete: async (options: {id: string}) => await this.deleteScoreCard({teamId, ...options}),
            create: async (options: { data: ScoreCardSettings}) => await this.createScoreCard({teamId, ...options}),
            publishChanges: async (options: {id: string}) => await this.publishChangesScoreCard({teamId, ...options}),
            edit: async (options: { data: ScoreCardSettings}) => await this.editScoreCard({teamId, ...options}),
            getDefaults: async (options?: {forceRefresh?: boolean}) => await this.getScoreCardDefaults({teamId, ...options}),
            setDefaults: async (options: { data: ScoreCardDefaultMappings}) => await this.setScoreCardDefaults({teamId, ...options}),
            // The following are for manipulating the CSGlobalDefault Scorecard...
            resetCSDefault: async () => await this.resetDefaultScoreCard({teamId}),
            getCSDefault: async () => this.getDefaultScorecards({teamId}),
            getSystemCards: async () => this.getSystemScorecards({teamId}),
            editCSDefault: async (options: { data: ScoreCardSettings}) => await this.editDefaultScoreCard(options),
            setCSDefault: async (options: { scoreCardId: string, status:boolean}) => await this.setDefaultScoreCard({teamId, ...options}),
        };
    }

    private teamOperationsLoad = async (options: {
        teamId: string;
        tsUpTo?: number;
        limit?: number;
    }) => {
        const teamOperationsRes = await this.CSApiClient["API_CALL_teamOperations"](options);
        const existingOps = this.teamData[options.teamId].operations || {};
        this.teamData[options.teamId].operations = { ...existingOps, ...teamOperationsRes.data.operations}
    }

    private teamOperationsGet = async (options: {
        teamId: string;
        tsUpTo?: number;
        limit?: number;
        forceRefresh?: boolean
    }) => {
        if (options.forceRefresh || this.teamData[options.teamId].operations === undefined) {
            await this.teamOperationsLoad(options);
        }

        return this.teamData[options.teamId].operations;
    }

    teamOperations = (options: {teamId?: string}) => {
        const teamId = this.getTeamIdToUse(options.teamId);

        return {
            get: async (options?: {tsUpTo?: number; limit?: number; forceRefresh?: boolean}) => await this.teamOperationsGet({teamId, ...options}),
            refresh: async () => await this.teamOperationsLoad({teamId}),
        }
    }
}

export const ContentStatusDataService = new ContentStatusDataServiceClass();

/**
 * Usage
 * const catalogData = await ContentStatusDataService.catalogData({teamId: <teamId>}).get();
 *
 * ContentStatusDataService.skuTags({teamId: "<teamId>"}).set({...})
 */
