import React from 'react';
import './style.scss';

interface Props {
  value?: 'grey' | 'yellow' | 'green';
  onChange?: (color: 'grey' | 'yellow' | 'green') => void;
}

const Checkmark = ({ value, onChange }: Props) => {
  const toggleColor = (e: React.MouseEvent) => {
    let newColor;
    switch (value) {
      case 'grey':
        newColor = 'yellow';
        break;
      case 'yellow':
        newColor = 'green';
        break;
      default:
        newColor = 'grey';
        break;
    }
    if (onChange) {
      onChange(newColor);
    }
  };

  return (
    <button
      onClick={toggleColor}
      className={`checkmark-toggler ${value}-checkmark`}>
      {' '}
    </button>
  );
};

export default Checkmark;
