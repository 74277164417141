import * as React from "react";
import { NavLink } from "react-router-dom";
// import * as firebase from 'firebase';
import { v4 as uuidv4} from 'uuid';
import { Auth } from "../Auth";
import { getCoreUserAccount } from "../Util";
import config from '../config';
import countryOptionsLists from './countryOptionsLists';

import { ADMIN_IDS } from "../../constants";
import { RetailerNameRenderFns } from "../Util/constants";

export const Mathlib = { avg: (nums: number[]) => nums.reduce((a,b) => a+b) / nums.length }

export function Objectkeys<T extends {}>(o: T) {
    return Object.keys(o) as Array<keyof T>;
}
export function Objectentries<T extends {}>(o: T) {
    return Objectkeys(o).map(key => o[key]);
}
export function ObjectKeyValuePairs<T extends {}>(o: T) {
    return Objectkeys(o).map(key => ({
        key,
        value: o[key]
    }));
}

export const defaultVal = (val: any, defaultVal: any) => val !== undefined ? val : defaultVal;

export const capitalizeStr = (str: string) => {
  return str
  ? str[0].toLocaleUpperCase()+str.slice(1)
  : ''
}

export const copyToClipboard = (text: string, domId = "") => {
  if (window['clipboardData'] && window['clipboardData'].setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window['clipboardData'].setData("Text", text);

  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      const textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      const appendEle = document.getElementById(domId || '') || document.body;
      appendEle.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      } finally {
        appendEle.removeChild(textarea);
      }
  }
}

export function PromisifySetState<T>(
    setState: (state: T, callback?: any) => void,
    classInstance: any
) {
    const returnFn = (state: T) => new Promise<void>((resolve, reject) => {
        try {
            classInstance.setState(state, () => resolve());
        } catch (error) {
            reject(error);
        }
    });
    return (returnFn).bind(classInstance) as typeof returnFn;
}

export function tryer<T>(fn: () => T, catcher: T | undefined = undefined): T | undefined {
    try {
        const returnValue = fn();
        return (returnValue)
        ? returnValue
        : catcher;
    } catch (e) {
        return catcher;
    }
}

export const appendToTextarea = (id: string, value: string) => {
    const textarea = window.document.getElementById(id) as HTMLTextAreaElement;

  /* const newValue = (textarea.value.trim() === '')
      ? value
      : textarea.value + " " + value */
    if (textarea) {
        textarea.value = value; //newValue;
        textarea.focus();
        textarea.dispatchEvent(new Event("input", { bubbles: true }));
        textarea.dispatchEvent(new Event("change", { bubbles: true }));
        textarea.blur();
    }
}

export const priceRenderer = (price: number | string, options: {divisor?: number; numDecimals?: number} = {}) => {
    const priceNum = typeof price === 'string'
    ? parseFloat(price)
    : price;

    const {divisor, numDecimals} = options;
    const safeDivisor = (!divisor || divisor <= 0) ? 1 : divisor;
    const numDec = (numDecimals === undefined || numDecimals === null || numDecimals < 0)
    ? 2
    : numDecimals;

    const fixedFmt = (priceNum/safeDivisor).toFixed(numDec);
    const [whole, dec] = fixedFmt.split(".");
    const wholeLocaleFmt = parseInt(whole, 10).toLocaleString();
    const decNum = parseInt(dec, 10);
    return `$${wholeLocaleFmt.replace(/\s/g, ',')}${decNum ? "."+decNum : ''}`;
}

export const fuzzySearch = <T extends {}>(
    searchTermGroup: string,
    items: T[],
    itemAccessor: (item: T) => string[],
    blankSearchReturnAll: boolean = true
) => {
    if (searchTermGroup && searchTermGroup.trim() && items.length) {
        const searchTerms = searchTermGroup.trim().toLocaleLowerCase().split(/\s+/);
        return items.filter(item =>
            itemAccessor(item)
            .some(value => {
                return (value !== null && value !== undefined)
                ? searchTerms
                .some(searchTerm => String(value).toLocaleLowerCase().indexOf(searchTerm) !== -1)
                : false;
            })
        );
    } else {
        return (blankSearchReturnAll)
        ? items
        : [];
    }
}

export const multiAndCompare = (root: any, cmpFn: (a, b) => boolean, ...items: any[]) => {
    let result = true;
    for (let i = 0; i < items.length; i++) {
        result = result && cmpFn(root, items[i]);
        if (result === false) {
            break;
        }
    }
    return result;
}

export const statusNameStyled = (name: string) => <span className="statusNameStyled">
    <span style={{color: '#4FDA03', fontWeight: 900}}>{`${name.toLocaleUpperCase()}`}</span>
    <span style={{fontWeight: 400}}>{"STATUS"}</span>
</span>;

export const statusNameStyledList = (names: string[]) => names
.map(x=>({el: statusNameStyled(x!), key: x!}))
.map((x, idx, arr) => React.cloneElement(
    (idx === 0)
    ? x.el
    : (idx === arr.length - 1)
        ? <span>{`${(arr.length > 2) ? ',' : ''} and `}{x.el}</span>
        : <span>, {x.el}</span>,
    {key: x.key}
));

const assignDottedRecur = <T extends object>(obj: T, arr: string[], val: any): T => {
    if (arr.length > 0) {
        if (arr.length > 1) {
            if (!(obj as any)[arr[0]]) {
                (obj as any)[arr[0]] = {};
            }
            assignDottedRecur((obj as any)[arr[0]], arr.slice(1), val);
        } else {
            (obj as any)[arr[0]] = val;
        }
    }
    return obj;
}

export const assignDotted = <T extends object>(obj: T, accessor: string, val: any): T => {
    return assignDottedRecur(obj, accessor.split('.'), val);
}

export const assignDottedArray = <T extends object>(obj: T, accessorValPairs: Array<[string, any]>): T => {
    return accessorValPairs.reduce((o, pair, idx) => assignDotted(o, ...pair), obj);
}

export const oxfordCommaList = (array?: any[]) => {
    return (array && array.length > 0)
        ? (array.length > 2)
            ? array.slice(0, array.length - 1).join(', ') + ', and ' + String(array[array.length - 1])
            : (array.length > 1)
                ? array.join(' and ')
                : String(array[0])
        : undefined;
}

export const ButtonNavLink = NavLink; // toClass(NavLink);

export function RandomId() {
    return (uuidv4)();
}

export const retailerNameRender = (retailer: string) => {
    return RetailerNameRenderFns.displayName(retailer) || '';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const internalPort = config.getPort();
const publicHostname = window.location.hostname;
const publicPort = (window.location.port) ? parseInt(window.location.port, 10) : 80;
const isProdWebpackEnv = (config.NODE_ENV === 'production');
const gcpProject = config.customEnv.GCP_PROJECT;

export const SITE_URL_PUBLIC = 'https://app.contentstatus.com';
export const SITE_URL = (isProdWebpackEnv)
? (config.isProdHostEnv)
    ? SITE_URL_PUBLIC
    : window.location.origin
: window.location.origin;

const BACK_URL_PUBLIC = 'https://main-backend-sf3pg75z2q-uc.a.run.app'//'https://twd-contentstatus.appspot.com';
export const BACK_URL = (isProdWebpackEnv || config.forceProdBackend)
? (config.isProdHostEnv || config.forceProdBackend)
    ? BACK_URL_PUBLIC
    : config.customEnv.DEPLOY_ENV === 'prod'
        ? `https://main-backend-${config.customEnv.CR_SVC_UUID}-uc.a.run.app`//https://${gcpProject}.appspot.com`
        : !!config.customEnv.REACT_APP_DEV_ID
            ? `https://${config.customEnv.REACT_APP_DEV_ID}-backend-${config.customEnv.CR_SVC_UUID}-uc.a.run.app`//`https://${config.customEnv.REACT_APP_DEV_ID}-backend-dot-${gcpProject}.uc.r.appspot.com`
            : `https://staging-backend-${config.customEnv.CR_SVC_UUID}-uc.a.run.app`//`https://${gcpProject}.appspot.com`
: `${window.location.protocol}//${publicHostname}:${publicPort+1}`;

export const API_URL_PUBLIC = BACK_URL_PUBLIC+'/api';
export const API_URL = BACK_URL+'/api';

// import * as firebase from 'firebase';

// export type AuthContext = {
//     user: firebase.User;
// };

export interface CoreUserAccount {
    userId: string;
    firstName: string;
    lastName: string;
    title: string;
    role: string;
    company: string;
    companyType: string;
    industry: string;
    email: string;
    teamId: string;
    teamIds?: string[];
    chargebeeCustomerId?: string;
    status?: string;
    inviteId?: string;
    dateJoined?: number;
    isEmailConfirmed: boolean | undefined;
}

export interface CoreTeamAccount {
    teamId: string;
    jobs: string[];
    credits: number;
    recurringSubscription: boolean;
}

export interface ContactCoreDetails {
    contactId: string;
    firstName: string;
    lastName: string;
    company: string;
    type: string;
    email: string;
}

export interface NewJobContact extends ContactCoreDetails {
    newJob: {
        fullReport: boolean;
        summaryReport: boolean;
    };
}

export interface ContactPermissions extends ContactCoreDetails {
    access: {
        role: 'OWNER' | 'ADMIN' | 'LIMITED';
        permissions: {
            inviteNewUsers: boolean;
            removeUsers: boolean;
            purchaseCredits: boolean;
            createNewJobs: boolean;
            viewJobResults: boolean;
            editDataReports: boolean;
            viewDataReports: boolean;
        };
    };
}

export interface NewJobContactMap {
    myTeam: {
        [x: string]: NewJobContact
    },
    myRetailers: {
        [x: string]: NewJobContact
    },
    myPartners: {
        [x: string]: NewJobContact
    }
}

/*
export type ActiveUserAccount = {
    isSignedIn: false;
    user: undefined;
} | {
    isSignedIn: true;
    user: firebase.User;
};
*/

export interface InactiveUserAccount {
    isSignedIn: false;
    auth0Client: Auth;
}

export interface ActiveUserAccount {
    isSignedIn: true;
    profile: NonNullable<ReturnType<Auth["getProfile"]>>;
    auth0Client: Auth;
    user: {
        displayName: string;
        core?: CoreUserAccount;
        diffUserId?: string;
        rootUserId?: string;
        rootUserDisplayName?: string;
    }
}

export type UserAccountObj = InactiveUserAccount | ActiveUserAccount;
export function getCoreFromUserAccount(account: UserAccountObj) {
    return (account.isSignedIn && account.user.core)
    ? account.user.core
    : undefined;
}

export function InitInactiveUser(auth0Client: Auth): InactiveUserAccount {
    return { isSignedIn: false, auth0Client };
}
export function InitActiveUser(
    auth0Client: Auth,
    options?: {
        core?: CoreUserAccount;
        rootUserId?: string;
        diffUserId?: boolean;
    }
): ActiveUserAccount {
    const {core, diffUserId, rootUserId} = options || {};

    const profile = auth0Client.getProfile()!;

    const auth0DisplayName = profile.name;

    const user = {
        core,
        displayName: auth0DisplayName
    };

    user["diffUserId"] = diffUserId;

    if (diffUserId) {
        user["rootUserId"] = rootUserId;
        user["rootUserDisplayName"] = auth0DisplayName;
        user["displayName"] = core.firstName + " " + core.lastName;
    }

    return {
        isSignedIn: true,
        auth0Client,
        profile,
        user
    };
}

export function InitUserAccountObj(auth0Client: Auth): UserAccountObj {
    return (auth0Client.isAuthenticated())
    ? InitActiveUser(auth0Client)
    : InitInactiveUser(auth0Client);
}

// export function GetUser(auth0Client: Auth, inactive: true): Promise<InactiveUserAccount>;
export async function GetUser(auth0Client: Auth, userId?: string): Promise<UserAccountObj> {
    if (auth0Client.isAuthenticated()) {
        const auth0UserId = auth0Client.getProfile()!.sub;

        let userIdToUse = auth0UserId;
        let diffUserId = false;

        if (ADMIN_IDS.indexOf(auth0UserId) !== -1 && userId !== undefined) {
            userIdToUse = userId;

            diffUserId = userId !== auth0UserId;
        }

        const coreUser = await getCoreUserAccount(userIdToUse);

        return InitActiveUser(auth0Client, {core: coreUser, rootUserId: auth0UserId, diffUserId })
    } else {
        return InitInactiveUser(auth0Client);
    }
}

/*
export function InitActiveUser(user: firebase.User | null): ActiveUserAccount {
    return (user) ? { isSignedIn: true, user} : { isSignedIn: false, user: undefined};
}
*/

export interface CsvData {
    papa: Papa.ParseResult;
    fileName: string;
}

export interface RetailerMappedData {
    mappedData: MappedRow[];
    retailerName: string;
};

export interface MappedRow {
    rowId: string;
    sectionTitles: FieldTypeNames[];
    retailerName: string;
    sections: {
        [x in FieldTypeNames]?: GenericMappedRowSection<x>;
    };
};

interface GenericMappedRowSection<T extends FieldTypeNames> {
    sectionTitle: T;
    retailerTitles: string[];
    brandTitles: string[];
    retailerCells: {
        [x: string]: GenericMappedRowSectionCell<T>
    };
    brandCells: {
        [x: string]: GenericMappedRowSectionCell<T>
    };
};

export type MappedRowSection = GenericMappedRowSection<FieldTypeNames>;

interface GenericMappedRowSectionColumn<T extends FieldTypeNames> {
    retailerCell: GenericMappedRowSectionCell<T>;
    brandCell: GenericMappedRowSectionCell<T>;
};

export type MappedRowSectionColumn = GenericMappedRowSectionColumn<FieldTypeNames>;

export function GetMappedRowSectionColumn(titleIndex: number, section: MappedRowSection): MappedRowSectionColumn {
    return {
        retailerCell: section.retailerCells[section.retailerTitles[titleIndex]],
        brandCell: section.brandCells[section.brandTitles[titleIndex]]
    };
}

interface GenericMappedRowSectionCell<T extends FieldTypeNames> {
    labelColor: '' | 'none' | 'red' | 'yellow';
    sectionTitle: T;
    cellType: 'retailerCell' | 'brandCell';
    title: string;
    value: string;
    notes: string;
    error: string;
    hasError: boolean;
    errorEdited: boolean;
};

export type MappedRowSectionCell = GenericMappedRowSectionCell<FieldTypeNames>;

const RetailerAdminRecordTemplate: RetailerAdminRecord = {
    retailerId: '',
    retailerName: '',
    status: '', // Dropdown of 'Active' | 'Inactive'
    category: '', // Dropdown of categories
    country: '', // Dropdown of countries
    numFields: NaN,
    fieldDefinitions: {},
    pctMapped: NaN,
    companiesUsed: NaN,
    lastUsedTs: NaN,
    numSearches: NaN,
    numSKUS: NaN,
    avgDays: NaN,
    avgAccuracy: NaN,
    avgScore: NaN,
    createdTs: NaN,
    createdBy: '',
    modifiedTs: NaN,
    modifiedBy: ''
};

// tslint:disable-next-line:align
export function newRetailerAdminRecord(initialValues?: Partial<RetailerAdminRecord>) {
    return Object.assign({}, RetailerAdminRecordTemplate, initialValues) as RetailerAdminRecord;
}

export const { countryOptions, countryOptionsMap } = countryOptionsLists;

export interface RetailerAdminRecord {
    retailerId: string;
    country: string; // Dropdown of countries
    category: string; // Dropdown of categories
    retailerName: string;
    status: string; // Dropdown of 'Active' | 'Inactive'
    numFields: number;
    fieldDefinitions: { [x: string]: FieldDefinition };
    pctMapped: number;
    companiesUsed: number;
    lastUsedTs: number;
    numSearches: number;
    numSKUS: number;
    avgDays: number;
    avgAccuracy: number;
    avgScore: number;
    createdTs: number;
    createdBy: string;
    modifiedTs: number;
    modifiedBy: string;
};

export interface FieldDefinition {
    fieldName: string;
    fieldType: FieldType;
};

export interface MappedBrandFieldDefinition {
    fieldName: string;
    retailerFieldDefinition: FieldDefinition;
}

export interface RetailerSKU {
    jobId: string;
    retailerSkuId: string;
    data: {
        [x: string]: string;
    };
    fields: string[];
}

export interface BrandSKU {
    jobId: string;
    brandSkuId: string;
    data: {
        [x: string]: string;
    };
    fields: string[];
}



interface ParsedCsv {
    data: Array<{ [x: string]: string; }>;
    errors?: any[];
    meta: Partial<{
        aborted: boolean;
        cursor: number;
        delimiter: string;
        fields: string[];
        linebreak: string;
        truncated: boolean;
    }>;
}

export interface JobData {
    datasetId: string;
    fileName: string;
    masterCsv: ParsedCsv;
    skuIdMap: {
        [x in 'upc' | 'toolId' | 'url' | 'asin' | 'productId']?: string;
    };
    retailerInfo?: {
        [retailerId: string]: {
            retailerId: string;
            retailerFieldMap: { // retailerName:
                [x: string]: /*Brand Field Name:*/MappedBrandFieldDefinition;
            }
            retailerName: string;
        }
    };
}

export interface JobSchedule {
    start: {
        when: 'now';
    } | {
        when: 'custom';
        date: number;
    };
    liveStatus: {
        frequency: {
            how: 'once';
        } | {
            how: 'custom';
            frequency: number;
            stop: {
                when: 'percent';
                percent: number;
            } | {
                when: 'date';
                date: number;
            };
        };
    };
    recur: {
        how: 'once';
    } | {
        how: 'custom';
        frequency: {
            unit: 'day' | 'week' | 'month';
            amount: number;
        };
        stop: {
            when: 'never';
        } | {
            when: 'limit';
            limit: number;
        } | {
            when: 'date';
            date: number;
        }
    };
}

export interface JobMetaData2 {
    sku?: {
        checkType?: 'new' | 'edit';
        submitDate?: number;
    };
}

export interface JobMetaData {
    sku?: {
        checkType: {
            new: boolean
            edit: boolean
        };
        submitDate: {
            new?: number
            edit?: number
        };
    }
    accuracy? : {
        datasetId: string;
    }
}

export interface CoreJob {
    jobId: string;
    jobName: string;
    jobNum: string;
    owner: string;
    statusChecks: Array<"Live" | "Accuracy" | "Health" | "Curation">;
    dateOpened: number;
    dateClosed?: number;
    stage: 'Complete' | 'In Process' | 'Scheduled' | 'Incomplete Job' | 'Error';
    statusReportMap: {
        [report in "Live" | "Accuracy" | "Health" | "Curation"]?: 'Complete' | 'In Process' | 'System Error' | 'Data Error'
    };
    jobData: JobData;
    retailerInfo: {
        retailerId: string;
        retailerFieldMap: { // retailerName:
            [x: string]: /*Brand Field Name:*/MappedBrandFieldDefinition;
        }
        retailerName: string;
    };
    schedule: JobSchedule;
    meta: JobMetaData;
}

export type BrandJobStatusChecks = 'Live' | 'Accuracy' | 'Health' | 'Curation';

export interface BrandJob {
    jobId: string;
    retailerSKUs: RetailerSKU[];
    brandSKUs: BrandSKU[];
    retailerFieldMap: {
        [x: string]: { // retailerName:
            [x: string]: /*Brand Field Name:*/MappedBrandFieldDefinition;
        }
    };
    dateOpened: number;
    jobName: string;
    owner: string;
    stage: 'Complete' | 'In Process' | 'Scheduled' | 'Incomplete Job';
    statusChecks: BrandJobStatusChecks[];
    shared: object[];
}

export function newBrandJob(initialValues?: Partial<BrandJob>) {
    return Object.assign(
        {},
        {
            jobId: '',
            brandSKUs: [],
            retailerSKUs: [],
            retailerFieldMap: {},
            dateOpened: NaN,
            jobName: '',
            owner: '',
            stage: 'Scheduled',
            statusChecks: [],
            shared: []
        } as BrandJob,
        initialValues
    ) as BrandJob;
}

export interface BrandJobData {
    jobId: string;
    unlinkedSKUs: {
        unlinkedBrandSKUs: BrandSKU[];
        unlinkedRetailerSKUs: RetailerSKU[];
    };
    retailerMappedData: RetailerMappedData;
}

const BrandJobHeaderNameMap = {
    dateOpened: 'Date Opened',
    dateClosed: 'Date Closed',
    owner: 'Owner',
    retailerId: 'Retailer',
    jobName: 'Job Name',
    live: 'Live',
    health: 'Health',
    accuracy: 'Accuracy',
    statusChecks: 'Status Checks',
    stage: 'Stage',
    action: ''
}

export const BrandJobDisplayConfig = {
    headerNameMap: BrandJobHeaderNameMap,
    renderer: (job: CoreJob, headerName: keyof typeof BrandJobHeaderNameMap) => {
        const pathnamePrefix = (window.location.pathname.startsWith('/newshell'))
        ? '/newshell'
        : '';
        let stageView;
        switch (job.stage) {
            case 'Scheduled':
            case 'Incomplete Job':
                stageView = <NavLink
                    to={pathnamePrefix+'/newjob?jobId='+job.jobId}
                >
                    Edit draft
                </NavLink>;
                break;
            case 'Complete':
            case 'In Process':
            default:
                stageView = null;
                break;
        }
        const reportRender = (x: string) => {
            let view;
            switch (x) {
                case 'Complete':
                    view = <NavLink
                        to={pathnamePrefix+'/data?jobId='+job.jobId}
                    >
                        View
                    </NavLink>;
                    break;
                case 'In Process':
                    view = 'In Process';
                    break;
                default:
                    view = '';
                    break;
            }
            return view;
        }
        let jsx;
        switch (headerName) {
            case 'dateClosed':
                jsx = (job.stage === 'Complete')
                ? (new Date((job['dateClosed']) ? job['dateOpened'] : job['dateOpened'])).toLocaleDateString()
                : ' - ';
                break;
            case 'dateOpened':
                jsx = (new Date(job['dateOpened'])).toLocaleDateString();
                break;
            case 'action':
                jsx = stageView;
                break;
            case 'live':
            case 'accuracy':
            case 'health':
                if (stageView) {
                    jsx = stageView;
                } else {
                    const upperHeader = headerName.slice(0, 1).toLocaleUpperCase()+headerName.slice(1);
                    const checks = (job.statusReportMap) ? job.statusReportMap : Object.assign({},...job.statusChecks.map(x=>({[x]: job.stage})));
                    if (job.statusChecks.indexOf(upperHeader as any) !== -1 && checks[upperHeader]) {
                        jsx = reportRender(checks[upperHeader]);
                    } else {
                        jsx = ' - ';
                    }
                }
                break;
            case 'statusChecks':
                // tslint:disable-next-line:no-console
                // console.log(job); console.log(headerName);
                jsx = job[headerName].join(", ");
                break;
            case 'retailerId':
                const retailerNameMap = {
                    "3f483a05-e743-41f3-93da-0f9ab8414b45": 'Walmart.com' as 'Walmart.com',
                    "f52da738-ac10-44d3-bcd8-73889aedc009": "Lowe's" as "Lowe's"
                };
                jsx = retailerNameMap[job.retailerInfo.retailerId] || 'Walmart.com';
                break;
            default:
                // jsx = String(record[headerName]);
                jsx = job[headerName];
        }
        return jsx;
    },
    widths: (headerName: keyof typeof BrandJobHeaderNameMap) => {
        switch (headerName) {
            case 'dateClosed':
            case 'dateOpened':
                return 120;
            case 'owner':
            case 'retailerId':
                return 120;
            case 'live':
            case 'accuracy':
            case 'health':
                return 120;
            default:
                return undefined
        }
    }
};

const uidN = 'UID';
const specN = 'Specification';
const astuN = 'Asset URL';

const uid = {
    fieldTypeName: uidN as typeof uidN,
    reorderable: true as true,
    compared: true as true
};
const spec = {
    fieldTypeName: specN as typeof specN,
    reorderable: true as true,
    compared: false as false
};
const astu = {
    fieldTypeName: astuN as typeof astuN,
    reorderable: false as false,
    compared: false as false
};

type defaultNames = 'Copy' | 'Dimension' | 'Feature' | 'Weight & Measure' | 'Information';

function defaultFieldName<name extends defaultNames>
    (fTName: name) {
        return {
            fieldTypeName: fTName,
            reorderable: false as false,
            compared: true as true
        };
}

type FieldTypeNameMapIndex = {
    UID: typeof uid,
    Specification: typeof spec,
    'Asset URL': typeof astu
} & {
    [name in defaultNames]: ReturnType<typeof defaultFieldName>
};
type FieldTypeNameMap<K extends keyof FieldTypeNameMapIndex> = FieldTypeNameMapIndex[K];

export type FieldType = typeof uid | typeof spec | typeof astu | ReturnType<typeof defaultFieldName>;
type FieldTypeNames = FieldType['fieldTypeName'];

export function GetFieldType<T extends FieldTypeNames>
    (
        fieldTypeName: T
    ): FieldTypeNameMap<T> {
    let fieldType;
    switch (fieldTypeName) {
        case 'UID':
            fieldType = uid;
            break;
        case 'Specification':
            fieldType = spec;
            break;
        case 'Asset URL':
            fieldType = astu;
            break;
        default:
            fieldType = defaultFieldName(fieldTypeName as defaultNames);
            break;
    }
    return fieldType as FieldTypeNameMap<T>;
}
/*
type FieldTypeNameMapIndex = {
    UID: {
        fieldTypeName: 'UID';
        reorderable: true;
        compared: true;
    },
    Specification: {
        fieldTypeName: 'Specification';
        reorderable: true;
        compared: false;
    },
    'Asset URL': {
        fieldTypeName: 'Asset URL';
        reorderable: false;
        compared: false;
    }
} & {
    [name in 'Copy' | 'Dimension' | 'Feature' | 'Weight & Measure' | 'Information']: {
        fieldTypeName: name; // 'Copy' | 'Dimension' | 'Feature' | 'Weight & Measure' | 'Information';
        reorderable: false;
        compared: true;
    }
};

type FieldTypeNames = keyof FieldTypeNameMapIndex;

export type FieldType = FieldTypeNameMap<FieldTypeNames>;

export function GetFieldType1<T extends FieldTypeNames>
    (
        fieldTypeName: T
    ): FieldTypeNameMap<T> {
    let fieldType: FieldTypeNameMap<T>;
    switch (fieldTypeName) {
        case 'UID':
            fieldType = {
                fieldTypeName: fieldTypeName,
                reorderable: true,
                compared: true
            } as FieldTypeNameMap<T>;
            break;
        case 'Specification':
            fieldType = {
                fieldTypeName: fieldTypeName,
                reorderable: true,
                compared: false
            } as FieldTypeNameMap<T>;
            break;
        case 'Asset URL':
            fieldType = {
                fieldTypeName: fieldTypeName,
                reorderable: false,
                compared: false
            } as FieldTypeNameMap<T>;
            break;
        default:
            fieldType = {
                fieldTypeName: fieldTypeName,
                reorderable: false,
                compared: true
            } as FieldTypeNameMap<T>;
            break;
    }
    return fieldType;
}
*/

export interface TeamInvite {
    teamId: string;
    inviteId: string;
    inviterId: string;
    invitedUserId?: string | undefined,
    status: 'pending' | 'accepted';
    userContact: {
        firstname: string;
        lastname: string;
        email: string;
        role: string;
    };
}
