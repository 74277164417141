import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GlobalState } from '../../../libraries/types/ReportUp';
import { CSUISegment } from '../../CSUI';
import RegistrationForm from '../RegistrationForm';
import GlobalStateProvider from '../../GlobalStateProvider';
import './style.scss';

interface Props {
  globalState: GlobalState;
}
const NewUserRegistration = ({ globalState }: Props) => {
  const isSignedIn = globalState.activeUser.isSignedIn &&
  globalState.activeUser.user.core &&
  globalState.activeUser.user.core.userId
  return (
    <div className='newUserRegistrationContainer'>
      <div>
        <CSUISegment>
          {isSignedIn ? (
            <Redirect to='/dashboard' />
          ) : (
            <Route
              render={(router) => (
                <RegistrationForm {...{ globalState, router }} />
              )}
            />
          )}
        </CSUISegment>
      </div>
    </div>
  );
};

export default GlobalStateProvider(NewUserRegistration);
