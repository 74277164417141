import axios from 'axios';
import {API_URL} from '../../types/datastructures';
import {
    AuditRule, DefaultRuleBasicPayload, EditRulePayload,
    RuleId,
    RuleBasicPayload,
    RuleStatusPayload,
} from '../../../components/AuditRules/datastructures';

const SVC_ROOT = `${API_URL}/rule-builder`;

export function API_CALL_getRulesReportInfo(teamId: string){
    return axios.get(`${SVC_ROOT}/report?teamId=${teamId}`)
}

export function API_CALL_getRulesAttributes(teamId: string){
    return axios.get(`${SVC_ROOT}/attributes?teamId=${teamId}`)
}

export function API_CALL_getRulesResultsInfo(teamId: string){
    return axios.get(`${SVC_ROOT}/results?teamId=${teamId}`)
}

export function API_CALL_GetTeamRules(teamId: string){
    return axios.post(`${SVC_ROOT}/get-rules`, { teamId })
}

export function API_CALL_GetRuleKeywords(teamId: string){
    return axios.post(`${SVC_ROOT}/keywords/get`, { teamId })
}

export function API_CALL_AddRuleKeywords(teamId: string, keyword: string){
    return axios.post(`${SVC_ROOT}/keywords/add`, { teamId, keyword })
}

export function API_CALL_DeleteRuleKeywords(teamId: string, keyword: string){
    return axios.post(`${SVC_ROOT}/keywords/delete`, { teamId, keyword })
}

function rb_Create(data: { auditRules: AuditRule}) {
    return axios.post(`${SVC_ROOT}/create`, data);
}

function rb_Edit(data: EditRulePayload) {
    return axios.post(`${SVC_ROOT}/edit`, data);
}

function rb_Delete(data: RuleBasicPayload) {
    return axios.post(`${SVC_ROOT}/delete`, data);
}

function rb_Duplicate(data: RuleBasicPayload) {
    return axios.post(`${SVC_ROOT}/duplicate`, data);
}

function rb_Test(data: any) {
    return axios.post(`${SVC_ROOT}/test`, data);
}

function rb_ChangeStatus(data: RuleStatusPayload, isDefault: boolean) {
    return axios.post(`${SVC_ROOT}${isDefault ?'/default': ''}/change-status`, data);
}

function rb_RunRule(data: DefaultRuleBasicPayload) {
    return axios.post(`${SVC_ROOT}/run-rule`, data);
}

const SVC_DEFAULT_ROOT = `${SVC_ROOT}/default`;

function rb_DEFAULT_Create(data: { auditRules: AuditRule}) {
    return axios.post(`${SVC_DEFAULT_ROOT}/create`, data);
}

function rb_DEFAULT_Edit(data: EditRulePayload) {
    return axios.post(`${SVC_DEFAULT_ROOT}/edit`, data);
}

function rb_DEFAULT_Delete(data: RuleId) {
    return axios.post(`${SVC_DEFAULT_ROOT}/delete`, data);
}

function rb_DEFAULT_Duplicate(data: DefaultRuleBasicPayload) {
    return axios.post(`${SVC_DEFAULT_ROOT}/duplicate`, data);
}

function rb_DEFAULT_Test(data: any) {
    return axios.post(`${SVC_DEFAULT_ROOT}/test`, data);
}

function rb_DEFAULT_ChangeStatus(data: RuleStatusPayload) {
    return axios.post(`${SVC_DEFAULT_ROOT}/change-status`, data);
}

function rb_DEFAULT_RunRule(data: DefaultRuleBasicPayload) {
    return axios.post(`${SVC_DEFAULT_ROOT}/run-rule`, data);
}

export const API_CALL_RuleBuilder = {
    TeamRules: {
        create: rb_Create,
        edit: rb_Edit,
        delete: rb_Delete,
        test: rb_Test,
        changeStatus: rb_ChangeStatus,
        runRule: rb_RunRule,
        duplicate: rb_Duplicate,
    },
    CSDefaultRules: {
        create: rb_DEFAULT_Create,
        edit: rb_DEFAULT_Edit,
        delete: rb_DEFAULT_Delete,
        test: rb_DEFAULT_Test,
        changeStatus: rb_DEFAULT_ChangeStatus,
        runRule: rb_DEFAULT_RunRule,
        duplicate: rb_DEFAULT_Duplicate,
    }
}
