import React, { useState } from 'react';
import { Header, Input, InputOnChangeData } from 'semantic-ui-react';
import { Job, NewJobSetState } from '../index';
import { CSUIButton } from '../../CSUI';
import { assignDotted } from '../../../libraries/types/datastructures';
import Grid from '@material-ui/core/Grid/Grid';
import Divider from '@material-ui/core/Divider/Divider';
import { RetailerRecordList } from '../../../libraries/Util/constants';

interface Step1ComponentProps {
  message?: JSX.Element;
  basic: boolean;
  onClick: () => void;
  job: Job;
  jobNameClick: boolean;
  onChange: NewJobSetState;
  onClear: () => void;
  jobNames: Array<string>;
}

const Step1Component: React.FC<Step1ComponentProps> = (
  props: Step1ComponentProps
) => {
  const [isUniqueName, setIsUniqueName] = useState(true);
  const disabledButtons = [
    'Nordstrom',
    'Harristeeter',
    'Publix',
    'Safeway',
    'Walmart Grocery'
  ].sort().map((store) => (
    <CSUIButton
      key={store}
      slim
      label={`${store}.com`}
      color='grey'
      outline={true}
      disabled={true}
    />
  ));

  const setSelectedRetailer = (retailerId: string) => {
    props.onClear();
    props.onChange((prevState) => ({
      job: {
        ...prevState.job,
        coreJob: {
          ...prevState.job.coreJob,
          retailerInfo: {
            ...prevState.job.coreJob.retailerInfo,
            retailerFieldMap: {},
            retailerId,
          },
        },
        selectedRetailer: prevState.job.retailerRecords[retailerId],
        paste: {
          textarea: [],
          field: 'url',
          selectedDefaultRadio: false
        },
      },
    }));
  };

  const isRetailerSelected = (retailerId: string) =>
    props.job.selectedRetailer && props.job.selectedRetailer.retailerId
      ? props.job.selectedRetailer.retailerId === retailerId
      : false;

  const topActiveButtonKeys = ['amazon', ];
  const restActiveButtonKeys = Object.values(RetailerRecordList)
  .filter(ret => !ret['disabled'] && !topActiveButtonKeys.includes(ret.retailerKey))
  .map(ret => ret.retailerKey)
  .sort()


  const activeButtons = [...topActiveButtonKeys, ...restActiveButtonKeys]
  .map(retKey => {
    const retObj = RetailerRecordList[retKey];
    const label = `${retObj.retailerName}${retObj.isBeta ? " (beta)" : ""}`
    return <CSUIButton
      key={retObj.retailerName}
      slim
      label={label}
      onClick={() => setSelectedRetailer(retObj.retailerId)}
      outline={!isRetailerSelected(retObj.retailerId)}
    />
  });

  const retailerButtons = [
    ...activeButtons,
    //...disabledButtons,
  ];

  const setFirstRetailer = () => {
    const id = Object.values(RetailerRecordList)[0].retailerId;
    if (!isRetailerSelected(id)) {
      setSelectedRetailer(id);
    }
  };

  if (!props.job.selectedRetailer) {
    setFirstRetailer();
  }

  const checkUniqueName = (value) => {
    const isUnique = !props.jobNames.includes(value);
    setIsUniqueName(isUnique)
    props.onChange((prevState) => ({
      view: assignDotted(
        { ...prevState.view },
        'errors.isUniqueName',
        isUnique
      ),
    }));
  }

  return (
    <Grid container direction='column' spacing={4}>
      <Grid item container spacing={2}>
        <Grid item>
          <Header as='h2'>
            <b>What do you want to call this audit job?</b>
            <div style={{ alignSelf: 'start' }} className='requiredStar'>
              *
            </div>
          </Header>
        </Grid>
        <Grid item>
          <Input
            ref={(input) => {
              if (props.jobNameClick) {
                props.onChange((prevState) => ({
                  view: { ...prevState.view, jobNameClick: false },
                }));
              }
              if (input) {
                input.focus();
              }
            }}
            name={'jobName'}
            placeholder={'Enter audit name here'}
            value={props.job.coreJob.jobName ? props.job.coreJob.jobName : ''}
            onChange={(
              e: React.SyntheticEvent<HTMLInputElement>,
              data: InputOnChangeData
            ) => {
              e.persist();
              const jobName = data.value.trimStart();
              checkUniqueName(jobName)
              props.onChange((prevState) => ({
                job: assignDotted(
                  { ...prevState.job },
                  'coreJob.jobName',
                  jobName
                ),
              }));
              props.onChange((prevState) => ({
                view: assignDotted(
                  { ...prevState.view },
                  'errors.saveButton',
                  false
                ),
              }));
            }}
          />
          <p className={`input-error ${!isUniqueName && props.job.coreJob.jobName !== '' && 'active'}`}>Unique name is required!</p>
        </Grid>
      </Grid>
      <Divider flexItem orientation='horizontal' style={{ height: '1px' }} />
      <Grid item>
        <div className='stepContent'>
          <Header as='h2'>
            <b>Select Your Site</b>
          </Header>
          <p>
            Select one site from our growing list of pre-programmed sites below.
          </p>
          <div className='retailerButtons'>{retailerButtons}</div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Step1Component;
