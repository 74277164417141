export function toRecordId(sku: { timestamp: number, skuId: string, retailer: string }): string {
  // (1st 8-characters of skuId)-(3-letter retailer code)-(country code)-(utc date)-(utc hour, min)
  const date = new Date(sku.timestamp);

  const utcMonth = `${date.getUTCMonth()+1}`;
  const month = `${utcMonth.length === 1 ? 0 : ''}${utcMonth}`;

  return `${
    sku.skuId.slice(0,8)
  }-${
    sku.retailer.slice(0,3)
  }-${
    "us"
  }-${
    date.getUTCFullYear()}${month}${date.getUTCDate()
  }-${
    date.getUTCHours()}${date.getUTCMinutes()
  }`;
}

export const SkuRecordTransform = {
    toRecordId
}
