import React from 'react';
import { GlobalState } from '../../libraries/types/ReportUp';
import { firestoreDb } from '../../libraries/Util';
import GlobalStateProvider from '../GlobalStateProvider';
import ReactJson from 'react-json-view';
import './style.scss';

export interface OperationConfig {
  status: 'active' | 'complete';
  opId: string;
  opType: string;
  opUnitCollection: string;
  opUnitStatusField: string;
  opUnitStatusCheckValue: string;
  opUnitStatusCheckCount: number | null;
  opCompleteActions: {
      url: string;
      method: 'get' | 'post';
      payload?: any;
  }[];
  opTimeoutActions: {
      url: string;
      method: 'get' | 'post';
      payload?: any;
  }[];
  metadata?: any;
  timestamp: number;
  progress: {
      initialProgress: boolean;
      tsOfLastProgress: number | null;
      timeout: number | null;
  };
  retryConfig?: {
      limit: number;
      retries: number;
      waitUntilRetry: number;
      retryActions: {
          url: string;
          method: 'get' | 'post';
          payload?: any;
      }[];
  };
}

interface Props {
  globalState: GlobalState;
}

interface State {
  operationsCol: {
    [opId: string]: any;
  };
  operations: {
    [opId: string]: any;
  };
  dataLoading: boolean;
  prettyLoaded: boolean;
}

class OperationsList extends React.Component<Props, State> {
  private firestoreUnsubscribe: {
    [id: string]: () => void
  };

  private loaded: boolean
  private timeout: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.firestoreUnsubscribe = {};

    this.state = {
      operationsCol: {},
      operations: {},
      dataLoading: true,
      prettyLoaded: false
    };
  }

  async componentDidMount() {

    const docSub = (docPath: string) => {
    
      this.firestoreUnsubscribe[docPath] = firestoreDb
        .doc(docPath)
        .onSnapshot((docSnapshot) => {
          this.setState((prevState) => {
            const operations = { ...prevState.operations, [docPath]: docSnapshot.data() };
            // docSnapshot.
            return { operations };
          });
        });
    }
    
    this.firestoreUnsubscribe['operations'] = firestoreDb
      .collection('operations')
      .where("status", "==", "active")
      .onSnapshot((colSnapshot) => {
        this.setState((prevState) => {
          const operations = { ...prevState.operationsCol };
          colSnapshot.docChanges().forEach((change) => {
            const docData = change.doc.data();
            const docPath = (docData.opUnitCollection as string).replace("/statusConfig", "");
            switch (change.type) {
              case 'removed':
                delete operations[docData.opId as string];
                if (this.firestoreUnsubscribe[docPath]) {
                  const unSubFn = this.firestoreUnsubscribe[docPath];
                  delete this.firestoreUnsubscribe[docPath];
                  unSubFn();
                }
                delete operations[docPath];
                break;
              // @ts-ignore
              case 'added':
                docSub(docPath);
              // eslint-disable-next-line no-fallthrough
              case 'modified':
              default:
                operations[
                  docData.opId as string
                ] = change.doc.data();
                break;
            }
          });
          return { operationsCol: operations, dataLoading: false };
        });
      });
  }

  componentWillUnmount() {
    Object.keys(this.firestoreUnsubscribe).forEach(id => {
      this.firestoreUnsubscribe[id]();
      delete this.firestoreUnsubscribe[id];
    });
  }

  render() {
    return <ReactJson src={this.state.operationsCol} />
  }
}

export default GlobalStateProvider(OperationsList);
