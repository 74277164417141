import {
    withStyles, Theme
  } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";

export const CSUIToolTip = withStyles((theme: Theme) => ({
    tooltip: {
      //backgroundColor: '#f5f5f9',
      //color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      //border: '1px solid #dadde9',
    },
  }))(Tooltip);