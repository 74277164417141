import React from 'react';
import { Job, NewJobSetState, NewJobState } from '../';
import { Radio } from 'semantic-ui-react';
import { DropFilesEventHandler } from 'react-dropzone';
import {
  CsvData,
  RandomId,
  CoreJob,
  Objectentries,
  ObjectKeyValuePairs,
  appendToTextarea,
} from '../../../libraries/types/datastructures';
import PreviewTable from '../../DataMapping/PreviewTable';
import { CSUIButton } from '../../CSUI';
import Grid from '@material-ui/core/Grid/Grid';
import Divider from '@material-ui/core/Divider/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize/TextareaAutosize';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import StepLabel from '../../Stepper';
import { debounce, set } from 'lodash';
import { RetailerNameRenderFns, RetailerRecordList, RetailerMetadata } from '../../../libraries/Util/constants';

interface Props {
  message?: JSX.Element;
  basic: boolean;
  job: Job;
  reservedFields: {
    [x: string]: { set: (fieldname: string) => void; unset: () => void };
  };
  reservedText: string;
  goBackButton: (message: string, tab: number) => JSX.Element;
  selectedRetailerName: string;
  onDrop: DropFilesEventHandler;
  onErrorsCheck: (idFieldName: string, fieldName: string) => void;
  areThereUidErrors: boolean;
  errors: {
    [idFieldName: string]: {
      tests: {
        notLengthTwelve?: string[];
        repeated: {
          key: string | number;
          value: number;
        }[];
        notNumeric: string[];
      };
      idFieldName: string;
      fieldName: string;
    };
  };
  onChange: NewJobSetState;
  onClear: () => void;
}

const greenSpan = (num: string | number = 0) => (
  <span
    style={{
      color: num ? '#4FDA03' : 'darkred',
      fontWeight: 'bold',
    }}>
    {num}
  </span>
);

const idRetMapper = (id: string, type: 'upc' | 'toolId' | 'url' | 'asin' | 'productId') => {
  let fieldName = '';
  switch (type) {
    case 'upc':
      fieldName = 'UPC (GTIN-12)';
      break;
    case 'toolId':
      fieldName = 'Tool ID (Item ID)';
      break;
    case 'asin':
    case 'url':
      fieldName = type.toLocaleUpperCase();
      break;
    case 'productId':
      fieldName = type
  }
  return {
    [id]: {
      fieldName: id,
      retailerFieldDefinition: {
        fieldName,
        fieldType: { compared: true, fieldTypeName: 'UID', reorderable: true },
      },
    },
  } as CoreJob['retailerInfo']['retailerFieldMap'];
};

const pasteMapping: {
  [x: string]: CoreJob['retailerInfo']['retailerFieldMap'];
} = {
  upc: idRetMapper('upc', 'upc'),
  toolId: idRetMapper('toolId', 'toolId'),
  url: idRetMapper('url', 'url'),
  asin: idRetMapper('asin', 'asin'),
  productId: idRetMapper('productId', 'productId'),
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0, 2),
  },
  content: {
    padding: theme.spacing(0, 2),
  },
  textarea: {
    width: '100%',
    borderRadius: '5px',
    borderColor: theme.palette.grey[200],
    resize: 'none',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  radio: {
    marginLeft: 'unset',
  },
  reviewHeader: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  skuTotal: {
    marginLeft: theme.spacing(1),
  },
}));

const insertSampleSkus = (retailer: string, field: "upc" | "toolId" | "url" | "asin" | 'productId') => {

  const retName = RetailerNameRenderFns.keyName(retailer);
  const samples = RetailerRecordList[retName].productSamples[field] as string[]

  const sampleString = samples.join("\n");
  appendToTextarea("newJobLoadSkusTextarea", sampleString);
  return { target: { value: sampleString}} as React.ChangeEvent<HTMLTextAreaElement>;
}

const uidErrorDisplay = (
  uidErrorObj: NewJobState['view']['errors']['uidErrors']['any'],
  fixFns: {
    onRemoveDuplicates?: (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => void,
    onAddLeadingZeros?: (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => void,
    onRemoveMalformedUrls?: (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => void,
    onRemoveIncorrectLengthUIDs?: (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any'], correctLength: number) => void,
  },
  pasted?: boolean
) => {
  const {
    onRemoveDuplicates,
    onAddLeadingZeros,
    onRemoveMalformedUrls,
    onRemoveIncorrectLengthUIDs
  } = fixFns;
  const columnType = (uidErrorObj.idFieldName === 'upc')
      ? "UPC (GTIN-12)"
      : (uidErrorObj.idFieldName === 'toolId')
          ? "Tool ID (Item ID)"
          : uidErrorObj.idFieldName.toLocaleUpperCase();
  const testDisplayConfig = {
      notUrlFormat: {
          label: 'Mal-formed URLs',
          desc: 'These URLs do not start with "http(s)://":',
          render: (item) => item
      },
      notAlphaNumeric: {
          label: 'Non-Alphanumeric Characters',
          desc: 'These contain non-alphanumeric characters:',
          render: (item) => item
      },
      notNumeric: {
          label: 'Non-Digit Characters',
          desc: 'These contain non-digit characters:',
          render: (item) => item
      },
      notLengthTen: {
          label: 'ASIN not 10 characters long',
          desc: 'The following ASINs do not contain 10 characters: ',
          render: (item) => item
      },
      notLengthTwelve: {
          label: 'UPC not 12 digits long',
          desc: 'The following UPCs do not contain 12 digits, which may be caused by (1) Excel removing leading zeros or (2) you pasted the retailer ID but the UPC button was selected: ',
          render: (item) => item
      },
      repeated: {
          label: 'Duplicated unique UIDs/URLs',
          desc: 'Each SKU should have an unique ID or URL. The following SKU ID(s) are repeated: ',
          render: (item) => `${item.key} (${item.value} times)`
      },
 };

  const getResolutionOptions = (testName: keyof typeof testDisplayConfig) => {
      switch (testName) {
          case "repeated":
              return <div>
                  <ul>
                      <li>Review, update and re-upload your sheet</li>
                      <li>Or, automatically...</li>
                  </ul>
                  <CSUIButton onClick={() => onRemoveDuplicates(uidErrorObj)} slim={true} color='green'>Remove duplicates</CSUIButton>
              </div>;
          case "notLengthTwelve":
              return <div>
                  <ul>
                      <li>Review, update and re-upload your sheet</li>
                      <li>Or, automatically...</li>
                  </ul>
                  <CSUIButton onClick={() => onAddLeadingZeros(uidErrorObj)} slim={true} color='green'>Add leading zeros</CSUIButton>
              </div>;
          case "notLengthTen":
            return <div>
                <ul>
                    <li>Review, update and re-upload your sheet</li>
                    <li>Or, re-paste with corrected characters</li>
                    <li>Or, automatically...</li>
                </ul>
                <CSUIButton onClick={() => onRemoveIncorrectLengthUIDs(uidErrorObj, 10)} slim={true} color='green'>Remove Error ASINs</CSUIButton>
            </div>;
          case "notAlphaNumeric":
          case "notNumeric":
              return <div>
                  <ul>
                      <li>Review, update and re-upload your sheet</li>
                      <li>Or, re-paste with corrected characters</li>
                  </ul>
              </div>;
          case "notUrlFormat":
              return <div>
                  <ul>
                      <li>Review, update and re-upload your sheet</li>
                      <li>Or, add https:// or replace http:// with https:// at the beginning of each URL</li>
                      <li>Or, automatically...</li>
                  </ul>
                  <CSUIButton onClick={() => onRemoveMalformedUrls(uidErrorObj)} slim={true} color='green'>Remove mal-formed URLs</CSUIButton>
              </div>;
      }
  }
  const testRenderer = (testName: keyof typeof testDisplayConfig, testItems: any[]) => {
      // debugger;
      const displayConfig = testDisplayConfig[testName];
      return testItems.length ? <div className="error-box">
          <b>Your Selected UID: </b>{columnType}
          <p className="error">{displayConfig.label}</p>
          <p>There {testItems.length > 1 ? `is ${testItems.length} instance` : `are ${testItems.length} instances`} of this error.</p>
          <p>{displayConfig.desc} <br /> <br /> {testItems.map(displayConfig.render).join(', ')}</p>
          <p><b>Data Error Location:</b> {pasted?"Pasted List":`Uploaded Column Name: ${uidErrorObj.idFieldName}`}</p>
          <b>Resolution Options</b>
          {getResolutionOptions(testName)}
      </div> : null;
  }
  return <div key={uidErrorObj.idFieldName}>
      {ObjectKeyValuePairs(uidErrorObj.tests).map(testPair => testRenderer(testPair.key, testPair.value)).filter(x => x)}
  </div>
}

const scrollToLoadClearButtons = debounce(
  () => {
    const div = window.document.getElementById("step2LoadClearContainer") as HTMLDivElement;
    div.scrollIntoView();
  },
  100
)

const InsertProductsIds = ({
  selectedRetailerName,
  job,
  onChange,
  onErrorsCheck,
  onClear
}: Props) => {
  const classes = useStyles();
  const selectedDefaultRadio = job.paste['selectedDefaultRadio'];
  const {
    paste: { field },
  } = job;

  const handleChangeRadio = (e, { value }) => {
    if (selectedDefaultRadio) {
      onClear();
    }

    onChange((prevState) => ({
      job: {
        ...prevState.job,
        paste: {
          ...prevState.job.paste,
          field: value,
          ...(selectedDefaultRadio ? {selectedDefaultRadio: false} : {})
        },
      },
    }));

  };

  const handleSelectingRadio = () => {
    onChange((prevState) => ({
      job: {
        ...prevState.job,
        paste: {
          ...prevState.job.paste,
          field: 'url',
          selectedDefaultRadio: true
        },
      },
    }));
    handleChangeTextArea(insertSampleSkus(selectedRetailerName, 'url'));
  }

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const ids = (e.target.value as string)
      .trim()
      .split(/\s*\n\s*/g)
      .map((x) => x.trim())
      .filter((x) => x);
    onChange((prevState) => ({
      job: {
        ...prevState.job,
        paste: {
          ...prevState.job.paste,
          textarea: ids
        },
      },
    }));
  };

  const onClickLoad = () => {
    if (job.paste) {
      onChange(
        (prevState) => ({
          job: {
            ...prevState.job,
            coreJob: {
              ...prevState.job.coreJob,
              jobData: {
                datasetId: RandomId(),
                fileName: `(pasted ${field === 'toolId' ? 'Tool ID' : field.toLocaleUpperCase()
                  }s)`,
                masterCsv: {
                  data: prevState.job.paste!.textarea.map((u) => ({
                    [field]: u,
                  })),
                  meta: {
                    fields: [field],
                  },
                },
                skuIdMap: {
                  ...prevState.job.coreJob.jobData.skuIdMap,
                  [field]: field,
                },
              },
              retailerInfo: {
                ...prevState.job.coreJob.retailerInfo,
                retailerFieldMap: pasteMapping[field],
              },
            },
            loadMethod: 'paste',
          },
          view: {
            ...prevState.view,
            errors: {
              ...prevState.view.errors,
              uidErrors: undefined,
            },
          },
        }),
        () => onErrorsCheck(field, field)
      );
      const div = window.document.getElementById("step2ReviewDataLabel") as HTMLDivElement;
      div.scrollIntoView();
    }
  };

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      className={classes.container}
    >
      <Grid item>
        <StepLabel
          numberText={'1'}
          label='Select one of the options for this retailer and paste them below'
        />
      </Grid>
      <Grid item>
        <Grid container direction='column' className={classes.content}>
          <Grid item>
            {!RetailerMetadata.retailersWithDisabledUrls.includes(selectedRetailerName) && <FormControlLabel
              className={classes.radio}
              value='url'
              control={
                <Radio checked={field === 'url' && !selectedDefaultRadio} onChange={handleChangeRadio} />
              }
              label='URL'
            />}
          </Grid>
          <Grid item>
            {RetailerMetadata.retailersWithProductId.toolId.includes(selectedRetailerName) && (
              <FormControlLabel
                value='toolId'
                className={classes.radio}
                control={
                  <Radio
                    checked={field === 'toolId' && !selectedDefaultRadio}
                    onChange={handleChangeRadio}
                  />
                }
                label='Walmart Tool ID'
              />
            )}
          </Grid>
          <Grid item>
            {RetailerMetadata.retailersWithProductId.asin.includes(selectedRetailerName) && (
              <FormControlLabel
                value='asin'
                className={classes.radio}
                control={
                  <Radio
                    checked={field === 'asin' && !selectedDefaultRadio}
                    onChange={handleChangeRadio}
                  />
                }
                label='ASIN'
              />
            )}
          </Grid>
          <Grid item>
            {RetailerMetadata.retailersWithProductId.id.includes(selectedRetailerName) && (
              <FormControlLabel
                value='productId'
                className={classes.radio}
                control={
                  <Radio
                    checked={field === 'productId' && !selectedDefaultRadio}
                    onChange={handleChangeRadio}
                  />
                }
                label='Product ID'
              />
            )}
          </Grid>
          <Grid item>
            {!RetailerMetadata.retailersWithDisabledUrls.includes(selectedRetailerName) && <FormControlLabel
              className={classes.radio}
              //onClick={() => handleChangeTextArea(insertSampleSkus(selectedRetailerName, field))}
              control={
                <Radio
                  checked={selectedDefaultRadio}
                  onChange={handleSelectingRadio}
                />
              }
              label={`Insert sample ${selectedRetailerName} URLs`}
            />}
          </Grid>
          <Grid item>
            <TextareaAutosize
              rowsMin={4}
              id={"newJobLoadSkusTextarea"}
              className={classes.textarea}
              placeholder={`Input ${field === 'toolId' ? 'Tool ID' : field === 'productId' ? 'Product ID' : field.toLocaleUpperCase()
                }s (one per line)`}
              onChange={handleChangeTextArea}
              onPaste={scrollToLoadClearButtons}
              //value={textarea.join(' \n')}
            />
          </Grid>
          <Grid item id="step2LoadClearContainer" className={classes.buttonContainer}>
            <CSUIButton slim color='purple' onClick={onClickLoad}>
              Load
            </CSUIButton>
            <CSUIButton slim color='grey' onClick={onClear}>
              Clear
            </CSUIButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReviewData = (props: Props) => {
  const { job, onClear } = props;
  const {
    coreJob: {
      jobData: { masterCsv },
    },
  } = job;

  const classes = useStyles();
  /* const idFieldName = props.job.paste.field;
  const fieldName = idFieldName; */
  const _removeDuplicates = (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => {
    const {idFieldName, fieldName} = uidErrorObj;
    const flattenedList: string[] = props.job.coreJob.jobData.masterCsv.data.map((element: { [property: string]: string }) => element[fieldName]);
    props.onChange(
      prevState => set(
        prevState,
        "job.coreJob.jobData.masterCsv.data",
        props.job.coreJob.jobData.masterCsv.data.filter((element: { [property: string]: string }, index: number) => flattenedList.indexOf(element[fieldName]) === index)
      ),
      () => props.onErrorsCheck(idFieldName,fieldName)
    );
  };
  const _removeMalformedUrls = (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => {
    const {idFieldName, fieldName} = uidErrorObj;
    props.onChange(
      prevState => set(
        prevState,
        "job.coreJob.jobData.masterCsv.data",
        props.job.coreJob.jobData.masterCsv.data.filter((element: { [property: string]: string }, index: number) => (/^(ftp|http|https):\/\/.+$/g).test(element[fieldName]))
      ),
      () => props.onErrorsCheck(idFieldName,fieldName)
    );
  };
  const _removeIncorrectLengthUIDs = (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any'], correctLength: number) => {
    const {idFieldName, fieldName} = uidErrorObj;
    props.onChange(
      prevState => set(
        prevState,
        "job.coreJob.jobData.masterCsv.data",
        props.job.coreJob.jobData.masterCsv.data.filter((element: { [property: string]: string }, index: number) => element[fieldName].length === correctLength)
      ),
      () => props.onErrorsCheck(idFieldName,fieldName)
    );
  };
  const _addLeadingZeros = (uidErrorObj: NewJobState['view']['errors']['uidErrors']['any']) => {
    const {idFieldName, fieldName} = uidErrorObj;
    props.onChange(
      prevState => set(
        prevState,
        "job.coreJob.jobData.masterCsv.data",
        props.job.coreJob.jobData.masterCsv.data.map((element: { [property: string]: string }, index: number) => {
          if (element.hasOwnProperty("upc") && element.upc.length !== 12) {
            return { ...element, upc: `${Array.from(Array(12 - element.upc.length).keys()).map((value: number) => '0').join('')}${element.upc}` };
          } else {
            return element;
          }
        })
      ),
      () => props.onErrorsCheck(idFieldName,fieldName)
    );
  }

  return (
    <Grid container direction='column'>
      <Grid item id="step2ReviewDataLabel" style={{marginBottom: 10}}>
        <StepLabel
          numberText={'2'}
          label='Review sample of loaded data, verify accuracy, and fix any errors'
        />
      </Grid>
      <Grid item>
        {(!props.areThereUidErrors) ? null : <div style={{ marginBottom: 10, marginLeft: 29 }}>

          <p style={{ color: 'red' }}>
            Let's fix these data issues before proceeding...
          </p>

          <div>
            {Objectentries(props.errors).map(fieldErrorObj => uidErrorDisplay(
              fieldErrorObj,
              { onRemoveDuplicates: _removeDuplicates,
                onAddLeadingZeros: _addLeadingZeros,
                onRemoveMalformedUrls: _removeMalformedUrls,
                onRemoveIncorrectLengthUIDs: _removeIncorrectLengthUIDs},
              props.job.loadMethod === 'paste'
            ))}
          </div>
        </div>}
      </Grid>
      <Grid item>
        <Grid container direction='column' className={classes.content}>
          <Grid container direction='row'>
            <Grid item className={classes.reviewHeader}>
              <div>SKUs</div>
              <div className={classes.skuTotal}>
                {greenSpan(masterCsv.data?.length)}
              </div>
            </Grid>
            {(masterCsv.data?.length && masterCsv.data?.length > 50) ? <Grid item style={{marginLeft: 10}}>
              <CSUIButton slim color='grey' onClick={onClear}>
                Clear
              </CSUIButton>
            </Grid> : null}
          </Grid>
          <Grid item>
            <PreviewTable
              previewData={
                {
                  papa: job.coreJob.jobData.masterCsv,
                  fileName: job.coreJob.jobData.fileName,
                } as CsvData
              }
              pastedData={job.loadMethod === 'paste'}
              hideHeader
              doNotDisplay={
                !masterCsv.data.length ||
                !masterCsv.meta.fields ||
                !masterCsv.meta.fields.length
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UploadSKU: React.FC<Props> = (props: Props) => (
  <Grid
    id='stepContent'
    container
    spacing={2}
    wrap={"nowrap"}
    direction="row"
  >
    <Grid item>
      <InsertProductsIds {...props} />
    </Grid>
    <Divider orientation='vertical' flexItem />
    <Grid item>
      <ReviewData {...props} />
    </Grid>
  </Grid>
);

export default UploadSKU;
