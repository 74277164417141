import { makeAutoObservable } from "mobx";
import { WorkflowStore, workflowStore } from "./workflow";
import { DashboardStore, dashboardStore } from "./dashboard";
import { RulesStore, rulesStore } from "./rules";

export class RootStore {
    workflowStore: WorkflowStore;
    rulesStore: RulesStore;
    dashboardStore: DashboardStore;

    constructor() {
        makeAutoObservable(this);
        this.workflowStore = workflowStore;
        this.rulesStore = rulesStore;
        this.dashboardStore = dashboardStore;
    }
}

export const rootStore = new RootStore();