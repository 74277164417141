import * as React from "react";
import CSUITable, { CSUIMUIXGrid, RT_CellInfo, RT_Column } from '../../../components/CSUI/CSUITable';
import {
  capitalizeStr,
  copyToClipboard,
  CoreUserAccount,
  getCoreFromUserAccount,
  Objectkeys,
  retailerNameRender,
} from "../../../libraries/types/datastructures";
import "./style.scss";
import { NavLink } from "react-router-dom";
import { CSUIIcon, CSUISegment, CSUISwitch } from "../../../components/CSUI";
import DeleteJobConfirmation from "../DeleteJobConfirmation";
import JobErrorDialog from "../JobErrorDialog";
import { PlatformCatalogJob } from "../../../components/NewJob/data";
import { firestoreDb } from "../../../libraries/Util";
import { GlobalState } from "../../../libraries/types/ReportUp";
import { API_CALL_deleteCatalogJob } from "../../../libraries/Util/apiClient";
import { TeamBalance } from "../../../libraries/DataService";
import { history } from "../../../libraries/Util/url";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider/Divider';
import FileCopy from "@material-ui/icons/FileCopy";
import Pagination from '../../../components/Pagination';
import { capitalizeFirstLetter } from "../../../libraries/Util/string";
import { CSUIToolTip } from "../../../components/CSUI/CSUIToolTip";

interface NewJobsTableProps extends WithStyles<typeof styles> {
  globalState: GlobalState;
  type: "one-time" | "recurring" | "draft";
  showStatusConfig?: boolean;
  teamBalance?: TeamBalance;
}

interface State {
  teamMemberList: { [userId: string]: CoreUserAccount };
  teamJobList: { [x: string]: any };
  data: { [x: string]: PlatformCatalogJob };
  dataLoading: boolean;
  expanded: { [rowId: string]: boolean };
  page: number;
  jobToDelete: PlatformCatalogJob;
  isModalOpen: string;
  modalSkusToShow: string[];
  errorJob: PlatformCatalogJob;
  type: "one-time" | "recurring" | "draft";
  selectedJobId: string;
}

const BrandJobHeaderNameMap = {
  dateOpened: "Opened",
  dateClosed: "Closed",
  lastRun: "Last Run",
  frequency: "Frequency",
  owner: "Owner",
  retailerId: "Retailer",
  jobName: "Audit Name",
  skus: "SKUs",
  status: "Status",
  success: "✔",
  missing: "X",
  errors: "E",
  // credits: 'Est. Credits/mo',
  // creditsUsed: 'Credits Used',
  statusDropdown: "Status Config",
  actions: "Actions",
};

const renderFrequencyColumn = (job: PlatformCatalogJob, globalState: GlobalState) => {
  const { schedule } = job;

  if (!schedule.isRecurring) {
    return "One-time";
  }

  const { config } = schedule;

  if (config.timeUnit === "day" && config.every === 1) {
    return "Daily";
  }

  if (config.timeUnit === "subscription" as any) {
    const planType = capitalizeFirstLetter(globalState?.teamJobData?.planType || "");

    return  planType ? planType : "Subscription";
  }

  return `Every ${config.every} ${config.timeUnit}s`;
};

const renderJobStatus = (
  job: PlatformCatalogJob,
  onJobStatusChange: (job: PlatformCatalogJob) => void,
  onErrorClick: (job: PlatformCatalogJob) => void
) => {
  if (job.schedule.isRecurring && job.status.state !== "Draft") {
    return (
      <div>
        <CSUISwitch
          className="csui_switch_green"
          labelLeft=""
          labelRight=""
          checked={job.status.enabled}
          onChange={() => onJobStatusChange(job)}
        />
      </div>
    );
  } else {
    const stageLabel =
      job.status.state === "Error" ? (
        <span style={{ cursor: "pointer" }} onClick={() => onErrorClick(job)}>
          Error
        </span>
      ) : (
          job.status.state
        );

    return (
      <div
        style={{
          textAlign: "center",
          color: job.status.state.toLowerCase() === "error" ? "red" : "inherit",
        }}
      >
        {stageLabel}
      </div>
    );
  }
};

const updateCatalogData = async (jobId: string, state: string) => {
  history.push(state === "Draft" ? "#" : `/catalog?jobId=${jobId}`);
};

const renderJobActions = (
  job: PlatformCatalogJob,
  onDelete: (job: PlatformCatalogJob) => void,
  type: string
) => {
  return (
    <div style={{ textAlign: "center" }}>
      <NavLink
        className="cs-table-icon-link small"
        to={`/jobs/new?jobId=${job.jobId}`}
      >
        <CSUIIcon icon="settings" />
      </NavLink>

      {// eslint-disable-next-line jsx-a11y/anchor-is-valid
      }<a
        onClick={() => updateCatalogData(job.jobId, job.status.state)}
        className={`cs-table-icon-link ${(job.status.state !== "Complete" && type === "one-time") ||
          job.status.state === "Draft"
          ? "disabled"
          : ""
          }`}
      >
        <CSUIIcon
          icon="show"
          style={{
            opacity:
              (job.status.state !== "Complete" && type === "one-time") ||
                job.status.state === "Draft"
                ? 0.5
                : 1,
          }}
        />
      </a>

      <NavLink className="cs-table-icon-link" to="#">
        {/* <CSUIIcon icon="dash statusaccuracy" style={{ opacity: job.status.state.toLowerCase() === "complete" ? 1 : 0.5 }} /> */}
        <CSUIIcon icon="dash statusaccuracy" style={{ opacity: 0.5 }} />
      </NavLink>

      <button
        className="cs-table-icon-button small"
        onClick={() => onDelete(job)}
      >
        <CSUIIcon icon="delete" />
      </button>
    </div>
  );
};

const renderJobStatusSwitch = (
  job: PlatformCatalogJob,
  onJobStatusStringChange: (
    job: PlatformCatalogJob,
    statusString: PlatformCatalogJob["status"]["state"]
  ) => void
) => {
  const options = ["Draft", "Error", "Processing", "Scheduled", "Complete"].map(
    (value) => (
      <option key={value} value={value}>
        {value}
      </option>
    )
  );

  const selectNode = (
    <select
      value={job.status.state}
      onChange={(event) => {
        onJobStatusStringChange(job, event.target.value as any);
      }}
    >
      {options}
    </select>
  );

  return <div>{selectNode}</div>;
};

const calculateCredits = (job: PlatformCatalogJob) => {
  let factor = 1;
  if (job.schedule.isRecurring) {
    switch (job.schedule.config.timeUnit) {
      case "day":
        factor = 30;
        break;
      case "week":
        factor = 4;
        break;
      case "month":
      default:
    }
  }
  const errorsLength = job.errorSkus ? job.errorSkus.length : 0;
  const missingSkusLength = job.missingSkus ? job.missingSkus.length : 0;

  return (job.skus.length - errorsLength - missingSkusLength) * factor;
};

const BrandJobDisplayConfig = {
  headerNameMap: BrandJobHeaderNameMap,
  widths: (headerName: keyof typeof BrandJobHeaderNameMap) => {
    switch (headerName) {
      case "skus":
        return 80;
      case "errors":
      case "success":
      case "missing":
        return 50;
      case "frequency":
      case "lastRun":
      case "dateOpened":
      case "dateClosed":
      case "retailerId":
        return 108;
      case "owner":
        return 140;
      case "status":
        return 120;
      case "actions":
        return 175;
      case "jobName":
        return 300;
      default:
        return undefined;
    }
  },
};

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 1000,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
    },
    body: {
      maxHeight: 400,
      overflowY: 'auto',
    },
  });

const RenderJobsTable = ({ data, columns }: any) => {
  if (data === undefined) {
    return null
  }
  return (
    <CSUITable
      columnConfig={columns}
      rows={data}
      getRowId={row => row}
      rowConfig={{}}
      maxWidth={1800}
    />
  )
}

class NewJobsTable extends React.Component<NewJobsTableProps, State> {
  constructor(props: NewJobsTableProps) {
    super(props);
    this.state = {
      type: this.props.type,
      isModalOpen: "",
      teamJobList: {},
      data: {},
      dataLoading: true,
      expanded: {},
      page: 0,
      jobToDelete: null,
      errorJob: null,
      teamMemberList: {},
      modalSkusToShow: [],
      selectedJobId: null,
    };
  }

  componentDidMount() {
    const coreUser = getCoreFromUserAccount(this.props.globalState.activeUser);

    firestoreDb
      .collection("UserAccount")
      .where("teamId", "==", coreUser.teamId)
      .get()
      .then((colSnapshot) => {
        const teamMemberList = Object.assign(
          {},
          ...colSnapshot.docs.map((doc) => ({ [doc.id]: doc.data() }))
        );
        this.setState({ teamMemberList });
      });
  }

  componentWillUnmount() { }

  // tslint:disable-next-line:no-any

  dataFilter = () => {
    // return Object.keys(this.state.data)
    const data = this.props.globalState.teamJobData.teamJobs;

    /****/
    //const {data} = this.state;
    return Object.keys(data)
      .filter((key) => {
        if (!!!data[key]) {
          return false;
        }
        const job = data[key];
        if (this.props.type === "draft") {
          return job.status.state === "Draft";
        } else {
          return job.status.state === "Draft"
            ? false
            : this.props.type === "recurring"
              ? data[key].schedule.isRecurring
              : !data[key].schedule.isRecurring;
        }
      })
      .sort(
        (a, b) =>
          data[b].schedule.config.startTimestamp -
          data[a].schedule.config.startTimestamp
      );
  };

  onErrorJobClick = (errorJob: PlatformCatalogJob) => {
    this.setState({ errorJob });
  };

  selectJobToDelete = (jobToDelete: PlatformCatalogJob) => {
    this.setState({ jobToDelete });
  };

  onJobStatusChange = (job: PlatformCatalogJob) => {
    firestoreDb
      .collection("CatalogJobs")
      .doc(job.jobId)
      .update({ "status.enabled": !job.status.enabled });
  };

  onJobStatusStringChange = (
    job: PlatformCatalogJob,
    statusString: PlatformCatalogJob["status"]["state"]
  ) => {
    firestoreDb
      .collection("CatalogJobs")
      .doc(job.jobId)
      .update({ "status.state": statusString });
  };

  getHeaderLabelString = (headerName: string) => {
    return BrandJobDisplayConfig.headerNameMap[headerName];
  };

  columnsToDisplay = () => {
    const excludeColumns: Array<
      keyof typeof BrandJobDisplayConfig.headerNameMap
    > = [];

    switch (this.props.type) {
      case "draft":
        excludeColumns.push(
          "dateClosed",
          "lastRun",
          // 'credits', 'creditsUsed',
          "success",
          "missing",
          "errors"
        );
        break;
      case "recurring":
        excludeColumns.push(
          "dateOpened",
          "dateClosed"
          // 'creditsUsed'
        );
        break;
      case "one-time":
        excludeColumns.push(
          "lastRun",
          "frequency"
          // 'credits'
        );
        break;
    }

    return Objectkeys(BrandJobDisplayConfig.headerNameMap)
      .filter((headerName) =>
        headerName === "statusDropdown" ? this.props.showStatusConfig : true
      )
      .filter((headerName) => excludeColumns.indexOf(headerName) === -1);
  };

  columns = () => {
    const { teamJobs } = this.props.globalState.teamJobData;
    // debugger;

    const isCenteredColumnFn = (
      str: keyof typeof BrandJobDisplayConfig["headerNameMap"]
    ) => {
      const columns: Array<typeof str> = [];
      columns.push(
        "status",
        "actions",
        "skus",
        // 'credits', 'creditsUsed',
        "success",
        "missing",
        "errors"
      );
      return columns.indexOf(str) !== -1;
    };

    const isRedColumnFn = (
      str: keyof typeof BrandJobDisplayConfig["headerNameMap"]
    ) => {
      const columns: Array<typeof str> = [];
      columns.push("missing", "errors");
      return columns.indexOf(str) !== -1;
    };

    const isGreenColumnFn = (
      str: keyof typeof BrandJobDisplayConfig["headerNameMap"]
    ) => {
      const columns: Array<typeof str> = [];
      columns.push("success");
      return columns.indexOf(str) !== -1;
    };

    return this.columnsToDisplay().map((headerName) => {
      const isCenteredColumn = isCenteredColumnFn(headerName);
      const isRedColumn = isRedColumnFn(headerName);
      const isGreenColumn = isGreenColumnFn(headerName);

      return {
        Header: (
          <div
            style={{
              borderBottom: "1px solid indigo",
              padding: "5px 0",
              textAlign: isCenteredColumn ? "center" : "left",
              color: isRedColumn ? "red" : isGreenColumn ? "#4FDA03" : "",
            }}
          >
            {this.getHeaderLabelString(headerName)}
          </div>
        ),
        // expander: headerName === 'accuracy',
        headerStyle: {
          // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
          fontWeight: "bold",
          borderRightColor: "transparent",
          ...(isCenteredColumn ? {} : { textAlign: "left" }),
        },
        width: BrandJobDisplayConfig.widths(headerName),
        ...(isCenteredColumn
          ? { style: { textAlign: "center" } }
          : { style: { textAlign: "left" } }),
        id: headerName,
        // accessor: (d: object) => d[headerName],
        ...{
          // [headerName === 'accuracy' ? 'Expander' : 'Cell']
          Cell: (cellInfo: RT_CellInfo) =>
            this.renderer(
              teamJobs[cellInfo.original],
              headerName as keyof typeof BrandJobDisplayConfig.headerNameMap,
              this.selectJobToDelete,
              this.onJobStatusChange,
              this.onJobStatusStringChange,
              this.onErrorJobClick
            ),
        },
      } as RT_Column;
    });
  };

  deleteJob = async (jobToDelete: PlatformCatalogJob, deleteData: boolean) => {
    this.setState({ jobToDelete: null });
    await API_CALL_deleteCatalogJob(jobToDelete.jobId);
    // alert("Job deleted!");
  };

  tableHeader = () => {
    let prefix = "";
    switch (this.props.type) {
      case "draft":
        prefix = "Draft";
        break;
      case "one-time":
        prefix = "One-Time";
        break;
      case "recurring":
        prefix = "Recurring";
        break;
    }

    return `${prefix} Audits`;
  };

  balanceInfo = () => {
    if (this.props.type !== "recurring") {
      return null;
    }

    const fmtNum = (num: any) =>
      typeof num === "number" ? num.toLocaleString() : "--";

    const label1 = (
      <span className="label">
        Credits Used <span>(mo.)</span>:
      </span>
    );
    const label2 = <span className="label">Balance:</span>;

    const creditUsedJobs = Object.values(
      this.props.globalState.teamJobData.teamJobs
    )
      .filter((job) => job && job.schedule.isRecurring)
      .map((job) => calculateCredits(job));

    let creditUsed = 0; // There could be no Recurring (for example, on initializing)
    if (creditUsedJobs.length) {
      creditUsed = creditUsedJobs.reduce((accum, current) => accum + current);
    }

    const { balance } =
      this.props.teamBalance || this.props.globalState.credits;

    return (
      <div className="jobTableBalanceInfo">
        <div>
          {label1} {fmtNum(creditUsed)}{" "}
        </div>
        <div>
          {label2} {fmtNum(balance)}{" "}
        </div>
      </div>
    );
  };

  renderer = (
    job: PlatformCatalogJob,
    headerName: keyof typeof BrandJobHeaderNameMap,
    onDelete: (job: PlatformCatalogJob) => void,
    onJobStatusChange: (job: PlatformCatalogJob) => void,
    onJobStatusStringChange: (
      job: PlatformCatalogJob,
      statusString: PlatformCatalogJob["status"]["state"]
    ) => void,
    onErrorClick: (job: PlatformCatalogJob) => void
  ) => {
    let jsx;
    const errorsLength = job.errorSkus ? job.errorSkus.length : 0;
    const missingSkusLength = job.missingSkus ? job.missingSkus.length : 0;

    const renderSkuNumbers = (num: number, type: string) => {
      let modalSkusToShow = [];
      if (type === 'missing') {
        modalSkusToShow = job.missingSkus || [];
      } else if (type === 'errors') {
        modalSkusToShow = job.errorSkus || [];
      }

      const div = <div style={{ cursor: type !== 'success' ? 'pointer' : 'auto' }} onClick={() => this.setState({ isModalOpen: type === 'success' ? "" : type, modalSkusToShow, selectedJobId: job.jobId })}>{num}</div>;

      switch (job.status.state) {
        case "Scheduled":
          return (job.schedule?.metadata?.numOfExecs) 
          ? div
          : " - " 
        case "Complete":
        case "Error":
          return div;
        default:
          return " - ";
      }
    };

    const renderDate = (ts: number, returnUndefined?: boolean) => {
      if (ts) {
        const dateObj = new Date(ts);
        return (
          <CSUIToolTip title={dateObj.toLocaleTimeString()} placement="top">
            <span>{dateObj.toLocaleDateString()}</span>
          </CSUIToolTip>
        );
      } else {
        return returnUndefined ? undefined : " - ";
      }
    };

    switch (headerName) {
      case "lastRun":
        jsx = renderDate(job.status.lastRun, true) || renderDate(job.schedule.metadata.previousExecTimestamp);
        break;
      case "dateClosed":
        jsx =
          job.status.state === "Complete"
            ? renderDate(job.status.lastRun, true) || renderDate(job.schedule.metadata.previousExecTimestamp, true) || renderDate(job.schedule.config.startTimestamp)
            : " - ";
        break;
      case "dateOpened":
        jsx = renderDate(job.schedule.config.startTimestamp);
        break;
      case "actions":
        jsx = renderJobActions(job, onDelete, this.state.type);
        break;
      case "skus":
        jsx = job.skus.length;
        break;
      case "success":
        jsx = renderSkuNumbers(
          job.skus.length - errorsLength - missingSkusLength, headerName
        );
        break;
      case "missing":
        jsx = renderSkuNumbers(missingSkusLength, headerName);
        break;
      case "errors":
        jsx = renderSkuNumbers(errorsLength, headerName);
        break;
      case "status":
        jsx = renderJobStatus(job, onJobStatusChange, onErrorClick);
        break;
      case "statusDropdown":
        jsx = renderJobStatusSwitch(job, onJobStatusStringChange);
        break;
      case "retailerId":
        jsx = retailerNameRender(job.retailer.toLocaleLowerCase()).replace(
          ".com",
          ""
        );
        break;
      case "jobName":
        jsx = job.metadata.jobName;
        break;
      case "owner":
        const user = this.state.teamMemberList[job.metadata.submittedUserId];
        jsx = user ? `${user.firstName} ${user.lastName}` : " - ";
        break;
      case "frequency":
        jsx = renderFrequencyColumn(job, this.props.globalState);
        break;
      default:
        jsx = job[headerName];
    }
    return jsx;
  };

  sortValue = (
    job: PlatformCatalogJob,
    headerName: keyof typeof BrandJobHeaderNameMap
  ) => {
    let jsx;
    const errorsLength = job.errorSkus ? job.errorSkus.length : 0;
    const missingSkusLength = job.missingSkus ? job.missingSkus.length : 0;

    const renderSkuNumbers = (num: number, type: string) => {

      switch (job.status.state) {
        case "Complete":
        case "Error":
          return num
        default:
          return 0;
      }
    };

    const renderDate = (ts: number) => {
      if (ts) {
        const dateObj = new Date(job.schedule.metadata.previousExecTimestamp);
        return dateObj.valueOf()
      } else {
        return 0;
      }
    };

    switch (headerName) {
      case "lastRun":
        jsx = renderDate(job.status.lastRun) || renderDate(job.schedule.metadata.previousExecTimestamp);
        break;
      case "dateClosed":
        jsx =
          job.status.state === "Complete"
            ? renderDate(job.status.lastRun) || renderDate(job.schedule.metadata.previousExecTimestamp) || renderDate(job.schedule.config.startTimestamp)
            : " - ";
        break;
      case "dateOpened":
        jsx = renderDate(job.schedule.config.startTimestamp);
        break;
      case "actions":
        jsx = 0//renderJobActions(job, onDelete, this.state.type);
        break;
      case "skus":
        jsx = job.skus.length;
        break;
      case "success":
        jsx = renderSkuNumbers(
          job.skus.length - errorsLength - missingSkusLength, headerName
        );
        break;
      case "missing":
        jsx = renderSkuNumbers(missingSkusLength, headerName);
        break;
      case "errors":
        jsx = renderSkuNumbers(errorsLength, headerName);
        break;
      case "status":
        jsx = job.status.state//renderJobStatus(job, onJobStatusChange, onErrorClick);
        break;
      case "statusDropdown":
        jsx = job.status.state//renderJobStatusSwitch(job, onJobStatusStringChange);
        break;
      case "retailerId":
        jsx = retailerNameRender(job.retailer.toLocaleLowerCase()).replace(
          ".com",
          ""
        );
        break;
      case "jobName":
        jsx = job.metadata.jobName;
        break;
      case "owner":
        const user = this.state.teamMemberList[job.metadata.submittedUserId];
        jsx = user ? `${user.firstName} ${user.lastName}` : " - ";
        break;
      case "frequency":
        jsx = renderFrequencyColumn(job, this.props.globalState);
        break;
      default:
        jsx = job[headerName];
    }
    return jsx;
  };

  newColumns = () => {
    const { teamJobs } = this.props.globalState.teamJobData;
    const oldColumns = this.columns();
    
    const columns = oldColumns.map(column => {
      const columnId = column.id;
      /* let headerArray = undefined;
      if (typeof tableConfig[columnId]?.headerLabel === 'string') {
          headerArray = [<>&zwnj;</>, tableConfig[columnId]?.headerLabel];
      } else {
          headerArray = tableConfig[columnId]?.headerLabel
      }
      const headerLabel = <>{headerArray[0]}<br />{headerArray[1]}</>; */
      const align = column.style['textAlign'];

      return {
          renderHeader: (params) => ( column.Header
            /* <div
              style={{
                //borderBottom: "1px solid indigo",
                padding: "5px 0",
                textAlign: "left",
                fontSize: "1.1rem",
                fontWeight: 700,
                borderRightColor: "transparent",
              }}
            >
              {headerLabel || columnId}
            </div> */
          ),
          headerClassName: 'grid-header',
          // expander: headerName === 'accuracy',
          headerStyle: {
            // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
            fontWeight: "bold",
            borderRightColor: "transparent",
          },
          ...(columnId === 'jobName' ? {flex: 1} : {}),
          field: columnId,
          width: column.width,
          minWidth: column.width,
          resizable: true,
          style: { textAlign: align} ,
          align,
          valueGetter: (params) => {
            return this.sortValue(teamJobs[params.row["jobId"]], columnId as any);
          },
          //id: columnId,
          ...{
            // [headerName === 'accuracy' ? 'Expander' : 'Cell']
            renderCell: (params) => (column.Cell as Function)({original: params.row["jobId"]})
          },
        };
    });
    return columns;
  }

  newTable = () => {
    const columns = this.newColumns()
    const tableData = this.dataFilter().map(jobId => {
      const rowObj = {
        jobId,
        id: jobId
      };

      columns.forEach(column => {
        rowObj[column.field] = jobId
      })

      return rowObj
    });

    const pageSize = this.props.type === "recurring" ? 50 : 10

    return <CSUIMUIXGrid 
      rows={tableData}
      columns={columns}
      pageSize={pageSize}
      rowHeight={40}
      headerHeight={40}
      disableColumnMenu={true}
      className={"newJobsTable"}
    />
  }

  render() {
    const tableData = this.dataFilter();
    const { classes } = this.props;
    // const { dataLoading } = this.props.globalState.teamJobData;
    // const expanded = Object.assign(
    //   {},
    //   ...tableData.map((x, idx) => ({ [idx]: this.state.expanded[x] }))
    // );

    const shouldShow = !(this.props.type === "draft" && tableData.length === 0);

    const showPagination =
      tableData.length > (this.props.type === "recurring" ? 50 : 10);

    const modalSkus = this.state.modalSkusToShow.map((item, idx) => {
      const scrapeConfigObject = this.props.globalState.teamJobData.teamJobs[this.state.selectedJobId]?.scrapeConfig[item];
      return scrapeConfigObject || null
    }).filter(x => x)

    const copyErrorMissingSkus = () => {
      const str = modalSkus.map(x => x.skuRetUid).join("\n");
      const result = !!copyToClipboard(str, "modal-ref");
      if (result) {
        alert((modalSkus.length === 1 ? "1 UID" : modalSkus.length + " UIDs") + " copied!");
      }
    }

    const renderOutput = (
      <div style={{ marginLeft: 0, marginBottom: 25 }}>
        <Modal open={!!this.state.isModalOpen} onClose={() => this.setState({ isModalOpen: "" })}>
          <div id="modal-ref" className={classes.paper}>
            {capitalizeStr(this.state.isModalOpen) === 'Missing' ? (
              <>
                <h1>SKUs Not Found</h1>
                <h5>
                  The following item(s) were not found.  Before contacting your Merchant, you should click on some of the URL's to manually confirm as sometimes items may only be down for a short period.  If these products should be live and they're still not appearing - you should contact your Merchant as the lost sales can quickly pile up.  If you would like to re-test them - use the copy button and create a new Audit as this will often lead to successful results.
                </h5>
              </>
            ) : (
                <>
                  <h1>Product Error(s)</h1>
                  <h5>
                    The following item(s) incurred an error, which can be the result of any number of issues.  Please note, your account is not charged for Errors.  If you would like to re-test them - use the copy button and create a new Audit as this will often lead to successful results.
                </h5>
                </>
              )}
            <Divider flexItem orientation='horizontal' style={{ height: '1px' }} />
            <br />
            <Grid className={classes.body}>
              {modalSkus.length > 0
                ? modalSkus.map((scrapeConfigObject, idx) => {
                  return (<div key={idx} style={{ width: '100%', textAlign: 'left', marginTop: 10 }}>
                    {!(scrapeConfigObject.url || '').startsWith("http") 
                      ? <>{scrapeConfigObject.skuRetUid}</> 
                      : <a href={scrapeConfigObject.url} rel="noreferrer" target='_blank'>
                      {scrapeConfigObject.skuRetUid}
                    </a>}
                  </div>)
                })
                : <div>
                  There are no error or missing skus
                </div>
              }
            </Grid>
            <Grid
              container
              spacing={4}
              className={classes.buttonContainer}
              justify="center"
            >
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={copyErrorMissingSkus}
                  startIcon={<FileCopy />}
                >
                  Copy UIDs
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.setState({ isModalOpen: "" })}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <CSUISegment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                <h2>{this.tableHeader()}</h2>
              </div>

              {/* {this.balanceInfo()} */}
            </div>
            <div style={{ minWidth: 100 }}>
              <div style={{ fontSize: "10pt", marginTop: 10 }}>
                {this.newTable() || (showPagination ? (
                  <Pagination data={tableData} pageSize={10}>
                    <RenderJobsTable
                      rowConfig={{}}
                      maxWidth={1800}
                      getRowId={row => row}
                      tableData={tableData}
                      columns={this.columns()}
                      columnConfig={this.columns()}
                    />
                  </Pagination>
                ) : (
                  <RenderJobsTable
                    rowConfig={{}}
                    maxWidth={1800}
                    rows={tableData}
                    getRowId={row => row}
                    data={tableData}
                    columns={this.columns()}
                    columnConfig={this.columns()}
                  />
                ))}
              </div>
            </div>
          </div>
          {this.state.jobToDelete ? (
            <DeleteJobConfirmation
              jobToDelete={this.state.jobToDelete}
              onCancel={() => this.setState({ jobToDelete: null })}
              onConfirm={this.deleteJob}
            />
          ) : null}
          {this.state.errorJob && (
            <JobErrorDialog
              onClose={() => this.setState({ errorJob: null })}
              jobName={this.state.errorJob.metadata.jobName}
            />
          )}
        </CSUISegment>
      </div>
    );

    return shouldShow ? renderOutput : null;
  }
}

export default withStyles(styles, { withTheme: true })(NewJobsTable);
