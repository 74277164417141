import axios from 'axios';
import {API_URL} from '../../types/datastructures';

const API_URL_teamBalance = `${API_URL}/team-balance/get`;
export const API_CALL_teamBalance = (teamId: string) => {
    return axios.get<{ teamId: string; balance: number; asofTs: number; }>(API_URL_teamBalance + `?teamId=${teamId}`)
}

const API_URL_teamBalanceSet = `${API_URL}/team-balance/set`;
export const API_CALL_teamBalanceSet = (teamId: string, amount: number) => {
    return axios.post<any>(API_URL_teamBalanceSet, {
        teamId,
        amount
    });
}

const API_URL_teamCreditEvents = `${API_URL}/team-credit-events`;
export const API_CALL_teamCreditEvents = (teamId: string) => {
    return axios.get<any[]>(API_URL_teamCreditEvents + `?teamId=${teamId}`);
}

export const API_CALL_GetTeamBilling = (teamId: string) => {
    return axios.post<{ teamId: string; }>(`${API_URL}/team-balance/get-billing`, {teamId});
}

export const API_CALL_GetTeamCredits = () => {
    return axios.get(`${API_URL}/team/credits`);
}
