import React from 'react';
import Close from '@material-ui/icons/Close';
import InputNumber from './InputNumber';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import { StateKey } from '../../types';

interface Props {
  state: any;
  onChangeFormula: (key) => void;
}

const RetailersFormula = ({ state, onChangeFormula }: Props) => {
  const classes = styles();
  const onChangeInput = (key: StateKey) => (e) => {
    const newState = { ...state, [key]: e.target.value };

    if (newState[StateKey.Retailers] && newState[StateKey.SkusPerRetailer]) {
      newState[StateKey.Total] = String(
        +newState[StateKey.Retailers] * +newState[StateKey.SkusPerRetailer]
      );
    } else if (newState[StateKey.Retailers] || newState[StateKey.SkusPerRetailer]) {
      newState[StateKey.Total] = "--"
    } else if (!newState[StateKey.Retailers] && !newState[StateKey.SkusPerRetailer]) {
      newState[StateKey.Total] = ""
    }
    onChangeFormula(newState);
  };

  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <p className={classes.description}>Let’s determine your needs!</p>
      </Grid>
      <div className={classes.formulaWrapper}>
        <InputNumber
          label='How many retailers do you want to audit?'
          inputProps={{
            placeholder: '5',
            value: state.retailers,
            onChange: onChangeInput(StateKey.Retailers),
          }}
        />
        <span className={classes.iconWrapper}>
          <Close className={classes.closeIcon} />
        </span>
        <InputNumber
          label='On average, how many SKUs per retailer?'
          inputProps={{
            placeholder: '300',
            value: state.skusPerRetailer,
            onChange: onChangeInput(StateKey.SkusPerRetailer),
          }}
        />
        <span className={classes.formulaIcon}>=</span>
        <InputNumber
          label='Unique Product Pages to be audited'
          inputProps={{
            placeholder: '1500',
            disabled: true,
            value: state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }}
        />
      </div>
    </div>
  );
};
export default RetailersFormula;
