import 'firebase/messaging';

import { ContentStatusDataService } from '../../DataService';
import { firebaseInstance } from '../../Util';
import { API_CALL_SendFCMToken } from '../../Util/apiClient';
import { ActiveUserAccount } from '../../types/datastructures';
import { createNotification } from '../../Util/notifications';

export enum MESSAGES {
    EMIT_CATALOG_DATA = 'emitCatalogData',
    DEFAULT = 'default',
}

class FcmServiceClass {
    private client;
    private initialized = false;
    private userInfo: { name: string, email: string; teamId: string; }

    init = async () => {
        if (this.initialized) {
            return;
        }

        try {
            this.client = firebaseInstance.messaging();
            this.client.usePublicVapidKey("BOgvLDIPectfsZFWlv3MN82HgknpJ8or3E3HbmRgfVmmp3tHcn_DkrJir3EmFvPa3bsXK9b0hBh8g5XxiAqLABQ");
            const token = await this.client.getToken();
            await API_CALL_SendFCMToken(token);

            this.client.onMessage(this.onMessageHandler);
        } catch (e) {
            console.error(e);
            console.info("Error trying to init FCM-based Catalog refresh...switching to polling implementation.");
        }

        this.initialized = true;
    }

    setUser = (activeUser: ActiveUserAccount) => {
        const coreUser = activeUser.user.core;
        this.userInfo = {
            email: coreUser?.email,
            name: activeUser.user.displayName,
            teamId: coreUser?.teamId
        };
    }

    private onMessageHandler = async (payload: {
        data: {
            teamId: string;
            message: any;
            topic: MESSAGES;
        };
        topic: MESSAGES;
    }) => {
        const { teamId, topic, message } = payload.data;
        if (!this.userInfo || this.userInfo.teamId !== teamId) {
            return;
        }
        switch (topic) {
            case MESSAGES.EMIT_CATALOG_DATA:
                await ContentStatusDataService.catalogData({
                    teamId,
                }).refresh();
                break;
            case MESSAGES.DEFAULT:
                const parsedMessage = JSON.parse(message);
                if (typeof parsedMessage === 'string') {
                } else {
                    const noteStr = parsedMessage.msg === 'catalogJobSnapshotComplete'
                    ? "Job is complete"
                    : "Catalog Re-audit complete";

                    const note = createNotification({title: noteStr, text: noteStr});
                    await ContentStatusDataService.notifications({teamId}).push(note);
                }
                break;
        }
    }
}

export const FcmService = new FcmServiceClass();
