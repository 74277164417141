import React, { memo } from 'react';
import styles from './styles.module.scss';
import ErrorIcon from '@material-ui/icons/Warning';
import { calculateFlaggedItems } from '../../../../libraries/Util/utils';
import './style.scss';
import Grid from '@material-ui/core/Grid/Grid';

const FlaggedSnapshot = ({skuRecord}) => {

    const renderFlagSection = (name: string, flagsCount: any[]) => {
        return <React.Fragment>
            <h2>{name}</h2>
            <React.Fragment>
                {flagsCount.length > 0 ? flagsCount.map((el, id ) => {
                    return <div className={styles.row} key={el.flagMessage + id}>
                        <div className={styles.col1}>
                            <h4>
                                <ErrorIcon
                                    style={{
                                        fontSize: '12px',
                                        color: name === 'critical' ? 'red' : '#FEBE00',
                                        marginRight: '5px',
                                        marginTop: '-4px'
                                    }}
                                />
                                {el.flagMessage}
                            </h4>
                        </div>
                    </div>
                }): <h3>No {name} flags</h3>}
            </React.Fragment>
        </React.Fragment>
    }

    const renderData = () => {
        const flaggedData = calculateFlaggedItems(skuRecord);
        const critFlagsCount = flaggedData.criticalFlags;
        const warnFlagsCount = flaggedData.warningFlags;
        const indicatorFlagsCount = flaggedData.indicatorFlags;

        return (<div className={styles.lists}>
            {renderFlagSection("critical", critFlagsCount)}
            {renderFlagSection("warning", warnFlagsCount)}
            {renderFlagSection("indicator", indicatorFlagsCount)}
        </div>)
    }

    return <Grid className="card-body">
                {renderData()}
    </Grid>
}


export default memo(FlaggedSnapshot);
