import React from 'react';
import { CSUISegment } from '../../components/CSUI';
import Content from './Content';
import CSLogo from '../../media/Header/ContentStatus_Basic Logo.png';

const DirectCheckout = ({ router }) => {
  const title = 'Direct Checkout';
  const detail =
    'Complete the checkout of your subscription or newly purchased credits.';
  return (
    <React.Fragment>
      <div className='publicNav'>
        <img src={CSLogo} style={{ maxHeight: 40, margin: '-10px 0' }} alt='' />
      </div>
      <div className='billing-wrapper' style={{ padding: '1.5rem 3%' }}>
        <div>
          <div className='billing-headerrow'>
            <div className='billing-header'>
              <h1 className='h1-simple cs-purple'>{title}</h1>
              <p style={{ marginBottom: 20 }}>{detail}</p>
            </div>
          </div>
        </div>
        <CSUISegment>
          <Content router={router} />
        </CSUISegment>
      </div>
    </React.Fragment>
  );
};

export default DirectCheckout;