import { CircularProgress } from '@material-ui/core';
import React, { useState }  from 'react'
import { RouteComponentProps } from "react-router-dom";
import { CSUIButton, CSUISegment } from '../../components/CSUI';
import { GlobalState, GlobalStateAppContextConsumer } from '../../libraries/types/ReportUp';
import { API_CALL_IndexTeamDataElastic } from '../../libraries/Util/apiClient';

const indexCatalogButton = (globalState: GlobalState, setIsDataLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const teamId = globalState.teamJobData.teamId;
    const onClick = async () => {
        setIsDataLoading(true);
        const indexResponse = await API_CALL_IndexTeamDataElastic(teamId)
        console.log({indexResponse});
        //history.push("/demo/escatalog/");
        window.location.assign("/demo/escatalog/");
    }
    return <div>
        <CSUIButton onClick={onClick}>
            (Re-)Index Catalog
        </CSUIButton>
    </div>
}

export const ElasticReindexDemo = (props: RouteComponentProps) => {
    const [isDataLoading, setIsDataLoading] = useState(false);

    return <CSUISegment><GlobalStateAppContextConsumer>{globalState =>
        isDataLoading 
        ? <CircularProgress />
        : <div>
            {indexCatalogButton(globalState, setIsDataLoading)}
        </div>
    }</GlobalStateAppContextConsumer></CSUISegment>
}