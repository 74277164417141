import * as React from "react";
import { querystringparser } from "../../libraries/Util";
import { RouteComponentProps } from "react-router-dom";
import { API_CALL_quickLookupImage } from "../../libraries/Util/apiClient";
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSUISegment, CSUIButton } from "../CSUI";
import { ImageDataRes } from "./dataSample";
import CSLogo from '../../media/Header/ContentStatus_Basic Logo.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Objectkeys } from "../../libraries/types/datastructures";
import TopLevelPageLayout from "../../layouts/TopLevelPageLayout";
import "./style.scss";

const useStyles = makeStyles({
  table: {
    //minWidth: 650,
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  td: {
    fontSize: '1rem'
  }
});

const KeyValueTable: React.FC<{headerValues?: string[], rows: {key: string, value: any}[]}> = props => {
  const {rows, headerValues} = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        {headerValues
          ? <TableHead>
            <TableRow>
              {headerValues.map(header => <TableCell key={header}>
                {header}
              </TableCell>)}
            </TableRow>
          </TableHead>
          : null
        }
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={`${idx}${row.key}`}>
              <TableCell component="th" scope="row" className={classes.th}>
                {row.key}
              </TableCell>
              <TableCell align="right" className={classes.td}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface ImageReportProps {
  router: RouteComponentProps;
}

interface State {
  imageData?: ImageDataRes['result'];
  dataLoading: boolean;
}

class ImageReport extends React.Component<ImageReportProps, State> {

  constructor(props: ImageReportProps) {
    super(props);

    this.state = {
      dataLoading: true
    };
  }

  evaluateQueryParams = () => {
    const parsedLocationSearch = querystringparser(this.props.router.location.search);

    const imageUrlParam = parsedLocationSearch['imageUrl'];

    const imageUrl = (imageUrlParam && imageUrlParam[0])
        ? imageUrlParam[0]
        : undefined;

    return imageUrl;
  }

  async componentDidMount() {
    const imageUrl = this.evaluateQueryParams();
    const res = await API_CALL_quickLookupImage(imageUrl);
    const imageDataRes = res.data as ImageDataRes;

    this.setState({imageData: imageDataRes.result, dataLoading: false});
  }

  loadingCircle = () => {
    return <div className="loadingCircle">
        <CircularProgress />
    </div>
  }

  fileDataTable = (rows: {key: string, value: any}[], headers?: string[]) => {
    return <KeyValueTable rows={rows} headerValues={headers} />
  }

  uiBase = () => {
    const {imageData} = this.state;

    const imagePreview = <div className="imagePreview">
      <h2>Image Preview</h2>
      <div className="imagePreviewImg">
          {// eslint-disable-next-line jsx-a11y/alt-text
          }<img src={imageData.url} style={{maxWidth: 500}} />
      </div>
    </div>

    const colorsInner = {
      __html: imageData.google.parsed.colors ? imageData.google.parsed.colors.map(color => color.html).join(" ") : ''
    }
    const colors = imageData.google.parsed.colors ? <div className="colors">
      <h2>Dominant Colors (average)</h2>
      <div className="colorsBreakdown" dangerouslySetInnerHTML={colorsInner} />
    </div> : null

    const fileData = <div className="fileData">
      <h2>File Metadata</h2>
      <div className="fileMetaData">
          {this.fileDataTable(
            Objectkeys(imageData.file_type).map(key => ({key, value: `${imageData.file_type[key]}`}))
          )}
      </div>
    </div>

    const detectedObject = imageData.google.parsed.detectedObjects ? <div className="detectedObjects">
      <h2>Detected Objects</h2>
      <div className="">
          {this.fileDataTable(
            imageData.google.parsed.detectedObjects.map(obj => ({key: obj.name, value: obj.confidenceScore})),
            ["Name", "Confidence Score"]
          )}
      </div>
    </div> : null

    const safetyReport = imageData.google.parsed.safeSearchAnnotation ? <div className="safetyReport">
      <h2>Safety Report</h2>
      <div className="">
          {this.fileDataTable(
            Objectkeys(imageData.google.parsed.safeSearchAnnotation).map(key => ({key, value: `${imageData.google.parsed.safeSearchAnnotation[key]}`}))
          )}
      </div>
    </div> : null

    const root = <div className="rootDiv">
      {imagePreview}
      {colors}
      {fileData}
      {detectedObject}
      {safetyReport}
    </div>
    return root
  }

  newTab = (href: string) => Object.assign(document.createElement('a'), { target: '_blank', href}).click();

  render() {
    const {dataLoading, imageData} = this.state;

    const title = "Image Analysis Report"
    const buttons = imageData ? [<CSUIButton onClick={() => {
        // this.openChargeBeePortal(this.props.globalState)
        this.newTab(imageData.url)
    }} color="green">Go to URL</CSUIButton>] : []
    
    return (<React.Fragment>
      <div className="publicNav">
        {// eslint-disable-next-line jsx-a11y/alt-text
        }<img src={CSLogo} style={{ maxHeight: 40, margin: "-10px 0" }} />
      </div>
      <TopLevelPageLayout className="imagereport-wrapper" title={title} buttons={buttons}>
        <CSUISegment>
          {dataLoading
            ? this.loadingCircle()
            : this.uiBase()
          }
        </CSUISegment>
      </TopLevelPageLayout>
    </React.Fragment>);
  }
}

export default ImageReport;
