import React from 'react';
import { Job, NewJobSetState } from '../index';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import Button, { ButtonProps } from '@material-ui/core/Button/Button';
import StepLabel from '../../Stepper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface Step3ComponentProps {
  message?: JSX.Element;
  basic: boolean;
  job: Job;
  onChange: NewJobSetState;
  isRecurring: boolean;
  planType?: string;
}

interface Props extends ButtonProps {
  selected?: boolean;
  label: string;
  subLabel?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bigButton: {
    width: '200px',
    height: '70px',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
  },
  subLabelBigButton: {
    fontSize: '0.75rem',
    textTransform: 'lowercase',
  },
  content: {
    padding: theme.spacing(2),
  },
}));

const BigButtonSelection = ({ label, subLabel, selected, ...props }: Props) => {
  const classes = useStyles({ selected });

  return (
    <Button
      {...props}
      className={classes.bigButton}
      color='primary'
      variant={selected ? 'contained' : 'outlined'}>
      <div>
        <div>{label}</div>
        {subLabel && (
          <div className={classes.subLabelBigButton}>{subLabel}</div>
        )}
      </div>
    </Button>
  );
};

const PlanSelection = (props: Step3ComponentProps) => {
  const classes = useStyles({});
  //   const {
  //     coreJob: {
  //       schedule: {
  //         start: { when },
  //       },
  //     },
  //   } = job;

  const onClickSubscription = (props: Step3ComponentProps) => {
    props.onChange((prevState) => ({
        ...prevState,
        job: {
          ...prevState.job,
          coreJob: {
            ...prevState.job.coreJob,
            schedule: {
              ...prevState.job.coreJob.schedule,
              recur: {
                how: "custom",
                frequency: {
                    unit: "subscription" as any,
                    amount: 1
                },
                stop: {
                    when: "never"
                }
              }
            }
          }
        },
        isRecurring: true,
    }));
  };

  const onClickOneTimeAudit = (props: Step3ComponentProps) => {
      props.onChange((prevState) => ({
          ...prevState,
          job: {
            ...prevState.job,
            coreJob: {
              ...prevState.job.coreJob,
              schedule: {
                ...prevState.job.coreJob.schedule,
                recur: {
                  how: "once"
                }
              }
            }
          },
          isRecurring: false,
      }));
  };

  const {jobData} = props.job.coreJob;

  const newPlans = [
    {value: 'daily', text: 'Genius (daily)'},
    {value: 'weekly', text: 'Brilliant (weekly)'},
    {value: 'monthly', text: 'Smart (monthly)'},
  ]
  
  const subLabel = newPlans.find(plan => plan.value === props.planType)?.text || 'Subscription';

  return (
    <Grid container direction='column'>
      <Grid item>
        <StepLabel
          numberText={1}
          label={`Which plan do you want to use for these ${(jobData.masterCsv.data.length) ? jobData.masterCsv.data.length+" " : ''}audits?`}
        />
      </Grid>
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.content}
        spacing={2}>
        <Grid item>
          <BigButtonSelection
              label='One-Time Audits'
              selected={!props.isRecurring}
              onClick={() => onClickOneTimeAudit(props)}
          />
        </Grid>
        <Grid item>
          <BigButtonSelection
            label={subLabel + ' Plan'}
            onClick={() => onClickSubscription(props)}
            selected={props.isRecurring}
            disabled={!!!props.planType}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const TimingSelection = (props: Step3ComponentProps) => {
  const classes = useStyles({});
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <StepLabel
          numberText={2}
          label='When do you want to start auditing these SKUs?'
        />
      </Grid>
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.content}
        spacing={2}>
        <Grid item>
          <BigButtonSelection
            label='Run Now'
            subLabel={props.isRecurring ? '...then add to normal schedule' : ''}
            selected
          />
        </Grid>
        <Grid item>
          <BigButtonSelection
            label='Wait'
            subLabel='...then add to normal schedule'
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Step3Component: React.FC<Step3ComponentProps> = (
  props: Step3ComponentProps
) => (
  <Grid container spacing={2}>
    <Grid item>
      <PlanSelection {...props} />
    </Grid>
    <Divider orientation='vertical' flexItem />
    <Grid item>
      <TimingSelection {...props} />
    </Grid>
  </Grid>
);

export default Step3Component;
