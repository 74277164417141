import * as React from 'react';
import './style.scss';
import { ScoreObject } from '../../../../pages/Catalog/catalog-datamodel';

interface ScoreBarBinaryProps {
	score: ScoreObject;
	qty: number;
}

interface State {
	color: string;
	left: number;
}

class ScoreBarBinary extends React.Component<ScoreBarBinaryProps, State> {

	
    /* static getDerivedStateFromProps = (nextProps: Props, prevState: State) => {
        if (nextProps.index !== prevState.index) {
            prevState.index = nextProps.index;
            return prevState;
        }
        
        return null;
        // tslint:disable-next-line:no-console
        // console.log(this.props);
        // this.setState({index: this.props.index});
    } */

	constructor(props: ScoreBarBinaryProps) {
		super(props);

		this.state = {
			color: 'transparent',
			left: 0,
		};
	}

	constrainScore = (score: ScoreObject): number => {
		const min = 0;
		const max = 5;
		if(!score) {
			return Math.min(
				max,
				Math.max(
					min,
					0,
				),
			);
		}
		return Math.min(
			max,
			Math.max(
				min,
				score.potential ? score.score / (score.potential / 5) : 0,
			),
		);
	}

	componentDidMount() {
	}

	render = () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { qty } = this.props;

        const score = this.constrainScore(this.props.score);
        
        const isBonus = score === 5;

		return (
			<div className="bar-wrap productScoreBar productScoreBarBinary">
				<div className="bar">
                    <div className={(!isBonus) ? "noneHalf active" : "inactive"}>
                        <div className="step">
                            None. No Penalty
                        </div>
                    </div>
                    <div className={(isBonus) ? "pointsHalf active" : "inactive"}>
                        <div className="step">
                            Bonus 5 / 5
                        </div>
                    </div>
				</div>
			</div>
		);
	}
}

export default ScoreBarBinary;
