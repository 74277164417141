import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';

interface Props {
  label: string;
  inputProps?: TextFieldProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '200px',
  },
  coreInput: {
    //width: '800px',
    maxWidth: '1000px',
    [`& fieldset`]: {
      borderRadius: '10px',
      boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.25)',
    },
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: '1.9rem',
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '1.3rem'
    },
  },
  label: {
    position: 'absolute',
    bottom: '-5px',
    top: 0,
    // marginTop: theme.spacing(1.5),
    width: 200,
    marginTop: 10,
    color: '#000',
    textAlign: 'center',
    fontSize: '1.2rem',
    lineHeight: '17px',
    transform: 'translateY(120%)',
    ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
      width: 150,
      fontSize: 13
    },
    ['@media only screen and (max-width:  516px)']: { // eslint-disable-line no-useless-computed-key
      width: 115
    },
    ['@media only screen and (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
      width: 100,
      fontSize: 12
    },
    ['@media only screen and (max-width: 320px)']: { // eslint-disable-line no-useless-computed-key
      width: 85
    },
  },
}));

const InputNumber = ({ label, inputProps }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        className={classes.coreInput}
        hiddenLabel
        type={inputProps.disabled ? 'text' : 'number'}
        variant='outlined'
        inputProps={{ className: classes.input }}
        {...inputProps}
        value={inputProps.disabled && inputProps.value !== "--" ? inputProps.value as string || inputProps.placeholder || '600' : inputProps.value}
      />
      <label className={classes.label}>{label}</label>
    </div>
  );
};

export default InputNumber;
