import { SkuRecord, SkuRecordCatalogData } from '../catalog-datamodel';
import { KeywordCollection } from '../datastructures';
import { isEmpty } from 'lodash';

export interface SKUWithKeywords {
    skuRecord: SkuRecord,
    primaryKeywords: string[],
    secondaryKeywords: string[]
}

export interface SkuKeywords {
    skuRecord: {
        skuId: string,
        mainImage: string,
        brand: string,
        model: string,
        title: string,
        upc: string,
    },
    primaryKeywords: string[],
    secondaryKeywords: string[]
}

export const SKUWithKeywordsToSkuKeywords = (sku: SKUWithKeywords) => {
    const {Images} = sku.skuRecord.skuData?.Assets
    return {
        ...sku,
        skuRecord: {
            skuId: sku.skuRecord.skuId,
            mainImage: Images.values[Images.labels[0]].url,
            brand: sku.skuRecord.skuData?.UID["Brand Name"],
            model: sku.skuRecord.skuData?.UID.Model,
            title: sku.skuRecord.skuData?.UID["Product Name"],
            upc: sku.skuRecord.skuData?.UID["UPC (GTIN-12)"],
        }
    }
}

export const addKeywordsToSKURecords = (catalogData: SkuRecordCatalogData, keywordsCollection: KeywordCollection = null): { [skuId: string]: SkuKeywords} => {
    if (isEmpty(catalogData)) {
        return {};
    }
    const records = {};
    Object.keys(catalogData).forEach((key: string) => {
        const skuData = (keywordsCollection && keywordsCollection.hasOwnProperty(key)) ? keywordsCollection[key] : null;

        records[key] = {
            skuRecord: {
                skuId: key,
                mainImage: catalogData[key].image,
                brand: catalogData[key].brand,
                model: catalogData[key].model,
                title: catalogData[key].title,
                upc: catalogData[key].upc,
            },
            primaryKeywords: skuData ? Object.keys(skuData.keywords).filter((key: string) => skuData.keywords[key].type === "primary").map((key: string) => skuData.keywords[key].displayName) : [],
            secondaryKeywords: skuData ? Object.keys(skuData.keywords).filter((key: string) => skuData.keywords[key].type === "secondary").map((key: string) => skuData.keywords[key].displayName) : []
        }
    });
    return records;
}

export const addKeywordsToSKURecord = (skuRecord: SkuRecord, keywordsCollection = null): SKUWithKeywords => {
    return {
        skuRecord,
        primaryKeywords: keywordsCollection ? Object.keys(keywordsCollection.keywords).filter((key: string) => keywordsCollection.keywords[key].type === "primary").map((key: string) => keywordsCollection.keywords[key].displayName) : [],
        secondaryKeywords: keywordsCollection ? Object.keys(keywordsCollection.keywords).filter((key: string) => keywordsCollection.keywords[key].type === "secondary").map((key: string) => keywordsCollection.keywords[key].displayName) : []
    }
}
