import { SkuRecord } from '../Catalog/catalog-datamodel';
import { SKUWithKeywords } from '../Catalog/SKUKeywords/datastructures';
import { Objectentries } from '../../libraries/types/datastructures';
import { escapedRegex } from '../../libraries/Util/string';

export interface KeywordsStats {
    total: {
        count: number,
        used: number
    },
    primary: {
        count: number,
        used: number
    },
    secondary: {
        count: number,
        used: number
    }
}
export const defaultKeywordsStats = {
    total: {
        count: 0,
        used: 0
    },
    primary: {
        count: 0,
        used: 0
    },
    secondary: {
        count: 0,
        used: 0
    }
}
export const getSkuKeywordsStats = (sku: SKUWithKeywords) => {
    const stats = {
        total: {
            count: sku.primaryKeywords.length + sku.secondaryKeywords.length,
            used: 0
        },
        primary: {
            count: sku.primaryKeywords.length,
            used: sku.primaryKeywords.filter((word: string) => countKeywordInstances(sku.skuRecord, word, "title") > 0 || countKeywordInstances(sku.skuRecord, word, "description") > 0 || countKeywordInstances(sku.skuRecord, word, "bullets") > 0).length
        },
        secondary: {
            count: sku.secondaryKeywords.length,
            used: sku.secondaryKeywords.filter((word: string) => countKeywordInstances(sku.skuRecord, word, "title") > 0 || countKeywordInstances(sku.skuRecord, word, "description") > 0 || countKeywordInstances(sku.skuRecord, word, "bullets") > 0).length
        }
    }
    stats.total.used = stats.primary.used + stats.secondary.used;
    return stats;
}
export const countKeywordInstances = (sku: SkuRecord, keyword: string, location: "title" | "description" | "bullets") => {

    let text;
    switch (location) {
        case "title":
            text = sku.skuData.UID["Product Name"];
            break;
        case "description":
            text = sku.skuData.Copy["Short Description"];
            break;
        case "bullets":
            text = Objectentries(sku.skuData.Copy.Bullets.values).join(" ");
            break;
    }
    text = text.replace(/-/g, ' ').replace(escapedRegex(`${keyword.replace(/-/g, ' ')}`, 'gi'), `[keyword]${keyword}[keyword]`);
    return text.split("[keyword]").filter((word: string) => word.toLowerCase() === keyword.toLowerCase()).length;
}

export const getKeywordsSnapshot = (sku: SKUWithKeywords) => {
    const stats = getSkuKeywordsStats(sku);
    const percent = (value: number, total: number): number => {
        if (total === 0) {
            return 0;
        } else {
            return (value * 100) / total;
        }
    }

    const totalPct = parseInt(percent(stats.total.used, stats.total.count).toString());
    const primaryPct = parseInt(percent(stats.primary.used, stats.primary.count).toString());
    const secondaryPct = parseInt(percent(stats.secondary.used, stats.secondary.count).toString());

    return {
        total: {
            type: "percent" as "percent",
            value: stats.total.count === 0 ? "--" : totalPct,
            variation: NaN,
            progress: totalPct,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        primary: {
            type: "percent" as "percent",
            value: stats.primary.count === 0 ? "--" : primaryPct,
            variation: NaN,
            progress: primaryPct,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        secondary: {
            type: "percent" as "percent",
            value: stats.secondary.count === 0 ? "--" : secondaryPct,
            variation: NaN,
            progress: secondaryPct,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        stats: {
            primary: sku.primaryKeywords.map((keyword: string) => {
                // console.log(keyword, countKeywordInstances(sku.skuRecord, keyword, "description"), countKeywordInstances(sku.skuRecord, keyword, "bullets"))
                return {
                    keyword: keyword,
                    title: countKeywordInstances(sku.skuRecord, keyword, "title"),
                    description: countKeywordInstances(sku.skuRecord, keyword, "description"),
                    bullets: countKeywordInstances(sku.skuRecord, keyword, "bullets"),
                    warning: countKeywordInstances(sku.skuRecord, keyword, "description") >= 3 || countKeywordInstances(sku.skuRecord, keyword, "bullets") >= 3
                }
            }),
            secondary: sku.secondaryKeywords.map((keyword: string) => {
                return {
                    keyword: keyword,
                    title: countKeywordInstances(sku.skuRecord, keyword, "title"),
                    description: countKeywordInstances(sku.skuRecord, keyword, "description"),
                    bullets: countKeywordInstances(sku.skuRecord, keyword, "bullets"),
                    warning: countKeywordInstances(sku.skuRecord, keyword, "description") >= 3 || countKeywordInstances(sku.skuRecord, keyword, "bullets") >= 3
                }
            })
        }

    };
}
