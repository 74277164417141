import { SinglePlan, Subscription } from "../../types/chargebeeStructure";
import { API_CALL_getChargebeeConfig, API_CALL_getChargebeePlans, API_CALL_getActiveChargebeeSubscriptions, API_CALL_generateCheckout, API_CALL_generatePortal } from "../../Util/apiClient";
import { RandomId, CoreTeamAccount, Objectentries } from "../../types/datastructures";
import { firestoreDb } from "../../Util/firestore";

declare var Chargebee: any;

const testSite = "contentstatus-test";

/* export const chargebee = Chargebee.init({
    site : testSite
}); */


const generatePlan = (plans: Array<SinglePlan>, creditsNeeded: number) => {
    let lowestMargin = -1
    let bestPlan = ""
    for (var i in plans) {
        const currentPlan = plans[i].plan
        const currentMargin = currentPlan.cf_credits - creditsNeeded

        if (currentMargin > 0) {
            if (lowestMargin === -1) {
                lowestMargin = currentMargin
                bestPlan = currentPlan.id
            }
            if (currentMargin < lowestMargin) {
                lowestMargin = currentMargin
                bestPlan = currentPlan.id
            }
        }
    }
    return bestPlan;
}

const establishBestPlan = (plans: Array<SinglePlan>, neededCredits: number) => {
    const filteredPlans = plans.filter(plan => plan.plan.cf_one_time_payment === "True")
    const defaultPlan = filteredPlans[0].plan.id;

    return neededCredits < 0 ? defaultPlan : generatePlan(filteredPlans, neededCredits);
}

interface ChargebeeAppProps {
    teamId: string;
    chargebeeCustomerId: string;
}

interface Listener {
    handler: (availableCredits: number) => void
    error?: (error: any) => void
}

const speak = (listener: Listener, availableCredits: number) => {
    try {
        listener.handler(availableCredits);
    } catch (e) {
        if (listener.error) {
            listener.error(e);
        }
    }
}

export class ChargebeeAppClass {
    private plans: SinglePlan[];
    private props: ChargebeeAppProps;
    private teamCredits: number;

    private teamCreditsListeners: {
        [listenerId: string]: Listener
    }
    private cancelTeamCreditsSubscription: () => void | undefined

    public chargebee;

    private timer: {
        [x: string]: NodeJS.Timeout;
    } = {};

    private siteId: string;

    constructor(props: ChargebeeAppProps) {

        this.props = {...props, chargebeeCustomerId: props.teamId};

        this.teamCreditsListeners = {};

        this.subscribeToTeamCredits();
    }

    private setupChargebee = async () => {
        const timerId = RandomId();

        const inst = this;
        const configRes = await API_CALL_getChargebeeConfig();
        //console.log(configRes)
        this.siteId = configRes.data['site'] || testSite;
        this.chargebee = await new Promise(resolve =>
            this.timer[timerId] = setInterval(() => {
                if (window['Chargebee']) {
                    
                    clearInterval(inst.timer[timerId]);
                    delete inst.timer[timerId];
                    resolve(Chargebee.init({
                        site : this.siteId
                    }));
                }
                    
            }, 1000)
        );
    }

    private getChargebee = () => {
        if (this.chargebee) {
            return Promise.resolve(this.chargebee);
        } else {
            this.setupChargebee();
            
            const timerId = RandomId();
            
            const inst = this;
            return new Promise<any>(resolve =>
                this.timer[timerId] = setInterval(() => {
                    if (this.chargebee) {
                        
                        clearInterval(inst.timer[timerId]);
                        delete inst.timer[timerId];
                        resolve(this.chargebee);
                    }
                        
                }, 1000)
            );
        }
    }

    destructor = () => {
        if (this.cancelTeamCreditsSubscription) {
            this.cancelTeamCreditsSubscription();
        }
    }

    getPlans = () => {
        if(!this.plans) {
            return API_CALL_getChargebeePlans()
            .then(response => {
                this.plans = response.data;
                return this.plans;
            })
        } else {
            return Promise.resolve(this.plans);
        }
    }

    getActiveSubscriptions = () => {
        return API_CALL_getActiveChargebeeSubscriptions(this.props.chargebeeCustomerId)
        .then(response => {
            return response.data as Subscription[]
        });
    }

    chooseBestPlanId = (neededCredits: number) => {
        return this.getPlans()
        .then(plans => establishBestPlan(plans, neededCredits));
    }

    private subscribeToTeamCredits = () => {
        if(this.props.teamId) {
            this.cancelTeamCreditsSubscription = firestoreDb.collection('TeamAccount').doc(this.props.teamId)
            .onSnapshot(docSnapshot => {
                const coreTeam = (docSnapshot.exists) ? docSnapshot.data() as CoreTeamAccount : undefined;
                if (coreTeam && coreTeam.credits !== this.teamCredits) {
                    this.teamCredits = coreTeam.credits;
                    Objectentries(this.teamCreditsListeners).forEach(listener => {
                        speak(listener, coreTeam.credits);
                    })
                }
            })
        }
    }

    registerTeamCreditsListener = (handler: (availableCredits: number) => void, error?: (error: any) => void) => {
        const listener = {handler, error};
        const listenerId = RandomId();
        this.teamCreditsListeners[listenerId] = listener;
        speak(listener, (this.teamCredits) ? this.teamCredits : 0);
        return () => this.removeTeamCreditsListener(listenerId);
    }

    private removeTeamCreditsListener = (listenerId: string) => {
        delete this.teamCreditsListeners[listenerId];
    }

    openCheckout = async (plan_id: string, quantity = 1, addonIds: string[] = []) => {
        //console.log("wjat in the world!?")
        const options: {
            hostedPage: () => void,
            success?: (hostedPageId) => void, // Called on success of the modal
            close?: () => void, // Called when the modal is closed
            step?: (step) => void // Called during each step through of the checkout modal
        } = {
            hostedPage: async () => {
                const response = await API_CALL_generateCheckout(this.props.chargebeeCustomerId, plan_id, quantity, addonIds);
                return response.data;
            },
            success: (hostedPageId) => { },
            close: () => { },
            step: (step) => { }
        };
        const chargebee = await this.getChargebee();
        return chargebee.openCheckout(options);
    }

    openCustomerPortal = async (onClose?: () => void) => {
        const chargebee = await this.getChargebee();
        chargebee.setPortalSession(async () => {
            const response = await API_CALL_generatePortal(this.props.chargebeeCustomerId)
            return response.data;
        });

        chargebee.createChargebeePortal().open(onClose ? {close: onClose} : undefined);
    }

}

class ChargebeeSingleton {
    private chargebeeAppInstance: ChargebeeAppClass;

    get = (props: ChargebeeAppProps) => {
        if (!this.chargebeeAppInstance) {
            // test to see if window.Chargebee actually exists...
            // while (!window['Chargebee']) { console.log("waiting")}
            try {
                this.chargebeeAppInstance = new ChargebeeAppClass(props);
            } catch (e) {
                this.chargebeeAppInstance = undefined;
            }
        }
        return this.chargebeeAppInstance;
    }
}

export const ChargebeeService = new ChargebeeSingleton();