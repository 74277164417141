import React from 'react';
import ProgressCard from '../ProgressCard';
import './style.scss';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import {
    calculateFlaggedItems,
    getLatestRubric
} from '../../../../libraries/Util/utils';
import Grid from '@material-ui/core/Grid/Grid';

interface StatusSnapshotProps {
    skuRecord: SkuRecord
}

interface StatusSnapshotVersionState {

}

export default class StatusSnapshot extends React.Component<StatusSnapshotProps, StatusSnapshotVersionState> {

    getProgressCardSettings = (card: "live" | "health" | "accuracy" | "keyword" | "flagged", skuRecord: SkuRecord) => {
        let props;

        const flaggedData = calculateFlaggedItems(skuRecord);
        const critFlagsCount = flaggedData.criticalFlagsCount;
        const warnFlagsCount = flaggedData.warningFlagsCount + flaggedData.indicatorFlagsCount;
        const scoreText = (score) => score
            ? score === 5
                ? "Excellent"
                : score >= 4
                    ? "Very Good"
                    : score >= 3
                        ? "Average"
                        : "Needs Improvement"
            : "Needs Improvement";

        switch (card) {
            case "health":
                const rubric = getLatestRubric(skuRecord);

                const score = skuRecord.status.health.rubric[rubric].scores?.overall.score;

                const health = {
                    type: "number",
                    value: score,
                    // variation: 0,
                    progress: (score / 5)*100,
                    variationText: scoreText(score),
                    scheme: {
                        primary: score >= 4 ? '#4FDA03' : score >= 3 ? '#FFC000' : 'red',
                    }
                }
                props = {
                    icon: "dash statushealth",
                    title: "Health",
                    // subTitle: "Status",
                    ...health
                };
                break;
            case "accuracy":
                if (skuRecord.status.accuracy.previous) {
                    const value = skuRecord.status.accuracy.previous.overall;
                    const accuracy = {
                        type: "percent",
                        value,
                        progress: 0,
                        scheme: {
                            primary: value >= 80 ? '#4FDA03' : value >= 50 ? '#FFC000' : 'red',
                        },
                        variationText: value
                            ? value === 95
                                ? "Excellent"
                                : value >= 80
                                    ? "Very Good"
                                    : value >= 50
                                        ? "Average"
                                        : "Needs Improvement"
                            : "Needs Improvement",
                    }
                    props = {
                        icon: "dash statusaccuracy",
                        title: "Accuracy",
                        // subTitle: "Status",
                        ...accuracy
                    };
                } else {
                    const accuracy = {
                        type: "text",// as "percent",
                        value: "--",
                        progress: 0,
                        scheme: {
                            primary: 'grey'
                        },
                        variationText: 'Not Tested'
                    }
                    props = {
                        icon: "dash statusaccuracy",
                        title: "Accuracy",
                        // subTitle: "Status",
                        ...accuracy
                    };
                }
                break;
            case "keyword":
                const value = skuRecord.status.health.rubric[getLatestRubric(skuRecord)].scores?.seo.overall?.score;
                const keyword = {
                    type: "text",// as "percent",
                    value: value ? `${((value / 5) * 100).toFixed(1)}%` : "--",
                    progress: 0,
                    scheme: {
                        primary: !isNaN(value) ? value >= 4 ? '#4FDA03' : value >= 3 ? '#FFC000' : 'red' : 'grey',
                    },
                    variationText: scoreText(value)
                }
                props = {
                    icon: "dash statuskeyword",
                    title: "Keywords",
                    // subTitle: "Status",
                    ...keyword
                };
                break;
            case "flagged":
                    const flagged = {
                        value: critFlagsCount,
                        value1: warnFlagsCount,
                        scheme: {
                            primary: critFlagsCount > 0 ? "red" : 'red' // '#4B0082'
                        },
                        scheme1: {
                            primary: warnFlagsCount > 0 ? "#FFC000" : '#FFC000' // '#4B0082'
                        }
                    }


                    props = {
                        icon: "dash errors",
                        title: "Flags",
                        // subTitle: "Flags",
                        ...flagged,
                    };
                    break;
            case "live":
                const isLive = skuRecord.status.live;

                const live = {
                    type: "text" as "text",
                    value: isLive ? "Live" : "Not Live",
                    progress: isLive ? 100 : 0,
                    scheme: {
                        primary: isLive ? '#4FDA03' : 'red',
                    },
                    variationText: 'On this date'
                    // variationType: "change"
                }
                props = {
                    icon: "dash scorelive",
                    title: "Page",
                    // subTitle: "Status",
                    ...live
                };
                break;
        }

        return props;

    }

    render() {
        return <Grid container>
                    <div className="cards-line">
                        <ProgressCard {...this.getProgressCardSettings("live", this.props.skuRecord)} />
                        <ProgressCard {...this.getProgressCardSettings("health", this.props.skuRecord)} />
                        <ProgressCard {...this.getProgressCardSettings("flagged", this.props.skuRecord)} />
                        <ProgressCard {...this.getProgressCardSettings("accuracy", this.props.skuRecord)} />
                        <ProgressCard {...this.getProgressCardSettings("keyword", this.props.skuRecord)} />
                    </div>
            </Grid>
    }
}
