import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1% 3%',
    width: '100%',
  },
  headerrow: {
    display: 'flex',
    marginBottom: 20,
  },
  header: {
    width: '70%'
  },
  title: {
    fontWeight: 900,
    fontSize: 22,
    color: 'indigo',
    minHeight: '1rem',
  },
  'header-button-area': {
    marginLeft: 'auto',
    display: 'flex',
    ['@media only screen and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
  },
  'header-button': {
    margin: '0 5px',
  },
  contentContainer: {},
}));

interface TopLevelPageLayoutProps {
  title: string | JSX.Element;
  details?: JSX.Element;
  buttons?: JSX.Element[];
  customButtons?: JSX.Element;
  className?: string;
}

const TopLevelPageLayout: React.FunctionComponent<TopLevelPageLayoutProps> = (
  props
) => {
  const classes = useStyles();

  const title = props.title;
  const details = <p>{props.details}</p>;

  const buttons = props.buttons ? props.buttons : [];

  const className = props.className || '';

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes['headerrow']}>
        <div className={classes['header']}>
          <div className={classes['title']}>{title}</div>
          {details}
        </div>
        <div className={classes['header-button-area']}>
          {props.customButtons ? props.customButtons : buttons.map((button, idx) => (
            <div key={idx} className={classes['header-button']}>
              {button}
            </div>
          ))}
        </div>
      </div>
      <div className={clsx(classes.contentContainer, 'layoutContent')}>{props.children}</div>
    </div>
  );
};

export default TopLevelPageLayout;
