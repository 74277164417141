import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  makeStyles, Badge, Box, Hidden, IconButton, createStyles, Theme, MenuList, MenuItem, Divider
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import Logo from './Logo';
import { GlobalState, GlobalStateAppContext } from '../../libraries/types/ReportUp';
import { getCoreFromUserAccount } from '../../libraries/types/datastructures';
import { TermsModal, SiteLegalConfig, TermsModalTrigger } from '../../components/Legal';
import { history } from '../../libraries/Util';
import { ADMIN_IDS } from '../../constants';
import { ContentStatusRouteLinks } from '../../routes';
import { ContentStatusDataService, TeamData } from '../../libraries/DataService';
import { Notification } from '../../components/Notifications/datastructures';
import {orderBy} from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 100
    },
    typography: {
      padding: theme.spacing(2),
    },
    toolbar: {
      minHeight: 52,
      height: 52,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
      backgroundColor: '#fff'
    },
    fullHeight: {
      height: '100%'
    },
    toolbarIcon: {
      fontSize: '2rem'
    },
    barMenu: {
      zIndex: 1110,
      width: 220
    },
    barMenuList: {
      padding: "0 4px"
    },
    appLogo: {
      maxHeight: 40,
      cursor: 'pointer !important'
    },
    dropDownMenu: {
      width: '100%'
    },
    notificationMenuItem: {
      textAlign: 'center',
      width: '100%'
    },
    newNotification: {
      backgroundColor: '#fcf0ff',
      transition: "background-color 2s"
    }
  }),
);

interface NotificationsButtonProps {
  notifications: any[];
  setOpen: (id: string) => void;
  open: string;
  menuName: string;
  globalState: GlobalState;
}

const NotificationsButton: React.FunctionComponent<NotificationsButtonProps> = (props) => {
  const [notifications, setNotifications] = useState<TeamData['notifications']>(null);
  const [lastAck, setLastAck] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  // const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const classes = useStyles();

  const {menuName} = props;//"notificationsButton";
  const teamId = props.globalState.teamJobData.teamId;

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const shouldOpen = props.open !== menuName;
    if (shouldOpen) {
      setAnchorEl(event.currentTarget);
      props.setOpen(menuName);
      setPlacement(newPlacement);
      setTimeout(() => {
        ContentStatusDataService.notifications({teamId}).reportAck();
      }, 5000);
    } else {
      props.setOpen('');
    }
  };

  useEffect(
    () => {    
      function handleStatusChange(notifications: TeamData['notifications']) {      
        setNotifications({...notifications});    
        setLastAck(notifications.lastAck);
      }    
      
      /* const cleanup =  */ContentStatusDataService.notifications({teamId}).subscribe(handleStatusChange)   

      // Specify how to clean up after this effect:    
      return () => {
        //(await cleanup)();
      };
    },
    [teamId]
  );

  const notes = notifications && Object.keys(notifications.list).length > 0
    ? orderBy(Object.values(notifications.list), ['date'],['desc'])
    : [{
      id: '',
      title: "No notifications.",
      text: "",
      date: 0,
    } as Notification]

  const menuElements = [];

  notes.forEach((note, idx) => {
    menuElements.push(<MenuItem key={idx} className={clsx((note.date > lastAck) ? classes.newNotification:'')}>
      <Typography className={clsx(classes.typography, classes.notificationMenuItem)}>
        {note.title}
      </Typography>
    </MenuItem>);

    if ((idx + 1) < notes.length) {
      menuElements.push(<Divider key={idx+"Divider"} />)
    }
  });

  return (
    <div id={menuName+"MenuItem"} className={classes.root}>
      <Popper  id={menuName} open={props.open === menuName} anchorEl={anchorEl} placement={placement} transition className={classes.barMenu}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <MenuList>
                {menuElements}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton color="default" onClick={handleClick('bottom-end')}>
        <Badge
          badgeContent={notes.filter(note => note.id && note.date > lastAck).length}
          color="primary"
          variant="dot"
        >
          <NotificationsIcon className={classes.toolbarIcon} />
        </Badge>
      </IconButton>
    </div>
  );
}

interface ProfileMenuProps {
  setOpen: (id: string) => void;
  open: string;
  globalState: GlobalState;
  menuName: string;
  setModalTermDefHandler: (termDefId: keyof typeof SiteLegalConfig | undefined) => void
}

const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  // const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const classes = useStyles();

  const { globalState, menuName, setModalTermDefHandler } = props;

  // const menuName = pro"profileMenu";

  const userDisplayName = globalState.activeUser.isSignedIn
    ? `${globalState.activeUser.user.diffUserId
      ? "LOGGED IN AS "
      : ""
    }${globalState.activeUser.user.displayName}`
    : 'Profile';

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const shouldOpen = props.open !== menuName;
    if (shouldOpen) {
      setAnchorEl(event.currentTarget);
      props.setOpen(menuName);
      setPlacement(newPlacement);
    } else {
      props.setOpen('');
    }
  };

  return (
    <div id={menuName+"MenuItem"} className={classes.root}>
      <Popper id={menuName} open={props.open === menuName} anchorEl={anchorEl} placement={placement} transition className={classes.barMenu}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <MenuList className={classes.barMenuList}> 
                <MenuItem>{userDisplayName}</MenuItem>
                <Divider />
                {dropDownLink("Account Settings", "/settings/profile", {applyPrefix: true, cls: classes.dropDownMenu})}
                {/* dropDownLink("Billing", "/settings/billing", {applyPrefix: true, cls: classes.dropDownMenu}) */}
                {dropDownLink("Downloads", "/downloads", {applyPrefix: true, cls: classes.dropDownMenu})}
                {/* {dropDownLink("Support", "/support", {applyPrefix: false, cls: classes.dropDownMenu, target: '_blank'})} */}
                <Divider />
                {adminProfileDropdown(globalState)}
                <MenuItem className="CSProfileMenuItem" onClick={globalState.signOut}><div className="jsLink">Logout</div></MenuItem>
                <Divider />
              </MenuList>
              <div className={classes.typography}>{termsDropdown(setModalTermDefHandler)}</div>
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton color="default" onClick={handleClick('bottom-end')}>
        <PersonOutlineOutlinedIcon className={classes.toolbarIcon} />
      </IconButton>
    </div>
  );
}

type TopBarProps = {
  className?: string
  onMobileNavOpen?: () => any
};


const usePrefix = false;
const PATH_PREFIX = usePrefix ? "/mshell" : '';

const dropDownLink = (
  label: string, 
  path: string, 
  options: {applyPrefix?: boolean; cls?: string; target?: string;} = {}
) => {
  const applyPrefix = options.applyPrefix !== undefined ? options.applyPrefix : true;
  const cls = options.cls !== undefined ? options.cls : '';
  const target = options.target !== undefined ? options.target : '';

 return <MenuItem 
    key={label} 
    className="CSProfileMenuItem"
  >
    <RouterLink
      to={(applyPrefix ? PATH_PREFIX : '') + path}
      className={cls}
      children={label}
      target={target}
    />
  </MenuItem>
}


/* function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
} */

const pathActive = (location: Location, path: string) => location.pathname.startsWith(path)

const RouteLinks: React.FunctionComponent<{ links: any[] }> = (props) => {
  const routeLinkObjs = props.links;
  const location = useLocation();

  return <React.Fragment>
    {routeLinkObjs ? routeLinkObjs.map(routeLink => <Typography
      className="CSNavLinkItem"
      variant="h6"
      key={routeLink.name}
      style={{
        width: routeLink.name.length * 10
      }}
    >
      <RouterLink
        className={clsx("CSNavLink", pathActive(location as any, PATH_PREFIX + routeLink.path) ? 'active' : '')}
        {...((routeLink.path) ? { to: PATH_PREFIX + routeLink.path } : { to: routeLink.name, disabled: true })}
      >
        {routeLink.name}
      </RouterLink>
    </Typography>) : <></>}
  </React.Fragment>
}

const adminProfileDropdown = (globalState: GlobalState) => {
  const coreUser = getCoreFromUserAccount(globalState.activeUser)

  return (coreUser && ADMIN_IDS.indexOf(coreUser.userId) !== -1)
    ? [
      dropDownLink("Admin UI", "/admin"),
      /* dropDownLink("NewShell UI", "/newshell", false),
      dropDownLink("OldShell UI", "/oldshell", false),
      dropDownLink("MShell UI", "/mshell", false), */
      <Divider key={'adminDropdownEndDivider'} />
    ]
    : null
}

const termsDropdown = (setModalTermDefHandler: (termDefId: keyof typeof SiteLegalConfig | undefined) => void) => {
  const date = new Date().getFullYear();
  const copyRight = <span style={{fontSize: 10, margin: '0 -10px'}}>&copy; {date} Copyright:{' '} ContentStatus.com</span>;

  const onClick = (termDef: typeof SiteLegalConfig['CookiesPolicy']) => setModalTermDefHandler(termDef.iframeTitle.replace("CS_", "") as any);
  
  const genTrigger = (termDef: typeof SiteLegalConfig['CookiesPolicy']) => <TermsModalTrigger 
    termDef={termDef} 
    onClick={onClick} 
  />
  const privacy = genTrigger(SiteLegalConfig['PrivacyPolicy']);
  const tou = genTrigger(SiteLegalConfig['TermsOfUse']);
  const cookies = genTrigger(SiteLegalConfig['CookiesPolicy']);

  const terms = <span style={{ fontSize: ".9rem" }}>
    {privacy} - {tou}<br />{cookies}
  </span>

  const centeredDiv = (children: any) => <div style={{ textAlign: "center" }}>{children}</div>

  return <React.Fragment>
    {centeredDiv(copyRight)}
    {centeredDiv(terms)}
  </React.Fragment>
}


const TopBar: React.ComponentType<TopBarProps> = React.memo(({ className, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [open, setOpen] = React.useState<string>(undefined);
  const [modalTermDef, setModalTermDef] = React.useState<keyof typeof SiteLegalConfig>(undefined);

  const globalState = useContext(GlobalStateAppContext);

  const onMobileNavOpen = rest.onMobileNavOpen ? rest.onMobileNavOpen : () => { }

  const menuNames = ["notificationsButton", "profileMenu"]

  const setMenuOpenHandler = (id: string) => {

    const clickOff = (evt) => { 
      const clickedMenuMenuItem = menuNames.map(name => name+"MenuItem").find(menuName => {
        const el = document.getElementById(menuName);
        const res = el && el.contains(evt.target);
        return res
      });

      const clickedMenu = clickedMenuMenuItem
      ? clickedMenuMenuItem.replace("MenuItem", '')
      : ''

      setOpen(prev => {
        if (clickedMenu) {
          return prev;
        } else {
          document.removeEventListener('click', clickOff);
          return '';
        }
      });
    };
    
    setOpen(prev => {
      if (id === '') {
        document.removeEventListener('click', clickOff);
      } else if (!!!menuNames.find(menuName => menuName === prev)) {
        document.addEventListener('click', clickOff);
      }
      return id;
    });
  }

  const setModalTermDefHandler = (termDefId: keyof typeof SiteLegalConfig | undefined) => {
    setModalTermDef(termDefId);
  }
  return (<React.Fragment>
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <div onClick={() => history.push("/")}>
          <Logo className={clsx(classes.appLogo)} partnerLogo={globalState.partnerLogo} />
        </div>
        {globalState.activeUser.isSignedIn &&
          globalState.activeUser.user.core &&
          globalState.activeUser.user.core.userId ? <React.Fragment>
          <Box flexGrow={1} />
          <RouteLinks links={ContentStatusRouteLinks} />
          <div style={{width: 20}} />
          {/* <Hidden mdDown> */}
            <NotificationsButton notifications={notifications} {...{ open, globalState, setOpen: setMenuOpenHandler, menuName: menuNames[0] }} />
            <ProfileMenu {...{ open, setOpen: setMenuOpenHandler, globalState, menuName: menuNames[1], setModalTermDefHandler }} />
          {/* </Hidden> */}
          {null && <Hidden lgUp>
            <IconButton
              color="primary"
              onClick={onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>} 
        </React.Fragment> : null}
      </Toolbar>
    </AppBar>
    <TermsModal open={modalTermDef !== undefined} onClose={() => setModalTermDefHandler(undefined)} termDef={modalTermDef} />
  </React.Fragment>);
});



export default TopBar;
