import React from 'react';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModal,
  MDBMask,
  MDBInput,
  MDBView,
} from 'mdbreact';
import { CSUIButton } from '../CSUI';
import { RouteComponentProps } from 'react-router-dom';
//import config from '../../libraries/config';
import './BetaLock.scss';

import CSLogo from './../../media/Header/ContentStatus_Basic Logo.png';

const BETA_PASSWORD = "Launch2020";
const ENABLE_HOSTCHECK = true;
const HOSTCHECK = ENABLE_HOSTCHECK //&& !config.isProdHostEnv;

interface BetaLockProps {
  router: RouteComponentProps<any>;
  signIn: () => void;
}

interface State {
  password: string;
  success?: boolean;
  error?: boolean;
  passwordVisible: boolean;
}

class BetaLock extends React.Component<BetaLockProps, State> {
    constructor(props: BetaLockProps) {
        super(props);

        this.state = {
          password: '',
          passwordVisible: false
        };
    }

    componentDidMount() {
      if(HOSTCHECK) {
        this.props.signIn();
      }
    }

    passwordOnChange = (e: React.FormEvent<HTMLInputElement>) => {
      e.persist();
      const input = e.currentTarget;
      this.setState({password: input.value, error: false});
    }

    togglePasswordVisibility = () => this.setState(prev => ({passwordVisible: !prev.passwordVisible}));

    formOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      this.passwordSubmit();
    }
    
    passwordSubmit = () => {
      if (this.state.password === BETA_PASSWORD) {
        // this.setState({success: true, error: false});
        this.props.signIn();
      } else {
        this.setState({success: false, error: true});
      }
    }

    lockUI() {
        return (
          <div id='betalock'>
            <MDBView>
              <MDBMask
                className='d-flex justify-content-center align-items-center cloudy-knoxville-gradient'
                // overlay='stylish-strong'
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                      <MDBModal
                        noClickableBodyWithoutBackdrop={false}
                        size='sm'
                        cascading
                        overflowScroll={false}
                        // className='modal-avatar'
                        isOpen={true}
                        inline={true}
                        backdrop={false}
                        toggle={() => { }}
                      >
                        <MDBModalHeader className='mx-auto'>
                          <img
                            src={CSLogo}
                            alt='avatar'
                            // className='img-responsive'
                            // className='rounded-circle img-responsive'
                          />
                        </MDBModalHeader>
                        <MDBModalBody className='text-center mb-1'>
                          <h2 className='mt-1 mb-2'>Beta Access:</h2>
                          <p className="motd-alert">*UNAUTHORIZED ACCESS IS PROHIBITED*</p>
                          <form className={`mx-3 grey-text beta-password-form`} onSubmit={this.formOnSubmit}>
                            <div>
                              <MDBInput
                                id="beta-password"
                                label='Enter password'
                                group
                                type={this.state.passwordVisible ? 'text' : 'password'}
                                icon={this.state.passwordVisible ? 'eye-slash' : 'eye'}
                                iconClass="beta-password-icon"
                                iconSize={"1x"}
                                required
                                validate
                                value={this.state.password}
                                onChange={this.passwordOnChange}
                                onIconClick={this.togglePasswordVisibility}
                                className={(this.state.error) ? "is-invalid" : ''}
                              >
                                <div className="invalid-feedback">
                                  Incorrect Password
                                </div>
                              </MDBInput>
                            </div>
                          </form>
                        </MDBModalBody>
                        <MDBModalFooter className='justify-content-center'>
                          <CSUIButton color='green' onClick={this.passwordSubmit}>
                            Unlock
                          </CSUIButton>
                        </MDBModalFooter>
                      </MDBModal>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBMask>
            </MDBView>
          </div>
        );
    }

    render() {
      return (HOSTCHECK || this.state.success)
      ? null // <Redirect to="/login" />
      : this.lockUI();
    }

}

export default BetaLock;
