import makeStyles from '@material-ui/core/styles/makeStyles'

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        color: '#000000',
        height: '600px',
        width: '100%',
        position: 'relative',
        ['@media only screen and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
            overflow: 'hidden',
            paddingTop: 10
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            height: 755
        },
    },
    header: {
        width: 300,
        height: '213px',
        position: 'relative',
        right: 18,
        bottom: 15,
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            position: 'initial'
        }
    },
    headerNoneActive: {
        width: '200px',
        height: '223px',
        marginTop: -75
    },
    title: {
        fontSize: '2.2rem',
        fontWeight: 900,
        margin: 'unset',
        marginLeft: 40,
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            marginLeft: 0
        },
    },
    description: {
        textAlign: 'left',
        fontSize: '1.2rem',
        fontWeight: 600,
        width: 330,
        marginLeft: 40,
        ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            width: 200
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
            marginLeft: 0
        },
    },
    instruction: {
        marginTop: 0, //theme.spacing(3),
        fontSize: '1.9rem',
        fontFamily: 'Caveat !important',
        textAlign: 'center',
        position: 'relative',
        right: 0,
        left: 10,
        top: 15,
        ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            left: -13,
            fontSize: '1.7rem',
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        }
    },
    planTabs: {
        position: 'relative',
        right: 15,
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            position: 'initial'
        }
    },
    plan: {
        position: 'relative',
        width: '90%',
        border: '1px solid #D0D0D0',
        borderRadius: '10px',
        margin: theme.spacing(1, 0),
        cursor: 'pointer',
        ['@media only screen and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
            top: 16
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            top: 184
        },
        ['@media only screen and (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
            top: 220
        },
    },
    planContent: {
        maxWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 80
        }
    },
    planPrice: {
        fontSize: '1.5rem',
        lineHeight: '22px',
        margin: theme.spacing(1, 0),
    },
    planPriceActive: {
        margin: 'unset',
        fontWeight: 900,
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 18
        }

    },
    planPriceDescription: {
        fontSize: '.9rem',
        fontWeight: 500,
        width: '60%',
        color: '#000',
        textAlign: 'center',
        lineHeight: '10px',
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            width: '95%',
        }
    },
    planActive: {
        height: '74px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planOverlay: {
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            position: 'absolute',
            right: -5,
            width: 100,
            height: 490,
            bottom: 0,
            background: 'linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1))'
        }
    },
    planColumns: {
        position: 'absolute',
        right: 0,
        top: 30,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        ['@media only screen and (max-width: 1014px)']: { // eslint-disable-line no-useless-computed-key
            right: -270,
            top: 50
        },
        ['@media only screen and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
            top: 60,
            right: 0,
            height: 570,
            width: '65%',
            overflowY: 'auto'
        },
        ['@media only screen and (max-width: 728px)']: { // eslint-disable-line no-useless-computed-key
            width: '60%'
        },
        ['@media only screen and (max-width: 635px)']: { // eslint-disable-line no-useless-computed-key
            width: '50%'
        },
        ['@media only screen and (max-width: 522px)']: { // eslint-disable-line no-useless-computed-key
            width: '45%'
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            top: 225,
            overflowY: 'auto',
            left: 100,
            height: 580,
            width: '74%'
        },
        ['@media only screen and (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
            top: 261,
        },
        ['@media only screen and (max-width: 320px)']: { // eslint-disable-line no-useless-computed-key
            top: 260,
            left: 80
        },
    },
    planSelect: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    planRecomended: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '20px',
        backgroundColor: theme.palette.secondary.main,
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    planRecomendedLabel: {
        fontSize: '0.7rem',
        transform: 'rotate(-90deg)',
        color: '#ffffff',
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    tabSubTitle: {
        fontSize: '1.1rem',
        textAlign: 'center',
        fontWeight: 'initial',
        marginTop: theme.spacing(1.5),
    },
    flexRow: {
        flexDirection: 'row'
    },
    flexRowReverse: {
        flexDirection: 'row-reverse'
    }
}));

export default styles;