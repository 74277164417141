const config = {
    defaultPort: 3000,
    port: undefined
}

export const getPort = () => {
    if (!config.port) {
        config.port = normalizePort(process.env.PORT || config.defaultPort);
    }
    return config.port;
}

function normalizePort(val: number|string): number|string|boolean {
    const port: number = (typeof val === 'string') ? parseInt(val, 10) : val;
    if (isNaN(port)) { 
        return val; 
    } else if (port >= 0) {
        return port;
    } else {
        return false;
    }
}

const isProdHostEnv = window.location.host === 'app.contentstatus.com';
const forceProdBackend = false;
const forceProdGCP = false;

const prodEnv = {
    GCP_PROJECT: 'twd-contentstatus',
    DEPLOY_ENV: 'prod',
    CR_SVC_UUID: 'sf3pg75z2q'
}

const stagingEnv = {
    GCP_PROJECT: 'contentstatus-staging-01',
    DEPLOY_ENV: 'staging',
    CR_SVC_UUID: 'uzbo62v4qa'
}

const customEnv = {
    REACT_APP_DEV_ID: process.env.REACT_APP_DEV_ID,
    GCP_PROJECT: forceProdBackend || forceProdGCP ? prodEnv.GCP_PROJECT : process.env.REACT_APP_GCP_PROJECT || stagingEnv.GCP_PROJECT,
    DEPLOY_ENV: forceProdBackend || forceProdGCP ? prodEnv.DEPLOY_ENV : process.env.REACT_APP_DEPLOY_ENV || stagingEnv.DEPLOY_ENV,
    CR_SVC_UUID: forceProdBackend ? prodEnv.CR_SVC_UUID : process.env.REACT_APP_CR_SVC_UUID || stagingEnv.CR_SVC_UUID
}

// export default {...config, getPort};
const exportVariable = {getPort, NODE_ENV: process.env.NODE_ENV, customEnv, isProdHostEnv, forceProdBackend};
export default exportVariable