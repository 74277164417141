import { Button, CircularProgress, MenuItem, Select, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { API_URL, copyToClipboard, defaultVal, retailerNameRender } from "../../libraries/types/datastructures";
import { CSUIButton, CSUISegment } from "../CSUI";
import { RHInput } from "../CSUI/RHInput";
import "./style.scss";
import { useState } from "react";
import ctaImage from "../../media/DigitalSOS/PublicCTA.png";
import { CSUIToolTip } from "../CSUI/CSUIToolTip";
import { SOSCharts } from "./dscharts";

interface DSPublicResultsUIProps {
    tableData?: any[];
    onSubmit: (email: string) => Promise<void>;
  }

export const DSPublicResultsUI: React.FC<DSPublicResultsUIProps> = (props) => {

  const [submitted, setSubmitted] = useState<"false" | "submitting" | "true">("false");
  const { register, control, handleSubmit, watch,  formState: { errors, isValid }/* reset */ } = useForm<{email: string}>({
    //defaultValues: { retailer: "amazon", input: "", inputType: "searchTerms" },
    mode: "onChange",
  });

  const email = watch("email");

  const onSubmit = async () => {
    setSubmitted("submitting");
    const pro = props.onSubmit(email);
    setTimeout(() => setSubmitted("true"), 2000);
    await pro;
  }

  const ctaButton = <CSUIButton 
    onClick={() => onSubmit()}
    disabled={!isValid} 
    color="green" 
    label="Get Report" 
    slim 
  />
  
  
  const ctaBox = submitted === 'submitting'
  ? <div id="loading">
    <CircularProgress />
  </div>
  : submitted === 'true'
    ? <div>
      <h2>Thanks!</h2>
      <div>Check your inbox in a few minutes for the full report showcasing how you and your competitors compare for keywords in your category.</div>
    </div>
    : <>
      <div>
        <h2>Get the full report!</h2>
        <div>Share your email and we’ll send you a full report showcasing how you and your competitors compare for keywords in your category.</div>
      </div>
      <div>
        <RHInput
          control={control}
          type="text"
          name="email"
          label={'Email'}
          rules={{ required: true }}
          validateEmail={true}
          gridItem={false}
        />
        </div>
      <div>{isValid ? ctaButton : <CSUIToolTip title={"Please input valid email address"} placement="right">
          <div style={{display: 'inline'}}>{ctaButton}</div>
        </CSUIToolTip>
      }</div>
    </>

  return <div id="public-results">
    <style>{`
      div#digital-shelf-container
      {
        height: fit-content;
      }
    `}</style>
    <div className="public-chart-title">
      {/* <div>[Snapshot]</div> */}
      <div>Page 1 Results</div>
    </div>
    <SOSCharts public={true} tableData={props.tableData}/>
    <div className="cta">
      {ctaBox}
    </div>
    {/* <div className="cta-image"><img src={ctaImage} /></div> */}
  </div>
}