import firebase from 'firebase';
import 'firebase/firestore';
import { Objectentries, CoreUserAccount, JobData, CoreTeamAccount } from '../types/datastructures';
import { chunk } from 'lodash';
import { DashboardInfo } from '../../pages/Dashboard/datastructures';
import config from '../config';

const genFirestoreDb = () => {
    // Configure Firebase.
    const prodConfig = {
        apiKey: 'AIzaSyAHUPv3FHRmRv3GvTrDAhnM0RBx3n_EjJI',
        authDomain: 'twd-contentstatus.firebaseapp.com',
        databaseURL: 'https://twd-contentstatus.firebaseio.com',
        projectId: 'twd-contentstatus',
        storageBucket: 'twd-contentstatus.appspot.com',
        messagingSenderId: '868590971854',
        appId: "1:868590971854:web:4f8dd5ade72da2aa84199f"
    };
    
    const stagingConfig = {
        apiKey: "AIzaSyBhs-p5DViP9blgdnaEgc1vgmw6BwV45Wc",
        authDomain: "contentstatus-staging-01.firebaseapp.com",
        projectId: "contentstatus-staging-01",
        storageBucket: "contentstatus-staging-01.appspot.com",
        messagingSenderId: "651257007337",
        appId: "1:651257007337:web:62da9e2e33dfa8387e7478"
    };
    const appConfig = config.customEnv.DEPLOY_ENV === 'prod'
    ? prodConfig
    : stagingConfig;

    const app = firebase.initializeApp(appConfig);
    return app;
}
export const firebaseInstance = genFirestoreDb();
export const firestoreDb = firebaseInstance.firestore();

// Object.assign(window, {firestoreDb})

function DeleteCoreJobFinal(jobId: string, teamId: string, db: firebase.firestore.Firestore) {
    return DeleteFbCollection(db.collection('testJobData').doc(jobId).collection('data'))
    .then(() =>
        mapFbCollection(
            db.collection('testJobData').doc(jobId).collection('grepsr'),
            (doc) => DeleteFbCollection(doc.ref.collection('data'))
            .then(() => doc.ref.delete())
        )
        .then(() =>
            db.collection('testJobData').doc(jobId).delete()
            .then(() =>
                Promise.all([
                    DeleteFbCollection(db.collection('brandJobData').doc(jobId).collection('retailerMappedData')),
                    DeleteFbCollection(db.collection('brandJobData').doc(jobId).collection('healthStatusData'))
                ])
                .then(() =>
                    db.collection('brandJobData').doc(jobId).delete()
                    .then(() =>
                        db.collection('jobRequestTracker').doc(jobId).get()
                        .then(docSnaphot => {
                            const data = docSnaphot.data();
                            // tslint:disable-next-line:variable-name
                            return Promise.all((data) ? Objectentries(data.requests).map(request_id => DeleteFbDoc(
                                db, 'grepsr_requests', request_id
                            )) : [])
                            .then (() => {
                                return db.collection('jobRequestTracker').doc(jobId).delete()
                                .then (() => {
                                    return db.collection('brandJobs').doc(jobId).delete()
                                    .then(() =>
                                    db.collection('TeamAccount').doc(teamId).collection('jobs').doc(jobId).delete())
                                })
                            })

                        })

                    )
                )
            )
        )
    );
}

const DeleteFbDoc = (db: firebase.firestore.Firestore, collection: string, docId: string) => db.collection(collection).doc(docId).delete()

const DeleteFbCollection = (collectionRef: firebase.firestore.CollectionReference) => {
    return mapFbCollection(collectionRef, doc => doc.ref.delete());
}

const mapFbCollection = <T>(
    collectionRef: firebase.firestore.CollectionReference,
    mappedFn: (doc: firebase.firestore.QueryDocumentSnapshot, collectionRef: firebase.firestore.CollectionReference) => Promise<T>
) => {
    return collectionRef.get()
    .then(snapshot =>
        Promise.all(snapshot.docs.map(doc => mappedFn(doc, collectionRef)))
    );
}

export const FirestoreUtilFns = {
    DeleteCoreJobFinal,
    DeleteFbCollection
}

export const getCoreUserAccount = (userId: string) => firestoreDb.collection('UserAccount').doc(userId).get()
.then(docSnapshot => (docSnapshot.exists) ? docSnapshot.data() as CoreUserAccount : undefined)

export const getDashboardInfo = async (userId: string): Promise<DashboardInfo> => {
    const userInfo: CoreUserAccount = await getCoreUserAccount(userId);
    const teamId: string = userInfo.teamId;

    let result;
    const resData = await firestoreDb.collection('TeamCatalog')
        .doc(teamId)
        .collection('DashboardSnapshots')
        .orderBy('timestamp', 'desc')
        .limit(1)
        .get();

    resData.docs.forEach((data) => {
        result = data.data() as DashboardInfo;
    });
    return result;
};

const delay = (sec: number) => new Promise(resolve => setTimeout(resolve, sec*1000));
const batchExecuteAll = <T, E>(
    executor: (itemArray: E[], sink?: T) => Promise<T>,
    array: E[],
    batchSize: number,
    delaySec: number = .5,
    sinkInit: T = [] as any as T
): Promise<T> => {
    const batchedArray = chunk(array, batchSize);
    const recur = (promise: Promise<T>, innerBatchedArray: E[][]): Promise<T> => {
        if (innerBatchedArray.length > 0) {
            return recur(
                promise.then(
                    (sink) => delay(delaySec).then(() => executor(innerBatchedArray[0], sink))
                ),
                innerBatchedArray.slice(1),
            );
        } else {
            return promise;
        }
    }
    return recur(executor((batchedArray[0]) ? batchedArray[0] : [], sinkInit), batchedArray.slice(1));
}

function batchedDataWriter(dataArray: any[], docId: string, collection: string, subCollection: string, chunkSize: number = 500, rowId?: string) {
    if (dataArray.length > 0) {
        const batcher = (rows: any[], idxTracker: number[]) => {
            const batch = firestoreDb.batch();
            // console.log(rows.length)
            rows.forEach((row, idx) => {
                // console.log(row[rowId])
                const ref = firestoreDb.collection(collection).doc(docId)
                .collection(subCollection).doc((1000000000+idx+(idxTracker[0]*chunkSize)).toString());
                batch.set(ref, row);
            });
            return batch.commit()
            .then(() => idxTracker.slice(1));
        }
        const sinkInit = [];
        for (let i = 0; i < (Math.ceil(dataArray.length) / chunkSize); i++) {
            sinkInit.push(i);
        }
        return batchExecuteAll(batcher, dataArray, chunkSize, 0, sinkInit)
        .then(() => void 0)
        // return recur(batcher(firestoreDb, mappedDataChunks[0]), firestoreDb, mappedDataChunks, 1);
    } else {
        return Promise.resolve();
    }
}

export const coreJobDataSet = (jobData: JobData, teamId: string) => {
    const { datasetId } = jobData;
    if (datasetId) {
        // tslint:disable-next-line:no-console
        // console.log(datasetId)
        const masterCsv = { ...jobData.masterCsv};
        delete masterCsv.data;

        const skuDataCollection = 'sku_datasets';

        // const { meta, errors } = masterCsv;
        return firestoreDb.collection(skuDataCollection).doc(datasetId).set({...jobData, masterCsv: JSON.stringify(masterCsv)})
        .then(() => {
            return batchedDataWriter(jobData.masterCsv.data, datasetId, skuDataCollection, 'data')
            .then(() => {
                return firestoreDb.collection('TeamAccount').doc(teamId)
                .collection('datasets').doc(datasetId).set({datasetId})
                .then(() => {
                    return datasetId;
                });
            });
        })
    } else {
        return Promise.resolve(datasetId);
    }
}

export const coreJobDataGet = (datasetId: string, limit: number = 0): Promise<JobData | undefined> => {

    const skuDataCollection = 'sku_datasets';

    return firestoreDb.collection(skuDataCollection).doc(datasetId).get()
    .then(docSnapshot => {
        if (docSnapshot.exists){
            const snapshotData = docSnapshot.data();
            const {skuIdMap, fileName, masterCsv} = snapshotData!;
            // const jobData
            return firestoreDb.collection(skuDataCollection).doc(datasetId).collection('data').get()
            .then(colSnapshot => {
                return {
                    datasetId,
                    fileName,
                    skuIdMap,
                    masterCsv: {
                        ...JSON.parse(masterCsv),
                        data: // colSnapshot.docs.slice(...((limit > 0) ? [0, limit] : [0])).map(doc => doc.data())!
                        colSnapshot.docs.map((doc, idx) => (limit > 0 && idx >= limit) ? {} : doc.data())!
                    }
                }
            })
        } else {
            return undefined;
        }
    })
}

export const getCoreTeamAccount = (teamId: string) => {
    return firestoreDb.collection('TeamAccount').doc(teamId).get()
    .then(docSnapshot => (docSnapshot.exists) ? docSnapshot.data() as CoreTeamAccount : undefined)
}


/* const fixTeamDatasets = () => {
    return firestoreDb.collection('brandJobs').doc('859b6fa0-0479-452e-8d93-623981008fd6')
    .collection('datasets').get()
    .then(colSnapshot => {
        return Promise.all(colSnapshot.docs.map(docSnapshot => {
            return firestoreDb.collection('TeamAccount').doc('859b6fa0-0479-452e-8d93-623981008fd6')
            .collection('datasets').doc(docSnapshot.id).set(docSnapshot.data())
            .then(() => docSnapshot.ref.delete());
        }));
    });
} */

/* Object.assign(window, {FSTest:{
    coreJobDataGet
}}) */
