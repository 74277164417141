import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Spinner from './components/Spinner';
import NewJob from './components/NewJob';
import Notifications from './components/Notifications';
import NewUserRegistration from './components/NewUser/NewUserRegistration';
import SiteAdminSection from './components/SiteAdminSection';
import { generateRoutes, RouteObject } from './libraries/Util/routing';
import NewAccuracyJob from './components/NewAccuracyJob';
import { NewMainLayout } from './layouts/NewMainLayout';
import { ElasticReindexDemo } from './pages/Catalog/elastic';
import DigitalShelf from './components/DigitalShelf';

const AccuracyJob = lazy(() => import('./pages/AccuracyJob'));
const Billing = lazy(() => import('./pages/Billing'));
const Catalog = lazy(() => import('./pages/Catalog'));
const CatalogProduct = lazy(() => import('./pages/CatalogProduct'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Downloads = lazy(() => import('./pages/Downloads'));
const MyTeam = lazy(() => import('./pages/MyTeam'));
const Profile = lazy(() => import('./pages/Profile'));
const RuleBuilder = lazy(() => import('./pages/RuleBuilder'));
const ScoreCards = lazy(() => import('./pages/ScoreCards'));
const Workflow = lazy(() => import('./pages/Workflow'));
const Jobs = lazy(() => import('./pages/MyJobs'));
const Reports = lazy(() => import('./pages/Reports'));
const DemoNavbar = lazy(() => import('./pages/DemoNavbar'));
const DemoNLReports = lazy(() => import('./components/Reports'));
const DemoReports = lazy(() => import('./pages/DemoReports'));

const routes: RouteObject[] = [
  {
    path: '/jobs',
    exact: true,
    component: Jobs,
    children: [
      {
        path: '/new',
        exact: true,
        component: NewJob,
      },
      {
        path: '/accuracy',
        exact: true,
        component: NewAccuracyJob,
      },
    ],
  },
  {
    path: '/demo/mapping',
    exact: true,
    component: AccuracyJob,
  },
  {
    path: '/settings/billing',
    exact: true,
    component: Billing,
  },
  {
    path: '/settings/profile',
    exact: true,
    component: Profile,
  },
  {
    path: '/catalog',
    exact: true,
    component: Catalog,
    children: [
      {
        path: '/product',
        exact: true,
        component: CatalogProduct,
      },
    ],
  },
  {
    path: '/audit-rules',
    exact: false,
    component: RuleBuilder,
  },
  {
    path: '/workflow',
    exact: false,
    component: Workflow,
  },
  {
    path: '/old-reports',
    exact: false,
    component: Reports,
  },
  {
    path: '/reports',
    exact: false,
    component: DemoReports,
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/downloads',
    exact: true,
    component: Downloads,
  },
  {
    path: '/team',
    exact: true,
    component: MyTeam,
  },
  {
    path: '/scoring',
    exact: true,
    component: ScoreCards,
    children: [
      {
        path: '/rubric',
        exact: true,
        component: ScoreCards,
      },
      {
        path: '/defaults',
        exact: true,
        component: ScoreCards,
      },
    ],
  },
  {
    path: '/notifications',
    exact: true,
    component: Notifications,
  },
  {
    path: '/betatester-signup',
    exact: true,
    component: NewUserRegistration,
  },
  {
    path: '/newuser-registration',
    exact: true,
    component: NewUserRegistration,
  },
  {
    path: '/admin',
    exact: false,
    component: SiteAdminSection,
  },
  {
    path: '/digital-shelf',
    exact: true,
    render: props => <DigitalShelf {...(props as any)} />
  },
  {
    path: '/demo/navbar/:subRoute',
    exact: true,
    component: DemoNavbar,
  },
  {
    path: '/demo/reports',
    exact: false,
    component: DemoNLReports,
  },
  {
    path: '/demo/escatalog/',
    exact: true,
    render: (props) => <Catalog elasticTest={true} {...props}/>
  },
  {
    path: '/demo/escatalog/reindex',
    exact: true,
    render: props => <ElasticReindexDemo {...props}/>
  },
  {
    path: '/demo/digital-shelf',
    exact: true,
    render: props => <Redirect to={`/digital-shelf${props.location.search}${props.location.hash}`} />
  }
];

export const RootSiteRoutes: React.ComponentType = () => (
  <NewMainLayout>
    <Suspense fallback={<Spinner />}>
      <Switch>
        {generateRoutes(routes)}
        <Redirect from='/demo/navbar' to='/demo/navbar/add-skus' />
        <Redirect to='/dashboard' />
      </Switch>
    </Suspense>
  </NewMainLayout>
);

interface RouteLink {
  name: string;
  path?: string;
  exact?: boolean;
}

export const ContentStatusRouteLinks: RouteLink[] = [
  { name: 'Dashboard', path: '/dashboard' },
  /* { name: 'Jobs', path: '/jobs' },
  { name: 'Scoring', path: '/scoring' },
  { name: 'Rules', path: '/audit-rules' },
  { name: 'Catalog', path: '/catalog', exact: false },
  { name: 'Workflow', path: '/workflow', exact: false }, */
  { name: 'Team', path: '/team' },
  // {name: "Sites", path: undefined},
  // {name: "Add Credits", path: undefined},
  // {name: "Profile Settings", path: "/newshell/settings/profile"}
];
