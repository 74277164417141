import React from 'react';
import { SuccessDialogProps } from './datastructures';
import './style.scss';
import { CSUIButton } from '../CSUI/CSUIButton';
import Dialog from '@material-ui/core/Dialog';

const SuccessDialog = (props: SuccessDialogProps) => {
    const open = props.open === undefined
    ? true
    : props.open;

    const dialogBody = <div className="cs-success-dialog">
        <h1>{props.title}</h1>
        {props.children || ""}
        <div className="options">
            <CSUIButton slim onClick={props.onClose} flat color="green">{props.close || "Close"}</CSUIButton>
        </div>
    </div>;

    const materialDialog = <Dialog 
        open={open}
        disableBackdropClick={true}
    >
        {dialogBody}
    </Dialog>;

    return materialDialog;
}

export default SuccessDialog;