import React from "react";
import { SyncedDivs, withSyncedDivsHOC } from "../../../../libraries/types/ReportUp";

interface SyncedDivSimpleProps {
    syncedDivs: SyncedDivs;
    groupId: string;
    divId: string;
    className?: string;
    minimumHeight?: number;
    registerOnChangeHandler?: (onChangeHandler: () => void) => void
}

interface State {
}

class SyncedDivSimple extends React.Component<SyncedDivSimpleProps, State> {

    private divRef: React.RefObject<HTMLDivElement>;

    constructor(props: SyncedDivSimpleProps) {
        super(props);

        this.divRef = React.createRef<HTMLDivElement>();

        this.state = {
        };
    }

    componentDidMount() {
        const { registerOnChangeHandler, syncedDivs, groupId } = this.props;

        if (registerOnChangeHandler) {
            registerOnChangeHandler(this.onChangeHandler);
        }

        syncedDivs.setExpand(groupId, true);
        this.onChangeHandler();
    }

    onChangeHandler = () => {
        const domRect = this.divRef.current.getBoundingClientRect();

        const minHeight = (this.props.minimumHeight !== undefined)
            ? this.props.minimumHeight
            : 0;

        const heightToReport = Math.max(minHeight, domRect.height)

        this.reportHeight(heightToReport);
    }

    reportHeight = (height: number) => {
        const { syncedDivs, groupId, divId } = this.props;

        syncedDivs.reportDivData(groupId, divId, height);
    }

    isExpanded = () => {
        const { syncedDivs, groupId } = this.props;
        return (syncedDivs.groups[groupId] && syncedDivs.groups[groupId].expanded);
    }

    render() {
        const { syncedDivs, groupId, children } = this.props;

        const classes = ['syncedDivSimple'];
        classes.push(syncedDivs.groupClassname(groupId));
        if (this.props.className) {
            classes.push(this.props.className);
        }
        const className = classes.join(" ");

        const renderChildren = children || null;

        return (
            <div ref={this.divRef} {...{ className }}>
                {renderChildren}
            </div>
        );
    }
}

export default withSyncedDivsHOC(SyncedDivSimple)//SyncedDivTest;