import axios from "axios";
import React from "react";
import { API_URL } from "../../libraries/types/datastructures";
import { TeamSettingsDataService, TeamSettingsModal } from "../../pages/Profile/TeamAccountSettings";

export interface NLReportData {
  data: {
    input: {
      [x: string]: string | number;
    };
    rendered: {
      [x: string]: string;
    };
  };
  template: {
    header: string;
    body: string;
  };
  rendered: {
    header: string;
    body: string;
  };
}

const allReports = {
  //Live: testLiveReportResponse
} as {
  [reportId: string]: NLReportData;
}

const loadReport = async (reportId: string, teamId: string) => {
  let reportData = undefined;
  try {
    if (teamId) {
      const res = await axios.get(API_URL+`/report/nl/?reportId=${reportId.toLowerCase()}&teamId=${teamId}`);
      reportData = res.data.reportOutput || undefined
    }
  } catch (e) {
    console.error(e)
  }
  allReports[reportId] = reportData;
}

let loadedTeamId = '';

const reportValueRenderer = (
  foundKey: string, 
  reportId: string, 
  data: {
    [x: string]: string;
  }
) => {
  const isTeamSettingValue = Object.keys(TeamSettingsDataService.defaultSettings.metrics).indexOf(foundKey) > -1;
  const spanStyle = {color: '#cc00cc'}
  const spanEle = (<span 
      style={spanStyle} 
      key={foundKey} 
      id={`${reportId}_${foundKey}`}
  >
      {data[foundKey]}
  </span>);

  if (isTeamSettingValue) {
    return <TeamSettingsModal render={onClick => React.cloneElement(spanEle, {
      onClick, 
      style: {...spanStyle, cursor: 'pointer'}
    })}/>
  } else {
    return spanEle;
  }
}

export const NLReportsService = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTeamId: (teamIdParam: string) => { loadedTeamId = teamIdParam; },
  getReports: async () => {
    return allReports;
  },
  renderReport: async (reportId: string) => {
    if (!allReports[reportId]) {
      await loadReport(reportId, loadedTeamId);
    }
    if (allReports[reportId]) {
      const data = allReports[reportId].data.rendered
      const body_temp = allReports[reportId].template.body;
      const sentenceArray = [];
      body_temp.split(" ").forEach(slice => {
          const foundKey = Object.keys(data).find(key => slice.indexOf(`[${key}]`) > -1);
          if(foundKey) {
              const splitArray = slice.split(`[${foundKey}]`);
              sentenceArray.push(splitArray[0]);
              sentenceArray.push(reportValueRenderer(foundKey, reportId, data));
              sentenceArray.push(splitArray[1]);
          } else {
              sentenceArray.push(slice);
          }
          sentenceArray.push(" ");
      })
      const test = <>
          {sentenceArray}
      </>
      return <div>{test}</div>;
    } else {
      return undefined
    }
  }
}