import { ActiveUserAccount, RandomId } from '../types/datastructures';
import {Location} from "history";
import {HubSpotService} from "../ScriptServices/HubSpot"
import { HelpHeroService } from '../ScriptServices/HelpHero';
import { UserActivityEvent } from './datastructures';
import { API_CALL_trackEvent } from '../Util/apiClient/tracking';

class TrackingServiceClass {

    private initialized: boolean;
    private userInfo: { name: string, email: string; }
    private trackedData: {
        [property: string]: boolean
    }
    private activeUser: ActiveUserAccount;

    constructor() {
        this.initialized = false;
        this.userInfo = { name: '', email: '' };
        this.trackedData = {};
    }

    public init = async () => {
        if (!this.initialized) {
            await HubSpotService.init();
            HelpHeroService.init();
            this.initialized = true;
        }
    }

    setUser = async (activeUser: ActiveUserAccount) => {
        if (!this.initialized) {
            this.init();
        }

        this.activeUser = activeUser;

        const coreUser = activeUser?.user?.core;
        this.userInfo = {
            email: coreUser?.email || '',
            name: activeUser.user.displayName || ''//`l: ${coreUser.lastName}, f: ${coreUser.firstName}`
        };

        if (coreUser['tracking']) {
            this.trackedData = coreUser['tracking'];
        }
        
        await HubSpotService.setUser(activeUser);
        HelpHeroService.setUser(activeUser);
    }

    onRouteChange = async (location: Location) => {
        //await HubSpotService.trackPageView(location);
    }

    onEvent = async (event: UserActivityEvent['meta']['activity']) => {
        if (!this.trackedData[event]) {
            HelpHeroService.update({
                [event]: true
            });

            const coreUser = this.activeUser?.user?.core;
    
            const eventObj = {
                id: RandomId(),
                accountId: coreUser.teamId,
                timestamp: Date.now(),
                type: 'user-activity',
                action: "track",
                meta: {
                    activity: event
                }
            };

            await API_CALL_trackEvent(eventObj, coreUser.userId);

            this.trackedData[event] = true;
        }
    }
}

export const TrackingService = new TrackingServiceClass();