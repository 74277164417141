import * as React from 'react';
import UserAccountSettings from '../../../pages/Profile/UserAccountSettings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GlobalState } from '../../../libraries/types/ReportUp';
import {CoreUserAccount, TeamInvite} from '../../../libraries/types/datastructures';
import {firestoreDb, querystringparser} from '../../../libraries/Util';
import Spinner from '../../Spinner';
import { TermsOfUseModal, PrivacyPolicyModal, CookiesPolicyModal } from '../../Legal';
import { CSUIButton, CSUICheckbox } from '../../CSUI';
import './style.scss';
import LoadingScreen from '../../LoadingScreen';
import LinearProgress from '@material-ui/core/LinearProgress';

// tslint:disable-next-line:no-any
interface RegistrationFormProps extends RouteComponentProps {
    router: RouteComponentProps<any>;
    globalState: GlobalState;
}

interface State {
    teamId?: string;
    inviteId?: string;
    refId?: string;
    view: {
        loadingData: boolean;
        message: {
            show: boolean;
            header: string;
            items: string[];
        }
        doneRegistering?: boolean;
        readyToSubmit?: boolean;
    }
    data: {
        termsAgreement?: boolean;
        coreUser?: CoreUserAccount;
        completionProgressStats?: {
            num: number;
            denom: number;
            amt: number;
            pct: string;
        }
    }
    saveFn?: () => Promise<void>
}

const blankMessageTemplate = {
    show: false,
    header: '',
    items: []
};

const newUserMessageObj = {
    show: true,
    header: 'Welcome to ContentStatus.com!',
    items: ['Please complete your account profile to finish your registration.']
}

class RegistrationForm extends React.Component<RegistrationFormProps, State> {

    constructor(props: RegistrationFormProps) {
        super(props);

        this.state = {
            view: {
                loadingData: false,
                message: blankMessageTemplate, // messageInit(props),
                doneRegistering: undefined
            },
            data: {}
        };
    }

    async componentDidMount() {
        const {inviteId, refId} = this.getQueryParams();

        let teamId: string;

        if (inviteId) {

            const docSnapshot = await firestoreDb
            .collection('TeamInvite')
            .doc(inviteId)
            .get();

            if (docSnapshot.exists) {
                const teamInvite = docSnapshot.data() as TeamInvite;
                teamId = teamInvite.teamId;
            }
        }
        
        this.setState({
            teamId,
            inviteId,
            refId
        });
    }

    getQueryParams = () => {
        const { search } = window.location;
        const parsedLocationSearch = querystringparser(search);

        const inviteIdParam = parsedLocationSearch['inviteId'];
        const inviteId = (inviteIdParam && inviteIdParam[0]) ? inviteIdParam[0] : undefined;

        const refIdParam = parsedLocationSearch['fpr'];
        const refId = (refIdParam && refIdParam[0]) ? refIdParam[0] : undefined;

        return {inviteId, refId};
    }

    componentWillUnmount() {
        //
    }

    setData = (data: Partial<State['data']>, cb: (...args: any[]) => any = () => { }) => this.setState(prev => ({ data: { ...prev.data, ...data } }), cb);
    setView = (view: Partial<State['view']>, cb: (...args: any[]) => any = () => { }) => this.setState(prev => ({ view: { ...prev.view, ...view } }), cb);

    setCompletionProgressStats = (completionProgressStats: State['data']['completionProgressStats']) => {
        this.setData({ completionProgressStats }, this.evaluateCompletion);
    }

    setCoreUser = (coreUser: CoreUserAccount) => {
        this.setData({ coreUser });
    }

    setMessage = (message: State['view']['message']) => {
        this.setState(prev => ({ view: { ...prev.view, message } }));
    }

    evaluateCompletion = () => {
        const { completionProgressStats, termsAgreement } = this.state.data;
        if (completionProgressStats) {
            if (completionProgressStats.amt < 1 || !termsAgreement) {
                this.setView({ message: newUserMessageObj, readyToSubmit: false });
            } else {
                this.setView({ readyToSubmit: true });
            }
        }
    }

    termsAgreement = () => {
        const label = <div>
            I agree to the terms and conditions set forth in the {TermsOfUseModal()}, {PrivacyPolicyModal()}, and {CookiesPolicyModal()}
        </div>;
        const { termsAgreement } = this.state.data;
        const chx = <CSUICheckbox
            id="chxBxRegistrationTerms"
            label={label}
            checked={!!termsAgreement}
            onChange={() => this.setData(
                { termsAgreement: !!!termsAgreement },
                this.evaluateCompletion
            )}
        />;

        return chx;
    }

    onSubmit = () => {
        //this.setState()
        this.setView({ doneRegistering: true }, async () => {
            if (this.state.saveFn) {
                await this.state.saveFn();
            }
        });
        //this.props.history.push("/catalog");
    }

    onCancel = () => {
        this.props.globalState.signOut();
    }

    registerSaveFn = (saveFn: () => Promise<void>) => {
        this.setState({ saveFn })
    }

    account = () => {
        const { completionProgressStats } = this.state.data;
        const { readyToSubmit } = this.state.view;

        const progressStats = completionProgressStats;

        const progressDiv = (progressStats)
            ? <div style={{ display: 'flex', textAlign: 'left', marginBottom: 20, width: "70%", alignItems: 'center' }}>
                <div style={{width: '100%'}}><LinearProgress variant="determinate" value={progressStats.amt * 100} color="secondary" /></div>
                <div style={{width: '100%', marginLeft: 10}}>{progressStats.pct} complete</div>
            </div>
            : null;

        const div = <div className="registrationFormBody" style={{ width: '100%', marginTop: "50px" }}>
            {progressDiv}
            <UserAccountSettings
                reportCompletion={this.setCompletionProgressStats}
                reportCoreUser={this.setCoreUser}
                registration={true}
                registerSaveFn={this.registerSaveFn}
                queryParams={{
                    teamId: this.state.teamId,
                    inviteId: this.state.inviteId,
                    refId: this.state.refId
                }}
                {...this.props}
            />
            {this.termsAgreement()}
            <div style={{ marginTop: 30 }}>
                <CSUIButton
                    disabled={!readyToSubmit || !this.state.saveFn}
                    color="green"
                    label={"SUBMIT"}
                    onClick={this.onSubmit}
                />
                <CSUIButton
                    label={"CANCEL"}
                    color="red"
                    onClick={this.onCancel}
                />
            </div>
        </div>;

        return div;
    }

    render() {
        const { loadingData, doneRegistering } = this.state.view;
        return doneRegistering
        ? <LoadingScreen />
        : <div>
                { loadingData && <Spinner active={loadingData} />}

                <div className="container-with-padding">
                    <h1 className="standard-header-title">Let's get your free trial started</h1>
                    <p className="standard-header-text">Please finish your registration so that we can provide a customized experience.</p>
                    {this.account()}
                </div>

            </div>
    }
}

export default withRouter(RegistrationForm);