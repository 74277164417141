import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { JobProduct } from '../datastructures';
import {
    SKUsList,
    Tag
} from '../../../pages/Catalog/SKUTaggingView/datastructures';
import ContentSnapshot from './ContentSnapshot';
import { CSUITabs } from '../../../components/CSUI/CSUITabs/index';
import { SkuRecord } from '../../../pages/Catalog/catalog-datamodel';
import { SKUWithKeywords } from '../../../pages/Catalog/SKUKeywords/datastructures';
import TagsSnapshot from './TagsSnapshot';
import KeywordSnapshot from './KeywordSnapshot';
import FlaggedSnapshot from './FlaggedSnapshot';
import './styles.scss';

interface ProductAuditProps {
    product: JobProduct;
    skuRecord: SkuRecord;
    customTags?: Tag[];
    sku: SKUWithKeywords;
    skuTags: {
        [tag: string]: Tag
    },
    setTags: (data: {
        skus?: SKUsList;
        tags?: Tag[];
    }, alreadySet?: boolean) => Promise<void>;
}

interface State {
}

class ProductAudit extends React.Component<ProductAuditProps, State> {
    panes = () => {
        return [
                {header: 'Flagged Items', content: <FlaggedSnapshot skuRecord={this.props.skuRecord} />},
                {header: 'Keywords used', content: <KeywordSnapshot sku={this.props.sku} product={this.props.product} />},
                {header: "Tags", content: <TagsSnapshot skuRecord={this.props.skuRecord} product={this.props.product} customTags={this.props.customTags} skuTags={this.props.skuTags} setTags={this.props.setTags}/>}
            ].map((step,idx) => ({
                key: idx,
                header: step.header,
                content: step.content,
                contentClassName: 'white-bg',
                headerClassName: 'tabs-grey-text'
        }));
    }
    render() {
        return <Grid container id='_page-rows' spacing={2}>
            <Grid item xs={6}>
                <ContentSnapshot sku={this.props.sku} product={this.props.product} skuRecord={this.props.skuRecord} />
            </Grid>
            <Grid item xs={6}>
                <div className={'card-body dark-container'} style={{borderRadius: '.25em'}}>
                    <h1 className="dual">Content<span>status</span></h1>
                    <CSUITabs panes={this.panes()} />
                </div>
            </Grid>
        </Grid>
    }
}

export default ProductAudit;
