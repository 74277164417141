import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal/Modal';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalWindow = ({ open, children, onClose }: Props) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.paper}>{children}</div>
    </Modal>
  );
};

export default ModalWindow;
