import * as React from 'react';
import './style.scss';
import { PlatformCatalogJob } from '../../../components/NewJob/data';
import { CSUIButtonCloseX } from '../../../components/CSUI';

interface DeleteJobConfirmationProps {
    jobToDelete: PlatformCatalogJob
    onConfirm:(jobToDelete: PlatformCatalogJob, deleteData:boolean)=>void,
    onCancel:()=>void
}
interface DeleteJobConfirmationState {
    confirmed:boolean,
    deleteData:boolean
}
class DeleteJobConfirmation extends React.Component<DeleteJobConfirmationProps,DeleteJobConfirmationState> {
    constructor(props:DeleteJobConfirmationProps) {
        super(props);
        this.state={
            confirmed:false,
            deleteData:false
        }
    }

    deleteJob = () => {
        this.props.onConfirm(this.props.jobToDelete, this.state.deleteData)

    }

    renderFirstDialog=()=>{
        return <div>
        <h1>Choose what you would like to delete?</h1>
        <div className="options">
            <button onClick={()=>this.setState({confirmed:true,deleteData:true})}>
                <div><b>Delete Job</b></div>
                <div>and</div>
                <div><b>Delete all collected data</b></div>
            </button>
            <button onClick={()=>this.setState({confirmed:true,deleteData:false})}>
                <div><b>Delete Job</b></div>
                <div>but</div>
                <div><b>Keep collected data</b></div>
            </button>
        </div>
    </div>;
    }

    renderSecondDialog=()=>{
        return <div>
        <h1>Are you sure?</h1>
        <p>You cannot recover deleted content.</p>
        <div className="options">
            <button className="large" onClick={this.deleteJob}>
                <div>I'm sure,</div>
                <div style={{color:"red"}}><b>DELETE</b></div>
            </button>
            <button className="large" onClick={this.props.onCancel}>
                <div>Oops,</div>
                <div><b>CANCEL</b></div>
            </button>
        </div>
    </div>;
    }
    
    render() {
        return <div className="delete-job-confirmation-dialog">
            <CSUIButtonCloseX onClick={this.props.onCancel} topRight/>
            {/* <button onClick={this.props.onCancel} className="close">X</button> */}
            {this.state.confirmed?this.renderSecondDialog():this.renderFirstDialog()}
        </div>
    }
}
export default DeleteJobConfirmation;

