import React, { useState, useRef } from 'react';
import { ImagePopup } from '../AssetPopup';
import './style.scss';
//import { SITE_URL } from '../../../../libraries/types/datastructures';
import clsx from 'clsx';

interface FileTypeChecks {
    preferred: boolean;
    accepted: boolean;
    filetype: string;
    under_1mb: boolean;
    filesize: string;
    appropriate_colorspace: boolean;
    colorspace: string;
    width: number;
    height: number;
    is_square: boolean;
    recommended_resolution: boolean;
    minimum_resolution: boolean;
    ppi: number;
}

interface ImageBoxProps {
    value: {
        label: string;
        url: string;
        meta: {
            [x: string]: any;
        }
    };
    fileTypeChecks: FileTypeChecks;
    showResolution?: boolean;
}

const ImageBox = (props: ImageBoxProps) => {
    const { value, fileTypeChecks } = props;
    const [imgSize, setImgSize] = useState({ width: 1000, height: 1000 });
    const imgRef = useRef(null);

    const showResolution = props.showResolution === undefined ? true : props.showResolution;

    return <div className="image-box-container">
        <img style={{ display: 'none' }} ref={imgRef} onLoad={() => setImgSize({ width: imgRef.current.naturalWidth, height: imgRef.current.naturalHeight})} src={value.url} alt="" />
        <div className="image-container">
            <ImagePopup value={value} />
        </div>
        {showResolution ? <div className={clsx('dimensions', { 'with-errors': !fileTypeChecks.minimum_resolution })}>
            {/* <a 
                href={`${SITE_URL}/qlImage?imageUrl=${value.url}`}
                target={"_blank"}
                rel="noopener noreferrer"
                
            > */}
                {imgSize.width} x {imgSize.height}
            {/* </a> */}
        </div> : null}
    </div>
}

export default ImageBox;