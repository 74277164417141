import * as React from 'react';
import { debounce } from 'lodash';
import './style.scss';
import { ScoreObject } from '../../../../pages/Catalog/catalog-datamodel';
import ScoreBarBinary from './binaryBar';
import { Scorer } from '../../../../libraries/scoring';

interface ScoreBarProps {
	score: ScoreObject;
	qty: number;
	binaryScore?: boolean
}

interface State {
	color: string;
	left: number;
}

class ScoreBar extends React.Component<ScoreBarProps, State> {

	
    /* static getDerivedStateFromProps = (nextProps: Props, prevState: State) => {
        if (nextProps.index !== prevState.index) {
            prevState.index = nextProps.index;
            return prevState;
        }
        
        return null;
        // tslint:disable-next-line:no-console
        // console.log(this.props);
        // this.setState({index: this.props.index});
    } */

	componentWillReceiveProps(nextProps: ScoreBarProps) {
		if (this.getLeft(nextProps?.score?.score) !== this.state.left) {
			this.setState({ color: 'transparent' });
			this.setValues();
		}
	}

	constructor(props: ScoreBarProps) {
		super(props);

		this.state = {
			color: 'transparent',
			left: 0,
		};

		this.setColor = debounce(this.setColor, 200);
	}

	getStep = (step: number, steps: string[]) =>
		steps && steps.length > step ? steps[step] : null;

	getLeft = (score: number): number => {
		if (score === 0) {
			return 0;
		}
		return 9.8 + (Math.floor(score) - 1) * 20;
	}

	getColor = (left: number) => {
		let color: string;
		if (left < 42.5) {
			color = '#e80003';
		}
		if (left > 42.5 && left < 62.3) {
			color = '#d8d8d8';
		}
		if (left > 62.3 && left < 82.5) {
			color = '#05bf06';
		}
		if (left > 82.5) {
			color = '#00e100';
		}
		return color;
	}

	constrainScore = (score: ScoreObject): number => {
		const min = 0;
		const max = 5;
		if(!score) {
			return Math.min(
				max,
				Math.max(
					min,
					0,
				),
			);
		}
		return Math.min(
			max,
			Math.max(
				min,
				score.potential ? score.score / (score.potential / 5) : 0,
			),
		);
	}

	barSectionGen = (last3Steps: string[]) => (number: number) => {
		const className =
			number < 3
				? 'fail'
				: number === 3
				? 'neutral'
				: number === 4
				? 'good'
				: 'excellent';
		return (
			<div className={className} key={number}>
				<div className="segment">{number}</div>
				{number > 2 ? (
					<div className="step">
						{this.getStep(number - 3, last3Steps)}
					</div>
				) : null}
			</div>
		);
	}

	setLeft = async (left: number) => this.setState({ left: left });

	setValues = () => {
		const score = this.constrainScore(this.props.score);
		let left = this.getLeft(score)
		if (left < 0) {
			left = 0;
		}
		const color = this.getColor(left)
		this.setLeft(left).then(() => this.setColor(color));
		return;
	}

	setColor = (color: string) => {
		return this.setState({ color: color });
	}

	componentDidMount() {
		this.setValues();
	}

	renderBinaryBar = () => {
		return <ScoreBarBinary {...this.props} />
	}

	renderFullBar = () => {
		const { qty } = this.props;
		const { steps, stepBreaks} = this.props.score;

		const score = this.constrainScore(this.props.score);
		const stepBreaksString = (stepBreaks) ? stepBreaks.map(String) : undefined;
		const last3Steps = (stepBreaksString || steps || []).slice(-3);

		const left = this.getLeft(score);
		// const color = this.getColor(left);

		return (
			<div className="bar-wrap productScoreBar">
				<div className="score-wrapper">
					<div
						className="triangle-wrap"
						style={{ left: `${left}%` }}
					>
						<div className="val">{(qty % 1 === 0) ? qty : qty.toFixed(2)}</div>
						<div
							className="triangle-up"
							style={{
								// borderBottomColor: color,
							}}
						/>
					</div>
				</div>
				<div className="bar">
					{[1, 2, 3, 4, 5].map(this.barSectionGen(last3Steps))}
				</div>
			</div>
		);
	}

	render() {
		const {binaryScore, score} = this.props;
		return score
		? (binaryScore)
			? this.renderBinaryBar()
			: this.renderFullBar()
		: null
	}
}

interface LiveScoreBarProps {
	scorer: Scorer;
	qty: number;
	binaryScore?: boolean
}

export const LiveScoreBar: React.FC<LiveScoreBarProps> = props => {
	const { scorer, ...restProps } = props;
	const score = scorer.score(props.qty)

	const newProps = { ...restProps, score }

	return <ScoreBar {...newProps} />;
}

export default ScoreBar;
