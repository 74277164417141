import * as React from 'react';
import './style.scss';
import { CSUIButtonCloseX } from '../../../components/CSUI';

interface JobErrorDialogProps {
    onClose: () => void,
    jobName: string
}

const JobErrorDialog = (props: JobErrorDialogProps) => <div className="job-error-dialog">
    <CSUIButtonCloseX onClick={props.onClose} topRight/>
    <h1>Well that was unexpected!</h1>
    <p className="margin-bottom">There was an error processing {props.jobName}.</p>
    <p>Our support team has been notified and will provide details as soon as possible on next steps.</p>
</div>;

export default JobErrorDialog;