import axios from 'axios';
import {API_URL} from '../../types/datastructures';
import { ScoreCardDefaultMappings, ScoreCardSettings } from '../../../pages/ScoreCards/types';

export function API_CALL_CreateScoreCard(scoreCard: ScoreCardSettings, teamId: string){
    return axios.post(`${API_URL}/scorecard/create`, { scoreCard, teamId })
}

export function API_CALL_EditScoreCard(scoreCard: ScoreCardSettings, teamId: string){
    return axios.post(`${API_URL}/scorecard/edit`, { scoreCard, teamId })
}

export function API_CALL_EditDefaultScoreCard(scoreCard: ScoreCardSettings){
    return axios.post(`${API_URL}/scorecard/edit-default`, { scoreCard })
}

export async function API_CALL_GetDefaultScoreCard(){
    const data = await axios.post(`${API_URL}/scorecard/get-default`);

    return data.data.defaultScorecard;
}

export async function API_CALL_GetSystemScoreCards(){
    const data = await axios.post(`${API_URL}/scorecard/get-system`);

    return data.data.systemScorecards as any[];
}

export async function API_CALL_ResetDefaultScoreCard(){
    const data = await axios.post(`${API_URL}/scorecard/reset-default`);

    return data.data.defaultScorecard;
}

export async function API_CALL_ScorecarsdPublishChanges(teamId: string, scoreCardId: string){
    await axios.post(`${API_URL}/scorecard/publish-changes`, {teamId, scoreCardId});
}

export function API_CALL_ScoreCardSetDefault(scoreCardId: ScoreCardSettings, teamId: string, status: boolean){
    return axios.post(`${API_URL}/scorecard/set-default`, { scoreCardId, teamId, status })
}

export function API_CALL_DeleteScoreCard(scoreCardId: string, teamId: string){
    return axios.post(`${API_URL}/scorecard/delete`, { scoreCardId, teamId })
}

export function API_CALL_GetScoreCardList(teamId: string){
    return axios.get(`${API_URL}/scorecard`, { params: { teamId }});
}

export function API_CALL_GetTeamDefaultScoreCards(teamId: string) {
    return axios.post(`${API_URL}/scorecard/get-team-defaults`, { teamId });
}

export function API_CALL_SetTeamDefaultScoreCards(teamId: string, data: ScoreCardDefaultMappings) {
    return axios.post(`${API_URL}/scorecard/set-team-defaults`, { teamId, data });
}
