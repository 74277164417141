import clsx from 'clsx';
import React from 'react';
import RecomendedPlan from './RecomendedPlan'
import { CSUIButton } from '../../../../../components/CSUI/CSUIButton';
import { priceRenderer } from '../../../../../libraries/types/datastructures';
import styles from './styles';

interface Props {
  activePlan: string;
  planKey: string;
  name: string;
  subTitle: string;
  period: string;
  periodChildren?: React.ReactNode;
  plans: PlanItem[];
  gradient: string;
  activeColor: string;
  onSelect: (planKey: string) => void;
  planType: 'quarterly' | 'annual' | 'flex';
  plansSlice: {
    startIdx: number;
    endIdx: number;
  }
  publicVersion?: boolean;
}

interface PlanItem {
  key: string;
  price: string;
  description: string;
  feePer: number;
  overageFee: number;
}

const Plan: React.FunctionComponent<Props> = (props) => {
  const {
    activePlan,
    planKey,
    name,
    subTitle,
    period,
    periodChildren,
    gradient,
    plans,
    activeColor,
    onSelect,
    planType,
    plansSlice,
    publicVersion
  } = props;

  const classes = styles();
  return (
    <>
      {name === 'Brilliant' && <RecomendedPlan />}
      <div className={classes.root} style={{ background: gradient }}>
        <h1 className={clsx(classes.title, classes.blackShadow)}>{name}</h1>
        <h6 className={clsx(classes.subTitle, classes.blackShadow)} dangerouslySetInnerHTML={{ __html: subTitle }} />
        <div className={classes.divider} />
        <div className={clsx(classes.periodWrapper, classes.blackShadow)}>
          <h4 className={clsx(classes.period, classes.blackShadow)}>{period}</h4>
          {periodChildren && (
            <p className={clsx(classes.periodChildren, classes.blackShadow)}>{periodChildren}{
              planType === 'quarterly' ? <><br />3-Month Term</> : planType === 'annual' ? <><br />Annual Term</> : ''
            }</p>
          )}
          {}
        </div>
        <div className={classes.planContainer}>
          {plans.slice(plansSlice.startIdx, plansSlice.endIdx).map(({ key, price, ...planParams }) => (
            <React.Fragment key={key}>
              {key !== activePlan && (
                <div className={clsx(classes.plan, classes.whiteShadow)} key={key}>
                  {priceRenderer(price, {divisor: 100, numDecimals: undefined})}
                </div>
              )}
              {key === activePlan && (
                <div
                  className={classes.activePlan}
                  key={key}
                  style={{ backgroundColor: activeColor, marginTop: key === '50000' ? 3 : 8 }}>
                  <h1 className={classes.activePlanPrice}>{priceRenderer(price, {divisor: 100, numDecimals: undefined})}</h1>
                  <p className={classes.activePlanDescription}>
                    A Month / Billed {planType === 'annual' ? 'Annually' : 'Monthly'} / US <br /> ● {planType === 'flex' ? priceRenderer(planParams.feePer, {divisor: 100}) : priceRenderer(planParams.overageFee)} per SKU overage
                  </p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className={classes.action}>
          {publicVersion ? <a href="https://app.contentstatus.com" target="_top"><CSUIButton slim color='green' onClick={() => void 0} >
            Free Trial*
          </CSUIButton></a>
            : <CSUIButton slim color='green' onClick={onSelect.bind(null, planKey)} >
            Select Plan
          </CSUIButton>}
        </div>
      </div>
    </>
  );
};

export default Plan;