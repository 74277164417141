import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const termButton = (termDef: TermDef, onClick: (termDef: TermDef) => void) => (
  <span
    {...{
      children: termDef.name,
      onClick: () => onClick(termDef),
      style: { color: '#4183c4', cursor: 'pointer' },
    }}
  />
);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    height: '90%',
    width: '80%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  link: {
    color: '#4183c4',
    cursor: 'pointer',
  },
}));

// eslint-disable-next-line jsx-a11y/iframe-has-title
const termIframe = (title: string, src: string) => (
  <iframe
    {...{
      title,
      src,
      style: {
        width: '100%',
        height: '100%',
        border: '1px solid rgb(192,192,192)',
        borderRadius: 3,
      },
    }}
  />
);

const TermModal = ({ name, iframeTitle, iframeSrc }: TermDef) => {
  const [open, setStateWindow] = useState(false);
  const onClickLink = (event) => {
    event.preventDefault();
    setStateWindow(!open);
  }
  const classes = useStyles();

  return (
    <React.Fragment>
      <span className={classes.link} onClick={onClickLink}>
        {name}
      </span>
      <Modal open={open} onClose={onClickLink}>
        <div className={classes.paper}>
          {termIframe(iframeTitle, iframeSrc)}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const termDefGen = (
  name: string,
  iframeTitle: string,
  iframeSrc: string
): TermDef => ({ name, iframeTitle, iframeSrc });

interface TermDef {
  name: string;
  iframeTitle: string;
  iframeSrc: string;
}

export const SiteLegalConfig = {
  TermsOfUse: termDefGen(
    'Terms of Use',
    'CS_TermsOfUse',
    'https://www.iubenda.com/terms-and-conditions/31508126'
    //'https://www.termsfeed.com/terms-conditions/b00a4a615c75747ede49af98040e7367'
  ),
  PrivacyPolicy: termDefGen(
    'Privacy Policy',
    'CS_PrivacyPolicy',
    'https://www.iubenda.com/privacy-policy/31508126'
    //'https://www.termsfeed.com/privacy-policy/f828a3481144d81d9a1b5e13e94890de'
  ),
  CookiesPolicy: termDefGen(
    'Cookies Policy',
    'CS_CookiesPolicy',
    'https://www.iubenda.com/privacy-policy/31508126/cookie-policy'
    //'https://www.termsfeed.com/cookies-policy/76eb3c74f697579a644cfcfe1b1c988c'
  ),
};

export const TermsOfUseModal = () => (
  <TermModal {...SiteLegalConfig.TermsOfUse} />
);

export const PrivacyPolicyModal = () => (
  <TermModal {...SiteLegalConfig.PrivacyPolicy} />
);

export const CookiesPolicyModal = () => (
  <TermModal {...SiteLegalConfig.CookiesPolicy} />
);

export const TermsModal: React.FunctionComponent<{
  open: boolean;
  onClose: () => void;
  termDef: keyof typeof SiteLegalConfig;
}> = ({ open, onClose, termDef }) => {
  if (open) {
    const { iframeTitle, iframeSrc } = SiteLegalConfig[termDef];

    return (
      <Modal
        open={open}
        onClose={onClose}
        //aria-labelledby="simple-modal-title"
        //aria-describedby="simple-modal-description"
      >
        <div
          style={{ height: '100%', width: '100%', padding: '5%' }}
          onClick={onClose}>
          {termIframe(iframeTitle, iframeSrc)}
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export const TermsModalTrigger: React.FunctionComponent<{
  termDef: TermDef;
  onClick: (termDef: TermDef) => void;
}> = ({ termDef, onClick }) => termButton(termDef, onClick);
