import React from 'react';
import classnames from 'classnames';
import styles from './styles';

interface Props {
  tabs: string[];
  active: string;
  onSelect: (tab: string) => void;
}

const Tab = ({ tabs, active, onSelect }: Props) => {
  const classes = styles();

  return (
    <div className={classes.core}>
      {tabs.map((tab, i) => {
        if (i === 0) {
          return (
            <div
              className={classnames(classes.left, classes.defaultTab, {
                [classes.activeTab]: tab === active,
                [classes.tab]: tab !== active,
                activeTab: tab === active
              })}
              onClick={onSelect.bind(null, tab)}
              key={i + tab}>
              {tab}
            </div>
          );
        } else if (i === tabs.length - 1) {
          return (
            <div
              className={classnames(classes.right, classes.defaultTab, {
                [classes.activeTab]: tab === active,
                [classes.tab]: tab !== active,
                activeTab: tab === active
              })}
              onClick={onSelect.bind(null, tab)}
              key={i + tab}>
              {tab}
            </div>
          );
        }
        return (
          <div
            className={classnames(classes.defaultTab, {
              [classes.activeTab]: tab === active,
              [classes.tab]: tab !== active,
            })}
            onClick={onSelect.bind(null, tab)}
            key={i + tab}>
            {tab}
          </div>
        );
      })}
    </div>
  );
};

export default Tab;
