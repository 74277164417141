import initHelpHero, {HelpHero, } from 'helphero';
import { ActiveUserAccount } from '../../types/datastructures';

class HelpHeroServiceClass {

    public helpHero: HelpHero;
    private initialized: boolean;
    private userInfo: { name: string, email: string; userId: string; }

    constructor() {
        this.initialized = false;
        this.userInfo = { name: '', email: '', userId: '' };
    }

    public init = () => {
        if (!this.initialized) {
            this.initialized = true;
            this.helpHero = initHelpHero('KsSlhC8RBHe');
            this.helpHero.anonymous();
        }
    }

    getHelpHero = () => {
        if (!this.initialized) {
            this.init();
        }
        return this.helpHero;
    }

    setUser = (activeUser: ActiveUserAccount) => {
        if (!this.initialized) {
            this.init();
        }

        const coreUser = activeUser?.user?.core;
        this.userInfo = {
            userId: coreUser?.userId,
            email: coreUser?.email,
            name: activeUser.user.displayName//`l: ${coreUser.lastName}, f: ${coreUser.firstName}`
        };

        this.helpHero.identify(
            coreUser?.userId,
            this.userInfo
        );
    }

    update = ((data) => {
        if (this.userInfo?.userId) {
            return this.helpHero.update(
                data
            );
        } else {
            throw new Error("HelpHero user not set-- update function not available");
        }
    }) as HelpHero['update']
}

export const HelpHeroService = new HelpHeroServiceClass();