import React, { useState } from "react";
import styles from "./styles";
import { Grid } from "@material-ui/core";

import {
    AccuracyAnalysisResult,
    DynamicLengthAccuracyAnalysisResult,
    SkuRecord,
} from "../../Catalog/catalog-datamodel";
import { GlobalState } from "../../../libraries/types/ReportUp";
import ComparisonItem from "./ComparisonItem";
import { getLatestRubric } from "../../../libraries/Util/utils";
import { CSUIIcon } from "../../../components/CSUI";
import ImageBox from "../Common/ImageBox";
import { VideoPopup } from "../Common/AssetPopup";
import Popup from "reactjs-popup";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { ProductAccuracySection, ProductAccuracyState } from './constants';
import clsx from "clsx";
import "./style.scss";
import { htmlStringToReact } from "../../../libraries/Util/string";
import { PDPSpecsTable } from "../Common/Misc";

interface Props {
    skuRecord: SkuRecord;
    comparedSkuRecord: SkuRecord
    globalState: GlobalState;
}

const renderValueItem = (value: any, textOveride?: string) => {
    return textOveride ? textOveride : <div>
        Value: <span style={{fontWeight: "bold"}}>{String(value).toLocaleUpperCase()}</span>
    </div>
}

const renderSectionTitle = (title: string, isMatch: boolean, section: string, onHeaderClick, classes) => (
    <div className={classes.sectionTitle}
         onClick={() => onHeaderClick && onHeaderClick(section)}>
        <h2 className={classes.title}>{title}</h2>
        {isMatch === null ? null : isMatch ? (
            <div className={classes.approved} />
        ) : (
            <CSUIIcon icon="product warning" className="icon" />
        )}
    </div>
);

const renderImagesSection = (
    images: SkuRecord['skuData']['Assets']['Images'],
    difference: number,
    score: number,
    isMatch: boolean,
    count: number,
    differenceCount: number,
    mediaClass?: any,
) => {
    const imagesData = images.labels.length ? images.labels.map((label, idx) => {
        const popup = (
            <ImageBox
                fileTypeChecks={
                    {
                        minimum_resolution: true,
                        height: 1000,
                        width: 1000,
                    } as any
                }
                value={images.values[label]}
            />
        );

        return <div key={`product-image-${idx}`}>{popup}</div>;
    }) : renderValueItem(null, "No images");

    return (
        <ComparisonItem
            item={imagesData}
            title="Image"
            differencePct={difference}
            differenceCount={differenceCount}
            isMatch={isMatch}
            contentClass={mediaClass}
        />
    );
};

const renderVideosSection = (
    videos: SkuRecord['skuData']['Assets']['Videos'],
    difference: number,
    score: number,
    isMatch: boolean,
    count: number,
    differenceCount: number,
    mediaClass?: any,
) => {
    const videosArray: any[] =
        videos.labels.length > 0
            ? videos.labels.map((label) => ({ value: videos.values[label] }))
            : [];

    const videosData = videosArray.length ? videosArray.map((video, index) => {
        return (
            <div key={index} className="thumbnail-image-wrapper">
                <VideoPopup {...video}
                            key={`product-video-${index}`}></VideoPopup>
            </div>
        );
    }) : renderValueItem(null, "No videos");

    return (
        <ComparisonItem
            item={videosData}
            title="Video"
            differencePct={difference}
            differenceCount={differenceCount}
            isMatch={isMatch}
            contentClass={mediaClass}
        />
    );
};

const renderDocumentsSection = (
    skuDocuments: SkuRecord['skuData']['Assets']['Documents'],
    difference: number,
    score: number,
    isMatch: boolean,
    count: number,
    differenceCount: number,
    mediaClass?: any,
) => {
    let documentsArray: {
        label: string;
        href: string;
    }[] = [];
    const newTabLink = { target: '_blank', rel: 'noreferrer noopener' };

    if (skuDocuments && skuDocuments.labels.length > 0) {
        documentsArray = skuDocuments.labels.map((labelName) => {
            const doc = skuDocuments.values[labelName];
            const label = doc.meta.title ? doc.meta.title : labelName;

            return { label, href: doc.url, ...newTabLink };
        });
    }
    const documentsData = documentsArray.length ? documentsArray.map((document, idx) => (
        <Popup
            key={`${idx}_${document.label}`}
            trigger={
                <div className="thumbnail-image-wrapper">
                    <a className="thumbnail-image" {...documentsArray[idx]}>
                        <FileCopyIcon />
                    </a>
                </div>
            }
            position="top center"
            on="hover"
            children={<div className="text-center">{document.label}</div>}
        />
    )) : renderValueItem(null, "No documents");

    return (
        <ComparisonItem
            item={documentsData}
            title="PDF"
            differencePct={difference}
            differenceCount={differenceCount}
            isMatch={isMatch}
            contentClass={mediaClass}
        />
    );
};

const renderPreviousVersionColumn = (skuRecord: SkuRecord, comparedSkuRecord: SkuRecord, classes, onHeaderClick, sectionExpansion) => {
    const comparedLatestRubric = getLatestRubric(comparedSkuRecord);
    const sectionsMatches = {};

    for (const sectionName of Object.keys(
        skuRecord.status.accuracy.previous.analysis,
    )) {
        const section = skuRecord.status.accuracy.previous.analysis[sectionName];
        let number = 0;
        let count = 0;
        for (const sectionItem of Object.values(section)) {
            if (sectionItem.hasOwnProperty('difference')) {
                number +=
                    (sectionItem as AccuracyAnalysisResult).compare === ProductAccuracyState.MATCH ? 1 : 0;
                count++;
            } else {
                const values = Object.values(
                    (sectionItem as DynamicLengthAccuracyAnalysisResult).values,
                );
                for (const item of values) {
                    number += item.compare === ProductAccuracyState.MATCH ? 1 : 0;
                    count++;
                }

            }
        }
        sectionsMatches[sectionName] = count === number;
    }

    let missedBullets = 0;
    let bulletsMatch = 0;
    const bulletsData = skuRecord.status.accuracy.previous.analysis.marketing.bullets.labels.map(label => {
        const bullet = skuRecord.status.accuracy.previous.analysis.marketing.bullets.values[label];

        bulletsMatch += bullet.compare === ProductAccuracyState.MATCH ? 1 : 0;

        if (bullet.compare === ProductAccuracyState.MISSING) {
            missedBullets++;
            return null;
        }
        const diff = bullet.comparedValue.length - bullet.baseValue.length;
        return (<>
            <div className={classes.content}>
                {bullet.comparedValue}
            </div>
            <div className={classes.bulletsWarningSection}>
                <span className={classes.warningSectionCount} style={{color: diff === 0 ? '#00DF00' : 'red'}}>{diff}</span> Characters
            </div>
        </>);
    });
    const bulletDiff = 1 - bulletsMatch / (bulletsData.length);

    const comparedOther = comparedSkuRecord?.skuData?.Other || {} ;
    const comparedSpecsOther = comparedSkuRecord?.skuData?.Specifications?.Other || {};

    return (
        <Grid item xs={4} className={classes.section}>
            <div className={classes.columnTitle}>
               <div>
                   <h1 className="dual">
                       PREVIOUS<span>Version</span>
                   </h1>
                   <h3>{new Date(comparedSkuRecord.timestamp).toLocaleDateString()}</h3>
               </div>
                <div className={classes.accuracyTotal}>
                    <div className={classes.accuracyTotalNumber} style={{color: skuRecord.status.accuracy.previous.overall < 100 ? 'red' : '#00DF00'}}>
                        {skuRecord.status.accuracy.previous.overall}%
                    </div>
                    <div className={classes.accuracySubtitle}>
                        <span>vs. </span>CURRENT<span className={classes.thinFont}>VERSION</span>
                    </div>
                </div>

            </div>

            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Unique ID\'s', sectionsMatches[ProductAccuracySection.UID], ProductAccuracySection.UID, onHeaderClick, classes)}
                {sectionExpansion.uid &&
                <>
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['Brand Name']}
                        title="Brand"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.uid.brand.difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.uid.brand.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.uid.brand.compare ===
                            ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['Retailer UID']}
                        title="Retailer ID"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.uid.retailerUid
                                .difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.uid.retailerUid.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.uid.retailerUid
                                .compare === ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['Model']}
                        title="Model #"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.uid.model.difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.uid.model.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.uid.model.compare ===
                            ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['Manufacturer Part Number']}
                        title="MFG Part #"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.uid
                                .manufacturerPartNumber.difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.uid.manufacturerPartNumber.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.uid
                                .manufacturerPartNumber.compare === ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['UPC (GTIN-12)']}
                        title="UPC GTIN-12"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.uid.upc.difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.uid.upc.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.uid.upc.compare ===
                            ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                </>
                }
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle(
                    'Marketing Group',
                    sectionsMatches[ProductAccuracySection.MARKETING],
                    ProductAccuracySection.MARKETING,
                    onHeaderClick,
                    classes,
                )}
                {sectionExpansion.marketing &&
                <>
                    <ComparisonItem
                        item={comparedSkuRecord.skuData.UID['Product Name']}
                        title="Title"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.marketing.title
                                .difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.marketing.title
                            .differenceCount}
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.marketing.title
                                .compare === ProductAccuracyState.MATCH
                        }
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={htmlStringToReact(comparedSkuRecord.skuData.Copy['Short Description - Raw'] || comparedSkuRecord.skuData.Copy['Short Description'])}
                        title="Description"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.marketing.description
                                .difference
                        }
                        differenceCount={ skuRecord.status.accuracy.previous.analysis.marketing.description
                            .differenceCount}
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.marketing.description
                                .compare === ProductAccuracyState.MATCH
                        }
                        contentClass={classes.contentDescription}
                        secondProgressCardTitle={"Characters"}
                    />
                    <ComparisonItem
                        item={<div className={classes.bulletsWrapper}>{bulletsData}</div>}
                        title="Bullet"
                        differencePct={bulletDiff}
                        differenceCount={-missedBullets}
                        isMatchSectionHidden={true}
                        isMatch={bulletDiff === 0}
                        contentClass={classes.contentBullets}
                    />
                </>
                }
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Image Group', sectionsMatches[ProductAccuracySection.ASSETS], ProductAccuracySection.ASSETS, onHeaderClick, classes)}
                {sectionExpansion.assets && renderImagesSection(
                    comparedSkuRecord.skuData.Assets.Images,
                    skuRecord.status.accuracy.previous.analysis.assets.images.difference,
                    comparedSkuRecord.status.health.rubric[comparedLatestRubric].scores
                        .assets.images?.score,
                    skuRecord.status.accuracy.previous.analysis.assets.images.compare ===
                    ProductAccuracyState.MATCH,
                    0,
                    skuRecord.status.accuracy.previous.analysis.assets.images.differenceCount,
                    classes.contentMedia,
                )}
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle(
                    'Rich Content Group',
                    sectionsMatches[ProductAccuracySection.RICH_CONTENT],
                    ProductAccuracySection.RICH_CONTENT,
                    onHeaderClick,
                    classes,
                )}
                {sectionExpansion.richContent &&
                <>
                    {renderVideosSection(
                        comparedSkuRecord.skuData.Assets.Videos,
                        skuRecord.status.accuracy.previous.analysis.richContent.videos
                            .difference,
                        comparedSkuRecord.status.health.rubric[comparedLatestRubric].scores
                            .richContent.videos?.score,
                        skuRecord.status.accuracy.previous.analysis.richContent.videos
                            .compare === ProductAccuracyState.MATCH,
                        0,
                        skuRecord.status.accuracy.previous.analysis.richContent.videos.differenceCount,
                        classes.contentMedia,
                    )}
                    {renderDocumentsSection(
                        comparedSkuRecord.skuData.Assets.Documents,
                        skuRecord.status.accuracy.previous.analysis.richContent.documents
                            .difference,
                        comparedSkuRecord.status.health.rubric[comparedLatestRubric].scores
                            .richContent.documents?.score,
                        skuRecord.status.accuracy.previous.analysis.richContent.documents
                            .compare === ProductAccuracyState.MATCH,
                        0,
                        skuRecord.status.accuracy.previous.analysis.richContent.documents.differenceCount,
                        classes.contentMedia,
                    )}
                    <ComparisonItem
                        title="360 Rotation"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.richContent.view360
                                .difference
                        }
                        /* differenceCount={skuRecord.status.accuracy.previous.analysis.richContent.view360
                            .differenceCount} */
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.richContent.view360
                                .compare === ProductAccuracyState.MATCH
                        }
                        item={renderValueItem(!!(comparedOther['360 View'] || comparedSpecsOther['360 View']))}
                    />
                    <ComparisonItem
                        title="Enhanced"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.richContent
                                .enhancedContent.difference
                        }
                        /* differenceCount={skuRecord.status.accuracy.previous.analysis.richContent
                            .enhancedContent.differenceCount} */
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.richContent
                                .enhancedContent.compare === ProductAccuracyState.MATCH
                        }
                        item={renderValueItem(!!(comparedOther['Enhanced Content'] || comparedSpecsOther['Enhanced Content']))}
                    />
                    <ComparisonItem
                        title="A+ Modules"
                        differencePct={
                            skuRecord.status.accuracy.previous.analysis.richContent
                                .aModulesCount.difference
                        }
                        differenceCount={skuRecord.status.accuracy.previous.analysis.richContent
                            .aModulesCount.differenceCount}
                        
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.richContent
                                .aModulesCount.compare === ProductAccuracyState.MATCH
                        }
                        item={renderValueItem(Number(comparedOther['A+ Modules Count'] || comparedSpecsOther['A+ Modules Count']) || 0)}
                    />
                </>
                }
            </div>      
            {skuRecord.status.accuracy?.previous?.analysis?.specifications ? <div className={classes.sectionWrapper}>
                {renderSectionTitle(
                    'Specifications',
                    sectionsMatches[ProductAccuracySection.SPECS],
                    ProductAccuracySection.SPECS,
                    onHeaderClick,
                    classes,
                )}
                {sectionExpansion.specifications &&
                <>
                    <ComparisonItem 
                        title="Specifications" 
                        item={<PDPSpecsTable sku={comparedSkuRecord} />}
                        differenceCount={skuRecord.status.accuracy.previous.analysis.specifications.specifications.differenceCount || 0}
                        differencePct={skuRecord.status.accuracy.previous.analysis.specifications.specifications.difference}
                        isMatch={
                            skuRecord.status.accuracy.previous.analysis.specifications.specifications?.compare === ProductAccuracyState.MATCH
                        }
                        contentClass={classes.specsTable}
                    />
                </>
                }
            </div> : null}
        </Grid>
    );
};

const renderCurrentVersionColumn = (skuRecord: SkuRecord, classes, onHeaderClick, sectionExpansion) => {
    const latestRubric = getLatestRubric(skuRecord);

    const bulletsData = skuRecord.skuData.Copy.Bullets.labels.map((label, idx) => {
        const isLastElem = idx === skuRecord.skuData.Copy.Bullets.labels.length - 1;

        return (  <>
            <div className={classes.content} style={{border: isLastElem && 'none'}}>
                {skuRecord.skuData.Copy.Bullets.values[label]}
            </div>
           <div className={classes.bulletsWarningPlaceHolder} />
        </>);
    });

    const skuOther = skuRecord?.skuData?.Other || {} ;
    const skuSpecsOther = skuRecord?.skuData?.Specifications?.Other || {};

    return (
        <Grid item xs={4} className={classes.section}>
            <h1 className="dual">
                CURRENT<span>Version</span>
            </h1>
            <h3>{new Date(skuRecord.timestamp).toLocaleDateString()}</h3>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Unique ID\'s', null, ProductAccuracySection.UID, onHeaderClick, classes,)}
                {sectionExpansion.uid &&
                <>
                    <ComparisonItem
                        item={skuRecord.skuData.UID['Brand Name']}
                        title="Brand"
                    />
                    <ComparisonItem
                        item={skuRecord.skuData.UID['Retailer UID']}
                        title="Retailer ID"
                    />
                    <ComparisonItem
                        item={skuRecord.skuData.UID['Model']}
                        title="Model #"
                    />
                    <ComparisonItem
                        item={skuRecord.skuData.UID['Manufacturer Part Number']}
                        title="MFG Part #"
                    />
                    <ComparisonItem
                        item={skuRecord.skuData.UID['UPC (GTIN-12)']}
                        title="UPC GTIN-12"
                    />
                </>
                }
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Marketing Group', null, ProductAccuracySection.MARKETING, onHeaderClick, classes)}
                {sectionExpansion.marketing &&
                <>
                    <ComparisonItem
                        item={skuRecord.skuData.UID['Product Name']}
                        title="Title"
                    />
                    <ComparisonItem
                        item={htmlStringToReact(skuRecord.skuData.Copy['Short Description - Raw'] || skuRecord.skuData.Copy['Short Description'])}
                        title="Description"
                        contentClass={classes.contentDescription}
                    />
                    <ComparisonItem
                        item={<div className={classes.bulletsWrapper}>{bulletsData}</div>}
                        title="Bullet"
                        isMatchSectionHidden={true}
                        
                        contentClass={classes.contentBullets}
                    />
                </>
                }
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Image Group', null, ProductAccuracySection.ASSETS, onHeaderClick, classes)}
                {sectionExpansion.assets && renderImagesSection(
                    skuRecord.skuData.Assets.Images,
                    null,
                    skuRecord.status.health.rubric[latestRubric].scores.assets?.images?.score,
                    undefined,
                    null,
                    null,
                    classes.contentMedia,
                )}
            </div>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Rich Content Group', null, ProductAccuracySection.RICH_CONTENT, onHeaderClick, classes)}
                {sectionExpansion.richContent &&
                <>
                    {renderVideosSection(
                        skuRecord.skuData.Assets.Videos,
                        null,
                        skuRecord.status.health.rubric[latestRubric].scores.richContent?.videos?.score,
                        undefined,
                        null,
                        null,
                        classes.contentMedia,
                    )}
                    {renderDocumentsSection(
                        skuRecord.skuData.Assets.Documents,
                        null,
                        skuRecord.status.health.rubric[latestRubric].scores.richContent?.documents?.score,
                        undefined,
                        null,
                        null,
                        classes.contentMedia,
                    )}
                    <ComparisonItem
                        title="360 Rotation"                      
                        item={renderValueItem(!!(skuOther['360 View'] || skuSpecsOther['360 View']))}
                    />
                    <ComparisonItem
                        title="Enhanced"
                        item={renderValueItem(!!(skuOther['Enhanced Content'] || skuSpecsOther['Enhanced Content']))}
                    />
                    <ComparisonItem title="A+ Modules" 
                        item={renderValueItem(Number(skuOther['A+ Modules Count'] || skuSpecsOther['A+ Modules Count']) || 0)} />
                    </>
                }
            </div>
            {skuRecord.status?.accuracy?.previous?.analysis?.specifications ? <div className={classes.sectionWrapper}>
                {renderSectionTitle('Specifications', null, ProductAccuracySection.SPECS, onHeaderClick, classes)}
                {sectionExpansion.specifications &&
                <>
                    <ComparisonItem 
                        title="Specifications" 
                        item={<PDPSpecsTable sku={skuRecord} />}
                        contentClass={classes.specsTable}
                    />
                </>
                }
            </div> : null}
        </Grid>
    );
};

const renderMasterVersionColumn = (skuRecord, classes) => {
    return (
        <Grid item xs={4} className={classes.section}>
            <h1 className="dual">
                MASTER<span>Version</span>
            </h1>
            <h3>[date]</h3>
            <div className={classes.sectionWrapper}>
                {renderSectionTitle('Coming soon', null, null, null, classes)}
            </div>
        </Grid>
    );
};

const renderColumnWithEmptyData = (skuRecord, classes, columnName) => {
    return (
      <Grid item xs={4} className={classes.section}>
        <h1 className="dual">
          {columnName}<span>Version</span>
        </h1>
        <h3>[date]</h3>
        <div className={classes.sectionWrapper}>
          {renderSectionTitle(
            "This product has no previous snapshots",
            null,
            null,
            null,
            classes
          )}
        </div>
      </Grid>
    );
};

const ProductMonitorTab = ({ skuRecord, globalState, comparedSkuRecord }: Props) => {
    const classes = styles();
    const [sectionExpansion, setSectionExpansion] = useState({
        uid: false,
        assets: false,
        marketing: false,
        richContent: false,
        specifications: false
    });
    const onHeaderClick = (section: string) =>
        setSectionExpansion({
            ...sectionExpansion,
            [section]: !sectionExpansion[section],
        });
    return (
        <Grid container className={clsx(classes.root, "MonitorTab")}>
            {renderCurrentVersionColumn(skuRecord, classes, onHeaderClick, sectionExpansion)}
            {comparedSkuRecord && skuRecord.status?.accuracy?.previous?.analysis ?
                renderPreviousVersionColumn(skuRecord, comparedSkuRecord, classes, onHeaderClick, sectionExpansion)
            :
                renderColumnWithEmptyData(skuRecord, classes, 'previous')
            }
            {renderMasterVersionColumn(skuRecord, classes)}
        </Grid>
    );
};

export default ProductMonitorTab;
