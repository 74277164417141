import React from 'react';
import { EmptyDialogProps } from './datastructures';
import './style.scss';
import Dialog from '@material-ui/core/Dialog';

const EmptyDialog = (props: EmptyDialogProps) => {
    const open = props.open === undefined
    ? true
    : props.open;

    const dialogBody = <div className="cs-success-dialog">
        <h1>{props.title}</h1>
        {props.children || ""}
    </div>;

    const materialDialog = <Dialog 
        open={open}
        disableBackdropClick={true}
    >
        {dialogBody}
    </Dialog>;

    return materialDialog;
}

export default EmptyDialog;
