import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SiteLegalConfig } from './terms';

interface PublicLegalPageProps {
	router: RouteComponentProps<any>;
}

interface State {

}

class PublicLegalPage extends React.PureComponent<PublicLegalPageProps, State> {
    constructor(props: PublicLegalPageProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    chosenTerm = (termPath: 'cookies' | 'termsofuse' | 'privacy') => {
        let chosenTerm: keyof typeof SiteLegalConfig | undefined;
        switch (termPath) {
            case 'cookies':
                chosenTerm = 'CookiesPolicy';
                break;
            case 'termsofuse':
                chosenTerm = 'TermsOfUse';
                break;
            case 'privacy':
            default:
                chosenTerm = 'PrivacyPolicy';
                break;
        }
        return chosenTerm;
    }

    termDefinitonView = (termPath: 'cookies' | 'termsofuse' | 'privacy') => {
        
        const chosenTerm = this.chosenTerm(termPath);
        const termDefinition = SiteLegalConfig[chosenTerm];
        const title = <h2>{termDefinition.name}</h2>
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        const iframe = <iframe {...{title: termDefinition.iframeTitle, src: termDefinition.iframeSrc, style: {
            width: '100%', height: 400, border: 'none'//, borderRadius: 3
        }}} />

        return <div id={termPath}>
            {title}
            {iframe}
        </div>
    }

    termView = () => {
        const termPaths = ['cookies', 'termsofuse', 'privacy'];
        const tableOfContents = <ul>
            {termPaths.map((termPath: any) => <li><a href={'#'+termPath}>{
                SiteLegalConfig[this.chosenTerm(termPath)].name
            }</a></li>)}
        </ul>
        const views = termPaths.map(path => this.termDefinitonView(path as any));

        return <div>
            {tableOfContents}
            {views}
        </div>
    }

    render() {
        return <div style={{height: '100%', margin: '30px 5% 15px'}}>
            <h1>Content Status, LLC - Legal Terms</h1>
            {this.termView()}
        </div>
    }

}

export default PublicLegalPage;