import { Score, ScoreRange, ScorePointRule } from './types/score';
import { Mathlib, Objectkeys } from '../types/datastructures';

export interface Scorer {
  potential: number;
  score: (value: number) => Score;
}

interface ScoreByRange extends Scorer {
  ranges: Array<ScoreRange>;
}

interface ScoreByPointRule extends Scorer {
  rule: ScorePointRule;
}

const generate_Scorer = (potential: number, scoreFn: (value: number) => Score): Scorer => ({potential, score: scoreFn});

const generate_RangeScorer = (ranges: Array<ScoreRange>, wheretofocus: string, steps?: string[]): ScoreByRange => {
  const default_potential = 5;
  const default_steps = (steps) ? steps : ranges.map(range => range.min).map(String);
  const default_scoreFn = (value: number): Score => {
    const matchedRange = ranges.map((range, idx) => {
      return {...range, ...((range.max === undefined && idx !== ranges.length-1) ? {max: ranges[idx+1].min-.0001} : {})}
    }).find(range =>
      (value >= range.min && value <= range.max) || typeof range.max == 'undefined'
    );

    const scoreVal = matchedRange.score === undefined || matchedRange.score === null
      ? 0
      : matchedRange.score;
    return {
      score: scoreVal,
      potential: default_potential,
      steps: default_steps,
      wheretofocus
    };
  }
  const scorer = generate_Scorer(default_potential, default_scoreFn);
  return { ranges, ...scorer };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generate_PointRuleScorer = (rule: ScorePointRule, steps: string[], wheretofocus: string, stepBreaks?: number[]): ScoreByPointRule => {
  const default_potential = rule.max;
  const default_scoreFn = (value: number): Score => {
    const score = Math.min(
      rule.max,
      rule.pointSize * Math.max(
        0,
        value - (rule.startCountingAt - 1)
      )
    );
    return { score: score || 0, potential: default_potential, steps, wheretofocus, stepBreaks };
  }
  const scorer = generate_Scorer(default_potential, default_scoreFn);
  return { rule, ...scorer };
}

export const ScoreTitle = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 25, score: 2 },
    { min: 50, score: 3 },
    { min: 55, score: 4 },
    { min: 60, score: 5 }
  ],
  'Walmart product names should follow: Brand + Defining Qualities + Item Name + Pack Count, if applicable, and should be 50-80 characters.'
);

export const ScoreShortDescription = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 300, score: 2 },
    { min: 480, score: 3 },
    { min: 600, score: 4 },
    { min: 800, score: 5 }
  ],
  'An overview in paragraph form with the key selling points of the product, marketing content and product highlights.'
);

export const ScoreLongDescription = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 240, score: 2 },
    { min: 420, score: 3 },
    { min: 800, score: 4 },
    { min: 2000, score: 5 }
  ],
  "In bullet form, the product's key features, benefits and item specifics."
);

export const ScoreRatingsAverage = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 2, score: 2 },
    { min: 3, score: 3 },
    { min: 4, score: 4 },
    { min: 4.5, score: 5 }
  ],
  'Boost your average ratings with an influx of new reviews.'
);

/* export const ScoreRatingsAverage = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 2.5, score: 1 },
    { min: 3, score: 2 },
    { min: 3.5, score: 3 },
    { min: 4, score: 4 },
    { min: 4.5, score: 5 }
  ],
  'Boost your average ratings with an influx of new reviews.'
); */

export const ScoreRatingsCount = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 20, score: 1 },
    { min: 50, score: 2 },
    { min: 75, score: 3 },
    { min: 100, score: 4 },
    { min: 200, score: 5 }
  ],
  ''
);

export const ScoreReviewsCount = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 5, score: 1 },
    { min: 10, score: 2 },
    { min: 20, score: 3 },
    { min: 50, score: 4 },
    { min: 100, score: 5 }
  ],
  '50 reviews is the magic number you want to get to, but keeping a constant flow of current reviews is important as well.'
);

export const ScoreRecommendedPercentage = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 70, score: 3 },
    { min: 80, score: 4 },
    { min: 90, score: 5 }
  ],
  ''
);

export const ScoreBullets = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 3, score: 2 },
    { min: 5, score: 3 },
    { min: 7, score: 4 },
    { min: 9, score: 5 }
  ],
  '5 bullets is the key to products with higher conversions.'
);

/* export const ScoreBullets = generate_PointRuleScorer(
  {pointSize: 1, startCountingAt: 1, max: 5},
  ["3", "4", "5"],
  '5 bullets is the key to products with higher conversions.'
); */

export const ScoreImages = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 2, score: 2 },
    { min: 4, score: 3 },
    { min: 5, score: 4 },
    { min: 7, score: 5 }
  ],
  'Walmart prefers at least 4 images for each product.'
);

/* export const ScoreImages = generate_PointRuleScorer(
  {pointSize: 1, startCountingAt: 2, max: 5},
  ["3", "4", "5"],
  'Walmart prefers at least 4 images for each product.'
); */

export const ScoreImageResolution = generate_RangeScorer(
  [
    { min: 0, score: 0 },
    { min: 1, score: 1 },
    { min: 25, score: 2 },
    { min: 50, score: 3 },
    { min: 80, score: 4 },
    { min: 100, score: 5 }
  ],
  'Image resolution is key for zoom functionality, so your goal is to have 100% of your images surpass the minimum.'
);

/* export const ScoreImageResolution = generate_PointRuleScorer(
  {pointSize: .5, startCountingAt: 1, max: 5},
  ["3", "4", "5"],
  'Image resolution is key for zoom functionality, so your goal is to have 100% of your images surpass the minimum.'
); */

export const ScoreVideos = generate_RangeScorer(
  [
    { min: 0, score: NaN },
    { min: 1, score: 5 }
  ],
  '',
  ['0', '', '1']
);

/* export const ScoreVideos = generate_PointRuleScorer(
  {pointSize: 3, startCountingAt: 1, max: 5},
  ["1", "2", "3"],
  ''
); */

export const ScoreDocuments = generate_RangeScorer(
  [
    { min: 0, score: NaN },
    { min: 1, score: 5 }
  ],
  '',
  ['0', '', '1']
);

export const ScoreRichContent = generate_RangeScorer(
  [
    { min: 0, score: 3 },
    { min: 1, score: 5 }
  ],
  '',
  ['0', '', '1']
);

export class ScoreCollection2 {

  private scores: {
    [x: string]: Score;
  };

  private sections: {
    [x: string]: Score[];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public static avgScoreArray = (scores: Score[]) => {
    const scoreArray = scores.map(scoreVal => scoreVal.score)
    .filter(score => !isNaN(score));

    return {
      score: Mathlib.avg(scoreArray),
      potential: 5
    };
  }

  /**
   * Sets score on object and ensures a key's value isn't overwritten.
   * @param {string} key    Score's identifier
   * @param {object} parent Object that the key/score pair are set on
   * @param {Score}  score
   */
  private static addScoreAtKey(key: string, parent: { [x: string]: Score }, score: Score) {
    if (parent[key] !== undefined) {
      throw new Error('A score already exists at the specified location.');
    }
    parent[key] = score;
  }

  private static scoreKeyFromSectionScore = (key: [string, string]) => `${key[0]}.${key[1]}`

  constructor() {
    this.scores = {};
    this.sections = {};
  }

  public toString = () => JSON.stringify(this.getCollectionObject())

  public getCollectionObject = () => ({
    scores: this.scores,
    sections: this.sections
  })

  /**
   * Add a score to collector.
   * @param  {string|[string,string]} key Key or [section, key] tuple.
   * @param {Score}  score   Score to be stored.
   */
  public add = (key: string | [string, string], score: Score) => {
    const [sectionKey, scoreKey] = (typeof key === 'string')
    ? [key, key]
    : [key[0], ScoreCollection2.scoreKeyFromSectionScore(key)];

    ScoreCollection2.addScoreAtKey(scoreKey, this.scores, score)

    if (!this.sections[sectionKey]) {
      this.sections[sectionKey] = [];
    }
    this.sections[sectionKey].push(score);
  }



  /**
   * Retreive a Score from the collection.
   * @param  {string|[string,string]} key Key or [section, key] tuple.
   * @return {Score}
   */
  public get = (key: string | [string, string]) => {
    const scoreKey = (typeof key === 'string')
    ? key
    : ScoreCollection2.scoreKeyFromSectionScore(key);

    return this.scores[scoreKey];
  }

  /**
   * Adds up scores for specified section.
   * @param  {string} section
   * @return {Score}
   */
  private sumSection = (section: string) => {
    const sectionArray = this.sections[section];
    const avg: Score = {
      ...ScoreCollection2.avgScoreArray(sectionArray),
      steps: [],
      wheretofocus: ''
    };
    return avg;
  }

  /**
   * Add up the scores and potential for all scores or a specific section.
   * @param  {string} section Section identifier.
   * @return {Score}
   */
  public sum = (section?: string) => {
    if (section !== undefined) {
      return this.sumSection(section);
    }
    const sectionArray = Objectkeys(this.sections).map(this.sumSection)
    const avg: Score = {
      ...ScoreCollection2.avgScoreArray(sectionArray),
      steps: [],
      wheretofocus: ''
    };
    return avg;
  }

  /**
   * Determine if a Score exists for a specified section and key combination in
   * the collection.
   * @param  {string} section Section name
   * @param  {string} key     Key name
   * @return {boolean}
   */
  public has = (section: string, key: string) => {
    return this.scores[key] !== undefined;
  }
}
