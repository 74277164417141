import { Button, CircularProgress, MenuItem, Select, TextField } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import StarIcon from '@material-ui/icons/Star';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import axios from "axios";
import { mapKeys, orderBy, cloneDeep } from "lodash";
import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import TopLevelPageLayout from "../../layouts/TopLevelPageLayout";
import { API_URL, copyToClipboard, defaultVal, retailerNameRender } from "../../libraries/types/datastructures";
import { CSUIButton, CSUISegment } from "../CSUI";
import { CSUIDxReactGrid } from "../CSUI/CSUIDataTable/DXReactGrid";
import { CSUIMUIXGrid, RT_CellInfo, RT_Column } from "../CSUI/CSUITable";
import * as Papa from 'papaparse';
import "./style.scss";
import Logo from "../../layouts/MainLayout/Logo";
import { DSFormState, DSOS_SearchPrefixes, ScrapeHeroSearchBox } from "./searchbox";
import { SOSCharts } from "./dscharts";
import { DSPublicResultsUI } from "./publicresultsui";
import Rating from "@material-ui/lab/Rating";
import { TrackingService } from "../../libraries/Tracking";

export function API_CALL_ScrapeHeroSearch(retailer: string, searchUrl: string) {
  return axios.post(`${API_URL}/misc/qlDigitalShelf`, { retailer, searchUrl });
}

export function API_CALL_DSLogRequests(email: string, retailer: string, searchUrl: string) {
  //alert(JSON.stringify({email, retailer, searchUrl }))
  return axios.post(`${API_URL}/misc/DSLogRequests`, {email, retailer, searchUrl });
}

export interface CSShelfData {
  page: number;
  rank: number;
  isSponsored: boolean;
  isFeatured: boolean;
  paid: string;
  badge: string;
  brand: string;
  productId: string;
  imageUrl: string;
  productUrl: string;
  productTitle: string;
  usageTitle: number;
  usageDescription: number;
  usageBullets: number;
  rating: number;
  reviewCount: number;
  msrp: string;
  price: string;
  availability: string;
  shippingOption: string;
}

export interface CSShelfDataResults {
  query: { retailer: string; searchUrl: string; page: number; };
  data: CSShelfData[];
  next_page?: string;
}

interface DigitalShelfProps {
  public?: boolean
  print?: boolean
}

export interface DSState {
  apiResponse?: CSShelfDataResults;
  dataLoading: boolean;
  formData?: DSFormState;
  apiRequest?: {
    retailer: string;
    searchUrl: string;
  }
}

const tableConfig = {
    brand: {width: 110, headerLabel: "Brand", align: 'left'},
    availability: {width: 150, headerLabel: "Availability", align: 'left'},
    description: {width: 150, headerLabel: "Description", align: 'left'},
    imageUrl: {width: 90, headerLabel: "Image"},
    productTitle: {width: 300, headerLabel: "Title", align: 'left'},
    page: {width: 80, headerLabel: "Page"},
    productId: {width: 120, headerLabel: "Product ID", align: 'left'},
    badge: {width: 90, headerLabel: "Badge", align: 'left'},
    productUrl: {width: 300, headerLabel: "Link", align: 'left'},
    rank: {width: 80, headerLabel: "Rank"},
    rating: {width: 90, headerLabel: "Rating"},
    msrp: {width: 90, headerLabel: "MSRP", align: 'right'},
    reviewCount: {width: 100, headerLabel: ["Review", "Count"]},
    price: {width: 90, headerLabel: ["Sale", "Price"], align: 'right'},
    shippingOption: {width: 120, headerLabel: ["Shipping", "Option"], align: 'left'},
    paid: {width: 90, headerLabel: "Paid", align: 'left'},
    usageTitle: {width: 90, headerLabel: ["Usage", "Title"]},
    usageDescription: {width: 90, headerLabel: ["Usage", "Desc."]},
    usageBullets: {width: 90, headerLabel: ["Usage", "Bullet"]}
}

const postToParent = (message: string | object) => {
  if (window?.parent?.postMessage) {
    window.parent.postMessage(message, "https://contentstatus.com/");
  }
}

let height_cache = null;

const postToParentResize = () => {
  const div = document.getElementById("digital-shelf-container") as HTMLDivElement
  //const body = document.body, html = document.documentElement;
  const height = Math.max(div.scrollHeight, div.offsetHeight,
    div.clientHeight);

  if (height !== height_cache) {
    const message = {
      height,
      type: "dsos-resize"
    }
    postToParent(message);
  }

  height_cache = height;
}

const postToParentEmail = (email: string) => {
  const message = {
    email,
    type: "dsos-email"
  }
  postToParent(message);
}

class DigitalShelf extends React.PureComponent<DigitalShelfProps, DSState> {
  private intervalId: NodeJS.Timeout;

  constructor(props: DigitalShelfProps) {
    super(props);
    this.state = {
      dataLoading: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.public) {
      window.addEventListener("resize", postToParentResize);
      this.intervalId = setInterval(postToParentResize, 2000);
    }
  }

  componentWillUnmount = async () => {
    if (this.props.public) {
      window.removeEventListener("resize", postToParentResize);
      clearInterval(this.intervalId);
    }
  }

  onSubmit = async (data: DSFormState) => {
    //console.log(data);
    this.setState({ dataLoading: true, formData: data }, async () => {
      const retailer = data.retailer;
      const searchPrefix = DSOS_SearchPrefixes[retailer] || '';

      const searchUrl = data.inputType === 'searchUrl'
      ? data.input
      : `${searchPrefix}${encodeURIComponent(data.input)}`;

      const apiResponse = await API_CALL_ScrapeHeroSearch(
          retailer,
          searchUrl
      );
      this.setState({ 
        apiResponse: apiResponse.data, 
        dataLoading: false, 
        apiRequest: {retailer, searchUrl} 
      }, () => {
        if (this.props.public) {
          postToParentResize();
        } else {
          
          TrackingService.onEvent("runJobSOS");
        }
      });
    });
  };

  createImgPopup = (srcParam: string) => {
    const split = srcParam.split("http")[1];
    if (srcParam && split) {
      const src = "http"+split;
      const trigger = <img /* style={{ maxWidth: 25, maxHeight: 25 }} */ src={src} alt=""/>;
      const content = <img style={{
          display: 'block',
          maxWidth: 200,
          maxHeight: 200,
          width: 'auto'
      }} src={src} alt=""/>;

      const reactjsPopup = <Popup key={src} trigger={<div className="img-holder" style={{
          display: 'flex',
          justifyContent: 'center'
      }}>{trigger}</div>} position="right top" on="hover">
          <div style={{
              display: 'flex',
              justifyContent: 'center'
          }}>{content}</div>
      </Popup>

      return reactjsPopup;
    } else {
      return undefined
    }
  };

  copyURLs = (rows: CSShelfData[]) => {
    const str = rows.map(x => x.productUrl).join("\n");
    const result = !!copyToClipboard(str, "digital-shelf-container");
    if (result) {
      alert((rows.length === 1 ? "1 URL" : rows.length + " URLs") + " copied!");
    }
  }

  displayCell = (columnId: string, row: CSShelfData) => {
    try {
      if (row) {
        switch (columnId as keyof typeof tableConfig) {
          case 'productUrl':
              return <a href={row[columnId]} target="_blank" rel="noopener noreferrer">Link</a>;
          case 'imageUrl':
              return this.createImgPopup(row[columnId]) || "--";
          case 'productTitle':
            if (row['productUrl']) {
              const href = row['productUrl'];
              return <div className="name-cell">
                  <a href={href} target="_blank" rel="noopener noreferrer">{row[columnId]}</a>
              </div>
            } else {
              let returnText = "(Not available)"
              if (this.state.formData.retailer === 'amazon' && row.isFeatured) {
                returnText = "(Not available for featured products)";
              }
              return returnText;
            }
          case 'msrp':
          case 'price':
              return row[columnId] ? "$"+row[columnId] : undefined;
          case 'usageBullets':
          case 'usageDescription':
          case 'usageTitle':
              return "--";
          case 'rank':
              return row[columnId] ? "#"+row[columnId] : <StarIcon className="star-icon" />
          case 'paid':
              return row.isFeatured ? "Featured" : row.isSponsored ? "Sponsored" : "--"
          default:
              return row[columnId] || "--"; 
        }
      } else {
          return "--";
      } 
    } catch (e) {
      return "--"
    }
  }

  displayTableOld = (columnIds: string[], rows: any[]) => {

    const columns = columnIds.map(columnId => {
        let headerArray = undefined;
        if (typeof tableConfig[columnId]?.headerLabel === 'string') {
            headerArray = [<>&zwnj;</>, tableConfig[columnId]?.headerLabel];
        } else {
            headerArray = tableConfig[columnId]?.headerLabel
        }
        const headerLabel = <>{headerArray[0]}<br />{headerArray[1]}</>;

        return {
            Header: (
              <div
                style={{
                  //borderBottom: "1px solid indigo",
                  padding: "5px 0",
                  textAlign: "left",
                  fontSize: "1.2rem",
                  fontWeight: 700
                }}
              >
                {headerLabel || columnId}
              </div>
            ),
            // expander: headerName === 'accuracy',
            headerStyle: {
              // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
              fontWeight: "bold",
              borderRightColor: "transparent",
            },
            width: tableConfig[columnId].width,
            style: { textAlign: "center" } ,
            //id: columnId,
            ...{
              // [headerName === 'accuracy' ? 'Expander' : 'Cell']
              Cell: (cellInfo: RT_CellInfo) => <>
                {this.displayCell(columnId, cellInfo.original)}
              </>
            },
          } as RT_Column;
    });

    return <div>
        <div className="button-holder">
            <Button
                color="secondary"
                variant="contained"
                onClick={() => this.copyURLs(rows)}
                startIcon={<FileCopy />}
                >
                Copy URLs
            </Button>
            <Button
                color="secondary"
                variant="contained"
                onClick={this.triggerDownload}
                startIcon={<SaveAltIcon />}
                >
                Download
            </Button>
        </div>
        <CSUIDxReactGrid
            rows={rows}
            columns={columns}
            isDataLoading={false}
            numSkus={rows.length}
            getRowId={(row) => row.product_id}
            //rowConfig={{}}
            /* maxWidth={1800}
            pagination={true}
            paginationSize={25} */
        />
    </div>

  }

  displayColumns = (columnIds: string[]) => {
    const columns = columnIds.map(columnId => {
      let headerArray = undefined;
      if (typeof tableConfig[columnId]?.headerLabel === 'string') {
          headerArray = [<>&zwnj;</>, tableConfig[columnId]?.headerLabel];
      } else {
          headerArray = tableConfig[columnId]?.headerLabel
      }
      const headerLabel = <>{headerArray[0]}<br />{headerArray[1]}</>;
      const align = tableConfig[columnId]?.align || 'center';

      return {
          renderHeader: (params) => (
            <div
              style={{
                //borderBottom: "1px solid indigo",
                padding: "5px 0",
                textAlign: "left",
                fontSize: "1.1rem",
                fontWeight: 700,
                borderRightColor: "transparent",
              }}
            >
              {headerLabel || columnId}
            </div>
          ),
          headerClassName: 'grid-header',
          // expander: headerName === 'accuracy',
          headerStyle: {
            // backgroundColor: '#c8c8c8', // 'rgb(255,192,0)',
            fontWeight: "bold",
            borderRightColor: "transparent",
          },
          field: columnId,
          width: tableConfig[columnId].width,
          resizable: true,
          style: { textAlign: align } ,
          align,
          //id: columnId,
          ...{
            // [headerName === 'accuracy' ? 'Expander' : 'Cell']
            renderCell: (params) => <>
              {this.displayCell(columnId, params.row)}
            </>
          },
        };
    });

    return columns;
  }

  displayTable = (columnIds: string[], rows: CSShelfData[]) => {

    const columns = this.displayColumns(columnIds);

    return <div>
        <div className="button-holder">
            <Button
                color="secondary"
                variant="contained"
                onClick={() => this.copyURLs(rows)}
                startIcon={<FileCopy />}
                >
                Copy URLs
            </Button>
            <Button
                color="secondary"
                variant="contained"
                onClick={this.triggerDownload}
                startIcon={<SaveAltIcon />}
                >
                Download
            </Button>
        </div>
        <CSUIMUIXGrid 
            rows={rows}
            autoHeight={false}
            rowHeight={100}
            columns={columns}
            loading={false}
            getRowClassName={() => 'table-row'}
        />
        {/* <CSUIDxReactGrid
            rows={rows}
            columns={columns}
            isDataLoading={false}
            numSkus={rows.length}
            getRowId={(row) => row.product_id}
            //rowConfig={{}}
            maxWidth={1800}
            pagination={true}
            paginationSize={25}
        /> */}
    </div>

  }

  tableData = () => {
    const { apiResponse } = this.state;
    const rows = [
        ...(apiResponse?.data?.filter((row) => row.isFeatured) || []),
        ...(orderBy(apiResponse?.data?.filter((row) => !row.isFeatured), ['rank']) || [])
    ].map((row, idx) => ({...row, id: row.productId + idx.toString()}));

    return rows;
  }

  organicTable = () => {
    const rows = this.tableData();

    const columnIds = [
        "page",
        "rank",
        "paid",
        "badge",
        "brand",
        "productId",
        "imageUrl",
        "productTitle",
        "usageTitle",
        "usageDescription",
        "usageBullets",
        "rating",
        "msrp",
        "price",
        "availability",
        "shippingOption"
    ];

    return this.displayTable(columnIds, rows);
  };

  organicPrint = () => {
    const rows = this.tableData();

    const columnIds = [
        "page",
        "rank",
        "badge",
        "productId",
        "imageUrl",
        "productTitle",
        "usageTitle",
        "usageDescription",
        "usageBullets",
        "rating",
        "msrp",
        "price",
        "availability",
        "shippingOption"
    ];
    const columns = this.displayColumns(columnIds);

    const titleDisplayVal = (row: any) => {
      const res = this.displayCell("productTitle", row);
      if (typeof res === 'string' && res.startsWith("(Not available")) {
        return <div className="kv"><div>Title: </div><div>{res}</div></div>
      } else {
        return res;
      }
    }

    const ratingStars = (row: any) => {
      const ratingVal = parseFloat(row['rating']);
      if (isNaN(ratingVal)) {
        return null
      }
      
      return <div><Rating readOnly value={ratingVal} precision={.5} /><span>{ratingVal.toFixed(1).replace(".0", "")+"%"}</span></div>
    }

    const out = <div className="print-table">
      <div className="cs-row cs-header-row">
        {columns.map(col => <div 
          key={col.field} 
          className="cs-header-cell"
          style={{ width: col.width}}
        >
          {col.renderHeader(undefined)}
        </div>)}
      </div>
      <div className="cs-body">
        {rows.map(row => <div key={row.id} className="cs-row">
          {columns.map(col => <div 
          key={col.field} 
          className="cs-body-cell"
          style={{ ...col.style, width: col.width}}
         >
            {col.renderCell({row})}
          </div>)}
        </div>)}
      </div>
    </div>
    return out;
  };

  dataForCsv = () => {
      const csvArray = this.tableData().map(row => mapKeys(row, (value, key) => {
            const {headerLabel} = tableConfig[key] || {};
            const newKey = headerLabel
            ? typeof headerLabel === "string"
                ? headerLabel
                : headerLabel.join(" ")
            : key;

            return newKey;
      }));

      return Papa.unparse(csvArray);
  }

  triggerDownload = () => {
    const csv = this.dataForCsv();
    const downloadLink = document.createElement("a");
    const blob = new Blob(["\ufeff", csv]);
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    const fileName = `CS_Share-of-Shelf-Beta_${retailerNameRender(this.state.formData.retailer)}_${Date.now()}.csv`;
    downloadLink.download = fileName;
    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  fullResultsUI = () => {

    return <div style={{paddingTop: 30}}>
      <SOSCharts tableData={this.state.apiResponse ? this.tableData() : undefined}/>
      <h2>Results</h2>
      <div className="legend">
          <div><div><StarIcon className="star-icon" /></div><div>Featured = Paid placements at the top of the page with typically only one brand featured</div></div>
          <div><div /><div>Sponsored = Paid placements that fall within the results</div></div>
      </div>
      
      <div className="table-container" id="organic">
          {this.organicTable()}
      </div>
      <div className="table-container" id="organic-print">
          {this.organicPrint()}
      </div>
    </div>
  }

  publicDemoSubmitHandler = async (email: string) => {
    const pro = API_CALL_DSLogRequests(email, this.state.apiRequest.retailer, this.state.apiRequest.searchUrl);
    postToParentEmail(email);
    await pro;
  }

  publicResultsUI = () => {

    return <DSPublicResultsUI 
      tableData={this.state.apiResponse ? this.tableData() : undefined} 
      onSubmit={this.publicDemoSubmitHandler}
    />
  }

  ui = () => {
    const { dataLoading, apiResponse } = this.state;

    return (
      <>
        <ScrapeHeroSearchBox public={this.props.public} onSubmit={this.onSubmit} />
        <div id="results">
          {dataLoading ? (<div style={{flexDirection: 'column'}} className="flex-center">
                <div className="purple-text" style={{ zIndex: 9 }}>
                    <CircularProgress /> 
                </div>
                <div style={{marginTop:20}}>Please wait as the results load...</div>
            </div>
          ) : apiResponse ? (
            this.props.public
            ? this.publicResultsUI()
            : this.fullResultsUI()
          ) : (
            this.props.public
            ? null
            : "Submit a search above"
          )}        </div>
      </>
    );
  };

  privateUI = () => {
    const headerText = <>Share-of-Shelf <span style={{color: 'black', fontWeight: 400}}>beta</span></>;
    const details = <>Understanding how your products rank for key site-search terms is critical to maximizing page traffic.
    Expensive enterprise solutions just give you a list. We believe you also need to know <em>why</em>.
    So we're building our tool to also show how many times the keyword was used in the Title, Description, and Bullets.</>;

    return (
      <TopLevelPageLayout
        //className="ReportsLayout"
        title={headerText}
        details={details}
        buttons={[]}
      >
        <style>{this.props.print 
        ? `
          body, 
          div,
          #digital-shelf-container,
          .csui_mui_xgrid  
          {
            background-color: white !important;
          }
          
          header.MuiPaper-root.MuiAppBar-root,
          .newNavbar,
          div#hubspot-messages-iframe-container
          {
            display: none!important;
          }
        ` 
        : `
          .jt-dimmer,
          .jt-feedback
          {
            display: block!important;
          }
        `}</style>
        <CSUISegment id={"digital-shelf-container"} className={this.props.print ? "print-version" : ''}>
          <div id="sos-header">
            <Logo showLogo={false} primaryColor={"indigo"} className="sos-logo"/>
            <div><h2>Share-of-Shelf</h2></div>
          </div>
          {this.ui()}
        </CSUISegment>
      </TopLevelPageLayout>
    );
  };

  publicUI = () => {
    
    return (
      <div id="digital-shelf-container">
        <style>
          {`
          div#hubspot-messages-iframe-container
          {
            display: none!important;
          }
          body
          {
            background: transparent!important;
          }
          `}
        </style>
        {this.ui()}
      </div>
    );
  };

  render = () => {
    return this.props.public
    ? this.publicUI()
    : this.privateUI()
  }
}

export default DigitalShelf;
