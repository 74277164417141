import { Notification } from "../../components/Notifications/datastructures";
import { RandomId } from "../types/datastructures";

export const createNotification = (options: Partial<Notification> & {title: string; text: string;}) => {
    const note: Notification = {
        id: RandomId(),
        date: Date.now(),
        ...options
    };

    return note;
}