import { ActiveUserAccount, RandomId } from '../../types/datastructures';
import {Location} from "history"

class HubSpotServiceClass {

    public hubspotArray: Array<string | { [x: string]: any }>[];
    private initialized: boolean;
    private userInfo: { 
        email: string;
        firstname: string;
        lastname: string;
        id: string;
        cs_user_id: string;
        cs_user_role: string;
    }
    private lastTrackedUrl: string;

    private timer: {
        [x: string]: NodeJS.Timeout;
    } = {};

    constructor() {
        this.initialized = false;
        this.userInfo = { firstname: '', lastname: '', email: '', id: '', cs_user_id: '', cs_user_role: '' };
        this.lastTrackedUrl = '';
    }

    public init = async () => {
        if (!this.initialized) {
            await this.setupHubSpot();
            this.initialized = true;
        }
    }

    private setupHubSpot = async () => {
        const timerId = RandomId();

        const inst = this;
        this.hubspotArray = await new Promise(resolve =>
            this.timer[timerId] = setInterval(() => {
                const array = this.hubspotArray || window['_hsq'];
                if (array) {
                    clearInterval(inst.timer[timerId]);
                    delete inst.timer[timerId];
                    resolve(array);
                }
                    
            }, 1000)
        );
    }

    getHubSpotArray = async () => {
        if (!this.initialized) {
            await this.init();
        }
        return this.hubspotArray;
    }

    private methodCall = async (method: string, args: Array<string | { [x: string]: any }> = []) => {
        const methodArray = [method, ...args];

        const hubspotArray = await this.getHubSpotArray();

        //console.log("methodArray", methodArray);
        hubspotArray.push(methodArray);
    }

    trackPageView = async (location: Location) => {
        if (!this.initialized) {
            this.init();
        }

        const url_relative_full = location.pathname+location.search+location.hash;
        
        if (url_relative_full !== this.lastTrackedUrl) {
            this.lastTrackedUrl = url_relative_full;
            await this.methodCall('setPath', [url_relative_full]);
            await this.methodCall('trackPageView');
        }
    }

    setUser = async (activeUser: ActiveUserAccount) => {
        if (!this.initialized) {
            this.init();
        }

        const coreUser = activeUser?.user?.core;
        this.userInfo = {
            email: coreUser?.email || '',
            firstname: coreUser?.firstName || '',
            lastname: coreUser?.lastName || '', //`l: ${coreUser.lastName}, f: ${coreUser.firstName}`
            id: coreUser?.userId || '',
            cs_user_id: coreUser?.userId || '',
            cs_user_role: coreUser?.role || '',
        };

        await this.methodCall('identify', [this.userInfo]);  
    }
}

export const HubSpotService = new HubSpotServiceClass();