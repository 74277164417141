import * as React from 'react';
import * as ReactDOM from 'react-dom';
import '../../../assets/scss/common.scss';
interface CustomDropdownProps {
    onChange: (e: SimulatedEvent) => void;
    value: string;
    placeholder?: string;
    className?: string;
}
interface SimulatedTarget extends EventTarget {
    value: string;
}
interface SimulatedEvent extends React.SyntheticEvent<HTMLElement, Event> {
    target: SimulatedTarget
}
interface CustomDropdownState {
    visible: boolean;
    element: React.RefObject<HTMLDivElement>;
    boundingRect: ClientRect;
}

interface DropDownOptionsProps {
    boundingRect: ClientRect;
    onClose: () => void;
    onSelect: (e: SimulatedEvent) => void;
    className?: string;
}
interface DropDownOption {
    text: HTMLCollection | string;
    value: string;
    type: "option" | "optgroup";
    disabled: boolean;
    level: number;
    className?: string;
}
interface DropDownOptionsState {
    element: React.RefObject<HTMLUListElement>;
}
const _buildOptions = (children: React.ReactNode, level: number = 0): DropDownOption[] => {
    let options: DropDownOption[] = [];
    React.Children.forEach(children, (child: React.ReactElement<HTMLOptionElement | HTMLOptGroupElement>) => {
        if (child && child.type === "option") {
            options.push({
                text: child.props.children,
                value: (child as any).props.value,
                disabled: child.props.disabled,
                type: "option",
                level,
                className: child.props.className
            })
        } else if (child) {
            options.push({
                text: child.props.label,
                value: "",
                disabled: child.props.disabled,
                type: "optgroup",
                level
            });
            options = options.concat(_buildOptions(child.props.children, level + 1));
        }
    });
    return options;
};
class DropDownOptions extends React.Component<DropDownOptionsProps, DropDownOptionsState> {
    constructor(props: DropDownOptionsProps) {
        super(props);
        this.state = {
            element: React.createRef()
        }
    }
    componentDidMount() {
        window.addEventListener('mousedown', this.handleClick);
    }
    handleClick = (e: any) => {
        if (!this.state.element.current.contains(e.target)) {
            this.props.onClose();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClick);
    }
    optionSelected = (e: React.SyntheticEvent<HTMLElement, Event>, option: DropDownOption) => {
        if (option.value && !option.disabled) {
            // debugger;
            this.props.onSelect({ ...e, target: { ...e.target, value: option.value } } as any);
        }
    }

    render() {

        const options: DropDownOption[] = _buildOptions(this.props.children);
        return ReactDOM.createPortal(
            <ul ref={this.state.element} style={{
                position: "fixed",
                left: this.props.boundingRect.left,
                top: this.props.boundingRect.top + this.props.boundingRect.height,
                width: `${Math.floor(this.props.boundingRect.width)}px`
            }} className={`custom-dropdown-options ${this.props.className || ""}`}>
                {options.map((option: DropDownOption, index: number) => {
                    if (option.type === "option") {
                        return <li key={`dropdown-option-${index}`} className={`option ${option.disabled && 'disabled'} level-${option.level} ${option.className}`} onClick={(e: React.MouseEvent) => this.optionSelected(e as any, option)}>{option.text}</li>;
                    } else {
                        return <li key={`dropdown-optgroup-${index}`} className={`optgroup  ${option.className} level-${option.level}`}>
                            {option.text}
                        </li>
                    }
                })}
            </ul>,
            document.body);
    }
}
class CustomDropDown extends React.Component<CustomDropdownProps, CustomDropdownState> {
    constructor(props: CustomDropdownProps) {
        super(props);
        this.state = {
            visible: false,
            element: React.createRef(),
            boundingRect: null
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (this.state.visible) {
            this.setState({ boundingRect: this.state.element.current.getBoundingClientRect() })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const option: DropDownOption = _buildOptions(this.props.children).find((option: DropDownOption) => option.value === this.props.value);
        return <div>
            <div ref={this.state.element} onClick={() => this.setState({ visible: !this.state.visible })} className={`custom-dropdown ${this.props.className || ""}`}>
                {(option && option.text) || this.props.placeholder}
            </div>
            {this.state.visible && <DropDownOptions className={this.props.className} onSelect={(e: SimulatedEvent) => this.setState({ visible: false }, () => this.props.onChange(e))} onClose={() => {
                setTimeout(() => {
                    if (this.state.visible) {
                        this.setState({ visible: false });
                    }
                }, 100);
            }} boundingRect={this.state.boundingRect || this.state.element.current.getBoundingClientRect()}>{this.props.children}</DropDownOptions>}
        </div>
    }
}
export default CustomDropDown;