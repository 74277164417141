import axios from 'axios';
import {API_URL} from '../../types/datastructures';

export function API_CALL_getChargebeeConfig() { return axios.get(`${API_URL}/chargebee_config`) }
export function API_CALL_generatePortal(chargebeeCustomerId: string) { return axios.post(`${API_URL}/generate_portal_session?customerId=${chargebeeCustomerId}`); }
export function API_CALL_generateCheckout(chargebeeCustomerId: string, planId: string, quantity = 1, addonIds: string[] = []) { 
    const options = { customerId: chargebeeCustomerId, planId, quantity, addonIds };
    return axios.post(`${API_URL}/generate_checkout_new_url`, options); 
}
// export function API_CALL_generateChargebeeCustomer(userId: string) { return axios.post(`${API_URL}/generate_customer?userId=${userId}`) }
export function API_CALL_getChargebeePlans() { return axios.post(`${API_URL}/get_plans`); }

export function API_CALL_getChargebeeInfo(chargebeeCustomerId){ return axios.get(`${API_URL}/retrieve_customer?customerId=${chargebeeCustomerId}`) }
export function API_CALL_getChargebeePayment(chargebeeCustomerId){return axios.get(`${API_URL}/retrieve_customer_payment?customerId=${chargebeeCustomerId}`) }
export function API_CALL_getActiveChargebeeSubscriptions(chargebeeCustomerId){return axios.get(`${API_URL}/retrieve_active_customer_subscriptions?customerId=${chargebeeCustomerId}`) }

const API_URL_customPlanSubscription = `${API_URL}/custom-plan-subscription`;
export const API_CALL_customPlanSubscription = (teamId: string, plan_id: string, email: string) => {
    return axios.post<any>(API_URL_customPlanSubscription, {
        teamId,
        plan_id,
        email
    });
}