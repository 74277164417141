export const DEFAULT_ID = 'CSGlobalDefault';

const toolId = [
    /* "820835173",
    "121571623",
    "883937248",
    "506507232",
    "53676089" */
    "19598680",
    "130738371",
    "19598679",
    "843883041",
    "48810153",
];

const asin = [
    "B01BRB8VVA",
    "B0068GXZ9I",
    "B07ZHHSB5Y",
    "B0049J3X0W",
    "B00IK3IRKY"
]

const asinDe = [
    "B082VP8M8Y",
    "B07H4514QL",
    "B097387BMW",
    "B00FGRSIH4",
    "B0721D5WNL"
]

const asinCa = [
    "B084RQZSJH",
    "B08HVH8FPF",
    "B07PS3YC1R",
    "B08CSDRB6H",
    "B08V1Q54VN"
];

const asinFr = [
	"B081TPYSMB",
	"B096MBBY52",
	"B07XS3GZ7S",
	"B09GSH96KX",
	"B09B4BL77X"
];

const asinIn = [
    "B08PD14FSR", 
    "B012DF4EUQ", 
    "B07Y2RQYFX", 
    "B07X1RLPZP", 
    "B09B1D24MC"
];

const asinIt = [
	"B086MYTDHS",
	"B07CMN4PB2",
	"B018KIKW56",
	"B07JML91PY",
	"B07F2QX9MX"
];

const asinNl = [
	"B098B2XP8C",
	"B098B3WK47",
	"B08D6VJVFG",
	"B08LNX3SDN",
	"B08LP1BWPQ"
];

const asinPl = [
	"B07R2FJRX1",
	"B07T7K2V7L",
	"B01BCF09R0",
	"B08GYBWQP5",
	"B07WBYXPGX"
];

const asinSe = [
	"B075KJX8R6",
	"B01C9YD3I0",
	"B074N27ZXD",
	"B00P98LY7G",
	"B01C9NXXTK"
];

const asinEs = [
	"B07V61GN2L",
	"B089KVXMMB",
	"B08VDH9ZFW",
	"B088N533XT",
	"B01EY8LKCK"
];

const asinUk = [
	"B07FQ4DJ7X",
	"B07SMN7FYZ",
	"B08L5TWL9D",
	"B08FCTSMQN",
	"B086QLM9BV"
];

const walmartCaToolId = [
    '466DUX5T9FMA',
    '6000202317710',
    '6000187055090',
    '6000200733707',
    '6000202352104'
];

const dickssportinggoodsId = [
    '21nikmpgss38rmlt4rnn',
    '20asimnstr13xxxxxmns',
    '21bromlnch8tdy31xmns',
    '21nikmpgss38blckprnn',
    '19nikmrmx270blkpnmns',
];

const graingerId = [
    '5DNL4',
    '22DA21',
    '4GAE3',
    '53CE36',
    '15E779'
];

export const RetailerRecordList = {
    amazon: {
        retailerKey: 'amazon',
        retailerId: 'cf7ec1c1-f84f-4510-83dc-3e0180a9cfc8',
        retailerName: 'Amazon.com',
        productId: 'asin',
        productSamples: {
            "asin": asin,
            "url": asin.map(id => `https://www.amazon.com/dp/${id}`)
        }
    },
    walmart: {
        retailerKey: 'walmart',
        retailerId: '3f483a05-e743-41f3-93da-0f9ab8414b45',
        retailerName: 'Walmart.com',
        productId: 'toolId',
        productSamples: {
            "toolId": toolId,
            "url": toolId.map(id => `https://www.walmart.com/ip/${id}`)
        }
    },
    lowes: {
        retailerKey: 'lowes',
        retailerId: 'f52da738-ac10-44d3-bcd8-73889aedc009',
        retailerName: 'Lowes.com',
        productSamples: {
            "url": [
                "https://lowes.com/pd/Holiday-Living-9-ft-Pre-Lit-Traditional-Slim-Flocked-Artificial-Christmas-Tree-with-700-Constant-White-Warm-White-LED-Lights/1003313766",
                "https://lowes.com/pd/Holiday-Living-Multiple-Colors-Finishes-Assorted-Ornament/1000526155",
                "https://lowes.com/pd/Holiday-Living-SS-HL-SHATTERPROF-ORN-ASRT-120mm/1002559970",
                "https://www.lowes.com/pd/Clorox-Disinfecting-Wipes-Bleach-Free-Cleaning-Wipes-Fresh-35/5001462537",
                "https://www.lowes.com/pd/SCOTT-SCOTT-BTH-20PK-WH-1000/5001645963"
            ]
        }
    },
    target: {
        retailerKey: 'target',
        retailerId: 'e07d835e-2775-4ad7-bdea-38eca4ec56bb',
        retailerName: 'Target.com',
        productId: 'id',
        productSamples: {
            "url": [
                "https://www.target.com/p/toddler-boys-2pk-jogger-pants-cat-jack-gray-black/-/A-79797755",
                "https://www.target.com/p/61-chloe-mid-century-modern-loveseat-adore-d-cor/-/A-78586296",
                "https://www.target.com/p/brimfield-drop-leaf-metal-base-dining-table-brown-threshold-8482/-/A-53455061",
                "https://www.target.com/p/rosen-skincare-earth-mask-2oz/-/A-80841042",
                "https://www.target.com/p/american-tourister-22-breakwater-hardside-carry-on-suitcase/-/A-79741112"
            ]
        }
    },
    fastenal: {
        retailerKey: 'fastenal',
        retailerId: '9f593998-854b-4f69-a495-c4d029a5c94a',
        retailerName: 'Fastenal.com',
        productId: 'id',
        productSamples: {
            "url": [
                "https://www.fastenal.com/products/details/0766928",
                "https://www.fastenal.com/products/details/0706948",
                "https://www.fastenal.com/products/details/0727992",
                "https://www.fastenal.com/products/details/0712992",
                "https://www.fastenal.com/products/details/99194242"
            ]
        }
    },
    kroger: {
        retailerKey: 'kroger',
        retailerId: '25888a65-cf2a-4171-88a3-5262d9b78e5e',
        retailerName: 'Kroger.com',
        productSamples: {
            "url": [
                "https://www.kroger.com/p/tide-original-liquid-laundry-detergent/0003700040212",
                "https://www.kroger.com/p/kroger-buffalo-style-chicken-mix-ns/0001111031919",
                "https://www.kroger.com/p/angel-soft-double-roll-bath-tissue/0003040079211",
                "https://www.kroger.com/p/charmin-ultra-strong-mega-roll-toilet-paper/0003700061071",
                "https://www.kroger.com/p/digiorno-pepperoni-frozen-pizza-on-a-rising-crust/0007192196239"
            ]
        }
    },
    samsclub: {
        retailerKey: 'samsclub',
        retailerId: 'b147efae-10e5-40e7-a151-de18ff55482f',
        retailerName: 'SamsClub.com',
        productId: 'id',
        productSamples: {
            "url": [
                "https://www.samsclub.com/p/polaroid-now-instant-camera/prod25150855",
                "https://www.samsclub.com/p/instax-mini-film-tw/prod19320152",
                "https://www.samsclub.com/p/fuji-mini-11-instant-camera/prod24733299",
                "https://www.samsclub.com/p/canon-eos-77d-18-135mm-lens-kit/prod24021124",
                "https://www.samsclub.com/p/canon-eos-6d-dslr-camera/prod24442344"
            ]
        }
    },
    walmartca: {
        retailerKey: 'walmartca',
        retailerId: '25888a65-cf2a-1234-88a3-5262d9b78e5e',
        retailerName: 'Walmart.ca',
        productId: 'toolId',
        productSamples: {
            "toolId": walmartCaToolId,
            "url": walmartCaToolId.map(id => `https://www.walmart.ca/ip/${id}`)
        }
    },
    buybuybaby: {
        retailerKey: 'buybuybaby',
        retailerId: '7403a86d-3bb9-4f59-abd7-d3eca9aaae2c',
        retailerName: 'BuyBuyBaby.com',
        productSamples: {
            "url": [
                "https://www.buybuybaby.com/store/product/gb-pockit-all-city-compact-stroller/5393301",
                "https://www.buybuybaby.com/store/product/chicco-keyfit-35-infant-car-seat/5511601",
                "https://www.buybuybaby.com/store/product/mesa-infant-car-seat-by-uppababy-reg/5172670",
                "https://www.buybuybaby.com/store/product/graco-snugride-35-lite-lx-infant-car-seat/5425474",
                "https://www.buybuybaby.com/store/product/maxi-cosi-coral-xp-infant-car-seat/5515868"
            ]
        }
    },
    homedepot: {
        retailerKey: 'homedepot',
        retailerId: '5fe71ce7-9c2c-4b97-a040-60cd66dc7940',
        retailerName: 'HomeDepot.com',
        productId: 'id',
        productSamples: {
            "url": [
                'https://www.homedepot.com/p/Home-Decorators-Collection-Sassy-60-in-Vanity-in-White-with-Marble-Vanity-Top-in-Carrara-White-BF90225/204294107',
                'https://www.homedepot.com/p/Home-Decorators-Collection-Hamilton-Shutter-49-1-2-in-W-x-22-in-D-Bath-Vanity-in-Ivory-with-Granite-Vanity-Top-in-Grey-10806-VS48H-DW/203704582',
                'https://www.homedepot.com/p/Defender-Ultra-HD-4K-8MP-4-Channel-1TB-DVR-Security-Camera-System-with-Remote-Viewing-and-4-Cameras-4K1T4B4V2/313806404',
                'https://www.homedepot.com/p/LG-Electronics-14-000-BTU-115-Volt-Dual-Inverter-Smart-Window-Air-Conditioner-in-White-with-Wi-Fi-Enabled-and-Remote-LW1517IVSM/303947186',
                'https://www.homedepot.com/p/Vigoro-EcoBorder-4-ft-Brown-Rubber-Landscape-Edging-6-Pack-ECOBRD-BRN-6PK/203518152'
            ]
        }
    },
    grainger: {
        retailerKey: 'grainger',
        retailerId: 'e5e8057d-fcd4-4435-9752-6f5ce50707a1',
        retailerName: 'Grainger.com',
        productId: 'id',
        productSamples: {
            "productId": graingerId,
            "url": graingerId.map(id => `https://www.grainger.com/product/${id}`)
        }
    },
    officedepot: {
        retailerKey: 'officedepot',
        retailerId: 'fcb8890b-d9f4-4138-aef0-3671ec242273',
        retailerName: 'OfficeDepot.com',
        productId: 'id',
        productSamples: {
            "url": [
                'https://www.officedepot.com/a/products/9280196/Epson-FastFoto-FF-680W-High-Speed/',
                'https://www.officedepot.com/a/products/9886355/Epson-Workforce-Pro-WF-7820-Wireless/',
                'https://www.officedepot.com/a/products/7200026/Realspace-Lenzer-Mesh-High-Back-Task/',
                'https://www.officedepot.com/a/products/6071281/Texas-Instruments-TI-84-Plus-CE/',
                'https://www.officedepot.com/a/products/8453541/Omron-Evolv-Wireless-Upper-Arm-Blood/'
            ]
        }
    },
    dickssportinggoods: {
        retailerKey: 'dickssportinggoods',
        retailerId: '9d28cd9f-a786-4b56-aa7c-882e7047ac77',
        retailerName: 'DicksSportingGoods.com',
        productId: 'id',
        productSamples: {
            "productId": dickssportinggoodsId,
            "url": [
                'https://www.dickssportinggoods.com/p/nike-mens-air-zoom-pegasus-38-a-i-rrunning-shoes-21nikmpgss38rmlt4rnn/21nikmpgss38rmlt4rnn',
                'https://www.dickssportinggoods.com/p/asics-mens-gel-noosa-tri-13-running-shoes-20asimnstr13xxxxxmns/20asimnstr13xxxxxmns',
                'https://www.dickssportinggoods.com/p/brooks-mens-launch-8-tie-dye-running-shoes-21bromlnch8tdy31xmns/21bromlnch8tdy31xmns',
                'https://www.dickssportinggoods.com/p/nike-mens-air-zoom-pegasus-38-running-shoes-21nikmpgss38blckprnn/21nikmpgss38blckprnn?color=Black%2FGrey',
                'https://www.dickssportinggoods.com/p/nike-mens-air-max-270-shoes-19nikmrmx270blkpnmns/19nikmrmx270blkpnmns',
            ],
        }
    },
    staples: {
        retailerKey: 'staples',
        retailerId: 'cfa20355-4d4a-4b05-87ef-8d14bd8cfc2a',
        retailerName: 'Staples.com',
        productId: 'id',
        productSamples: {
            "url": [
                'https://www.staples.com/staples-gaming-chair-black-and-grey/product_2829477',
                'https://www.staples.com/tru-red-multipurpose-paper-8-1-2-x-11-case/product_513096',
                'https://www.staples.com/apple-12-9-ipad-pro-4th-generation-tablet-wifi-256-gb-space-gray-mxat2ll-a/product_24441802',
                'https://www.staples.com/NETGEAR-Nighthawk-AC1900-Smart-Wi-Fi-Gigabit-Router-R7000-100NAS/product_280401',
                'https://www.staples.com/Staples-Rectangle-Melamine-Wood-Folding-Table-Walnut-Finish-29-5-H-x-24-W-x-48-L-27095/product_1321934'
            ]
        }
    },
    amazonFresh: {
        retailerKey: 'amazonFresh',
        retailerId: 'f9b50b57-031d-4465-a2ff-7171ef142e7e',
        retailerName: 'AmazonFresh.com',
        productId: 'id',
        productSamples: {
            "url": [
                'https://www.amazon.com/Grape-Red-Clam-Conventional-Ounce/dp/B07FDMFDZY?pd_rd_w=zE9CA&pf_rd_p=4e3a532f-d853-455f-b665-d9d3cd57b45a&pf_rd_r=MP9WV9SXG2BX45EES4SW&pd_rd_r=a6aed71d-1840-4302-b7f9-b4d27cc0c587&pd_rd_wg=erxCD&pd_rd_i=B07FDMFDZY&fpw=alm&almBrandId=QW1hem9uIEZyZXNo&ref_=pd_alm_fs_dsk_dp_dzrp_1_2_i',
                'https://www.amazon.com/gp/product/B00J3VHERY/ref=pd_alm_fs_mzg_1_3_fs_dsk_sf_ai_img_371469011?fpw=alm&almBrandId=QW1hem9uIEZyZXNo&pd_rd_r=c22da9bb-45d6-4ea4-bb16-7e0e84c6f171&pd_rd_w=gO3Xk&pd_rd_wg=rL0Tk&pd_rd_i=B00J3VHERY&pf_rd_r=AS1Y05409ANTTGNC8171&pf_rd_p=0fa08f26-3b7e-44eb-9dd5-f275b1ee7194',
                'https://www.amazon.com/gp/product/B00G4U6UY0/ref=pd_alm_fs_mzg_1_4_fs_dsk_sf_ai_img_16322721?fpw=alm&almBrandId=QW1hem9uIEZyZXNo&pd_rd_r=5b3c30ac-ae50-4a69-9833-1088bb8c907b&pd_rd_w=KQnWN&pd_rd_wg=y7pfj&pd_rd_i=B00G4U6UY0&pf_rd_r=AS1Y05409ANTTGNC8171&pf_rd_p=b36bfef3-386a-4e3d-8c1d-b7af4367a170',
                'https://www.amazon.com/gp/product/B07BGLT25K/ref=pd_alm_fs_mzg_1_2_fs_dsk_sf_ai_img_15342811?fpw=alm&almBrandId=QW1hem9uIEZyZXNo&pd_rd_r=a2de434c-d3db-4c07-b32a-52ed07e9723f&pd_rd_w=1sskZ&pd_rd_wg=1yoph&pd_rd_i=B07BGLT25K&pf_rd_r=AS1Y05409ANTTGNC8171&pf_rd_p=f4e2ac1d-d229-4260-b845-88e051613df4',
                'https://www.amazon.com/gp/product/B07RC4NGJ1/ref=pd_alm_fs_merch_1_7_fs_dsk_sf_mw_img_af_nereid?fpw=alm&almBrandId=QW1hem9uIEZyZXNo&pd_rd_r=1433ef96-b354-445c-9c72-76fc9528f885&pd_rd_w=poD3J&pd_rd_wg=ebGcD&pd_rd_i=B07RC4NGJ1&pf_rd_r=7BEE5XKG9299Y4ZXY7S9&pf_rd_p=0725f8d4-e34d-46af-baca-86deed903b7a'
            ]
        }
    },
    expressLane: {
        retailerKey: 'expressLane',
        retailerId: 'ae9c0743-ced9-4f5c-9ed0-b9ae64d42650',
        retailerName: 'ExpressLane.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.harristeeter.com/shop/store/453/product/00028400199148/details/selected',
                'https://www.harristeeter.com/shop/store/453/product/00049000075946/details/selected',
                'https://www.harristeeter.com/shop/store/453/product/00076301845046/details/selected',
                'https://www.harristeeter.com/shop/store/453/product/00648649070079/details/selected',
                'https://www.harristeeter.com/shop/store/453/product/00604913000166/details/selected'
            ]
        }
    },
    meijer: {
        retailerKey: 'meijer',
        retailerId: 'cdd469d0-3b5d-40b3-85bd-d9b019cafaff',
        retailerName: 'Meijer.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.meijer.com/shopping/product/ben-jerry-s-half-baked-ice-cream-16-oz/7684010132.html',
                'https://www.meijer.com/shopping/product/french-s-classic-yellow-squeeze-bottle-mustard-20-oz/4150000031.html',
                'https://www.meijer.com/shopping/product/mountain-dew-12-oz-24-pk-cans/1200000088.html',
                'https://www.meijer.com/shopping/product/tropicana-pure-premium-orange-juice-no-pulp-original-89-oz/4850001833.html',
                'https://www.meijer.com/shopping/product/pace%C2%A0chunky-salsa-mild-16-oz-jar/4156514016.html'
            ]
        }
    },
    msc: {
        retailerKey: 'msc',
        retailerId: '878eb05d-90ce-4db1-84a2-c6ce7b752141',
        retailerName: 'MSC.com',
        productSamples: {
            url: [
                'https://www.mscdirect.com/product/details/80480619?fromRR=Y',
                'https://www.mscdirect.com/product/details/37659943',
                'https://www.mscdirect.com/product/details/39480488',
                'https://www.mscdirect.com/product/details/07441132',
                'https://www.mscdirect.com/product/details/61179784'
            ]
        }
    },
    partsTown: {
        retailerKey: 'partsTown',
        retailerId: '65366f83-c1ed-4551-8ee4-55e4b543ddf1',
        retailerName: 'PartsTown.com',
        productSamples: {
            url: [
                'https://www.partstown.com/cres-cor/cre0808116k',
                'https://www.partstown.com/atlas-metal/at111-1069',
                'https://www.partstown.com/duke/duk175780',
                'https://www.partstown.com/electrolux-professional/elx0h1034',
                'https://www.partstown.com/electrolux-professional/elx653053'
            ]
        }
    },
    macys: {
        retailerKey: 'macys',
        retailerId: '4a0e2d12-1ca3-46d6-9068-39d51ca1ee4f',
        retailerName: "Macy's.com",
        productId: 'id',
        productSamples: {
            url: [
                'https://www.macys.com/shop/product/marc-jacobs-perfect-eau-de-parfum-fragrance-collection?ID=11268473',
                'https://www.macys.com/shop/product/too-faced-better-than-sex-mascara?ID=10989412',
                'https://www.macys.com/shop/product/my-clarins-clear-out-blackhead-expert-stick-mask-1.8-oz.?ID=10577241',
                'https://www.macys.com/shop/product/dior-miss-dior-eau-de-parfum-fragrance-collection?ID=4795654',
                'https://www.macys.com/shop/product/tonymoly-5-pc.-sheet-mask-set?ID=4740694'
            ]
        }
    },
    heb: {
        retailerKey: 'heb',
        retailerId: '795a0b97-a3dc-4d6a-9f74-2810e65c8eaa',
        retailerName: 'HEB.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.heb.com/product-detail/fresh-watermelon-in-a-bowl-large/1196328',
                'https://www.heb.com/product-detail/h-e-b-meal-simple-queso-topped-pork-tamales-with-mexican-rice-and-refried-beans/3203515',
                'https://www.heb.com/product-detail/h-e-b-select-ingredients-roasted-red-pepper-hummus/1837111',
                'https://www.heb.com/product-detail/h-e-b-jumbo-parmesan-panko-breaded-butterfly-shrimp/4774069',
                'https://www.heb.com/product-detail/h-e-b-unsalted-tortilla-chips/2529019'
            ]
        }
    },
    nordstrom: {
        retailerKey: 'nordstrom',
        retailerId: 'b5dd58c3-86f5-476e-a436-f8b29a881446',
        retailerName: 'Nordstrom.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.nordstrom.com/s/caslon-vivi-slide-sandal-women/5788457?origin=coordinating-5788457-0-2-HP_SALE-recbot-best_sellers_new_visitor_sale&recs_placement=HP_SALE&recs_strategy=best_sellers_new_visitor_sale&recs_source=recbot&recs_page_type=home&recs_seed=0&color=BLACK',
                'https://www.nordstrom.com/s/caslon-rounded-v-neck-tee-plus-size/4437390?origin=coordinating-4437390-0-3-NEW_AFF_BRAND_1-recbot-latest_arrivals_age_gender_brand',
                'https://www.nordstrom.com/s/ecco-st-1-hybrid-lite-cap-toe-derby-men/6119187?origin=category-personalizedsort&breadcrumb=Home%2FMen%2FShoes%2FOxfords%20%26%20Derbys&color=200',
                'https://www.nordstrom.com/s/burberry-eddie-pique-polo/5309179?origin=category-personalizedsort&breadcrumb=Home%2FMen%2FClothing%2FPolo%20Shirts&color=100',
                'https://www.nordstrom.com/s/the-north-face-etip-gloves/5541628?origin=category-personalizedsort&breadcrumb=Home%2FMen%2FAccessories%2FGloves&color=030'
            ]
        }
    },
    samsclubPhoto: {
        retailerKey: 'samsclubPhoto',
        retailerId: '1a1c59b6-7976-4f0e-af21-935db120814d',
        retailerName: 'SamsClubPhoto.com',
        productSamples: {
            url: [
                'https://photo.samsclub.com/Products/SimpleGift/CanvasGalleryWrap',
                'https://photo.samsclub.com/Products/SimpleGift/PrintsMounted',
                'https://photo.samsclub.com/Products/SimpleGift/AluminumWallPanel',
                'https://photo.samsclub.com/Products/SimpleGift/Standout',
                'https://photo.samsclub.com/photocards/Product/DM/HY002316GCSAMSPHO/PHO57H?category=Babies_BabyShowerInvitationsGirls'
            ]
        }
    },
    costcoPhotocenter: {
        retailerKey: 'costcoPhotocenter',
        retailerId: '4515afae-d654-4e26-8427-d6bdba29f78e',
        retailerName: 'Costcophotocenter.com',
        productSamples: {
            url: [
                'https://www.costcophotocenter.com/wall-decor/acrylic-prints',
                'https://www.costcophotocenter.com/photo-gifts/photo-mugs',
                'https://www.costcophotocenter.com/cards/greeting-cards/Product/G/GPHO9900371/PHO57H?category=PhotoCards_Wedding_BridalShowerInvitations',
                'https://www.costcophotocenter.com/wall-decor/metal-prints',
                'https://www.costcophotocenter.com/photo-gifts/photo-plaques'
            ]
        }
    },
    walgreens: {
        retailerKey: 'walgreens',
        retailerId: 'ccdc4407-8286-43ec-b96f-89c81724eb0e',
        retailerName: 'Walgreens.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.walgreens.com/store/c/acuvue-oasys-12pk-acuvue-oasys-12-pack/ID=prod6258783-product',
                'https://www.walgreens.com/store/c/1-day-acuvue-moist-90-pk-1-day-acuvue-moist-90-pack/ID=prod6017176-product',
                'https://www.walgreens.com/store/c/acuvue-oasys-for-astigmatism-acuvue-oasys-for-astigmatism/ID=prod4093553-product',
                'https://www.walgreens.com/store/c/acuvue-oasys-1-day-for-astigmatism-acuvue-oasys-1-day-for-astigmatism-30-pack/ID=prod6358602-product',
                'https://www.walgreens.com/store/c/acuvue-oasys-hydraluxe-90pk-acuvue-oasys-1-day-90-pack/ID=prod6312311-product'
            ]
        }
    },
    bassPro: {
        retailerKey: 'bassPro',
        retailerId: '3410e503-38da-400b-92be-df8658caf4c3',
        retailerName: 'BassPro.com',
        productSamples: {
            url: [
                'https://www.basspro.com/shop/en/caviness-laminated-boat-oar',
                'https://www.basspro.com/shop/en/bass-pro-shops-pond-prowler-10-fishing-boat',
                'https://www.basspro.com/shop/en/tracker-marine-lithium-super-high-output-lithium-deep-cycle-marine-battery',
                'https://www.basspro.com/shop/en/pelican-catch-pwr-100-single-person-fishing-boat',
                'https://www.basspro.com/shop/en/kuuma-stow-n-go-125-compact-gas-grill'
            ]
        }
    },
    costco: {
        retailerKey: 'costco',
        retailerId: '57f1ce47-0179-4712-87a4-3e23a7b0cdcf',
        retailerName: 'Costco.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.costco.com/gtx-solar-pathway-light-5-pack.product.100714284.html',
                'https://www.costco.com/.product.100526720.html',
                'https://www.costco.com/tresanti-47%22-adjustable-height-desk.product.100664030.html',
                'https://www.costco.com/samsung-60%22---tu700d-series---4k-uhd-led-lcd-tv.product.100783320.html',
                'https://www.costco.com/.product.100368216.html?&ADBUTLERID=grocery_itemdriver_skippycreamy'
            ]
        }
    },
    cvs: {
        retailerKey: 'cvs',
        retailerId: 'bf50362d-01d4-4c5e-936e-e61cd312ac1f',
        retailerName: 'CVS.com',
        //productId: 'id',
        productSamples: {
            url: [
                'https://www.cvs.com/shop/cvs-health-vitamin-c-tablets-500mg-prodid-1010583',
                'https://www.cvs.com/shop/l-oreal-paris-telescopic-original-washable-mascara-prodid-1016370',
                'https://www.cvs.com/shop/burt-s-bees-lip-balm-4-pack-prodid-1680013',
                'https://www.cvs.com/shop/perfect-scents-fragrances-an-impression-of-bombshell-by-victoria-s-secret-prodid-973132',
                'https://www.cvs.com/shop/cvs-health-women-s-maximum-absorbency-underwear-prodid-1170248',
            ]
        }
    },
    capitalelectricsupply: {
        retailerKey: "capitalelectricsupply",
        retailerId: '614a663b-50dd-43ef-92a8-86dc73e7abbe',
        retailerName: 'CapitalElectricSupply.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.capitalelectricsupply.com/product/detail/310744/',
                'https://www.capitalelectricsupply.com/product/detail/1565815/erico-inc-pluscu2l6',
                'https://www.capitalelectricsupply.com/product/detail/7893/arlington-industries-as0',
                'https://www.capitalelectricsupply.com/product/detail/20059/arlington-industries-840',
                'https://www.capitalelectricsupply.com/product/detail/29085/eaton-crouse-hinds-tp403'
            ]
        }
    },
    rexel: {
        retailerKey: "rexel",
        retailerId: '2c404868-6fa4-4a4c-819f-eb0c415e2b5f',
        retailerName: 'RexelUsa.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.rexelusa.com/p/376981/',
                'https://www.rexelusa.com/p/166421/crc/rtv-silicone-adhesive-red/078254140599/14059',
                'https://www.rexelusa.com/p/1225321/ewon/ethernet-router-gateway-2-digital-input-1-digital-output-4-rj45/flexy20500',
                'https://www.rexelusa.com/p/820113/maxim-lighting/outdoor-wall-lantern-1-light-60w-incandescent-black/783209103016/1030bk',
                'https://www.rexelusa.com/p/644528/marathon-motors/motor-208-230-460vac-2hp-1800rpm-56-frame-fla-60-56-28/5k49nn4472x'
            ]
        }
    },
    yaleelectricsupply: {
        retailerKey: "yaleelectricsupply",
        retailerId: '57574802-dd38-4273-94ea-e402826c230f',
        retailerName: 'YaleElectricSupply.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.yaleelectricsupply.com/893190',
                'https://www.yaleelectricsupply.com/philips-lighting-467258-ed23--1-2--high-pressure-sodium-hid-lamp-70-watt-e39-single-contact-mogul-screw-6500-lumens-13-cri-2100k-ceramalux-alto-reg-725480',
                'https://www.yaleelectricsupply.com/emerson-rs-2-roof-de-icing-control-120-volt-10-amp-white-roof-sentry-reg-easyheat-reg-55495',
                'https://www.yaleelectricsupply.com/siemens-6sl32105be255uv0-through-hole-wall-side-by-side-mount-inverter-5-50-kilo-watt-7-5-hp-459935',
                'https://www.yaleelectricsupply.com/snapnrack-242-02151-6000-series-aluminum-stainless-steel-hardware-mlpe-frame-attachment-kit-2-24-inch-x-1-1-2-inch-694391'
            ]
        }
    },
    turtle: {
        retailerKey: "turtle",
        retailerId: 'ebcfc7a9-ba2b-4c75-8d66-fd021c08b5ac',
        retailerName: 'Turtle.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.turtle.com/1342041/Product/',
                'https://www.turtle.com/1023628/Product/philips-lighting-391227',
                'https://www.turtle.com/2162553/Product/3m-051135-08796',
                'https://www.turtle.com/1040759/product/n/cutler-hammer-d64d1',
                'https://www.turtle.com/1012249/Product/b-line-sb87019s1fb'
            ]
        }
    },
    mayer: {
        retailerKey: "mayer",
        retailerId: 'ce3f6b04-522f-4418-9815-0f3be9776e1d',
        retailerName: 'MayerElectric.com',
        productSamples: {
            url: [
                'https://www.mayerelectric.com/Product/PROGRESS-LIGHTING-Vintage-LED-Medium-Base-Light-Bulb-767627928666',
                'https://www.mayerelectric.com/Catalog/datacom/electronics/media-converters/SIGNAMAX-10-100BaseT-TX-to-100BaseFX-Media-Converter-ST-MM-2-km-Span-SIG0651100',
                'https://www.mayerelectric.com/Catalog/datacom/rackscabinets-and-enclosures/wall-mount-cabinet/NVENT-HOFFMAN-AccessPlus-Double-Hinged-Type-1-EWMW242425-783510178581',
                'https://www.mayerelectric.com/Catalog/datacom/labeling/hand-held-printers/BRADY-WIREMARKERS-BMP21-PLUS-Portable-Label-Printer-9-5-H-x-4-5-W-x-2-5-D-754473928483',
                'https://www.mayerelectric.com/Catalog/datacom/cable-support-systems/j-hooks/B-LINE-Eaton-B-Line-series-datacomm-and-low-voltage-support-fasteners-781011047139'
            ]
        }
    },
    platt: {
        retailerKey: "platt",
        retailerId: 'bd766670-62df-4323-9ee3-b78c5912a6f4',
        retailerName: 'Platt.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.platt.com/product.aspx?zpid=212222',
                'https://www.platt.com/platt-electric-supply/Fluorescent-Tube-T12-High-Output/Philips-Lighting/381764/product.aspx?zpid=223986',
                'https://www.platt.com/platt-electric-supply/Entrance-Caps-Service-Non-Metallic/Multiple/078307/product.aspx?zpid=69201',
                'https://www.platt.com/platt-electric-supply/Air-Conditioner-Disconnects-A-C-Pullout-Type-Metallic/Eaton/DPU222R/product.aspx?zpid=208915',
                'https://www.platt.com/platt-electric-supply/Heaters-Assembly-Items-Heater-Interiors/Cadet/CS152/product.aspx?zpid=439135'
            ]
        }
    },
    wesco: {
        retailerKey: "wesco",
        retailerId: 'd2e178f6-3dfb-4b49-9a51-83c701d94bf7',
        retailerName: 'Wesco.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://buy.wesco.com/p/03129340934-1',
                'https://buy.wesco.com/Lighting/Light-Bulbs/Fluorescent-Light-Bulbs/GE-CURRENT-A-DAINTREE-COMPANY/Fluorescent-Bulb-75WW-T-8/F96T12XLHL41WMCVG/p/04316826038-1',
                'https://buy.wesco.com/Audio-and-Visual/Audio-Equipment/Other-Audio-Equipment/FEDERAL-SIGNAL/Weatherproof-Back-Box-Square-Aluminum-Horns/WB/p/78297922470-1',
                'https://buy.wesco.com/Janitorial-and-Facility-Maintenance/Floor-and-Carpet-Cleaners/Floor-Finishes/BETCO-CORP/Floor-Finish-Crystal-Clear-White-5-gal-Pail/65905-00/p/70042798880-1',
                'https://buy.wesco.com/Electrical-and-Power-Distribution/Electrical-Outlets-and-Switches/Straight-Blade-Devices/Ground-Fault-Receptacle-Outlets/HUBBELL/Ground-Fault-Duplex-White-20A-125VAC-2P/GFRST20W/p/88377812215-1'
            ]
        }
    },
    zoro: {
        retailerKey: "zoro",
        retailerId: '29b43a4d-7909-4b3e-b171-c94ff22b2bd0',
        retailerName: 'Zoro.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.zoro.com/i/G3970679/',
                'https://www.zoro.com/pelican-high-visibility-yellow-led-161-2010-g/i/G8475251/',
                'https://www.zoro.com/keckley-2-12-fnpt-stainless-steel-ball-valve-inline-bvm3thrssrgsl-250/i/G6136509/',
                'https://www.zoro.com/garmin-gps-truck-navigator-tablet-10-dezlotr1000/i/G9264430/',
                'https://www.zoro.com/malco-edge-roller-12-34-stainless-steel-er3/i/G0548597/'
            ]
        }
    },
    amazonde: {
        retailerKey: 'amazonde',
        retailerId: '099f8b19-8061-4cb1-9f53-c7a33401bb1e',
        retailerName: 'Amazon.de',
        productId: 'asin',
        productSamples: {
            "url": asinDe.map(id => `https://www.amazon.de/dp/${id}`)
        }
    },
    hdsupply: {
        retailerKey: 'hdsupply',
        retailerId: '0a3371f8-5551-49c4-8931-fe9311094745',
        retailerName: 'HDSupplySolutions.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://hdsupplysolutions.com/p/kwikset-delta-door-lever-passage-hall-closet-grade-3-metal-satin-chrome-p901805',
                'https://hdsupplysolutions.com/p/14x18x1-fiberglass-air-filter-merv-4-box-of-12-p126012',
                'https://hdsupplysolutions.com/p/r-410a-refrigerant-25-lb-tank-p150110',
                'https://hdsupplysolutions.com/p/supco-370-volt-35-5-mfd-round-dual-run-capacitor-p252426',
                'https://hdsupplysolutions.com/p/diversitech-swoosh-co2-cartridge-package-of-12-p235014'
            ]
        }
    },
    newegg: {
        retailerKey: 'newegg',
        retailerId: '26125bd3-03a6-43ce-8cd0-6fca941832e1',
        retailerName: 'NewEgg.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.newegg.com/gigabyte-geforce-rtx-3060-ti-gv-n306tgamingoc-pro-8gd/p/N82E16814932376?Item=9SIAXMKFNE0117',
                'https://www.newegg.com/amd-ryzen-5-5600x/p/N82E16819113666?Item=N82E16819113666',
                'https://www.newegg.com/g-skill-32gb-288-pin-ddr4-sdram/p/N82E16820232091?Item=N82E16820232091',
                'https://www.newegg.com/gigabyte-x570-aorus-elite/p/N82E16813145160?Item=N82E16813145160',
                'https://www.newegg.com/asus-rog-strix-x370-f-gaming/p/N82E16813132989?Item=9SIA4REF488180'
            ]
        }
    },
    amazonca: {
        retailerKey: 'amazonca',
        retailerId: '597838c7-230c-469f-8b2d-57b91ecd2686',
        retailerName: 'Amazon.ca',
        productId: 'asin',
        productSamples: {
            "url": asinCa.map(id => `https://www.amazon.ca/dp/${id}`)
        }
    },
    amazonfr: {
        retailerKey: 'amazonfr',
        retailerId: 'd8c4f900-36a4-4eab-8aed-1da59e355c32',
        retailerName: 'Amazon.fr',
        productId: 'asin',
        productSamples: {
            "url": asinFr.map(id => `https://www.amazon.fr/dp/${id}`)
        }
    },
    amazonin: {
        retailerKey: 'amazonin',
        retailerId: '2b7396fb-0f12-459c-9e33-52d1b44bbea4',
        retailerName: 'Amazon.in',
        productId: 'asin',
        productSamples: {
            "url": asinIn.map(id => `https://www.amazon.in/dp/${id}`)
        }
    },
    amazonit: {
        retailerKey: 'amazonit',
        retailerId: '412d267c-46ce-44f4-b7d1-050815f0106a',
        retailerName: 'Amazon.it',
        productId: 'asin',
        productSamples: {
            "url": asinIt.map(id => `https://www.amazon.it/dp/${id}`)
        }
    },
    amazonnl: {
        retailerKey: 'amazonnl',
        retailerId: '86bcac32-fa9e-4552-a8c1-f3bfa6b1ea76',
        retailerName: 'Amazon.nl',
        productId: 'asin',
        productSamples: {
            "url": asinNl.map(id => `https://www.amazon.nl/dp/${id}`)
        }
    },
    amazonpl: {
        retailerKey: 'amazonpl',
        retailerId: 'bbf1dbaa-2acb-436a-baf2-3d99bd4c2e87',
        retailerName: 'Amazon.pl',
        productId: 'asin',
        productSamples: {
            "url": asinPl.map(id => `https://www.amazon.pl/dp/${id}`)
        }
    },
    amazonse: {
        retailerKey: 'amazonse',
        retailerId: '883cc778-33d6-4b0f-81cf-6027e8685c0a',
        retailerName: 'Amazon.se',
        productId: 'asin',
        productSamples: {
            "url": asinSe.map(id => `https://www.amazon.se/dp/${id}`)
        }
    },
    amazones: {
        retailerKey: 'amazones',
        retailerId: '26a1f77c-d3d7-487d-908d-2ffa39249cd3',
        retailerName: 'Amazon.es',
        productId: 'asin',
        productSamples: {
            "url": asinEs.map(id => `https://www.amazon.es/dp/${id}`)
        }
    },
    amazonuk: {
        retailerKey: 'amazonuk',
        retailerId: 'a16dcbd4-6927-4384-a7ea-0a2b73cf5289',
        retailerName: 'Amazon.co.uk',
        productId: 'asin',
        productSamples: {
            "url": asinUk.map(id => `https://www.amazon.co.uk/dp/${id}`)
        }
    },
    autozone: {
        retailerKey: 'autozone',
        retailerId: 'af6be16a-75fb-4db7-bfa0-063db0e7c221',
        retailerName: 'AutoZone.com',
        productId: 'id',
        isBeta: true,
        productSamples: {
            url: [
                'https://www.autozone.com/p/455107',
                'https://www.autozone.com/p/256752',
                'https://www.autozone.com/p/897021',
                'https://www.autozone.com/p/1073337',
                'https://www.autozone.com/p/81508'
            ]
        }
    },
    bedbath: {
        retailerKey: 'bedbath',
        retailerId: 'b075c784-0c31-4141-a011-50bafe383387',
        retailerName: 'BedBathAndBeyond.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.bedbathandbeyond.com/store/product/simply-essential-notched-hangers-set-of-18/5560324?categoryId=12210',
                'https://www.bedbathandbeyond.com/store/product/simply-essential-11-inch-brick-print-collapsible-bin-in-blue/5576334?categoryId=12208',
                'https://www.bedbathandbeyond.com/store/product/google-chromecast-with-google-tv-in-white/5549451?categoryId=14901',
                'https://www.bedbathandbeyond.com/store/product/nico-amp-yeye-rolling-toy-box-chest/5443942?categoryId=15642',
                'https://www.bedbathandbeyond.com/store/product/fireglow-rechargeable-led-lantern-in-white/5547640?categoryId=13274'
            ]
        }
    },
    homedepotca: {
        retailerKey: 'homedepotca',
        retailerId: '0168b124-9a29-4574-9f48-fdc7372ad534',
        retailerName: 'HomeDepot.ca',
        productId: 'id',
        productSamples: {
            "url": [
                'https://www.homedepot.ca/product/hampton-bay-edson-18-inch-w-x-84-inch-h-x-24-5-inch-d-shaker-style-assembled-kitchen-pantry-cabinet-cupboard-in-solid-white-with-adjustable-shelves-t188424l-/1001421148',
                'https://www.homedepot.ca/product/frigidaire-gallery-30-inch-5-4-cu-ft-front-control-slide-in-electric-range-with-air-fry-in-stainless-steel/1001318565',
                'https://www.homedepot.ca/product/ge-24-inch-portable-dishwasher-with-sanitize-cycle-in-stainless-steel/1001637634',
                'https://www.homedepot.ca/product/ih-casa-decor-gold-vectors-cushion-with-side-zipper-in-teal/1001567115',
                'https://www.homedepot.ca/product/wallpops-whiteboard-weekly-calendar-decal-set-of-2/1001517317?rrec=true',
            ]
        }
    },
    automercado: {
        disabled: true,
        retailerKey: 'automercado',
        retailerId: 'b6509478-b22b-41cd-bbb2-35cfd692bae9',
        retailerName: 'AutoMercado.cr',
        // productId: 'id',
        productSamples: {
            "url": [
                'https://automercado.cr/p/harina-trigo-seleccion-auto-paquete-1000-g/id/894caf2b-d00a-4427-bdf9-aafa58318861',
                'https://automercado.cr/p/rellenos-pure-calabaza-libby%25C2%25B4s-envase-425-g/id/be9939c6-2c1a-4266-af25-79787a5cba3c',
                'https://automercado.cr/p/alimento-perro-seco-adulto-raza-grande-dog-chow-bolsa-7500-g/id/fdca3211-a38b-40e0-9a35-31553a7edb10',
                'https://automercado.cr/p/galleta-banano-mum-mun-caja-50-g/id/fd40ec07-d2e2-41aa-bc5d-5a56b9b5ae76',
                'https://automercado.cr/p/guisante-seleccion-auto-lata-590-g-/id/a41bebf8-185f-4506-a0cc-3df034efacff'
            ]
        }
    },
    wilko: {
        retailerKey: 'wilko',
        retailerId: 'b4588700-43d0-42f5-a278-35f364455549',
        retailerName: 'Wilko.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.wilko.com/en-uk/wilko-luxe-luxury-christmas-wrapping-paper-3-pack/p/0516687',
                'https://www.wilko.com/en-uk/wilko-24-days-of-hot-sauce-advent-calendar/p/0497854',
                'https://www.wilko.com/en-uk/wilko-luxe-medium-gift-box/p/0511185',
                'https://www.wilko.com/en-uk/wilko-digital-timer/p/0188600',
                'https://www.wilko.com/en-uk/russel-hobbs-black-inspire-kettle-17l/p/0493020'
            ]
        }
    },
    bestbuy: {
        retailerKey: 'bestbuy',
        retailerId: '69fb9604-5cd1-4d6a-99d5-5c31858b0017',
        retailerName: 'BestBuy.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.bestbuy.com/site/oculus-quest-2-advanced-all-in-one-virtual-reality-headset-128gb/6473553.p?skuId=6473553',
                'https://www.bestbuy.com/site/sony-playstation-5-console/6426149.p?skuId=6426149',
                'https://www.bestbuy.com/site/atari-vcs-800-all-in-bundle-black-walnut/6459020.p?skuId=6459020',
                'https://www.bestbuy.com/site/samsung-55-class-7-series-led-4k-uhd-smart-tizen-tv/6401735.p?skuId=6401735',
                'https://www.bestbuy.com/site/samsung-28-cu-ft-large-capacity-3-door-french-door-refrigerator-with-autofill-water-pitcher-stainless-steel/6417768.p?skuId=6417768'
            ]
        }
    },
    oreillyauto: {
        retailerKey: 'oreillyauto',
        retailerId: '59a0055f-c5ec-4023-9497-97b23c315159',
        retailerName: 'OReillyAuto.com',
        //productId: 'id',
        productSamples: {
            url: [
                'https://www.oreillyauto.com/detail/c/1-advanced-fuel-economy/oil--chemicals---fluids/motor-oil/motor-oil---full-synthetic/0065378f5577/mobil-1-advanced-fuel-economy-synthetic-motor-oil-0w-20-5-quart/mob7/10205qt?pos=6',
                'https://www.oreillyauto.com/detail/c/platinum/battery---accessories/batteries/31624da3221a/super-start-platinum-battery-group-size-24f-24r/ssbq/24rplt',
                'https://www.oreillyauto.com/detail/c/brakebest-select-brake-shoes/brakes/brake-pads---shoes/a349129f45da/brakebest-select-brake-shoes-7-09-inch-bonded-new-parking-brake-shoe/fdp1/829?pos=6',
                'https://www.oreillyauto.com/detail/c/laclede/accessories/winter/tire-chains---cable-chains/be8dc8c6dc7a/laclede-cable-tire-chains/ltc0/1042?pos=0',
                'https://www.oreillyauto.com/detail/c/prestone/accessories/winter/de-icers/535b0dd3df8a/prestone-windshield-de-icer/prs0/as242?pos=0'
            ]
        }
    },
    advanceauto: {
        retailerKey: 'advanceauto',
        retailerId: '5411a116-cdac-4099-8062-9ecfe6766acb',
        retailerName: 'AdvanceAutoParts.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://shop.advanceautoparts.com/p/diehard-lawn-garden-pro-battery-group-size-u1-340-cca-u1-3/2050013-P?navigationPath=L1*14920%7CL2*15000%7CL3*15600',
                'https://shop.advanceautoparts.com/p/diehard-lawn-garden-battery-group-size-u1-160-cca-u1-1/2050099-P?navigationPath=L1*14920%7CL2*15000%7CL3*15600',
                'https://shop.advanceautoparts.com/p/spartan-atk-engines-engine-assembly-dcx4/10472770-P?navigationPath=L1*14923%7CL2*15031%7CL3*16033',
                'https://shop.advanceautoparts.com/p/spartan-atk-engines-cad-3.6-10-11-engine-dcvw/11950707-P?navigationPath=L1*14923%7CL2*15031%7CL3*16033',
                'https://shop.advanceautoparts.com/p/dorman-chrysler-ford-gm-panel-retainer-963-058d/11911131-P?navigationPath=L1*14933%7CL2*15025%7CL3*15980'
            ]
        }
    },
    fcpeuro: {
        retailerKey: 'fcpeuro',
        retailerId: '9ed513c8-fd79-445d-b0a0-8b79c6be3251',
        retailerName: 'FCPEuro.com',
        productSamples: {
            url: [
                'https://www.fcpeuro.com/products/bmw-clutch-flywheel-oem-dmf112',
                'https://www.fcpeuro.com/products/bmw-fuel-injector-kit-bosch-0445110597kt1',
                'https://www.fcpeuro.com/products/audi-timing-chain-kit-iwis-06e109465bckt5',
                'https://www.fcpeuro.com/products/audi-vw-k03-turbocharger-kit-borg-warner-06f145701h',
                'https://www.fcpeuro.com/products/mercedes-brake-kit-front-e63-c63-amg-oem-w204amg2pfbk1'
            ]
        }
    },
    elkjop: {
        retailerKey: 'elkjop',
        retailerId: '344ec3a3-0fab-4690-8b16-ab06933d0bcc',
        retailerName: 'Elkjop.no',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.elkjop.no/product/293519/',
                'https://www.elkjop.no/product/311304/',
                'https://www.elkjop.no/product/293478/',
                'https://www.elkjop.no/product/KVL6300S/',
                'https://www.elkjop.no/product/305407/'
            ]
        }
    },
    elgigantendk: {
        retailerKey: 'elgigantendk',
        retailerId: 'e8d869b6-e63b-4023-963a-c9414287b8ec',
        retailerName: 'Elgiganten.dk',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.elgiganten.dk/product/305409',
                'https://www.elgiganten.dk/product/377617',
                'https://www.elgiganten.dk/product/183904',
                'https://www.elgiganten.dk/product/206096',
                'https://www.elgiganten.dk/product/293519',
            ]
        }
    },
    vitacost: {
        retailerKey: 'vitacost',
        retailerId: 'a2e252b9-7483-44f4-95b6-49de6cf346ce',
        retailerName: 'Vitacost.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.vitacost.com/now-vitamin-d-3-5000-iu-240-softgels',
                'https://www.vitacost.com/garden-of-life-vitamin-code-raw-zinc-60-vegan-capsules',
                'https://www.vitacost.com/natures-way-organic-mct-oil',
                'https://www.vitacost.com/every-man-jack-deodorant-stick-aluminum-free-cedar-wood-3-oz',
                'https://www.vitacost.com/mineral-fusion-blush-bronzer-duo-blonzer'
            ]
        }
    },
    albertsons: {
        retailerKey: 'albertsons',
        retailerId: '0e7dd0b8-c60d-45d1-9398-1cf34eb2af29',
        retailerName: 'Albertsons.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.albertsons.com/shop/product-details.127101868.html',
                'https://www.albertsons.com/shop/product-details.960019243.html',
                'https://www.albertsons.com/shop/product-details.960113945.html',
                'https://www.albertsons.com/shop/product-details.960063790.html',
                'https://www.albertsons.com/shop/product-details.960109089.html'
            ]
        }
    },
    smythstoys: {
        retailerKey: 'smythstoys',
        retailerId: 'a367054a-5017-4df4-8f77-bcf0ba33c02d',
        retailerName: 'SmythsToys.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www.smythstoys.com/p/204035',
                'https://www.smythstoys.com/uk/en-gb/toys/lego-and-bricks/lego-dc-comics-super-heroes/lego-76161-dc-comics-batman-1989-batwing-set-for-adults/p/194433',
                'https://www.smythstoys.com/uk/en-gb/toys/action-figures-and-playsets/jurassic-world/jurassic-world-dinosaur-track-set-and-3-dinosaur-figures/p/189404',
                'https://www.smythstoys.com/uk/en-gb/p/106919',
                'https://www.smythstoys.com/p/204864'

            ]
        }
    }, 
    renorun: {
        retailerKey: 'renorun',
        retailerId: 'cc94cba1-f842-4d71-a150-eed803fa304c',
        retailerName: 'RenoRun.com',
        productSamples: {
            url: [
                'https://renorun.com/en/washington/hardware-store/doors-accessories/door-hardware/door-strike-plates/2-3-4-in-x-1-1-8-in-steel-security-deadbolt-strike-satin-nickel-2000001177',
                'https://renorun.com/en/washington/hardware-store/doors-accessories/interior-doors/prehung-door/32-in-x-80-in-6-panel-colonist-primed-left-hand-textured-mdf-prehung-door-2803000509',
                'https://renorun.com/en/washington/hardware-store/framing-lumber-steel-stud/framing-lumber/2-in-x-4-in/2-in-x-4-in-x-4-ft-framing-lumber-2101000034',
                'https://renorun.com/en/washington/hardware-store/paint-adhesive-sealant/caulking-adhesive/glue-epoxy/gorilla-wood-glue-8-oz-2000000668',
                'https://renorun.com/en/washington/hardware-store/hvac/ventilation/duct-transitions-connectors/6-in-x-6-in-x-6-in-wye-2000002307'
            ]
        }
    },
    renorunca: {
        retailerKey: 'renorunca',
        retailerId: '82574595-e985-4164-a1fc-6c462008f696',
        retailerName: 'RenoRun.ca',
        productSamples: {
            url: [
                'https://renorun.ca/en/toronto/hardware-store/framing-lumber-steel-stud/framing-lumber/2-in-x-4-in/2-in-x-4-in-x-10-ft-framing-lumber-i-1101000651',
                'https://renorun.ca/en/toronto/hardware-store/moulding-millwork/wall-panel/4-ft-x-8-ft-fiberglass-reinforced-polyester-resin-wall-panel-1801000289',
                'https://renorun.ca/en/toronto/hardware-store/electrical/outlets-dimmers-wall-plates/outlets/electrical-outlet-receptacle-duplex-tamper-resistant-white-1000002780',
                'https://renorun.ca/en/toronto/hardware-store/roofing-siding/shingles/timberline-driftwood-roof-shingles-1907000916',
                'https://renorun.ca/en/toronto/hardware-store/cement-masonry/masonry-tools/54-in-100-in-jack-post-1503000811'
            ]
        }
    },
    idea4industry: {
        retailerKey: 'idea4industry',
        retailerId: '76641ca1-7bac-4a94-97e0-8cc5f13d0f16',
        retailerName: 'Idea4Industry.com',
        productId: 'id',
        disabled: false,
        disableUrl: true,
        productSamples: {
            url: [
            ]
        }
    },
    napaonline: {
        retailerKey: 'napaonline',
        retailerId: '2ce6a744-5054-43f3-aab1-a7a8ad19a4c8',
        retailerName: 'NAPAOnline.com',
        productSamples: {
            url: [
                'https://www.napaonline.com/en/p/BAT8231',
                'https://www.napaonline.com/en/p/BK_MTSA2525',
                'https://www.napaonline.com/accessories/weathertech-avm-floor-mats/27eacfa4?impressionRank=1',
                'https://www.napaonline.com/accessories/weathertech-avm-floor-mats?set-vehicle=1',
                'https://www.napaonline.com/accessories/smartliner-floor-mats'
            ]
        }
    },
    usfoods: {
        retailerKey: 'usfoods',
        retailerId: '5a1af739-3545-486f-8899-b0b42b8e258e',
        retailerName: 'USFoods.com',
        productId: 'id',
        productSamples: {
            url: [
                'https://www3.usfoods.com/order/faces/oracle/webcenter/portalapp/pages/productdetail/productDetail.jspx?_adf.ctrl-state=r64tavde9_130&productNumber=1155076&backUrl=%2Ffaces%2FHome%2FshopProducts%3F_adf.ctrl-state%3Dr64tavde9_130&context=Search&backLabel=Search&status2Flow=false&_afrLoop=1620130798430550#!',
                'https://www3.usfoods.com/order/faces/oracle/webcenter/portalapp/pages/productdetail/productDetail.jspx?_adf.ctrl-state=r64tavde9_179&productNumber=4072948&backUrl=%2Ffaces%2FHome%2FshopProducts%3F_adf.ctrl-state%3Dr64tavde9_179&context=Search&backLabel=Search&status2Flow=false&_afrLoop=1620153974578445#!',
                'https://www3.usfoods.com/order/faces/oracle/webcenter/portalapp/pages/productdetail/productDetail.jspx?_adf.ctrl-state=r64tavde9_4&productNumber=3017779&backUrl=%2Ffaces%2FHome%2FshopProducts%3F_adf.ctrl-state%3Dr64tavde9_4&context=Search&backLabel=Search&status2Flow=false&_afrLoop=1620772207253674#!',
                'https://www3.usfoods.com/order/faces/oracle/webcenter/portalapp/pages/productdetail/productDetail.jspx?_adf.ctrl-state=r64tavde9_617&productNumber=4131553&backUrl=%2Ffaces%2FHome%2FshopProducts%3F_adf.ctrl-state%3Dr64tavde9_617&context=Search&backLabel=Search&status2Flow=false&_afrLoop=1620796913432434#!',
                'https://www3.usfoods.com/order/faces/oracle/webcenter/portalapp/pages/productdetail/productDetail.jspx?_adf.ctrl-state=r64tavde9_4&productNumber=2803769&backUrl=%2Ffaces%2FHome%2FshopProducts%3F_adf.ctrl-'
            ]
        }
    },
    //TODO Create new Retailer above here
}
type RetailerType = keyof typeof RetailerRecordList

const canonicalNameMap = Object.assign(
    {},
    ...Object.values(RetailerRecordList).map(retObj => ({
        [retObj.retailerId]: retObj.retailerKey,
        [retObj.retailerKey]: retObj.retailerKey,
        [retObj.retailerKey.toLocaleLowerCase()]: retObj.retailerKey,
        [retObj.retailerName.toLocaleLowerCase()]: retObj.retailerKey,
    }))
);

const canonicalRetailerName = (retailer: string): RetailerType => {
    return canonicalNameMap[retailer?.toLocaleLowerCase()] || retailer;
}

const retailerNameRender = (retailer: string) => {
    switch(retailer) {
        case 'all':
            return "All"
        default:
            const canonical = canonicalRetailerName(retailer);
            const retObj = RetailerRecordList[canonical as keyof typeof RetailerRecordList];
            return retObj?.retailerName || null;
    }
}

const retailersWithProductId = {
    asin: [] as string[],
    id: [] as string[],
    toolId: [] as string[]
};

const retailersWithDisabledUrls = [] as string[];

Object.values(RetailerRecordList)
.forEach(rec => {
    if(rec['productId']) {
        retailersWithProductId[rec['productId']].push(rec.retailerName);
    }

    if(rec['disableUrl']) {
        retailersWithDisabledUrls.push(rec.retailerName);
    }
});

export const RetailerMetadata = {
    retailersWithProductId,
    retailersWithDisabledUrls
}

/* console.log(Object.assign({}, ...Object.values(RetailerRecordList)
.filter(rec => rec['productId'])
.map(rec => ({
    [rec.retailerKey]: ''
})))); */

export const RetailerNameRenderFns = {
    displayName: retailerNameRender,
    keyName: canonicalRetailerName
}

export const ACTIVE_RETAILERS = Object.keys(RetailerRecordList) as Array<RetailerType>;
