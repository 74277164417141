import { mapKeys, orderBy, cloneDeep } from "lodash";
import React, { useEffect } from "react";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "./style.scss";
import { useState } from "react";
import { CSShelfData } from ".";

interface ChartJsProps {
  id: string,
  options: any
}

const ChartJs: React.FC<ChartJsProps> = (props) => {
  useEffect(() => {
    const componentDidMount = async () => {
      const ctx = document.getElementById(props.id) as HTMLCanvasElement;
      const myChart = new Chart(ctx, props.options)
    };
    componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // <-- Empty dependency array makes this a "componentDidMount hook"

  return <canvas id={props.id} />
}

interface SOSChartsProps {
  public?: boolean;
  tableData?: Array<CSShelfData & { id: string}>;
}

const ChartsConfig = {
  sosChartPage1: {
    label: "Page 1"
  },
  sosChartTop10: {
    label: "Top 10"
  },
  sosChartTop5: {
    label: "Top 5"
  }
}

type ChartType = keyof typeof ChartsConfig;

type ChartOptions = {id: ChartType; data: number[]; labels: string[]; centerText: number};

const createChart = (options?: ChartOptions) => {
  if (options) {
    const {id, data, labels, centerText} = options;
    const colors = [
      [189, 44, 212],
      [84, 45, 182],
      [92, 185, 231],
      [160, 160, 160]

    ]
    const backgroundColor = colors.map(color => `rgba(${color.join(", ")},1)`);
    const borderColor = colors.map(color => "white"/* `rgba(${color.map(v => (v/2)).join(", ")},1)` */);
    
    const dataProp = {
      labels,
      datasets: [{
        //label: '# of Votes',
        data,
        backgroundColor,
        borderColor,
        borderWidth: 1,
        datalabels: {
          backgroundColor: 'white',
          clamp: true,
          anchor: 'end',
          align: 'right',
          offset: 10,
          font: {
            weight: 'bold'
          },
          formatter: function(value, context) {
            return ((value/centerText)*100).toFixed(1).replace(".0", "")+"%";//context.chart.data.labels[context.dataIndex];
          }
        }  
      }]
    }
    const maxValue = Math.max(...data);
    const optionsProp = {
      type: 'bar',
      data: dataProp,
      options: {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            title: {
              display: true,
              text: 'Brand'
            },
            labels: {
              boxWidth: 10,
              usePointStyle: true,
            },
            onClick: function(event, legendItem) {}
          }
        },
        scales: {
          x: {
            max: maxValue+Math.max(1, Math.ceil(maxValue*.25)),
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },
      plugins: [
        ChartDataLabels
        /* { //https://stackoverflow.com/questions/38724876/
        id: "annotateDoughnutCenter",
        beforeDraw: (chart) => {
          const width = chart.width - chart.legend.width;
          const height = chart.height;
          const ctx = chart.ctx;
  
          ctx.restore();
          const fontSize = (height / 114).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";
  
          const text = centerText;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;

          ctx.fillStyle = "#BD2CD4";
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      } */]
    }
  
    return <ChartJs
      key={id}
      id={id}
      options={optionsProp}
    />
  } else {
    return null;
  }
}

const genChartOptions = (id: ChartType, brandsCount: {[brand: string]: number}, total: number) => {
  const sortedRows = orderBy(Object.entries(brandsCount), ["1"], ["desc"]);

  const labelsAmount = 7

  const data = sortedRows.slice(0,labelsAmount).map(row => row[1]);
  const labels = sortedRows.slice(0,labelsAmount).map(row => row[0]);
  
  const othersRows = sortedRows.slice(labelsAmount);
  if (othersRows.length) {
    data.push(othersRows.reduce((a,b) => a+b[1], 0));
    labels.push("Other");
  }

  const centerText = total;
  return {id, data, labels, centerText} as ChartOptions
}

export const SOSCharts: React.FC<SOSChartsProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [chartState, setChartState] = useState<{[x in ChartType]?: ChartOptions}>({});

  const filteredData = props.tableData.filter(x => !x.isFeatured);

  useEffect(() => {
    const componentDidMount = async () => {

    };
    componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // <-- Empty dependency array makes this a "componentDidMount hook"

  useEffect(() => {
    const processProps = async () => {
      setDataLoading(true);
      const brandsCount = {

      }

      const processRows = (row: CSShelfData) => {
        const brand = row?.brand || undefined;
        if (!brandsCount[brand]) {
          brandsCount[brand] = 0;
        }
        brandsCount[brand] += 1;
      }

      filteredData.slice(0, 5).forEach(processRows); // Top 5
      const sosChartTop5 = genChartOptions("sosChartTop5", cloneDeep(brandsCount), 5);
      
      filteredData.slice(5, 10).forEach(processRows); // Top 10
      const sosChartTop10 = genChartOptions("sosChartTop10", cloneDeep(brandsCount), 10);

      filteredData.slice(10).forEach(processRows); // Page 1
      const sosChartPage1 = genChartOptions("sosChartPage1", cloneDeep(brandsCount), filteredData.length);

      setChartState({
        sosChartTop5,
        sosChartTop10,
        sosChartPage1
      });
      
      setDataLoading(false);
    };
    processProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartContainer = (id: ChartType) => {
    return <div key={id} className="chart-container">
      {!props.public ? <div className="header-div">
        <div>
          {ChartsConfig[id].label}
        </div>
        <div>
          Share-Of-Shelf
        </div>
      </div> : null}
      <div className="chart-div">
        {createChart(chartState[id])}
      </div>
    </div>
  }

  const chartIds = ["sosChartPage1"]
  if (!props.public) {
    chartIds.push(...["sosChartTop10", "sosChartTop5"]);
  }

  const chartsContainer = <div className={`charts-container${props.public ? " public" : ""}`}>
    {!dataLoading ? chartIds.map(chartContainer) : "Loading..."}
  </div>

  return chartsContainer;
};