import * as React from 'react';
import { Form, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import './style.scss';
import { MappedBrandFieldDefinition, Objectentries } from '../../libraries/types/datastructures';
import CustomDropDown from './CustomDropDown';

interface CustomVisualDropdownProps extends DropdownProps {
    insideForm?: boolean;
    insideFormField?: boolean;
    options: DropdownItemProps[];
    mappedFields?: { [x: string]: MappedBrandFieldDefinition }
}

const CustomVisualDropdown: React.FC<CustomVisualDropdownProps> = (props) => {
    const select = <CustomDropDown {...{
        onChange: (props.onChange) ? (e) => {
            const value = e.target.value;
            // debugger;
            props.onChange!(e, {
                ...props,
                value
            });
        } : undefined,
        value: (props.value) ? String(props.value) : (props.placeholder) ? "" : undefined,
        // defaultValue: (props.placeholder) ? "" : undefined
    }}>
        {(props.placeholder) ? <optgroup label={props.placeholder} disabled={true} ></optgroup> : null}
        {props.options.map((option, index) => {
            return <option key={index} value={String(option.value)}>{option.text}</option>;
        })}
        {Object.keys(props.mappedFields).length > 0 && [<option disabled={true} value="-"></option>, <optgroup label="Fields previously used" disabled>{Objectentries(props.mappedFields).map((entry: MappedBrandFieldDefinition, index: number) => <option disabled={true} className="visible" key={`mapped-${index}`}>{entry.fieldName}</option>)}</optgroup>]}
    </CustomDropDown>;

    const formField = (props.insideFormField)
        ? select
        : <Form.Field>
            {select}
        </Form.Field>;

    return (
        (props.insideForm)
            ? formField
            : <Form>
                {formField}
            </Form>
    );
};

export default CustomVisualDropdown;