import * as React from 'react';
import { ColorScheme } from '../../../../pages/Dashboard/datastructures';
import './style.scss';
import { ScoreObject } from '../../../../pages/Catalog/catalog-datamodel';

interface ScoreDisplayProps {
    title: string;
    score: ScoreObject;
    scheme?: ColorScheme;
    comingSoon?: boolean;
}

const ScoreDisplay: React.FC<ScoreDisplayProps> = props  => {

    const scoreNumber = props.score ? props.score.score : undefined;

    const scoreText = scoreNumber
    ? scoreNumber === 5 
        ? "Excellent"
        : scoreNumber >= 4
            ? "Very Good"
            : scoreNumber >= 3
                ? "Average"
                : "Needs Improvement"
    : "Needs Improvement";

    const scoreColor = typeof scoreNumber === 'number' && !isNaN(scoreNumber)
    ? scoreNumber === 5 
        ? '#4FDA03'
        : scoreNumber >= 4
            ? "#4FDA03"
            : scoreNumber >= 3
                ? "#FFC000"
                : "red"
    : "grey";


    return <div className="score-display">
        <h3>{props.title} Score</h3>
        <h1 style={{ color: scoreColor }}>
            { typeof scoreNumber !== "number" || isNaN(scoreNumber) || props.comingSoon
                ? '--'
                : scoreNumber.toFixed(1)
            }
        </h1>
        <h2>{props.comingSoon 
            ? "Coming Soon" 
            : scoreText
        }</h2>
    </div>;
}

export default ScoreDisplay;
