export interface Notification {
    id: string,
    title: string,
    text: string,
    date: number,
    link?: string,
    actionText?: string,
    color?: string,
    icon?: string,
    onAction?: (n: Notification) => void
}

//Sample Data
export const sampleNotifications: Notification[] = [{
    id: "download",
    title: "Export #33 is ready for download",
    text: "Click the link to download your Export #22.",
    date: 0,//"1 hour ago",
    link: "https://google.com",
    actionText: "Download",
    color: "#15b21f",
    icon: "file-excel"
}, {
    id: "job-open",
    title: "Job #345 has now Open",
    text: "Click on the link to view the Job Details.",
    date: 0,//"Yesterday",
    link: "https://google.com",
    actionText: "View Job",
    icon: "folder-open"
}, {
    id: "job-complete",
    title: "Job #235 is now Complete",
    text: "No further action is required.",
    date: 0,//"2 days ago",
    icon: "check-circle",
    color: "#ee00ff"
}, {
    id: "job-error",
    title: "Job #124 has an error",
    text: "Please review the Job to find the errors.",
    date: 0,//"Just now",
    link: "https://google.com",
    actionText: "View Job",
    icon: "exclamation-triangle",
    color: "#ef1515"
}, {
    id: "new-user",
    title: "User John Doe joined the team",
    text: "The user John Doe accepted your invitation and joined the team.",
    date: 0,//"On Apr. 22nd",
    icon: "user-plus",
    color: "#007fff"
}, {
    id: "retailer",
    title: "Walmart has sent a SKU Action List",
    text: "Click on the link to view the list.",
    date: 0,//"5 hours ago",
    icon: "clipboard-list",
    color: "#ff6e00"
}]