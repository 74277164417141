import React from 'react';
import { Job, NewJobProps, NewJobSetState } from '../index';
import moment from 'moment-timezone';
import {
  statusNameStyled,
  assignDotted,
} from '../../../libraries/types/datastructures';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider/Divider';
import StepLabel from '../../Stepper';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      fontSize: '1.1rem',
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
    list: {
      marginLeft: theme.spacing(2),
    },
    cardBody: {
      padding: theme.spacing(2),
    },
    saveButton: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
    modalBody: {
      textAlign: 'center',
    },
    controlLabel: {
      margin: 'unset',
    },
    content: {
      marginTop: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(0.5, 1),
      borderRadius: '3px',
      fontWeight: 600,
      fontSize: '.9rem',
    },
    newCustomTag: {
      marginTop: theme.spacing(1.5),
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
    gridContent: {
      padding: theme.spacing(0, 2),
    },
  });

interface Step4ComponentProps extends WithStyles<typeof styles> {
  message?: JSX.Element;
  basic: boolean;
  job: Job;
  retailerSite: string;
  onTabClicked: (tab: number) => void;
  goBackButton: (message: string, tab: number) => JSX.Element;
  credits: boolean;
  accuracy: boolean;
  parentProps: NewJobProps;
  onChange: NewJobSetState;
}

const Step4Component: React.FunctionComponent<Step4ComponentProps> = (
  props: Step4ComponentProps
) => {
  const retailerSite = props.retailerSite;
  const { schedule, jobData } = props.job.coreJob;
  const { reportTypes } = props.job;
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Grid
          container
          direction='column'
          spacing={2}
          className={props.classes.gridContent}>
          <Grid item>
            <StepLabel
              numberText={1}
              label='Please review and approve the audit details'
            />
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                Audits will be run on{' '}
                <span
                  onClick={() => props.onTabClicked(0)}
                  style={{ cursor: 'pointer' }}
                  className='csGreen'>
                  {retailerSite}
                </span>{' '}
                for{' '}
                <span
                  onClick={() => props.onTabClicked(1)}
                  style={{ cursor: 'pointer' }}
                  className='csGreen'>
                  these{' '}
                  {jobData.masterCsv.data.length
                    ? jobData.masterCsv.data.length
                    : undefined}{' '}
                  SKUs.
                </span>
              </Grid>
              <Grid item>
                <p className='jobSummary'>
                  This audit job will begin{' '}
                  <span
                    onClick={() => props.onTabClicked(2)}
                    style={{ cursor: 'pointer' }}
                    className='csGreen'>
                    {schedule.start.when === 'now'
                      ? 'today'
                      : moment(schedule.start.date).format('MMMM D, YYYY')}
                    .
                  </span>{' '}
                  {reportTypes.Live &&
                  schedule.liveStatus.frequency.how !== 'once' ? (
                    <span>
                      It will check the {statusNameStyled('LIVE')} of each SKU{' '}
                      {
                        <span
                          onClick={() => props.onTabClicked(2)}
                          style={{ cursor: 'pointer' }}
                          className='csGreen'>
                          {schedule.liveStatus.frequency.frequency &&
                          schedule.liveStatus.frequency.stop ? (
                            `${
                              schedule.liveStatus.frequency.frequency === 1
                                ? 'every day'
                                : `every ${schedule.liveStatus.frequency.frequency} days`
                            } until ${
                              schedule.liveStatus.frequency.stop.when === 'date'
                                ? `ending on ${moment(
                                    schedule.liveStatus.frequency.stop.date
                                  ).format('MMMM D, YYYY')}`
                                : `${schedule.liveStatus.frequency.stop.percent}% of the SKUs are live`
                            }`
                          ) : (
                            <b
                              onClick={() => props.onTabClicked(2)}
                              style={{ cursor: 'pointer' }}
                              className='csGreen'>
                              (please select how status checks will stop)
                            </b>
                          )}
                        </span>
                      }
                      .{' '}
                    </span>
                  ) : undefined}
                  {schedule.recur.how !== 'once' && schedule.recur.frequency ? (
                    <span>
                      Once the initial job is complete, it will re-run{' '}
                      {schedule.recur.stop &&
                      schedule.recur.frequency.amount &&
                      schedule.recur.frequency.unit ? (
                        <span
                          onClick={() => props.onTabClicked(2)}
                          style={{ cursor: 'pointer' }}
                          className='csGreen'>
                          {schedule.recur.stop.when === 'limit' &&
                          schedule.recur.stop.limit === 1 ? (
                            <span>
                              one time after{' '}
                              {schedule.recur.frequency.amount === 1
                                ? `one ${schedule.recur.frequency.unit}`
                                : `${schedule.recur.frequency.amount} ${schedule.recur.frequency.unit}s`}
                            </span>
                          ) : schedule.recur.stop.when !== 'limit' ||
                            schedule.recur.stop.limit !== 1 ? (
                            <span>
                              {schedule.recur.frequency.amount === 1
                                ? `every ${schedule.recur.frequency.unit}`
                                : `every ${schedule.recur.frequency.amount} ${schedule.recur.frequency.unit}s`}{' '}
                              {schedule.recur.stop.when === 'never'
                                ? undefined
                                : schedule.recur.stop.when === 'date'
                                ? `until ${moment(
                                    schedule.recur.stop.date
                                  ).format('MMMM D, YYYY')}`
                                : schedule.recur.frequency.amount === 1
                                ? `for ${schedule.recur.stop.limit} ${schedule.recur.frequency.unit}s`
                                : `${schedule.recur.stop.limit} times`}
                            </span>
                          ) : undefined}
                        </span>
                      ) : (
                        <b
                          onClick={() => props.onTabClicked(2)}
                          style={{ cursor: 'pointer' }}
                          className='csGreen'>
                          {' '}
                          (please select how the job will stop)
                        </b>
                      )}
                      .
                    </span>
                  ) : undefined}
                </p>
              </Grid>
              <Grid className={'tagRow'} item /* xs={12} */>
                <FormControlLabel
                    className={props.classes.controlLabel}
                    key={'props.credits'}
                    control={
                    <Checkbox
                        color='primary'
                        onChange={() => props.onChange(prevState => ({ view: assignDotted({ ...prevState.view }, 'legal.credits', !prevState.view.legal.credits) }))}
                        key={`props.credits`}
                        id={`props.credits`}
                        checked={props.credits}
                    />
                    }
                    label={<><b>I approve applying these {(jobData.masterCsv.data.length) ? jobData.masterCsv.data.length : undefined} SKUs to my One-Time Audit plan.</b><div className="requiredStar">*</div></>}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation='vertical' flexItem />
      <Grid item xs={6}>
        <Grid container direction='column' spacing={2} className={props.classes.gridContent}>
          <Grid item>
            <StepLabel numberText={2} label='Approve & Submit' />
          </Grid>
          <Grid item>
            Please review and agree to the following in order to click submit.
          </Grid>
          <Grid item>
            <FormControlLabel
              className={props.classes.controlLabel}
              key={'props.accuracy'}
              control={
                <Checkbox
                  color='primary'
                  onChange={() =>
                    props.onChange((prevState) => ({
                      view: assignDotted(
                        { ...prevState.view },
                        'legal.accuracy',
                        !prevState.view.legal.accuracy
                      ),
                    }))
                  }
                  key={`props.accuracy`}
                  id={`props.accuracy`}
                  checked={props.accuracy}
                />
              }
              label={
                <>
                  <b>I read and agree to the following:</b>
                  <div className='requiredStar'>*</div>
                </>
              }
            />
          </Grid>
          <Grid item>
            Content Status does not claim ownership of content that is returned;
            the ownership of returned content remains with the original author.
            You agree that your use of Content Status technology and tools are
            restricted to the extraction of data from publicly accessible
            websites which may or may not require a user login. In all cases
            where copyrighted and or confidential data may be included as part
            or all of the extracted data, you agree to comply with all copyright
            laws and rules covering such data including (but not limited to) the
            reproduction and resale of such data. You agree that Content Status
            will not be held responsible for legal liabilities incurred through
            the use or misuse of data obtained via Content Status technology.
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(Step4Component);
