import * as React from 'react';
import { Header, Segment, Divider, Card, SemanticCOLORS, List } from 'semantic-ui-react';
import { Objectentries } from '../../libraries/types/datastructures';
import { SinglePlan } from '../../libraries/types/chargebeeStructure';
import { firestoreDb, querystringparser } from '../../libraries/Util';
import { CSUIButton } from '../../components/CSUI';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import { ThirdPartyScriptService } from '../../libraries/Util/thirdPartyScripts';
import { API_CALL_GetTeamBilling } from '../../libraries/Util/apiClient';
import { ChargebeeAppClass, ChargebeeService } from '../../libraries/ScriptServices/Chargebee';

interface DirectCheckoutProps {
    router: RouteComponentProps<any>;
}

type TeamBilling = {
    adminId: string;
    authorizedIds: string[];
    teamId: string;
    planId?: string;
}

interface State {
    dataLoading: boolean;
    plan_id: string;
    plans?: {[plan_id: string]: SinglePlan};
    teamBilling?: TeamBilling;
    teamId?: string;
}

const priceRenderer = (price: number) => {
    const fixedFmt = (price/100).toFixed(2);
    const [whole, dec] = fixedFmt.split(".");
    const wholeLocaleFmt = parseInt(whole, 10).toLocaleString();
    return `$${wholeLocaleFmt}.${dec}`;
}
const creditsRenderer = (credits: string) => `Credits: ${parseInt(credits, 10).toLocaleString()}`

const getColor = (index: number, mixed: boolean = true) => {
    const colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];
    const colorsMixed = ['red', 'green', 'purple', 'oragne', 'teal', 'pink', 'yellow', 'blue', 'brown', 'olive', 'violet', 'grey', 'black'];
    return ((mixed) ? colorsMixed : colors)[index] as SemanticCOLORS;
}

class Content extends React.Component<DirectCheckoutProps, State> {

    private chargebeeApp: ChargebeeAppClass;

    constructor(props: DirectCheckoutProps) {
        super(props);

        this.state = {
            dataLoading: true,
            plan_id: ''
        };
    }

    async componentDidMount() {
        ThirdPartyScriptService.setupChargebeeScript();

        const processTeamId = (teamId: string, next: () => void = () => {}) => {
            if (teamId) {
                this.chargebeeApp = ChargebeeService.get({teamId, chargebeeCustomerId: teamId});
                this.setState({teamId}, next);
            }
        }

        const processPlanId = async (plan_id: string) => {

            if (plan_id) {
                this.setState({plan_id});
            }

            if (this.chargebeeApp) {
                const plans: State['plans'] = {};
                const plansArray = await this.chargebeeApp.getPlans();
                plansArray.forEach(plan => { plans[plan.plan.id] = plan});

                this.setState({plans});
            }
        }

        const parsedLocationSearch = querystringparser(this.props.router.location.search);

        const hashIdParam = parsedLocationSearch['hashId'];

        const hashId = (hashIdParam && hashIdParam[0])
        ? hashIdParam[0]
        : undefined;

        if (hashId) {
            const hashObjDoc = await firestoreDb.collection("CSPublicHash").doc(hashId).get();

            const hashObj = hashObjDoc.exists
            ? hashObjDoc.data()
            : undefined;

            if (hashObj && hashObj.type === "directCheckout") {
                const {teamId, plan_id} = hashObj.data;

                processTeamId(
                    teamId,
                    () => processPlanId(plan_id)
                )
            }

        } else {

            const teamIdParam = parsedLocationSearch['teamId'];

            const teamId = (teamIdParam && teamIdParam[0])
            ? teamIdParam[0]
            : undefined;

            processTeamId(
                teamId,
                () => {
                    const planIdParam = parsedLocationSearch['plan_id'];

                    const plan_id = (planIdParam && planIdParam[0])
                    ? planIdParam[0]
                    : undefined;

                    processPlanId(plan_id);
                }
            );
        }

        await this.getTeamBilling();

        this.setState({dataLoading: false})
    }

    getTeamBilling = async () => {

        const {teamId} = this.state;

        const teamBillingResponse = await API_CALL_GetTeamBilling(teamId);
        const teamBilling = teamBillingResponse.data as TeamBilling;

        this.setState({teamBilling});
    }

    componentWillUnmount() {
        if (this.chargebeeApp) {
            this.chargebeeApp.destructor();
        }
    }

    plansFilter = (one_time_payment: string) => (this.state.plans)
    ? Objectentries(this.state.plans).filter(obj => obj.plan['cf_plan_enabled'] === 'True' && (obj.plan.cf_one_time_payment === one_time_payment))
    : [];

    subscriptionPlans = () => this.plansFilter('False').sort((a, b) => {
        return (a.plan.price > b.plan.price)
        ? -1
        : (a.plan.price > b.plan.price)
            ? 1
            : 0
    })

    oneTimePaymentPlans = () => this.plansFilter('True')

    directCheckout = (plan_id: string) => {
        // console.log("hi")
        const {plans} = this.state;

        const {attached_addons} = plans[plan_id].plan as any;
        const addonIds = (attached_addons || []).map(addon => addon.id) as string[];

        this.chargebeeApp.openCheckout(plan_id, 1, addonIds);
    }

    subscriptionCard = ({plan}: SinglePlan, index: number) => {
        const isTeamPlanId = this.isTeamPlanId(plan.id);
        return <Card key={plan.id} color={getColor(index)} className={isTeamPlanId ? "teamPlanId" : ""}>
            <Card.Content header={plan.name} />
            <Card.Content description={priceRenderer(plan['price'])} />
            {plan.cf_credits ? <Card.Content description={creditsRenderer(plan.cf_credits as any)} /> : null}
            <Card.Content description={<List items={[plan['description']]} bulleted={true} />} />
            <Card.Content extra>
                <CSUIButton
                    disabled={isTeamPlanId}
                    label={'Subscribe'}
                    color="green"
                    onClick={() => this.directCheckout(plan.id)}
                />
            </Card.Content>
        </Card>;
    }

    oneTimePaymentButton = ({plan}: SinglePlan, index: number) => {
        if (plan) {

            const card = <Card key={plan.id} color={getColor(index)} className={this.isTeamPlanId(plan.id) ? "teamPlanId" : ""}>
                <Card.Content header={plan.name} />
                <Card.Content description={priceRenderer(plan['price'])} />
                <Card.Content description={creditsRenderer(plan.cf_credits as any)} />
                <Card.Content description={<List items={[plan['description']]} bulleted={true} />} />
                <Card.Content extra>
                    <CSUIButton label={'Pay now'} color="green" onClick={() => this.directCheckout(plan.id)} />
                </Card.Content>
            </Card>;

            return card;
        } else {
            return null;
        }
    }

    isTeamPlanId = (planId: string) => this.state.teamBilling?.planId === planId;

    newLayout = () => <div style={{minHeight: 486}}>{!!!this.state.plans
        ? "Loading..."
        : <div>
            <Header as="h2">Monthly Plans</Header>
            <div style={{marginTop: 30, minHeight: 196}}>
                <Card.Group content={this.subscriptionPlans().map(this.subscriptionCard)} centered={true} />
            </div>
            <Divider />
            <Header as="h3">Add Credits Now</Header>
            Choose an amount:
            <div style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: "baseline"
            }}>
                {this.oneTimePaymentPlans().map(this.oneTimePaymentButton)}
            </div>
        </div>
    }</div>

    directCheckoutLayout = () => {
        const {plans, plan_id} = this.state;
        if (plans && plan_id) {
            const plan = plans[plan_id];

            return <div>
                <Header as="h2">Purchase Details</Header>
                <div style={{marginTop: 30, minHeight: 196, display: "flex", justifyContent: "center"}}>
                    {this.subscriptionCard(plan, 0)}
                </div>
            </div>
        } else {
            return null
        }
    }

    comingSoon = () => <Segment style={{minHeight: 486}}>
        <div style={{marginTop: 20}}><span style={{fontWeight: 900, fontSize: '2rem', color: '#4FDA03'}}>COMING SOON...</span></div>
    </Segment>

    loadingCircle = () => {
        return <div className="loadingCircle">
            <CircularProgress />
        </div>
    }

    render() {
        const {dataLoading} = this.state;
        return (
            <div>
                <div
                    className="AddCreditsContainer"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '0 auto',
                        justifyContent: 'center',
                        paddingTop: 20
                        // flexWrap: 'wrap'
                    }}
                >
                    {dataLoading
                        ? this.loadingCircle()
                        : this.directCheckoutLayout()
                    }
                </div>
            </div>
        );
    }
}

export default Content;
