export interface ColorScheme {
    primary?: string,
    secondary?: string,
    tertiary?: string,
    default?: string
}

export interface ProgressCard {
    title: string,
    subTitle: string,
    icon: string,
    progress: number,
    value: any,
    type: 'number' | 'percent' | 'currency' | 'integer' | 'string',
    variation: number,
    period: 'day' | 'week' | 'month' | 'year',
    footNote: string
}

export interface DashboardState {
    scheme: ColorScheme,
    currentView: 'main' | 'kpt' | 'kpi',
    statusChecks: ProgressCard[],
    scoreCards: ProgressCard[],
    improvementSummary: ProgressCard[],
    stats: DashboardStats[],
    tables: DashboardTable[],
    lastUpdate: string
}

export interface DashboardStat {
    display: 'title' | 'subtitle' | 'item',
    label: string,
    value?: number | string,
    highlighted?: boolean,
    link?: string,
}

export interface DashboardTableColumn {
    label: string,
    percent: number,
    textAlign?: 'left' | 'right' | 'center' | 'justify'
}

export interface DashboardTableRow {
    value: any,
    underlined?: boolean,
    className?: string,
    styles?: string
}

export interface DashboardTable {
    columns: DashboardTableColumn[],
    rows: DashboardTableRow[][],
    title: string,
    subTitle: string,
    ctaText?: string,
    ctaTarget?: string
}

export interface DashboardStats {
    stats: DashboardStat[],
    title: string,
    subTitle: string,
    footNote?: string
}

export interface DashboardInfo {
    recurring_jobs: RecurringInfo[],
    scorecard_summary: {
        asset_score: {
            today: number
        },
        keyword_score: {
            today: number,
            progress?: number

        },
        marketing_score: {
            today: number
        },
        sentiment_score: {
            today: number
        },
    },
    status_checks: {
        accuracy_status: {
            today: number
        },
        health_status: {
            today: number
        },
        keyword_status: {
            today: number
        },
        critical_flags?: number,
        live_status: {
            today: number
        },
        num_skus: {
            today: number
        },
    },
    action_items?: {
        alerts: number | string,
        skus_not_found: number | string,
        missing_skus: number | string,
        score_under_2: number | string,
        key_items_under_3: number | string,
        new_launches_under_3: number | string,
        top_sellers_under_3: number | string,
        high_returns_under_3: number | string
    },
    workflow_folders?: {
        open_workflows: number | string,
        skus_in_stage: {
            not_reviewed: number | string,
            in_progress: number | string,
            review: number | string,
            approved: number | string,
            re_submitted: number | string
        }
    },
    timestamp: number
}

export interface RecurringInfo {
    accuracy_status: number,
    health_status: number,
    keyword_status: number,
    lastTimestamp: number,
    live_status: number,
    numSkus: number,
    retailer: string,
    jobName: string,
}


export const dashboardDemoState: DashboardState = {
    currentView: 'main',
    scheme: {
        primary: 'indigo',
        secondary: 'green',
        tertiary: 'blue',
        default: '#595959'
    },
    lastUpdate: 'Today',
    statusChecks: [
        {
            title: 'Live',
            subTitle: 'Status',
            icon: 'dash scorelive',
            progress: 100,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'day',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Health',
            subTitle: 'Status',
            icon: 'dash statushealth',
            progress: 100,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Accuracy',
            subTitle: 'Status',
            icon: 'dash statusaccuracy',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Critical',
            subTitle: 'Flags',
            icon: 'dash statuskeyword',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs'
        }
    ],
    scoreCards: [
        {
            title: 'Marketing',
            subTitle: 'Score',
            icon: 'dash marketingscore',
            progress: 100,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Keyword',
            subTitle: 'Score',
            icon: 'dash keywordscore',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Asset',
            subTitle: 'Score',
            icon: 'dash assetscore',
            progress: 100,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }, {
            title: 'Sentiment',
            subTitle: 'Score',
            icon: 'dash sentimentscore',
            progress: 100,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of SKUs Tested'
        }
    ],
    improvementSummary: [
        {
            title: 'Score',
            subTitle: 'Improvement',
            icon: 'dash scoreimprovement',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% score improvement'
        }, {
            title: 'Brand',
            subTitle: 'Participating',
            icon: 'dash brandsparticipate',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of total brands'
        }, {
            title: 'SKUS',
            subTitle: 'Improved',
            icon: 'dash skusimproved',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of total SKUs'
        }, {
            title: 'MSRP',
            subTitle: 'Improved',
            icon: 'dash msrp improve',
            progress: 0,
            value: '--',
            type: 'string',
            variation: 0,
            period: 'week',
            footNote: '% of total MSRP'
        }
    ],
    stats: [
        {
            stats: [{
                display: 'title',
                label: 'Open Workflows',
                value: '--'
            }, {
                display: 'subtitle',
                label: 'SKUs in stage'
            }, {
                display: 'item',
                label: 'Not reviewed',
                value: '--',
                highlighted: true
            }, {
                display: 'item',
                label: 'In Progress',
                value: '--'
            }, {
                display: 'item',
                label: 'Review',
                value: '--'
            }, {
                display: 'item',
                label: 'Approved',
                value: '--'
            }, {
                display: 'item',
                label: 'Re-Submitted',
                value: '--'
            }],
            title: 'Workflow',
            subTitle: 'Folders'
        }, {
            stats: [{
                display: 'item',
                label: 'Alerts',
                value: '--'
            }, {
                display: 'item',
                label: 'SKUs not found',
                highlighted: true,
                value: '--'
            }, {
                display: 'item',
                label: 'Missing SKUS',
                value: '--'
            }, {
                display: 'item',
                label: 'Score <2',
                value: '--',
                highlighted: true
            }, {
                display: 'item',
                label: 'Key Items <3',
                value: '--'
            }, {
                display: 'item',
                label: 'New Launches <3',
                value: '--'
            }, {
                display: 'item',
                label: 'Top Sellers <3',
                value: '--'
            }, {
                display: 'item',
                label: 'High Returns <3',
                value: '--',
                highlighted: true
            }],
            title: 'Action',
            subTitle: 'Items',
            footNote: 'NA = no SKUs have been tagged'
        }
    ],
    tables: [
        {
            title: 'Recurring',
            subTitle: 'Jobs',
            ctaText: '+Add',
            ctaTarget: '/jobs/new',
            columns: [{
                label: 'Job Name',
                percent: 35
            }, {
                label: 'SKUs',
                percent: 15,
                textAlign: 'center'
            }, {
                label: 'Last Run',
                percent: 20,
                textAlign: 'center'
            }, {
                label: '% Live',
                percent: 15,
                textAlign: 'center'
            }, {
                label: 'Avg Health',
                percent: 15,
                textAlign: 'center'
            }],
            rows: []
        }
    ]
}
