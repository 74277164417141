/* eslint-disable import/first */
import 'core-js';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

if (process.env.NODE_ENV === 'production') {
  const devToolsGlobalHook = (window as any)['__REACT_DEVTOOLS_GLOBAL_HOOK__'];
  if (
    devToolsGlobalHook &&
    typeof devToolsGlobalHook === 'object'
  ) {
    for (const key of Object.keys(devToolsGlobalHook)) {
      devToolsGlobalHook[key] = (typeof devToolsGlobalHook[key] === "function") ? () => {} : null;
    }
  }
}

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { AppWithRouter } from './App';
import {unregister} from './registerServiceWorker';
import { Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import CSSentryBoundary from './components/Sentry';
import { UserAccountObj } from './libraries/types/datastructures';
import { history } from './libraries/Util/url';
import { ScriptServices } from './libraries/ScriptServices';
import { TrackingService } from './libraries/Tracking';
import { rootStore } from './store';
import { NLReportsService } from './components/Reports/data';
import { API_CALL_updateUserAccount } from './libraries/Util/apiClient';

ScriptServices.init();

const store = {
  rootStore: rootStore,
  rulesStore: rootStore.rulesStore,
  workflowStore: rootStore.workflowStore,
  dashboardStore: rootStore.dashboardStore
}

const activeUserHandler = async (activeUser: UserAccountObj) => {
  if (activeUser.isSignedIn) {
    NLReportsService.setTeamId(activeUser.user.core.teamId);
    await ScriptServices.setUser(activeUser);
    await API_CALL_updateUserAccount({...activeUser, lastLogin: Date.now()} as any);
  }
}

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
  //    ga.send(["pageview", location.pathname]);
    TrackingService.onRouteChange(location);
  }, [location]);
}

function App() {
  usePageViews();
  return <AppWithRouter reportActiveUser={activeUserHandler} />;
}

ReactDOM.render(
    <Router history={history}>
      <CSSentryBoundary>
        <Provider {...store}>
          <App />
        </Provider>
      </CSSentryBoundary>
    </Router>,
    document.getElementById('root') as HTMLElement
);

unregister();
