import React from 'react';
import { ConfirmationDialogProps } from './datastructures';
import Modal from '../Modal';
import { CSUIButton } from '../CSUI/CSUIButton';
import useStyles from './styles';

// README
// If you want to use single button you should pass onAlert, confirm and type props.
// for props look at ./datastructures

const ConfirmationDialog = ({
  type,
  title,
  titleColor,
  cancel,
  confirm,
  infoButonColor,
  onCancel,
  onConfirm,
  onAlert,
  children,
}: ConfirmationDialogProps) => {
  const classes = useStyles();
  return (
    <Modal open={true} onClose={onCancel}>
      <div className={classes.container}>
        <div>
          <h3
            className={classes.title}
            style={{ color: titleColor ? titleColor : 'initial' }}>
            {title}
          </h3>
          {children}
          <div className={classes.buttonContainer}>
            {!type && (
              <div>
                <CSUIButton slim onClick={onCancel} flat color='lightgrey'>
                  {cancel || 'Cancel'}
                </CSUIButton>
                <CSUIButton slim onClick={onConfirm} flat color='lightred'>
                  {confirm || 'Confirm'}
                </CSUIButton>
              </div>
            )}
            {type === 'success' && (
              <div>
                <CSUIButton slim onClick={onAlert} flat color='green'>
                  {confirm || 'Ok'}
                </CSUIButton>
              </div>
            )}
            {type === 'error' && (
              <div>
                <CSUIButton slim onClick={onAlert} flat color='lightred'>
                  {confirm || 'Close'}
                </CSUIButton>
              </div>
            )}
            {type === 'info' && (
              <div>
                <CSUIButton
                  slim
                  onClick={onAlert}
                  flat
                  color={infoButonColor ? infoButonColor : 'lightgrey'}>
                  {confirm || 'Ok'}
                </CSUIButton>
              </div>
            )}
            {type === 'confirmation' && (
              <div>
                <CSUIButton slim onClick={onCancel} flat color='lightgrey'>
                  {cancel || 'Cancel'}
                </CSUIButton>
                <CSUIButton slim onClick={onConfirm} flat color='green'>
                  {confirm || 'Confirm'}
                </CSUIButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationDialog;
