
// User Settings Datastructure
import { Tag } from './SKUTaggingView/datastructures';
import { MasterDataModel } from '../../components/JobStatusReports/MasterStatus/datamodel';
import { columnMapByList, masterColumnMap } from './columnDefinitions';

export interface UserSettingsData {
    filters: Filters,
    customTags: Tag[],
    viewData: CustomViewData,
    categories: ColumnObj[],
    search: UserSearch
}

export interface UserSearch {
    searchedData?: { [x: string]: MasterDataModel };
    searchTerm?: string;
    searchInputValue?: string;
}
// New Filter Datastructure

export interface CustomViewList {
    id: string;
    type: 'list';
    displayName: string;
    list: {
        [columnId: string]: CustomViewItem
    };
    position?: number;
}
//New interface for branch items
export interface CustomViewItem {
    id: string;
    type: "item" | "tags";
    displayName: string;
    checked: boolean;
    selection?: string[];
    subtext?: string,
    bold?: boolean,
    columnHeaderTitle?: string,
    columnHeaderSubtitle?: string,
    tableColumnPosition?: number,
    position?: number,
}

export interface CustomViewSection {
    id: string;
    type: 'section';
    displayName: string;
    section: {
        [sectionName: string]: CustomViewSubItem
    };
    position?: number;
}

export type CustomViewSubItem = CustomViewItem | CustomViewList | CustomViewSection

export interface CustomViewData {
    [sectionName: string]: CustomViewList | CustomViewSection;
}

export const findCustomViewValueDisplayName = (id: string, source: CustomViewData | CustomViewList | CustomViewSection = defaultCustomViewValues): string => {
    let displayName: string;
    Object.keys(source).forEach((key: string) => {
        if (!displayName) {
            if (source[key].id === id) {
                displayName = source[key].displayName;
            } else if (source[key].type === "section") {
                displayName = findCustomViewValueDisplayName(id, source[key].section);
            } else if (source[key].type === "list") {
                displayName = findCustomViewValueDisplayName(id, source[key].list);
            }
        }
    });
    return displayName || ""
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findCustomViewValueDisplayName2 = (columnId: string) => {
    const columnObj = masterColumnMap[columnId];

    return columnObj
    ? columnObj.displayName
    : ''
}

export const defaultCustomViewValues: CustomViewData = {
    audit_brandIds: {
        id: "audit_brandIds",
        type: "section",
        displayName: "Audit & Brand ID's",
        section: {
            all_record_ids: {
                id: "content_types_ari",
                type: "list",
                displayName: "All Record ID's",
                list: columnMapByList.allRecordIdColumns,
                position: 1,
            },
            all_corportate_ids: {
                id: "content_types_aci",
                type: "list",
                displayName: "All Corporate ID's",
                list: columnMapByList.allCorporateIdColumns,
                position: 2,
            },
            all_brand_ids: {
                id: "all_brand_ids",
                displayName: "All Brand ID's",
                type: "list",
                list: columnMapByList.allBrandIdColumns,
                position: 3,
            }
        }
    },
    page_product: {
        id: "page_product",
        type: "section",
        displayName: "Page & Product",
        section: {
            live: {
                id: "live",
                type: "list",
                displayName: "All Product Status",
                list: columnMapByList.live,
                position: 1
            },
            hierarchies: {
                id: "content_types_hierarchies",
                displayName: "All Hierarchies",
                type: "list",
                list: columnMapByList.allHierarchies,
                position: 2,
            },
            sales_data: {
                id: "content_types_sales_data",
                displayName: "All Sales",
                type: "list",
                list: columnMapByList.allSalesData,
                position: 3,
            },
            specifications: {
                id: "specifications",
                displayName: "All Specifications",
                type: 'list',
                list: columnMapByList.specifications,
                position: 4
            },
            specs: {
                id: "specs",
                displayName: "Custom Retailer Specs",
                type: "list",
                list: columnMapByList.specsAmazon,
                position: 5,
            }
        }
    },
    content_types: {
        id: "cst",
        type: "section",
        displayName: "Content",
        section: {
            marketing: {
                id: "content_types_marketing",
                displayName: "All Marketing Copy",
                type: "list",
                list: columnMapByList.allMarketing,
                position: 2
            },
            assets: {
                id: "content_types_assets",
                displayName: "All Asset Images",
                type: "list",
                list: columnMapByList.allAssets,
                position: 3
            },
            richContent: {
                id: "richContent",
                type: "list",
                displayName: "All Rich Content",
                list: columnMapByList.allRichContent,
                position: 4
            },
            workflows: {
                id: "workflows",
                type: "list",
                displayName: "Workflow Stage",
                list: columnMapByList.workflows,
                position: 5
            },
            edited: {
                id: "edited",
                type: "list",
                displayName: "All Edited Marketing Copy",
                list: columnMapByList.editedColumns,
                position: 6,
            },
            /* dimensions: {
                id: "content_types_all_dimensions",
                displayName: "Dimensions, Weight & Color",
                type: "list",
                list: columnMapByList.allDimensions,
                position: 7,
            }, */
            sentiment: {
                id: "content_types_sentiment",
                displayName: "All Sentiment",
                type: "list",
                list: columnMapByList.allSentiment,
                position: 7,
            },
        }
    },
    scores: {
        id: "scores",
        displayName: "Scores",
        type: "section",
        section: {
            health_scores: {
                id: "health_scores",
                displayName: "All Scores",
                type: "list",
                list: columnMapByList.health,
                position: 1
            },
            all_counts: {
                id: "all_counts",
                displayName: "All Counts",
                type: "list",
                list: columnMapByList.allCounts,
                position: 2
            }
        }
    },
    accuracy: {
        id: "accuracy",
        displayName: "Accuracy",
        type: "section",
        section: {
            all_accuracy: {
                id: "all_accuracy",
                displayName: "All Accuracy",
                type: "list",
                list: columnMapByList.accuracy,
                position: 1
            },
            flag_counts: {
                id: "flag_counts",
                displayName: "All Flag Counts",
                type: "list",
                list: columnMapByList.flagCounts,
                position: 2
            }
        }
    },
    search_tags: {
        id: "search_tags",
        displayName: "Search & Tags",
        type: "section",
        section: {
            keywords: {
                id: "keywords",
                displayName: "Keywords",
                type: "list",
                list: columnMapByList.keywords,
                position: 1,
            },
            all_system_tags: {
                id: "tags_all_system_tags",
                displayName: "All System Tags",
                type: "list",
                list: columnMapByList.allSystemTagColumns,
                position: 2,
            },
            custom_tags: {
                id: "custom_tags",
                displayName: "All Custom Tags",
                type: "tags",
                checked: false,
                selection: [],
                position: 3,
            }
        }
    }
}

export const columnSectionNameMap: { [columnId: string]: string } = {};

const processCustomViewItem = (item: CustomViewSubItem, sectionName = '') => {
    const secName = sectionName || item.displayName;
    switch (item.type) {
        case 'item':
            columnSectionNameMap[item.id] = secName;
            break;
        case 'tags':
            break;
        default:
            Object.values(item[item.type]).forEach(thing => {
                processCustomViewItem(thing as any, secName);
            })
    }
}

Object.values(defaultCustomViewValues).forEach(section => processCustomViewItem(section))

// Exports
export interface ColumnObj {
    category: string;
    column: string;
    id: string;
    columnHeaderTitle?: string;
    columnHeaderSubtitle?: string;
    tableColumnPosition?: number;
    position?: number;
}


/* Types definitions for the Filter View Component */

export interface FilterSelectableItem {
    id: string,
    displayName: string,
    checked: boolean,
    type: "selectable",
    className?: string,
    keepOrder?: boolean,
    selectedValue?: boolean,
    disabled?: boolean,
}
export interface FilterItemWithValue {
    id: string,
    displayName: string,
    valueType: "number" | "percent" | "numeric",
    value: number,
    minValue?: number,
    maxValue?: number,
    type: "value",
    checked?: boolean,
    className?: string,
    keepOrder?: boolean,
    disabled?: boolean,
}

export interface FilterSelectableItemWithValue {
    id: string,
    displayName: string,
    valueType: "number" | "percent" | "numeric",
    value: number,
    minValue?: number,
    maxValue?: number,
    checked: boolean,
    type: "selectable-value" | "value",
    className?: string,
    keepOrder?: boolean,
    selectValue?: string,
    disabled?: boolean,
}

export interface FilterDateItem {
    id: string,
    displayName: string,
    type: "date",
    value: Date | null,
    className?: string,
    keepOrder?: boolean,
    disabled?: boolean,
    checked?: boolean
}
export interface FilterDynamicItem {
    id: string,
    displayName: string,
    type: "jobs" | "tags",
    value: string[],
    className?: string,
    keepOrder?: boolean,
    disabled?: boolean,
    checked?: boolean
}

export interface FilterDateOptionsItem {
    id: string,
    displayName: string,
    type: "date-options",
    from: Date | null,
    to: Date | null,
    value: "all" | "current" | "range",
    keepOrder?: boolean,
    disabled?: boolean,
    checked?: boolean,
}

export type FilterItem = FilterSelectableItem | FilterItemWithValue | FilterSelectableItemWithValue | FilterDateItem | FilterDateOptionsItem | FilterDynamicItem;


export interface FiltersGenericList<T> {
    items: T[],
    searchable: boolean,
    label: string,
    id: string,
}

export interface FiltersList extends FiltersGenericList<FilterItem> { }

export interface Filters {
    dataSource: FiltersList,
    versions: FiltersList,
    date: FilterDateOptionsItem,
    jobs: FilterDynamicItem,
    brands: FiltersGenericList<FilterSelectableItem>,
    category: FiltersGenericList<FilterSelectableItem>,
    subCategory1: FiltersGenericList<FilterSelectableItem>,
    subCategory2: FiltersGenericList<FilterSelectableItem>,
    live: FiltersGenericList<FilterSelectableItem>,
    accuracy: FiltersGenericList<FilterSelectableItemWithValue>,
    health: FiltersGenericList<FilterSelectableItemWithValue>,
    systemTags: FiltersGenericList<FilterSelectableItem>,
    keywords: FiltersGenericList<FilterSelectableItem>,
    content: FiltersGenericList<FilterSelectableItem>,
    fulfillmentType: FiltersGenericList<FilterSelectableItem>,
    stages: FiltersGenericList<FilterSelectableItem>,
    isPrime: FiltersGenericList<FilterSelectableItem>,
    folders: FiltersGenericList<FilterSelectableItem>,
    customTags: FilterDynamicItem,
    checked?: boolean,
}

/* Keywords interfaces */

export interface KeywordObject {
    displayName: string;
    id: string;
    applied: boolean;
    type: "primary" | "secondary";
}

export interface KeywordCollection {
    [skuId: string]: {
        skuId: string;
        keywords: {
            [keywordId: string]: KeywordObject;
        }
    }
}
