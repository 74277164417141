import {Report, Format, ConditionalFormat} from 'webdatarocks';
import {ReportData} from "./ReportsLayout";
import {mapKeys, mapValues} from 'lodash';

const commonNumberFormats: Array<Format> = [
    {
        name: '',
        maxDecimalPlaces: 1,
        decimalPlaces: 1,
        thousandsSeparator: ",",
        nullValue: ""
    },
    {
        name: 'count',
        maxDecimalPlaces: 0,
        decimalPlaces: 0,
        thousandsSeparator: ","
    }
]

const defaultCommonConditionalFormats: Array<Partial<ConditionalFormat>> = [
    {
        formula: "#value >= 4",
        format: {
            backgroundColor: "#4FDA03",
            color: "#FFFFFF",
        }
    },
    /* {
        formula: "#value >= 4 AND #value < 5",
        format: {
            //backgroundColor: "#FF0000",
            color: "#4FDA03",
        }
    }, */
    {
        formula: "#value >= 3 AND #value < 4",
        format: {
            //backgroundColor: "#FF0000",
            //color: "#FFC000",
        }
    },
    {
        formula: "#value >= 2 AND #value < 3",
        format: {
            backgroundColor: "#FF9393 ",
            color: "#FFFFFF",
        }
    },
    {
        formula: "#value >= 1 AND #value < 2",
        format: {
            backgroundColor: "#FF3F3F",
            color: "#FFFFFF",
        }
    },
    {
        formula: "!isNaN(#value) AND #value < 1",
        format: {
            backgroundColor: "#E20000",
            color: "#FFFFFF",
        }
    },
    {
        formula: "!isNaN(#value) AND #value < 1",
        format: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
        },
        measure: "richContentScore"
    },
    {
        formula: "!isNaN(#value) OR isNaN(#value)",
        format: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
        },
        measure: "e" //brand
    }
]

export const ReportsConditionalFormatter = (value: number | string, columnName?: string) => {
    const valStr = (value === undefined || value === null
    ? NaN
    : typeof value === 'string'
        ? parseFloat(value)
        : Number(value)
    ).toString();
    
    const candidates = defaultCommonConditionalFormats.filter(fmt => {
        if (fmt.measure === undefined && fmt.formula !== undefined) {
            const evalStr = fmt.formula
            .replaceAll("#value", valStr)
            .replaceAll("AND", "&&")
            .replaceAll("OR", "||");

            // eslint-disable-next-line no-eval
            return eval(evalStr);            
        } else {
            return false;
        }
    });
    const candidatesWithoutColumnNames = candidates.filter(fmt => fmt.measure === undefined);

    const found = (columnName !== undefined)
    ? candidates.find(fmt => fmt.measure === columnName) || candidatesWithoutColumnNames[0]
    : candidatesWithoutColumnNames[0];

    return (found?.format || {}) as React.CSSProperties;
}

export const perfectSku = ({keyMap, skus}: ReportData) => {
    const data = Object.values(skus).map(
        (item) => {
            item.description = (item.description?.length || 0) + ''
            item.title = (item.title?.length || 0) + ''
            return {
                ...item
            }
        },
    )
    const res: Report = {
        conditions: [
            {}
        ],
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    mainCategory: {
                        type: 'level',
                        hierarchy: 'mainCategory',
                    },
                    subcategory1: {
                        type: 'level',
                        hierarchy: 'mainCategory',
                        level: 'subcategory1',
                        parent: 'mainCategory',
                    },
                    subcategory2: {
                        type: 'level',
                        hierarchy: 'mainCategory',
                        level: 'subcategory2',
                        parent: 'subcategory1',
                    },
                    subcategory3: {
                        type: 'level',
                        hierarchy: 'mainCategory',
                        level: 'subcategory3',
                        parent: 'subcategory2',
                    },
                    brand: {
                        type: 'string',
                    },
                    overallScore: {
                        type: 'number',
                    },
                    title: {
                        type: 'number',
                    },
                    description: {
                        type: 'number',
                    },
                    bullets: {
                        type: 'number',
                    },
                    imageCount: {
                        type: 'number',
                    },
                    imageResolutionScore: {
                        type: 'number',
                    },
                    videosCount: {
                        type: 'number',
                    },
                    documentsCount: {
                        type: 'number',
                    },
                    ratingScore: {
                        type: 'number',
                    },
                    ratingsCount: {
                        type: 'number',
                    },
                    richContentScore: {
                        type: 'number',
                    },
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: 'mainCategory',
                    caption: 'Main category',
                },
            ],
            columns: [],
            measures: [
                {
                    grandTotalCaption: 'SKUs (#)',
                    uniqueName: 'brand',
                },
                {
                    uniqueName: 'overallScore',
                    grandTotalCaption: 'Score',
                    caption: 'Score',
                    aggregation: "average"
                },
                {
                    uniqueName: 'title',
                    grandTotalCaption: 'Title count',
                    caption: 'Title count',
                    aggregation: "average"
                },
                {
                    uniqueName: 'description',
                    grandTotalCaption: 'Short Desc',
                    caption: 'Short Desc',
                    aggregation: "average"
                },
                {
                    uniqueName: 'bullets',
                    grandTotalCaption: 'Bullets',
                    caption: 'Bullets',
                },
                {
                    uniqueName: 'imageCount',
                    grandTotalCaption: 'Images',
                    caption: 'Images',
                },
                {
                    uniqueName: 'imageResolutionScore',
                    grandTotalCaption: 'Images res',
                    caption: 'Images res',
                    aggregation: "average"
                },
                {
                    uniqueName: 'videosCount',
                    grandTotalCaption: 'Videos',
                    caption: 'Videos',
                },
                {
                    uniqueName: 'documentsCount',
                    grandTotalCaption: 'Documents',
                    caption: 'Documents',
                },
                {
                    uniqueName: 'richContentScore',
                    grandTotalCaption: 'Rich content',
                    caption: 'Rich content',
                    aggregation: "average"
                },
                {
                    uniqueName: 'ratingScore',
                    grandTotalCaption: 'Ratings avg',
                    caption: 'Ratings avg',
                    aggregation: "average"
                },
                {
                    uniqueName: 'ratingsCount',
                    grandTotalCaption: 'Ratings count',
                    caption: 'Ratings count',
                    aggregation: "average"
                },
            ],
        },
    }
    return res;
}

const keyMapperFn = (keyName: string, map: {[key: string]: string}) => map[keyName] || keyName;

const getCommonData = (keyMap) => {

    const newFields = [
        "titleScore", 
        "bulletScore", 
        "shortDescriptionScore", 
        "imageScore", 
        'documentScore', 
        'videoScore', 
        'enhancedContentScore',
        'richContentScore',
        'reviewScore',
        'view360Score',
        'sentimentScore'
    ];
    
    const newFieldsDataDef = Object.assign({}, ...newFields.map(x => ({[x]: {
        type: 'number',
    }})));
    
    const commonData = {
        marketingScore: {
            type: 'number',
        },
        assetScore: {
            type: 'number',
        },
        overallScore: {
            type: 'number',
        },
        ratingScore: {
            type: 'number',
        },
        searchScore: {
            type: 'number',
        },
        richContentScore: {
            type: 'number',
        },
        ...newFieldsDataDef
    };
    
    const commonMeasures = [
        {
            grandTotalCaption: 'SKUs (#)',
            uniqueName: 'brand',
            format: "count"
        },
        {
            uniqueName: 'overallScore',
            grandTotalCaption: 'Health',
            caption: 'Health',
            aggregation: "average"
        },
        {
            uniqueName: 'marketingScore',
            grandTotalCaption: 'Marketing',
            caption: 'Marketing',
            aggregation: "average"
        },
        {
            uniqueName: 'titleScore',
            grandTotalCaption: 'Title',
            caption: 'Title',
            aggregation: "average"
        },
        {
            uniqueName: 'shortDescriptionScore',
            grandTotalCaption: 'Short Desc.',
            caption: 'Short Desc.',
            aggregation: "average"
        },
        {
            uniqueName: 'bulletScore',
            grandTotalCaption: 'Bullets',
            caption: 'Bullets',
            aggregation: "average"
        },
        {
            uniqueName: 'assetScore',
            grandTotalCaption: 'Assets',
            caption: 'Assets',
            aggregation: "average"
        },
        {
            uniqueName: 'imageScore',
            grandTotalCaption: 'Images',
            caption: 'Images',
            aggregation: "average"
        },
        {
            uniqueName: 'richContentScore',
            grandTotalCaption: 'Rich Content',
            caption: 'Rich Content',
            aggregation: "average"
        },
        {
            uniqueName: 'videoScore',
            grandTotalCaption: 'Videos',
            caption: 'Videos',
            aggregation: "average"
        },
        {
            uniqueName: 'documentScore',
            grandTotalCaption: 'Documents',
            caption: 'Documents',
            aggregation: "average"
        },
        {
            uniqueName: 'enhancedContentScore',
            grandTotalCaption: 'Enhanced Content',
            caption: 'Enhanced Content',
            aggregation: "average"
        },
        {
            uniqueName: 'view360Score',
            grandTotalCaption: '360 Views',
            caption: '360 Views',
            aggregation: "average"
        },
        {
            uniqueName: 'sentimentScore',
            grandTotalCaption: 'Sentiment',
            caption: 'Sentiment',
            aggregation: "average"
        },
        {
            uniqueName: 'ratingScore',
            grandTotalCaption: 'Ratings',
            caption: 'Ratings',
            aggregation: "average"
        },
        {
            uniqueName: 'reviewScore',
            grandTotalCaption: 'Reviews',
            caption: 'Reviews',
            aggregation: "average"
        },
        /* {
            uniqueName: 'searchScore',
            grandTotalCaption: 'Search',
            caption: 'Search',
            aggregation: "average"
        }, */
    ];

    // reducing the data to show more information under the 1MB limit
    const remapCommonMeasures = commonMeasures.map(item => mapValues(item, (value, key) => keyMapperFn(value, keyMap)))
    const remapCommonData =  mapKeys(commonData, (value, key: string) => keyMap[key] || key);
    const remapCommonConditionalFormats = defaultCommonConditionalFormats.map(obj => {
        if (obj.measure) {
            obj.measure = keyMapperFn(obj.measure, keyMap);
        }
        return obj;
    })

    return {commonData: remapCommonData, commonMeasures: remapCommonMeasures, commonConditionalFormats: remapCommonConditionalFormats }
}

export const brandCategory = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    const data = Object.values(skus);
    
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('brand', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap),
                    },
                    [keyMapperFn('mainCategory', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap), //brand
                        level: keyMapperFn('mainCategory', keyMap),
                        parent: keyMapperFn('brand', keyMap),
                    },
                    [keyMapperFn('subcategory1', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap),
                        level: keyMapperFn('subcategory1', keyMap),
                        parent: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory2', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap),
                        level: keyMapperFn('subcategory2', keyMap),
                        parent: keyMapperFn('subcategory1', keyMap),
                    },
                    [keyMapperFn('subcategory3', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap),
                        level: keyMapperFn('subcategory3', keyMap),
                        parent: keyMapperFn('subcategory2', keyMap),
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('brand', keyMap),
                    caption: 'Brand',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}

export const brandRetailer = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    const data = Object.values(skus);
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('brand', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('brand', keyMap),
                    },
                    [keyMapperFn('retailer', keyMap)]: {
                        type: 'string',
                        hierarchy: keyMapperFn('retailer', keyMap),
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('brand', keyMap),
                    caption: 'Brand',
                },
                {
                    uniqueName: keyMapperFn('retailer', keyMap),
                    caption: 'Retailer',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}

export const categoryRetailer = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    const data = Object.values(skus);
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('mainCategory', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory1', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory1', keyMap),
                        parent: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory2', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory2', keyMap),
                        parent: keyMapperFn('subcategory1', keyMap),
                    },
                    [keyMapperFn('subcategory3', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory3', keyMap),
                        parent: keyMapperFn('subcategory2', keyMap),
                    },
                    [keyMapperFn('retailer', keyMap)]: {
                        type: 'string',
                        hierarchy: 'Retailer',
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('mainCategory', keyMap),
                    caption: 'Main category',
                },
                {
                    uniqueName: keyMapperFn('retailer', keyMap),
                    caption: 'Retailer',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}

export const categoryBrand = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    //const numberKeys = Object.keys(commonData);
    const data = Object.values(skus);/* //.slice(0, 35);
    const data = dataOld.map(sku => {
        const newSku = {...sku};
        numberKeys.forEach(key => {
            const oldValue = newSku[key];
            if (oldValue !== undefined) {
                const newValue = parseFloat(oldValue);
                newSku[key] = isNaN(newValue) ? undefined : newValue;
            }
        });
        return newSku;
    }); */
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('mainCategory', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory1', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory1', keyMap),
                        parent: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory2', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory2', keyMap),
                        parent: keyMapperFn('subcategory1', keyMap),
                    },
                    [keyMapperFn('subcategory3', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('mainCategory', keyMap),
                        level: keyMapperFn('subcategory3', keyMap),
                        parent: keyMapperFn('subcategory2', keyMap),
                    },
                    [keyMapperFn('brand', keyMap)]: {
                        type: 'string',
                        hierarchy: 'Brand',
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('mainCategory', keyMap),
                    caption: 'Main category',
                },
                {
                    uniqueName: keyMapperFn('brand', keyMap),
                    caption: 'Brand',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}

export const retailerBrandCategory = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    const data = Object.values(skus);
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('retailer', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                    },
                    [keyMapperFn('brand', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('brand', keyMap),
                        parent: keyMapperFn('retailer', keyMap),
                    },
                    [keyMapperFn('mainCategory', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('mainCategory', keyMap),
                        parent: keyMapperFn('brand', keyMap),
                    },
                    [keyMapperFn('subcategory1', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory1', keyMap),
                        parent: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory2', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory2', keyMap),
                        parent: keyMapperFn('subcategory1', keyMap),
                    },
                    [keyMapperFn('subcategory3', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory3', keyMap),
                        parent: keyMapperFn('subcategory2', keyMap),
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('retailer', keyMap),
                    caption: 'Retailer',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}

export const retailerCategoryBrand = ({keyMap, skus}: ReportData) => {
    const {commonMeasures, commonData, commonConditionalFormats } = getCommonData(keyMap);
    const data = Object.values(skus);
    const res: Report = {
        conditions: commonConditionalFormats,
        formats: [
            ...commonNumberFormats
        ],
        dataSource: {
            dataSourceType: 'json',
            data: [
                {
                    [keyMapperFn('retailer', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                    },
                    [keyMapperFn('mainCategory', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('mainCategory', keyMap),
                        parent: keyMapperFn('retailer', keyMap),
                    },
                    [keyMapperFn('subcategory1', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory1', keyMap),
                        parent: keyMapperFn('mainCategory', keyMap),
                    },
                    [keyMapperFn('subcategory2', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory2', keyMap),
                        parent: keyMapperFn('subcategory1', keyMap),
                    },
                    [keyMapperFn('subcategory3', keyMap)]: {
                        type: 'level',
                        hierarchy: keyMapperFn('retailer', keyMap),
                        level: keyMapperFn('subcategory3', keyMap),
                        parent: keyMapperFn('subcategory2', keyMap),
                    },
                    [keyMapperFn('brand', keyMap)]: {
                        type: 'string',
                        hierarchy: 'Brand',
                    },
                    ...commonData
                },
                ...data,
            ],
        },
        slice: {
            rows: [
                {
                    uniqueName: keyMapperFn('retailer', keyMap),
                    caption: 'Retailer',
                },
                {
                    uniqueName: keyMapperFn('brand', keyMap),
                    caption: 'Brand',
                },
            ],
            columns: [],
            measures: commonMeasures,
        },
    }
    return res
}
