import { makeAutoObservable } from "mobx";
import * as CSApiClient from "../libraries/Util/apiClient";
import {
    RuleBuilderAttributes,
    TeamRules,
} from '../pages/RuleBuilder/datamodels';

interface Rules {
    rulesResult: {
        [flagPriority: string]: {
            [flagMessage: string]: {
                [skuId: string]: {
                    productName: string;
                    link: string;
                }
            }
        }
    }
    rulesResultVisibility: {
        [flagPriority: string]: {
            [flagMessage: string]: {
                [skuId: string]: boolean
            }
        }
    }
}

export class RulesStore {
    rules: Rules;
    teamRules?: TeamRules[];
    dataLoading: boolean;
    dataLoadingStatus: 'loading' | 'success' | 'err';
    attributes: RuleBuilderAttributes;

    constructor() {
        makeAutoObservable(this)

        this.rules = {
            rulesResult: {
                'Critical': {},
                'Warning': {},
                'Indicator': {},
            },
            rulesResultVisibility: {},
        };
        this.teamRules = [];
        this.dataLoading = true;
        this.dataLoadingStatus = 'loading';
        this.attributes = {};
    }


    getRulesList = async (teamId?: string) => {
        try {
            const { data }: any = await CSApiClient["API_CALL_getRulesReportInfo"](teamId)
            const results = data
            const resultsVisibility = data

            this.rules = {
                rulesResult: results,
                rulesResultVisibility: resultsVisibility,
            }
            this.dataLoading = false
            this.dataLoadingStatus = 'success'
        } catch (err) {
            this.dataLoadingStatus = 'err'
        }
    }

    getAttributesList = async (teamId?: string) => {
        try {
            const { data }: any = await CSApiClient["API_CALL_getRulesAttributes"](teamId);
            this.attributes = data;
        } catch (err) {
            this.attributes = {};
        }
    }

    getTeamRulesList = async (teamId?: string) => {
        try {
            const { data: { data } }: any = await CSApiClient["API_CALL_GetTeamRules"](teamId)
            this.teamRules = this.handleTransformData(data)
            this.dataLoading = false
            this.dataLoadingStatus = 'success'
        } catch (err) {
            this.dataLoadingStatus = 'err'
        }
    }

    handleTransformData = (data) => {
        return Object.keys(data).map(key => {
            const auditRule = data[key].auditRules
            const timestamp = data[key].timestamp
            return {
                owner: auditRule.author,
                applyTo: auditRule.applyTo,
                msg: auditRule.flagMessage,
                group: auditRule.group,
                source: auditRule.retailer,
                ruleId: auditRule.ruleId,
                results: auditRule.results,
                status: auditRule.ruleStatus,
                flagAs: auditRule.flagPriority,
                operations: auditRule.operations,
                testingFor: auditRule.categories,
                isSystemRule: auditRule.isSystemRule,
                timestamp: timestamp,
                updateClicked: false,
                library: undefined,
            }
        })
    };
}

export const rulesStore = new RulesStore()
