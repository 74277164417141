import * as React from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

interface VideoPlayerProps {
    playerOptions: videojs.PlayerOptions;
    onPlayerReady?: () => void;
    videoTagProps?: React.VideoHTMLAttributes<HTMLVideoElement>
}

class VideoPlayer extends React.Component<VideoPlayerProps, {}> {

    private videoNode: HTMLVideoElement | null;
    private player: videojs.Player;

    constructor(props: VideoPlayerProps) {
        super(props);

        this.state = {
        };
    }

  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props.playerOptions, this.props.onPlayerReady);
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (<div data-vjs-player={true}>
        <video ref={ node => this.videoNode = node } className="video-js" {...this.props.videoTagProps} />
    </div>);
  }
}

export default VideoPlayer;