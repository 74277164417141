import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        background: '#f2f2f2',
        border: '1px solid lightgrey',
        borderRadius: 5,
        paddingTop: 15,
        color: '#464646',
    },
    section: {
        height: '100%',
        paddingLeft: 15,
        paddingRight: 15,
    },
    sectionWrapper: {
        padding: 20,
        backgroundColor: 'white',
        marginTop: 20,
        marginBottom: 20,
        border: "1px solid #e4e4e4 !important",
        borderRadius: 3
    },
    sectionTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 10,
        cursor: 'pointer',
    },
    title: {
        marginBottom: 0,
    },
    approved: {
        borderRadius: '50%',
        backgroundColor: '#00DF00',
        height: 20,
        width: 20,
        marginRight: 5,
    },
    thinFont: {
        fontWeight: 500,
    },
    itemWrapper: {
        marginTop: 30,
    },
    itemWrapperChild: {
        height: "100%",
    },
    itemHeaderSection: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingBottom: 10,
        height: 80,
    },
    progressCardsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    itemTitleSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
    },
    itemTitle: {
        marginBottom: -5,
        fontWeight: 800,
    },
    itemSubTitle: {
        marginTop: 0,
    },
    content: {
        borderBottom: '1px solid lightgrey',
        paddingBottom: 10,
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 90,
        overflow: 'auto',
    },
    contentDescription: {
      height: 250,
      maxHeight: 250
    },
    contentBullets: {
        height: 'auto',
        borderBottom: 'none',
    },
    contentMedia: {
        height: 225,
    },
    warningSection: {
        borderBottom: '1px solid lightgrey',
        paddingBottom: 10,
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    warningPlaceHolder: {
        height: 41,
    },
    accuracyTotal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    accuracyTotalNumber: {
        fontWeight: 800,
        fontSize: 20,
        textAlign: 'center',
    },
    columnTitle: {
      display: 'flex',
      flexDirection: 'row',
        justifyContent: 'space-between'
    },
    accuracySubtitle: {
        marginTop: 5,
        fontWeight: 900,
        fontSize: 12
    },
    bulletsWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    warningSectionCount: {
      color: 'red',
      fontSize: 20,
      fontWeight: 800,
      marginRight: 15,
    },
    bulletsWarningSection: {
        borderBottom: '1px solid lightgrey',
        paddingBottom: 10,
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    bulletsWarningPlaceHolder: {
        height: 41,
        marginBottom: 10,
    },
    specsTable: {
        height: "100%",
        maxHeight: 650
    }
}));

export default useStyles;
