import { makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) => ({
    root: {
        marginRight: 85,
        ['@media (max-width: 1280px)']: { // eslint-disable-line no-useless-computed-key
            marginRight: 40
        },
        ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            margin: 0,
            width: 530
        },
        ['@media only screen and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
            width: 670,
        },
    },
    iconWrapper: {
        display: 'flex',
        margin: '0 20px',
        ['@media only screen and (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
            margin: 0
        },
    },
    closeIcon: {
        fontSize: '2rem',
    },
    formulaIcon: {
        fontSize: '2.8rem',
        margin: '0 20px',
        ['@media only screen and (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
            margin: 0
        },
    },
    formulaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 625,
        margin: 'auto',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(4),
    },
    description: {
        marginTop: `${theme.spacing(4)}px`,
        color: 'black',
        fontSize: '3rem',
        fontFamily: 'Caveat',
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '2rem',
        },
    },
}));

export default styles;
