import { makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2, 4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: 500,
        ['@media only screen and (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            paddingRight: 0,
            width: 435,
        },
        ['@media only screen and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: 0,
            width: '100%'
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: 0,
        },
    },
    title: {
        color: theme.palette.primary.main,
        margin: 'initial',
        fontWeight: 800,
        fontSize: '2.2rem',
        ['@media only screen and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
            width: 670,
        },
        ['@media only screen and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1.7rem',
            width: '100%'
        },
    },
    subTitle: {
        margin: 'initial',
        color: 'black',
        fontSize: '1.4rem',
        fontWeight: 500,
        ['@media only screen and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
            width: 670,
        },
        ['@media only screen and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        },
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1.3rem',
            width: '100%'
        },
    },
    description: {
        marginTop: `${theme.spacing(4)}px!important`,
        color: 'black',
        fontSize: '1.6rem',
        fontStyle: 'italic'
    },
}));

export default styles;
