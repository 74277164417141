import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';

export function capitalizeFirstLetter(str: string): string {
  return str ? str[0].toUpperCase() +  str.slice(1) : '';
}

const escapeRegExp = (string) => {
  return string
  .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
  .replace(/-/g, '\\x2d'); // $& means the whole matched string
}

export const escapedRegex = (pattern: string, flags?: string) => new RegExp(escapeRegExp(pattern), flags);

export const toTitleCase = (str: string) => {
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}


export const htmlStringToReact = (htmlStr: string) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: Element) => {
        if (domNode.name === 'ul') {
            return <ul className="bullets-list">{domToReact(domNode.children, options)}</ul>
        }
    }
  };

  return parse(htmlStr, options) as JSX.Element;
}