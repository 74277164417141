import axios from 'axios';
import {API_URL, CoreUserAccount} from '../../types/datastructures';

export function API_CALL_registerNewAccount(options: {
    userId: string;
    inviteId?: string;
    refId?: string;
    confirmed?: boolean;
}) {
    const url = `${API_URL}/registerNewAccount`;

    return axios.post(url, {...options, confirmed: !!options.confirmed});
}

export function API_CALL_saveUserAccount(user: CoreUserAccount) {
    return axios.post(`${API_URL}/user-save`, user)
}

export function API_CALL_updateUserAccount(user: CoreUserAccount) {
    return axios.post(`${API_URL}/auth/update`, user)
}