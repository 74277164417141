import * as auth0 from 'auth0-js';
import { SITE_URL, multiAndCompare, RandomId,  API_URL_PUBLIC, SITE_URL_PUBLIC } from '../types/datastructures';
import { checkIfNewUser } from './newUserCheck';

interface webAuthUserProfile {
    at_hash: string;
    aud: string;
    exp: number
    family_name: string;
    given_name: string;
    iat: number;
    iss: "https://contentstatus.auth0.com/";
    locale: string;
    name: string;
    nickname: string;
    nonce: string;
    picture: string;
    sub: string;
    updated_at: string;
}

interface internalData {
    authResultProfile?: webAuthUserProfile;
    idToken?: string;
    expiresAt?: number;
    profile: auth0.Auth0UserProfile
}

interface internalDataCache {
    authResult: auth0.Auth0DecodedHash
    profile: auth0.Auth0UserProfile
}

const isTimeExpired = (expiresAt: number) => (new Date().getTime() >= expiresAt);

// const namespace = 'https://twd-contentstatus.firebaseapp.com';

// const getPropOfType = <T>(o: object, k: string): T => o[k] as any as T;
// const namespacedGet = <T>(o: object, k: string) => getPropOfType<T>(o, namespace+k);

// tslint:disable:member-ordering
export class Auth {
    private webAuth: auth0.WebAuth;
    // private profile?: webAuthUserProfile;
    // private idToken?: string;
    // private accessToken?: string;
    // private expiresAt?: number;

    private data?: internalData;
    // private registered: boolean;

    private customRedirectUriSearch: string;

    constructor() {
        // this.registered = false;
        this.customRedirectUriSearch = '';
        const dataCache = this.getDataCache();
        if (dataCache && !isTimeExpired(dataCache.authResult.idTokenPayload.exp! * 1000)) {
            this.setSession(dataCache);
        } else {
            const { pathname, search } = window.location;
            this.customRedirectUriSearch = `?redir=${encodeURIComponent(pathname+search)}`;
        }
        this.webAuth = new auth0.WebAuth({
            // the following three lines MUST be updated
            domain: 'contentstatus.auth0.com',
            clientID: 'dTrmvFb9nvtvIJox42I1LjzcPGiQE362',
            redirectUri: `${SITE_URL}/callback${this.customRedirectUriSearch}`,
            responseType: 'token id_token',
            scope: 'openid profile email'
        });
    }

    getProfile = () => this.data ? this.data.profile : undefined;

    getIdToken = () => this.data ? this.data.idToken : undefined;

    private getExpiresAt = () => this.data ? this.data.expiresAt : undefined;

    isAuthenticated = () => {
        const expiresAt = this.getExpiresAt()
        return !!expiresAt && !isTimeExpired(expiresAt)
    }

    /* userRegistered = () => { 
        this.registered = true;
    } */

    isNewUser = async () => {
        return await checkIfNewUser(this.data.profile.sub);
    }//(this.data ? namespacedGet<boolean>(this.data.profile, 'isNewUser') : false) // || !this.registered

    redirNewUser = () => {
        if (multiAndCompare(window.location.pathname, (a, b) => a!==b, '/callback', '/newuser-registration', '/betatester-signup') && this.isNewUser()) {
            // console.log('Redirected here: Auth.redirNewUser', window.location.pathname)
            window.location.replace('/newuser-registration');
        }
    }

    signIn = () => {
        this.webAuth.authorize();
    }

    customSignIn = () => {
        const auth0Params = {state: RandomId(), nonce: RandomId()};
        localStorage.setItem("auth0Params", JSON.stringify(auth0Params));
        const signInUrl = `${API_URL_PUBLIC}/auth/auth0authorize?site_url=${
            SITE_URL
        }&custom_redirect_uri=${
            this.customRedirectUriSearch
        }&state=${
            auth0Params.state
        }&nonce=${
            auth0Params.nonce
        }`
        window.location.assign(signInUrl);
    }

    signInIfNot = () => {
        if (!this.isAuthenticated() && window.location.pathname !== '/callback') {
            //this.signIn();
            this.customSignIn();
            return false;
         } else {
            return true;
         }
    }

    private auth0UserInfo = async (authResult: auth0.Auth0DecodedHash) => {
        return new Promise<{
            profile: auth0.Auth0UserProfile
        }>((resolve, reject) => {
            this.webAuth.client.userInfo(authResult.accessToken, (err, profile) => {
                // This method will make a request to the /userinfo endpoint
                // and return the user object, which contains the user's information,
                // similar to the response below.
                if (err) { 
                    reject(err); 
                } else {
                    resolve({profile});
                }
            });
        });
    }

    handleAuthentication = () => new Promise<boolean>((resolve, reject) => {
        if (this.webAuth) {
            const auth0ParamsString = localStorage.getItem("auth0Params");
            const auth0Params = auth0ParamsString ? JSON.parse(auth0ParamsString) : {};
            
            this.webAuth.parseHash(auth0Params, async (err, authResult) => {
                if (err) { 
                    reject(err); 
                } else if (!authResult || !authResult.accessToken || !authResult.idToken) {
                    reject(err);
                } else {
                    // tslint:disable-next-line:no-console
                    // console.log(authResult)
                    const {profile} = await this.auth0UserInfo(authResult);
                    
                    this.setDataCache({authResult, profile});
                    this.setSession({authResult, profile});
                    resolve(this.isNewUser());

                    /* this.webAuth.client.userInfo(authResult.accessToken, (err1, profile) => {
                        // This method will make a request to the /userinfo endpoint
                        // and return the user object, which contains the user's information,
                        // similar to the response below.
                        if (err1) { 
                            reject(err1); 
                        } else {
                            this.setDataCache({authResult, profile});
                            this.setSession({authResult, profile});
                            resolve(this.isNewUser());
                        }
                    }); */
                }
            });
        } else {
            reject("Auth0 WebAuth not initialized");
        }
    });

    refreshAuth0UserProfile = async () => {
        const {authResult} = this.getDataCache();

        const {profile} = await this.auth0UserInfo(authResult);

        this.setDataCache({authResult, profile});
        this.setSession({authResult, profile});

        return profile;
    }
    
    private getDataCache = () => {
        const dataCache = localStorage.getItem('auth0_dataCache'); 
        return (dataCache) ? JSON.parse(dataCache) as internalDataCache : undefined;
    }

    private setDataCache = (dataCache: internalDataCache) => localStorage.setItem('auth0_dataCache', JSON.stringify(dataCache));

    private deleteDataCache = () => localStorage.removeItem('auth0_dataCache');

    private setSession = (dataCache: internalDataCache) => {
        // tslint:disable-next-line:no-console
        // console.log(dataCache)
        // tslint:disable-next-line:no-console
        // console.log(this.webAuth)
        // this.idToken = authResult.idToken;
        // this.accessToken = authResult.accessToken;
        // this.profile = authResult.idTokenPayload;
        // Set the time that the Access token will expire at
        // this.expiresAt = authResult.idTokenPayload.exp * 1000;

        this.data = {
            idToken: dataCache.authResult.idToken,
            expiresAt: dataCache.authResult.idTokenPayload.exp * 1000,
            authResultProfile: dataCache.authResult.idTokenPayload,
            profile: dataCache.profile
        }
        // navigate to the home route
        // history.replace('/home');
    }

    private deleteSession = () => {
        // clear id token, profile, and expiration
        /* this.idToken = undefined;
        this.profile = undefined;
        this.expiresAt = undefined; */

        this.data = undefined
    }

    signOut = (federated: boolean = false) => {
        this.deleteSession();
        this.deleteDataCache();
        this.webAuth.logout({
            returnTo: SITE_URL_PUBLIC === SITE_URL ? SITE_URL : `${SITE_URL_PUBLIC}/auth-logout?callback=${SITE_URL}`,
            //returnTo: `${API_URL_PUBLIC}/auth/auth0logout?callback=${SITE_URL}`,
            federated
        });
    };
}

export const AuthClientService = new Auth();