import * as React from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import {UserAccountObj, GetUser, SITE_URL} from './libraries/types/datastructures';
import {AuthClientService} from './libraries/Auth'
import { querystringparser } from './libraries/Util';
import { JobService, TeamJobData } from './libraries/Util/jobService';
import { GlobalStateAppContextProvider } from './libraries/types/ReportUp';
import { ThirdPartyScriptService } from './libraries/Util/thirdPartyScripts';
import LoadingScreen from './components/LoadingScreen';
import PublicLegalPage from './components/Legal/public';
import BetaLock from './components/BetaLock';
import NewUserRegistration from './components/NewUser/NewUserRegistration';
import CatalogProduct from './pages/CatalogProduct';
import { API_CALL_confirm_email } from './libraries/Util/apiClient';
import EmptyDialog from "./components/Dialogs";
import {ContentStatusDataService} from "./libraries/DataService";
import ImageReport from './components/ImageReport';
import DirectCheckout from './pages/DirectCheckout';
import Snackbar from '@material-ui/core/Snackbar';
import PermScanWifiOutlinedIcon from '@material-ui/icons/PermScanWifiOutlined';
import { RootSiteRoutes } from './routes';
import { ThemeProvider } from '@material-ui/core';
import {GlobalStyles} from './styling';
import theme from './theme';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './App.scss';
import Billing from './pages/Billing';
import EmailVerification from './components/NewUser/EmailVerification';
import DigitalShelf from './components/DigitalShelf';
import { initLocalStorageService } from './pages/Catalog/data';
import { TeamSettingsDataService } from './pages/Profile/TeamAccountSettings';

interface AppProps {
  router: RouteComponentProps<any>;
  reportActiveUser: (activeUser: UserAccountObj) => void;
}

export interface State {
  authCallbackHandled?: true;
  authStateProcessed?: true;
  activeUser?: UserAccountObj;
  isNewUser?: boolean;
  teamJobData: TeamJobData;
  credits?: { teamId: string; balance: number; asofTs: number };
  offline: boolean;
  partnerLogo?: "idea4industry"
}

class App extends React.Component<AppProps, State> {

  private unregisterFns: Array<() => void>;

  private startProcessAuth: boolean;
  private startHandleAuth: boolean;

  constructor(props: AppProps) {
    super(props);
    this.unregisterFns = [];
    this.state = {
      //activeUser: InitUserAccountObj(AuthClientService),
      teamJobData: {
        teamJobs: {},
        dataLoading: true,
        teamId: ''
      },
      offline: false
    };

    //console.log("@constructor", {state: this.state})

    this.handleOnline = this.handleOnline.bind(this);
    this.handleOffline = this.handleOffline.bind(this);
  }

  initAuth = () => {
    // if (publicRouteRoots.indexOf(window.location.pathname.split("/")[1]) === -1) {
      return AuthClientService.signInIfNot();
    // }
  }

  handleOnline = () => {
      this.setState({ offline: false })
  }

  handleOffline = () => {
    this.setState({ offline: true })
  }

  togglePartnerLogo = (onlyToggleIfNot?: boolean) => {
    const val = 'idea4industry'
    const partnerLogo = this.state.partnerLogo === val ? undefined : val;
    if ((onlyToggleIfNot && partnerLogo) || (onlyToggleIfNot === undefined)) {
      this.setState({partnerLogo});
    }
  }

  // Listen to the Firebase Auth state and set the local state.
  async componentDidMount() {
    window['__xsx'] = this.togglePartnerLogo;
    //console.log("@componentDidMount")
    window.addEventListener('offline', this.handleOffline)

    window.addEventListener('online', this.handleOnline)

    /* const activeUser =  await GetUser(AuthClientService)
    this.setState({
      activeUser
    });
    if (!AuthClientService.isNewUser()) {
      this.props.reportActiveUser(activeUser);
    } */



    this.unregisterFns.push(this.props.router.history.listen(() => {
      const Tooltip = window['Tooltip'];

      if (Tooltip && Tooltip.API && Tooltip.API.refresh) {
        Tooltip.API.refresh();
      }
    }));
  }

  handleAuthentication = async (router: RouteComponentProps<any>) => {
    if(!this.startHandleAuth) {
      this.startHandleAuth = true;
      if(!this.state.authCallbackHandled) {
        const customLocation = router.location;

        const redir = (backupUrl: string = "/") => {
          const parsedLocationSearch = querystringparser(customLocation.search);

          const newUrl = (parsedLocationSearch.redir && parsedLocationSearch.redir[0]) ? decodeURIComponent(parsedLocationSearch.redir[0]) : backupUrl;

          router.history.replace(newUrl);
        }

        if (/access_token|id_token|error/.test(customLocation.hash)) {
          try {
            const isNewUser = await AuthClientService.handleAuthentication();

            await this.processLoginState(router, isNewUser, redir);
            // redir();
          } catch (e) {
            redir();
          }
        } else {
          redir();
        }

        this.setState({authCallbackHandled: true});
      }
    }
  }

  detectOtherUserId = (router: RouteComponentProps<any>) => {
    const customLocation = router.location;

    //console.log(customLocation);
    if (customLocation.pathname === "/admin/user-view") {

      const parsedLocationSearch = querystringparser(customLocation.search);

      const otherUserId = (parsedLocationSearch.userId && parsedLocationSearch.userId[0])
      ? decodeURIComponent(parsedLocationSearch.userId[0])
      : undefined;

      return otherUserId;
    } else {
      return undefined;
    }
  }

  processLoginState = async (
    router: RouteComponentProps<any>,
    isNewUser: boolean,
    redirFn?: (backupUrl?: string) => void
  ) => {
    //console.log("@processLoginState")
    if(!this.startProcessAuth) {
      this.startProcessAuth = true;

      if (!this.state.authStateProcessed) {
        const otherUserId = this.detectOtherUserId(router);

        const activeUser = await GetUser(AuthClientService, otherUserId);

        const newUserFn = () => {
          // activeUser.auth0Client.redirNewUser();
          // if (['/callback', '/newuser-registration', '/betatester-signup'].indexOf(router.location.pathname) === -1) {
            // console.log('Redirected here: Auth.redirNewUser', window.location.pathname)
          if(redirFn) {
            redirFn('/newuser-registration')
          } else {
            if(router.location.pathname !== '/newuser-registration') {
              const parsedLocationSearch = querystringparser(router.location.search);

              const newUrl = (parsedLocationSearch.redir && parsedLocationSearch.redir[0]) ? decodeURIComponent(parsedLocationSearch.redir[0]) : '/newuser-registration';

              router.history.replace(newUrl);
            }
          }

          this.setState({isNewUser: true });
        }

        let partnerLogo;

        if (isNewUser) {
          newUserFn()
        } else {
          if (activeUser.isSignedIn) {
            //console.log("@processLoginState", "signed in")
            if (activeUser.user.core && activeUser.user.core.userId) {
              if (activeUser.profile.email_verified) {
                try {
                  this.props.reportActiveUser(activeUser);
                  this.setupJobService(activeUser);
                  initLocalStorageService(activeUser.user.core.userId, activeUser.user.core.teamId)
                  ContentStatusDataService.initTeam(activeUser.user.core.teamId);
                  partnerLogo = (await TeamSettingsDataService.get(activeUser.user.core.teamId))?.branding?.partnerLogo;
  
                  if (redirFn) {
                    redirFn();
                  }
                } catch(error ) {
                  console.error('Error: ', error)
                  this.setState({ credits: {
                    teamId: "",
                    balance: 0,
                    asofTs: 0
                  }});
                }
              }
            } else {
              newUserFn()
            }
          } else {
            //console.log("@processLoginState", "not signed in")
          }
        }

        try {
          return this.setState({activeUser, authStateProcessed: true, partnerLogo });
        } catch (e) {
          // console.log(e);
          // debugger
        }
      }
    }
  }

  setupJobService = (activeUser: UserAccountObj) => {
    const jobSvc = new JobService({activeUser});

    this.unregisterFns.push(jobSvc.registerTeamJobsListener(
      teamJobData => this.setState( ({ teamJobData }) ),
      console.log
    ));

  }

  signOut = () => {
    AuthClientService.signOut();
    // this.setState({activeUser: InitInactiveUser(auth0Client), authStateProcessed: true });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterFns.forEach(fn => fn());
  }

  publicRoutes = () => {
    //console.log("@publicRoutes")
    return <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Switch>
        <Route exact path="/notrandom/ds-print-test" render={props => <DigitalShelf 
          {...(props as any)} print={true}/>} 
        />
        <Route exact path="/notrandom/print-test" component={CatalogProduct} />
        <Route path="/legal" render={router => <PublicLegalPage router={router} />} />
        <Route path="/support" component={() => { 
            window.location.href = 'https://contentstatus.ladesk.com/'; 
            return null;
        }} />
        <Route path="/qlImage" render={router => <ImageReport router={router} />} />
        <Route path="/callback"  render={this.authCallbackRoute} />
        <Route path="/billing-plans" render={() => {
          ThirdPartyScriptService.setupChargebeeScript();
          return <Billing publicVersion={true} />
        }} />
        <Route exact path='/public/digital-shelf' render={props => <DigitalShelf 
          {...(props as any)} public={true} />} 
        />
        <Route path="/auth-logout" render={router => {
          const parsedLocationSearch = querystringparser(router.location.search);

          const backupUrl = SITE_URL;

          const newUrl = (parsedLocationSearch.callback && parsedLocationSearch.callback[0]) 
            ? decodeURIComponent(parsedLocationSearch.callback[0]) 
            : backupUrl;

          window.location.replace(newUrl);
          return null;
        }} />
        <Route
          exact={true}
          path="/directCheckout"
          render={(props: RouteComponentProps<any>) => <DirectCheckout router={props} /> }
        />
        {/* <Route path="/login" render={router => this.loginPage()} /> */}
        <Route path="/*"  render={this.betaLock} />
      </Switch>
    </ThemeProvider>
  }

  /* loginPage = () => {
    const isSignedIn = this.initAuth();
    console.log("@loginPage", JSON.parse(JSON.stringify(window.location)))
    return isSignedIn ? <Redirect to="/" /> : null;
  } */

  displayUnconfirmedPopup = () => {
    // return <div>Not confirmed email</div>;
    return <EmptyDialog title="Almost there...">
      <div>
        <p>Now we just need to confirm your email address.</p>
        <p>
          Can't find the confirmation email? Please, check your SPAM folder or click to {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#">resend</a>
          } it.
        </p>
      </div>
    </EmptyDialog>;
  }

  privateRoutes = (router: RouteComponentProps<any>) => {
    //console.log("@privateRoutes")
    const { isNewUser, credits } = this.state;

    const routes = (isNewUser)
    ? this.newUserRoutes(router)
    : this.state.activeUser.isSignedIn && this.state.activeUser.profile.email_verified
      ? this.existingUserRoutes()
      : <EmailVerification />

    return <GlobalStateAppContextProvider value={{
      teamJobData: this.state.teamJobData, activeUser: this.state.activeUser, signOut: this.signOut, credits: credits, partnerLogo: this.state.partnerLogo
    }}>
        {routes}
    </GlobalStateAppContextProvider>
  }

  newUserRoutes = (router: RouteComponentProps<any>) => {
    const registrationRender = () => {
      return <NewUserRegistration />
    }

    return <Switch>
        {/*<Route path="/emailConfirmation" render={() => { return 'Hello World!'} } />*/}
        <Route
            exact={true}
            path="/betatester-signup"
            render={registrationRender}
        />
        <Route
            exact={true}
            path="/newuser-registration"
            render={registrationRender}
        />
        <Route
            path="/*"
            render={() => {
              // window.location.replace('/newuser-registration');
              return <Redirect to={'/newuser-registration'+router.location.search} />; // <div>Hi!</div>;
            }}
        />
    </Switch>
  }

  renderEmailConfirmation = () => {
    const { emailConfirmId } = querystringparser(window.location.search);
    if (emailConfirmId && emailConfirmId[0]) {
      API_CALL_confirm_email(emailConfirmId[0]).then(res => {
        if (res.status >= 200 && res.status < 300) {
          window.location.replace('/catalog')
        }
      })
    }

    return <EmptyDialog title="Token Expired">
      <div>
        <p>Invalid confirmation URL</p>
        <p>Back to <a href="/catalog">Catalog</a></p>
      </div>
    </EmptyDialog>
  }

  emailConfirmationRoutes = () => {
    return <Switch>
      <Route path="/emailConfirmation" render={(rootRouteProps: RouteComponentProps<any>) => this.renderEmailConfirmation() } />
      <Route path="/*" render={(rootRouteProps: RouteComponentProps<any>) => this.displayUnconfirmedPopup() }/>
    </Switch>
  }

  existingUserRoutes = () => {

    /* if (this.state.activeUser.isSignedIn &&
        this.state.activeUser.user.core &&
        !this.state.activeUser.user.core.isEmailConfirmed) {
      return this.emailConfirmationRoutes();
    } */

    ThirdPartyScriptService.setupThirdPartyScripts(this.state.activeUser as any);

    return <ThemeProvider theme={theme}>
    <GlobalStyles />
    <RootSiteRoutes />
  </ThemeProvider>
  }

  authCallbackRoute = (router: RouteComponentProps<any>) => {
    this.handleAuthentication(router);
    return <LoadingScreen />
  }

  betaLock = (router: RouteComponentProps<any>) => {
    const { authStateProcessed, activeUser } = this.state;
    //console.log("@betaLock", { authStateProcessed, activeUser })
    this.processLoginState(router, false);

    return (authStateProcessed && activeUser.isSignedIn === false)
    ? <BetaLock router={router} signIn={this.initAuth}/>
    : null;
  }

  render() {

    const { authStateProcessed, activeUser} = this.state;
    //console.log("app", new Date().valueOf(), { authStateProcessed, activeUser})

    return (<>
    {/* FIXME */}
    {/* <Offline> */}
      <Snackbar 
        open={this.state.offline} 
      >
          <div
            style={{
              padding: '15px 30px',
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottom: '2px solid indigo',
              boxShadow: '0px 0px 16px -4px rgba(0,0,0,0.81)',
            }}
          >
            <PermScanWifiOutlinedIcon fontSize='large' />
            <div style={{ marginLeft: '20px' }}>
              Please check your network.<br />
              Connection to the Internet was lost.
            </div>
          </div>
      </Snackbar>
    {/* </Offline> */}
    <Switch>
      <Route path="/*" render={(rootRouteProps: RouteComponentProps<any>) => (authStateProcessed && activeUser.isSignedIn)
      ? this.privateRoutes(rootRouteProps)
      : this.publicRoutes() }/>
    </Switch>
    </>)
  }

}

export const AppWithRouter: React.FunctionComponent<{reportActiveUser: (activeUser: UserAccountObj) => void}> = (props) => <Switch>
  <Route render={router => <App {...{router, ...props}} />} />
</Switch>

export default App;
