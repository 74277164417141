import * as React from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Route } from 'react-router-dom';
import './style.scss'; 


type CSUI_ButtonProps = {
    onClick?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void
    color?: 'purple' | 'grey' | 'green' | 'blue' | 'red' | 'lightgrey' | 'lightred' | 'none'
    className?: string
    active?: boolean
    outline?: boolean
    slim?: boolean
    label?: string | number | JSX.Element
    style?: React.CSSProperties
    flat?: boolean,
    disabled?: boolean,
    rounded?: boolean,
    icon?:boolean
    type?: 'button' | 'submit' | 'reset'
}

export const CSUIButton: React.FC<CSUI_ButtonProps> = (props) => {
    const {rounded,active, className, children, label, slim, style, color, outline, flat, disabled,icon, type, ...restProps} = props;
    
    const classes = ["csui_button"];
    switch (color) {
        case 'green':
        case 'grey':
        case 'blue':
        case 'red':
        case 'purple':
        case 'lightgrey':
        case 'lightred':
        default: {
            const colorValue = (color) ? color : 'purple';
            classes.push(`csui_${colorValue}`);
        } break;
    }
    if (flat === undefined || flat === true) {
        classes.push("csui_button_flat");
    }
    if (rounded === undefined || rounded === true) {
        classes.push("csui_button_rounded");
    }
    if (icon === true) {
        classes.push("csui_button_icon");
    }
    if (outline) {
        classes.push("csui_button_outlined");
    }
    if (slim) {
        classes.push("csui_button_slim");
    }
    if (disabled) {
        classes.push("csui_button_disabled");
    }
    if (className) {
        classes.push(className);
    }

    const customProps = {
        type: type === undefined ? 'button' : type,
        disabled,
        active,
        // outline,
        className: classes.join(" "),
        // ...(colorValue ? {color: colorValue} : {}),
        style: {
            /* fontFamily: "'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            fontWeight: 700,
            fontSize: '1rem',
            textTransform: 'none',
            margin: "0",
            marginRight: 4, */
            // borderRadius: (rounded || rounded === undefined) ? 5 : 0,
            // ...(slim ? {padding: ".46em 1em"} : {padding: "0.8rem 2.14rem"}),
            // ...(outline ? {borderWidth: 1} : {}),
            ...style
        } as React.CSSProperties
    };
    return <Button {...restProps} {...customProps} >{label || children}</Button>
}

export const CSUIButtonLink: React.FunctionComponent<Omit<CSUI_ButtonProps, 'onClick'> & {to: string}> = (props) => {
    const {to, ...restProps} = props;

    return <Route path="/*" render={props => 
        <CSUIButton {...restProps} onClick={() => props.history.push(to)} />
    } />
}

type CSUI_ButtonCloseXProps = {
    onClick?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    topRight?: boolean;
}

export const CSUIButtonCloseX: React.FC<CSUI_ButtonCloseXProps> = React.memo((props) => {
    const {style, onClick, className, disabled, topRight} = props;
    const classes = ['csui_buttonCloseX'];

    if (className) {
        classes.push(className);
    }

    const customProps = {
        className: classes.join(" "),
        ...((disabled !== undefined) ? {disabled} : {}),
        ...((onClick) ? {onClick} : {}),
        style: { 
            ...((style !== undefined) ? style : {})
        }
    };

    const btn = <Button
        startIcon={<CloseIcon />}
        {...customProps}
    />

    return (topRight)
    ? <div className="csui_buttonCloseXParent closebutton-topright">
        {btn}
    </div>
    : btn;
})
