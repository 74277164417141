import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = makeStyles((theme) => ({
    root: {
        width: '200px',
        height: '490px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        ['@media (max-width: 1280px)']: { // eslint-disable-line no-useless-computed-key
            width: 180
        },
        ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            width: 175
        },
    },
    title: {
        margin: 'unset',
        fontWeight: 900,
        color: '#FFFFFF'
    },
    subTitle: {
        marginTop: theme.spacing(0.5),
        color: '#FFFFFF',
        margin: 'unset',
        textAlign: 'center',
        fontWeight: 100,
        maxWidth: '70%',
        fontSize: '.9rem',
        height: 23
    },
    divider: {
        height: '1px',
        backgroundColor: '#FFFFFF',
        width: '55%',
        margin: theme.spacing(1.5, 0)
    },
    periodWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 50
    },
    period: {
        color: '#FFFFFF',
        margin: 'unset',
        textAlign: 'center'
    },
    periodChildren: {
        fontSize: '.8rem',
        color: '#FFFFFF',
        textAlign: 'center',
        //maxWidth: '65%',
        fontWeight: 500
    },
    plan: {
        fontSize: '1.5rem',
        margin: theme.spacing(1, 0),
        fontWeight: 500,
        color: '#000000',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:first-child': {
            marginTop: 3
        }
    },
    planContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    activePlanPrice: {
        fontWeight: 900,
        color: '#FFFFFF',
        margin: 'unset',
    },
    activePlanDescription: {
        color: '#FFFFFF',
        margin: 'unset',
        textAlign: 'center',
        fontSize: '.8rem',
        paddingTop: 15,
        padding: theme.spacing(0, 2),
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            paddingTop: 10,
        },
    },
    activePlan: {
        margin: theme.spacing(1, 0),
        height: '76px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            padding: '5px 0px'
        },
    },
    action: {
        marginTop: theme.spacing(1)
    },
    blackShadow: {
        textShadow: "0px 0px 8px rgba(0,0,0,.4)"
    },
    whiteShadow: {
        textShadow: "0px 0px 8px rgba(255,255,255,.8)"
    },
    greenLabel: {
        width: '100%',
        color: '#fff',
        background: '#50da01',
        textAlign: 'center',
        fontWeight: 500,
        padding: '10px 0',
        position: 'absolute',
        top: -39
    }
}));

export default styles;
