import React from 'react';
import Popup from "reactjs-popup";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VideoPlayer from '../../../../components/VideoPlayer';
import './style.scss';

import noImageAvailable from '../../../../media/no_image_available.jpg';

type assetValue = {
    value: {
        label: string;
        url: string;
        meta: {
            [x: string]: any;
        }
    }
    trigger?: JSX.Element | string | number;
}

interface ImagePopupProps {
    value: {
        url: string,
        label: string,
        meta: any
    }
    onLoadImg?: (width: number, height: number) => void
}

export const ImagePopup = (props: ImagePopupProps) => {
    let url = noImageAvailable;

    if (props.value?.url) {
        url = props.value?.url;
    }
    const imgElement = <img src={url} alt='' />;
  
    const triggerElement = <div className='imagePopupTrigger'>{imgElement}</div>;
  
    return (
      <Popup trigger={triggerElement} position='bottom center' on='hover'>
        <div className='imagePopupContent'>{imgElement}</div>
      </Popup>
    );
  };
  
const getYoutubeEmbedCode = (url: string) => { 
  const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|vi{0,1}=|shorts|)(.*?((?=[&#?])|$))/gm;
  return regex.exec(url)?.[3];
}

export const VideoPopup: React.FC<assetValue> = props => {
    let url = '';
    let poster = '';
    let type = 'video/mp4';
    let youtubeEmbedCode = '';

    
    if(props.value) {
        url = props.value.url || '';
        poster = props.value.meta
        ? props.value.meta.poster || ''
        : ''

        youtubeEmbedCode = getYoutubeEmbedCode(url) || '';

        if (url.indexOf(".m3u8")) {
            type = 'application/x-mpegURL';
        }
    }
    
    // const trigger = <img style={{ maxWidth: 25, maxHeight: 25 }} src={src} alt="" />;
    const innerTrigger =  props.trigger 
    ? props.trigger
    : <div 
        style={{ 
            // maxWidth: 25, maxHeight: 25 
            height: '100%', width: '100%', 
            ...(poster ? {
                background: `url(${poster})`, 
                backgroundSize: 'contain', 
                backgroundRepeat: "no-repeat", 
                backgroundPosition: 'center'
            } : {})
        }}
    >
        {(!poster) ? <PlayArrowIcon /> : undefined}
    </div>

    const player = youtubeEmbedCode
    ? <iframe
        height="150"
        src={`https://www.youtube.com/embed/${youtubeEmbedCode}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube preview"
    />
    : <VideoPlayer
        videoTagProps={{ height: '150' }}
        playerOptions={{
            autoplay: true,
            controls: true,
            sources: [{ src: url, type }],
            ...(poster ? {poster} : {})
        }}
    />

    const content = <div
        style={{ display: 'block', /* maxWidth: 200,  */maxHeight: 200, width: 'auto' }} 
    >
        {player}
    </div>

    const reactjsPopup = <Popup 
        trigger={<div 
            className="videoPopupTrigger"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            {innerTrigger}
        </div>} 
        position="bottom center" 
        on="click"
        className="videoPopup"
    >
        <div 
            className="videoPopupContent"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            {content}
        </div>
    </Popup>

    return reactjsPopup;
};

// Approach for DocumentPopup
// https://pspdfkit.com/blog/2018/open-pdf-in-your-web-app/
// https://pdfobject.com/#use-cases