export enum StateKey {
    Retailers = 'retailers',
    SkusPerRetailer = 'skusPerRetailer',
    Total = 'total',
}

export interface PlanSize {
    key: string;
    price: number;
    description: string;
    isActive: boolean;
    recommended?: boolean;
}

export interface IPlan {
  planKey: string;
  planName: string;
  name: string;
  subTitle: string;
  period: string;
  plans: {
      starting_unit: number;
      ending_unit?: number;
      price: number;
      object: string;
      numAudits: number;
      feePer?: number;
      overageFee?: number;
  }[];
  periodChildren?: string;
  activeColor: string;
  gradient: string;
}

export interface FormulaState {
    retailers: string;
    skusPerRetailer: string;
    total: string;
}
