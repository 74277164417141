import { CoreJob, CoreUserAccount } from "../../libraries/types/datastructures";
import { firestoreDb, coreJobDataSet } from "../../libraries/Util";
import {
    API_CALL_submitNewJob,
    API_CALL_deleteCatalogJob
} from '../../libraries/Util/apiClient';
import { RetailerNameRenderFns } from "../../libraries/Util/constants";
import { CSVUtils } from "../../libraries/Util/misc";

interface CatalogJobSchedule {
    isRecurring: boolean;
    enabled: boolean;
    config: {
        timeUnit: 'day' | 'week' | 'month';
        every: number; // 1 - 4
        limit: number | null; // null, negative, or 0 should mean infinite. Otherwise, if num of previoud runs > limit, don't run
        endTimestamp: number | null; // null, negative, or 0 will be ignored. Otherwise, if today > than endTimestamp, don't run
        startTimestamp: number; // This determines the start date. The date shoud be rounded to the beginning of the UTC day this endTimestamp is during
    }
    metadata: {
        numOfExecs: number;
        previousExecTimestamp: number | null; // null until it runs for the first time! Should be rounded to beginning of UTC day (so 7am UTC => 12am UTC)
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onetimeJobScheduleExample: CatalogJobSchedule = {
    isRecurring: false,
    enabled: true,
    config: {
        timeUnit: 'day',
        every: 1,
        limit: 1,
        endTimestamp: null,
        startTimestamp: new Date().setUTCHours(0,0,0,0).valueOf()
    },
    metadata: {
        numOfExecs: 0, // Will be 1 after it is executed
        previousExecTimestamp: null // Will be equal to roundToStartOfUTCDay(startTimestamp) after it is executed
    }
}
export interface CatalogJobSku {
    skuRetUid: string,
    skuRetUidType: string
}
export interface PlatformCatalogJob {
    version: 'v1';
    accountId: string;
    jobId: string;
    skus: { skuRetUid: string, skuRetUidType: string }[];
    scrapeConfig: {
        [skuId: string]: {skuId: string; url: string; skuRetUid: string; }
    }
    schedule: CatalogJobSchedule; // daily, specific days of week, monthly
    retailer: string; // Walmart, Amazon, etc.
    metadata: {
        submittedUserId: string;
        jobName: string;
    }
    status: {
        enabled: boolean;
        state: 'Draft' | 'Error' | 'Processing' | 'Scheduled' | 'Complete';
        lastRun?: number;
        errorMsg?: string;
    },
    errorSkus?: string[];
    missingSkus?: string[];
}

export const PlatformCatalogJobFns = {
    write: (job: PlatformCatalogJob): Promise<void> => {
        return firestoreDb.collection("CatalogJobs")
        .doc(job.jobId)
        .set(job);
    },
    read: async (jobId: string): Promise<PlatformCatalogJob> => {
        const docRef = await firestoreDb.collection("CatalogJobs")
        .doc(jobId)
        .get();

        return docRef.data() as PlatformCatalogJob;
    },
    getCatalogJobsForRetailer: async (retailer: string): Promise<PlatformCatalogJob[]> => {
        const jobs = await firestoreDb.collection("CatalogJobs")
        .where('retailer', '==', retailer)
        .get()
        .then(snapshot => snapshot.docs.map(docRef => docRef.data() as PlatformCatalogJob));

        return jobs;
    }
}

const coreJobScheduleToCatalogJobSchedule = (coreSchedule: CoreJob['schedule']): PlatformCatalogJob['schedule'] => {
    const extractCustomRecur = (recur: {
        how: "custom";
        frequency: {
            unit: "day" | "week" | "month";
            amount: number;
        };
        stop: {
            when: "never";
        } | {
            when: "limit";
            limit: number;
        } | {
            when: "date";
            date: number;
        };
    }) => {

        const config = {
            timeUnit: recur.frequency.unit,
            every: recur.frequency.amount,
            ...((recur.stop.when === 'never')
                ? {limit: null, endTimestamp: null}
                : (recur.stop.when === 'date')
                    ? {limit: null, endTimestamp: recur.stop.date}
                    : {limit: recur.stop.limit, endTimestamp: null}
            )
        }

        return config
    }

    const onceRecur = {
        timeUnit: 'day' as 'day',
        every: 1,
        limit: 1,
        endTimestamp: null,
    };

    const makeSchedule = (
        isRecurring: boolean,
        configPrefix: Omit<PlatformCatalogJob['schedule']['config'], 'startTimestamp'>
    ): PlatformCatalogJob['schedule'] => ({
        isRecurring,
        enabled: true,
        metadata: {
            numOfExecs: 0, // Will be 1 after it is executed
            previousExecTimestamp: null // Will be equal to roundToStartOfUTCDay(startTimestamp) after it is executed
        },
        config: {
            ...configPrefix,
            startTimestamp: (coreSchedule.start.when === 'now')
            ? new Date().valueOf()//.setUTCHours(0,0,0,0).valueOf()
            : coreSchedule.start.date
        }
    });

    const schedule = (coreSchedule.recur.how === 'custom')
    ? makeSchedule(true, extractCustomRecur(coreSchedule.recur))
    : makeSchedule(false, onceRecur);

    return schedule;
}

const coreJobToCatalogJob = (coreJob: CoreJob, coreUser: CoreUserAccount, draftJob: boolean): PlatformCatalogJob => {
    const {skuIdMap, masterCsv } = coreJob.jobData;

    const skus = masterCsv.data.map(row => {
        if (skuIdMap.upc && row[skuIdMap.upc]) {
            return {
                skuRetUid: row[skuIdMap.upc],
                skuRetUidType: 'upc'
            }
        } else if (skuIdMap.toolId && row[skuIdMap.toolId]) {
            return {
                skuRetUid: row[skuIdMap.toolId],
                skuRetUidType: 'itemId'
            }
        } else if (skuIdMap.url && row[skuIdMap.url]) {
            return {
                skuRetUid: row[skuIdMap.url],
                skuRetUidType: 'url'
            }
        } else if (skuIdMap.asin && row[skuIdMap.asin]) {
            return {
                skuRetUid: row[skuIdMap.asin],
                skuRetUidType: 'asin'
            }
        } else if (skuIdMap.productId && row[skuIdMap.productId]) {
            return {
                skuRetUid: row[skuIdMap.productId],
                skuRetUidType: 'productId'
            }
        }

        return null
    }).filter(x => x);

    const schedule = coreJobScheduleToCatalogJobSchedule(coreJob.schedule);

    const catJob: PlatformCatalogJob = {
        version: 'v1',
        accountId: coreUser.teamId,
        jobId: coreJob.jobId,
        retailer: RetailerNameRenderFns.keyName(coreJob.retailerInfo.retailerId),
        schedule,
        scrapeConfig: {},
        skus,
        metadata: {
            submittedUserId: coreUser.userId,
            jobName: coreJob.jobName
        },
        status: {
            enabled: true,
            state: draftJob ? 'Draft' : 'Processing'
        }
    }
    // console.log(catJob)
    return catJob;
}

async function newJobData_save(coreJob: CoreJob, coreUser: CoreUserAccount, draftJob: boolean = true) {
    const catJob = coreJobToCatalogJob(coreJob, coreUser, draftJob);
    await PlatformCatalogJobFns.write(catJob);

    await firestoreDb.collection('TeamAccount')
    .doc(coreUser.teamId)
    .collection('catalogjobs')
    .doc(coreJob.jobId)
    .set({ jobId: coreJob.jobId });
}

const saveJobDataIfNew = (teamId: string, coreJob: CoreJob, savedJobDataId: string) => (coreJob.jobData.datasetId === savedJobDataId)
        ? Promise.resolve(coreJob.jobData.datasetId)
        : coreJobDataSet(coreJob.jobData, teamId);

async function newJobData_saveOld(coreJob: CoreJob, coreUser: CoreUserAccount, savedJobDataId: string) {
    const promise1 = firestoreDb.collection('TeamAccount')
    .doc(coreUser.teamId)
    .collection('jobs')
    .doc(coreJob.jobId)
    .set({ jobId: coreJob.jobId });

    const promise2 = firestoreDb.collection('brandJobs')
    .doc(coreJob.jobId)
    .set({
        ...coreJob,
        jobData: {
            datasetId: coreJob.jobData.datasetId
        },
        submissionInfo: {
            userId: coreUser.userId
        }
    });

    const promise3 = saveJobDataIfNew(coreUser.teamId, coreJob, savedJobDataId);

    await Promise.all([promise1, promise2, promise3]);

    return promise3;
}

export async function newJobData_submit(coreJob: CoreJob, coreUser: CoreUserAccount, savedJobDataId: string, isRecurring = false) {
    await newJobData_saveOld(coreJob, coreUser, savedJobDataId);

    await newJobData_save(coreJob, coreUser, false);

    await API_CALL_submitNewJob(coreJob.jobId);
}

export async function newJobData_delete(coreJob: CoreJob, coreUser: CoreUserAccount) {
    const deletePromises = [
        API_CALL_deleteCatalogJob(coreJob.jobId),
        /* firestoreDb.collection('brandJobs').doc(coreJob.jobId).delete(),
        firestoreDb.collection('TeamAccount').doc(coreUser.teamId).collection('jobs').doc(coreJob.jobId).delete() */
    ];
    await Promise.all(deletePromises);
}

export async function newJobData_saveDraft(coreJob: CoreJob, coreUser: CoreUserAccount, savedJobDataId: string) {
    try {
        await newJobData_save(coreJob, coreUser);
        return newJobData_saveOld(coreJob, coreUser, savedJobDataId);
    } catch (e) {
        console.log(coreJob)
        console.log(e)
    }
}

export const newJobData_parseUploadedCsv = CSVUtils.parse;

const default_parsedCsv = () => ({
    data: [],
    meta: {
        fields: []
    }
}) as CoreJob['jobData']['masterCsv'];

export const newJob_default_jobData = () => ({
    datasetId: '',
    fileName: '',
    masterCsv: default_parsedCsv(),
    skuIdMap: {}
}) as CoreJob['jobData'];
