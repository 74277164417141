
import { SkuRecord } from '../../pages/Catalog/catalog-datamodel';

export type WorkFlowStage = "not-reviewed" | "in-progress" | "review" | "approved" | "re-submitted";

export const stripHtml = (html: string) =>  {
    let result = '';
    if (!!html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        result = doc.body.textContent || "";
    }
    return result;
};


export type ProductCategory = { [name: string]: ProductCategory } | string
export interface ProductProgress {
    type: "currency" | "number" | "text" | "percent" | "label",
    value: number | string,
    variation?: number,
    progress?: number,
    footNote?: string,
    variationText?: string,
    variationType?: "comparison" | "change" | "none",
    variationValueType?: "number" | "percent" | "currency",
    variationPeriod?: number,
    variationStartDate?: string
}
export interface KeyWordStats {
    keyword: string,
    title: number,
    description: number,
    bullets: number,
    warning?: boolean
}
export interface ScoreCard {
    name: string,
    productName: string,
    mainImageUrl: string,
    productLink: string,
    retailerName: string,
    availability: string,
    price: number,
    msrp: number,
    description: string,
    highlightBullets: string[],
    mainBullets: string[],
    productImages: string[],
    productReviews: number,
    videos: {
        thumbnailImageUrl: string,
        videoUrl: string
    }[],
    pdf: {
        thumbnailImageUrl: string,
        downloadUrl: string
    },
    averageRating: number,
    nonGradedSpecs: {
        dimensionsAndWeights: { [dimension: string]: string },
        otherSpecs: { [spec: string]: string }
    },
    scores: {
        title: number,
        description: number,
        bullets: number,
        keyword: number,
        image: number,
        video: number,
        ratings: number,
        pdf: number,
        count: number
    },
    keywordsUsage: {
        primary: {
            total: number,
            used: number
        },
        secondary: {
            total: number,
            used: number
        }
    }
}
export interface JobProduct {
    jobName: string,
    dataSource: string,
    runDate: string,
    retailerName: string,
    upc: string,
    retailerId: string,
    model: string,
    productNumber: string,
    productName: string,
    manufacturer: string,
    availability: string,
    mainImageUrl: string,
    link: string,
    category: ProductCategory,
    price: number,
    msrp: number,
    statusSnapshot: {
        live: ProductProgress,
        health: ProductProgress,
        accuracy: ProductProgress,
        keyword: ProductProgress
    },
    keywordSnapshot: {
        total: ProductProgress,
        primary: ProductProgress,
        secondary: ProductProgress,
        stats: {
            primary: KeyWordStats[],
            secondary: KeyWordStats[]
        }
    },
    contentSnapshot: ScoreCard[]

}
export const demoProduct: JobProduct = {
    jobName: "Walmart Sprint",
    dataSource: "Walmart US",
    runDate: "11/25/2019",
    retailerName: "Walmart",
    upc: "123456789102",
    retailerId: "123456789102",
    model: "WW32k-4",
    productNumber: "123456789102",
    productName: "Krud Kutter Window Wash, 32 oz",
    manufacturer: "Krud Kutter",
    availability: "In-stock store; Shipping",
    mainImageUrl: "https://i5.walmartimages.com/asr/6f2287f5-db5b-4a50-87ce-bf9ea2ac3e73_1.d28fc7f22a9ad0ff6ff214bfb2612d1f.jpeg",
    link: "https://www.walmart.com/ip/Krud-Kutter-Window-Wash-32-oz/17619960",
    category: {
        "Walmart US": {
            "Home": {
                "Cleanning Supplies": "General"
            }
        }
    },
    price: 59.98,
    msrp: 65.98,
    statusSnapshot: {
        live: {
            type: "label" as "label",
            value: "Live",
            footNote:"Previously Live on 11/24/2019",
        },
        health: {
            type: "percent" as "percent",
            value: 3.2,
            variation: 0,
            progress: 45,
            variationType: "change" as "change",
            variationValueType: "percent" as "percent",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        accuracy: {
            type: "percent" as "percent",
            value: 0,
            progress: 0
        },
        keyword: {
            type: "text" as "text",
            value: 3.5,
            variation: 2.7,
            progress: 33,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        }
    },
    keywordSnapshot: {
        total: {
            type: "percent" as "percent",
            value: "--",
            variation: NaN,
            progress: 50,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        primary: {
            type: "percent" as "percent",
            value: "--",
            variation: NaN,
            progress: 80,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        secondary: {
            type: "percent" as "percent",
            value: "--",
            variation: NaN,
            progress: 40,
            variationType: "comparison" as "comparison",
            variationValueType: "number" as "number",
            variationPeriod: 7,
            variationStartDate: "11/25/2019"
        },
        stats: {
            primary: [
                {
                    keyword: "Homeshades",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }, {
                    keyword: "spray paint",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }, {
                    keyword: "awesome",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }
            ],
            secondary: [
                {
                    keyword: "Homeshades",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }, {
                    keyword: "spray paint",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }, {
                    keyword: "awesome",
                    title: 0,
                    description: 0,
                    bullets: 0,
                    warning: true
                }
            ]
        }

    },
    contentSnapshot: [{
        name: "Walmart 2019",
        productName: "Krud Kutter Window Wash, 32 oz",
        mainImageUrl: "",
        productLink: "https://www.walmart.com/ip/Krud-Kutter-Window-Wash-32-oz/17619960",
        retailerName: "Walmart",
        availability: "In-stock store;Shipping",
        price: 59.98,
        msrp: 65.98,
        description: "Instant Pot DUO60 6 Qt 7-in-1 Multi-Use Programmable Pressure Cooker has the functions of a slow cooker, rice cooker, steamer, sauté, yogurt maker and warmer all in one conveniently sized appliance. With 14 built-in smart programs: Soup, Meat/Stew, Bean/Chili, Poultry, Sauté, Steam, Rice, Porridge, Multigrain, Slow Cook, Keep-Warm, Yogurt, Pasteurize and Pressure Cook, you can cook your favorite dishes with the press of a button. A 24-hour timer allows for delayed cooking. Automatic Keep Warm holds the temperature of the food until you serve it. Instant Pot generates almost no noise and leaks no steam. It traps all the flavors and aromas in the food. The 3-ply bottom stainless steel inner pot is extremely durable, and the slim body design of the finger print resistant brushed stainless-steel exterior has lid holders for both left and right-handed users. Enjoy delicious, healthy meals with the Instant Pot DUO60 7-in-1 Multi-Use Programmable Pressure Cooker.",
        highlightBullets: ["Bullet #1", "Bullet #2", "Bullet #3"],
        productImages: [],
        mainBullets: ["Bullet #1", "Bullet #2", "Bullet #3"],
        videos: [{
            thumbnailImageUrl: "https://i5.walmartimages.com/asr/6f2287f5-db5b-4a50-87ce-bf9ea2ac3e73_1.d28fc7f22a9ad0ff6ff214bfb2612d1f.jpeg",
            videoUrl: "https://www.youtube.com/watch?v=6I-Hs9aFtr0"
        }, {
            thumbnailImageUrl: "https://i5.walmartimages.com/asr/6f2287f5-db5b-4a50-87ce-bf9ea2ac3e73_1.d28fc7f22a9ad0ff6ff214bfb2612d1f.jpeg",
            videoUrl: "https://www.youtube.com/watch?v=6I-Hs9aFtr0"
        }],
        pdf: {
            thumbnailImageUrl: "http://instantpot.us/wp-content/uploads/2013/12/InstantPot-IP-DUO-Product-Structure.jpg",
            downloadUrl: "http://www.pdf995.com/samples/pdf.pdf"
        },
        averageRating: 4.7,
        nonGradedSpecs: {
            dimensionsAndWeights: { "Product Dimensions": "L x W x H", "Length": "", "Width": "", "Height": "", "Weight": "" },
            otherSpecs: { "Misc Spec": "", "Misc Spec1": "", "Misc Spec2": "", "Misc Spec3": "" }
        },
        scores: {
            title: 2.5,
            description: 2.5,
            bullets: 2.5,
            keyword: 5,
            image: 4.5,
            video: 5,
            ratings: 4.1,
            pdf: 5,
            count: 2.5
        },
        keywordsUsage: {
            primary: {
                total: 0,
                used: 0
            },
            secondary: {
                total: 0,
                used: 0
            }
        },
        productReviews: 21
    }]

}

export const convertSkuToJobProduct = (skuRecord: SkuRecord): JobProduct => {
    const product: JobProduct = { ...demoProduct };


    if (skuRecord.skuData) {
        //Extract Main Image, and Product Images
        if (skuRecord.skuData.Assets.Images.labels.length > 0) {
            let mainImageUrl: string = "";
            const images: string[] = [];
            Object.keys(skuRecord.skuData.Assets.Images.values).forEach((key: string) => {
                const { label, url } = skuRecord.skuData.Assets.Images.values[key];
                if (label === skuRecord.skuData.Assets.Images.main) {
                    mainImageUrl = url;
                }
                images.push(url);
            });
            product.mainImageUrl = mainImageUrl || product.mainImageUrl;
            product.contentSnapshot[0].productImages = (images.length > 0 && images) || product.contentSnapshot[0].productImages;
        }
        //Extract videos
        if (skuRecord.skuData.Assets.Videos.labels.length > 0) {
            const videos: { thumbnailImageUrl: string, videoUrl: string }[] = [];
            Object.keys(skuRecord.skuData.Assets.Videos.values).forEach((key: string) => {
                const { url } = skuRecord.skuData.Assets.Videos.values[key];
                videos.push({
                    thumbnailImageUrl: product.mainImageUrl,
                    videoUrl: url
                });
            });
            product.contentSnapshot[0].videos = (videos.length > 0 && videos) || product.contentSnapshot[0].videos;
        }
        //Extract Category
        if (skuRecord.skuData.Categories.labels.length > 0) {
            const getProductCategory = (index: number = 0): ProductCategory => {
                if (skuRecord.skuData.Categories.labels.length === 1) {
                    return { [skuRecord.skuData.Categories.values[skuRecord.skuData.Categories.labels[0]]]: skuRecord.skuData.Categories.values[skuRecord.skuData.Categories.labels[0]] }
                } else if (index === skuRecord.skuData.Categories.labels.length - 1) {
                    return { [skuRecord.skuData.Categories.values[skuRecord.skuData.Categories.labels[index - 1]]]: skuRecord.skuData.Categories.values[skuRecord.skuData.Categories.labels[index]] }
                } else {
                    return { [skuRecord.skuData.Categories.values[skuRecord.skuData.Categories.labels[index]]]: getProductCategory(index + 1) };
                }
            };
            product.category = getProductCategory();
        }
        //Extract main bullets
        if (skuRecord.skuData.Copy.Bullets.labels.length > 0) {
            product.contentSnapshot[0].mainBullets = skuRecord.skuData.Copy.Bullets.labels.map((label: string) => skuRecord.skuData.Copy.Bullets.values[label]);
        }
        //Extract average rating and ratings count
        product.contentSnapshot[0].averageRating = skuRecord.skuData.Ratings["Ratings Average"];
        product.contentSnapshot[0].productReviews = skuRecord.skuData.Ratings["Ratings Count"];
        //Extract product description
        product.contentSnapshot[0].description = skuRecord.skuData.Copy["Long Description"];
        //Extract dimensions and weight

        const dimensions: { [dimension: string]: string } = {};
        Object.keys(skuRecord.skuData.Specifications.Dimensions).filter((key: string) => skuRecord.skuData.Specifications.Dimensions[key] ? true : false).forEach((key: string) => {
            dimensions[key] = skuRecord.skuData.Specifications.Dimensions[key];
        });
        if (skuRecord.skuData.Specifications["Product Weight"]) {
            dimensions["Product Weight"] = skuRecord.skuData.Specifications["Product Weight"];
        }
        product.contentSnapshot[0].nonGradedSpecs.dimensionsAndWeights = dimensions;
        //Extract other specs
        const other: { [spec: string]: string } = {};
        Object.keys(skuRecord.skuData.Specifications.Other).filter((key: string) => skuRecord.skuData.Specifications.Other[key] ? true : false).forEach((key: string) => {
            other[key] = skuRecord.skuData.Specifications.Other[key];
        });
        product.contentSnapshot[0].nonGradedSpecs.otherSpecs = other;
        //Extract product name
        product.contentSnapshot[0].productName = skuRecord.skuData.UID["Product Name"];
        product.productName = skuRecord.skuData.UID["Product Name"];
        //Extract retailer name
        product.contentSnapshot[0].retailerName = skuRecord.retailer;
        product.retailerName = skuRecord.retailer;

        //Extract product url
        product.link = skuRecord.skuData.UID.URL;
        product.contentSnapshot[0].productLink = skuRecord.skuData.UID.URL
        //Extract manufacturer
        product.manufacturer = skuRecord.skuData.UID["Brand Name"];
        //Extract upc
        product.upc = skuRecord.skuData.UID["UPC (GTIN-12)"];
        //Extract model
        product.model = skuRecord.skuData.UID.Model;
        //Extract prduct number
        product.productNumber = skuRecord.skuData.UID["Manufacturer Part Number"];
        //Extract retailer id
        product.retailerId = skuRecord.skuData.UID["Retailer UID"];


    }


    return product;
}

export interface NavButtonProps {
    text: string,
    icon: string,
    active: boolean,
    title?:string,
    onClick: () => void,
    large?:boolean,
    larger?:boolean
}

