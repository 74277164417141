
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = makeStyles((theme) => ({
    core: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '80px',
        margin: 'auto',
        justifyContent: 'center'
    },
    left: {
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px',
    },
    right: {
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
    },
    tab: {
        backgroundColor: theme.palette.grey[200],
    },
    activeTab: {
        backgroundColor: '#4fda03',//theme.palette.secondary.main,
        color: '#ffffff',
        fontWeight: 500,
    },
    defaultTab: {
        padding: theme.spacing(1, 2),
        cursor: 'pointer',
    },
}));

export default styles;
