import { SkuRecord } from "../../../Catalog/catalog-datamodel";

import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import Paper from '@material-ui/core/Paper/Paper';
import Table from '@material-ui/core/Table/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';

export const PDPSpecsTable: React.FC<{
    sku?: SkuRecord
    specs?: any
}> = props => {
    const otherSpecs = props.sku
    ? props.sku.skuData?.Specifications?.Other || {}
    : props.specs || {};
    const sortedOtherSpecs = Object.keys(otherSpecs).filter(spec => ![
        "360 View",
        "A+ Modules Count",
        "Enhanced Content"
    ].includes(spec)).sort(new Intl.Collator(undefined, {sensitivity: "base", numeric: true}).compare);
    return <TableContainer component={Paper}>
        <Table size='small'>
            <TableBody>
            {sortedOtherSpecs.map((spec: string, i: number) => (
                <TableRow key={`product-dimension-${i}`}>
                    <TableCell>
                        {spec}
                    </TableCell>
                    <TableCell align='left'>{otherSpecs[spec]}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
}