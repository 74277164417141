import React from 'react';
import { ParseResult } from 'papaparse';
import { Button } from 'semantic-ui-react';
import {
  FieldDefinition,
  MappedBrandFieldDefinition,
  RetailerAdminRecord,
  Objectentries,
} from '../../libraries/types/datastructures';
import CustomVisualDropdown from './CustomVisualDropdown';
import CSUITable, { RT_CellInfo } from '../../components/CSUI/CSUITable';
import './style.scss';

interface Props {
  reportMappedFields: (mappedFields: {
    [x: string]: MappedBrandFieldDefinition;
  }) => void;
  csvData: ParseResult;
  mappedFields: { [x: string]: MappedBrandFieldDefinition };
  retailerFields?: { [x: string]: FieldDefinition };
  retailer?: RetailerAdminRecord;
  idOnly?: boolean;
  reservedFields: {
    [x: string]: {
      set: (fieldname: string) => void;
      unset: () => void;
    };
  };
  reservedText: string;
}

interface State {
  columnWidths: { [x: string]: number };
}

class VisualFieldMapperBrand extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      columnWidths: {},
    };
  }

  reportMappedField = (
    headerName: string,
    retailerFieldDefinition: FieldDefinition
  ) => {
    this.props.reportMappedFields(
      Object.assign({}, this.props.mappedFields, {
        [headerName]: {
          fieldName: headerName,
          retailerFieldDefinition,
        } as MappedBrandFieldDefinition,
      })
    );
  };

  reservedFieldNames = () => Object.keys(this.props.reservedFields);

  retBrandMap = () =>
    Object.assign(
      {},
      ...Objectentries(this.props.mappedFields).map((x) => ({
        [x.retailerFieldDefinition.fieldName]: x,
      }))
    ) as {
      [x: string]: MappedBrandFieldDefinition;
    };

  handleRetailerDropdownChange = (
    cellInfo: RT_CellInfo,
    retailerFieldName: string
  ) => {
    const fieldName = cellInfo.original;

    if (this.props.retailerFields) {
      const definition = this.props.retailerFields![retailerFieldName]
        ? this.props.retailerFields![retailerFieldName]
        : {
          fieldName: retailerFieldName,
          fieldType:
            /Additional Image \(\d\d\)/g.test(retailerFieldName) ||
              /Video \(\d\d\)/g.test(retailerFieldName)
              ? {
                compared: false,
                fieldTypeName: 'Asset URL',
                reorderable: false,
              }
              : /Bullet \(\d\d\)/g.test(retailerFieldName)
                ? {
                  compared: true,
                  fieldTypeName: 'Feature',
                  reorderable: false,
                }
                : /Product Subcategory \(\d\d\)/g.test(retailerFieldName)
                  ? {
                    compared: true,
                    fieldTypeName: 'Information',
                    reorderable: false,
                  }
                  : {},
        };
      this.reportMappedField(fieldName, definition as any);
    }
    const reserved = this.props.reservedFields[retailerFieldName];
    if (reserved) {
      reserved.set(fieldName);
    }
  };

  getDropdown = (
    retBrandMap: { [x: string]: MappedBrandFieldDefinition },
    cellInfo: RT_CellInfo,
    dropdownOptions: Array<{
      text: string;
      value: string;
      description: string;
    }>
  ) => {
    return (
      <CustomVisualDropdown
        placeholder={'*Select a Field'}
        value={
          retBrandMap[cellInfo.original]
            ? retBrandMap[cellInfo.original].fieldName
            : ''
        }
        options={dropdownOptions}
        onChange={(e, { value }) => {
          this.handleRetailerDropdownChange(
            { original: value } as any,
            cellInfo.original
          );
        }}
        mappedFields={this.props.mappedFields}
      />
    );
  };

  getSelected = (mapping: MappedBrandFieldDefinition) => {
    return (
      <div style={{ display: 'flex' }}>
        <div>{mapping.fieldName + ' '}</div>
        {this.reservedFieldNames().indexOf(mapping.fieldName) === -1 ? (
          <div style={{ marginLeft: 'auto' }}>
            <span
              className='unselectRemove'
              style={{ color: 'red' }}
              onClick={() => {
                const newMappedFields = { ...this.props.mappedFields };
                delete newMappedFields[mapping.fieldName];
                this.props.reportMappedFields(newMappedFields);
                const reserved = this.props.reservedFields[
                  mapping.retailerFieldDefinition.fieldName
                ];
                if (reserved) {
                  reserved.unset();
                }
              }}>
              remove
            </span>
            {undefined && (
              <Button
                className='unselect chromelessButton'
                compact={true}
                basic={true}
                circular={true}
                color='red'
                icon='close'
                onClick={() => {
                  const newMappedFields = { ...this.props.mappedFields };
                  delete newMappedFields[mapping.fieldName];
                  this.props.reportMappedFields(newMappedFields);
                  const reserved = this.props.reservedFields[
                    mapping.retailerFieldDefinition.fieldName
                  ];
                  if (reserved) {
                    reserved.unset();
                  }
                }}
              />
            )}
          </div>
        ) : undefined}
      </div>
    );
  };

  retailerFieldList = (retBrandMap: {
    [x: string]: MappedBrandFieldDefinition;
  }) => {
    const { retailerFields } = this.props;
    const fields: string[] = [];
    for (const key in retailerFields) {
      if (retailerFields.hasOwnProperty(key)) {
        if (retailerFields[key].fieldType.reorderable) {
          fields.push(key);
        } else {
          const parseNumber = key.replace(/\D/g, '');
          const recordNumber = Number(parseNumber);
          const keyWithoutNumbers = key.replace(/[(0-9)]/g, '');
          const nextKey = retailerFields[
            `${keyWithoutNumbers}(${recordNumber + 1})`
          ]
            ? `${keyWithoutNumbers}(${recordNumber + 1})`
            : retailerFields[`${keyWithoutNumbers}(0${recordNumber + 1})`]
              ? `${keyWithoutNumbers}(0${recordNumber + 1})`
              : '';

          if (recordNumber === 1 || retBrandMap[key]) {
            fields.push(key);
          }

          if (nextKey && !retBrandMap[nextKey] && retBrandMap[key]) {
            fields.push(nextKey);
          }
        }
      }
    }
    return fields.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  };

  setColumnWidth = (column: { id: string }) =>
    Object.assign(
      column,
      this.state.columnWidths[column.id]
        ? { width: this.state.columnWidths[column.id] }
        : {}
    );

  getColumns = (
    rest: boolean,
    retailer: RetailerAdminRecord,
    retBrandMap: {
      [x: string]: MappedBrandFieldDefinition;
    },
    brandDropdownOptions: Array<{
      text: string;
      value: string;
      description: string;
    }>
  ) => {
    const headerStyle = {
      fontWeight: 'bold',
    };
    const headerMap = {
      retailerFields: `${retailer.retailerName} Fields`,
      yourFields: 'Your Fields',
    };
    const columns = [
      {
        headerStyle: { display: 'none' },
        id: 'retailerFields',
        accessor: (d: string) => d,
      },
      {
        headerStyle: { display: 'none' },
        style: {
          overflow: 'visible',
        },
        id: 'yourFields',
        Cell: (cellInfo: RT_CellInfo) => {
          const mapping = retBrandMap[cellInfo.original];
          return mapping
            ? this.getSelected(mapping)
            : this.getDropdown(retBrandMap, cellInfo, brandDropdownOptions);
        },
      },
    ];
    return rest
      ? columns.map(this.setColumnWidth)
      : columns.map((column) =>
        Object.assign(column, {
          Header: (
            <div
              style={{
                borderBottom: '1px solid rgb(123,48,167)',
                padding: '5px 0',
              }}
              key={column.id}>
              {(headerMap as any)[column.id]}
            </div>
          ),
          headerStyle,
        })
      );
  };

  render() {
    const reservedFieldNames = this.reservedFieldNames();
    const chosenBrandFields = Object.keys(this.props.mappedFields);
    const brandDropdownOptions = this.props.csvData.meta.fields
      .filter((fieldName) => chosenBrandFields.indexOf(fieldName) === -1)
      .map((fieldName) => {
        return {
          text: fieldName,
          value: fieldName,
          description: fieldName,
        };
      });
    const retBrandMap = this.retBrandMap();
    const retailerFieldList = this.retailerFieldList(retBrandMap);

    return (
      !!this.props.retailer && (
        <div
          className='visualFieldMapperBrand'
          style={{
            width: '100%',
          }}>
          <div className='mapping-title'>{this.props.reservedText}</div>
          <CSUITable
            columnConfig={this.getColumns(
              false,
              this.props.retailer,
              retBrandMap,
              brandDropdownOptions
            )}
            rows={reservedFieldNames}
            //getRowId={plan => plan.plan.id}
            rowConfig={{}}
            maxWidth={1300}
          />
          {!this.props.idOnly && (
            <div>
              <div className='mapping-title with-margin-top'>
                Please map all fields to be checked for accuracy. &nbsp;
                Unmapped fields will not appear.
              </div>
              <CSUITable
                columnConfig={this.getColumns(
                  true,
                  this.props.retailer,
                  retBrandMap,
                  brandDropdownOptions
                )}
                rows={retailerFieldList}
                //getRowId={plan => plan.plan.id}
                rowConfig={{}}
                maxWidth={1300}
              />
            </div>
          )}
        </div>
      )
    );
  }
}

export default VisualFieldMapperBrand;
