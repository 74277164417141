import React, { useRef, useCallback, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
    TreeDataState,
    CustomTreeData,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    GridProps,
    VirtualTable ,
    TableProps,
    TableHeaderRow,
    TableTreeColumn,
    ExportPanel,
    Toolbar,
    TableColumnResizing,
    TableBandHeader, TableBandHeaderProps
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { CSUIButton } from "../../../components/CSUI";
import { ReportsConditionalFormatter } from '../../../pages/Reports/ReportTemplates';
import saveAs from 'file-saver';
import { StyledHeader, StyledRow } from '../../../pages/DemoReports/styles';
import './style.scss';
import { commonMeasuresMap } from '../../../pages/DemoReports/ReportsDemoLayout';

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);

const onSave = (workbook) => {
    const parthnameParams = window.location.pathname.split('/');
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${parthnameParams[parthnameParams.length - 1]}.xlsx`);
    });
};

interface CSUIPivotTableProps {
    columns: GridProps['columns'];
    rows: GridProps['rows'];
    renderer: TableProps['cellComponent'];
    pivotColumn: string;
    columnExtensions?: { [columnName: string]: TableProps['columnExtensions'][0]};
    columnBands?: any[]
}

const Root = props => <Grid.Root {...props} style={{ height: '72vh' }} />;

const BandCellBase: TableBandHeaderProps['cellComponent'] = ({
    children, ...restProps
  }) => {
    return (
      <TableBandHeader.Cell
        {...restProps}
      >
          <StyledHeader {...{className:"topHeaderRow headerRow"}}>{children}</StyledHeader>
      </TableBandHeader.Cell>
    );
  };

export const CSUIPivotTable: React.FC<CSUIPivotTableProps> = ({ columns, rows, renderer, pivotColumn, columnExtensions, columnBands }) => {
    const exporterRef = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [expandedRowIds, onExpandedRowIdsChange] = useState([]);

    const tableRows = rows.map(row => Object.assign({}, row, {items: undefined}));

    const defaultColumnWidths = columns.map(column => columnExtensions[column.name] as any || ({
        columnName: column.name, width: 65
    }));

    const exportColumns = columns.map(col => ({...col, title: col['exportTitle'] ? col['exportTitle'] : col.title}));

    const customizeCell = (cell, row, column) => { 
        const measureObj = (column?.name ? commonMeasuresMap[column.name] : {}) || {};
        
        if (row !== undefined && row.hasOwnProperty(column.name) && measureObj['format'] === 'score') {
            const found:any = ReportsConditionalFormatter(row[column.name], column.name);
            const bgColor = found.hasOwnProperty('backgroundColor') ? found.backgroundColor.substring(1) : 'FFFFFFFF';
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor } };
            cell.font = {
                name: 'Calibri',
                color: { argb: found.hasOwnProperty('backgroundColor') ? 'FFFFFFFF' : '00000000' },
                family: 2,
                size: 11,
                italic: false
              };
        }
    };

    return (
        <Paper className="CSUIPivotTable">
            <Grid
                rows={rows}
                columns={columns}
                rootComponent={Root}
            >
                <TreeDataState {...{expandedRowIds, onExpandedRowIdsChange}}/>
                <CustomTreeData getChildRows={getChildRows} />
                <VirtualTable /* columnExtensions={columnExtensions} */ cellComponent={renderer} />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow contentComponent={(value) => <StyledHeader {...{className:"bottomHeaderRow headerRow"}}>{value.column.title}</StyledHeader>}/>
                <TableBandHeader 
                    columnBands={columnBands || []} 
                    cellComponent={BandCellBase}  
                    rowComponent={all => {
                        return <TableBandHeader.Row {...all} className="bandRow">
                            {all.children}
                        </TableBandHeader.Row>
                    }}
                />
                <TableTreeColumn for={pivotColumn} contentComponent={(value) => <StyledRow>{value.children}</StyledRow>}/>
                <Toolbar />
                <ExportPanel toggleButtonComponent={() => <CSUIButton onClick={startExport} label='EXPORT Excel' />} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={tableRows}
                columns={exportColumns}
                onSave={onSave}
                customizeCell={customizeCell}
            />
        </Paper>
    );
}
