
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 50px',
        width: '100%',
        ['@media (max-width: 1280px)']: { // eslint-disable-line no-useless-computed-key
            padding: 0
        },
    },
    planWrapper: {
        margin: '0 15px',
        position: 'relative',
        ['@media (max-width: 1100px)']: { // eslint-disable-line no-useless-computed-key
            margin: '0 5px',
        },
    }
}));

export default styles;
