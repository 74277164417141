import React from "react";
import { JobProduct } from "../../datastructures";
import { CSUIButton } from "../../../../components/CSUI/CSUIButton";
import {
    defaultTags,
    SKUsList,
    Tag
} from "../../../../pages/Catalog/SKUTaggingView/datastructures";
import { SkuRecord } from "../../../../pages/Catalog/catalog-datamodel";
import { firestoreDb } from "../../../../libraries/Util";
import Grid from "@material-ui/core/Grid/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import './style.scss';

interface TagsSnapshotProps {
    product: JobProduct;
    defaultTags?: Tag[];
    customTags?: Tag[];
    skuRecord: SkuRecord;
    skuTags: {
        [tag: string]: Tag;
    };
    setTags: (data: {
        skus?: SKUsList;
        tags?: Tag[];
    }, alreadySet?: boolean) => Promise<void>;
}

interface STagsSnapshotState {
    defaultTags: Tag[],
    customTags: Tag[],
    checkedTags: { [key: string]: boolean };
}

export default class TagsSnapshot extends React.Component<TagsSnapshotProps,
    STagsSnapshotState> {
        
    constructor(props: TagsSnapshotProps) {
        super(props);

        this.state = {
            defaultTags: props.defaultTags || defaultTags,
            customTags: props.customTags || [],
            checkedTags: {},
        };
    }

    async componentDidMount() {
        await this.loadSkuTags();
    }

    loadSkuTags = async () => {
        if (!this.props.skuRecord) {
            return;
        }
        const customTags = [];
        const customTagsData = await firestoreDb
            .collection("TeamAccount")
            .doc(this.props.skuRecord.teamId)
            .collection("custom_tags")
            .get();
        customTagsData.docs.forEach((data) => {
            customTags.push({ ...data.data().tag } as Tag);
        });
        const { defaultTags } = this.state;
        const checkedTags = {};
        for (const tag in this.props.skuTags) {
            const tagData = this.props.skuTags[tag];
            customTags.forEach((item) => {
                if (item.id === tagData.id) {
                    item.selected = tagData.selected;
                }
                return item;
            });

            defaultTags.forEach((item) => {
                if (item.id === tagData.id) {
                    item.selected = tagData.selected;
                }
                return item;
            });
        }

        this.setState({
            customTags,
            checkedTags,
            defaultTags,
        });
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.skuRecord && this.props.skuRecord) {
            this.loadSkuTags();
        }
    }

    onSave = () => {
        const skus: SKUsList = {
            [this.props.skuRecord.skuId]: {},
        };
        this.state.defaultTags.forEach((tag: Tag) => {
            skus[this.props.skuRecord.skuId][tag.displayName] = tag;
        });
        this.state.customTags.forEach((tag: Tag) => {
            skus[this.props.skuRecord.skuId][tag.displayName] = tag;
        });
        this.props.setTags({skus}, false);
    }

    displayDefaultTags = () => {
        return (
            <Grid item xs={6}>
                <h1 className="section-header ">System Tags</h1>
                <ul style={{listStyleType: "none"}}>
                    {this.state.defaultTags.map((tag: Tag, index: number) => (
                        <li key={tag.id} className="branch">
                            <FormControlLabel
                                key={`tag-${tag.id}`}
                                label={tag.displayName}
                                control={
                                <Checkbox
                                    checked={tag.selected}
                                    color="primary"
                                    onChange={this.onTagSelectedGenerator("default", tag.id)}
                                />
                                }
                            />
                        </li>
                    ))}
                </ul>
            </Grid>
        );
    }

    onTagSelectedGenerator = (tagType: "default" | "custom", tagId: string) => (
        e
    ) => {
        const { checked } = e.target as HTMLInputElement;
        if (tagType === "default") {
            this.setState({
                defaultTags: this.state.defaultTags.map((tag: Tag) => {
                        if (tag.id === tagId) {
                            tag.selected = checked;
                        }
                        return tag;
                    }),
            });
        } else if (tagType === "custom") {
            this.setState({
                customTags: this.state.customTags.map((tag: Tag) => {
                        if (tag.id === tagId) {
                            tag.selected = checked;
                        }
                        return tag;
                    }),
            });
        }
    }

    displayCustomTags = () => {
        return (
            <Grid item xs={6}>
                <h1 className="section-header">Custom Tags</h1>
                <ul  style={{listStyleType: "none"}}>
                    {this.state.customTags.map((tag: Tag) => (
                        <li key={tag.id} className="branch">
                             <FormControlLabel
                                key={`tag-${tag.id}`}
                                label={tag.displayName}
                                control={
                                <Checkbox
                                    checked={tag.selected}
                                    color='primary'
                                    onChange={this.onTagSelectedGenerator('custom', tag.id)}
                                />
                                }
                            />
                        </li>
                    ))}
                </ul>
            </Grid>
        );
    }

    render() {
        return (
            <Grid className="card-body dark-container tags-snapshot">
                <Grid container>
                    <Grid xs={8}>
                        <h1 className="dual">
                            Tags<span>Snapshot</span>
                        </h1>
                    </Grid>
                    <Grid xs={4} className="text-right">
                        <CSUIButton onClick={this.onSave} color="green" flat>
                            Save
                        </CSUIButton>
                    </Grid>
                </Grid>
                <Grid className="card-body white-container snapshot-body">
                    <Grid container spacing={2} className="catalog-filters">
                        {this.displayDefaultTags()}
                        {this.displayCustomTags()}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
