import React, { useState } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBIcon, MDBProgress } from 'mdbreact';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import "./style.scss";
import { CSUISelect } from '../../../../components/CSUI';
import moment from 'moment-timezone';
import SyncedDivTest from '../../Common/SyncedDivTest';
import SyncedTextArea from '../../Common/SyncedTextArea';
import ScoreBar from '../../Common/ScoreBar';
import ScoreDisplay from '../../Common/ScoreDisplay';
import { ImagePopup } from '../../Common/AssetPopup';
import SyncedDivSimple from '../../Common/SyncedDivSimple';
import KeywordUsageColumn from '../KeywordUsageColumn';
import { GlobalState } from '../../../../libraries/types/ReportUp';
import { SKUWithKeywords } from '../../../../pages/Catalog/SKUKeywords/datastructures';
import { Scorer, ScoreTitle, ScoreShortDescription } from '../../../../libraries/scoring';
import IconDatePicker from './IconDatePicker';
import Checkmark from '../../Common/Checkmark';
import { stripHtml } from '../../datastructures';
import BlankMasterColumn from '../BlankMasterColumn';
import EditPreviewColumn from '../EditPreviewColumn';
import { JobProduct } from '../../datastructures';
import { ScoreRange } from '../../../../libraries/scoring/types/score';
import { scoreCardService } from '../../../../libraries/scoring/scoreCard.service';
import { getLatestRubric } from '../../../../libraries/Util/utils';
import {cloneDeep} from 'lodash'
import { ScoreCardSettings, ScoreCardDefaultMappings } from '../../../ScoreCards/types';

interface DynamicColumnProps {
    globalState: GlobalState,
    skuRecord: SkuRecord,
    columnId: string;
    columnType: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview';
    includeKeywords?: boolean;
    highlight?: string[];
    onColumnTypeChanged: (columnType: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview') => void;
    stickyTop?: number;
    skuWithKeywords: SKUWithKeywords;
    product: JobProduct;
    updateEditedSkuRecord?: (skuRecord: SkuRecord) => void;
    editedSkuRecord: SkuRecord;
    scoreCardConfig?: {
        scoreCards: ScoreCardSettings[];
        scoreCardMappings: ScoreCardDefaultMappings;
    }
}
interface ApprovalStatus {
    section: "brand" | "marketing" | "assets" | "sentiment",
    status: "grey" | "yellow" | "green"
}
interface State {
    columnType: 'Archived' | 'Edited' | 'Master' | 'Keywords' | 'Edited-Preview';
    reportFns: {
        [id: string]: () => void
    }
    contentQty: {
        [id: string]: number;
    },
    datePick: boolean,
    recordDate: number;
    contentUpdated: boolean;
    approvalStatuses: ApprovalStatus[];
}

const avg = (nums: number[]) => nums.length
    ? nums.reduce((a, b) => a + b) / nums.length
    : NaN

function DynamicColumn(props: DynamicColumnProps){
    if (props.skuRecord) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [columnType, setColumnType] = useState<State['columnType']>(props.columnType);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [reportFns, setReportFns] = useState<State['reportFns']>({});
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [contentQty, setContentQty] = useState<State['contentQty']>({});
        // const [datePick, setDatePick] = useState(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [recordDate, setRecordDate] = useState(props.skuRecord.timestamp);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [, setContentUpdated] = useState(false);
        /* const [approvalStatuses, setApprovalStatuses] = useState([
            {
                section: "brand",
                status: "yellow"
            }, {
                section: "marketing",
                status: "grey"
            }, {
                section: "assets",
                status: "yellow"
            }, {
                section: "sentiment",
                status: "yellow"
            }
        ]) ; */

        const getScorecard = () => {
            if (props.scoreCardConfig) {
                const {scoreCardMappings, scoreCards} = props.scoreCardConfig;

                const scorecardId = props.skuRecord?.retailer && scoreCardMappings.defaults[props.skuRecord.retailer]
                ? scoreCardMappings.defaults[props.skuRecord.retailer].scoreCardId
                : "CSGlobalDefault";

                const scoreCard = scoreCards.find(card => card.scoreCardId === scorecardId);

                return scoreCard;
            } else {
                return undefined;
            }
        }

        const CHOSEN_SCORECARD = getScorecard() //|| DEFAULT_SCORECARD

        /* const setApprovalStatus = (status: ApprovalStatus) => {
            const newApprovalStatuses = approvalStatuses.map((content: ApprovalStatus) => {
                if (content.section === status.section) {
                    content.status = status.status;
                }
                return content;
            })

            setApprovalStatuses(newApprovalStatuses);
        } */

        const dropdown = () => {
            type optionObj = typeof CSUISelect.defaultProps['options'][0];

            const options = [
                { value: "Archived" },
                { value: "Edited" },
                { value: "Edited-Preview" },
                { value: "Keywords", disabled: !props.includeKeywords }
            ].map(obj => ({ ...obj, text: obj.value !== "Keywords" ? obj.value : "Keyword usage" } as optionObj));

            const getValueFn = (e) => {

                e.persist();
                setColumnType(e.target.value);
                props.onColumnTypeChanged(e.target.value)
            }

            return <div className="">
                <select className="csui-select"//icon-input select"
                    value={columnType}
                    onChange={(e) => getValueFn(e)}
                >
                    {options.map(el => <option key={el.value} value={el.value}>{el.value}</option>)}
                </select>
            </div>
        }

        // const stageDropdown = () => {
        //     let text = "Not Reviewed";
        //     if (contentUpdated) {
        //         text = "In Progress";
        //     } else if (!approvalStatuses.find((status: ApprovalStatus) => status.status !== "green")) {
        //         text = "Review";
        //     }
        //     const stages=[
        //         {
        //             text:"Not Reviewed",
        //             value:"not-reviewed",
        //             checked:false,
        //             disabled:true
        //         },
        //         {
        //             text:"In Progress",
        //             value:"in-progress",
        //             checked:false,
        //             disabled:true
        //         },
        //         {
        //             text:"Review",
        //             value:"review",
        //             checked:false,
        //             disabled:true
        //         },
        //         {
        //             text:"Approved",
        //             value:"approved",
        //             checked:false
        //         },
        //         {
        //             text:"Re-submitted",
        //             value:"resubmitted",
        //             checked:false
        //         }
        //     ]
        //     const options = [
        //         {
        //             text,
        //             value: "current-stage",
        //             checked: true
        //         },...stages.filter((stage:{text:string,value:string,checked:boolean})=>stage.text!==text)
        //     ];

        //     const label = "Current Stage"

        //     return <div className="">
        //         <CSUISelect className="csui-select"//icon-input select"
        //             /* getValue={selectSnapshot} */
        //             options={options}
        //             selected={label}
        //             label={label}
        //         />
        //     </div>
        // }

        // const scorecardDropdown = () => {
        //     if (columnType === "Edited") {
        //         return null//stageDropdown();
        //     }
        //     const options = [
        //         {
        //             text: "Default",
        //             value: "default"
        //         }
        //     ];

        //     const label = "Choose a Scorecard"

        //     return <div className="">
        //         <CSUISelect className="csui-select"//icon-input select"
        //             /* getValue={selectSnapshot} */
        //             options={options}
        //             selected={label}
        //             label={label}
        //         />
        //     </div>
        // }

        const renderTop = () => {
            return <div className="columnType" style={{visibility: props.columnId === '3' ? 'visible' : 'hidden'}}>
                <div>Column Type</div>
                {dropdown()}
            </div>
        }

        const registerReportFn = (id: string) => (fn: () => void) => {
            setReportFns({...reportFns, [id]: fn})
        }

        const appendToTextarea = (id: string, value: string) => {
            const textarea = window.document.getElementById(id) as HTMLTextAreaElement;

            const newValue = (textarea.value.trim() === '')
                ? value
                : textarea.value + " " + value

            textarea.value = newValue;
            textarea.focus();
            textarea.dispatchEvent(new Event("input", { bubbles: true }));
            textarea.dispatchEvent(new Event("change", { bubbles: true }));
            textarea.blur();
        }

        const isArchived = () => columnType === 'Archived';
        const isMaster = () => columnType === 'Master';

        const showNonEditedStuff = () => isArchived() || isMaster();

        const uidSection = () => {
            const { skuRecord, columnId } = props;

            const UIDsectionData = {
                "Brand": skuRecord.skuData?.UID["Brand Name"],
                "Retailer Product ID": skuRecord.skuData?.UID["Retailer UID"],
                "Model #": skuRecord.skuData?.UID.Model,
                "Manufacturer Part #": skuRecord.skuData?.UID["Manufacturer Part Number"],
                "UPC GTIN 12": skuRecord.skuData?.UID["UPC (GTIN-12)"]/*,
                "Product Name": skuRecord.skuData.UID["Product Name"]*/
            }

            const UIDsection = Object.keys(UIDsectionData).map((key, idx) => {

                const syncedDivId = `UIDsection-${idx}-${columnId}`;
                const textareaId = `UIDsection-${idx}-${columnId}-editTextarea`;


                const textAreaVersion = <SyncedTextArea
                    className={"editTextarea"}
                    id={textareaId}
                    groupId={`UIDsection-${idx}-editTextarea`}
                    itemId={columnId}
                    /* onResize={() => {
                        if (reportFns[syncedDivId]){
                            reportFns[syncedDivId]();
                        }
                    }}  */
                    // onResize={onResizeFn}
                    //{...restProps}
                    // defaultValue
                    value={UIDsectionData[key]}

                />

                // const inputVersion = <MDBInput className="editInput" value={UIDsectionData[key]} />;

                const onClick = () => appendToTextarea(`UIDsection-${idx}-${2}-editTextarea`, UIDsectionData[key]);

                return <SyncedDivSimple
                    groupId={`UIDsection-${idx}`}
                    divId={columnId}
                    key={key}
                    registerOnChangeHandler={registerReportFn(syncedDivId)}
                >
                    <MDBRow className="">
                        <MDBCol size={showNonEditedStuff() ? "5" : "6"}>
                            <b>{key}:</b>
                        </MDBCol>
                        <MDBCol size="5" className={"editMDBCol" + (showNonEditedStuff() ? " nonEditCol" : "")}>
                            {textAreaVersion}
                            {showNonEditedStuff()
                                ? <div className="nonEditValue">{UIDsectionData[key]}</div>
                                : null
                            }
                        </MDBCol>
                        {showNonEditedStuff() ? <MDBCol size="2">
                            <div className="plus-circle" {...{ onClick }}>
                                <MDBIcon far icon="times-circle" />
                            </div>
                        </MDBCol> : null}
                    </MDBRow>
                </SyncedDivSimple>
            })

            return <SyncedDivTest
                className="whiteBox"
                groupId={"UID"}
                divId={props.columnId}
                title={{
                    main: 'UID',
                    detail: 'Brand & Unique Identifiers',
                    icons: (columnType === "Edited" ?
                        <Checkmark
                            // onChange={(color: "grey" | "yellow" | "green") => setApprovalStatus({ section: "brand", status: color })}
                            value='grey' />
                        : null)
                }}
            >
                <div className="contentCard">
                    <div className="UID-data">
                        {UIDsection}
                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-UID'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
            </SyncedDivTest>

        }

        const scoreDisplaySection = (
            scoreTitle: string,
            scoreValue: string,
            scoreDetail: string,
            props: ScoreBar['props']
        ) => {
            if (props) {
                const newScore = <div className="">
                    <ScoreDisplay
                        score={props.score}
                        title={scoreTitle}
                    />
                </div>
                return <div className="scoreDisplay">
                    <MDBRow>
                        <MDBCol size="5">
                            {newScore}
                        </MDBCol>
                        <MDBCol size="7" className="">
                            <div className="scoreBarWrapper">
                                <ScoreBar {...props} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            } else {
                return null
            }
        }

        const dynamicContentIds = (columnId: string, idPrefix: string) => {
            const syncedDivId = `${idPrefix}-${columnId}`;
            const textareaId = `${syncedDivId}-editTextarea`;
            return { syncedDivId, textareaId };
        }

        const genDynamicQtyReporter = (syncedDivId: string) => (event: React.FormEvent<HTMLTextAreaElement>) => {
            const { currentTarget } = event;

            setContentQty({...contentQty, [syncedDivId]: currentTarget.value.length})
        }

        const getDynamicQty = (syncedDivId: string, initial: number) => {
            /* const qty = contentQty[syncedDivId];

            return qty !== undefined && !isNaN(qty) ? qty : initial; */
            return initial
        }

        const getDynamicScore = (scorer: Scorer, syncedDivId: string, initialQty: number) => {
            const qty = getDynamicQty(syncedDivId, initialQty);
            const score = scorer.score(qty);

            return { score, qty };
        }

        const handleContentUpdate = (e: React.FormEvent<HTMLTextAreaElement>) => {
            setContentUpdated(true);
        }

        const dynamicContentDisplay = (
            value: string,
            editedValue: string,
            columnId: string,
            idPrefix: string,
            inputHandler: (event: React.FormEvent<HTMLTextAreaElement>) => void,
            reportChanges: boolean = false
        ) => {

            const { syncedDivId, textareaId } = dynamicContentIds(columnId, idPrefix);

            const textAreaVersion = columnType === 'Edited' ? <div style={{height: '100%'}}>
                <textarea id={textareaId} className={`groupClass_${idPrefix}-editTextarea`} style={{resize: 'none', overflow: 'visible', width: '100%', border: 'none'}} value={value} onChange={event => {
                    genDynamicQtyReporter(syncedDivId)(event);
                    inputHandler(event);
                } }  />

            </div> : <SyncedTextArea
                highlight={props.highlight}
                className={"editTextarea"}
                id={textareaId}
                groupId={`${idPrefix}-editTextarea`}
                itemId={columnId}
                value={value}
                onInput={event => {
                    genDynamicQtyReporter(syncedDivId)(event);
                    inputHandler(event);
                }}
                onResize={() => {
                    const fn = reportFns[syncedDivId];
                    if (fn && columnId === "2") {
                        fn();
                    }
                }}
                onChange={reportChanges ? handleContentUpdate : null}
            />

            const onClick = () => {
                /* appendToTextarea(
                    `${idPrefix}-${2}-editTextarea`,
                    value
                ); */

                const newEvent = {
                    currentTarget: {
                        value: editedValue + " " + value
                    }
                } as any

                genDynamicQtyReporter(dynamicContentIds("2", idPrefix).syncedDivId)(newEvent);
                inputHandler(newEvent);
            }
            return <div
            /* groupId={`${idPrefix}-syncedDivSimple`}
            divId={columnId}
            registerOnChangeHandler={registerReportFn(syncedDivId)} */
            >
                <MDBRow className="">
                    <MDBCol
                        size={showNonEditedStuff() ? "11" : "12"}
                        className={"editMDBCol" + (showNonEditedStuff() ? " nonEditCol" : "")}
                    >
                        {textAreaVersion}
                        {showNonEditedStuff()
                            ? <SyncedDivSimple
                                className="nonEditValue"
                                groupId={`${idPrefix}-editTextarea`}
                                divId={columnId}
                            >
                                <div className="value" style={{ position: 'relative' }}>
                                    {value}
                                </div>
                            </SyncedDivSimple>
                            : null
                        }
                    </MDBCol>
                    {showNonEditedStuff() ? <MDBCol size="1">
                        <div className="plus-circle" {...{ onClick }}>
                            <MDBIcon far icon="times-circle" />
                        </div>
                    </MDBCol> : null}
                </MDBRow>
            </div>
        }

        const onChangeHandler = () => {}

        const marketingTitleContentCard = () => {
            const { skuRecord, columnId, editedSkuRecord } = props;

            // const marketingScores = skuRecord.status.health.rubric['default'].scores.marketing;

            const value = skuRecord.skuData.UID["Product Name"];
            const editedValue = editedSkuRecord.skuData?.UID["Product Name"] || '';

            const idPrefix = "Marketing-Title";
            const { syncedDivId } = dynamicContentIds(columnId, idPrefix);
            const qty = getDynamicQty(syncedDivId, value.length);

            let scoreObject;
            if (CHOSEN_SCORECARD) {
                const titleSteps: ScoreRange[] = scoreCardService.calculateScoreStepsRange(CHOSEN_SCORECARD.marketing.title?.score);
                scoreObject = scoreCardService.calculateScore(titleSteps, qty, CHOSEN_SCORECARD.marketing.title.focus);
            }

            return <div className="contentCard">
                <div className="Marketing-Title-data">
                    {scoreDisplaySection(
                        "Title",
                        "", // marketingScores.title.score.toFixed(1),
                        "",// "Needs Improvement",
                        scoreObject
                    )}
                </div>
                {dynamicContentDisplay(
                    value,
                    editedValue,
                    columnId,
                    "Marketing-Title",
                    (event) => {
                        // TODO: Sync edits
                        const { value } = event.currentTarget;

                        const newSkuRecord = editedSkuRecord?.skuData ? {...editedSkuRecord} : {...skuRecord};
                        newSkuRecord.skuData.UID['Product Name'] = value;

                        //console.log(value)

                        props.updateEditedSkuRecord(newSkuRecord);
                    },
                    true
                )}
                <div className="editDiv">
                    <SyncedTextArea
                        onChange={handleContentUpdate}
                        onResize={onChangeHandler}
                        placeholder='Add notes here'
                        groupId={'Notes-MarketingTitle'}
                        itemId={props.columnId}
                    />
                </div>
            </div>
        }

        const marketingDescriptionContentCard = () => {
            const { skuRecord, columnId, editedSkuRecord } = props;

            // const marketingScores = skuRecord.status.health.rubric['default'].scores.marketing;

            const value = stripHtml(skuRecord.skuData.Copy["Short Description"]);
            const editedValue = stripHtml(editedSkuRecord.skuData?.Copy["Short Description"] || '');

            const idPrefix = "Marketing-Description";
            const { syncedDivId } = dynamicContentIds(columnId, idPrefix);

            const qty = getDynamicQty(syncedDivId, value.length);


            let scoreObject;
            if (CHOSEN_SCORECARD) {
                const descriptionSteps: ScoreRange[] = scoreCardService.calculateScoreStepsRange(CHOSEN_SCORECARD.marketing.description?.score);
                scoreObject = scoreCardService.calculateScore(descriptionSteps, qty, CHOSEN_SCORECARD.marketing.description.focus);
            }

            return <div className="contentCard">
                <div className="Marketing-Description-data">
                    {scoreDisplaySection(
                        "Description",
                        "", // marketingScores.description.score.toFixed(1),
                        "", // "Needs Improvement",
                        scoreObject
                    )}
                </div>
                {dynamicContentDisplay(
                    value,
                    editedValue,
                    columnId,
                    idPrefix,
                    (event) => {
                        const { value } = event.currentTarget;

                        const newSkuRecord = editedSkuRecord?.skuData ? {...editedSkuRecord} : {...skuRecord};
                        newSkuRecord.skuData.Copy['Short Description'] = value;
                        // props.updateEditedSkuRecord(newSkuRecord)

                        // Update the product name

                        props.updateEditedSkuRecord(newSkuRecord);
                    },
                    true
                )}
                <div className="editDiv">
                    <SyncedTextArea
                        onChange={handleContentUpdate}
                        // onResize={onChangeHandler}
                        placeholder='Add notes here'
                        groupId={'Notes-MarketingDescription'}
                        itemId={props.columnId}
                    />
                </div>
            </div>
        }

        const bulletsContentCard = () => {
            const { skuRecord, columnId, editedSkuRecord } = props;

            const { Bullets } = skuRecord.skuData.Copy;
            const EditedBullets = editedSkuRecord.skuData?.Copy.Bullets || {
                labels: [],
                values: {}
            };

            const rubric = getLatestRubric(skuRecord);

            const marketingScores = skuRecord.status.health.rubric[rubric].scores.marketing;

            const bulletsDivs = Bullets.labels.map((label, idx) => {

                return <div key={label}>

                    {dynamicContentDisplay(
                        Bullets.values[label],
                        EditedBullets.values[label],
                        columnId,
                        `Marketing-Bullets-${idx}`,
                        (event) => {
                            // TODO: Sync edits

                            const { value } = event.currentTarget;

                            const newSkuRecord = editedSkuRecord?.skuData ? {...editedSkuRecord} : {...skuRecord};

                            newSkuRecord.skuData.Copy['Bullets'].values[`Bullet ${idx+1 < 10 ? `0${idx+1}` : idx+1}`] = value;

                            props.updateEditedSkuRecord(newSkuRecord);

                        },
                        true
                    )}
                    <div className="editDiv">
                        <SyncedTextArea

                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-MarketingBullets' + idx}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
            })

            const card = <div className="contentCard">
                <div className="Marketing-Bullets-score">
                    {scoreDisplaySection(
                        "Bullets",
                        marketingScores?.bullets?.score.toFixed(1),
                        "Needs Improvement",
                        {
                            score: marketingScores.bullets,
                            qty: skuRecord.skuData.Copy.Bullets.labels.length
                        }
                    )}
                </div>
                <div className="Marketing-Bullets-data">
                    {bulletsDivs}
                </div>
            </div>

            return card;
        }

        const marketingSection = () => {

            return <SyncedDivTest
                className="whiteBox"
                groupId={"Marketing"}
                divId={props.columnId}
                title={{
                    main: marketingHealthScoreObject().score.toFixed(1),
                    detail: 'Marketing Group',
                    icons: columnType === "Edited" ?
                        <Checkmark
                            // onChange={(color: "grey" | "yellow" | "green") => setApprovalStatus({ section: "marketing", status: color })}
                            value='grey'/>
                        : null
                }}
            >
                {marketingTitleContentCard()}
                {marketingDescriptionContentCard()}
                {bulletsContentCard()}
            </SyncedDivTest>

        }

        const assetSection = () => {
            const { skuRecord } = props;

            const rubric = getLatestRubric(skuRecord);

            const assetsScores = skuRecord.status.health.rubric[rubric].scores.assets;
            
            const { Images } = skuRecord.skuData.Assets
            const images = Images.labels.map((label, idx) => {
                const popup = <ImagePopup value={Images.values[label]} />

                return (
                    <div key={`product-image-${idx}`}>
                        <div className="product-image small with-margin-bottom">
                            {popup}
                        </div>
                    </div>
                )
            })
            const resolutionImages = Images.labels.map((label, idx) => {
                const popup = <ImagePopup value={Images.values[label]} />

                return (
                    <div key={`product-image-${idx}`}>
                        <div className="product-image small with-margin-bottom">
                            {popup}
                        </div>
                    </div>
                )
            })

            return <SyncedDivTest
                className="whiteBox"
                groupId={"Assets"}
                divId={props.columnId}
                title={{
                    main: assetsScores.images?.score?.toFixed(1),
                    detail: 'Asset Group',
                    icons: columnType === "Edited" ?
                        <Checkmark
                            // onChange={(color: "grey" | "yellow" | "green") => setApprovalStatus({ section: "assets", status: color })}
                            value="grey" />
                        : null
                }}
            >
                <div className="contentCard">
                    <div className="Assets-Images-score">
                        {scoreDisplaySection(
                            "Image",
                            assetsScores.images?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: assetsScores.images,
                                qty: skuRecord.skuData.Assets.Images.labels.length
                            }
                        )}
                    </div>
                    <div className="Assets-Images-data">
                        {images}
                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-AssetsImages'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
                <div className="contentCard">
                    <div className="Assets-Image-Resolution-score">
                        {scoreDisplaySection(
                            "Image Resolution",
                            assetsScores.images?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: assetsScores.images,
                                qty: skuRecord.skuData.Assets.Images.labels.length
                            }
                        )}
                    </div>
                    <div className="Assets-Images-data">
                        {resolutionImages}
                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-AssetsImageResolution'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
            </SyncedDivTest>

        }

        const richContentSection = () => {
            const { skuRecord } = props;

            const rubric = getLatestRubric(skuRecord);

            const assetsScores = skuRecord.status.health.rubric[rubric].scores.assets;
            const richContentScores = skuRecord.status.health.rubric[rubric].scores.richContent;

            const videoScores = richContentScores?.videos || assetsScores['videos'] as typeof richContentScores['videos'];
            const documentScores = richContentScores?.documents || assetsScores['documents'] as typeof richContentScores['documents'];

            const enhancedContentScores = richContentScores?.enhancedContent;
            const view360Scores = richContentScores?.view360;

            const avgScore = [videoScores?.score, documentScores?.score, enhancedContentScores?.score, view360Scores?.score]
            .filter(x => x && !isNaN(x))
            .reduce((tot, curr, idx) => { 
                return ((tot*idx)+curr)/(idx+1)
            }, 0); 

            return <SyncedDivTest
                className="whiteBox"
                groupId={"RichContent"}
                divId={props.columnId}
                title={{
                    main: (richContentScores?.overall?.score || avgScore).toFixed(1),
                    detail: 'Rich Content Group',
                    icons: columnType === "Edited" ?
                        <Checkmark
                            // onChange={(color: "grey" | "yellow" | "green") => setApprovalStatus({ section: "assets", status: color })}
                            value="grey" />
                        : null
                }}
            >
                <div className="contentCard">
                    <div className="RichContent-Videos-score">
                        {scoreDisplaySection(
                            "Videos",
                            videoScores?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: videoScores || {
                                    score: NaN
                                } as any,
                                qty: skuRecord.skuData.Assets.Videos.labels.length,
                                binaryScore: true
                            }
                        )}
                    </div>
                    <div className="RichContent-Videos-data">

                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-AssetsVideos'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
                <div className="contentCard">
                    <div className="RichContent-Documents-score">
                        {scoreDisplaySection(
                            "Documents",
                            documentScores?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: documentScores || {
                                    score: NaN
                                } as any,
                                qty: 0,
                                binaryScore: true
                            }
                        )}
                    </div>
                    <div className="RichContent-Documents-data">

                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-AssetsDocuments'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
                <div className="contentCard">
                    <div className="RichContent-EnhancedContent-score">
                        {scoreDisplaySection(
                            "Enhanced Content",
                            enhancedContentScores?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: enhancedContentScores || {
                                    score: NaN
                                } as any,
                                qty: 0,
                                binaryScore: true
                            }
                        )}
                    </div>
                    <div className="RichContent-EnhancedContent-data">

                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-RichContentEnhancedContent'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
                <div className="contentCard">
                    <div className="RichContent-View360-score">
                        {scoreDisplaySection(
                            "360 Product View",
                            view360Scores?.score?.toFixed(1),
                            "Needs Improvement",
                            {
                                score: view360Scores || {
                                    score: NaN
                                } as any,
                                qty: 0,
                                binaryScore: true
                            }
                        )}
                    </div>
                    <div className="RichContent-View360-data">

                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            onChange={handleContentUpdate}
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-RichContentView360'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
            </SyncedDivTest>

        }

        const sentimentSection = () => {
            const { skuRecord } = props;

            const rubric = getLatestRubric(skuRecord);

            const sentimentScores = skuRecord.status.health.rubric[rubric].scores.sentiment;

            const { Breakdown } = skuRecord.skuData.Ratings;

            const breakDownTotal = Object.values(Breakdown).reduce((a,b) => a+b, 0);

            const breakdownDiv = <div className="sentimentBreakdown">
                {[5, 4, 3, 2, 1].map((num: keyof typeof Breakdown) => {

                    const rowNum = Breakdown[num];
                    const percent = breakDownTotal ? (rowNum / breakDownTotal) * 100 : 0;


                    return <div
                        className="breakdownRow"
                    >
                        <div className="number">{num}</div>
                        <div className="star"><MDBIcon icon="star" /></div>
                        <div className="bar"><MDBProgress value={percent} /></div>
                        <div className="percent">{`${percent.toFixed(1)}%`}</div>
                    </div>
                })}
            </div>

            const countsScore = sentimentScores.ratings.counts
            ? sentimentScores.ratings.counts
            : { score: NaN, potential: 5, steps: ["0", "20", "50", "75", "100", "200"], wheretofocus: ""}

            return <SyncedDivTest
                className="whiteBox"
                groupId={"Sentiment"}
                divId={props.columnId}
                title={{
                    main: sentimentScores.overall?.score.toFixed(1) || '--',
                    detail: 'Sentiment Group',
                    icons: columnType === "Edited" ?
                        <Checkmark
                            // onChange={(color: "grey" | "yellow" | "green") => setApprovalStatus({ section: "sentiment", status: color })}
                            value="grey" /> : null
                }}
            >
                <div className="contentCard">
                    <div className="Sentiment-Average-score">
                        {scoreDisplaySection(
                            "Ratings Average",
                            sentimentScores.ratings.average?.score ? sentimentScores.ratings.average?.score.toFixed(1) : "0",
                            "Needs Improvement",
                            {
                                score: sentimentScores.ratings.average,
                                qty: skuRecord.skuData.Ratings["Ratings Average"]
                            }
                        )}
                    </div>
                    <div className="Sentiment-Count-score">
                        {scoreDisplaySection(
                            "Ratings Count",
                            "",//sentimentScores.ratings.counts.score.toFixed(1),
                            "Needs Improvement",
                            {
                                score: countsScore,
                                qty: skuRecord.skuData.Ratings["Ratings Count"]
                            }
                        )}
                    </div>
                    <div className="Sentiment-data">
                        {breakdownDiv}
                    </div>
                    <div className="editDiv">
                        <SyncedTextArea
                            // onResize={onChangeHandler}
                            placeholder='Add notes here'
                            groupId={'Notes-Sentiment'}
                            itemId={props.columnId}
                        />
                    </div>
                </div>
            </SyncedDivTest>

        }

        const marketingHealthScoreObject = () => {
            const { skuRecord, columnId } = props;

            const getScoreObject = (scorer: Scorer, idPrefix: string, qty: number) => {
                const { syncedDivId } = dynamicContentIds(columnId, idPrefix);

                return getDynamicScore(scorer, syncedDivId, qty);
            }

            const rubric = getLatestRubric(skuRecord);

            const marketingScores = skuRecord.status.health.rubric[rubric].scores.marketing;

            const editC = (num: number) => columnType === 'Edited'
                ? num // 0
                : num

            if (CHOSEN_SCORECARD) {
                const scoreCardWeights = CHOSEN_SCORECARD.weights;

                const titleScore = getScoreObject(ScoreTitle, "Marketing-Title", editC(skuRecord.skuData.UID["Product Name"].length)).score || null;

                const shortDescriptionScore = getScoreObject(ScoreShortDescription, "Marketing-Description", editC(skuRecord.skuData.Copy["Short Description"].length)).score || null;

                const bulletsScore = marketingScores.bullets || null;

                const overall = scoreCardService.calculateGroupScore([
                    [titleScore, scoreCardWeights.marketing.sections.title],
                    [shortDescriptionScore,
                        scoreCardWeights.marketing.sections.shortDescription],
                    [bulletsScore as any, scoreCardWeights.marketing.sections.bullets]
                ]);

                return overall;
            } else {

                const scoreObjects = [
                    marketingScores.bullets,
                    getScoreObject(ScoreTitle, "Marketing-Title", editC(skuRecord.skuData.UID["Product Name"].length)).score,
                    getScoreObject(ScoreShortDescription, "Marketing-Description", editC(skuRecord.skuData.Copy["Short Description"].length)).score
                ];

                const overall = marketingScores.overall || {} as { score: number };

                overall['score'] = avg(scoreObjects.map(scoreObj => scoreObj?.score || 0))
                return overall;
            }
        }

        const overallHealthScoreObject = () => {
            const { skuRecord, columnType } = props;

            const rubric = getLatestRubric(skuRecord);

            const healthScores = skuRecord.status.health.rubric[rubric].scores;

            if(skuRecord.status) {
                if (columnType === 'Edited') {

                    if (CHOSEN_SCORECARD) {
                        const scoreCardWeights = CHOSEN_SCORECARD.weights;

                        const marketingScore = marketingHealthScoreObject();
                        const overall = scoreCardService.calculateGroupScore([
                            [marketingScore, scoreCardWeights.marketing.weight],
                            [healthScores.assets.overall, scoreCardWeights.assets.weight],
                            [healthScores.sentiment.overall, scoreCardWeights.sentiment.weight],
                            [healthScores.seo.overall, scoreCardWeights.keywords.weight],
                            [healthScores.richContent?.overall, scoreCardWeights.richContent.weight]
                        ] as any);

                        return overall;
                    } else {
                        const scoreObjects = [
                            healthScores?.sentiment.overall,
                            healthScores?.assets.overall,
                            marketingHealthScoreObject()
                        ];

                        const overall = cloneDeep(healthScores.overall);
                        overall.score = avg(scoreObjects.map(scoreObj => scoreObj?.score || 0));

                        return overall;
                    }
                } else {
                    return healthScores.overall;
                }
            }

            return {
                score: '--'
            };
        }
        // const toggleDatePicker = () => {
        //     setDatePick(!datePick);
        // }
        const selectDate = (date: Date) => {
            setRecordDate(date.getTime());
        }

        const renderCard = () => {
            if (columnType === "Keywords") {
                return <KeywordUsageColumn skuKeywords={props.skuWithKeywords}
                    globalState={props.globalState}
                    sku={props.skuRecord}
                />;
            } else if (columnType === "Master") {
                return <BlankMasterColumn sku={props.skuRecord} />
            } else if(columnType==="Edited-Preview") {
                return <EditPreviewColumn product={props.product} skuRecord={props.editedSkuRecord} />
            }

            const momentDate = moment(recordDate)

            const dateRender = `${momentDate.format("dddd")} ${momentDate.format('M/D/YYYY')}`;

            const columnTitle = props.columnId === '1'
            ? "Live"
            : columnType

            const overallHealthScore = overallHealthScoreObject();

            return <MDBCard className="card-body dark-container" style={{ ...stickyTopStyle() }}>
                <MDBRow className="titleRow">
                    <MDBCol size="6">
                        <div>
                            <div>
                                <h1 className="dual">{columnTitle}<span>Version</span></h1>
                            </div>
                            <div className="date-row">
                                <div className="date-container">{dateRender}</div>
                                {columnType === "Archived" && <IconDatePicker value={new Date(recordDate)} onChange={selectDate} />}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <div className="restBody">

                    <div className="stats">
                        <div className="whiteBox">
                            <div className="score" style={{ color: Number(overallHealthScore?.score) === 5
                                ? '#4FDA03'
                                : Number(overallHealthScore?.score) >= 4
                                    ? "#4FDA03"
                                    : Number(overallHealthScore?.score) >= 3
                                        ? "#FFC000"
                                        : "red"
                                    }}
                                >
                                {typeof overallHealthScore?.score === 'number' ? Number(overallHealthScore?.score).toFixed(1) : '--'}
                            </div>
                            <div className="statusName">
                                <span>Health</span>Status
                            </div>
                        </div>
                        <div className="whiteBox">
                            <div className="score">
                                -%
                            </div>
                            <div className="statusName">
                                <span>Accuracy</span>Status
                            </div>
                            <div className="versus">vs MASTER</div>
                        </div>
                        <div className="whiteBox">
                            <div className="score">
                                -%
                            </div>
                            <div className="statusName">
                                <span>Accuracy</span>Status
                            </div>
                            <div className="versus">vs LIVE</div>
                        </div>
                    </div>

                    <div className="collapseContainer">
                        {uidSection()}
                        {marketingSection()}
                        {assetSection()}
                        {richContentSection()}
                        {sentimentSection()}
                    </div>

                </div>

            </MDBCard>
        }

        const stickyTopStyle = () => ((props.stickyTop !== undefined)
            ? { position: "sticky", top: props.stickyTop }
            : ((columnType === "Keywords")
                ? { position: "sticky", top: 18 }
                : {})
        ) as React.CSSProperties

        const classes = ['dynamicColumn'];
        if (columnType === 'Edited') {
            classes.push("editColumn");
        }


        return <div className={classes.join(" ")} style={{ ...stickyTopStyle() }}>
            {renderTop()}
            {renderCard()}
        </div>
    } else {
        return null
    }
}

export default DynamicColumn;
