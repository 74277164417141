import * as React from "react";

import "./style.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import icon_addretailer from "../../../media/platformicon/Add to Retailer icon.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import icon_delete from "../../../media/platformicon/Delete icon.png";
import icon_download from "../../../media/platformicon/Download icon.png";
import icon_email from "../../../media/platformicon/Email icon.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import icon_favoriteview from "../../../media/platformicon/Favorite View icon.png";
import icon_filter from "../../../media/platformicon/Filter icon.png";
import icon_question from "../../../media/platformicon/Question icon.png";
import icon_save from "../../../media/platformicon/Save icon.png";
import icon_tag from "../../../media/platformicon/Tag icon.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import icon_uploaddata from "../../../media/platformicon/Upload Data icon.png";
import icon_user from "../../../media/platformicon/User icon.png";
import icon_view from "../../../media/platformicon/View icon.png";
import icon_add from "../../../media/platformicon/Upload Data icon.png";
import icon_workflow from "../../../media/platformicon/workflow_white.png";

import icon_dashboard_asset_score from "../../../media/dashboard icon/dashboard_asset_score.svg";
import icon_dashboard_msrp_improvement from "../../../media/dashboard icon/dashboard_msrp_improvement.svg";
import icon_dashboard_status_accuracy from "../../../media/dashboard icon/dashboard_status_accuracy.svg";
import icon_dashboard_brands_participating from "../../../media/dashboard icon/dashboard_brands_participating.svg";
import icon_dashboard_score_improvement from "../../../media/dashboard icon/dashboard_score_improvement.svg";
import icon_dashboard_status_health from "../../../media/dashboard icon/dashboard_status_health.svg";
import icon_dashboard_keyword_score from "../../../media/dashboard icon/dashboard_keyword_score.svg";
import icon_ddashboard_sentiment_score from "../../../media/dashboard icon/dashboard_sentiment_score.svg";
import icon_dashboard_status_keyword from "../../../media/dashboard icon/dashboard_status_keyword.svg";
import icon_dashboard_marketing_score from "../../../media/dashboard icon/dashboard_marketing_score.svg";
import icon_dashboard_skus_improved from "../../../media/dashboard icon/dashboard_skus_improved.svg";
import icon_dashboard_status_live_score from "../../../media/dashboard icon/dashboard_status_live_score.svg";
import icon_dashboard_free_credits from "../../../media/dashboard icon/dashboard_free_credits.svg";
import icon_dashboard_team_and_partners from "../../../media/dashboard icon/dashboard_team_and_partners.svg";
import icon_dashboard_errors from "../../../media/dashboard icon/dashboard_warning_outline_gray.svg";

import copy_icon from "../../../media/JobsIcons/copy.png";
import delete_icon from "../../../media/JobsIcons/delete.png";
import show_icon from "../../../media/JobsIcons/view.png";
import settings_icon from "../../../media/JobsIcons/settings.png";
import new_workflow from "../../../media/JobsIcons/newWorkflow.png";

import catalog_product_search from "../../../media/CatalogProductIcons/magnifying-glass.svg";
import catalog_product_tag from "../../../media/CatalogProductIcons/tag.svg";
import catalog_product_pencil from "../../../media/CatalogProductIcons/pencil.svg";
import catalog_product_download from "../../../media/CatalogProductIcons/download.svg";
import catalog_product_print from "../../../media/CatalogProductIcons/print.svg";
import catalog_product_envelope from "../../../media/CatalogProductIcons/envelope.svg";
import catalog_product_keywords from "../../../media/CatalogProductIcons/keywords.png";
import catalog_product_live from "../../../media/CatalogProductIcons/live.svg";
import catalog_product_master from "../../../media/CatalogProductIcons/master.svg";
import catalog_product_calendar from "../../../media/CatalogProductIcons/calendar.svg";
import catalog_product_warning from "../../../media/CatalogProductIcons/warning.svg";


import toolbar_icon_view from "../../../media/DetailBarSVGIcons/view.svg";
import toolbar_icon_filter from "../../../media/DetailBarSVGIcons/filter.svg";
import toolbar_icon_save from "../../../media/DetailBarSVGIcons/save.svg";
import toolbar_icon_tag from "../../../media/DetailBarSVGIcons/tag.svg";
import toolbar_icon_export from "../../../media/DetailBarSVGIcons/export.svg";
import toolbar_icon_email from "../../../media/DetailBarSVGIcons/email.svg";
import toolbar_icon_add from "../../../media/DetailBarSVGIcons/add.svg";
import toolbar_icon_keywords from "../../../media/DetailBarSVGIcons/keywords.svg";
import toolbar_icon_delete from "../../../media/DetailBarSVGIcons/delete.svg";


import scorecard_icon_copy from "../../../media/ScorecardsIcons/file.svg";
import scorecard_icon_delete from "../../../media/ScorecardsIcons/delete.svg";

type toolbaricons =
  | "toolbar-view"
  | "toolbar-filter"
  | "toolbar-save"
  | "toolbar-tag"
  | "toolbar-add"
  | "toolbar-export"
  | "toolbar-share"
  | "toolbar-keywords"
  | "toolbar-delete";

type platformicons =
  | "view"
  | "user"
  | "tag"
  | "question"
  | "email"
  | "download"
  | "save"
  | "filter"
  | "add"
  | "workflow"
  | "copy"
  | "delete"
  | "show"
  | "settings"
  | "new workflow";

type dashboardicons =
  | "dash assetscore"
  | "dash msrp improve"
  | "dash statusaccuracy"
  | "dash brandsparticipate"
  | "dash scoreimprovement"
  | "dash statushealth"
  | "dash keywordscore"
  | "dash sentimentscore"
  | "dash statuskeyword"
  | "dash marketingscore"
  | "dash skusimproved"
  | "dash scorelive"
  | "dash freecredits"
  | "dash teampartners"
  | "dash errors";

type catalogproducticons =
  | "product search"
  | "product pencil"
  | "product tag"
  | "product download"
  | "product print"
  | "product envelope"
  | "product keywords"
  | "product live"
  | "product master"
  | "product calendar"
    | "product warning";

type scorecardsicons = "scorecard copy" | "scorecard delete";

interface CSUI_IconProps {
  icon: platformicons | dashboardicons | catalogproducticons | toolbaricons | scorecardsicons;
  className?: string;
  style?: React.CSSProperties;
}

// This could be useful...
/* const isDashboardSvg = (iconLabel: string) => {
    switch (iconLabel) {
        case 'dash assetscore':
        case 'dash msrp improve':
        case 'dash statusaccuracy':
        case 'dash brandsparticipate':
        case 'dash scoreimprovement':
        case 'dash statushealth':
        case 'dash keywordscore':
        case 'dash sentimentscore':
        case 'dash statuskeyword':
        case 'dash marketingscore':
        case 'dash skusimproved':
        case 'dash scorelive':
            return true;
        default:
            return false;
    }
}

*/

const getIconSrc = (icon: CSUI_IconProps["icon"]) => {
  switch (icon) {
    case "toolbar-delete":
      return toolbar_icon_delete;
    case "toolbar-add":
      return toolbar_icon_add;
    case "toolbar-keywords":
      return toolbar_icon_keywords;
    case "toolbar-save":
      return toolbar_icon_save;
    case "toolbar-share":
      return toolbar_icon_email;
    case "toolbar-export":
      return toolbar_icon_export;
    case "toolbar-tag":
      return toolbar_icon_tag;
    case "toolbar-view":
      return toolbar_icon_view;
    case "toolbar-filter":
      return toolbar_icon_filter;
    case "add":
      return icon_add;
    case "download":
      return icon_download;
    case "email":
      return icon_email;
    case "filter":
      return icon_filter;
    case "question":
      return icon_question;
    case "save":
      return icon_save;
    case "tag":
      return icon_tag;
    case "user":
      return icon_user;
    case "view":
      return icon_view;
    case "workflow":
      return icon_workflow;
    case "dash assetscore":
      return icon_dashboard_asset_score;
    case "dash msrp improve":
      return icon_dashboard_msrp_improvement;
    case "dash statusaccuracy":
      return icon_dashboard_status_accuracy;
    case "dash brandsparticipate":
      return icon_dashboard_brands_participating;
    case "dash scoreimprovement":
      return icon_dashboard_score_improvement;
    case "dash statushealth":
      return icon_dashboard_status_health;
    case "dash keywordscore":
      return icon_dashboard_keyword_score;
    case "dash sentimentscore":
      return icon_ddashboard_sentiment_score;
    case "dash statuskeyword":
      return icon_dashboard_status_keyword;
    case "dash marketingscore":
      return icon_dashboard_marketing_score;
    case "dash skusimproved":
      return icon_dashboard_skus_improved;
    case "dash scorelive":
      return icon_dashboard_status_live_score;
    case "dash freecredits":
      return icon_dashboard_free_credits;
    case "dash teampartners":
      return icon_dashboard_team_and_partners;
    case "dash errors":
      return icon_dashboard_errors;
    case "copy":
      return copy_icon;
    case "delete":
      return delete_icon;
    case "show":
      return show_icon;
    case "settings":
      return settings_icon;
    case "new workflow":
      return new_workflow;
    case "product search":
      return catalog_product_search;
    case "product pencil":
      return catalog_product_pencil;
    case "product tag":
      return catalog_product_tag;
    case "product download":
      return catalog_product_download;
    case "product print":
      return catalog_product_print;
    case "product envelope":
      return catalog_product_envelope;
    case "product keywords":
      return catalog_product_keywords;
    case "product live":
      return catalog_product_live;
    case "product master":
      return catalog_product_master;
    case "scorecard copy":
      return scorecard_icon_copy;
    case "scorecard delete":
      return scorecard_icon_delete;
    case "product calendar":
      return catalog_product_calendar;
    case "product warning":
      return catalog_product_warning;
    default:
      return "";
  }
};

export const CSUIIcon: React.FC<CSUI_IconProps> = props => {
  const { className, style, icon } = props;
  const customProps = {
    className: `csui_icon ${className ? className : ""}`,
    style: {
      ...style
    } as React.CSSProperties
  };
  return (
    <i {...customProps}>
      <img src={getIconSrc(icon)} alt="" />
    </i>
  );
};
