import axios from 'axios';
import {API_URL} from '../../types/datastructures';

export function API_CALL_TeamBillingPlanType(teamId: string){
    return axios.post(`${API_URL}/team/billing/planType`, { teamId })
}

export function API_CALL_DeleteTeamInvite(inviteId: string){
    return axios.post(`${API_URL}/team/invite/delete`, { inviteId })
}

const API_URL_teamInvite = `${API_URL}/team-invite`;
export const API_CALL_teamInvite = (teamId: string, inviterId: string, users: any[]) => {
    return axios.post<any>(API_URL_teamInvite, {
        teamId: teamId,
        inviterId: inviterId,
        users: users
    });
}

export function API_CALL_DeleteUser(userId: string){
    return axios.delete(`${API_URL}/auth/user/${userId}`);
}
