import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';

interface Props {
  button: React.ReactNode;
  children: React.ReactNode;
}

const TabWrapper = ({ button, children, ...otherProps }: Props) => {
  return (
    <Grid
      {...otherProps}
      className='stepContent'
      container
      direction='row'
      spacing={2}
      alignItems='flex-start'>
      <Grid item xs={9} style={{ overflow: 'hidden' }}>
        {children}
      </Grid>
      <Grid item xs={3}>
        <Grid container justify='flex-start'>
          <Grid item>{button}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabWrapper;
