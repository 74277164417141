import { ActiveUserAccount } from '../types/datastructures';
import { SentryService } from './Reporting';
import { FcmService } from './fcm';
import { TrackingService } from '../Tracking';

class ScriptServicesClass {

    private initialized: boolean;

    constructor() {
        this.initialized = false;
    }

    public init = async () => {
        if (!this.initialized) {
            SentryService.init();
            const fcm = FcmService.init();
            const ts = TrackingService.init();
            await Promise.all([fcm, ts]);

            this.initialized = true;
        }
    }

    setUser = async (activeUser: ActiveUserAccount) => {    
        SentryService.setUser(activeUser);
        FcmService.setUser(activeUser);
        await TrackingService.setUser(activeUser);
    }
}

export const ScriptServices = new ScriptServicesClass();