import React from "react";
import { SyncedDivs, withSyncedDivsHOC } from "../../../../libraries/types/ReportUp";
import TextareaAutosize from 'react-autosize-textarea';
import './style.scss';
import { escapedRegex } from "../../../../libraries/Util/string";

interface SyncedTextAreaProps extends TextareaAutosize.Props {
    syncedDivs: SyncedDivs;
    groupId: string;
    itemId: string;
    highlight?: string[];
}

interface State {
    contents: string,
    focus: boolean
}

class SyncedTextArea extends React.Component<SyncedTextAreaProps, State> {

    // private divRef: React.RefObject<HTMLDivElement>;

    constructor(props: SyncedTextAreaProps) {
        super(props);

        // this.divRef = React.createRef<HTMLDivElement>();

        this.state = {
            contents: '',
            focus: false
        };
    }

    componentDidMount() {

        // this.onResizeHandler()


        const { syncedDivs, groupId, itemId } = this.props;

        syncedDivs.reportDivData(groupId, itemId, 10);

        syncedDivs.setExpand(groupId, true);
    }

    onResizeHandler = (event: Event) => {
        const { syncedDivs, groupId, itemId } = this.props;
        // console.log(event.target)

        const textArea = event.target as HTMLTextAreaElement;

        if (textArea === document.activeElement) {

            const domRect = textArea.getBoundingClientRect();

            syncedDivs.reportDivData(groupId, itemId, domRect.height);
        }
    }

    renderHighlightedText = (classNameStr: string) => {

        const content =
            this.state.contents || (this.props.defaultValue && this.props.defaultValue.toString());
        let data =
            (this.state.contents || (this.props.defaultValue && this.props.defaultValue.toString())).replace(/-/g, ' ');
        this.props.highlight.forEach((word: string) => {
            data = data.replace(escapedRegex(`${word.replace(/-/g, ' ')}`, 'gi'), `[keyword]${word}[keyword]`)
        });

        const contents = data.split("[keyword]");
        const iKeywords = this.props.highlight.map((word: string) => word.replace(/-/g, ' ').toLowerCase())

        return <div onClick={() => this.setState({ focus: true })} className={classNameStr}>
            {contents.filter((text: string) => text !== "").map((word: string, index: number) => {
                const wordIndex = contents.filter((text: string) => text !== "").slice(0, index).join("").length;
                const originalWord = content.slice(wordIndex, wordIndex + word.length);
                if (iKeywords.includes(word.replace(/-/g, ' ').toLowerCase())) {
                    return <span className="highlighted-word">{originalWord}</span>;
                } else {
                    return `${originalWord}${index !== (contents.length - 1) ? ' ' : ""}`;
                }
            })}
        </div>
    };

    hasHighlightedWords = () => {
        const content = this.state.contents || ((this.props.defaultValue && this.props.defaultValue.toString()) || '').replace(/-/g, ' ');

        return this.props.highlight.find((keyword: string) => escapedRegex(keyword.replace(/-/g, ''), "gi").test(content) ? true : false)
            ? true
            : false;
    }

    render() {
        const { syncedDivs, groupId, onResize, className, itemId, ...restProps } = this.props;

        const classes = ['syncedTextArea'];
        classes.push(syncedDivs.groupClassname(groupId));
        if (className) {
            classes.push(className);
        }
        const classNameStr = classes.join(" ");

        const shouldRenderKeywordHighlights = (
            this.props.highlight
            && this.props.highlight.length > 0
            && !this.state.focus
        ) && this.hasHighlightedWords();
        return shouldRenderKeywordHighlights
            ? this.renderHighlightedText(classNameStr)
            : <textarea
                autoFocus={this.state.focus}
                onBlur={() => this.setState({ focus: false })}
                className={classNameStr}
                {...restProps}
            />
    }
}

export default withSyncedDivsHOC(SyncedTextArea)//SyncedDivTest;