import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    subTitle: {
        fontWeight: 'initial',
        marginTop: theme.spacing(1.5),
        textAlign: 'center',
    },
    container: {
        ['@media (min-width: 1280px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        },
        ['@media (min-width: 1680px)']: { // eslint-disable-line no-useless-computed-key
            width: '80%',
            margin: '0 auto'
        }
    },
    divider: {
        width: '93%',
        marginTop: 30,
        marginBottom: 21,
        marginLeft: 35,
        ['@media only screen and (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
            marginLeft: 0,
            marginTop: 50,
            width: '100%'
        },
    }
}));

export default useStyles;
