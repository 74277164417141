import React from 'react';
import { JobProduct, WorkFlowStage } from '../../datastructures';
import StatusSnapshot from '../../ProductAudit/StatusSnapshot';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { SkuRecord } from '../../../../pages/Catalog/catalog-datamodel';
import moment from 'moment';
import { ImagePopup } from '../AssetPopup';
import { retailerNameRender } from '../../../../libraries/types/datastructures';
import Grid from '@material-ui/core/Grid/Grid';

import './style.scss';

interface ArchivedVersionProps {
  product: JobProduct;
  skuRecord: SkuRecord;
  skuStage?: WorkFlowStage;
  setWorkflowStage: (workflowStages: WorkFlowStage[]) => void;
}

export default class ProductAudit extends React.Component<ArchivedVersionProps> {
  private stages = [
    'not-reviewed',
    'in-progress',
    'review',
    'approved',
    're-submitted',
  ];

  renderCategories = () => {
    const { Categories } = this.props.skuRecord.skuData;

    return Categories.labels
      .map(
        (label, idx) => `${idx === 0 ? '' : ' > '}${Categories.values[label]}`
      )
      .join('');
  };

  dropdown = () => {
    const { setWorkflowStage } = this.props;
    const options = this.stages.map((stage: string, index: number) => {
      return {
        text: stage
          .split('-')
          .map((part) => `${part[0].toLocaleUpperCase()}${part.slice(1)}`)
          .join(' '),
        value: stage,
        checked: stage === this.props.skuStage,
      };
    });
    const getValueFn = (e) => {
      e.persist();
      setWorkflowStage([e.target.value]);
    };

    return (
      <div className=''>
        <select
          className='csui-select' //icon-input select"
          value={this.props.skuStage}
          onChange={(e) => getValueFn(e)}>
          {options.map((el) => (
            <option key={el.value} value={el.value}>
              {el.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    const { skuRecord } = this.props;
    const { skuData } = skuRecord;

    const { Images } = skuRecord.skuData.Assets;
    const mainImagePopup = (
      <ImagePopup value={Images.values[Images.labels[0]]} />
    );

    return (
      <Card className='dark-container archived-version-container'>
        <CardContent className='no-padding top-card'>
          <Grid container>
            <Grid item xs={2}>
              <h1 className='dual'>
                Archived<span>Version</span>
              </h1>
            </Grid>
            <Grid item xs={2}>
              <div className='row'>
                <label className='field'>Data Source</label>
                <label className='field solid indent'>
                  {retailerNameRender(skuRecord['retailer'])}
                </label>
              </div>
              <div className='row'>
                <label className='field'>Run Date</label>
                <label className='field solid indent'>
                  {moment(skuRecord.timestamp).format('M/D/YYYY')}
                </label>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className='row'>
                <label className='field'>UPC</label>
                <label className='field solid indent'>
                  {skuRecord.skuData.UID['UPC (GTIN-12)'] || <>&zwnj;</>}
                </label>
              </div>
              <div className='row'>
                <label className='field'>Retailer ID</label>
                <label className='field solid indent'>
                  {skuRecord.skuData.UID['Retailer UID'] || <>&zwnj;</>}
                </label>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className='row'>
                <label className='field'>Model</label>
                <label className='field solid indent'>
                  {skuRecord.skuData.UID.Model || <>&zwnj;</>}
                </label>
              </div>
              <div className='row'>
                <label className='field'>Manufacturer Part #</label>
                <label className='field solid indent'>
                  {skuRecord.skuData.UID['Manufacturer Part Number'] || (
                    <>&zwnj;</>
                  )}
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className='no-padding-right'>
              <Grid container spacing={2} alignItems='center'>
                <Grid
                  item
                  xs={6}
                  className='workflow-stage-header text-right no-padding-right'>
                  <b className='label'>Workflow Stage</b>
                </Grid>
                <Grid item xs={6} className=''>
                  <div className='workflow-select'>{this.dropdown()}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent className='white-container'>
          <div className='archive-version-info'>
            <div className='main-content equal-height'>
              <div className='label-line product-category'>
                {this.renderCategories()}
              </div>
              <div className='inline-block'>
                <div className='product-image small pull-left'>
                  {mainImagePopup}
                </div>
                <a
                  className='product-link'
                  href={skuData.UID.URL}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    marginTop: '-12px',
                  }}>
                  {skuData.UID['Product Name']}
                </a>
                <div className='label-line'>By {skuData.UID['Brand Name']}</div>
              </div>
            </div>
            <div className='prices equal-height'>
              <StatusSnapshot skuRecord={skuRecord} />
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}
