import { ActiveUserAccount } from "../types/datastructures";

const addScriptToElement = (src: string, element: HTMLElement) => {
    const script = document.createElement("script");
    script.type = 'text/javascript';
    const scriptText = document.createTextNode(src);
  
    script.appendChild(scriptText);
    element.appendChild(script);
}

const addScriptToHead = (src: string) => addScriptToElement(src, document.head!);
const addScriptToBody = (src: string) => addScriptToElement(src, document.body);

const addRemoteScriptToElement = (src: string, element: HTMLElement) => {
    const script = document.createElement("script");
    script.src = src;

    element.appendChild(script);
}

// const addRemoteScriptToHead = (src: string) => addRemoteScriptToElement(src, document.head!);
const addRemoteScriptToBody = (src: string) => addRemoteScriptToElement(src, document.body);

const hotjarScript = 
// <!-- Hotjar Tracking Code for www.contentstatus.com -->
// <script>
`
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1097302,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;
// </script>

const inlineManualApiKey = "9e88c8e6a1fe8fdc8a7a446ebd3af8fa";
const imBarePlayer = false;

//https://help.inlinemanual.com/docs/single-page-app-and-people-tracking-angular-react-ember
const inlineManualScripts = [
    `window.inlineManualOptions = { language: 'en'};`,
    // `!function(){var e=document.createElement("script"),t=document.getElementsByTagName("script")[0];e.async=1,e.src="https://inlinemanual.com/embed/player.${inlineManualApiKey}.js",e.charset="UTF-8",t.parentNode.insertBefore(e,t)}();`,
    `!function(){
        var e=document.createElement("script"),t=document.getElementsByTagName("script")[0];
        e.async=1,e.src="https://inlinemanual.com/embed/player.${inlineManualApiKey}${imBarePlayer ? ".bare" : ""}.js",e.charset="UTF-8",t.parentNode.insertBefore(e,t)}();`
].slice(0,2);

const chargebeeScriptUrl = "https://js.chargebee.com/v2/chargebee.js";

const tooltipIoScript = 
// <!--- Tooltip player -->
// <script type='text/javascript'>
`
window.Tooltip||function(t,e){var o={
url:"https://cdn.tooltip.io/static/player.js",
key:"f89917f2-1e11-416a-8764-e9b70e205a79",
async:true
};
window.Tooltip={cs:[],_apiKey:o.key};for(
var r=["identify","goal","updateUserData","start","stop","refresh","show","hide","on"],
i={},n=0;n<r.length;n++){var a=r[n];i[a]=function(t){return function(){var e=Array.prototype.slice.call(arguments);
window.Tooltip.cs.push({method:t,args:e})}}(a)}window.Tooltip.API=i;var n=t.createElement(e),s=t.getElementsByTagName(e)[0];
n.type="text/javascript",n.async=o.async,s.parentNode.insertBefore(n,s),n.src=o.url}(document,"script");
`;

const jotFormDSOSSetup = (activeUser?: ActiveUserAccount) => {
    let iframeParameters = ''
    if (activeUser?.user?.core?.email) {
        iframeParameters = ", iframeParameters: "+JSON.stringify({
            enterYour: activeUser.user.core.email,
            app_metadata: JSON.stringify({
                teamId: activeUser.user.core.teamId,
                userId: activeUser.user.core.userId
            })
        });
    }
    addRemoteScriptToBody("https://form.jotform.com/static/feedback2.js");
    addScriptToBody(`
        var setup = function(){ var JFL_212365344098155 = new JotformFeedback({ formId: '212365344098155', base: 'https://form.jotform.com/'${iframeParameters}, windowTitle: 'SOS Beta Pop Up', background: 'indigo', fontColor: '#FFFFFF', type: 'false', height: 700, width: 800, openOnLoad: true });  }
        var intId = setInterval(intCheck, 1000);
        var intCheck = function(){ 
            if (JotformFeedback) {
                clearInterval(intId);
                setup();
            }
        }
    `);
    
    const anchor = document.createElement("a");
    anchor.id = "jotform-dsos";
    anchor.className = "btn lightbox-212365344098155 displayNone";
  
    document.body.appendChild(anchor);
}

// </script>
// <!--- Tooltip player -->
  
/*
const liveAgentButtonScript = 
// <script type="text/javascript">
`
(function(d, src, c) { 
var t=d.scripts[d.scripts.length - 1],
s=d.createElement('script');
s.id='la_x2s6df8d';s.async=true;s.src=src;
s.onload=s.onreadystatechange=function(){
    var rs=this.readyState;
    if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};
    t.parentElement.insertBefore(s,t.nextSibling);
})(document, 'https://contentstatus.ladesk.com/scripts/track.js', function(e){});
`
// </script>
*/

class ThirdPartyScriptServiceClass {

    private isSetup: boolean | undefined;
    private chargebeeSetup: boolean | undefined;

    constructor() {
        this.isSetup = false;
        this.chargebeeSetup = false;
    }

    setupChargebeeScript = () => {
        if (!this.chargebeeSetup) {
            addRemoteScriptToBody(chargebeeScriptUrl);

            this.chargebeeSetup = true;
        }
    }

    setupThirdPartyScripts = (activeUser?: ActiveUserAccount) => {
        if (!this.isSetup) {
            // console.debug("Initializing 3rd Party scripts...");

            // tslint:disable-next-line:no-empty
            // const addScriptToHead1 = (..._: any[]) => {};
        
            //addScriptToHead(hotjarScript);
            this.setupChargebeeScript();
            //addScriptToBody(tooltipIoScript);
            // addScriptToBody(hotjarScript);
            //inlineManualScripts.forEach(addScriptToBody);
            jotFormDSOSSetup(activeUser);

            this.isSetup = true;
        }
    }
}

export const ThirdPartyScriptService = new ThirdPartyScriptServiceClass();