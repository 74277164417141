import { SkuRecord } from '../../pages/Catalog/catalog-datamodel';

const checkFlagType = (flagType: string, flaggedItem) => typeof flaggedItem === 'object' && flaggedItem.hasOwnProperty('flagPriority') && flaggedItem.flagPriority && flaggedItem.flagPriority.toLowerCase() === flagType

export function calculateFlaggedItems(sku: SkuRecord) {
    const criticalFlags = [];
    const warningFlags = [];
    const indicatorFlags = [];

    const flaggedData = sku.status.health.flagged.flaggedItems;

    if(!flaggedData) {
        return {
            criticalFlags: [],
            warningFlags: [],
            indicatorFlags: [],
            criticalFlagsCount: 0,
            warningFlagsCount: 0,
            indicatorFlagsCount: 0,
            totalFlaggedItems: 0
        };
    }

    const ruleIds = Object.keys(flaggedData);
    for (const ruleId of ruleIds) {
        const ruleResultData = flaggedData[ruleId];

        if (checkFlagType('critical', ruleResultData)) {
            criticalFlags.push(ruleResultData)
        } else if (checkFlagType('warning', ruleResultData)) {
            warningFlags.push(ruleResultData)
        } else if (checkFlagType('indicator', ruleResultData)) {
            indicatorFlags.push(ruleResultData)
        }
    }
    const totalFlaggedItems = criticalFlags.length + warningFlags.length + indicatorFlags.length;
    return {
        criticalFlags,
        warningFlags,
        indicatorFlags,
        criticalFlagsCount: criticalFlags.length,
        warningFlagsCount: warningFlags.length,
        indicatorFlagsCount: indicatorFlags.length,
        totalFlaggedItems
    }
}

export function getLatestRubric(sku: SkuRecord) {
    let id = 'default';

    if (sku.status?.health) {
        const rubrics = Object.keys(sku.status.health.rubric);

        let maxTimestamp = 0;

        for (const rubric of rubrics) {
            const scoresData = sku.status.health.rubric[rubric].scores;
            const timestamp = scoresData?.timestamp || 0;
            if (timestamp > maxTimestamp) {
                maxTimestamp = timestamp;
                id = rubric;
            }
        }
    }

    return id;
}
