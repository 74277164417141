import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Column,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
// import { VirtualTableState } from '@devexpress/dx-react-grid';
import { Grid, Table, VirtualTable, TableHeaderRow, TableColumnResizing, /* TableFixedColumns */ } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { RT_Column } from '../../CSUITable';
import { useState } from 'react';

const onClick = (onSort, sortingEnabled, children) => (e) => {
  // console.log(sortingEnabled);

  if (!sortingEnabled) { return; }

  const isActionKeyDown = e.keyCode === 13 || e.keyCode === 32;
  const isMouseClick = e.keyCode === undefined;

  const cancelSortingRelatedKey = e.metaKey || e.ctrlKey;
  const direction = (isMouseClick || isActionKeyDown) && cancelSortingRelatedKey
    ? null
    : undefined;

  e.preventDefault();

  onSort({
    direction,
    keepOther: e.shiftKey || cancelSortingRelatedKey,
  });
};

const styles = {
  addIcon: {
    '&:before': {
      left: 55,
      top: 20,
      content: '"➕"',
      display: 'block',
      position: 'absolute',
    },
  },
};

const columnArray = (propsColumns: RT_Column[]) => {

  const columnExtensions: Table.ColumnExtension[] = []
  const columnWidths: { [columnName: string]: TableColumnWidthInfo } = {};

  const onSort = (...args) => console.log(args);

  const columns = propsColumns.map((column, idx) => {

    if (!column.columns) {
        column.columns = [column]
        
    }

    if (!column.columns[0].id) {
        column.columns[0].id = idx.toString()
    }

    const dxColumnBase: Column = {
      name: column.columns[0].id,
      title: column.columns[0].id,
      getCellValue: (row: any, columnName: string) => (column.columns[0].Cell as any)({ original: row }) // (columnName)
    }

    const colWidth = column.columns[0].width;
    const colMinWidth = column.columns[0].minWidth;

    const width = (colWidth && typeof colWidth === 'number')
      ? colWidth + 26
      : (colMinWidth && typeof colMinWidth === 'number')
        ? colMinWidth + 26
        : 70;
    columnExtensions.push({ columnName: column.columns[0].id, width })
    columnWidths[column.columns[0].id] = { columnName: column.columns[0].id, width };
    const dxColumn = {
      ...dxColumnBase,
      topHeader: column.Header,
      bottomHeader: column.columns[0].Header,
      cell: column.columns[0].Cell,
      onSort
    }

    return dxColumn;
  });

  return {
    columns,
    columnExtensions,
    columnWidths
  }
}

type CustomColumn = ReturnType<typeof columnArray>['columns']

interface CSUIDxReactGridProps {
  columns: RT_Column[];
  getRowId?: (row: any) => string;
  isDataLoading: boolean;
  numSkus: number;
  rows: any[];
  sort?: {
    sorting: Array<{ columnName: string, direction: 'asc' | 'desc' }>;
    fn: (sortOps: Array<{ columnName: string, direction: 'asc' | 'desc' }>) => void
  }
}

interface SortLabelBaseState {
}

const emptyFunction = () => { };

class SortLabelBase extends React.PureComponent<any, SortLabelBaseState> {

  constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { onSort, classes, ...restProps } = this.props;

    const { name, title, /* onSort, */ ...expandedCol } = restProps.column as CustomColumn[0];

    const sortLabel = (name !== "checkbox" && name !== "image")
      ? (
        <TableHeaderRow.SortLabel
          {...(restProps as TableHeaderRow.SortLabelProps)}
          className={classes.sortLabel}
          onClick={onClick(onSort, true, undefined)}
          onSort={emptyFunction}
        >
          {expandedCol.bottomHeader}
        </TableHeaderRow.SortLabel>
      )
      : expandedCol.bottomHeader;

    return sortLabel;
  }
}
const SortLabel = withStyles(styles, { name: "SortLabel" })(SortLabelBase) as typeof TableHeaderRow.SortLabel //.sortLabelComponent;

/*
const HeaderCellBase = React.memo<any>(({ classes, children, ...restProps }) => {
  const { name, title, ...expandedCol } = restProps.column as CustomColumn[0];
  return <TableHeaderRow.Cell key={name} {...(restProps as TableHeaderRow.CellProps)} className={classes.cell} >
    <div>{expandedCol.topHeader}</div>
    {children}
  </TableHeaderRow.Cell>;
});
const HeaderCell = withStyles(styles, { name: "HeaderCell" })(HeaderCellBase);
 */

const ContentBase = React.memo<any>(({ classes, children, ...restProps }) => {
  const { name, title, ...expandedCol } = restProps.column as CustomColumn[0];
  return <TableHeaderRow.Content {...(restProps as TableHeaderRow.ContentProps)} className={classes.content}>
    <div>{expandedCol.topHeader}</div>
    {children}
  </TableHeaderRow.Content>;
});
const Content = withStyles(styles, { name: "Content" })(ContentBase);

const Root: React.FC<Grid.RootProps> = props => <Grid.Root {...props} style={{ height: '100%' }} />;

interface CSUIDxReactGridState {
  
}

const RenderTable: React.FC<CSUIDxReactGridProps> = (props) => {
  // getTableData, isDataLoading, numSkus
  const { getRowId, rows, sort } = props
  const { columns, columnExtensions, columnWidths } = columnArray(props.columns);
  
  const [stateColumnWidths, setColumnWidths] = useState<{ [columnName: string]: TableColumnWidthInfo }>({});
  
  const columnWidthsArray = Object.values({
    ...columnWidths,
    ...stateColumnWidths
  });

  const setNewWidth = (newWidths: TableColumnWidthInfo[]) => {
      const newColumnWidths = { ...stateColumnWidths };
      newWidths.forEach(newWidth => {
        newColumnWidths[newWidth.columnName] = newWidth;
      });
      setColumnWidths(newColumnWidths);
  }

  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
        rootComponent={Root}
        getRowId={row => row.skuId}
        {...(getRowId ? { getRowId } : {})}
      >
        {sort && (
          <SortingState
            sorting={sort.sorting}
            onSortingChange={(sortOps) => sort.fn(sortOps)}
          />
        )}
        {/* <VirtualTableState
          infiniteScrolling
          loading={isDataLoading}
          totalRowCount={numSkus}
          pageSize={20}
          skip={0}
          getRows={getTableData}
        /> */}
        <VirtualTable
          height={"auto"}
          columnExtensions={columnExtensions}
        />
        <TableColumnResizing
          columnWidths={columnWidthsArray}
          onColumnWidthsChange={setNewWidth}
        />
        <TableHeaderRow
          {...(sort ? {
            showSortingControls: true, 
            sortLabelComponent: SortLabel
          } : {titleComponent: () => { return null}})}
          // cellComponent={HeaderCell as any}
          contentComponent={Content as any}
        />
        {/* <TableFixedColumns leftColumns={["checkbox", "image"]} /> */}
      </Grid>
    </Paper>
  );
}

export class CSUIDxReactGrid extends React.PureComponent<CSUIDxReactGridProps, CSUIDxReactGridState> {

  constructor(props: CSUIDxReactGridProps) {
    super(props);

    this.state = {
      
    };
  }

  componentDidMount() {
  }

  render() {
    const classes = ['CSUIDxReactGrid'];
    const className = classes.join(" ");

    return (
      <div {...{ className }}>
        <RenderTable
          {...this.props}
        />
      </div>
    );
  }
}
