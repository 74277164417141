import React, { useRef, useState } from 'react';
import { PlanSize } from '../../types';
import PlanTab from '../PlanTab';
import styles from './styles';

interface Props {
  plans: PlanSize[];
  activePlan: string;
  onSelect: (plan: string) => void;
  children: React.ReactNode;
  onTermChange: (termSize: string) => void
  termSize: string
}

const PlanSelect: React.FunctionComponent<Props> = (props) => {
  const { activePlan, plans, children, onSelect, onTermChange, termSize } = props;
  const myRef: any = useRef();
  const [scrollLef, setScrollLeft] = useState(0);
  const classes = styles();
  const isActive = (key: string) => key === activePlan;

  const anyActive = !!plans.filter(plan => isActive(plan.key)).length;

  const onScrollPlans = () => {
    const scrollLeftValue: any = myRef.current.scrollLeft
    setScrollLeft(scrollLeftValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.planSelect}>
        <div className={anyActive ? classes.header : classes.headerNoneActive}>
          <h1 className={classes.title}>Plan Sizes</h1>
          
          <p className={classes.description}>
            Based on your input, here are plans with the number of product page
            audits you’ll need.
          </p>
          <div style={{padding: '0 50px'}}>
            <div className={classes.planTabs}>
              <PlanTab
                tabs={['Monthly', 'Annual']}
                onSelect={onTermChange}
                active={termSize}
              />
              <h5 className={classes.tabSubTitle}>Save 20% with annual pre-pay</h5>
            </div>
          </div>
          <h4 className={classes.instruction}>
            Click plans below for more details
          </h4>
        </div>

        {plans.map(({ key, price, description, recommended: recomended }) => (
          <div
            className={classes.plan}
            key={key}
            onClick={onSelect.bind(null, key)}>
            <div className={classes.planContent}>
              {isActive(key) && (
                <div className={classes.planActive}>
                  <h1 className={classes.planPriceActive}>{price.toLocaleString().replace(/\s/g, "")}</h1>
                  <div className={classes.planPriceDescription}>
                    {description}
                  </div>
                </div>
              )}
              {!isActive(key) && (
                <div className={classes.planPrice}>{price.toLocaleString().replace(/\s/g, "")}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        ref={myRef}
        onScroll={onScrollPlans}
        className={classes.planColumns}
      >
        {children}
      </div>
      {scrollLef < 10 && (
        <div className={classes.planOverlay}/>
      )}
    </div>
  );
};

export default PlanSelect;
