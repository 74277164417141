import React from 'react';
import Logo from '../../../layouts/MainLayout/Logo';
import { AuthClientService } from '../../../libraries/Auth';
import { CSUIButton, CSUISegment } from '../../CSUI';

const EmailVerification: React.FunctionComponent<{}> = (props) => {
    return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100%"
    }} >
        <CSUISegment style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 400,
            fontSize: '1.2rem'
        }} >
            <Logo style={{maxHeight: 40}} />
            <div style={{
                marginTop: 20,
                textAlign: 'center',
            }}>
                <p>
                    Thanks for registering!
                </p>
                <p>
                    Please check your email inbox for the verification link. Only once your email address is verified can you access the Content Status platform.
                </p>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <span style={{cursor: 'pointer'}}>
                        Click here to try and login again:
                    </span>
                    <CSUIButton
                        style={{ marginLeft: 20 }}
                        slim
                        color={"green"}
                        onClick={() => AuthClientService.signOut()}
                    >
                        Login
                    </CSUIButton>
                </div>
            </div>
        </CSUISegment>
    </div>
}

export default EmailVerification;