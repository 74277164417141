import * as React from 'react';
import { MDBInput } from 'mdbreact';
import './style.scss';
import {Checkbox, FormControlLabel} from "@material-ui/core";


type CSUI_CheckboxProps = {
    id: string;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void
    color?: 'purple' | 'grey' | 'green' | 'blue' | 'red'
    className?: string
    checked?: boolean
    // active?: boolean
    // outline?: boolean
    // slim?: boolean
    label?: string | number | JSX.Element
    style?: React.CSSProperties
    // flat?: boolean,
    disabled?:boolean,
    // rounded?:boolean
}

type CSUI_CheckboxPrimaryProps = {
    id: string
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void
    className?: string
    checked?: boolean
    label?: string | number | JSX.Element
    disabled?: boolean
    color?: string
}

export const CSUICheckbox: React.FC<CSUI_CheckboxProps> = (props) => {
    const {
        className, children, label, style, color, disabled, 
        id, checked,
        /* outline, flat, slim, rounded, active, */  
        ...restProps
    } = props;
    const classes = ['csui_checkbox'];
    if (className) {
        classes.push(className);
    }
    switch (color) {
        case 'green':
            classes.push("csui_green");
            break;
        case 'grey':
            classes.push("csui_grey");
            break;
        case 'blue':
            classes.push("csui_blue");
            break;
        case 'red':
            // moreClasses.push(outline?"csui_blue outlined":"csui_blue");
            classes.push("csui_red");
            break;
        case 'purple':
        default:
            classes.push("csui_purple");
            break;
    }
    /* if (outline) {
        moreClasses.push("outline");
    }
    if (flat === undefined || flat === true) {
        moreClasses.push("csui_button_flat");
    } */
    const customProps = {
        disabled,
        // active,
        // outline,
        className: classes.join(" "),
        // ...(colorValue ? {color: colorValue} : {}),
        ...((checked !== undefined) ? {checked} : {}),
        style: {
            fontFamily: "'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            // fontWeight: 700,
            // fontSize: '1rem',
            // textTransform: 'none',
            // margin: "0",
            // marginRight: 4,
            /* borderRadius: (rounded || rounded === undefined) ? 5 : 0,
            ...(slim ? {padding: ".5em 1em"} : {}),
            ...(outline ? {borderWidth: 1} : {}), */
            ...style
        } as React.CSSProperties
    };

    const chxBox = <MDBInput 
        type="checkbox"
        id={id}
        label={label as any} 
        filled={true}

        {...restProps}

        {...customProps}
    />;

    return chxBox; //<MDBBtn {...restProps} {...customProps} >{label || children}</MDBBtn>
}

export const CSUICheckboxPrimary: React.FC<CSUI_CheckboxPrimaryProps> = React.memo(({label, color = "primary", ...props}) => {
    return <FormControlLabel
                control={
                    <Checkbox {...props}/>
                }
                label={label}
            />
})
