import * as React from 'react';

interface DashboardProgressBarProps {
    progress: number,
    color: string
}

const DashboardProgressBar = (props: DashboardProgressBarProps) => {
    return <div style={{ background: "#d8d8d8", height: "7px", marginBottom: '2px',width: '100%'}}>
        <div style={{
            width: `${props.progress}%`,
            background: props.color, height: "7px"
        }}></div>
    </div>
}
export default DashboardProgressBar;

