import * as React from 'react';
import { MDBSelect } from 'mdbreact';
import './style.scss'; 

type mdbselectProps = MDBSelect['props']

type Mutable<T> = {
    -readonly[P in keyof T]: T[P]
};

interface CSUISelectProps extends mdbselectProps {
    value?: string;
    values?: string[];
    onChange?: (values: string[]) => void;
}

const controlledProps = (props: CSUISelectProps) => {
    const {options, value, values, onChange} = props;

    const newProps = {

    } as Partial<Mutable<Pick<mdbselectProps, "options" | "getValue">>>;

    if (value || values) {
        const valueArr = (value)
        ? [value]
        : values;

        newProps.options = options.map(obj => ({
            ...obj,
            checked: valueArr.indexOf(obj.value) > -1
        }));
    }

    if (onChange) {
        newProps.getValue = onChange;
    }

    return newProps;
}

export const CSUISelect: React.FC<CSUISelectProps> = (props) => {
    
    const {
        className,
        ...restProps
    } = props;
    
    const classes = ['csui_select'];

    if (className) {
        classes.push(className);
    }
    
    const customProps = {
        className: classes.join(" "),
        ...controlledProps(props)
    };

    const switchComponent = <MDBSelect 
        {...restProps}

        {...customProps}
    />;

    return switchComponent; 
}
