import * as React from 'react';
import { MDBSwitch } from 'mdbreact';
import './style.scss'; 

export const CSUISwitch: React.FC<MDBSwitch['props']> = (props) => {
    
    const {
        className,
        ...restProps
    } = props;
    
    const classes = ['csui_switch'];

    if (className) {
        classes.push(className);
    }
    
    const customProps = {
        className: classes.join(" ")
    };

    const switchComponent = <MDBSwitch 
        {...restProps}

        {...customProps}
    />;

    return switchComponent; 
}