const commonTypes = [
    'marketingScore',
    'assetScore',
    'overallScore',
    'sentimentScore',
    'ratingScore',
    'richContentScore',
    'titleScore',
    "bulletScore",
    "shortDescriptionScore",
    "imageScore",
    'documentScore',
    'videoScore',
    'enhancedContentScore',
    'richContentScore',
    'reviewScore',
    'view360Score',
    'sentimentScore'
]

export const ReportFieldsTypes = {
    BrandCategory: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'brand',
        ...commonTypes
    ],
    BrandRetailer: [
        'brand',
        'retailer',
        ...commonTypes
    ],
    RetailerBrandCategory: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'retailer',
        'brand',
        ...commonTypes
    ],
    CategoryBrand: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'brand',
        ...commonTypes
    ],
    CategoryRetailer: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'retailer',
        'brand',
        ...commonTypes
    ],
    PerfectSku: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'brand',
        ...commonTypes
    ],
    RetailerCategoryBrand: [
        'mainCategory',
        'subcategory1',
        'subcategory2',
        'subcategory3',
        'retailer',
        'brand',
        ...commonTypes
    ]
}

export const ReportLabels = {
    BrandCategory: 'Brand to Category Ranking',
    BrandRetailer: 'Brand to Retailer Ranking',
    RetailerBrandCategory: 'Retailer to Brand to Category Ranking',
    CategoryBrand: 'Category to Brand Ranking',
    CategoryRetailer: 'Category to Retailer Ranking',
    PerfectSku: 'Perfect SKU™',
    RetailerCategoryBrand: 'Retailer to Category to Brand Ranking',
    TagsRetailerCategoryBrand: 'Tags to Retailer to Category to Brand Ranking',
    JobsRetailerCategoryBrand: 'Jobs to Retailer to Category to Brand Ranking'
}

export const ReportHeaders = {
    BrandCategory: 'Brand',
    BrandRetailer: 'Brand / Retailer',
    RetailerBrandCategory: 'Retailer',
    CategoryBrand: 'Main Category / Brand',
    CategoryRetailer: 'Main Category / Retailer',
    RetailerCategoryBrand: 'Retailer / Brand',
    TagsRetailerCategoryBrand: 'Tags / Brand',
    JobsRetailerCategoryBrand: 'Jobs / Brand'
}

export const ReportNames = {
    BrandCategory: 'BrandCategory',
    BrandRetailer: 'BrandRetailer',
    RetailerBrandCategory: 'RetailerBrandCategory',
    CategoryBrand: 'CategoryBrand',
    CategoryRetailer: 'CategoryRetailer',
    PerfectSku: 'PerfectSku',
    RetailerCategoryBrand: 'RetailerCategoryBrand',
    TagsRetailerCategoryBrand: 'TagsRetailerCategoryBrand',
    JobsRetailerCategoryBrand: 'JobsRetailerCategoryBrand'
}
