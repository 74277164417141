import React from 'react';
import "./style.scss";
import {
    Icon,
    Tab, Menu, Header, Dimmer, Message, Popup, Loader
} from 'semantic-ui-react';
import {
    RetailerAdminRecord, CoreJob, BrandJobStatusChecks, Objectkeys,
    assignDotted, assignDottedArray, Objectentries, RandomId, getCoreFromUserAccount, ObjectKeyValuePairs, appendToTextarea
} from '../../libraries/types/datastructures';
import { GlobalState } from '../../libraries/types/ReportUp';
import { DropFilesEventHandler } from 'react-dropzone';
import * as Papa from 'papaparse';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import { querystringparser, coreJobDataGet, firestoreDb } from '../../libraries/Util';
import { CSUIButton, CSUIButtonCloseX, CSUISegment, CSUITabs, } from '../CSUI';
import Step1Component from './Steps/step1';
import Step2Component from './Steps/step2';
import Step3Component from './Steps/step3';
import Step4Component from './Steps/step4';
import SuccessDialog from "../Dialogs/successDialog";
import {
    newJobData_submit, newJobData_saveDraft,
    newJobData_parseUploadedCsv, newJob_default_jobData
} from './data';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasePageLayout from '../../layouts/BasePageLayout';
import GlobalStateProvider from '../GlobalStateProvider';
import TabWrapper from './Steps/TabWrapper';
import { mapValues } from 'lodash';
import { RetailerRecordList } from '../../libraries/Util/constants';
import { TrackingService } from '../../libraries/Tracking';

export interface NewJobProps extends RouteComponentProps {
    globalState: GlobalState;
}

const default_job = (props: NewJobProps): CoreJob => {
    const jobId = RandomId(); // TODO: consider uuidv5 for namespaces
    return {
        jobId,
        jobNum: parseInt(jobId.slice(-4), 16).toString(),
        jobData: newJob_default_jobData(),
        jobName: '',
        owner: (props.globalState.activeUser.isSignedIn) ? props.globalState.activeUser.user.displayName! : '',
        statusChecks: ['Live', 'Health'],
        statusReportMap: {},
        dateOpened: (new Date()).valueOf(),
        stage: 'Incomplete Job',
        retailerInfo: {
            retailerId: '',
            retailerFieldMap: {},
            retailerName: ''
        },
        schedule: {
            start: {
                when: 'now'
            },
            liveStatus: {
                frequency: {
                    how: 'once'
                }
            },
            recur: {
                how: 'once'
            }
        },
        meta: {
            sku: {
                checkType: {
                    new: false,
                    edit: false
                },
                submitDate: {

                }
            }
        }
    };
};

type reportChooser = {
    [x in BrandJobStatusChecks]?: boolean;
}

export interface Job {
    retailerRecords: { [x: string]: RetailerAdminRecord };
    selectedRetailer?: RetailerAdminRecord;
    coreJob: CoreJob;
    reportTypes: reportChooser;
    paste: {
        textarea: string[];
        field: 'upc' | 'toolId' | 'url' | 'asin' | 'productId'
    };
    loadMethod?: 'csv' | 'paste';
}

export interface NewJobState {
    view: {
        jobIdParam?: string;
        loadingData: boolean;
        newInterface: boolean;
        activeIndex: number;
        jobNameClick: boolean;
        legal: {
            credits: boolean;
            accuracy: boolean;
        }
        errors: {
            message: boolean;
            saveButton: boolean;
            isUniqueName: boolean;
            uidErrors?: {
                [idFieldName: string]: {
                    tests: {
                        notLengthTwelve?: string[];
                        repeated: {
                            key: string | number;
                            value: number;
                        }[];
                        notNumeric: string[];
                    };
                    idFieldName: string;
                    fieldName: string;
                }
            }
        }
        notification: {
            show: boolean;
            animate: boolean;
            msg: {
                header: string;
                notes: string[];
            }
        }
        team: {
            submittable: boolean;
        }
        seen: { [stepNum: number]: boolean }
        savedJobData: string;
    };
    job: Job;
    submitted: boolean;
    jobNames: Array<string>;
    isRecurring: boolean;
    freeTrialLimit?: number | null;
}

export type NewJobSetState = <K extends keyof NewJobState>(
    state: ((prevState: Readonly<NewJobState>, props: Readonly<NewJobProps>) => (Pick<NewJobState, K> | NewJobState | null)) | (Pick<NewJobState, K> | NewJobState | null),
    callback?: () => void
) => void;

const getStateTemplate = (inst: NewJob, jobId?: string): NewJobState => {
    return Object.assign(
        {
            view: {
                jobIdParam: jobId,
                loadingData: true,
                newInterface: true,
                activeIndex: 0,
                jobNameClick: false,
                legal: {
                    credits: false,
                    accuracy: false
                },
                errors: {
                    message: false,
                    saveButton: false,
                    isUniqueName: true
                },
                notification: {
                    show: false,
                    animate: false,
                    msg: {
                        header: '',
                        notes: []
                    }
                },
                team: {
                    submittable: true
                },
                seen: { 1: true },
                savedJobData: ''
            },
            job: {
                retailerRecords: {},
                coreJob: default_job(inst.props),
                reportTypes: {
                    Live: true,
                    Health: true
                },
                paste: {
                    textarea: [],
                    field: 'url'
                },
                loadMethod: undefined
            } as Job,
            submitted: false,
            jobNames: [],
            isRecurring: false,
        } as NewJobState
    )
}

const coreJobToStateJob = (coreJobRaw: CoreJob, state: NewJobState): Job => {
    const coreJob = (coreJobRaw.jobData.datasetId)
        ? coreJobRaw
        : { ...coreJobRaw, jobData: newJob_default_jobData() }
    return {
        retailerRecords: {},
        coreJob,
        reportTypes: Object.assign({}, ...coreJob.statusChecks.map(check => ({ [check]: true }))),
        paste: {
            textarea: [],
            field: 'url'
        },
        loadMethod: (coreJob.jobData.fileName)
            ? (coreJob.jobData.masterCsv.meta.delimiter)
                ? 'csv'
                : 'paste'
            : undefined,
        ...(coreJob.retailerInfo.retailerId
            ? { selectedRetailer: state.job.retailerRecords[coreJob.retailerInfo.retailerId] }
            : {})
    };
}

class NewJob extends React.Component<NewJobProps, NewJobState> {


    constructor(props: NewJobProps) {
        super(props);

        const parsedLocationSearch = querystringparser(props.location.search);

        const jobIdParam = parsedLocationSearch['jobId'];

        const state = (jobIdParam && jobIdParam[0])
            ? getStateTemplate(this, jobIdParam[0])
            : getStateTemplate(this);

        const locationIndex = this.evaluateLocationHashIdx() || this.evaluateQueryParamIdx();

        if (locationIndex) {
            state.view = {
                ...state.view,
                activeIndex: locationIndex - 1,
                seen: { [locationIndex]: true }
            };
        }

        this.state = {
            ...state
        };
    }

    evaluateLocationHashIdx = () => {
        const { hash } = this.props.location;
        if (hash) {
            const hashIdx = parseInt(hash, 10);

            return (!isNaN(hashIdx))
                ? hashIdx
                : undefined;
        }

        return undefined;
    }

    evaluateQueryParamIdx = () => {
        const parsedLocationSearch = querystringparser(this.props.location.search);

        const locationIndexParam = parsedLocationSearch['idx'];

        const qParamIdx = (locationIndexParam && locationIndexParam[0])
            ? parseInt(locationIndexParam[0], 10)
            : undefined;

        return (qParamIdx !== undefined && !isNaN(qParamIdx))
            ? qParamIdx
            : undefined;
    }

    async componentDidMount() {
        const jobNames = Object.values(this.props.globalState.teamJobData.teamJobs).map(job => job?.metadata?.jobName).filter(x=>x);
        if (this.state.view.jobIdParam) {
            firestoreDb.collection('brandJobs')
                .doc(this.state.view.jobIdParam)
                .get()
                .then(async snapshot => {

                    const coreJob = snapshot.data()! as CoreJob;
                    if (coreJob.jobData.datasetId) {
                        const jobData = await coreJobDataGet(coreJob.jobData.datasetId, 0)

                        this.setState(prevState => ({
                            job: {
                                ...coreJobToStateJob({ ...coreJob, jobData }, prevState),
                                retailerRecords: prevState.job.retailerRecords
                            },
                            view: {
                                ...prevState.view,
                                savedJobData: coreJob.jobData.datasetId
                            },
                            jobNames: jobNames.filter(item => item !== coreJob.jobName),
                            isRecurring: coreJob.schedule.recur.how === 'custom'
                        }));

                    } else {
                        this.setState(prevState => ({
                            job: {
                                ...coreJobToStateJob(coreJob, prevState),
                                retailerRecords: prevState.job.retailerRecords
                            },
                            jobNames: jobNames.filter(item => item !== coreJob.jobName),
                            isRecurring: coreJob.schedule.recur.how === 'custom'
                        }));
                    }
                });
        } else {
            this.setState(prevState => ({
                ...prevState,
                jobNames: jobNames
            }));
        }

        const transformedRecords = Object.assign({}, ...Object.values(RetailerRecordList).map(retObj => ({[retObj.retailerId]: retObj})))
        const records = { ...this.state.job.retailerRecords, ...transformedRecords };

        const retailerRecords = mapValues(records, rec => ({ ...rec, fieldDefinitions: {} })) as any;
        
        const teamDoc = await firestoreDb.collection("TeamAccount").doc(this.props.globalState.teamJobData.teamId).get();
        let freeTrialLimit = null;
        
        if (teamDoc.exists) {
            const teamObj = teamDoc.data();
            if (typeof teamObj?.freeTrialLimit === 'number') {
                freeTrialLimit = teamObj?.freeTrialLimit;
            }
        }

        this.setState(prevState => ({
            job: { ...prevState.job, retailerRecords },
            view: { ...prevState.view, loadingData: false },
            freeTrialLimit
        }));

        /* firestoreDb.collection('retailerRecords')
            .doc('3f483a05-e743-41f3-93da-0f9ab8414b45')
            .get()
            .then(async walmartSnapshot => {
                const walmartRecord = walmartSnapshot.data() as RetailerAdminRecord;

                const retailersSnapsot = await firestoreDb.collection('retailerRecords')
                    .get();
                const retailerRecords = { ...this.state.job.retailerRecords };

                retailersSnapsot.docs.forEach(retailerSnapsot => {
                    const retailerRecord = retailerSnapsot.data() as RetailerAdminRecord;
                    retailerRecord.fieldDefinitions = walmartRecord.fieldDefinitions;
                    retailerRecords[retailerRecord.retailerId] = retailerRecord;
                });

                this.setState(prevState => ({
                    job: { ...prevState.job, retailerRecords },
                    view: { ...prevState.view, loadingData: false }
                }));

            }); */
    }

    componentWillUnmount() {
        // this.firestoreUnsubscribe();
    }

    submitJob = async () => {
        const { coreJob } = this.state.job;

        coreJob['shared'] = [];
        coreJob.stage = 'In Process';
        coreJob.statusChecks.forEach(report => {
            coreJob.statusReportMap[report] = 'In Process';
        });

        coreJob.dateOpened = (new Date()).valueOf();

        // const inst = this;

        const coreUser = getCoreFromUserAccount(this.props.globalState.activeUser);
        if (coreUser) {
            await this.showNotification(
                "Submitting job...",
                `You’ll soon be able to impress the team with all these insights.
            Now just hang tight until you’re redirected to the dashboard.`
            );

            newJobData_submit(coreJob, coreUser, this.state.view.savedJobData, this.state.isRecurring)
                .catch(function (error) {
                    console.log(error);
                });

            await this.showNotification("Job submitted!");
            this.hideNotification(1000);

            this.setState({ submitted: true });
            TrackingService.onEvent('runJobAudit');

        }
    }

    cancelJob = async () => {
        const coreUser = getCoreFromUserAccount(this.props.globalState.activeUser);
        if (coreUser) {
            await this.showNotification("Canceling...");
            const pathPrefix = (this.props.location.pathname.includes("newshell"))
            ? "/newshell"
            : '';

        this.props.history.push({ pathname: `${pathPrefix}/jobs` });
        }
    }

    saveDraft = async () => {
        const { coreJob } = this.state.job;
        if (coreJob.jobName) {

            coreJob['shared'] = [];

            const coreUser = getCoreFromUserAccount(this.props.globalState.activeUser);
            if (coreUser) {
                await this.showNotification("Saving job...")

                const datasetId = await newJobData_saveDraft(coreJob, coreUser, this.state.view.savedJobData);

                this.setState(
                    prevState => ({
                        view: {
                            ...prevState.view,
                            savedJobData: datasetId
                        }
                    }),
                    async () => {
                        const pathPrefix = (this.props.location.pathname.includes("newshell"))
                            ? "/newshell"
                            : '';

                        this.props.history.replace({
                            pathname: `${pathPrefix}/jobs/new`,
                            search: '?jobId=' + coreJob.jobId,
                            hash: window.location.hash
                        });
                        await this.showNotification("Job saved!")
                        this.hideNotification(2000);
                    }
                );

            }
        } else {
            this.setState(prevState => ({ view: assignDotted({ ...prevState.view }, "errors.saveButton", true) }));
            this.jobNameClickHandler();
        }
    }

    windowLocation = () => ({ ...window.location });

    setTabHash = (hashIdx: number, windowLocationSearch: string) => {
        const { location, history } = this.props;
        const config = {
            pathname: location.pathname,
            search: windowLocationSearch,
            hash: '#' + hashIdx
        };
        history.replace(config);
    }

    goToTab = (tabNumber: number) => {
        const windowLocationSearch = window.location.search + '';

        this.setState(
            prevState => ({
                view: assignDottedArray({ ...prevState.view }, [
                    ['activeIndex', tabNumber],
                    ['seen', { ...prevState.view.seen, [tabNumber + 1]: true }]
                ])
            }),
            () => this.setTabHash(tabNumber + 1, windowLocationSearch)
        );
    }

    handleNextTab = (tabNumber: number) => this.goToTab(tabNumber);

    handleTabChange = (e: any, { activeIndex }: any) => this.goToTab(activeIndex);

    goBackButton = (message: string, tab: number) => {
        const children = <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block', marginRight: 20 }}>
                <Icon name="arrow left" />
            </div>
            {message}
        </div>
        return <CSUIButton
            onClick={() => this.goToTab(tab - 1)}
            children={children}
            color="green" slim
        />
    }

    areThereUidErrors = () => ((this.state.view.errors.uidErrors)
        ? Objectentries(this.state.view.errors.uidErrors).map(fieldErrorObj =>
            Objectentries(fieldErrorObj.tests).map(x => x.length).filter(x => x).length
        ).filter(x => x).length > 0
        : false
    )

    checkUidErrors = (idFieldName: string, fieldName: string) => {
        const uidErrors = (() => {
            const { data } = this.state.job.coreJob.jobData.masterCsv;
            const items = data.map(sku => sku[fieldName].trim());

            const wordCount = items.reduce((collector, sku) => ({
                ...collector,
                [sku]: ((collector[sku]) ? collector[sku] : 0) + 1
            }), {} as { [sku: string]: number });
            const deDuped = Object.keys(wordCount).filter(x => x);

            const multipleIdenticalTest = () => ObjectKeyValuePairs(wordCount).filter(pair => pair.value > 1);
            const allNumericTest = () => deDuped.filter(sku => !/^\d+$/g.test(sku));
            const allAlphaNumericTest = () => deDuped.filter(sku => !/^[a-zA-Z0-9]+$/g.test(sku));
            const allCorrectUrlFormatTest = () => deDuped.filter(sku => !/^(ftp|http|https):\/\/.+$/g.test(sku));

            const errorDetails = {
                idFieldName,
                fieldName,
                tests: {
                    repeated: multipleIdenticalTest(),
                }
            }

            switch (idFieldName) {
                case 'upc':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notNumeric: allNumericTest(),
                            notLengthTwelve: deDuped.filter(sku => sku.length !== 12)
                        }
                    };
                case 'asin':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notAlphaNumeric: allAlphaNumericTest(),
                            notLengthTen: deDuped.filter(sku => sku.length !== 10)
                        }
                    };
                case 'toolId':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notNumeric: allNumericTest(),
                        }
                    };
                case 'url':
                    return {
                        ...errorDetails, tests: {
                            ...errorDetails.tests,
                            notUrlFormat: allCorrectUrlFormatTest(),
                        }
                    };
                default:
                    return errorDetails;
            }
        })();

        return new Promise<typeof uidErrors>((resolve) => {
            this.setState(
                prevState => ({
                    view: assignDottedArray({ ...prevState.view }, [
                        [`errors.uidErrors.${idFieldName}`, uidErrors]
                    ])
                }),
                () => resolve(uidErrors)
            );
        });
    }

    isWalmartSelected = () => {
        const { selectedRetailer } = this.state.job;
        return (selectedRetailer)
            ? selectedRetailer.retailerName === 'Walmart.com'
            : true
    }


    selectedRetailerName = () => {
        const { selectedRetailer } = this.state.job;
        return (selectedRetailer)
            ? selectedRetailer.retailerName
            : ''
    }

    skuIdMapSetUnset = (idFieldName: string) => ({
        set: (fieldName: string) => {
            this.checkUidErrors(idFieldName, fieldName);
            this.setState(prevState => ({
                job: assignDottedArray({ ...prevState.job }, [
                    ['coreJob.jobData.skuIdMap', Object.assign(
                        { ...prevState.job.coreJob.jobData.skuIdMap },
                        { [idFieldName]: fieldName }
                    )]
                ])
            }))
        },
        unset: () => this.setState(prevState => {
            const { skuIdMap } = prevState.job.coreJob.jobData;
            delete skuIdMap[idFieldName];
            return {
                job: assignDottedArray({ ...prevState.job }, [
                    ['coreJob.jobData.skuIdMap', Object.assign(
                        { ...skuIdMap }
                    )]
                ])
            };
        })
    })

    reservedWalmart = () => {
        return {
            "Tool ID (Item ID)": this.skuIdMapSetUnset('toolId'),
            //"UPC (GTIN-12)": this.skuIdMapSetUnset('upc'),
            "URL": this.skuIdMapSetUnset('url')
        };
    }

    reservedLowes = () => {
        return {
            "URL": this.skuIdMapSetUnset('url')
        };
    }

    reservedDefault = () => {
        return {
            "URL": this.skuIdMapSetUnset('url')
        };
    }

    reservedAmazon = () => {
        return {
            "ASIN": this.skuIdMapSetUnset('asin'),
            "URL": this.skuIdMapSetUnset('url')
        }
    }

    reservedRetailerFields = () => {
        const selectedRetailer = this.selectedRetailerName();
        switch (selectedRetailer) {
            case 'Walmart.com':
                return this.reservedWalmart();
            case "Amazon.com":
                return this.reservedAmazon();
            case "Lowe's":
                return this.reservedLowes();
            case "Target":
            default:
                return this.reservedDefault();
        }
        //return (this.isWalmartSelected()) ? this.reservedWalmart() : this.reservedLowes();
    }

    reservedText = () => {
        const selectedRetailer = this.selectedRetailerName();
        switch (selectedRetailer) {
            case 'Walmart.com':
                return 'Please map the Tool ID column' //'Please map either the UPC or Walmart Tool ID column (or both)'
            case "Amazon.com":
                return 'Please map the ASIN column'
            default:
                return 'Please map the URL column'
        }
    }

    showErrorMessage = () => this.setState(prevState => ({
        view: assignDotted({ ...prevState.view },
            'errors.message', true
        )
    }))

    toggleErrorShowing = () => this.setState(prevState => ({
        view: assignDotted({ ...prevState.view },
            'errors.message', !prevState.view.errors.message
        )
    }));

    newJobFlowOnClick = (stepNum: number) => {
        const isStepComplete = this.isStepComplete(stepNum);
        const { message } = this.state.view.errors;
        if (isStepComplete) {
            if (message) {
                this.toggleErrorShowing();
            }
            if (stepNum === 4) {
                this.submitJob();
            } else {
                this.handleNextTab(stepNum);
            }
        } else {
            if (!message) {
                this.toggleErrorShowing();
            }
        }
    }

    newJobErrors = () => ({
        1: [
            { step: 1, msg: 'Enter a job name', test: (!!this.state.job.coreJob.jobName) },
            { step: 1, msg: `The ${this.state.job.coreJob.jobName} is not unique`, test: this.state.view.errors.isUniqueName},
            { step: 1, msg: 'Make sure at least one Status Report is selected', test: (!!Objectentries(this.state.job.reportTypes).filter(x => x).length) },
            { step: 1, msg: 'Select a Retailer', test: (!!this.state.job.selectedRetailer) }
        ],
        2: [
            { step: 2, msg: 'Upload some SKUs', test: (!!this.state.job.coreJob.jobData.masterCsv.data.length) },
            {
                step: 2, msg: 'Fix errors with uploaded data', test: ((this.state.view.errors.uidErrors)
                    ? Objectentries(this.state.view.errors.uidErrors).map(fieldErrorObj =>
                        Objectentries(fieldErrorObj.tests).map(x => x.length).filter(x => x).length
                    ).filter(x => x).length === 0
                    : true
                )
            },
            { step: 2, msg: 'Please map an unique ID or URL for the SKUs', test: (!!Object.keys(this.state.job.coreJob.jobData.skuIdMap).length) }
        ],
        3: [
            { step: 3, msg: '', test: true }
        ],
        4: [
            { step: 4, msg: 'Please review and agree to our job warnings and notices', test: (this.state.view.legal.credits) && (this.state.view.legal.accuracy) },
            { step: 4, msg: 'Please add additional credits to submit this job', test: (this.state.view.team.submittable) }
        ]
    })

    stepCompletionCriteria = (num: keyof ReturnType<NewJob['newJobErrors']>) => this.newJobErrors()[num].reduceRight((prev, curr) => prev && curr.test, true)

    isStepComplete = (stepNum: number) => {
        let bit = true;
        switch (stepNum) {
            /* case 6:
                bit = bit && this.stepCompletionCriteria(6);
            case 5:
                bit = bit && this.stepCompletionCriteria(5); */
            // @ts-ignore
            case 4:
                bit = bit && this.stepCompletionCriteria(4);
                break;
            // @ts-ignore
            case 3:
                bit = bit && this.stepCompletionCriteria(3);
                break;
            // @ts-ignore
            case 2:
                bit = bit && this.stepCompletionCriteria(2);
                break;
            // @ts-ignore
            case 1:
                bit = bit && this.stepCompletionCriteria(1);
                break;
            default:
                bit = false;
        }
        return bit;
    }

    stepShowCheck = (stepNum: number): boolean => this.stepCompletionCriteria(stepNum as any) && (this.state.view.seen[stepNum] || (this.isStepComplete(stepNum + 1) && this.stepShowCheck(stepNum + 1)));

    errorMessage = (stepNum: number) => {
        const newJobErrors = this.newJobErrors();
        const errors = Objectkeys(newJobErrors)
            .filter(e => e <= stepNum)
            .reverse()
            .reduceRight(
                (prev, curr) => prev.concat(newJobErrors[curr].filter(e => !e.test)),
                [] as typeof newJobErrors[1]
            );
        // Objectkeys(newJobErrors).filter(e => e <= stepNum && !this.stepCompletionCriteria(e.step));
        const message = <Message error={true} style={{ maxWidth: 550, textAlign: 'center' }}>
            <Message.Header>Please complete the following before proceeding:</Message.Header>
            <Message.List>
                {errors.map((error, idx) => <Message.Item key={idx}>
                    {// eslint-disable-next-line jsx-a11y/anchor-is-valid
                    }<a onClick={() => this.goToTab(error.step - 1)}
                        style={{ cursor: 'pointer' }}
                    >
                        {error.msg}
                    </a>
                </Message.Item>)}
            </Message.List>
        </Message>;
        return (
            (
                (stepNum === 6 && (!!!Objectentries(this.state.job.reportTypes).filter(x => x).length))
                || this.state.view.errors.message
            )
            && (errors.length > 0)
        ) ? message : undefined;
    }

    retailerNameRenderer = (capitalize?: boolean) => (this.state.job.selectedRetailer) ? this.state.job.selectedRetailer.retailerName : (capitalize) ? 'Retailer Site' : 'retailer site';

    onDrop: DropFilesEventHandler = async (accepted) => {
        const acceptedFile = accepted[0];
        if (acceptedFile) {
            const masterCsv = await newJobData_parseUploadedCsv(acceptedFile);
            this.setUploadedCsv(acceptedFile.name, masterCsv);
        }
    }

    setUploadedCsv = (fileName: string, masterCsv: Papa.ParseResult) => {
        this.setState(prevState => ({
            job: {
                ...prevState.job,
                coreJob: {
                    ...prevState.job.coreJob,
                    jobData: {
                        datasetId: RandomId(),
                        fileName,
                        masterCsv,
                        skuIdMap: {}
                    },
                    retailerInfo: {
                        ...prevState.job.coreJob.retailerInfo,
                        retailerFieldMap: {}
                    }
                },
                loadMethod: 'csv'
            },
            view: {
                ...prevState.view,
                errors: {
                    ...prevState.view.errors,
                    uidErrors: undefined
                }
            }
        }));
    }

    onClear = () => {
        const skusPasted = this.state.job.loadMethod === 'paste';

        appendToTextarea("newJobLoadSkusTextarea", "");
        if (skusPasted) {
            this.setState((prevState) => ({
                ...prevState,
                job: {
                ...prevState.job,
                paste: {
                    ...prevState.job.paste,
                    textarea: [],
                },
                },
            }));
        }
        this.setState((prevState) => ({
            job: assignDottedArray({ ...prevState.job }, [
                ['coreJob.jobData', newJob_default_jobData()],
                ['loadMethod', undefined],
                ['coreJob.retailerInfo.retailerFieldMap', {}],
                ['paste.textarea', []],
            ]),
            view: assignDottedArray({ ...prevState.view }, [
                ['errors.uidErrors', undefined],
            ]),
        }));
    };

    jobNameClickHandler = () => {
        this.goToTab(0);
        this.setState(prevState => ({ view: assignDotted({ ...prevState.view }, `jobNameClick`, true) }));
    }

    getNotificationPanel = () => {
        const { notification } = this.state.view;
        return <Dimmer active={notification.show} inverted>
            <Loader inverted>
                <Header as="h1">{notification.msg.header}</Header>
                {notification.msg.notes.map((note, idx) => <p key={idx} style={{ fontSize: '1.3rem' }}>{note}</p>)}
            </Loader>
        </Dimmer>
    }

    showNotification = (headerMsg: string, ...notes: string[]) => {
        return new Promise<void>(resolve => {
            this.setState(
                prevState => ({
                    view: assignDottedArray({ ...prevState.view }, [
                        ['notification.show', true],
                        ['notification.msg.header', headerMsg],
                        ['notification.msg.notes', notes]
                    ])
                }),
                () => resolve()
            );
        });
    }

    hideNotification = (delay: number = 0) => {
        return new Promise<void>(resolve =>
            setTimeout(() => {
                this.setState(
                    prevState => ({
                        view: assignDottedArray({ ...prevState.view }, [
                            ['notification.show', false],
                            ['notification.msg.header', ''],
                            ['notification.msg.notes', []]
                        ])
                    }),
                    () => resolve()
                );
            }, delay)
        );
    }

    isFreeTrialLimitReached = () => {
        const {freeTrialLimit} = this.state;
        const successSkus = Object.values(this.props.globalState.teamJobData.teamJobs)
        .filter(x => x)
        .map(job => {
            const errorsLength = job.errorSkus ? job.errorSkus.length : 0;
            const missingSkusLength = job.missingSkus ? job.missingSkus.length : 0;
            return job.skus.length - errorsLength - missingSkusLength;
        })
        .reduce((a, b) => a+b, 0);

        return freeTrialLimit !== null
        ? successSkus >= freeTrialLimit
        : false;
    }

    newJobStepDetail = (stepNum: number) => {
        const freeTrialLimitReached = this.isFreeTrialLimitReached();
        const buttonDisabledBasicLogic = !this.isStepComplete(stepNum);
        const buttonInner = <CSUIButton
            // className="newjob_button"
            color="green" //{(this.isStepComplete(stepNum)) ? "green" : undefined}
            disabled={(stepNum === 4) ? buttonDisabledBasicLogic || freeTrialLimitReached : buttonDisabledBasicLogic}
            children={(stepNum === 4) ? "Submit" : "Next"}
            onClick={() => this.newJobFlowOnClick(stepNum)}
        />;
        const button = stepNum === 4 && freeTrialLimitReached
        ? <div>
            {buttonInner}
            <div style={{marginTop: 10}}>
                <span style={{color: 'red', fontWeight: 700}}>*Your Free Trial Audit limit has been reached. Save this Audit for later and return to submit it once a plan has been subscribed to.</span>
            </div>
        </div>
        : buttonInner;
        const message = this.errorMessage(stepNum);
        const onChange = (e: any, c) => this.setState(e, c);
        let jsx;
        switch (stepNum) {
            case 1:
                jsx = <TabWrapper button={button}>
                    <Step1Component
                        onChange={onChange}
                        onClear={this.onClear}
                        message={this.errorMessage(1)}
                        basic={!!!this.isStepComplete(stepNum)}
                        onClick={() => this.newJobFlowOnClick(stepNum)}
                        job={this.state.job}
                        jobNameClick={this.state.view.jobNameClick}
                        jobNames={this.state.jobNames}
                    />
                </TabWrapper>
                break;
            case 2:
                jsx = <TabWrapper button={button}>
                        <Step2Component
                            onChange={onChange}
                            onClear={this.onClear}
                            message={message}
                            basic={!!!this.isStepComplete(stepNum)}
                            job={this.state.job}
                            reservedFields={this.reservedRetailerFields()}
                            reservedText={this.reservedText()}
                            goBackButton={this.goBackButton}
                            selectedRetailerName={this.selectedRetailerName()}
                            onDrop={this.onDrop}
                            areThereUidErrors={this.areThereUidErrors()}
                            onErrorsCheck={(idFieldName: string, fieldName: string) => {
                                this.checkUidErrors(idFieldName, fieldName)
                                    .then(() => {
                                        if (this.areThereUidErrors()) {
                                            document.getElementById("stepContent").scrollIntoView();
                                        }
                                    });
                            }}
                            errors={this.state.view.errors.uidErrors}
                        />
                    </TabWrapper>

                break;
            case 3:
                jsx = <TabWrapper button={button}>
                    <Step3Component
                        onChange={onChange}
                        job={this.state.job}
                        message={message}
                        isRecurring={this.state.isRecurring}
                        basic={!!!this.isStepComplete(stepNum)}
                        planType={this.props.globalState.teamJobData.planType}
                    />
                </TabWrapper>
                break;
            case 4:
                jsx = <TabWrapper button={button}>
                         <Step4Component
                            parentProps={this.props}
                            onChange={onChange}
                            basic={!!!this.isStepComplete(stepNum)}
                            job={this.state.job}
                            message={message}
                            retailerSite={this.retailerNameRenderer(true)}
                            onTabClicked={this.goToTab}
                            goBackButton={this.goBackButton}
                            credits={this.state.view.legal.credits}
                            accuracy={this.state.view.legal.accuracy}
                        />
                </TabWrapper>
                break;
            case 7:
            default:
                jsx = <div>{String(stepNum)}</div>;
                break;
        }
        return <div
            style={{
                display: 'flex',
                padding: '15px 5px 15px'
            }}
        >
            {(stepNum !== 4) && message}
            {React.cloneElement(jsx, { id: `stepContent_${stepNum}` })}
        </div>;
    }

    newJobStepTitle = (stepNum: number) => {
        let title: string;
        const complete = this.stepShowCheck(stepNum);
        switch (stepNum) {
            case 1:
                title = 'Select Retailer';
                break;
            case 2:
                title = 'Upload SKUs';
                break;
            case 3:
                title = 'Schedule';
                break;
            case 4:
                title = 'Review';
                break;
            default:
                title = '';
                break;
        }
        return <Menu.Item key={title}>
            {(complete) ? <Icon name='check' /* color="green" */ style={{ color: 'indigo' }} size='big' /> : undefined}
            {`${(false && complete) ? '' : `${stepNum}. `}${title}`}
        </Menu.Item>;
    }

    oldPanes = () => {
        return [1, 2, 3, 4,/* 5,6 */].map(step => ({
            menuItem: this.newJobStepTitle(step),
            render: () => <Tab.Pane>{this.newJobStepDetail(step)}</Tab.Pane>
        }));
    }

    panes = () => {
        return [1, 2, 3, 4].map(step => ({
            key: step,
            header: this.newJobStepTitle(step),
            content: this.newJobStepDetail(step)
        }));
    }

    renderSuccessDialog = () => {
        return <SuccessDialog title="Success!" open={this.state.submitted} onClose={() => {
            const pathPrefix = (this.props.location.pathname.includes("newshell"))
                ? "/newshell"
                : '';

            this.props.history.push({
                pathname: `${pathPrefix}/jobs`
            });
        }}>
            <p>You started a new job and you'll soon impress the team with all those great insights.</p>
        </SuccessDialog>
    }

    loadingCircle = () => {
        return <div className="loadingCircle">
            <CircularProgress />
        </div>
    }

    render() {
        const { coreJob } = this.state.job;
        const panes = this.panes();
        return (
            <BasePageLayout>
                {this.renderSuccessDialog()}
                <CSUISegment className="newjob_container_segment">
                    <CSUIButtonCloseX topRight onClick={this.cancelJob} />
                    {(this.state.view.loadingData)
                        ? this.loadingCircle()
                        : <React.Fragment>
                            {this.getNotificationPanel()}
                            <div className="aboveTabs">
                                <div className="jobMetaData">
                                    <Header as="h2"><span onClick={this.jobNameClickHandler} style={{ cursor: 'pointer' }}>
                                        {(coreJob.jobName) ? coreJob.jobName : '(Enter audit job name)'}
                                    </span></Header>
                                    <span>{`Job #: ${coreJob.jobNum} opened on ${
                                        (coreJob.dateOpened) ? moment(coreJob.dateOpened).format('MMMM D, YYYY') : new Date().toLocaleDateString()
                                        } by ${coreJob.owner}`}</span>
                                </div>
                                <div className="saveCancel">
                                    <Popup
                                        content='Please enter a job name before saving' position='bottom center'
                                        on='click'
                                        open={!!!coreJob.jobName && this.state.view.errors.saveButton}
                                        onClose={() => this.setState(prevState => ({ view: assignDotted({ ...prevState.view }, "errors.saveButton", false) }))}
                                        style={{ fontWeight: 700, color: '#9f3a38', backgroundColor: '#fff6f6' }}
                                    />
                                    <CSUIButton color='red' onClick={this.cancelJob}>
                                        Cancel
                                    </CSUIButton>
                                    { coreJob.stage !== 'Incomplete Job' && <CSUIButton color='green' onClick={this.saveDraft}>
                                        Save
                                    </CSUIButton>}
                                </div>

                            </div>
                            <CSUITabs
                                panes={panes}
                                activeTabIndex={this.state.view.activeIndex}
                                onTabIndexChange={this.goToTab}
                            />
                        </React.Fragment>
                    }
                </CSUISegment>
            </BasePageLayout>
        );
    }
}

export default GlobalStateProvider(NewJob);
