import React, { FC, useEffect, useState } from "react";
import GlobalStateProvider from "../../components/GlobalStateProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSUIPivotTable } from "../../components/CSUI";
import { ContentStatusDataService } from "../../libraries/DataService";
import {
    ReportHeaders,
} from '../Reports/constants';
import { GlobalState } from "../../libraries/types/ReportUp";
import {
    CatalogData,
    SkuRecordCatalogData, SkuRecordFlatten,
} from '../Catalog/catalog-datamodel';
import { Report } from 'webdatarocks';
import {
    ReportsConditionalFormatter,
} from '../Reports/ReportTemplates';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { StyledCell } from './styles';
import { isEmpty, set, update } from "lodash";
import "./style.scss"
import { RetailerNameRenderFns } from "../../libraries/Util/constants";
import { capitalizeFirstLetter } from "../../libraries/Util/string";
import { SKUsList } from "../Catalog/SKUTaggingView/datastructures";

export type ReportData = {
    keyMap: { [key: string]: string },
    skus: SkuRecordCatalogData
}

interface Reports {
    generateReport: (data: ReportData) => Report
    reportType: string
    globalState: GlobalState
}

const RenderCell = ({ value, column }: Table.DataCellProps) => {
    const measureObj = commonMeasuresMap[column.name];
    const cssProps: React.CSSProperties = {
        color: '#000',
        ...((measureObj?.format === 'score') ? ReportsConditionalFormatter(value, column.name) : {})
    };

    return (
        <StyledCell {...cssProps}>
            {value}
        </StyledCell>
    )
}

const reportTypeData = {
    CategoryBrand: ['mainCategory', 'subcategory1', 'subcategory2', 'subcategory3', 'brand'],
    CategoryRetailer: ['mainCategory', 'subcategory1', 'subcategory2', 'subcategory3', 'retailer'],
    RetailerCategoryBrand: ['retailer', 'mainCategory', 'subcategory1', 'subcategory2', 'subcategory3', 'brand'],
    BrandCategory: ['brand', 'mainCategory', 'subcategory1', 'subcategory2', 'subcategory3',],
    BrandRetailer: ['brand', 'retailer',],
    RetailerBrandCategory: ['retailer', 'brand', 'mainCategory', 'subcategory1', 'subcategory2', 'subcategory3'],
    TagsRetailerCategoryBrand: ['tags', 'retailer', 'mainCategory', 'subcategory1', 'subcategory2', 'subcategory3', 'brand'],
    JobsRetailerCategoryBrand: ['jobs', 'retailer', 'mainCategory', 'subcategory1', 'subcategory2', 'subcategory3', 'brand'],
}

const countAccessor = (row, field) => parseInt(row[field]) || 0
const usageBooleanAccessor = (row, field) => ["True", "Y", "Yes"].includes(String(row[field]).trim()) ? 100 : 0
const usageCountAccessor = (row, field) => parseInt(row[field]) > 0 ? 100 : 0

const commonMeasures = [
    {
        grandTotalCaption: 'SKU',
        caption: 'Totals',
        uniqueName: 'skus',
        format: "count"
    },
    {
        uniqueName: 'overallScore',
        grandTotalCaption: 'Health',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    /* {
        uniqueName: 'marketingScore',
        grandTotalCaption: 'Marketing',
        caption: 'Marketing',
        aggregation: "average"
    }, */
    {
        uniqueName: 'titleScore',
        grandTotalCaption: 'Title',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'titleCharacterCount',
        grandTotalCaption: 'Title',
        caption: 'Char',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
    {
        uniqueName: 'shortDescriptionScore',
        grandTotalCaption: 'Desc.',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'descriptionCharCount',
        grandTotalCaption: 'Desc.',
        caption: 'Char',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
    {
        uniqueName: 'bulletScore',
        grandTotalCaption: 'Bullets',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'bullets',
        grandTotalCaption: 'Bullets',
        caption: 'Count',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
    {
        uniqueName: 'specsCount',
        grandTotalCaption: 'Specs',
        caption: 'Count',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
    /* {
        uniqueName: 'assetScore',
        grandTotalCaption: 'Assets',
        caption: 'Assets',
        aggregation: "average"
    }, */
    {
        uniqueName: 'imageScore',
        grandTotalCaption: 'Image',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'imageCount',
        grandTotalCaption: 'Image',
        caption: 'Count',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
    /* {
        uniqueName: 'richContentScore',
        grandTotalCaption: 'Rich Content',
        caption: 'Rich Content',
        aggregation: "average"
    }, */
    {
        uniqueName: 'videosCount',
        grandTotalCaption: 'Video',
        caption: 'Usage',
        aggregation: "average",
        accessor: usageCountAccessor,
        format: "percent"
    },
    {
        uniqueName: 'documentsCount',
        grandTotalCaption: 'PDF',
        caption: 'Usage',
        aggregation: "average",
        accessor: usageCountAccessor,
        format: "percent"
    },
    {
        uniqueName: 'view360',
        grandTotalCaption: '360',
        caption: 'Usage',
        aggregation: "average",
        accessor: usageBooleanAccessor,
        format: "percent"
    },
    {
        uniqueName: 'enhancedContent',
        grandTotalCaption: 'Enhanced',
        caption: 'Usage',
        aggregation: "average",
        accessor: usageBooleanAccessor,
        format: "percent"
    },
    /* {
        uniqueName: 'sentimentScore',
        grandTotalCaption: 'Sentiment',
        caption: 'Sentiment',
        aggregation: "average"
    }, */
    {
        uniqueName: 'ratingScore',
        grandTotalCaption: 'Ratings',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'reviewScore',
        grandTotalCaption: 'Reviews',
        caption: 'Score',
        aggregation: "average",
        format: "score"
    },
    {
        uniqueName: 'reviewsCount',
        grandTotalCaption: 'Reviews',
        caption: 'Count',
        aggregation: "average",
        accessor: countAccessor,
        format: "count"
    },
];

export const commonMeasuresMap = {} as { [x: string]: typeof commonMeasures[0]};
const commonColumnBands = {};
commonMeasures.forEach(m => {
    commonMeasuresMap[m.uniqueName] = m;
    if (!commonColumnBands[m.grandTotalCaption]) {
        commonColumnBands[m.grandTotalCaption] = {
            title: m.grandTotalCaption,
            children: []
        }
    }
    commonColumnBands[m.grandTotalCaption].children.push({ columnName: m.uniqueName});
});


const calculateAverageData = (skus: SkuRecordFlatten[]) => {
    const res = {};

    for (const measure of commonMeasures) {
        let numOfNonNaNValues = 0;
        let sum = 0;
        
        const accessorFn = measure.accessor;
        for (const sku of skus) {
            const value = accessorFn ? accessorFn(sku, measure.uniqueName) : sku[measure.uniqueName];;
            if (value !== null && !isNaN(value)) {
                numOfNonNaNValues += 1;
                sum += value;
            }
        }
        
        const numericalValue = numOfNonNaNValues > 0
        ? (sum / numOfNonNaNValues)
        : undefined;

        const stringValue = numericalValue === undefined
        ? " - "
        : numericalValue === 0 && measure.format !== 'score'
            ? " - "
            : (['Char', 'Count'].includes(measure.caption)
                ? Math.round(numericalValue).toFixed(0)
                : numericalValue.toFixed(1)
                .split(".")
                .map((num, idx) => {
                    if (idx === 0) { return num }
                    if (num === "00") { return "" }
                    if (num[1] === "0") { return num[0] }
                    return num;
                })
                .filter(x => x)
                .join(".")
            );

        const formattedValue = `${stringValue}${stringValue !== ' - ' && measure.format === 'percent' ? "%" : ""}`;

        res[measure.uniqueName] = formattedValue;
    }

    return res;
}

const deleteLastLevel = function(obj, groupKey) {
    if (Array.isArray(obj)) {
        obj.forEach(item => deleteLastLevel(item, groupKey))
    } else {
        if (obj && obj.hasOwnProperty('items')) {
            if (isEmpty(obj['items'])) {
                delete obj.items
            } else {
                obj['items'] = obj['items'].sort(function (a, b) {
                    if (a[groupKey] === "") {
                        return 1;
                    } else if (b[groupKey] === "") {
                        return -1;
                    } else {
                        return a[groupKey] < b[groupKey] ? -1 : a[groupKey] > b[groupKey] ? 1 : 0;
                    }
                });
                deleteLastLevel(obj['items'], groupKey)
            }
        }
    }
    return obj;
};

const sortCollator = new Intl.Collator(undefined, {sensitivity: "base", numeric: true});

const ReportsDemoLayout: FC<Reports> = ({ generateReport, reportType, globalState }) => {
    const [isLoading, setIsLoading] = useState(true);
    
    const [reportRows, setRows] = useState([]);
    const [reportColumns, setColumns] = useState([]);
    const [skuToJobMap, setSkuToJobMap] = useState<{[skuId: string]: string[]}>({});
    const groupKey = 'key';

    const loadData = async (reason?: string) => {
        setIsLoading(true);
        const data = await ContentStatusDataService.catalogData({
            teamId: globalState.teamJobData.teamId,
        }).getElastic();
        const skuTags = await ContentStatusDataService.skuTags({
            teamId: globalState.teamJobData.teamId,
        }).get();

        const catalogData = data.skus;
        const {rows, columns} = getReportData(catalogData, skuTags, reportType);

        setColumns(columns);
        setRows(rows);
        setIsLoading(false);
    }

    useEffect(() => {
        const componentDidMount = async () => {
            await loadData();
        }
        componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skuToJobMap]);

    useEffect(() => {

        if (reportType === 'JobsRetailerCategoryBrand' && globalState.teamJobData.dataLoading === false) {
            const skusMap = { }

            Object.values(globalState.teamJobData.teamJobs).forEach(job => {
                Object.keys(job?.scrapeConfig || {}).forEach(skuId => {
                    if (!skusMap[skuId]) {
                        skusMap[skuId] = []
                    }
                    skusMap[skuId].push(job.metadata.jobName);
                })
            });
            setSkuToJobMap(skusMap);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.teamJobData.teamJobs, globalState.teamJobData.dataLoading, reportType]);

    /* useEffect(() => {

        if (reportType === 'TagsRetailerCategoryBrand') {
            console.log("at Tags report!!")
            const loadTags = async () => {
                const skuTags = await ContentStatusDataService.skuTags({
                    teamId: globalState.teamJobData.teamId,
                }).get();
                setSkuTags(skuTags);
                console.log("skuTags set!!", skuTags)
                await loadData("cuz skuTags");
            }
            loadTags();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType]); */

    const getReportData = (catalogData: CatalogData['skus'], skuTags: SKUsList, reportType: string) => {
        const columnsToParse = reportTypeData[reportType];
        const groupKey = 'key';

        const columnsData = [{ name: 'key', title: ReportHeaders[reportType], }, ...getTableColumns(commonMeasures)];

        if (!columnsToParse) {
            return {rows: [], columns: columnsData};
        }

        const result = [];
        const groupedData = {};
        const performGrouping = (sku: SkuRecordFlatten, column: any, mappedKeysArray: any[], arrayToSpread: any[]) => {
            if (Array.isArray(sku[column])) {
                sku[column].forEach(item => {
                    mappedKeysArray.push([...arrayToSpread, item]);
                    update(groupedData, [...arrayToSpread, item, 'items'], (items) => items ? [...items, sku] : [sku]);
                })
            } else {
                arrayToSpread.push(sku[column]);
                update(groupedData, [...arrayToSpread, 'items'], (items) => items ? [...items, sku] : [sku]);
            }
        }
        
        for (const sku of Object.values(catalogData)) {
            const tagsArray = skuTags && skuTags[sku.skuId]
            ? Object.values(skuTags[sku.skuId]).filter(tag => tag.selected).map(tag => tag.displayName)
            : []
            const shouldSkipForTags = reportType === 'TagsRetailerCategoryBrand' && !tagsArray.length;
            const shouldSkipForJobs = reportType === 'JobsRetailerCategoryBrand' && !(skuToJobMap[sku.skuId] || []).length;
            if (shouldSkipForTags || shouldSkipForJobs) {
                continue;
            }
            const mappedKeys = [];
            const mappedKeysArray = [];
            for (const column of columnsToParse) {
                switch(column) {
                    case 'jobs':
                        sku[column] = skuToJobMap[sku.skuId] || [];
                        break;
                    case 'tags':
                        sku[column] = tagsArray
                        //(sku[column] || []).map(item => toTitleCase(item.replace('tags_all_system_tags_', '').replace('tags_customs_tags_', '').replaceAll('_', ' ')));
                        break;
                    case 'retailer':
                        sku[column] = RetailerNameRenderFns.displayName(sku[column]);
                        break;
                    /* case 'brand':
                        sku[column] = capitalizeFirstLetter(String(sku[column]).toLocaleLowerCase()) */
                }
                if (mappedKeysArray.length === 0) {
                    performGrouping(sku, column, mappedKeysArray, mappedKeys);
                } else {
                    for (const mappedKey of mappedKeysArray) {
                        performGrouping(sku, column, mappedKeysArray, mappedKey);
                    }
                }
            }
        }

        const groupData = (groupedData, keyPath: string[], currentKey: string,) => {
            if (groupedData.items.length < 0) {
                return;
            }

            const { items , ...restGroupedData } = groupedData;

            if (items) {
                const obj = {
                    ...calculateAverageData(groupedData.items),
                    skus: groupedData.items.length,
                    key: currentKey,
                    items: [],
                }
                set(result, keyPath, obj);
            }

            Object.keys(restGroupedData).forEach((key, index) => {
                groupData(groupedData[key], [...keyPath, 'items', String(index)], key)
            });
        };

        Object.keys(groupedData).forEach((key, index) => {
            groupData(groupedData[key], [String(index)], key);
        });

        result.sort(function (a, b) {
            const rowA = a[groupKey];
            const rowB = b[groupKey];
            if (rowA === "") {
                return 1;
            } else if (rowB === "") {
                return -1;
            } else {
                return sortCollator.compare(rowA, rowB)
            }
        });

        if (result.length !== 0) {
            result.forEach(row => deleteLastLevel(row, groupKey))
        }

        return {rows: result, columns: columnsData};
    }

    const getTableColumns = (columns) => {
        return columns.map(item => {
            return {
                name: item.uniqueName,
                title: item.caption,
                exportTitle: item.grandTotalCaption + " " + item.caption
            }
        });
    };

    const loadingDiv = (
        <div className="flex-center">
            <div className="purple-text" style={{ zIndex: 9 }}>
                <CircularProgress />
            </div>
        </div>
    );

    const makeColumnObj = (columnName: string, width: number | string) => ({ columnName, width });
    const tableColumnExtensions = Object.assign({}, ...[
        makeColumnObj(groupKey, 350),
        makeColumnObj("enhancedContent", 100),
        makeColumnObj("ratingScore", 80),
        makeColumnObj("overallScore", 80),
        //makeColumnObj("sentimentScore", 100)
    ].map(obj => ({[obj.columnName]: obj})));

    const columnBands = [
        //{ title: ReportHeaders[reportType], children: [{columnName: 'key'}]},
        ...Object.values(commonColumnBands)
    ]

    const tableDiv = <CSUIPivotTable
        rows={reportRows}
        columns={reportColumns}
        pivotColumn={groupKey}
        renderer={RenderCell}
        columnExtensions={tableColumnExtensions}
        columnBands={columnBands}
    />

    return isLoading
    ? loadingDiv
    : tableDiv;
}

export default GlobalStateProvider(ReportsDemoLayout)

