import {  MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { API_URL, defaultVal } from "../../libraries/types/datastructures";
import { CSUIButton } from "../CSUI";
import { RHInput } from "../CSUI/RHInput";
import "./style.scss";

export function API_CALL_ScrapeHeroSearch(retailer: string, searchUrl: string) {
  return axios.post(`${API_URL}/misc/qlDigitalShelf`, { retailer, searchUrl });
}

export function API_CALL_DSLogRequests(email: string, retailer: string, searchUrl: string) {
  //alert(JSON.stringify({email, retailer, searchUrl }))
  return axios.post(`${API_URL}/misc/DSLogRequests`, {email, retailer, searchUrl });
}

export interface DSFormState {
    retailer: string;
    input: string;
    inputType: string;
}

interface ScrapeHeroSearchBoxProps {
  onSubmit: (data: DSFormState) => void;
  public?: boolean;
}

const retailerConfig = {
  amazon: {
    label: 'Amazon'
  },
  walmart: {
    label: 'Walmart',
    //isDisabled: true
  },
  samsclub: {
    label: "Sam's Club"
  },
  homedepot: {
    label: "Home Depot",
    //isDisabled: true
  }
}

const invisible_character = 'ㅤ' // <-- This is not a normal space character...do not delete/refactor!

export const DSOS_SearchPrefixes = {
    amazon: "https://www.amazon.com/s?k=",
    walmart: "https://www.walmart.com/search/?query=",
    samsclub: "https://www.samsclub.com/s/",
    homedepot: "https://www.homedepot.com/s/"+invisible_character
};

export const ScrapeHeroSearchBox: React.FC<ScrapeHeroSearchBoxProps> = (props) => {
  const location = useLocation();
  const { register, control, handleSubmit, watch, setValue, getValues /* reset */ } = useForm<DSFormState>({
    defaultValues: { retailer: "amazon", input: "", inputType: "searchTerms" },
    mode: "onChange",
  });

  const isPublic = defaultVal(props.public, false);

  const { isDirty } = useFormState({
    control,
  });

  const onSubmit = (data: DSFormState) => {
    props.onSubmit(data);
    //reset(data);
  };

  useEffect(() => {
    const componentDidMount = async () => {
        const params = new URLSearchParams(location.search);
        if (params.has("retailer") && params.has("searchUrl")) {
            const retailer = params.get("retailer");
            const searchUrl = decodeURIComponent(params.get("searchUrl"));

            const searchTerms = decodeURIComponent(searchUrl.replace(DSOS_SearchPrefixes[retailer], ""));
            setValue("retailer", retailer);
            setValue("input", searchTerms);
            onSubmit({ retailer, input: searchTerms, inputType: "searchTerms" })
        }
    };
    componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // <-- Empty dependency array makes this a "componentDidMount hook"

  const retailer = watch("retailer");
  const searchInput = watch("input");

  const labels = {
    searchTerms: "Keyword or Phrase",
    searchUrl: "Search URL (from Retailer website)"
  }

  const inputLabel = labels[watch("inputType")];

  const noInput = !!!searchInput;

  const styleTag = <style>{`
  div#menu-retailer.MuiPopover-root div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  {
      max-height: ${Object.keys(retailerConfig).length*30+16}px !important;
  }
  `}</style>

  return (
    <form id="search-box-container" className={props.public ? "public" : ""} onSubmit={handleSubmit(onSubmit)}>
      {styleTag}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="search-row" >
            <div>
                <Select
                    variant="outlined"
                    defaultValue={'amazon'}
                    {...register("retailer")}
                    //onChange={this.handleChange('hdyhas')}
                >
                  {
                    Object.keys(retailerConfig).map(retailer => <MenuItem key={retailer} value={retailer} disabled={!!retailerConfig[retailer].isDisabled}>
                        {retailerConfig[retailer].label}
                    </MenuItem>)
                  }
                </Select>
            </div>
            {!isPublic ? <div>
                <Select
                    variant="outlined"
                    defaultValue={'searchTerms'}
                    {...register("inputType")}
                    //onChange={this.handleChange('hdyhas')}
                >
                  {["searchTerms", "searchUrl"].map(type => <MenuItem key={type} value={type}>
                        {labels[type]}
                    </MenuItem>)}
                </Select>
            </div> : null}
          <RHInput
            control={control}
            type="text"
            name="input"
            label={inputLabel}
            rules={{ required: true }}
          />
          <div>
            <CSUIButton
              type="submit"
              label={isPublic ? "See Results" : "Submit"}
              color="green"
              disabled={noInput}
            />
          </div>
        </div>
        <div className="search-row-print">
            <div className="retailer">
                <div>{retailerConfig[retailer].label}:</div>
                <div>({inputLabel})</div>
            </div>
            <div className="terms">
                {searchInput}
            </div>
        </div>
      </div>
    </form>
  );
};