export const PLAN_SIZES = [
    {
        key: 'first',
        price: 3000,
        description: 'Unique Product Pages to be Audited',
        isActive: false,
    },
    {
        key: 'second',
        price: 1500,
        description: 'Unique Product Pages to be Audited',
        isActive: false,
    },
    {
        key: 'third',
        price: 500,
        description: 'Unique Product Pages to be Audited',
        isActive: false,
    },
    {
        key:'fourth',
        price: 150,
        description: 'Unique Product Pages to be Audited',
        isActive: false,
    },
]