import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import styles from './styles';
import "./styles.scss";
import clsx from 'clsx';
import DigitalSOSIcon from '../../media/DigitalSOS/SOSHeader.png';

interface Props extends RouteComponentProps<{ subRoute: string }> {}

const UrlPrefix = ''//'/demo/navbar';

const SubRoutes = {
  'digital-shelf': {
    path: UrlPrefix + '/digital-shelf',
    title: 'Beta',
    subTitle: 'Share-of-Shelf',
    Icon: null,
    image: DigitalSOSIcon,
    onClick: () => {
      /* const anchor = document.getElementById("jotform-dsos");
      anchor.click(); */
      /* window.open(
        'https://form.jotform.com/212322296734454',
        'blank',
        'scrollbars=yes,toolbar=no,width=700,height=500'
      ); */
    }
  },
  'add-skus': {
    path: UrlPrefix + '/jobs',
    title: 'My',
    subTitle: 'Audits',
    Icon: AddCircleOutlineIcon,
  },
  'customize-gradient': {
    path: UrlPrefix + '/scoring',
    title: 'Customize',
    subTitle: 'Grading',
    Icon: PlaylistAddCheckIcon,
  },
  'create-alerts': {
    path: UrlPrefix + '/audit-rules',
    title: 'Create',
    subTitle: 'Alerts',
    Icon: ReportProblemOutlinedIcon,
  },
  'view-catalog': {
    path: UrlPrefix + '/catalog',
    title: 'View Audit',
    subTitle: 'Catalog',
    Icon: PageviewOutlinedIcon,
  },
  'create-workflow': {
    path: UrlPrefix + '/workflow',
    title: 'Create',
    subTitle: 'Workflow',
    Icon: CreateNewFolderOutlinedIcon,
  },
};

const DemoNavbar = ({
  match: {
    params: { subRoute },
  },
}: Props) => {
  const classes = styles();

  return (
    <div className={clsx(classes.root, 'newNavbar')}>
      {Object.values(SubRoutes).map((routeObj, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Icon, path, title, subTitle } = routeObj;
        const image = routeObj['image'] ? <img src={routeObj['image']} /> : undefined;
        const onClick = routeObj['onClick'] || (() => {});

        return <NavLink
          key={path}
          to={path}
          onClick={onClick}
          activeClassName={classes.active}
          className={classes.link}>
          <div>{image || <Icon className={classes.icon} />}</div>
          <div className={classes.labelContainer}>
            <div className={classes.title}>{title}</div>
            <div className={classes.subTitle}>{subTitle}</div>
          </div>
          {i !== Object.values(SubRoutes).length - 1 && (
            <KeyboardArrowRightIcon className={classes.nextArrow} />
          )}
        </NavLink>
      })}
    </div>
  );
};

export default withRouter(DemoNavbar);
