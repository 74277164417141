import React from 'react';
import styles from './styles';

const Header = () => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        You’ve Spent Millions Launching Your Products.
      </h2>
      <h4 className={classes.subTitle}>
        Can you really afford not knowing if they’re live, accurate &
        optimized?
      </h4>
    </div>
  );
};

export default Header;
