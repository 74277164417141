import React from 'react';
import clsx from 'clsx';
import Plan from './Plan';
import PlanSelect from '../PlanSelect';
import { PlanSize } from '../../types';
import styles from './styles';
//import { SinglePlan } from '../../../../libraries/types/chargebeeStructure';

interface Props {
  activePlan: string;
  planSizes: PlanSize[];
  onSelectPlan: (plan) => void;
  onSelectPlanSize: (key) => void;
  plans: any[];
  flexPlan: any;
  planType: 'quarterly' | 'annual' | 'flex';
  publicVersion?: boolean;
  onTermChange: (termSize: string) => void
  termSize: string
}

const PlanSizes: React.FunctionComponent<Props> = (props) => {
  const {
    planSizes,
    activePlan,
    onSelectPlanSize,
    onSelectPlan,
    planType,
    flexPlan,
    publicVersion,
    onTermChange,
    termSize
  } = props
  const classes = styles();

  const isActive = (key: string) => key === activePlan;

  const activeIdx = planSizes.findIndex(plan => isActive(plan.key));
  let endIdx = Math.min(planSizes.length, activeIdx+2);
  if(endIdx-4 < 0) {
    endIdx = 4;
  }
  const startIdx = Math.max(0, endIdx-4);

  const plansSlice = {startIdx, endIdx}

  return (
    <div className={classes.root}>
      <PlanSelect
        activePlan={activePlan}
        plans={planSizes.slice(startIdx, endIdx)}
        onSelect={onSelectPlanSize}
        onTermChange={onTermChange}
        termSize={termSize}
      >
        {flexPlan ? <>
          <div key={flexPlan.planKey} className={clsx(classes.planWrapper, 'planColumn')}>
            <Plan {...flexPlan} plansSlice={plansSlice} activePlan={activePlan} onSelect={onSelectPlan} planType={"flex"} publicVersion={publicVersion}/>
          </div>
          {/* <Divider flexItem orientation='vertical' style={{ width: '4px', margin: '0 20px', backgroundColor: 'rgba(0,0,0,.75' }} /> */}
        </> : null}
        {props.plans.map((plan) => (
          <div key={plan.planKey} className={clsx(classes.planWrapper, 'planColumn')}>
            <Plan {...plan} plansSlice={plansSlice} activePlan={activePlan} onSelect={onSelectPlan} planType={planType} publicVersion={publicVersion} />
          </div>
        ))}
      </PlanSelect>
    </div>
  );
};

export default PlanSizes;
